import { EQpImageElementType } from '@library/models/qp-image.models';
import { CommonRoutes } from '@one/app/common-routes';
import { InspectionId } from '@one/app/pages/isp/shared/models/isp-inspection.models';
import { EWorkflowActionType, IWorkflowAction } from '@one/app/shared/models/workflow/workflow.models';
import { QimaOptionalType } from '@qima/ngx-qima';

export class IspRoutes {
  public static backUrls: string[] = [
    IspRoutes.home(),
    IspRoutes.root(),
    CommonRoutes.root(),
    IspRoutes.inspectionConsultationPlanning(),
    IspRoutes.inspectionConsultationCompleted(),
    IspRoutes.inspectionConsultation(),
  ];

  public static root(): string {
    return `/isp`;
  }

  public static home(): string {
    return `${IspRoutes.root()}/home`;
  }

  // INSPECTIONS
  public static inspection(): string {
    return `${IspRoutes.root()}/inspection`;
  }

  public static inspectionConsultation(): string {
    return `${IspRoutes.inspection()}/consult`;
  }

  public static inspectionConsultationPlanning(): string {
    return `${IspRoutes.inspectionConsultation()}/planning`;
  }

  public static inspectionConsultationToModify(): string {
    return `${IspRoutes.inspectionConsultation()}/to-modify`;
  }

  public static inspectionConsultationCompleted(): string {
    return `${IspRoutes.inspectionConsultation()}/completed`;
  }

  public static inspectionConsultationErrorForbidden(): string {
    return `${IspRoutes.inspectionConsultation()}/error/forbidden`;
  }

  public static inspectionConsultationErrorUnknown(): string {
    return `${IspRoutes.inspectionConsultation()}/error/unknown`;
  }

  public static inspectionDetails(id: Readonly<InspectionId>): string {
    return `${IspRoutes.inspection()}/${id}/details`;
  }

  public static inspectionSteps(id: Readonly<InspectionId>): string {
    return `${IspRoutes.inspection()}/${id}`;
  }

  public static inspectionSiteLocation(id: Readonly<InspectionId>): string {
    return `${IspRoutes.inspection()}/${id}/site-location`;
  }

  public static inspectionPreparationDetails(id: Readonly<InspectionId>): string {
    return `${IspRoutes.inspection()}/${id}/preparation/details`;
  }

  public static inspectionComplete(id: Readonly<InspectionId>): string {
    return `${IspRoutes.inspection()}/${id}/complete`;
  }

  public static inspectionReviewConfirm(inspectionId: Readonly<InspectionId>, stepId: Readonly<string>): string {
    return `${IspRoutes.inspection()}/${inspectionId}/findings-summary-review/${stepId}/confirm`;
  }

  public static inspectionProducts(inspectionId: Readonly<InspectionId>): string {
    return `${IspRoutes.inspection()}/${inspectionId}/details/products`;
  }

  public static inspectionProductDetails(inspectionId: Readonly<InspectionId>, productIndex: Readonly<number>): string {
    return `${IspRoutes.inspection()}/${inspectionId}/details/products/${productIndex}`;
  }

  public static productPicture(inspectionId: Readonly<InspectionId>, categoryPath: Readonly<string>): string {
    return `${IspRoutes.inspection()}/${inspectionId}/product-picture/${categoryPath}`;
  }

  public static productPictureImageCapture(inspectionId: Readonly<InspectionId>, categoryPath: Readonly<string>): string {
    return `${IspRoutes.inspection()}/${inspectionId}/image-capture/product-picture/${categoryPath}`;
  }

  public static quantities(inspectionId: Readonly<InspectionId>): string {
    return `${IspRoutes.inspection()}/${inspectionId}/quantities`;
  }

  public static quantitiesWithoutAql(inspectionId: Readonly<InspectionId>): string {
    return `${IspRoutes.inspection()}/${inspectionId}/quantities-without-aql`;
  }

  public static inspectionComment(inspectionId: Readonly<InspectionId>): string {
    return `${IspRoutes.inspection()}/${inspectionId}/comment`;
  }

  public static inspectionAcceptation(id: Readonly<InspectionId>): string {
    return `${IspRoutes.inspection()}/${id}/acceptation`;
  }

  public static inspectionDone(id: Readonly<InspectionId>): string {
    return `${IspRoutes.inspection()}/${id}/done`;
  }

  public static testInTestsChecklist(
    inspectionId: Readonly<InspectionId>,
    categoryPath: Readonly<string>,
    testPath: Readonly<string>
  ): string {
    return `${IspRoutes.root()}/inspection/${inspectionId}/categories/${categoryPath}/test/${testPath}`;
  }

  public static documents(inspectionId: Readonly<InspectionId>, categoryPath: Readonly<string>): string {
    return `${IspRoutes.inspection()}/${inspectionId}/documents/${categoryPath}`;
  }

  public static findingsSummaryReview(inspectionId: Readonly<InspectionId>, categoryPath: Readonly<string>): string {
    return `${IspRoutes.inspection()}/${inspectionId}/findings-summary-review/${categoryPath}`;
  }

  public static findingsSummaryReviewRating(inspectionId: Readonly<InspectionId>, categoryPath: Readonly<string>): string {
    return `${IspRoutes.inspection()}/${inspectionId}/findings-summary-review-rating/${categoryPath}`;
  }

  public static instructions(inspectionId: Readonly<InspectionId>, categoryPath: Readonly<string>): string {
    return `${IspRoutes.inspection()}/${inspectionId}/instructions/${categoryPath}`;
  }

  public static referenceSample(inspectionId: Readonly<InspectionId>, categoryPath: Readonly<string>): string {
    return `${IspRoutes.inspection()}/${inspectionId}/reference-sample/${categoryPath}`;
  }

  public static sampleCollection(inspectionId: Readonly<InspectionId>, categoryPath: Readonly<string>): string {
    return `${IspRoutes.inspection()}/${inspectionId}/sample-collection/${categoryPath}`;
  }

  public static siteLocation(inspectionId: Readonly<InspectionId>, categoryPath: Readonly<string>): string {
    return `${IspRoutes.inspection()}/${inspectionId}/site-location/${categoryPath}`;
  }

  public static recipients(inspectionId: Readonly<InspectionId>, categoryPath: Readonly<string>): string {
    return `${IspRoutes.inspection()}/${inspectionId}/recipients/${categoryPath}`;
  }

  public static customFields(inspectionId: Readonly<InspectionId>, categoryPath: Readonly<string>): string {
    return `${IspRoutes.inspection()}/${inspectionId}/custom-fields/${categoryPath}`;
  }

  public static inspectionStepUrl(
    inspectionId: InspectionId,
    workflowAction: IWorkflowAction,
    isServiceProvider?: boolean
  ): QimaOptionalType<string> {
    switch (workflowAction.type) {
      case EWorkflowActionType.INSPECTION_PREPARATION: {
        return isServiceProvider ? IspRoutes.quantitiesWithoutAql(`${inspectionId}`) : IspRoutes.quantities(`${inspectionId}`);
      }

      case EWorkflowActionType.PRODUCT_PICTURE: {
        return IspRoutes.productPicture(`${inspectionId}`, workflowAction.id);
      }

      case EWorkflowActionType.TESTS_CHECKLIST: {
        return IspRoutes.testsChecklist(`${inspectionId}`, workflowAction.id);
      }

      case EWorkflowActionType.DEFECTS_CHECKLIST: {
        return IspRoutes.defectsChecklist(`${inspectionId}`, workflowAction.id);
      }

      case EWorkflowActionType.MEASURES_CHECKLIST: {
        return IspRoutes.measurementsChecklist(`${inspectionId}`, workflowAction.id);
      }

      case EWorkflowActionType.SIMPLIFIED_MEASUREMENTS_CHECKLIST: {
        return IspRoutes.simplifiedMeasurement(`${inspectionId}`, workflowAction.id);
      }

      case EWorkflowActionType.DOCUMENT: {
        return IspRoutes.documents(inspectionId, workflowAction.id);
      }

      case EWorkflowActionType.FINDINGS_SUMMARY_REVIEW: {
        return isServiceProvider
          ? IspRoutes.findingsSummaryReviewRating(inspectionId, workflowAction.id)
          : IspRoutes.findingsSummaryReview(inspectionId, workflowAction.id);
      }

      case EWorkflowActionType.INSTRUCTIONS: {
        return IspRoutes.instructions(inspectionId, workflowAction.id);
      }

      case EWorkflowActionType.REFERENCE_SAMPLE: {
        return IspRoutes.referenceSample(inspectionId, workflowAction.id);
      }

      case EWorkflowActionType.SAMPLE_COLLECTION: {
        return IspRoutes.sampleCollection(inspectionId, workflowAction.id);
      }

      case EWorkflowActionType.SITE_LOCATION: {
        return IspRoutes.siteLocation(inspectionId, workflowAction.id);
      }

      case EWorkflowActionType.ADD_RECIPIENTS: {
        return IspRoutes.recipients(inspectionId, workflowAction.id);
      }

      case EWorkflowActionType.CUSTOM_FIELDS: {
        return IspRoutes.customFields(inspectionId, workflowAction.id);
      }

      case EWorkflowActionType.COMPLETE: {
        return IspRoutes.inspectionComplete(inspectionId);
      }

      // Un-handled workflow type
      default:
        return undefined;
    }
  }

  // SHARE

  public static inspectionShare(inspectionId: Readonly<InspectionId>): string {
    return `${IspRoutes.inspection()}/${inspectionId}/share`;
  }

  // LIBRARY

  public static inspectionLibrary(inspectionId: Readonly<InspectionId>): string {
    return `${IspRoutes.inspection()}/${inspectionId}/library`;
  }

  public static libraryImageCapture(inspectionId: Readonly<InspectionId>): string {
    return `${IspRoutes.inspection()}/${inspectionId}/image-capture`;
  }

  public static libraryImage(inspectionId: Readonly<InspectionId>, pictureId: Readonly<string>): string {
    return `${IspRoutes.inspection()}/${inspectionId}/image-edit/${pictureId}`;
  }

  // MEASUREMENTS
  public static measurementsChecklist(inspectionId: Readonly<InspectionId>, categoryPath: Readonly<string>): string {
    return `${IspRoutes.inspection()}/${inspectionId}/measurements/${categoryPath}`;
  }

  public static measurementsSample(
    inspectionId: Readonly<InspectionId>,
    categoryPath: Readonly<string>,
    productPath: Readonly<string>
  ): string {
    return `${IspRoutes.inspection()}/${inspectionId}/measurements/${categoryPath}/${productPath}`;
  }

  public static measurementsSectionSample(
    inspectionId: Readonly<InspectionId>,
    categoryPath: Readonly<string>,
    productPath: Readonly<string>,
    sectionPath: Readonly<string>
  ): string {
    return `${IspRoutes.inspection()}/${inspectionId}/measurements/${categoryPath}/${productPath}/sections/${sectionPath}`;
  }

  public static measurementsSectionSampleInVariance(
    inspectionId: Readonly<InspectionId>,
    categoryPath: Readonly<string>,
    productPath: Readonly<string>,
    sectionPath: Readonly<string>,
    variancePath: Readonly<string>,
    samplePath: Readonly<string>
  ): string {
    return `${IspRoutes.inspection()}/${inspectionId}/measurements/${categoryPath}/${productPath}/sections/${sectionPath}/test/${variancePath}/${samplePath}`;
  }

  public static measurementsSampleInVariance(
    inspectionId: Readonly<InspectionId>,
    categoryPath: Readonly<string>,
    productPath: Readonly<string>,
    testPath: Readonly<string>,
    samplePath: Readonly<string>
  ): string {
    return `${IspRoutes.inspection()}/${inspectionId}/measurements/${categoryPath}/${productPath}/test/${testPath}/${samplePath}`;
  }

  // TESTS
  public static testsChecklist(inspectionId: Readonly<InspectionId>, categoryPath: Readonly<string>): string {
    return `${IspRoutes.inspection()}/${inspectionId}/categories/${categoryPath}`;
  }

  public static testAttachments(inspectionId: Readonly<InspectionId>, categoryPath: Readonly<string>, testPath: Readonly<string>): string {
    return `${this.testInTestsChecklist(inspectionId, categoryPath, testPath)}/attachments`;
  }

  public static testScanBarcode(inspectionId: Readonly<InspectionId>, categoryPath: Readonly<string>, testPath: Readonly<string>): string {
    return `${IspRoutes.root()}/inspection/${inspectionId}/categories/${categoryPath}/test/${testPath}/barcode`;
  }

  public static testImageCapture(inspectionId: Readonly<InspectionId>, testId: Readonly<string>): string {
    return `${IspRoutes.inspection()}/${inspectionId}/image-capture/answers/${testId}`;
  }

  public static testImage(inspectionId: Readonly<InspectionId>, testId: Readonly<string>, pictureId: Readonly<string>): string {
    return `${IspRoutes.inspection()}/${inspectionId}/image-edit/${pictureId}/answers/${testId}`;
  }

  public static testScanImage(inspectionId: Readonly<InspectionId>, testId: Readonly<string>, pictureId: Readonly<string>): string {
    return `${IspRoutes.inspection()}/${inspectionId}/image-edit/${pictureId}/scans/${testId}`;
  }

  // DEFECTS
  public static defectsChecklist(inspectionId: Readonly<InspectionId>, categoryPath: Readonly<string>): string {
    return `${IspRoutes.inspection()}/${inspectionId}/defect/${categoryPath}`;
  }

  public static defectsChecklistProduct(
    inspectionId: Readonly<InspectionId>,
    categoryPath: Readonly<string>,
    purchaseOrderProductId: Readonly<string>
  ): string {
    return `${IspRoutes.inspection()}/${inspectionId}/defect/${categoryPath}/products/${purchaseOrderProductId}`;
  }

  public static defectAttachments(inspectionId: Readonly<InspectionId>, categoryId: Readonly<string>, defectId: Readonly<string>): string {
    return `${this.defectsChecklist(inspectionId, categoryId)}/def/${defectId}/attachments`;
  }

  public static defectImageCapture(inspectionId: Readonly<InspectionId>, defectId: Readonly<string>): string {
    return `${IspRoutes.inspection()}/${inspectionId}/image-capture/defects/${defectId}`;
  }

  public static defectProductImageCapture(
    inspectionId: Readonly<InspectionId>,
    defectId: Readonly<string>,
    purchaseOrderProductId: Readonly<string>
  ): string {
    return `${IspRoutes.inspection()}/${inspectionId}/image-capture/defects/${defectId}/products/${purchaseOrderProductId}`;
  }

  public static defectImage(inspectionId: Readonly<InspectionId>, defectId: Readonly<string>, pictureId: Readonly<string>): string {
    return `${IspRoutes.inspection()}/${inspectionId}/image-edit/${pictureId}/defects/${defectId}`;
  }

  public static defectProductImage(
    inspectionId: Readonly<InspectionId>,
    defectId: Readonly<string>,
    pictureId: Readonly<string>,
    purchaseOrderProductId: Readonly<string>
  ): string {
    return `${IspRoutes.inspection()}/${inspectionId}/image-edit/${pictureId}/defects/${defectId}/products/${purchaseOrderProductId}`;
  }

  // Simplified measurements

  public static simplifiedMeasurement(inspectionId: Readonly<InspectionId>, categoryPath: Readonly<string>): string {
    return `${IspRoutes.inspection()}/${inspectionId}/simplified-measurements/${categoryPath}`;
  }

  public static simplifiedMeasurementsImageCapture(inspectionId: Readonly<InspectionId>, categoryPath: Readonly<string>): string {
    return `${IspRoutes.inspection()}/${inspectionId}/image-capture/${EQpImageElementType.SIMPLIFIED_MEASUREMENTS}/${categoryPath}`;
  }

  // Image edition
  public static testOrDefectImageEdit(
    inspectionId: Readonly<InspectionId>,
    imageId: Readonly<string>,
    imageElementType: Readonly<EQpImageElementType>,
    testOrDefectPath: Readonly<string>
  ): string {
    return `${IspRoutes.root()}/inspection/${inspectionId}/image-edit/${imageId}/${imageElementType}/${testOrDefectPath}`;
  }

  public static testOrDefectProductImageEdit(
    inspectionId: Readonly<InspectionId>,
    imageId: Readonly<string>,
    imageElementType: Readonly<EQpImageElementType>,
    testOrDefectPath: Readonly<string>,
    purchaseOrderProductId: Readonly<string>
  ): string {
    return `${IspRoutes.root()}/inspection/${inspectionId}/image-edit/${imageId}/${imageElementType}/${testOrDefectPath}/products/${purchaseOrderProductId}`;
  }

  public static globalImageEdit(inspectionId: Readonly<InspectionId>, imageId: Readonly<string>): string {
    return `${IspRoutes.root()}/inspection/${inspectionId}/image-edit/${imageId}`;
  }

  public static simplifiedMeasurementsImageEdit(
    inspectionId: Readonly<InspectionId>,
    imageId: Readonly<string>,
    categoryId: Readonly<string>
  ): string {
    return `${IspRoutes.root()}/inspection/${inspectionId}/image-edit/${imageId}/${
      EQpImageElementType.SIMPLIFIED_MEASUREMENTS
    }/${categoryId}`;
  }

  // SELF INSPECTION
  public static selfStarting(): string {
    return `${IspRoutes.root()}/self-starting`;
  }

  // CREATE PO
  public static po(): string {
    return `${IspRoutes.root()}/po`;
  }

  public static createPO(): string {
    return `${IspRoutes.po()}/create`;
  }

  public static poCreated(): string {
    return `${IspRoutes.po()}/created`;
  }

  public static comfirmInvitation(activationKey: string): string {
    return `${IspRoutes.root()}/inspector-invite-confirm?key=${activationKey}`;
  }
}
