import { BrandDTO } from '@library/dto/brand.dto';
import { DefectsCategoryDTO } from '@library/dto/checklist/defect/defects-category.dto';
import { DefectsChecklistDTO } from '@library/dto/checklist/defect/defects-checklist.dto';
import { InspectionTypeSettingDTO } from '@library/dto/inspection-type-settings.dto';
import { InspectionTypeDTO } from '@library/dto/inspection-type.dto';
import { ProductDetailDTO } from '@library/dto/product-detail.dto';
import { PurchaseOrderViewDTO } from '@library/dto/purchase-order-view.dto';
import { TestsChecklistDTO } from '@library/dto/tests-checklist.dto';
import { DefectAnsweredDTO } from '@library/dto/testschecklist/answer/defect-answered.dto';
import { CustomFieldDTO } from '@library/dto/workflow/custom-fields.dto';
import { WorkflowTemplateGetDTO } from '@library/dto/workflow/workflow-template.dto';
import { ActionRequest } from '@one/app/shared/models/action-request.models';
import { IInspectionConsultation } from '@one/app/shared/models/inspection-consultation/inspection-consultation.models';
import { InspectionFile } from '@one/app/shared/models/inspection-file.models';
import { IWorkflow } from '@one/app/shared/models/workflow/workflow.models';
import Dexie, { Transaction } from 'dexie';

export class DatabaseDexie extends Dexie {
  // Usefull to perform an offline inspection 🚀
  public inspection: Dexie.Table<IInspectionConsultation, number>;
  public workflow: Dexie.Table<IWorkflow, number>;
  public actionRequest: Dexie.Table<ActionRequest, number>;
  public file: Dexie.Table<InspectionFile, number>;

  // Usefull to start an offline inspection 🚀
  public purchaseOrder: Dexie.Table<PurchaseOrderViewDTO, number>;
  public product: Dexie.Table<ProductDetailDTO, number>;
  public inspectionType: Dexie.Table<InspectionTypeDTO, number>;
  public inspectionTypeSetting: Dexie.Table<InspectionTypeSettingDTO, number>;
  public workflowTemplate: Dexie.Table<WorkflowTemplateGetDTO, number>;
  public testsChecklist: Dexie.Table<TestsChecklistDTO, number>;
  public defectsChecklist: Dexie.Table<DefectsChecklistDTO<DefectsCategoryDTO<DefectAnsweredDTO>>, number>;
  public brand: Dexie.Table<BrandDTO, number>;
  public customField: Dexie.Table<CustomFieldDTO, number>;

  public constructor(databaseName: string) {
    super(databaseName);

    /**
     * @description This defines the properties we index
     */
    this.version(1).stores({
      inspection: '&id',
      statusUpdate: '++id,inspectionId',
    });
    this.version(3).stores({
      inspectionImage: '++id,inspectionId,timestamp',
      statusUpdate: '++id,inspectionId,timestamp',
    });
    this.version(4).stores({
      inspectionImageComment: '++id,inspectionId,inspectionImageId,timestamp',
    });
    this.version(5).stores({
      protocolAnswer: '++id,inspectionId,answerId,timestamp',
    });
    this.version(6).stores({
      inspectionImage: '++id,inspectionId,timestamp,answerId',
    });
    this.version(7).stores({
      inspectionGlobalComment: '++id,inspectionId,timestamp',
    });
    this.version(8).stores({
      inspectionImage: '++id,inspectionId,timestamp,isSynced,syncedId',
    });

    /**
     * @description remove all previous data on update -> fresh start!
     */
    this.version(9)
      .stores({
        inspection: '&id',
        actionRequest: '++id,isSynced',
      })
      .upgrade((): void => void Dexie.delete(databaseName));

    this.version(10).stores({
      workflow: '&id',
    });

    this.version(11).stores({
      file: '++id,inspectionId,&fileId',
    });

    this.version(12).stores({
      file: '++id,&fileId',
    });

    /**
     * @description In the version 13
     * We need the inspection id to clean the db
     * For generic file (file without inspection id) we decide to set default id to 0
     * due to Dexie not accepted null type. 😢
     *
     * For the action are not link to an inspection and we need to have a date to clean old actions
     * 🧼 (thrown on the floor of prison shower)
     */
    this.version(13)
      .stores({
        file: '++id,inspectionId,&fileId',
      })
      .upgrade(
        (trans): void =>
          void trans
            .table('file')
            .toCollection()
            .modify((file): void => {
              file.inspectionId = file.inspectionId || 0;
            })
      )
      .upgrade(
        (trans): void =>
          void trans
            .table('actionRequest')
            .toCollection()
            .modify((action): void => {
              if (action.isSynced && !action.syncDate) {
                action.syncDate = new Date();
              }
            })
      );

    this.version(14).stores({
      product: '++id,identifierValue',
      purchaseOrder: '++id,reference',
    });

    this.version(15).stores({
      inspectionType: '++id',
      inspectionTypeSetting: '++id',
      workflowTemplate: '++id, name',
      testsChecklist: '++id, name',
      defectsChecklist: '++id, name',
    });

    this.version(16).stores({
      brand: '++id',
      customField: '++id',
    });

    /**
     * @description In the version 17
     * 🚨 Big migration 🚨
     * Big changes for inspection and workflow, ID cannot be used anymore as primary key
     * So we need to migrate all data from inspection and workflow to a temporary table to ease the migration (version 18 👇)
     * BTW we need here to do it in 2 steps because Dexie does not support multiple table migration in one version
     * @tutorial see 👉 https://github.com/dexie/Dexie.js/issues/781
     */
    this.version(17)
      .stores({
        inspectionTmp: '++,id,&inspectionUuid', // 💡 tmp table to store inspection before next upgrade
        workflowTmp: '++,id,&inspectionUuid', // 💡 tmp table to store workflow before next upgrade
        inspection: null,
        workflow: null,
        file: '++id,inspectionId,inspectionUuid,&fileId',
      })
      .upgrade(async (transaction: Transaction): Promise<void> => {
        const inspections = await transaction.table('inspection').toArray();
        const workflows = await transaction.table('workflow').toArray();

        await transaction.table('inspectionTmp').bulkAdd(inspections);
        await transaction.table('workflowTmp').bulkAdd(workflows);
      });

    /**
     * @description In the version 18
     * 🚨 End of the "Big migration" (started during the version 17) 🚨
     * + the time to clean not used tables / temporary tables
     */
    this.version(18)
      .stores({
        inspection: '++,id,&inspectionUuid',
        workflow: '++,id,&inspectionUuid',
        inspectionTmp: null, // 🗑️ remove tmp migration table
        workflowTmp: null, // 🗑️ remove tmp migration table
        inspectionGlobalComment: null, // 🗑️ remove old table
        statusUpdate: null, // 🗑️ remove old table
        protocolAnswer: null, // 🗑️ remove old table
        inspectionImage: null, // 🗑️ remove old table
      })
      .upgrade(async (transaction: Transaction): Promise<void> => {
        const inspections = await transaction.table('inspectionTmp').toArray();
        const workflows = await transaction.table('workflowTmp').toArray();

        await transaction.table('inspection').bulkAdd(inspections);
        await transaction.table('workflow').bulkAdd(workflows);

        await transaction
          .table('inspection')
          .toCollection()
          .modify((inspectionToMigrate: IInspectionConsultation): void => {
            inspectionToMigrate.createdOffline = inspectionToMigrate.createdOffline || false;
          });
      });

    /**
     * @description In the version 19
     * Rename isAqlPerProduct to isDefectsPerProduct.
     */
    this.version(19).upgrade(async (transaction: Transaction): Promise<void> => {
      await transaction
        .table('inspection')
        .toCollection()
        .modify((inspectionToMigrate: IInspectionConsultation): void => {
          inspectionToMigrate.isDefectsPerProduct = inspectionToMigrate['isAqlPerProduct'];
        });

      await transaction
        .table('inspectionTypeSetting')
        .toCollection()
        .modify((settingToMigrate: InspectionTypeSettingDTO): void => {
          settingToMigrate.setting.aqlDefects.isDefectsPerProduct = settingToMigrate.setting.aqlDefects['isAqlPerProduct'];
        });
    });

    /**
     * @description In the version 20
     * Add isAqlPerProduct with default to false.
     */
    this.version(20).upgrade(async (transaction: Transaction): Promise<void> => {
      await transaction
        .table('inspection')
        .toCollection()
        .modify((inspectionToMigrate: IInspectionConsultation): void => {
          inspectionToMigrate.isAqlPerProduct = !!inspectionToMigrate.isAqlPerProduct;
        });
    });

    this.inspection = this.table('inspection');
    this.actionRequest = this.table('actionRequest');
    this.workflow = this.table('workflow');
    this.file = this.table('file');
    this.product = this.table('product');
    this.purchaseOrder = this.table('purchaseOrder');
    this.inspectionType = this.table('inspectionType');
    this.inspectionTypeSetting = this.table('inspectionTypeSetting');
    this.workflowTemplate = this.table('workflowTemplate');
    this.testsChecklist = this.table('testsChecklist');
    this.defectsChecklist = this.table('defectsChecklist');
    this.brand = this.table('brand');
    this.customField = this.table('customField');
  }
}
