import { DefectsCategoryDTO } from '@library/dto/checklist/defect/defects-category.dto';
import { DefectsChecklistDTO } from '@library/dto/checklist/defect/defects-checklist.dto';
import { InspectionTypeSettingDTO } from '@library/dto/inspection-type-settings.dto';
import { InspectionTypeDTO } from '@library/dto/inspection-type.dto';
import { ProductDetailDTO } from '@library/dto/product-detail.dto';
import { PurchaseOrderViewDTO } from '@library/dto/purchase-order-view.dto';
import { TestsChecklistDTO } from '@library/dto/tests-checklist.dto';
import { DefectAnsweredDTO } from '@library/dto/testschecklist/answer/defect-answered.dto';
import { WorkflowTemplateGetDTO } from '@library/dto/workflow/workflow-template.dto';
import { RatingTypes } from '@library/dto-enums/rating-types.dto-enum';
import { EQpHttpStatusCode } from '@library/models/qp-http.models';
import { EQpProfile } from '@library/models/qp-profile.models';
import { NetworkStatusService } from '@library/services/qp-network-status/qp-network-status.service';
import { QpQueryParamsService } from '@library/services/qp-query-params/qp-query-params.service';
import { SERVER_API_URL } from '@one/app/app.constants';
import { IBrdProductLinkedToPurchaseOrder } from '@one/app/pages/brd/pages/inspection/shared/components/brd-inspection-product-tab/brd-inspection-product-tab.models';
import {
  BrdWorkflowChecklistConsultationType,
  EBrdWorkflowChecklistConsultationType,
} from '@one/app/pages/brd/pages/workflow/pages/checklist/pages/consult/brd-workflow-checklist-consult.models';
import { INTERCEPTOR_SKIP_HEADER } from '@one/app/shared/interceptors/inspection.interceptor';
import { ETestChecklistStatus } from '@one/app/shared/models/checklist/tests-checklist.models';
import { IWorkflowTemplate } from '@one/app/shared/models/workflows/workflows-templates.models';
import { AccountService } from '@one/app/shared/services/account/account.service';
import { DatabaseDataService } from '@one/app/shared/services/database/database-data.service';
import { HttpHandlerFn, HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { isEmpty, isNil, last, trim } from 'lodash/index';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

const GET_PO_PRODUCTS_SEARCH = RegExp(`^${SERVER_API_URL}api/purchase-order-products/search$`);
const GET_INSPECTION_TYPES = RegExp(`^${SERVER_API_URL}api/inspection-types$`);
const GET_INSPECTION_TYPES_SETTINGS = RegExp(`^${SERVER_API_URL}api/brands/(?<brandId>[0-9\\-]+)\\/settings/inspection-types$`);
const GET_INSPECTION_TYPE_SETTING_ID = RegExp(
  `^${SERVER_API_URL}api/brands/(?<brandId>[0-9\\-]+)\\/settings/inspection-types/(?<inspectionTypeId>[0-9\\-]+)`
);
const GET_WORKFLOWS = RegExp(`^${SERVER_API_URL}api/workflows$`);
const GET_WORKFLOW_ID = RegExp(`^${SERVER_API_URL}api/workflows/(?<workflowId>[0-9\\-]+)`);
const GET_CHECKLISTS = RegExp(`^${SERVER_API_URL}api/checklists$`);

/**
 * @param { TestsChecklistDTO } test the test to convert 🧪
 * @param { EBrdWorkflowChecklistConsultationType } type the type of the test 🧪
 * @returns {BrdWorkflowChecklistConsultationType} the converted test ✨
 */
function convertChecklistDTOtoBrdWorkflowChecklistConsultationType(
  test: TestsChecklistDTO | DefectsChecklistDTO<DefectsCategoryDTO<DefectAnsweredDTO>>,
  type: EBrdWorkflowChecklistConsultationType
): BrdWorkflowChecklistConsultationType {
  return {
    id: test.id,
    name: test.name,
    publicationStatus: test.publicationStatus as unknown as ETestChecklistStatus,
    type,
    isQima: test.isQima,
    createdByFirstName: test.createdBy,
    createdById: 0,
    createdByLastName: test.createdBy,
    createdByLogin: '',
    createdDate: test.createdDate,
    lastModifiedByFirstName: test.lastModifiedBy,
    lastModifiedById: 0,
    lastModifiedByLastName: test.lastModifiedBy,
    lastModifiedByLogin: '',
    lastModifiedDate: test.lastModifiedDate,
    checklistId: test.id,
    ratingType: test.rating?.type ?? RatingTypes.PASS_FAIL,
    ratings: test.rating?.ratings ?? [],
  };
}

/**
 * @param  {PurchaseOrderViewDTO[]} products list of products use to enrich the PO list 📦
 * @returns {IBrdProductLinkedToPurchaseOrder[]} the converted list ✨
 */
function convertProductDetailDTOtoIBrdProductConsultations(products: ProductDetailDTO[]): IBrdProductLinkedToPurchaseOrder[] {
  return products.map((product): IBrdProductLinkedToPurchaseOrder => {
    return {
      brandId: product.brandId,
      productId: product.id,
      productIdentifierType: product.identifierType,
      productIdentifierValue: product.identifierValue,
      productPhotoUuid: product.photo?.uuid,
    };
  });
}

/**
 * @param {PurchaseOrderViewDTO[]} pos list of purchase orders to convert 📦
 * @param  {PurchaseOrderViewDTO[]} products list of products use to enrich the PO list 📦
 * @returns {IBrdProductLinkedToPurchaseOrder[]} the converted list ✨
 */
function convertPurchaseOrderViewDTOtoIBrdProductConsultations(
  pos: PurchaseOrderViewDTO[],
  products: ProductDetailDTO[]
): IBrdProductLinkedToPurchaseOrder[] {
  const data: IBrdProductLinkedToPurchaseOrder[] = [];

  pos.forEach((po): void => {
    po.products.forEach((product): void => {
      const productData = products.find((p): boolean => p.id === product.productId);

      data.push({
        brandId: productData?.brandId,
        productId: product.productId,
        productIdentifierType: product.productIdentifierType,
        productIdentifierValue: product.productIdentifierValue,
        productPhotoUuid: productData?.photo?.uuid,
        productPurchaseOrderId: product.id,
        purchaseOrderId: po.id,
        purchaseOrderReference: po.reference,
        purchaseOrderShipmentDate: product.shipmentDate ? new Date(product.shipmentDate) : undefined,
        productUnit: product.unit,
        productQuantity: product.quantity,
        purchaseOrderTier1Name: po.tier1.name,
        purchaseOrderTier2Name: po.tier2?.name ?? '',
        purchaseOrderDestinationName: po.destination?.name ?? '',
      });
    });
  });

  return data;
}

/**
 * @param {DatabaseDataService} databaseDataService instance to use 💿
 * @param  {string} searchInput to search in the database (can be an empty string) 🔍
 * @returns {IBrdProductLinkedToPurchaseOrder[]} the result of the search merging productInPo and product ✨
 */
async function searchInDatabaseService(
  databaseDataService: DatabaseDataService,
  searchInput: string
): Promise<IBrdProductLinkedToPurchaseOrder[]> {
  const products: ProductDetailDTO[] = (await databaseDataService.getProductsByIdentifierValue(searchInput)) ?? [];
  const allProducts: ProductDetailDTO[] = (await databaseDataService.getProducts()) ?? [];
  const pos: PurchaseOrderViewDTO[] = (await databaseDataService.getPOsByReference(searchInput)) ?? [];

  return [
    ...convertPurchaseOrderViewDTOtoIBrdProductConsultations(pos, allProducts),
    ...convertProductDetailDTOtoIBrdProductConsultations(products),
  ].sort((a, b): number => {
    return (b.productPurchaseOrderId ?? b.productId) - (a.productPurchaseOrderId ?? a.productId);
  });
}

/**
 * @param {HttpRequest} request the request to intercept
 * @param {HttpHandlerFn} next the request handler
 * @returns {Observable<HttpEvent<unknown>> | Observable<HttpResponse<undefined>>} the request or the response to return
 */
export function inspectionDataInterceptor$(
  request: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> | Observable<HttpResponse<undefined>> {
  const databaseDataService: DatabaseDataService = inject(DatabaseDataService);
  const accountService: AccountService = inject(AccountService);
  const networkStatusService: NetworkStatusService = inject(NetworkStatusService);
  const queryParamsService: QpQueryParamsService = inject(QpQueryParamsService);

  if (
    !accountService.hasAnyAuthority([EQpProfile.ROLE_SERVICE_PROVIDER_INSPECTOR]) ||
    request.headers.has(INTERCEPTOR_SKIP_HEADER) ||
    networkStatusService.isOnline
  ) {
    return next(request);
  }

  const pathname: string = queryParamsService.getPathname(request.url);

  // 🔗 GET: api/purchase-order-products/search?input=bouh👻
  if (request.method === 'GET' && GET_PO_PRODUCTS_SEARCH.test(pathname)) {
    const searchInput: string = queryParamsService.getQueryParams(request.urlWithParams)['input']?.toString() ?? '';

    return from(searchInDatabaseService(databaseDataService, searchInput)).pipe(
      map((body): HttpResponse<IBrdProductLinkedToPurchaseOrder[]> => {
        return new HttpResponse({ status: EQpHttpStatusCode.OK, body });
      })
    );
  }

  // 🔗 GET: api/inspection-types
  else if (request.method === 'GET' && GET_INSPECTION_TYPES.test(pathname)) {
    return from(databaseDataService.getInspectionTypes()).pipe(
      map((body): HttpResponse<InspectionTypeDTO[]> => {
        return new HttpResponse({ status: EQpHttpStatusCode.OK, body });
      })
    );
  }

  // 🔗 GET: api/brands/:id/settings/inspection-types
  else if (request.method === 'GET' && GET_INSPECTION_TYPES_SETTINGS.test(pathname)) {
    return from(databaseDataService.getInspectionTypeSettings()).pipe(
      map((body): HttpResponse<InspectionTypeSettingDTO[]> => {
        return new HttpResponse({ status: EQpHttpStatusCode.OK, body });
      })
    );
  }

  // 🔗 GET: api/brands/:id/settings/inspection-types/:id
  else if (request.method === 'GET' && GET_INSPECTION_TYPE_SETTING_ID.test(pathname)) {
    const id: string | undefined = GET_INSPECTION_TYPE_SETTING_ID.exec(pathname)?.groups?.inspectionTypeId;

    if (isNil(id)) {
      return next(request);
    }

    return from(databaseDataService.getInspectionTypeSettingById(parseInt(id, 10))).pipe(
      map((body): HttpResponse<InspectionTypeSettingDTO> => {
        return new HttpResponse({ status: EQpHttpStatusCode.OK, body });
      })
    );
  }

  // 🔗 GET: api/workflows
  else if (request.method === 'GET' && GET_WORKFLOWS.test(request.url)) {
    const filterParam: string = queryParamsService.getQueryParams(request.urlWithParams)['filter']?.toString() ?? '';
    let nameSearchInput: string = '';

    if (filterParam.includes('name,CONTAINS,')) {
      nameSearchInput = filterParam.split('name,CONTAINS,')[1];
    }

    return from(databaseDataService.getWorkflowTemplateByName(nameSearchInput)).pipe(
      map((body): HttpResponse<IWorkflowTemplate[]> => {
        return new HttpResponse({
          status: EQpHttpStatusCode.OK,
          body: body.map((workflow): IWorkflowTemplate => {
            return {
              ...workflow,
              inspectionTypeLabel: workflow.inspectionType?.label,
              inspectionTypeId: workflow.inspectionType?.id,
              inspectionTypeDescription: workflow.inspectionType?.description,
            } as IWorkflowTemplate;
          }),
        });
      })
    );
  }

  // 🔗 GET: api/workflows/:id
  else if (request.method === 'GET' && GET_WORKFLOW_ID.test(pathname)) {
    const id: string | undefined = GET_WORKFLOW_ID.exec(pathname)?.groups?.workflowId;

    if (isNil(id)) {
      return next(request);
    }

    return from(databaseDataService.getWorkflowTemplateById(parseInt(id, 10))).pipe(
      map((body): HttpResponse<WorkflowTemplateGetDTO> => {
        return new HttpResponse({ status: EQpHttpStatusCode.OK, body });
      })
    );
  }

  // 🔗 GET: api/checklists?page=0&size=100&filter=type,IN,[TESTS]&sort=lastModifiedDate,DESC
  else if (request.method === 'GET' && GET_CHECKLISTS.test(pathname)) {
    const filterParam: string = queryParamsService.getQueryParams(request.urlWithParams)['filter']?.toString() ?? '';

    if (filterParam.includes('type,IN,[TESTS]')) {
      return from(databaseDataService.getTestsChecklists()).pipe(
        map((testsChecklists): HttpResponse<BrdWorkflowChecklistConsultationType[]> => {
          return new HttpResponse({
            status: EQpHttpStatusCode.OK,
            body: testsChecklists.map(
              (test): BrdWorkflowChecklistConsultationType =>
                convertChecklistDTOtoBrdWorkflowChecklistConsultationType(test, EBrdWorkflowChecklistConsultationType.TESTS)
            ),
          });
        })
      );
    } else if (filterParam.includes('type,IN,[DEFECTS]')) {
      return from(databaseDataService.getDefectsChecklists()).pipe(
        map((defectsChecklists): HttpResponse<BrdWorkflowChecklistConsultationType[]> => {
          return new HttpResponse({
            status: EQpHttpStatusCode.OK,
            body: defectsChecklists.map(
              (defect): BrdWorkflowChecklistConsultationType =>
                convertChecklistDTOtoBrdWorkflowChecklistConsultationType(defect, EBrdWorkflowChecklistConsultationType.DEFECTS)
            ),
          });
        })
      );
    } else if (filterParam.includes('id,IN,') && !isEmpty(last(filterParam.split('IN,')))) {
      const filterParamValue = last(filterParam.split('IN,'));
      const ids: number[] = trim(filterParamValue, '[]').split(',').map(Number) ?? [];

      return from(databaseDataService.getChecklistsByIds(ids)).pipe(
        map((checklists): HttpResponse<BrdWorkflowChecklistConsultationType[]> => {
          return new HttpResponse({
            status: EQpHttpStatusCode.OK,
            body: checklists.map(
              (checklist): BrdWorkflowChecklistConsultationType =>
                convertChecklistDTOtoBrdWorkflowChecklistConsultationType(checklist, EBrdWorkflowChecklistConsultationType.DEFECTS)
            ),
          });
        })
      );
    }

    return next(request);
  }

  return next(request);
}
