// @ts-strict-ignore
import { EQpHttpStatusCode } from '@library/models/qp-http.models';
import { EQpProfile } from '@library/models/qp-profile.models';
import { LanguageHelperService } from '@library/services/locale/language-helper.service';
import { CommonRoutes } from '@one/app/common-routes';
import { ActionsQueueSyncService } from '@one/app/components/main/services/actions-queue-sync.service';
import { IspInspectionCleanService } from '@one/app/pages/isp/shared/services/isp-inspection-clean.service';
import { NetworkStatusAlertService } from '@one/app/services/network-status-alert/network-status-alert.service';
import { Account } from '@one/app/shared/classes/accounts/account';
import { EMenuPosition } from '@one/app/shared/models/menu/menu.models';
import { AccountService } from '@one/app/shared/services/account/account.service';
import { AuthenticationService } from '@one/app/shared/services/auth/authentication.service';
import { PictureImporterService } from '@one/app/shared/services/pictures/picture-importer/picture-importer.service';
import { DisplayedRoutesService } from '@one/app/shared/services/router/displayed-routes.service';
import { RouterHistoryService } from '@one/app/shared/services/router/router-history.service';
import { AppcuesService } from '@one/app/shared/services/third-party/appcues.service';
import { NotifuseService } from '@one/app/shared/services/third-party/notifuse/notifuse.service';
import { WINDOW } from '@one/app/shared/tokens/window.token';
import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, Event, NavigationEnd, NavigationError, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { QimaOptionalType } from '@qima/ngx-qima';
import { isNil } from 'lodash/index';
import { Observable, switchMap } from 'rxjs';
import { filter } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'one-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
  public currentMenuPosition = EMenuPosition.COLLAPSE;
  public menuPositions = EMenuPosition;
  public routerUrl: string;
  public isMenuHidden = false;
  public isAuthenticated = false;
  public hasFluidContainer = true;
  public hasContainer = true;

  public constructor(
    private readonly _languageHelperService: LanguageHelperService,
    private readonly _router: Router,
    private readonly _accountService: AccountService,
    private readonly _displayedRoutesService: DisplayedRoutesService,
    private readonly _networkStatusAlertService: NetworkStatusAlertService,
    private readonly _routerHistoryService: RouterHistoryService,
    private readonly _actionsQueueSyncService: ActionsQueueSyncService,
    private readonly _ispInspectionCleanService: IspInspectionCleanService,
    private readonly _notifuseService: NotifuseService,
    private readonly _authenticationService: AuthenticationService,
    private readonly _translateService: TranslateService,
    @Inject(WINDOW) private readonly _window: Window,
    @Inject(DOCUMENT) private readonly _document: Document,
    private readonly _appcuesService: AppcuesService,
    private readonly _pictureImporterService: PictureImporterService
  ) {
    this._actionsQueueSyncService.init();
    void this._ispInspectionCleanService.clean();
    this._router.events.pipe(untilDestroyed(this)).subscribe((): void => {
      this.routerUrl = this._router.url;
    });
    this._routerHistoryService.startListening();
    this._storeWindowInnerHeight();
    this._window.addEventListener('resize', (): void => this._storeWindowInnerHeight());
  }

  @HostListener('window:beforeunload', ['$event'])
  public unloadNotification(event: BeforeUnloadEvent): void {
    if (this._actionsQueueSyncService.syncStackCount$.getValue() > 0 || this._pictureImporterService.isAnalyzing) {
      // The string only shows in IE and Edge
      event.returnValue = this._translateService.instant('global.exit-without-sync');
    }
  }

  public ngOnInit(): void {
    this._watchAuthentication();
    this._watchNavigationAndMenu();
    this._loadNotifuseWidgetScript();
    this._initAppcues();

    this._networkStatusAlertService.subscribeToNetworkEvents();
  }

  private _watchAuthentication(): void {
    this._accountService
      .getAccount$()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (account: QimaOptionalType<Account>): void => {
          this.isAuthenticated = !isNil(account);
        },
        error: (): void => {
          this.isAuthenticated = false;
        },
      });
    void this._accountService.identity();
    this._authenticationService.setupAuthentication();
  }

  private _watchNavigationAndMenu(): void {
    this._router.events.pipe(untilDestroyed(this)).subscribe((event: Event): void => {
      if (event instanceof NavigationEnd) {
        this._languageHelperService.updateTitle(this._getPageTitle(this._router.routerState.snapshot.root));
        this.isMenuHidden = this._findRouterData(this._router.routerState.snapshot.root.firstChild, 'isMenuHidden') || false;
        this.hasFluidContainer = this._hasFluidContainer(this._router.routerState.snapshot.root);
        this.hasContainer = this._hasContainer(this._router.routerState.snapshot.root);
      }

      if (event instanceof NavigationError && event.error.status === EQpHttpStatusCode.NOT_FOUND) {
        void this._router.navigate([CommonRoutes.error404()], { replaceUrl: true });
      }
    });

    this._displayedRoutesService.menuPosition$
      .pipe(untilDestroyed(this))
      .subscribe((menuPosition: EMenuPosition): EMenuPosition => (this.currentMenuPosition = menuPosition));
  }

  private _getPageTitle(routeSnapshot: ActivatedRouteSnapshot): string {
    let title: string = routeSnapshot.data?.['pageTitle'] ? routeSnapshot.data['pageTitle'] : 'QIMAone';

    if (routeSnapshot.firstChild) {
      title = this._getPageTitle(routeSnapshot.firstChild) || title;
    }

    return title;
  }

  private _findRouterData(routeSnapshot: ActivatedRouteSnapshot, dataKey: string): boolean | undefined {
    if (routeSnapshot && Object.keys(routeSnapshot.data).length > 0 && routeSnapshot.data[dataKey] !== undefined) {
      return routeSnapshot.data[dataKey];
    } else if (routeSnapshot.firstChild) {
      return this._findRouterData(routeSnapshot.firstChild, dataKey);
    }

    return undefined;
  }

  private _loadNotifuseWidgetScript(): void {
    this._notifuseService.loadNotifuseWidgetScript();
  }

  private _initAppcues(): void {
    this._accountService
      .getAccount$()
      .pipe(
        filter(
          (account): boolean =>
            !isNil(account) &&
            this._accountService.hasAnyAuthority([
              EQpProfile.ROLE_BRAND,
              EQpProfile.ROLE_ENTITY,
              EQpProfile.ROLE_INSPECTOR,
              EQpProfile.ROLE_SERVICE_PROVIDER,
              EQpProfile.ROLE_SERVICE_PROVIDER_INSPECTOR,
            ])
        ),
        switchMap((account): Observable<void> => this._appcuesService.load$(account)),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private _hasFluidContainer(routeSnapshot: Readonly<ActivatedRouteSnapshot>): boolean {
    if (routeSnapshot.firstChild) {
      return this._hasFluidContainer(routeSnapshot.firstChild);
    }

    return routeSnapshot.data?.hasFluidContainer ?? true;
  }

  private _hasContainer(routeSnapshot: Readonly<ActivatedRouteSnapshot>): boolean {
    if (routeSnapshot.firstChild) {
      return this._hasContainer(routeSnapshot.firstChild);
    }

    return routeSnapshot.data?.hasContainer ?? true;
  }

  private _storeWindowInnerHeight(): void {
    const WINDOW_INNER_HEIGHT = this._window.innerHeight;

    this._document.documentElement.style.setProperty('--windowInnerHeight', `${WINDOW_INNER_HEIGHT}px`);
  }
}
