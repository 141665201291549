import { DefectsCategoryDTO } from '@library/dto/checklist/defect/defects-category.dto';
import { DefectsCategoryUtils } from '@library/dto/checklist/defect/defects-category.utils';
import { DefectsChecklistDTO } from '@library/dto/checklist/defect/defects-checklist.dto';
import { DefectAnsweredDTO } from '@library/dto/testschecklist/answer/defect-answered.dto';
import { WorkflowAnswerActionDefectDTO } from '@library/dto/testschecklist/answer/workflow/action/workflow-answer-action-defect.dto';
import { DefectClassification } from '@library/dto-enums/defect-classification.dto-enum';
import { QimaOptionalType } from '@qima/ngx-qima';

export type IDefectsQuantityByClassification = {
  [classification in DefectClassification]?: number;
};

export class DefectsChecklistUtils {
  public constructor(private readonly _defectsChecklistDTO: DefectsChecklistDTO<DefectsCategoryDTO<DefectAnsweredDTO>>) {}

  public computeTotalDefectsQuantity(purchaseOrderProductId?: QimaOptionalType<number>): number {
    return this._defectsChecklistDTO.categories.reduce(
      (accumulator, defectCategory): number =>
        accumulator + new DefectsCategoryUtils(defectCategory).computeTotalDefectsQuantity(purchaseOrderProductId),
      0
    );
  }

  public computeDefectsQuantityByClassification(purchaseOrderProductId?: QimaOptionalType<number>): IDefectsQuantityByClassification {
    return this._defectsChecklistDTO.categories.reduce<IDefectsQuantityByClassification>(
      (accumulator, defectCategory): IDefectsQuantityByClassification => {
        const categoryQuantities = new DefectsCategoryUtils(defectCategory).computeDefectsQuantityByClassification(purchaseOrderProductId);

        return {
          [DefectClassification.CRITICAL]: (accumulator[DefectClassification.CRITICAL] ?? 0) + (categoryQuantities.CRITICAL ?? 0),
          [DefectClassification.MAJOR]: (accumulator[DefectClassification.MAJOR] ?? 0) + (categoryQuantities.MAJOR ?? 0),
          [DefectClassification.MINOR]: (accumulator[DefectClassification.MINOR] ?? 0) + (categoryQuantities.MINOR ?? 0),
        };
      },
      { [DefectClassification.CRITICAL]: 0, [DefectClassification.MAJOR]: 0, [DefectClassification.MINOR]: 0 }
    );
  }

  public extractNonEmptyDefects(purchaseOrderProductId?: QimaOptionalType<number>, allowedEmptyDefectId?: string[]): DefectAnsweredDTO[] {
    return this._defectsChecklistDTO.categories.reduce<DefectAnsweredDTO[]>(
      (accumulator, defectCategory): DefectAnsweredDTO[] => [
        ...accumulator,
        ...new DefectsCategoryUtils(defectCategory).extractNonEmptyDefects(purchaseOrderProductId, allowedEmptyDefectId),
      ],
      []
    );
  }

  public isValid(action: WorkflowAnswerActionDefectDTO, purchaseOrderProductId?: QimaOptionalType<number>): boolean {
    return this._defectsChecklistDTO.categories.every((defectCategory): boolean =>
      new DefectsCategoryUtils(defectCategory).isValid(action, purchaseOrderProductId)
    );
  }

  public isMarkedAsComplete(
    isDefectsPerProduct: boolean,
    purchaseOrderProducts?: { purchaseOrderProductId?: number }[],
    purchaseOrderProductId?: QimaOptionalType<number>
  ): boolean {
    if (!isDefectsPerProduct || purchaseOrderProducts?.length === 1) {
      return !!this._defectsChecklistDTO.completions?.find((completion): boolean => !!completion.isCompleted);
    }

    if (purchaseOrderProductId) {
      return !!this._defectsChecklistDTO.completions?.find(
        (completion): boolean => !!completion.isCompleted && completion.purchaseOrderProductId === purchaseOrderProductId
      );
    }

    // In case this method is called without products data, we just look for one completion
    return (purchaseOrderProducts ?? [{}]).every(
      (product): boolean =>
        !!this._defectsChecklistDTO.completions?.find(
          (completion): boolean =>
            !!completion.isCompleted &&
            (!product.purchaseOrderProductId || completion.purchaseOrderProductId === product.purchaseOrderProductId)
        )
    );
  }

  public isCompletedAndValid(
    isDefectsPerProduct: boolean,
    action: WorkflowAnswerActionDefectDTO,
    purchaseOrderProducts: { purchaseOrderProductId?: number }[],
    purchaseOrderProductId?: QimaOptionalType<number>
  ): boolean {
    return (
      this.isMarkedAsComplete(isDefectsPerProduct, purchaseOrderProducts, purchaseOrderProductId) &&
      this.isValid(action, purchaseOrderProductId)
    );
  }
}
