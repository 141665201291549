// @ts-strict-ignore
import { WorkmanshipDTO } from '@library/dto/checklist/defect/defects-workmanship.dto';
import { PerProductAqlAndSamplingDTO } from '@library/dto/per-product-aql-and-sampling.dto';
import { CustomFieldInspectionAnswerPairDTO, CustomFieldInspectionAnswerPairTextDTO } from '@library/dto/workflow/custom-fields.dto';
import { CartonCalculationMethod } from '@library/dto-enums/carton-calculation-method.dto-enum';
import { CustomFieldCategory } from '@library/dto-enums/custom-field-category.dto-enum';
import { createWorkflowStepGroupAction } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/isp-create-workflow-step-grouped-action';
import { IspWorkflowStep } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/isp-workflow-step/isp-workflow-step';
import { IspWorkflowStepActionCustomFields } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/isp-workflow-step-action-custom-fields/isp-workflow-step-action-custom-fields';
import { IspWorkflowStepActionDefectsChecklist } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/isp-workflow-step-action-defects-checklist/isp-workflow-step-action-defects-checklist';
import { IspWorkflowStepActionFindingsSummaryReview } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/isp-workflow-step-action-findings-summary-review/isp-workflow-step-action-findings-summary-review';
import { IspWorkflowStepActionInspectionPreparation } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/isp-workflow-step-action-inspection-preparation/isp-workflow-step-action-inspection-preparation';
import { IspWorkflowStepActionMeasuresChecklist } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/isp-workflow-step-action-measures-checklist/isp-workflow-step-action-measures-checklist';
import { IspWorkflowStepActionSimplifiedMeasurementChecklist } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/isp-workflow-step-action-simplified-measurement-checklist/isp-workflow-step-action-simplified-measurement-checklist';
import { IspWorkflowStepActionTestsChecklist } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/isp-workflow-step-action-tests-checklist/isp-workflow-step-action-tests-checklist';
import { IspWorkflowStepActionType } from '@one/app/pages/isp/pages/inspection/pages/id/shared/models/isp-workflow-step-action.models';
import { IspWorkflowStepGroupActionType } from '@one/app/pages/isp/pages/inspection/pages/id/shared/models/isp-workflow-step-grouped-action.models';
import { IChecklistComment, IChecklistImageData } from '@one/app/shared/models/inspection-consultation/inspection-consultation.models';
import { WorkflowSamplingSizeType } from '@one/app/shared/models/sampling/sampling-size.models';
import {
  EWorkflowActionType,
  IWorkflow,
  IWorkflowInspectionFindingsSummaryReview,
  IWorkflowStep,
} from '@one/app/shared/models/workflow/workflow.models';
import { IWorkflowInspection, WorkflowIdType, WorkflowPathIdType } from '@one/app/shared/models/workflows/workflow.models';
import { QimaOptionalType } from '@qima/ngx-qima';
import { compact, flatMap, flatten, head, isArray } from 'lodash/index';

export const MISSING_INSPECTION_PREPARATION_ACTION_ERROR_MESSAGE = 'Could not find the inspection preparation step in the workflow';

/**
 * @description
 * Basically useful to create the {@link IWorkflow} but with extra logic
 */
export class IspWorkflow implements IWorkflow {
  private static _getSplitWorkflowPathId(id: Readonly<WorkflowPathIdType>): WorkflowIdType[] {
    return id.split('-');
  }

  public id: QimaOptionalType<number> = undefined;
  public name: string;
  public steps: IspWorkflowStep[] = [];
  public globalRemark: QimaOptionalType<IChecklistComment> = undefined;
  public globalImages: QimaOptionalType<IChecklistImageData[]> = undefined;
  public samplingSize: QimaOptionalType<WorkflowSamplingSizeType> = undefined;
  public findingsSummaryReview: QimaOptionalType<IWorkflowInspectionFindingsSummaryReview> = undefined;
  public pickedCartons: QimaOptionalType<number> = undefined;
  public totalCartons: QimaOptionalType<number> = undefined;
  public cartonsCalculationMethod = CartonCalculationMethod.SQUARE_ROOT_RULE;
  public comment: QimaOptionalType<string> = undefined;
  public workmanship: WorkmanshipDTO[] = [];
  public perProductAqlAndSampling: QimaOptionalType<PerProductAqlAndSamplingDTO[]> = undefined;

  public constructor(workflow: Readonly<IWorkflow>, inspection?: Readonly<IWorkflowInspection>) {
    this.id = workflow.id;
    this.name = workflow.name;
    this.steps = workflow.steps.map((step: IWorkflowStep): IspWorkflowStep => new IspWorkflowStep(step, inspection, workflow));
    this.globalRemark = workflow.globalRemark;
    this.globalImages = workflow.globalImages;
    this.samplingSize = workflow.samplingSize;
    this.findingsSummaryReview = workflow.findingsSummaryReview;
    this.pickedCartons = workflow.pickedCartons;
    this.totalCartons = workflow.totalCartons;
    this.cartonsCalculationMethod = workflow.cartonCalculationMethod;
    this.comment = workflow.comment;
    this.workmanship = workflow.workmanship;
    this.perProductAqlAndSampling = workflow.perProductAqlAndSampling;
  }

  public containsAction(type: Readonly<EWorkflowActionType>): boolean {
    return flatMap(this.steps, (step: IspWorkflowStep): IspWorkflowStepActionType[] => step.actions).some(
      (action: IspWorkflowStepActionType): boolean => action.type === type
    );
  }

  public getStepActions(type: EWorkflowActionType.TESTS_CHECKLIST): IspWorkflowStepActionTestsChecklist[];
  public getStepActions(type: EWorkflowActionType.DEFECTS_CHECKLIST): IspWorkflowStepActionDefectsChecklist[];
  public getStepActions(type: EWorkflowActionType.MEASURES_CHECKLIST): IspWorkflowStepActionMeasuresChecklist[];
  public getStepActions(type: EWorkflowActionType.SIMPLIFIED_MEASUREMENTS_CHECKLIST): IspWorkflowStepActionSimplifiedMeasurementChecklist[];
  public getStepActions(type: EWorkflowActionType.FINDINGS_SUMMARY_REVIEW): IspWorkflowStepActionFindingsSummaryReview[];
  public getStepActions(type: EWorkflowActionType.INSPECTION_PREPARATION): IspWorkflowStepActionInspectionPreparation[];
  public getStepActions(type: EWorkflowActionType.CUSTOM_FIELDS): IspWorkflowStepActionCustomFields[];
  public getStepActions(
    types: (
      | EWorkflowActionType.TESTS_CHECKLIST
      | EWorkflowActionType.DEFECTS_CHECKLIST
      | EWorkflowActionType.MEASURES_CHECKLIST
      | EWorkflowActionType.SIMPLIFIED_MEASUREMENTS_CHECKLIST
      | EWorkflowActionType.FINDINGS_SUMMARY_REVIEW
      | EWorkflowActionType.INSPECTION_PREPARATION
      | EWorkflowActionType.CUSTOM_FIELDS
    )[]
  ): IspWorkflowStepActionType[];

  public getStepActions(
    type:
      | EWorkflowActionType.TESTS_CHECKLIST
      | EWorkflowActionType.DEFECTS_CHECKLIST
      | EWorkflowActionType.MEASURES_CHECKLIST
      | EWorkflowActionType.SIMPLIFIED_MEASUREMENTS_CHECKLIST
      | EWorkflowActionType.FINDINGS_SUMMARY_REVIEW
      | EWorkflowActionType.INSPECTION_PREPARATION
      | EWorkflowActionType.CUSTOM_FIELDS
      | (
          | EWorkflowActionType.TESTS_CHECKLIST
          | EWorkflowActionType.DEFECTS_CHECKLIST
          | EWorkflowActionType.MEASURES_CHECKLIST
          | EWorkflowActionType.SIMPLIFIED_MEASUREMENTS_CHECKLIST
          | EWorkflowActionType.FINDINGS_SUMMARY_REVIEW
          | EWorkflowActionType.INSPECTION_PREPARATION
          | EWorkflowActionType.CUSTOM_FIELDS
        )[]
  ): IspWorkflowStepActionType[] {
    if (isArray(type)) {
      return flatten(
        type.map((actionType: Readonly<EWorkflowActionType>): IspWorkflowStepActionType[] => this._getStepActionsByType(actionType))
      );
    }

    return this._getStepActionsByType(type);
  }

  public getGroupedStepActions(types: EWorkflowActionType[]): IspWorkflowStepGroupActionType[] {
    return compact(
      types.map((actionType: Readonly<EWorkflowActionType>): IspWorkflowStepGroupActionType | null => {
        return createWorkflowStepGroupAction(this._getStepActionsByType(actionType));
      })
    );
  }

  /**
   * @param {Readonly<WorkflowPathIdType>} id A path id (e.g.: 1-1)
   * @returns {QimaOptionalType<IspWorkflowStepActionType>} The workflow step action
   */
  public getStepActionById(id: Readonly<WorkflowPathIdType>): QimaOptionalType<IspWorkflowStepActionType> {
    const splitWorkflowPathId: WorkflowIdType[] = IspWorkflow._getSplitWorkflowPathId(id);

    if (splitWorkflowPathId.length < 2) {
      throw Error('The workflow path id should contains the step id and the action id');
    }

    const step: IspWorkflowStep | undefined = this.steps.find(
      (step: Readonly<IspWorkflowStep>): boolean => step.id === head(splitWorkflowPathId)
    );

    if (!step) {
      return null;
    }

    return step.getActionById(splitWorkflowPathId[1]);
  }

  public getInspectionPreparationAction(): QimaOptionalType<IspWorkflowStepActionInspectionPreparation> {
    const inspectionPreparations: IspWorkflowStepActionInspectionPreparation[] = this.getStepActions(
      EWorkflowActionType.INSPECTION_PREPARATION
    );

    return head(inspectionPreparations);
  }

  public getCustomFieldsAction(): QimaOptionalType<IspWorkflowStepActionCustomFields> {
    const workflowStepActionCustomFields: IspWorkflowStepActionCustomFields[] = this.getStepActions(EWorkflowActionType.CUSTOM_FIELDS);

    return head(workflowStepActionCustomFields);
  }

  public getDefectsScoreCustomFieldValue(): string {
    return (
      this.getCustomFieldsAction()
        ?.getCustomFieldInspectionAnswerPairByCategories([CustomFieldCategory.INSPECTION])
        .find(
          (customField: CustomFieldInspectionAnswerPairDTO): customField is CustomFieldInspectionAnswerPairTextDTO =>
            customField.customField.label === 'Defects score' // 👈 this is a database key
        )?.answer?.value ?? ''
    );
  }

  private _getStepActionsByType(type: Readonly<EWorkflowActionType>): IspWorkflowStepActionType[] {
    return this.steps.reduce(
      (searchedStepAction: IspWorkflowStepActionType[], step: Readonly<IspWorkflowStep>): IspWorkflowStepActionType[] =>
        searchedStepAction.concat(step.actions.filter((action: Readonly<IspWorkflowStepActionType>): boolean => action.type === type)),
      []
    );
  }
}
