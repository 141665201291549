import {
  EQpDividerType,
  EQpDividerPosition,
  EQpDividerOrientation,
  EQpDividerColor,
} from '@library/components/qp-divider/qp-divider.models';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Input, ChangeDetectionStrategy, Component } from '@angular/core';

/**
 * @description
 * If you need to change the UI of the text from the ng-content
 * Apply it directly to the qp-divider with a class
 * This will apply the change as expected
 * @example
 *
 * ```html
 * <qp-divider class="hint"></qp-divider>
 * ```
 */
@Component({
  selector: 'qp-divider',
  templateUrl: './qp-divider.component.html',
  styleUrls: ['./qp-divider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, NgTemplateOutlet],
})
export class QpDividerComponent {
  /**
   * @description
   * Change the UI of the border style
   * @default {@link EQpDividerType.SOLID}
   * @type {EQpDividerType}
   */
  @Input()
  public type: EQpDividerType = EQpDividerType.SOLID;

  /**
   * @description
   * Define the content placement
   * @default {@link EQpDividerPosition.CENTER}
   * @type {EQpDividerPosition}
   */
  @Input()
  public position: EQpDividerPosition = EQpDividerPosition.CENTER;

  /**
   * @description
   * Define the axis orientation
   * @default {@link EQpDividerOrientation.HORIZONTAL}
   * @type {EQpDividerOrientation}
   */
  @Input()
  public orientation: EQpDividerOrientation = EQpDividerOrientation.HORIZONTAL;

  /**
   * @description
   * Define the color
   * @default {@link EQpDividerColor.SMOKE}
   * @type {EQpDividerColor}
   */
  @Input()
  public color: EQpDividerColor = EQpDividerColor.SMOKE;
}
