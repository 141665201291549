// @ts-strict-ignore
import { EInspectionResult } from '@one/app/pages/brd/pages/report/pages/inspection/pages/id/brd-report-inspection-id.models';
import { IspMeasuresSampleAnswer } from '@one/app/pages/isp/shared/classes/isp-measures-sample-answer/isp-measures-sample-answer';
import {
  IVariance,
  IMeasuresSampleAnswer,
  IMeasure,
  ISectionVariance,
} from '@one/app/pages/isp/shared/models/variances/inspection-variances.models';

export class IspVariance implements IVariance {
  public static fromIVariance(iVariance: IVariance | ISectionVariance): IspVariance {
    const variance = new IspVariance();

    variance.id = iVariance.id;
    variance.result = iVariance.result;
    variance.variance = iVariance.variance;
    variance.measuresEntries = iVariance.measuresEntries;

    if (IspVariance.isSectionVariance(iVariance)) {
      variance.sectionVarianceId = iVariance.sectionVarianceId;
    }

    return variance;
  }

  public static isSectionVariance(iVariance: IVariance | ISectionVariance): iVariance is ISectionVariance {
    return 'sectionVarianceId' in iVariance;
  }

  public variance: string;
  public measuresEntries: IMeasuresSampleAnswer[] = [];
  public measureModel: IMeasure[] = [];
  public numberOfEntries: number;
  public id: string;
  public sectionVarianceId: string;
  public result?: EInspectionResult;

  public get areAllMeasuresSampleAnswerDone(): boolean {
    return this.measuresEntries.every(
      (measuresSampleAnswer: IMeasuresSampleAnswer): boolean =>
        IspMeasuresSampleAnswer.fromIMeasuresSampleAnswer(measuresSampleAnswer).areAllMeasuresDone
    );
  }

  public get nbMeasuresSampleAnswerLeft(): number {
    return this.measuresEntries.reduce(
      (sum: number, entry: IMeasuresSampleAnswer): number => sum + IspMeasuresSampleAnswer.fromIMeasuresSampleAnswer(entry).nbMeasuresLeft,
      0
    );
  }

  public get nbMeasuresSampleAnswerDone(): number {
    return this.measuresEntries.reduce(
      (sum: number, entry: IMeasuresSampleAnswer): number => sum + IspMeasuresSampleAnswer.fromIMeasuresSampleAnswer(entry).nbMeasuresDone,
      0
    );
  }
}
