// @ts-strict-ignore
import { ChecklistRatingResultDTO } from '@library/dto/checklist/checklist-rating-result.dto';
import { ChecklistRatingDTO } from '@library/dto/checklist/checklist-rating.dto';
import { RatingTypes } from '@library/dto-enums/rating-types.dto-enum';
import { qpComputeWorstRatingIndex } from '@library/functions/ratings/qp-compute-worst-rating-index';
import { EQpQuestionType } from '@library/models/qp-question.models';
import { DEFAULT_RATING_RESULT } from '@library/models/qp-ratings.models';
import {
  QpTestsChecklistElementRatingFactory,
  QpTestsChecklistElementRatingType,
} from '@library/models/qp-tests-checklist-element-rating.models';
import {
  IQpMultipleChoiceElement,
  QpTestsChecklistElementFactory,
  QpTestsChecklistElementType,
} from '@library/models/qp-tests-checklist-element.models';
import { EInspectionResult } from '@one/app/pages/brd/pages/report/pages/inspection/pages/id/brd-report-inspection-id.models';
import { IspWorkflowStepAction } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/isp-workflow-step-action';
import { IspWorkflowStepTestsChecklistAction } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/isp-workflow-step-tests-checklist-action';
import { TestsChecklistElement } from '@one/app/shared/classes/checklist/tests-checklist-element';
import { EWorkflowActionType, IWorkflowAction } from '@one/app/shared/models/workflow/workflow.models';
import { isNil } from 'lodash/index';

const INITIAL_COUNT = 0;
const NO_RESULT_TYPES = [EQpQuestionType.TABLE, EQpQuestionType.TEXT, EQpQuestionType.DISTRIBUTION];

export class IspWorkflowStepActionTestsChecklist extends IspWorkflowStepAction {
  public type = EWorkflowActionType.TESTS_CHECKLIST;
  public testsChecklist: IspWorkflowStepTestsChecklistAction | undefined = undefined;
  public commentsMandatoryWhenNotApplicable: boolean = false;

  protected get _isOngoing(): boolean {
    let unfilledTestsCount = INITIAL_COUNT;

    this.testsChecklist.content.elements.forEach((element: Readonly<QpTestsChecklistElementType>): void => {
      const testsChecklistElement: TestsChecklistElement = TestsChecklistElement.fromITestsChecklistElement(element, this);

      unfilledTestsCount += testsChecklistElement.notFilledCounter;
    });

    return unfilledTestsCount !== INITIAL_COUNT;
  }

  public get ratingSystem(): ChecklistRatingDTO[] {
    return this.testsChecklist?.rating?.type === RatingTypes.RATINGS ? this.testsChecklist?.rating.ratings ?? [] : [];
  }

  public get ratingResult(): ChecklistRatingResultDTO {
    const ratingResults = this._getTestListRatingResult();
    const worstRatingIndex = qpComputeWorstRatingIndex(this.ratingSystem, ratingResults);

    return worstRatingIndex === -1 ? DEFAULT_RATING_RESULT : this.ratingSystem[worstRatingIndex];
  }

  public get result(): EInspectionResult {
    return this._getTestListResult(this.testsChecklist.content.elements);
  }

  public constructor(action: Readonly<IWorkflowAction>) {
    super(action);

    if (action.testsChecklist) {
      this.testsChecklist = new IspWorkflowStepTestsChecklistAction(action.testsChecklist);
    }

    this.commentsMandatoryWhenNotApplicable = action.commentsMandatoryWhenNotApplicable ?? false;
  }

  public getTotalTestsCount(): number {
    const allNonDivElements: QpTestsChecklistElementType[] = this.getAllNonDivElementsAndSubElements();

    return allNonDivElements.length;
  }

  public getNaTestCount(): number {
    const allNonDivElements: QpTestsChecklistElementType[] = this.getAllNonDivElementsAndSubElements();

    return allNonDivElements.filter(
      (element: QpTestsChecklistElementType): boolean => element.notApplicable && !isNil(element.comment?.message)
    ).length;
  }

  public getFailedTestsDetails(): QpTestsChecklistElementType[] {
    const allNonDivElements: QpTestsChecklistElementType[] = this.getAllNonDivElementsAndSubElements();

    return allNonDivElements.filter(
      (element: QpTestsChecklistElementType): boolean =>
        this._getTestResult(element) === EInspectionResult.FAIL && !element.excludeFromInspectionResult
    );
  }

  public getAllNonDivElementsAndSubElements<T extends QpTestsChecklistElementType | QpTestsChecklistElementRatingType>(): T[] {
    return (this.testsChecklist.content.elements as T[])
      .map((element: Readonly<T>): T[] => this._getAllNonDivElements<T>(element))
      .reduce((allElements: ReadonlyArray<T>, elements: ReadonlyArray<T>): T[] => allElements.concat(elements), []);
  }

  private _getTestListResult(elements: Readonly<QpTestsChecklistElementType>[]): EInspectionResult {
    const hasFail: boolean = elements.some(
      (element: Readonly<QpTestsChecklistElementType>): boolean =>
        this._getTestResult(element) === EInspectionResult.FAIL && !element.excludeFromInspectionResult
    );

    if (hasFail) {
      return EInspectionResult.FAIL;
    }

    const hasPass: boolean = elements.some(
      (element: Readonly<QpTestsChecklistElementType>): boolean => this._getTestResult(element) === EInspectionResult.PASS
    );

    if (hasPass) {
      return EInspectionResult.PASS;
    }

    return EInspectionResult.NOT_APPLICABLE;
  }

  private _getTestResult(element: Readonly<QpTestsChecklistElementType>): EInspectionResult {
    if (element.type === EQpQuestionType.DIV) {
      return this._getTestListResult(element.elements);
    }

    if (
      element.notApplicable ||
      (element.type === EQpQuestionType.MULTIPLE_CHOICE &&
        element.constraint.values.length === (<IQpMultipleChoiceElement>element.successCriteria).values.length)
    ) {
      return EInspectionResult.NOT_APPLICABLE;
    }

    if (NO_RESULT_TYPES.includes(element.type)) {
      return EInspectionResult.NO_RESULT;
    }

    // Avoid building empty value element
    if (isNil(element.value)) {
      return EInspectionResult.PASS;
    }

    return QpTestsChecklistElementFactory.buildElement(element).isPass() ? EInspectionResult.PASS : EInspectionResult.FAIL;
  }

  private _getTestListRatingResult(): ChecklistRatingResultDTO[] {
    return this.getAllNonDivElementsAndSubElements<QpTestsChecklistElementRatingType>().map(
      (element: Readonly<QpTestsChecklistElementRatingType>): ChecklistRatingResultDTO => this._getTestRatingResult(element)
    );
  }

  private _getTestRatingResult(element: Readonly<QpTestsChecklistElementRatingType>): ChecklistRatingResultDTO {
    if (NO_RESULT_TYPES.includes(element.type) || element.notApplicable || isNil(element.value)) {
      return {
        ...DEFAULT_RATING_RESULT,
      };
    }

    return (
      QpTestsChecklistElementRatingFactory.buildElement(
        element,
        this.testsChecklist?.rating.type === RatingTypes.RATINGS ? this.testsChecklist?.rating.ratings ?? [] : []
      )?.ratingResult ?? {
        ...DEFAULT_RATING_RESULT,
      }
    );
  }

  private _getAllNonDivElements<T extends QpTestsChecklistElementType | QpTestsChecklistElementRatingType>(element: Readonly<T>): T[] {
    if (element.type === EQpQuestionType.DIV) {
      return (
        (element.elements as T[])
          // @todo remove casting once ITestsChecklistElement is replaced by TestsChecklistElementType
          .map((element: Readonly<T>): T[] => this._getAllNonDivElements(element))
          .reduce((allElements: ReadonlyArray<T>, elements: ReadonlyArray<T>): T[] => allElements.concat(elements), [])
      );
    }

    return Array(element);
  }
}
