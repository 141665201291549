// @ts-strict-ignore
import { WorkflowDetailsDTO } from '@library/dto/workflow-details.dto';
import { InspectionMethod } from '@library/dto-enums/inspection-method.dto-enum';
import { InspectionStatus } from '@library/dto-enums/inspection-status.dto-enum';
import { IInspector } from '@one/app/pages/brd/pages/inspection/pages/consult/brd-inspection-consult.component.models';
import { IBrdInspectionReferenceSample, ISampleCollection } from '@one/app/pages/brd/pages/inspection/shared/models/brd-inspection.models';
import { EInspectionResult } from '@one/app/pages/brd/pages/report/pages/inspection/pages/id/brd-report-inspection-id.models';
import { IEntity } from '@one/app/pages/brd/shared/models/entity.model';
import { WorkflowTemplate } from '@one/app/shared/classes/workflow/workflow-template';
import { AqlDefectsType } from '@one/app/shared/models/defects/aql-defects.models';
import {
  IInspectionAdditionalInformation,
  IInspectionAudit,
  IInspectionCancellation,
  IInspectionConsultation,
  IInspectionManager,
} from '@one/app/shared/models/inspection-consultation/inspection-consultation.models';
import { IInspectionType } from '@one/app/shared/models/inspections/inspection.models';
import { IAbstractMultiProduct } from '@one/app/shared/models/products/product.models';
import { IMeasurementSamplingSize, SamplingSize } from '@one/app/shared/models/sampling/sampling-size.models';
import { IWorkflow } from '@one/app/shared/models/workflow/workflow.models';
import { IWorkflowTemplate } from '@one/app/shared/models/workflows/workflows-templates.models';
import { isEmpty } from 'lodash/index';

export class InspectionConsultation implements IInspectionConsultation {
  public static createFromInterface(inspection: IInspectionConsultation): InspectionConsultation {
    return new InspectionConsultation(
      inspection.plannedDate,
      inspection.shipmentDate,
      inspection.inspectionDate,
      inspection.entity,
      inspection.entityCountry,
      inspection.entityCity,
      inspection.inspectionType,
      inspection.typeLabel,
      inspection.status,
      inspection.brandName,
      inspection.products,
      inspection.workflow,
      inspection.isDefectsPerProduct,
      inspection.isAqlPerProduct,
      inspection.aqlDefects,
      inspection.initialAqlDefects,
      inspection.samplingSize,
      inspection.measurementsSamplingSize,
      inspection.unit,
      inspection.id,
      inspection.inspectionUuid,
      inspection.createdOffline,
      inspection.method,
      inspection.inspector,
      inspection.manager,
      inspection.workflowSnapshot,
      inspection.workflowDetails,
      inspection.audit,
      inspection.result,
      inspection.delegatedBy,
      inspection.supplierRequest,
      inspection.responsibleEntity,
      inspection.productionSiteEntity,
      inspection.brandId,
      inspection.cancellation,
      inspection.destinations,
      inspection.referenceSample,
      inspection.sampleCollection,
      inspection.additionalInformation
    );
  }

  private constructor(
    public plannedDate: Date,
    public shipmentDate: Date,
    public inspectionDate: Date,
    public entity: IEntity,
    public entityCountry: string,
    public entityCity: string,
    public inspectionType: IInspectionType,
    public typeLabel: string,
    public status: InspectionStatus,
    public brandName: string,
    public products: IAbstractMultiProduct[],
    public workflow: IWorkflow,
    public isDefectsPerProduct: boolean,
    public isAqlPerProduct: boolean,
    public aqlDefects: AqlDefectsType,
    public initialAqlDefects: AqlDefectsType,
    public samplingSize: SamplingSize,
    public measurementsSamplingSize: IMeasurementSamplingSize,
    public unit: string,
    public id: number,
    public inspectionUuid: string,
    public createdOffline: boolean,
    public method: InspectionMethod,
    public inspector: IInspector,
    public manager: IInspectionManager,
    public workflowSnapshot: IWorkflowTemplate,
    public workflowDetails: WorkflowDetailsDTO,
    public audit: IInspectionAudit,
    public result: EInspectionResult,
    public delegatedBy: IEntity,
    public supplierRequest: boolean,
    public responsibleEntity: IEntity,
    public productionSiteEntity: IEntity,
    public brandId: number,
    public cancellation: IInspectionCancellation,
    public destinations: IEntity[],
    public referenceSample?: IBrdInspectionReferenceSample,
    public sampleCollection?: ISampleCollection,
    public additionalInformation?: IInspectionAdditionalInformation
  ) {
    if (!isEmpty(workflowSnapshot?.content)) {
      this.workflowSnapshot = new WorkflowTemplate(workflowSnapshot);
    }
  }

  public get isPending(): boolean {
    return this.status === InspectionStatus.PENDING;
  }

  public get isAccepted(): boolean {
    return this.status === InspectionStatus.ACCEPTED;
  }

  public get isStarted(): boolean {
    return this.status === InspectionStatus.STARTED;
  }

  public get isCompleted(): boolean {
    return this.status === InspectionStatus.COMPLETED;
  }

  public get isAcceptedReport(): boolean {
    return this.status === InspectionStatus.ACCEPTED_REPORT;
  }

  public get isRejectedReport(): boolean {
    return this.status === InspectionStatus.REJECTED_REPORT;
  }
}
