import { getIamConfig } from '@one/app/shared/factories/iam-config.factory';
import { IAM_CONFIG } from '@one/app/shared/tokens/iam-config.token';
import { WINDOW } from '@one/app/shared/tokens/window.token';
import { FactoryProvider } from '@angular/core';

export const IAM_URL_PROVIDER: FactoryProvider = {
  deps: [WINDOW],
  provide: IAM_CONFIG,
  useFactory: getIamConfig,
};
