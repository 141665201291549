// @ts-strict-ignore
import { AutoUnsubscriber } from '@library/classes/auto-unsubscriber/auto-unsubscriber';
import { EQpBadgeColor, EQpBadgeSize, EQpBadgeType } from '@library/components/qp-badge/qp-badge.models';
import { QpDividerComponent } from '@library/components/qp-divider/qp-divider.component';
import { QpIconComponent } from '@library/components/qp-icon/qp-icon.component';
import { EQpIconName } from '@library/components/qp-icon/qp-icon.models';
import { QpFallbackImageDirective } from '@library/directives/qp-fallback-image/qp-fallback-image.directive';
import { EQpFallbackImageSource } from '@library/directives/qp-fallback-image/qp-fallback-image.models';
import { IQpDisplayedRoute } from '@library/models/qp-router.models';
import { CommonRoutes } from '@one/app/common-routes';
import { IAccount } from '@one/app/shared/models/account/account.models';
import { AuthorityLabelPipe } from '@one/app/shared/pipes/authority-label/authority-label.pipe';
import { AccountService } from '@one/app/shared/services/account/account.service';
import { DynamicSubnavRoutingStore } from '@one/app/shared/services/dynamic-subnav-routing-store/dynamic-subnav-routing-store.service';
import { DisplayedRoutesService } from '@one/app/shared/services/router/displayed-routes.service';
import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { RouterLinkActive, RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { QimaOptionalType } from '@qima/ngx-qima';
import { head, isEmpty, concat } from 'lodash/index';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { Observable } from 'rxjs';
import { switchMap, tap, filter } from 'rxjs/operators';

@Component({
  selector: 'one-mobile-main-navbar',
  templateUrl: './mobile-main-navbar.component.html',
  styleUrls: ['./mobile-main-navbar.component.scss'],
  standalone: true,
  imports: [
    NgFor,
    RouterLinkActive,
    RouterLink,
    QpIconComponent,
    NgIf,
    NzDrawerModule,
    QpDividerComponent,
    QpFallbackImageDirective,
    TranslateModule,
    AuthorityLabelPipe,
  ],
})
export class MobileMainNavbarComponent extends AutoUnsubscriber implements OnInit {
  public readonly numberOfMenuDisplayOnBottom = 5;

  public menuList: IQpDisplayedRoute[];
  public bottomMenuList: IQpDisplayedRoute[] = [];
  public expandMenuPrimary: IQpDisplayedRoute[] = [];
  public expandMenuSecondary: IQpDisplayedRoute[] = [];
  public isExpandMenuVisible: boolean;
  public readonly badgeSizes: typeof EQpBadgeSize = EQpBadgeSize;
  public readonly badgeTypes: typeof EQpBadgeType = EQpBadgeType;
  public readonly badgeColors: typeof EQpBadgeColor = EQpBadgeColor;
  public readonly commonRoutes: typeof CommonRoutes = CommonRoutes;
  public readonly iconNames: typeof EQpIconName = EQpIconName;
  public accountRoute: QimaOptionalType<IQpDisplayedRoute> = undefined;
  public defaultPictureUrl: EQpFallbackImageSource = EQpFallbackImageSource.PLACEHOLDER_USER;
  public account: QimaOptionalType<IAccount> = undefined;

  public constructor(
    private readonly _displayedRoutesService: DisplayedRoutesService,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _dynamicSubnavRoutingStore: DynamicSubnavRoutingStore,
    private readonly _accountService: AccountService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.registerSubscription(
      this._displayedRoutesService
        .getRoutes()
        .pipe(
          tap((entities: IQpDisplayedRoute[]): void => {
            this.accountRoute = head(entities.filter((route: IQpDisplayedRoute): boolean => route.routerLink === '/account'));

            if (entities) {
              // we sort the array for have the secondary menu at the end (for display them in priority in the expand menu)
              this.menuList = this._displayedRoutesService.sortMenu(entities);

              // if we have 5 or less menu we don't need a burger for expand menu (-1 for the sign out menu)
              if (this.menuList.length <= this.numberOfMenuDisplayOnBottom) {
                this.bottomMenuList = this.menuList;
              } else {
                // if we have more than 5 menu we display a burger with expandable menu
                // splice remove the first x elements of the previous array
                this.bottomMenuList = this.menuList.splice(0, this.numberOfMenuDisplayOnBottom - 1);
                this.expandMenuPrimary = this.menuList.filter(
                  (currentMenu: IQpDisplayedRoute): boolean => currentMenu.isSecondaryMenu !== true && currentMenu.routerLink !== '/account'
                );
                const secondaryMenuList: IQpDisplayedRoute[] = this.menuList.filter(
                  (currentMenu: IQpDisplayedRoute): boolean => currentMenu.isSecondaryMenu === true
                );

                this.expandMenuSecondary = concat(this.expandMenuSecondary, secondaryMenuList);
              }
            }
          }),
          switchMap((): Observable<IQpDisplayedRoute> => {
            return this._updateDynamicRoutes$();
          })
        )
        .subscribe()
    );

    this.registerSubscription(
      this._displayedRoutesService.getMiddleRoutes().subscribe((routes: IQpDisplayedRoute[]): void => {
        if (!isEmpty(routes)) {
          this.expandMenuSecondary = concat(this.expandMenuSecondary, routes);
        }
      })
    );

    void this._getIdentity();
  }

  public expandMenu(): void {
    this.isExpandMenuVisible = !this.isExpandMenuVisible;
  }

  private _updateDynamicRoutes$(): Observable<IQpDisplayedRoute> {
    return this._dynamicSubnavRoutingStore.routes$.pipe(
      filter((routes: IQpDisplayedRoute): boolean => this._dynamicSubnavRoutingStore.isCustomRoute(routes)),
      tap((routes: IQpDisplayedRoute): void => {
        this._updateMenus(routes);
        this._changeDetectorRef.detectChanges();
      })
    );
  }

  private _updateMenus(routes: IQpDisplayedRoute): void {
    const menuListIndex = this.menuList.findIndex((item): boolean => item.routerLink === routes.routerLink);
    const bottomMenuListIndex = this.bottomMenuList.findIndex((item): boolean => item.routerLink === routes.routerLink);
    const expandMenuPrimaryIndex = this.expandMenuPrimary.findIndex((item): boolean => item.routerLink === routes.routerLink);

    if (menuListIndex > 0) {
      this.menuList[menuListIndex] = routes;
    }

    if (bottomMenuListIndex > 0) {
      this.bottomMenuList[bottomMenuListIndex] = routes;
    }

    if (expandMenuPrimaryIndex > 0) {
      this.expandMenuPrimary[expandMenuPrimaryIndex] = routes;
    }
  }

  private async _getIdentity(): Promise<IAccount> {
    const account: IAccount = await this._accountService.identity();

    this.account = account;

    return account;
  }
}
