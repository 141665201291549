/**
 * @description This enum reflects `com.qima.platform.domain.enumeration.InspectionStatus.java`
 */
export enum InspectionStatus {
  FACTORY_REQUEST = 'FACTORY_REQUEST',
  PENDING = 'PENDING',
  FACTORY_INSPECTOR_PENDING = 'FACTORY_INSPECTOR_PENDING',
  DRAFT = 'DRAFT',
  INSPECTOR_TO_ASSIGN = 'INSPECTOR_TO_ASSIGN',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  STARTED = 'STARTED',
  COMPLETED = 'COMPLETED',
  REPORT_REVISION_REQUESTED = 'REPORT_REVISION_REQUESTED',
  REPORT_IN_REVISION = 'REPORT_IN_REVISION',
  REPORT_IN_REVISION_BY_SUPERVISOR = 'REPORT_IN_REVISION_BY_SUPERVISOR',
  CANCELLED = 'CANCELLED',

  // 👇 TO DELETE
  // These 3 statuses are no longer used in the backend
  // [PL-18792](https://qima.atlassian.net/browse/PL-18792)
  ACCEPTED_REPORT = 'ACCEPTED_REPORT',
  REJECTED_REPORT = 'REJECTED_REPORT',
  ACKNOWLEDGED = 'ACKNOWLEDGED',
  // 👆 TO DELETE
}
