// @ts-strict-ignore
import { ChecklistRatingDTO } from '@library/dto/checklist/checklist-rating.dto';
import { RatingTypes } from '@library/dto-enums/rating-types.dto-enum';
import { IDistributionValue } from '@library/models/distribution-chart/distribution-chart.model';
import { EQpQuestionType } from '@library/models/qp-question.models';
import { IQpNumberRatingsConstraint } from '@library/models/qp-tests-checklist-element-rating.models';
import { EQpConstraintCriterion, QpNumberConstraintType } from '@library/models/qp-tests-checklist-questions-constraints.models';
import { EInspectionResult } from '@one/app/pages/brd/pages/report/pages/inspection/pages/id/brd-report-inspection-id.models';
import { EYesNoNa } from '@one/app/pages/isp/pages/inspection/pages/id/pages/categories/pages/id/pages/test/pages/id/components/tests-checklist-element/tests-checklist-element-yes-no/models/isp-inspection-id-categories-id-test-id-yes-no-na.models';
import { SamplingSizeModel } from '@one/app/shared/classes/sampling/sampling-size-model';
import { Answer, MultipleChoiceAnswer } from '@one/app/shared/models/answer/answer.models';
import { IYesNoAnswer, IYesNoNaAnswer } from '@one/app/shared/models/answer/yes-no-answer.models';
import { IChecklistRequirement } from '@one/app/shared/models/checklist/checklist-requirement.models';
import {
  IAttachmentData,
  IChecklistComment,
  IChecklistImageData,
} from '@one/app/shared/models/inspection-consultation/inspection-consultation.models';
import { ESamplingSizeType } from '@one/app/shared/models/sampling/sampling-size.models';
import { WorkflowPathIdType } from '@one/app/shared/models/workflows/workflow.models';
import { difference, isEmpty } from 'lodash/index';

export interface IQpYesNoNaModel {
  values: EYesNoNa[];
  type?: RatingTypes.PASS_FAIL;
}

export interface IQpYesNoModel {
  value: EYesNoNa.YES | EYesNoNa.NO;
  type?: RatingTypes.PASS_FAIL;
}

export interface IQpMultipleChoiceElement {
  values: MultipleChoiceAnswer;
  type?: RatingTypes.PASS_FAIL;
}

export interface IQpAnswerForm {
  id: string;
  choice: string;
  isFail: boolean;
}

/**
 * @deprecated
 * It is now a common interface
 * Use instead {@link QpTestsChecklistElementType}
 * It provides a more accurate list of models
 */
export interface IQpTestsChecklistElement<Type> {
  comment?: IChecklistComment;
  constraint?: IQpConstraint;
  elements?: IQpTestsChecklistElement<Answer>[];
  expectedResult?: string;
  guidelines: object;
  id: string;
  images?: IChecklistImageData[];
  attachments?: IAttachmentData[];
  instruction?: string;
  instructionsDocuments?: string[];
  name: string;
  providedByQima?: boolean;
  excludeFromInspectionResult: boolean;
  qimaCertKey?: string;
  requirements?: IChecklistRequirement[];
  samplingSize?: SamplingSizeModel<ESamplingSizeType>;
  scans?: IChecklistImageData[];
  result?: EInspectionResult;
  successCriteria?: QpSuccessCriteriaType;
  ratingResult?: ChecklistRatingDTO;
  notApplicable: boolean;
  type: EQpQuestionType;
  value?: Type;
  commentsMandatoryWhenNotApplicable?: boolean;
}

/**
 * @todo
 * Once {@link IQpTestsChecklistElement} is totally removed we can rename it to ITestsChecklistElement
 */
export interface IQpTestsChecklistElementV2 {
  comment?: IChecklistComment;
  constraint?: IQpConstraint;
  elements?: QpTestsChecklistElementType[];
  expectedResult?: string;
  guidelines: object;
  id: string;
  images?: IChecklistImageData[];
  instruction?: string;
  instructionsDocuments?: string[];
  name: string;
  providedByQima?: boolean;
  excludeFromInspectionResult: boolean;
  qimaCertKey?: string;
  requirements?: IChecklistRequirement[];
  samplingSize?: SamplingSizeModel<ESamplingSizeType>;
  scans?: IChecklistImageData[];
  result?: EInspectionResult;
  successCriteria?: QpSuccessCriteriaType;
  notApplicable: boolean;
  type: EQpQuestionType;
  value?: Answer;
  attachments?: IAttachmentData[];
}

export interface IQpTestsChecklistElementYesNo extends IQpTestsChecklistElementV2 {
  elements: undefined;
  successCriteria: IQpYesNoModel;
  type: EQpQuestionType.YES_NO;
  value: IYesNoAnswer;
}

export interface IQpTestsChecklistElementYesNoNa extends IQpTestsChecklistElementV2 {
  elements: undefined;
  successCriteria?: IQpYesNoNaModel;
  type: EQpQuestionType.YES_NO_NA;
  value: IYesNoNaAnswer;
}

export interface IQpTestsChecklistElementMultipleChoice extends IQpTestsChecklistElementV2 {
  elements?: undefined;
  successCriteria: IQpMultipleChoiceElement;
  type: EQpQuestionType.MULTIPLE_CHOICE;
  value: MultipleChoiceAnswer;
  constraint: IQpConstraint;
}

export interface IQpTestsChecklistElementNumber extends IQpTestsChecklistElementV2 {
  elements: undefined;
  successCriteria: QpNumberConstraintType;
  type: EQpQuestionType.NUMBER;
  value: number;
}

export interface AverageNumberValue {
  average: number;
  values: number[];
}

export interface IQpTestsChecklistElementAverageNumber extends IQpTestsChecklistElementV2 {
  elements: undefined;
  successCriteria: QpNumberConstraintType;
  type: EQpQuestionType.AVERAGE_NUMBER;
  value: AverageNumberValue;
}

export interface IQpTestsChecklistElementBarcodeScan extends IQpTestsChecklistElementV2 {
  elements: undefined;
  type: EQpQuestionType.BARCODE_SCAN;
  value: undefined;
  scans: IChecklistImageData[];
}

export interface IQpTestsChecklistElementText extends IQpTestsChecklistElementV2 {
  elements: undefined;
  type: EQpQuestionType.TEXT;
  value: string;
}

export interface IQpTestsChecklistElementDiv extends IQpTestsChecklistElementV2 {
  type: EQpQuestionType.DIV;
  value: undefined;
}

export interface IQpTestsChecklistElementRatings extends IQpTestsChecklistElementV2 {
  type: EQpQuestionType.RATINGS;
}

export interface IQpTestsChecklistElementTable extends IQpTestsChecklistElementV2 {
  elements: undefined;
  type: EQpQuestionType.TABLE;
  value: IQpTestsChecklistElementTableValue;
}

export interface IQpTestsChecklistElementDistribution extends IQpTestsChecklistElementV2 {
  elements: undefined;
  type: EQpQuestionType.DISTRIBUTION;
  groupLabels?: string[];
  value: IQpTestsChecklistElementDistributionValue[];
}

export interface IQpTestsChecklistElementDistributionValue extends IDistributionValue {
  groupLabel: string;
  count: number;
}

export interface IQpTestsChecklistElementTableValue {
  header: IQpTestsChecklistElementTableHeader;
  rows?: IQpTestsChecklistElementTableRows[];
}

export interface IQpTestsChecklistElementTableHeader {
  columns: QpTestsChecklistElementTableColumnType[];
}

export interface IQpTestsChecklistElementTableRows {
  columns: QpTestsChecklistElementTableColumnType[];
}

export type QpTestsChecklistElementTableColumnType = IQpTestsChecklistElementText;

export type QpTestsChecklistElementType =
  | IQpTestsChecklistElementNumber
  | IQpTestsChecklistElementAverageNumber
  | IQpTestsChecklistElementYesNo
  | IQpTestsChecklistElementYesNoNa
  | IQpTestsChecklistElementText
  | IQpTestsChecklistElementBarcodeScan
  | IQpTestsChecklistElementDiv
  | IQpTestsChecklistElementMultipleChoice
  | IQpTestsChecklistElementRatings
  | IQpTestsChecklistElementDistribution
  | IQpTestsChecklistElementTable;

export type QpSuccessCriteriaType = QpNumberSuccessCriteriaType | IQpYesNoModel | IQpYesNoNaModel | IQpMultipleChoiceElement;

export type QpNumberSuccessCriteriaType = QpNumberConstraintType | IQpNumberRatingsConstraint;

export interface IQpConstraint extends IQpMultipleChoiceElement {
  maxAllowedAnswers: number;
}

export const DIV = 'DIV';

/**
 * @description TestsChecklistElementV2 is an 'final' element = not of type DIV
 */
export abstract class QpTestsChecklistElementV2 {
  public id: WorkflowPathIdType;

  protected constructor(id: WorkflowPathIdType) {
    this.id = id;
  }

  public abstract isPass(): boolean;
}

export class QpTestsChecklistYesNoElementV2 extends QpTestsChecklistElementV2 {
  private readonly _value: IYesNoAnswer;
  private readonly _successCriteria: IQpYesNoModel;

  public constructor(element: Readonly<IQpTestsChecklistElementYesNo>) {
    super(element.id);
    this._value = element.value;
    this._successCriteria = element.successCriteria;
  }

  public isPass(): boolean {
    const qpYesNoNaAnswer: EYesNoNa = this._value.value === true ? EYesNoNa.YES : EYesNoNa.NO;

    return qpYesNoNaAnswer === this._successCriteria?.value;
  }
}

export class QpTestsChecklistYesNoNaElementV2 extends QpTestsChecklistElementV2 {
  private readonly _value: IYesNoNaAnswer;
  private readonly _successCriteria: IQpYesNoNaModel;

  public constructor(element: Readonly<IQpTestsChecklistElementYesNoNa>) {
    super(element.id);
    this._value = element.value;
    this._successCriteria = element.successCriteria;
  }

  public isPass(): boolean {
    return this._successCriteria?.values.includes(this._value.value);
  }
}

export class QpTestsChecklistMultipleChoiceElementV2 extends QpTestsChecklistElementV2 {
  private readonly _value: MultipleChoiceAnswer;
  private readonly _successCriteria: IQpMultipleChoiceElement;

  public constructor(element: Readonly<IQpTestsChecklistElementMultipleChoice>) {
    super(element.id);
    this._value = element.value;
    this._successCriteria = element.successCriteria;
  }

  public isPass(): boolean {
    return isEmpty(difference(this._value, this._successCriteria?.values));
  }
}

export class QpTestsChecklistTextElementV2 extends QpTestsChecklistElementV2 {
  public constructor(element: Readonly<IQpTestsChecklistElementText>) {
    super(element.id);
  }

  public isPass(): boolean {
    return true;
  }
}

export class QpTestsChecklistDistributionElementV2 extends QpTestsChecklistElementV2 {
  public constructor(element: Readonly<IQpTestsChecklistElementDistribution>) {
    super(element.id);
  }

  public isPass(): boolean {
    return true;
  }
}

export class QpTestsChecklistTableElementV2 extends QpTestsChecklistElementV2 {
  public constructor(element: Readonly<IQpTestsChecklistElementTable>) {
    super(element.id);
  }

  public isPass(): boolean {
    return true;
  }
}

export class QpTestsChecklistBarCodeElementV2 extends QpTestsChecklistElementV2 {
  public constructor(element: Readonly<IQpTestsChecklistElementBarcodeScan>) {
    super(element.id);
  }

  public isPass(): boolean {
    return true;
  }
}

export abstract class QpTestsChecklistNumberElementV2 extends QpTestsChecklistElementV2 {
  protected _value: number;
  protected _successCriteria: QpNumberConstraintType;

  protected constructor(element: Readonly<IQpTestsChecklistElementNumber>) {
    super(element.id);
    this._value = element.value;
    this._successCriteria = element.successCriteria;
  }
}

export abstract class QpTestsChecklistAverageNumberElementV2 extends QpTestsChecklistElementV2 {
  protected _value: AverageNumberValue;
  protected _successCriteria: QpNumberConstraintType;

  protected constructor(element: Readonly<IQpTestsChecklistElementAverageNumber>) {
    super(element.id);
    this._value = element.value;
    this._successCriteria = element.successCriteria;
  }
}

export class QpTestsChecklistNumberGTElementV2 extends QpTestsChecklistNumberElementV2 {
  public constructor(element: Readonly<IQpTestsChecklistElementNumber>) {
    super(element);
  }

  public isPass(): boolean {
    return this._successCriteria?.values.every((value: Readonly<number>): boolean => this._value > value);
  }
}

export class QpTestsChecklistAverageNumberGTElementV2 extends QpTestsChecklistAverageNumberElementV2 {
  public constructor(element: Readonly<IQpTestsChecklistElementAverageNumber>) {
    super(element);
  }

  public isPass(): boolean {
    return this._successCriteria?.values.every((value: Readonly<number>): boolean => this._value.average > value);
  }
}

export class QpTestsChecklistNumberGTEElementV2 extends QpTestsChecklistNumberElementV2 {
  public constructor(element: Readonly<IQpTestsChecklistElementNumber>) {
    super(element);
  }

  public isPass(): boolean {
    return this._successCriteria?.values.every((value: Readonly<number>): boolean => this._value >= value);
  }
}

export class QpTestsChecklistAverageNumberGTEElementV2 extends QpTestsChecklistAverageNumberElementV2 {
  public constructor(element: Readonly<IQpTestsChecklistElementAverageNumber>) {
    super(element);
  }

  public isPass(): boolean {
    return this._successCriteria?.values.every((value: Readonly<number>): boolean => this._value.average >= value);
  }
}

export class QpTestsChecklistNumberLTElementV2 extends QpTestsChecklistNumberElementV2 {
  public constructor(element: Readonly<IQpTestsChecklistElementNumber>) {
    super(element);
  }

  public isPass(): boolean {
    return this._successCriteria?.values.every((value: Readonly<number>): boolean => this._value < value);
  }
}

export class QpTestsChecklistAverageNumberLTElementV2 extends QpTestsChecklistAverageNumberElementV2 {
  public constructor(element: Readonly<IQpTestsChecklistElementAverageNumber>) {
    super(element);
  }

  public isPass(): boolean {
    return this._successCriteria?.values.every((value: Readonly<number>): boolean => this._value.average < value);
  }
}

export class QpTestsChecklistNumberLTEElementV2 extends QpTestsChecklistNumberElementV2 {
  public constructor(element: Readonly<IQpTestsChecklistElementNumber>) {
    super(element);
  }

  public isPass(): boolean {
    return this._successCriteria?.values.every((value: Readonly<number>): boolean => this._value <= value);
  }
}

export class QpTestsChecklistAverageNumberLTEElementV2 extends QpTestsChecklistAverageNumberElementV2 {
  public constructor(element: Readonly<IQpTestsChecklistElementAverageNumber>) {
    super(element);
  }

  public isPass(): boolean {
    return this._successCriteria?.values.every((value: Readonly<number>): boolean => this._value.average <= value);
  }
}

export class QpTestsChecklistNumberBetweenElementV2 extends QpTestsChecklistNumberElementV2 {
  public constructor(element: Readonly<IQpTestsChecklistElementNumber>) {
    super(element);
  }

  public isPass(): boolean {
    return this._successCriteria?.values[0] < this._value && this._value < this._successCriteria?.values[1];
  }
}

export class QpTestsChecklistAverageNumberBetweenElementV2 extends QpTestsChecklistAverageNumberElementV2 {
  public constructor(element: Readonly<IQpTestsChecklistElementAverageNumber>) {
    super(element);
  }

  public isPass(): boolean {
    return this._successCriteria?.values[0] < this._value.average && this._value.average < this._successCriteria?.values[1];
  }
}

export class QpTestsChecklistNumberBetweenInclusiveElementV2 extends QpTestsChecklistNumberElementV2 {
  public constructor(element: Readonly<IQpTestsChecklistElementNumber>) {
    super(element);
  }

  public isPass(): boolean {
    return this._successCriteria?.values[0] <= this._value && this._value <= this._successCriteria?.values[1];
  }
}

export class QpTestsChecklistAverageNumberBetweenInclusiveElementV2 extends QpTestsChecklistAverageNumberElementV2 {
  public constructor(element: Readonly<IQpTestsChecklistElementAverageNumber>) {
    super(element);
  }

  public isPass(): boolean {
    return this._successCriteria?.values[0] <= this._value.average && this._value.average <= this._successCriteria?.values[1];
  }
}

export class QpTestsChecklistElementFactory {
  public static buildElement(element: Readonly<QpTestsChecklistElementType>): QpTestsChecklistElementV2 | never {
    switch (element.type) {
      case EQpQuestionType.YES_NO:
        return new QpTestsChecklistYesNoElementV2(element);
      case EQpQuestionType.YES_NO_NA:
        return new QpTestsChecklistYesNoNaElementV2(element);
      case EQpQuestionType.MULTIPLE_CHOICE:
        return new QpTestsChecklistMultipleChoiceElementV2(element);
      case EQpQuestionType.TEXT:
        return new QpTestsChecklistTextElementV2(element);
      case EQpQuestionType.DISTRIBUTION:
        return new QpTestsChecklistDistributionElementV2(element);
      case EQpQuestionType.BARCODE_SCAN:
        return new QpTestsChecklistBarCodeElementV2(element);
      case EQpQuestionType.NUMBER:
        return this._buildNumberElement(element);
      case EQpQuestionType.AVERAGE_NUMBER:
        return this._buildAverageNumberElement(element);
      case EQpQuestionType.TABLE:
        return new QpTestsChecklistTableElementV2(element);
      default:
        throw new Error(`Unexpected tests checklist element type: ${element.type}`);
    }
  }

  private static _buildNumberElement(element: IQpTestsChecklistElementNumber): QpTestsChecklistNumberElementV2 | never {
    switch (element.successCriteria.criteria) {
      case EQpConstraintCriterion.GT:
        return new QpTestsChecklistNumberGTElementV2(element);
      case EQpConstraintCriterion.GTE:
        return new QpTestsChecklistNumberGTEElementV2(element);
      case EQpConstraintCriterion.LT:
        return new QpTestsChecklistNumberLTElementV2(element);
      case EQpConstraintCriterion.LTE:
        return new QpTestsChecklistNumberLTEElementV2(element);
      case EQpConstraintCriterion.BETWEEN:
        return new QpTestsChecklistNumberBetweenElementV2(element);
      case EQpConstraintCriterion.BETWEEN_INCLUSIVE:
        return new QpTestsChecklistNumberBetweenInclusiveElementV2(element);
      default:
        throw new Error(`Unexpected tests checklist element number type: ${element.type}`);
    }
  }

  private static _buildAverageNumberElement(
    element: IQpTestsChecklistElementAverageNumber
  ): QpTestsChecklistAverageNumberElementV2 | never {
    switch (element.successCriteria.criteria) {
      case EQpConstraintCriterion.GT:
        return new QpTestsChecklistAverageNumberGTElementV2(element);
      case EQpConstraintCriterion.GTE:
        return new QpTestsChecklistAverageNumberGTEElementV2(element);
      case EQpConstraintCriterion.LT:
        return new QpTestsChecklistAverageNumberLTElementV2(element);
      case EQpConstraintCriterion.LTE:
        return new QpTestsChecklistAverageNumberLTEElementV2(element);
      case EQpConstraintCriterion.BETWEEN:
        return new QpTestsChecklistAverageNumberBetweenElementV2(element);
      case EQpConstraintCriterion.BETWEEN_INCLUSIVE:
        return new QpTestsChecklistAverageNumberBetweenInclusiveElementV2(element);
      default:
        throw new Error(`Unexpected tests checklist element number type: ${element.type}`);
    }
  }
}
