// @ts-strict-ignore
import { IMeasuresSampleAnswer, IMeasure } from '@one/app/pages/isp/shared/models/variances/inspection-variances.models';
import { isNil } from 'lodash/index';

export class IspMeasuresSampleAnswer {
  public static fromIMeasuresSampleAnswer(iMeasuresSampleAnswer: IMeasuresSampleAnswer): IspMeasuresSampleAnswer {
    const measureSampleAnswer = new IspMeasuresSampleAnswer();

    measureSampleAnswer.measureAnswers = iMeasuresSampleAnswer.measureAnswers;

    return measureSampleAnswer;
  }

  public measureAnswers: IMeasure[];

  public get areAllMeasuresDone(): boolean {
    return this.measureAnswers.every((measureAnswers: IMeasure): boolean => !isNil(measureAnswers.measure));
  }

  public get nbMeasuresLeft(): number {
    return this.measureAnswers.filter((measure: IMeasure): boolean => isNil(measure.measure)).length;
  }

  public get nbMeasuresDone(): number {
    return this.measureAnswers.filter((measure: IMeasure): boolean => !isNil(measure.measure)).length;
  }
}
