import { DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import {
  EQimaButtonIconStyle,
  EQimaButtonSize,
  EQimaButtonStyle,
  EQimaButtonWidth,
  EQimaIconName,
  QimaButtonIconModule,
  QimaButtonModule,
} from '@qima/ngx-qima';

@Component({
  selector: 'one-logout-confirm-modal',
  templateUrl: './logout-confirm-modal.component.html',
  styleUrls: ['./logout-confirm-modal.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [QimaButtonIconModule, QimaButtonModule, CommonModule, TranslateModule],
})
export class LogoutConfirmModalComponent implements OnDestroy {
  public readonly qimaIconNames: typeof EQimaIconName = EQimaIconName;
  public readonly qimaButtonSizes: typeof EQimaButtonSize = EQimaButtonSize;
  public readonly qimaButtonWidths: typeof EQimaButtonWidth = EQimaButtonWidth;
  public readonly qimaButtonStyles: typeof EQimaButtonStyle = EQimaButtonStyle;
  public readonly qimaButtonIconStyles: typeof EQimaButtonIconStyle = EQimaButtonIconStyle;

  private _isDialogConfirmed: boolean = false;

  public constructor(public dialogRef: DialogRef<boolean, LogoutConfirmModalComponent>) {}

  public ngOnDestroy(): void {
    if (!this._isDialogConfirmed) {
      this.dialogRef.close(false);
    }
  }

  public onButtonClick(isConfirm: boolean): void {
    this._isDialogConfirmed = true;
    this.dialogRef.close(isConfirm);
  }
}
