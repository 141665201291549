// @ts-strict-ignore
import { EQpProfile } from '@library/models/qp-profile.models';
import { CommonRoutes } from '@one/app/common-routes';
import { BrdRoutes } from '@one/app/pages/brd/brd-routes';
import { IspRoutes } from '@one/app/pages/isp/isp-routes';
import { Account } from '@one/app/shared/classes/accounts/account';
import { PermissionCheckerType } from '@one/app/shared/classes/permissions/permission-checker';
import { IAccount } from '@one/app/shared/models/account/account.models';
import { Permission } from '@one/app/shared/models/permission/permission.models';
import { AccountService } from '@one/app/shared/services/account/account.service';
import { AuthenticationService } from '@one/app/shared/services/auth/authentication.service';
import { StateStorageService } from '@one/app/shared/services/storage/state-storage.service';
import { Injectable, isDevMode } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree, Data } from '@angular/router';
import { QimaOptionalType } from '@qima/ngx-qima';
import { isEmpty, isFunction, cloneDeep, clone } from 'lodash/index';

/**
 * @description
 * Enable or disable the stacktrace
 * @todo
 * Could be improved to be a DI instead so that it can be used in prod builds and enabled/disabled per env
 * @type {boolean}
 */
const IS_DEBUG_ENABLED = false;

interface IRouteData extends Data {
  authorities?: QimaOptionalType<EQpProfile[]>;
  permissions?: QimaOptionalType<PermissionCheckerType>;
}

@Injectable({
  providedIn: 'root',
})
export class AuthoritiesGuard implements CanActivate {
  private static _isLoggerEnabled(): boolean {
    return isDevMode() && IS_DEBUG_ENABLED;
  }

  public constructor(
    private readonly _router: Router,
    private readonly _accountService: AccountService,
    private readonly _stateStorageService: StateStorageService,
    private readonly _authServerProvider: AuthenticationService
  ) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    this.log(`Can activate "${state.url}"?`).logGroup('Guard canActivate');

    return this._canActivate(route, state).finally((): void => {
      this.logGroupEnd();
    });
  }

  public log(message: Readonly<string>): AuthoritiesGuard {
    AuthoritiesGuard._isLoggerEnabled() && console.log(message);

    return this;
  }

  public logError(message: Readonly<string>): AuthoritiesGuard {
    AuthoritiesGuard._isLoggerEnabled() && console.error(message);

    return this;
  }

  public logGroup(groupLabel?: string): AuthoritiesGuard {
    AuthoritiesGuard._isLoggerEnabled() && console.group(groupLabel);

    return this;
  }

  public logGroupEnd(): AuthoritiesGuard {
    AuthoritiesGuard._isLoggerEnabled() && console.groupEnd();

    return this;
  }

  private _checkProfiles(profiles: EQpProfile[], url: Readonly<string>): Promise<boolean | UrlTree> {
    let destinationUrl: string = clone(url);

    return this._accountService.identity().then((account: QimaOptionalType<Account>): boolean | UrlTree => {
      if (account) {
        this.log('Account found => perform more checks')
          .logGroup('Guard _checkProfiles')
          .log(`Account profiles => ${account.profiles.join(', ')}`);

        if (isEmpty(profiles)) {
          this.log('No authorities => ok').logGroupEnd();

          return true;
        }

        if (profiles.includes(EQpProfile.ROLE_SUPERVISOR) && destinationUrl === CommonRoutes.selectBrand()) {
          this.log('Supervisor on select-brand page => ok').logGroupEnd();

          return true;
        }

        this.log(`Page authorities: ${profiles.join(', ')}`);
        const hasAnyAuthority: boolean = this._accountService.hasAnyAuthority(profiles);

        if (hasAnyAuthority) {
          this.log('Has any authority => ok').logGroupEnd();

          return true;
        }

        this.logError(`User has not any of the required authorities`);

        // Improve the navigation UX when someone authenticate from an authority to another
        // And comes from the wrong authority page
        // It will avoid to see the unauthorized page after a timeout
        if (this._shouldVisitInspectorHome(account) && destinationUrl.startsWith(BrdRoutes.root())) {
          // Any kind of inspector consulting brd pages
          destinationUrl = IspRoutes.home();
          this._stateStorageService.storeUrl(destinationUrl);
          this.log(
            `Inspector, Service provider inspector, QIMA inspector or auditor previously coming from brand => navigate to: "${destinationUrl}"`
          );
        } else if (account.hasServiceProviderRole() && destinationUrl.startsWith(IspRoutes.root())) {
          // A service provider role consulting isp pages
          destinationUrl = BrdRoutes.workflow();
          this._stateStorageService.storeUrl(destinationUrl);
          this.log(`Service provider previously coming from inspector => navigate to: "${destinationUrl}"`);
        } else if (
          (account.hasBrandRole() || account.hasSupervisorRole() || account.hasFactoryRole()) &&
          destinationUrl.startsWith(IspRoutes.root())
        ) {
          // Any other brand role consulting isp pages
          destinationUrl = BrdRoutes.home();
          this._stateStorageService.storeUrl(destinationUrl);
          this.log(`Brand, supervisor or factory previously coming from inspector => navigate to: "${destinationUrl}"`);
        } else {
          // No exception in the navigation
          destinationUrl = CommonRoutes.errorAccessDenied();
          this.log(`Not enough authorities => navigate to: "${destinationUrl}"`);
        }

        this.logGroupEnd();

        return this._router.createUrlTree([destinationUrl]);
      }

      // only show the login dialog, if the user hasn't logged in yet
      this._stateStorageService.storeUrl(destinationUrl);
      this.log(`No account => navigate to: "${destinationUrl}"`);

      this._authServerProvider.redirectToLogin();

      return false;
    });
  }

  private _shouldVisitInspectorHome(account: Account): boolean {
    return account.hasAnyInspectorRole() || account.hasAuditorRole();
  }

  private async _checkPermissions(requiredPermissionsFunction: QimaOptionalType<PermissionCheckerType>): Promise<true | UrlTree> {
    if (!isFunction(requiredPermissionsFunction)) {
      this.log('No required permissions => ok');

      return Promise.resolve(true);
    }

    const account: IAccount = await this._accountService.identity();
    const currentPermissions: Permission[] = account.permissions ?? [];
    const hasPermission: boolean = requiredPermissionsFunction(currentPermissions);

    if (!hasPermission) {
      const forbiddenUrl: string = CommonRoutes.forbidden();

      this.log(`Not enough permissions => navigate to: "${forbiddenUrl}"`);

      return Promise.resolve(this._router.createUrlTree([forbiddenUrl]));
    }

    this.log('Has valid permissions => ok');

    return Promise.resolve(true);
  }

  private _isAuthenticated(): Promise<boolean> {
    return this._authServerProvider.isAuthenticated();
  }

  private async _canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const routeData: IRouteData = cloneDeep(route.data);
    const isAuthenticated: boolean = await this._isAuthenticated();
    const { authorities, permissions } = routeData;

    // We need to call the checkLogin / and so the accountService.identity() function, to ensure,
    // that the client has a principal too, if they already logged in by the server.
    // This could happen on a page refresh.
    if (isAuthenticated) {
      this.log('Authenticated => check profiles and permissions').logGroup('Guard _canActivate');

      return Promise.all([this._checkProfiles(authorities, state.url), this._checkPermissions(permissions)]).then(
        ([profiles, permissions]: [true | UrlTree, true | UrlTree]): true | UrlTree => {
          if (profiles !== true) {
            this.log(`Profile not allowed => navigate to: "${profiles.toString()}"`).logGroupEnd();

            return profiles;
          } else if (permissions !== true) {
            this.log(`Not enough permissions => navigate to: "${permissions.toString()}"`).logGroupEnd();

            return permissions;
          }

          this.log('Profiles and permissions => ok').logGroupEnd();

          return true;
        }
      );
    }

    this.log(`Not authenticated => Redirect to login`);
    this._stateStorageService.storeUrl(state.url);
    this._authServerProvider.redirectToLogin();

    return Promise.resolve(false);
  }
}
