// @ts-strict-ignore
import { IQpScriptCreateData, QpScriptAttributeValueType } from '@library/classes/qp-script/qp-script.models';
import { DOCUMENT } from '@angular/common';
import { Inject, Renderer2, RendererFactory2 } from '@angular/core';
import { QimaOptionalType } from '@qima/ngx-qima';
import { isEmpty, isNil, isFunction } from 'lodash/index';

export class QpScript {
  /**
   * @internal
   * @type {QimaOptionalType<Renderer2>}
   * @default undefined
   */
  public renderer: QimaOptionalType<Renderer2> = undefined;
  private _htmlScriptElement: QimaOptionalType<HTMLScriptElement> = undefined;

  /**
   * @description
   * Throw an error on purpose because it should not be called if it was not created yet!
   * Use the {@link create} method!
   * @returns {HTMLScriptElement} The html script element
   */
  public get htmlScriptElement(): HTMLScriptElement | never {
    if (isNil(this._htmlScriptElement)) {
      throw new Error('QpScript => accessing a nil html script element');
    }

    return this._htmlScriptElement;
  }

  public set htmlScriptElement(htmlScriptElement: HTMLScriptElement) {
    this._htmlScriptElement = htmlScriptElement;
  }

  public constructor(rendererFactory: RendererFactory2, @Inject(DOCUMENT) private readonly _document: Document) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  public create(source?: QimaOptionalType<string>, data?: Partial<IQpScriptCreateData>): QpScript {
    const htmlScriptElement: HTMLScriptElement = this.renderer.createElement('script');

    if (!isEmpty(source)) {
      htmlScriptElement.src = source;
    }

    if (!isNil(data)) {
      if (!isEmpty(data.innerHTML)) {
        htmlScriptElement.innerHTML = data.innerHTML;
      }

      if (isFunction(data.onLoad)) {
        htmlScriptElement.onload = data.onLoad;
      }

      if (isFunction(data.onError)) {
        htmlScriptElement.onerror = data.onError;
      }
    }

    htmlScriptElement.type = 'text/javascript';

    this.htmlScriptElement = htmlScriptElement;

    return this;
  }

  public addAttribute(name: Readonly<string>, value: Readonly<QpScriptAttributeValueType> = ''): QpScript {
    this.htmlScriptElement.setAttribute(name, value.toString());

    return this;
  }

  public load(): QpScript {
    this.renderer.appendChild(this._document.body, this.htmlScriptElement);

    return this;
  }
}
