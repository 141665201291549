<a
  (nzVisibleChange)="isVisibleChanged($event)"
  [nzDropdownMenu]="menu"
  [nzVisible]="isVisible"
  [qpButtonSize]="buttonSizes.MEDIUM"
  [qpButtonType]="buttonTypes.HERO_BLUE"
  nz-dropdown
  qpButton
>
  <div class="creationButton">
    <span class="interactive-1">{{ buttonLabel | translate }}</span>
    <em class="icon" nz-icon nzType="ic-chevrondown"></em>
  </div>
</a>
<nz-dropdown-menu #menu="nzDropdownMenu" class="dropdown-nav">
  <ul [class.dropdown-nav__hidden]="!isVisible" class="dropDownMenu" nz-menu>
    <li
      (click)="redirectTo(displayedRoute.routerLink)"
      *ngFor="let displayedRoute of displayedRoutes; last as isLast"
      [ngClass]="isLast ? 'dropDownMenu_item last' : 'dropDownMenu_item'"
      nz-menu-item
    >
      <em *ngIf="displayedRoute.iconName" [nzType]="displayedRoute.iconName" class="dropDownMenu_item--icon" nz-icon></em>
      <span class="dropDownMenu_item--label">{{ displayedRoute.title | translate }}</span>
    </li>
  </ul>
</nz-dropdown-menu>
