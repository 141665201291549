import { QpLocaleService } from '@library/services/qp-locale/qp-locale.service';

/**
 * @description
 * Factory that will initialize the locale
 * @param {QpLocaleService} qpLocaleService The locale service to initialize
 * @returns {() => void} Function that initialize the locale service
 */
export function localeInitFactory(qpLocaleService: Readonly<QpLocaleService>): () => void {
  return (): void => {
    qpLocaleService.init();
  };
}
