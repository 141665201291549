import { EQpBadgeColor, EQpBadgeSize, EQpBadgeType } from '@library/components/qp-badge/qp-badge.models';
import { EQpDividerOrientation } from '@library/components/qp-divider/qp-divider.models';
import { QpDropdownNavComponent } from '@library/components/qp-dropdown-nav/qp-dropdown-nav.component';
import { EQpButtonDirectiveSize, EQpButtonDirectiveType, QpButtonDirective } from '@library/directives/qp-button/qp-button.directive';
import { EQpProfile } from '@library/models/qp-profile.models';
import { IQpDisplayedRoute } from '@library/models/qp-router.models';
import { QpResponsiveService } from '@library/services/qp-responsive/qp-responsive.service';
import { NotifuseNavComponent } from '@one/app/components/notifuse-nav/notifuse-nav.component';
import { QuickSearchComponent } from '@one/app/components/subnav/quick-search/quick-search.component';
import { SubnavBreadcrumbsModule } from '@one/app/components/subnav-breadcrumbs/subnav-breadcrumbs.module';
import { Account } from '@one/app/shared/classes/accounts/account';
import { IAccount } from '@one/app/shared/models/account/account.models';
import { EMenuPosition } from '@one/app/shared/models/menu/menu.models';
import { AccountService } from '@one/app/shared/services/account/account.service';
import { DynamicSubnavRoutingStore } from '@one/app/shared/services/dynamic-subnav-routing-store/dynamic-subnav-routing-store.service';
import { DisplayedRoutesService } from '@one/app/shared/services/router/displayed-routes.service';
import { CommonModule } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { QimaOptionalType, EQimaIconName, QimaIconModule } from '@qima/ngx-qima';
import { isEmpty, isFunction, isNil } from 'lodash/index';
import { Observable, of, switchMap } from 'rxjs';
import { distinctUntilChanged, map, take, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'one-subnav',
  standalone: true,
  imports: [
    CommonModule,
    QimaIconModule,
    RouterModule,
    TranslateModule,
    NotifuseNavComponent,
    QuickSearchComponent,
    SubnavBreadcrumbsModule,
    QpDropdownNavComponent,
    QpButtonDirective,
  ],
  templateUrl: './subnav.component.html',
  styleUrls: ['./subnav.component.scss'],
})
export class SubnavComponent implements OnInit, AfterViewChecked {
  public currentMenuPosition = EMenuPosition.COLLAPSE;
  public readonly menuPositions = EMenuPosition;
  public readonly dividerOrientations: typeof EQpDividerOrientation = EQpDividerOrientation;
  public subMenu: QimaOptionalType<IQpDisplayedRoute> = undefined;
  public subRoutesDisplayed = false;
  public isInspector = false;
  public isBrand = false;
  public isSupervisor = false;
  public creationRoutes: IQpDisplayedRoute[] = [];
  public actionMenu: IQpDisplayedRoute[] = [];
  public shouldDisplayQuickSearch = false;
  public readonly badgeSizes = EQpBadgeSize;
  public readonly badgeTypes = EQpBadgeType;
  public readonly badgeColors = EQpBadgeColor;
  public readonly buttonSizes: typeof EQpButtonDirectiveSize = EQpButtonDirectiveSize;
  public readonly buttonTypes: typeof EQpButtonDirectiveType = EQpButtonDirectiveType;
  public readonly iconNames: typeof EQimaIconName = EQimaIconName;
  private readonly LEAF_PAGE_REGEX = /(\d|details)$/;

  public get isNotifuseUser(): boolean {
    return !isEmpty(this._accountService.getNotifuseUserId());
  }

  public constructor(
    private readonly _displayedRoutesService: DisplayedRoutesService,
    private readonly _router: Router,
    private readonly _dynamicSubnavRoutingStore: DynamicSubnavRoutingStore,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _accountService: AccountService,
    private readonly _qpResponsiveService: QpResponsiveService
  ) {}

  public ngAfterViewChecked(): void {
    this._accountService
      .getAccount$()
      .pipe(untilDestroyed(this))
      .subscribe((iAccount: QimaOptionalType<IAccount>): void => {
        if (iAccount) {
          const account = Account.create(iAccount);

          this._setUserRoles(account);

          this.shouldDisplayQuickSearch = account.hasBrandRole() || account.hasSupervisorRole();
          this._changeDetectorRef.detectChanges();
        }
      });
  }

  public ngOnInit(): void {
    this._displayedRoutesService.menuPosition$.pipe(untilDestroyed(this)).subscribe({
      next: (menuPosition: EMenuPosition): void => {
        this.currentMenuPosition = menuPosition;
      },
    });
    this.calculateRoutes();
    // We need to re-check the displayed routes on route change
    this._router.events.pipe(untilDestroyed(this)).subscribe({
      next: (event): void => {
        if (event instanceof NavigationEnd) {
          this.calculateRoutes();
        }
      },
    });
    this._dynamicSubnavRoutingStore.routes$
      .pipe(
        switchMap((routes: IQpDisplayedRoute): Observable<QimaOptionalType<IQpDisplayedRoute>> => {
          if (this._dynamicSubnavRoutingStore.isCustomRoute(routes)) {
            return this._getRoutes$().pipe(
              tap({
                next: (): void => {
                  const saveCreationButtonLabel = this.subMenu ? this.subMenu.creationButtonLabel : '';

                  this.subMenu = routes;
                  this._calculateActions();
                  this._updateActions();
                  this.subRoutesDisplayed = this.shouldDisplaySubRoutes();
                  this.subMenu.creationButtonLabel = saveCreationButtonLabel;
                  this._changeDetectorRef.detectChanges();
                },
              })
            );
          }

          return of(null);
        }),
        untilDestroyed(this)
      )
      .subscribe();
    this._accountService
      .getAccount$()
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe((account: QimaOptionalType<IAccount>): void => {
        if (account) {
          this._setUserRoles(Account.create(account));
        }
      });
  }

  public calculateRoutes(): void {
    this._getRoutes$()
      .pipe(take(1), untilDestroyed(this))
      .subscribe((subMenu): void => {
        if (isNil(subMenu) && !this.isInspector) {
          this.subMenu = null;

          return;
        }

        this.subMenu = subMenu;

        if (subMenu?.children) {
          this._calculateActions();
          this._updateActions();
          this.creationRoutes = this.actionMenu;
          this._displayedRoutesService.isSubmenuActive$.next(true);
        } else {
          this._displayedRoutesService.isSubmenuActive$.next(false);
        }

        this.subRoutesDisplayed = this.shouldDisplaySubRoutes();
      });
  }

  public shouldDisplaySubRoutes(): boolean {
    if (this.subMenu) {
      if (this._accountService.isInspector()) {
        this._displayedRoutesService.setInspectorInspectionsStatistics$().pipe(untilDestroyed(this)).subscribe();
      }

      if (this.LEAF_PAGE_REGEX.test(this._router.url.split('?')[0])) {
        return false;
      }

      if (this.subMenu.children && !this.subMenu.isSubMenuHidden) {
        const activatedSubRoute = this.subMenu.children.find((route): boolean =>
          this._router.isActive(route.routerLink, route.routerLinkActiveOption.exact)
        );

        return activatedSubRoute !== undefined;
      }
    }

    return false;
  }

  public get isNotMobile(): boolean {
    return !this._qpResponsiveService.isMobile();
  }

  public getAllChildrenActions(route: QimaOptionalType<IQpDisplayedRoute>, actionRoutes: IQpDisplayedRoute[]): IQpDisplayedRoute[] {
    if (route) {
      if (route.children && !isEmpty(route.children)) {
        route.children.forEach((childRoute: IQpDisplayedRoute): void => {
          actionRoutes = this.getAllChildrenActions(childRoute, actionRoutes);
        });
      } else {
        if (route.isDefaultAction) {
          actionRoutes.push(route);
        }
      }
    }

    return actionRoutes;
  }

  public onSubNavLinkClick(): void {
    if (this.subMenu && isFunction(this.subMenu.creationButtonClickCallback)) {
      this.subMenu.creationButtonClickCallback();
    }
  }

  public onSubRouteClick(subEntity: IQpDisplayedRoute): void {
    subEntity.isCounterDisplayed && subEntity.subNavButtonClickCallback?.().pipe(untilDestroyed(this)).subscribe();
  }

  private _calculateActions(): void {
    this.actionMenu = this.getAllChildrenActions(this.subMenu, []);
  }

  private _updateActions(): void {
    if (this.subMenu) {
      this.subMenu.defaultActionRoutes = this.actionMenu;
      this.subMenu.isDefaultAction = this.actionMenu.length > 0 ? true : this.subMenu.isDefaultAction;
    }
  }

  private _getRoutes$(): Observable<QimaOptionalType<IQpDisplayedRoute>> {
    return this._displayedRoutesService.getRoutes().pipe(
      switchMap(
        (routes: IQpDisplayedRoute[]): Observable<IQpDisplayedRoute[]> =>
          this._displayedRoutesService
            .getMiddleRoutes()
            .pipe(map((middleRoutes: IQpDisplayedRoute[]): IQpDisplayedRoute[] => [...routes, ...middleRoutes]))
      ),
      map((routes: IQpDisplayedRoute[]): QimaOptionalType<IQpDisplayedRoute> => {
        if (!isEmpty(routes)) {
          return routes.find((route: IQpDisplayedRoute): boolean => {
            return this._router.isActive(route.routerLink, route.routerLinkActiveOption.exact);
          });
        }

        return null;
      })
    );
  }

  private _setUserRoles(account: Account): void {
    if (account?.profiles) {
      this.isInspector = account.profiles.some((accountProfile: EQpProfile): boolean => {
        return [EQpProfile.ROLE_INSPECTOR, EQpProfile.ROLE_SERVICE_PROVIDER_INSPECTOR].includes(accountProfile);
      });
      this.isSupervisor = account.profiles.includes(EQpProfile.ROLE_SUPERVISOR);
      this.isBrand = account.profiles.includes(EQpProfile.ROLE_BRAND);
    } else {
      this.isInspector = false;
      this.isSupervisor = false;
      this.isBrand = false;
    }
  }
}
