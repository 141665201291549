import { isString } from 'lodash/index';

/**
 * @description
 * Used to define the GTM Auth
 * Based on the environments variable injection in the index file
 *
 * @param {Window} window The window object containing possibly the "googleTagManagerAuth" variable
 *
 * @returns {string} Return the GTM Auth if it exists or null otherwise
 */
export function googleTagManagerAuthFactory(window: Window): string | null {
  const gtmAuth = window['googleTagManagerAuth'];

  if (isString(gtmAuth)) {
    return gtmAuth;
  }

  return null;
}
