<div class="modal" data-cy="logout-confirm-modal">
  <qima-button-icon
    [qimaButtonIconName]="qimaIconNames.CLOSE"
    [qimaButtonIconStyle]="qimaButtonIconStyles.TERTIARY_UTILITY"
    (qimaButtonIconClick)="onButtonClick(false)"
    class="modal__close"
  ></qima-button-icon>

  <div class="modal__header">{{ 'logout-confirm-modal.discard-pictures-question' | translate }}</div>
  <div class="modal__body">
    {{ 'logout-confirm-modal.elements-to-synchronize' | translate }}
  </div>

  <div class="modal__footer">
    <qima-button
      [qimaButtonStyle]="qimaButtonStyles.SECONDARY"
      [qimaButtonSize]="qimaButtonSizes.LARGE"
      [qimaButtonLabel]="'logout-confirm-modal.no' | translate"
      [qimaButtonWidth]="qimaButtonWidths.FULL"
      (qimaButtonClick)="onButtonClick(false)"
      class="modal__footer-button"
      data-cy="modal-reject-button"
    ></qima-button>

    <qima-button
      [qimaButtonStyle]="qimaButtonStyles.PRIMARY_DESTRUCTIVE"
      [qimaButtonSize]="qimaButtonSizes.LARGE"
      [qimaButtonLabel]="'logout-confirm-modal.yes' | translate"
      [qimaButtonWidth]="qimaButtonWidths.FULL"
      (qimaButtonClick)="onButtonClick(true)"
      class="modal__footer-button"
      data-cy="modal-accept-button"
    ></qima-button>
  </div>
</div>
