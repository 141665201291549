/**
 * @description This enum reflects `com.qima.platform.service.dto.testschecklist.answer.success.enumeration.NumberUnit`
 */
export enum NumberUnit {
  CM = 'CM',
  INCH = 'INCH',
  PCS = 'PCS',
  KG = 'KG',
  G = 'G',
  PERCENT = 'PERCENT',
  PSI = 'PSI',
  KGCM2 = 'KGCM2',
  SHORE = 'SHORE',
  GRMM = 'GRMM',
  C = 'C',
  F = 'F',
  MM = 'MM',
  CT = 'CT',
  GR = 'GR',
  LB = 'LB',
  OZ = 'OZ',
  SCALE = 'SCALE',
}
