import { IQpAuthenticationCredentials } from '@library/models/auth/qp-auth.models';
import { HttpErrorResponse } from '@angular/common/http';
import { QimaOptionalType } from '@qima/ngx-qima';
import { Observable } from 'rxjs';

export abstract class AuthenticationService {
  public abstract getToken$(): Observable<QimaOptionalType<string>>;

  public abstract login$(credentials: IQpAuthenticationCredentials): Observable<string | never>;

  public abstract loginAsBrand$(brandId: Readonly<number>): Observable<string>;

  public abstract loginAsEntity$(entityId: Readonly<number>): Observable<string>;

  public abstract loginAsInspector$(inspectorId: Readonly<number>): Observable<string>;

  public abstract storeAuthenticationToken(jwt, rememberMe): void;

  public abstract removeAuthenticationToken(): void;

  public abstract storeQimaConnectTokens(accessToken: Readonly<string>, refreshToken: Readonly<string>, clientId: Readonly<string>);

  public abstract hasQimaConnectSession(): boolean;

  public abstract getQimaConnectToken$(): Observable<string>;

  public abstract isAuthenticated(): Promise<boolean>;

  public abstract logout(): Promise<void>;

  public abstract redirectToLogin(): void;

  public abstract handleUnauthorized(error: HttpErrorResponse): void;

  public abstract setupAuthentication(): Promise<void>;

  public abstract hasRootSessionToken(): boolean;
  public abstract logBackWithRootSessionToken(): void;
}
