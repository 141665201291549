/**
 * @description This enum reflects `com.qima.platform.domain.enumeration.AqlValue`
 */
export enum AqlValue {
  NOT_ALLOWED = 'not-allowed',
  AQL_0_065 = '0.065',
  AQL_0_1 = '0.10',
  AQL_0_15 = '0.15',
  AQL_0_25 = '0.25',
  AQL_0_4 = '0.40',
  AQL_0_65 = '0.65',
  AQL_1_0 = '1.0',
  AQL_1_5 = '1.5',
  AQL_2_5 = '2.5',
  AQL_4_0 = '4.0',
  AQL_6_5 = '6.5',
}
