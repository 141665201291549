import { HomeComponent } from '@one/app/components/home/home.component';
import { HOME_ROUTE } from '@one/app/components/home/home.route';
import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [RouterModule.forChild([HOME_ROUTE]), CommonModule, TranslateModule.forChild(), HomeComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class HomeModule {}
