import { QpLoggerService } from '@library/services/qp-logger/qp-logger.service';
import { QpNotificationBarService } from '@library/services/qp-notification-bar/qp-notification-bar.service';
import { IAccount } from '@one/app/shared/models/account/account.models';
import { AccountService } from '@one/app/shared/services/account/account.service';
import { DatabaseDexie } from '@one/app/shared/services/database/database-dexie';
import { QimaOptionalType } from '@qima/ngx-qima';
import { isNil } from 'lodash/index';
import { Subject } from 'rxjs';

const STORAGE_QUOTA_WARNING_THRESHOLD: number = 0.9;

export abstract class DatabaseAbstract {
  public isDBInit$ = new Subject<boolean>();

  protected _databaseName: string = '';
  protected _db: QimaOptionalType<DatabaseDexie>;

  protected constructor(
    protected readonly _accountService: AccountService,
    protected readonly _qpNotificationBarService: QpNotificationBarService,
    protected readonly _qpLoggerService: QpLoggerService
  ) {
    this._accountService.getAccount$().subscribe((account: QimaOptionalType<IAccount>): void => {
      if (account?.email) {
        this._databaseName = account.email;
        this.initDB();
      }
    });
  }

  public verifyStorageRemainingSpace(): Promise<void> {
    return this._checkStorage(false, true);
  }

  protected get _database(): QimaOptionalType<DatabaseDexie> {
    const backendDb = this._db?.backendDB();

    if (!backendDb) {
      this._qpLoggerService.info('IndexedDB not initialized. Creating Dexie instance.');
      this.initDB();

      return this._db;
    }

    try {
      // Check if connection to indexedDB did not close in the meantime
      backendDb.transaction('inspection').abort();
    } catch (transactionError) {
      const error: Error = this._closeDbUnexpectedly(transactionError as Error);

      this._qpLoggerService.error(`IndexedDB was unexpectedly closed: ${error.message}. Recreating Dexie instance.`);

      this.initDB();
    }

    return this._db;
  }

  public initDB(): void {
    void this._checkStorage();
    this._db = new DatabaseDexie(this._databaseName);
    this.isDBInit$.next(true);
  }

  protected _displayErrorNotification(error: { name?: string; inner?: Error }, defaultErrorKey?: string): void {
    if (error.name === 'QuotaExceededError' || error?.inner?.name === 'QuotaExceededError') {
      this._qpNotificationBarService.error('inspection.validate.error.insufficient-storage');
    } else if (defaultErrorKey) {
      this._qpNotificationBarService.error(defaultErrorKey);
    }

    this._qpLoggerService.error(error);
  }

  private _closeDbUnexpectedly(error: Error): Error {
    try {
      this._db?.close();
    } catch (closeError: unknown) {
      error.message = `${error.message}. ${closeError instanceof Error ? closeError.message : ''}`;
    }

    return error;
  }

  private async _checkStorage(shouldLogStorage: boolean = true, shouldWarnUserIfNeeded: boolean = false): Promise<void> {
    if (!navigator.storage?.estimate) {
      return Promise.resolve();
    }

    const { quota, usage } = await navigator.storage.estimate();

    if (!isNil(usage) && !isNil(quota) && usage / quota > STORAGE_QUOTA_WARNING_THRESHOLD) {
      this._qpLoggerService.warn(`Storage: using ${usage} out of ${quota} bytes.`);

      if (shouldWarnUserIfNeeded) {
        this._qpNotificationBarService.warning('inspection.validate.warning.insufficient-storage');
      }
    } else if (shouldLogStorage) {
      this._qpLoggerService.info(`Storage: using ${usage} out of ${quota} bytes.`);
    }
  }
}
