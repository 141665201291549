import { ServiceProviderClientElasticDTO } from '@library/dto/service-provider-client-elastic.dto';
import { SERVER_API_URL } from '@one/app/app.constants';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ServiceProviderClientService {
  private readonly _resourceUrl = `${SERVER_API_URL}api/clients`;

  public constructor(private readonly _httpClient: HttpClient) {}

  public getClients$(): Observable<ServiceProviderClientElasticDTO[]> {
    return this._httpClient.get<ServiceProviderClientElasticDTO[]>(`${this._resourceUrl}/search`);
  }
}
