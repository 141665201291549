// @ts-strict-ignore
import { SERVER_API_URL } from '@one/app/app.constants';
import { IProductMeasurement } from '@one/app/pages/brd/pages/product-po/pages/product/pages/measurements/brd-product-measurements.model';
import WorkflowUtils from '@one/app/pages/isp/pages/inspection/pages/id/shared/services/workflow.utils';
import { InspectionId } from '@one/app/pages/isp/shared/models/isp-inspection.models';
import {
  IMeasure,
  IMeasureConfiguration,
  IMeasuresSampleAnswer,
  IVariance,
} from '@one/app/pages/isp/shared/models/variances/inspection-variances.models';
import { ProductMeasurement } from '@one/app/shared/classes/measurements/product-measurement';
import { EInspectionProductMeasurementStatus } from '@one/app/shared/models/inspections/inspection.models';
import {
  IMeasurement,
  IMeasurementLineDTO,
  IMeasurementsDTO,
  IProductMeasure,
  MeasurementsType,
} from '@one/app/shared/models/measurements/measurements.models';
import {
  IAddCustomMeasurementProductSampleBody,
  ISaveMeasureData,
} from '@one/app/shared/services/measurements/product-measurements.models';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QimaOptionalType } from '@qima/ngx-qima';
import { isEmpty, isNil } from 'lodash/index';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ProductMeasurementsService {
  public static findProductMeasurementStatusByMeasurementsToInspect(
    measurementsToInspectObservablesArray$: Observable<IMeasurement>[]
  ): Observable<EInspectionProductMeasurementStatus> {
    return combineLatest(measurementsToInspectObservablesArray$).pipe(
      map((arrayOfMeasures: QimaOptionalType<IMeasurement>[] = []): EInspectionProductMeasurementStatus => {
        let counterOfProductsWithoutMeasurements = 0;
        let counterOfProductsWithBlankMeasures = 0;

        arrayOfMeasures.forEach((data: QimaOptionalType<IMeasurement>): void => {
          if (isEmpty(data?.measurements)) {
            counterOfProductsWithoutMeasurements++;
          } else if (ProductMeasurementsService._checkIfContainsBlankMeasures(data.measurements)) {
            counterOfProductsWithBlankMeasures++;
          }
        });

        if (counterOfProductsWithoutMeasurements === arrayOfMeasures.length) {
          return EInspectionProductMeasurementStatus.NO_MEASUREMENTS;
        }

        if (counterOfProductsWithBlankMeasures === arrayOfMeasures.length) {
          return EInspectionProductMeasurementStatus.BLANK_MEASURES;
        }

        return EInspectionProductMeasurementStatus.OK;
      })
    );
  }

  public static generateMeasureAnswer(variance: Readonly<IVariance>, section?: Readonly<QimaOptionalType<string>>): IMeasuresSampleAnswer {
    let measureIndex: number = 0;
    const measureId: number = WorkflowUtils.getMeasuresEntriesNextIndex(variance.measuresEntries);
    const measureAnswers = variance.measureModel
      .filter((config: IMeasureConfiguration): boolean => isNil(section) || config.section === section)
      .map((config: IMeasureConfiguration): IMeasure => {
        measureIndex++;

        return {
          ...config,
          id: `${variance.id}-${measureId}-${measureIndex}`,
          measure: null,
          custom: true,
          section,
        };
      });

    return { measureAnswers };
  }

  private static _checkIfContainsBlankMeasures(measurements: IProductMeasure[]): boolean {
    return measurements.some((measure: IProductMeasure): boolean => {
      return measure.measurePoints.some((measurementLine: IMeasurementLineDTO): boolean => {
        return measurementLine.values.some((point: string): boolean => isEmpty(point));
      });
    });
  }

  private readonly _resourceUrl = `${SERVER_API_URL}api/`;

  public constructor(private readonly _httpClient: HttpClient) {}

  public parseMeasurements(separator: Readonly<string>, file: Readonly<File>): Observable<IProductMeasurement> {
    if (separator.length !== 1) {
      throw new Error('Invalid separator: should be one character');
    }

    const formData = new FormData();

    formData.append('file', file, file.name);

    return this._httpClient.post<IProductMeasurement>(`${this._resourceUrl}products/measurements?separator=${separator}`, formData, {
      reportProgress: true,
    });
  }

  public getMeasurementsToInspect(productId: Readonly<number>, toInspect: Readonly<boolean>): Observable<IMeasurement> {
    return this._httpClient.get<IMeasurement>(`${this._resourceUrl}products/${productId}/measurements`, {
      params: {
        toInspect: toInspect.toString(),
      },
    });
  }

  public getMeasurements$(productId: Readonly<string>): Observable<MeasurementsType> {
    return this._httpClient.get<IMeasurementsDTO>(`${this._resourceUrl}products/${productId}/measurements`).pipe(
      map((measurements: IMeasurementsDTO): MeasurementsType => {
        return ProductMeasurement.formatMeasurementToSections(measurements);
      })
    );
  }

  public updateMeasurements$(productId: Readonly<string>, measurements: MeasurementsType): Observable<unknown> {
    const measurementData: IMeasurementsDTO = ProductMeasurement.formatMeasurementToRequest(measurements);

    return this._httpClient.put(`${this._resourceUrl}products/${productId}/measurements`, measurementData);
  }

  public deleteMeasurements$(productId: Readonly<string>): Observable<unknown> {
    return this._httpClient.delete(`${this._resourceUrl}products/${productId}/measurements`);
  }

  public saveMeasure$(
    inspectionId: Readonly<InspectionId>,
    measurementId: Readonly<string>,
    measurement: Readonly<number>,
    sectionName?: Readonly<QimaOptionalType<string>>
  ): Observable<unknown> {
    const body: ISaveMeasureData = { measure: measurement };

    if (!isNil(sectionName)) {
      body.section = sectionName;
    }

    return this._httpClient.put<unknown>(`${this._resourceUrl}inspections/${inspectionId}/measures/${measurementId}`, body);
  }

  public addCustomMeasurementProductSample$(
    inspectionId: Readonly<InspectionId>,
    varianceId: Readonly<string>,
    sectionName?: Readonly<QimaOptionalType<string>>
  ): Observable<unknown> {
    const body: IAddCustomMeasurementProductSampleBody = {};

    if (!isNil(sectionName)) {
      body.section = sectionName;
    }

    return this._httpClient.post<unknown>(`${this._resourceUrl}inspections/${inspectionId}/measures/${varianceId}`, body);
  }
}
