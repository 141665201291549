import { EInspectionResult } from '@one/app/pages/brd/pages/report/pages/inspection/pages/id/brd-report-inspection-id.models';
import { IspWorkflowStepAction } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/isp-workflow-step-action';
import { EWorkflowActionType, IWorkflowAction } from '@one/app/shared/models/workflow/workflow.models';
import { EWorkflowTemplateStepActionInspectionPreparationCalculationMethod } from '@one/app/shared/models/workflows/workflows-templates.models';
import { isBoolean, isNil } from 'lodash/index';

export class IspWorkflowStepActionInspectionPreparation extends IspWorkflowStepAction {
  public readonly type = EWorkflowActionType.INSPECTION_PREPARATION;
  // Following the DTO for simplicity
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public updatableByInspector = false;
  public calculationMethod: EWorkflowTemplateStepActionInspectionPreparationCalculationMethod =
    EWorkflowTemplateStepActionInspectionPreparationCalculationMethod.PACKED_QUANTITY;

  // @todo make sure it is the right implementation
  protected get _isOngoing(): boolean {
    return false;
  }

  // @todo make sure it is the right implementation
  public get result(): EInspectionResult {
    return EInspectionResult.PASS;
  }

  public constructor(action: Readonly<IWorkflowAction>) {
    super(action);

    if (!isBoolean(action.updatableByInspector)) {
      throw new Error('Could not find the "updatableByInspector" property');
    }

    if (isNil(action.calculationMethod)) {
      throw new Error('Could not find the "calculationMethod" property');
    }

    this.updatableByInspector = action.updatableByInspector;
    this.calculationMethod = action.calculationMethod;
  }
}
