import { AutoUnsubscriber } from '@library/classes/auto-unsubscriber/auto-unsubscriber';
import { QpLoggerService } from '@library/services/qp-logger/qp-logger.service';
import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

@Injectable({ providedIn: 'root' })
export class SwReloadOnUpdateService extends AutoUnsubscriber {
  public constructor(private readonly _swUpdate: SwUpdate, private readonly _qpLoggerService: QpLoggerService) {
    super();
  }

  public init(): void {
    this.registerSubscription(
      this._swUpdate.available.subscribe(
        (): void => {
          this._qpLoggerService.info('🌈 New version available reload in progress');
          void this._swUpdate.activateUpdate().then((): void => document.location.reload());
        },
        (error: Error): void => {
          this._qpLoggerService.error(error);
        }
      )
    );
  }
}
