import { AqlTypes } from '@library/dto-enums/aql-types.dto-enum';
import { IFixedQuantityAqlDefects } from '@one/app/shared/models/defects/aql-defects.models';

export class FixedQuantityAqlDefects implements IFixedQuantityAqlDefects {
  public readonly type: AqlTypes.QUANTITY_AQL_DEFECTS = AqlTypes.QUANTITY_AQL_DEFECTS;
  public critical: number;
  public major: number;
  public minor: number;
  public sampleSize: number;

  public constructor(data: Readonly<Pick<IFixedQuantityAqlDefects, 'critical' | 'major' | 'minor' | 'sampleSize'>>) {
    this.critical = data.critical;
    this.major = data.major;
    this.minor = data.minor;
    this.sampleSize = data.sampleSize;
  }
}
