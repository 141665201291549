import { SimplifiedMeasurementChecklistMeasuresDTO } from '@library/dto/checklist/measure/simplified/simplified-measurement-checklist-measures.dto';
import { CustomFieldValueDTO } from '@library/dto/workflow/custom-fields.dto';
import { CartonCalculationMethod } from '@library/dto-enums/carton-calculation-method.dto-enum';
import { DefectClassification } from '@library/dto-enums/defect-classification.dto-enum';
import { EQpImageElementType } from '@library/models/qp-image.models';
import { EQpQuestionType } from '@library/models/qp-question.models';
import { IQpTestsChecklistElementTableRows } from '@library/models/qp-tests-checklist-element.models';
import { EInspectionResult } from '@one/app/pages/brd/pages/report/pages/inspection/pages/id/brd-report-inspection-id.models';
import { IEntity, IEntityConsultation } from '@one/app/pages/brd/shared/models/entity.model';
import { IDefectsChecklistDefect } from '@one/app/pages/isp/pages/inspection/pages/id/pages/defects-checklist/isp-inspection-id-defect.models';
import { IIspInspectionIdStepCompletionTime } from '@one/app/pages/isp/pages/inspection/pages/id/shared/models/isp-inspection-id-step-completion-time.models';
import { InspectionId } from '@one/app/pages/isp/shared/models/isp-inspection.models';
import { Answer } from '@one/app/shared/models/answer/answer.models';
import {
  IAttachmentData,
  IChecklistComment,
  IChecklistImageData,
} from '@one/app/shared/models/inspection-consultation/inspection-consultation.models';
import {
  IProductActualQuantities,
  IWorkflow,
  WorkflowAuditCheckType,
  WorkflowCheckType,
} from '@one/app/shared/models/workflow/workflow.models';
import { Action, createAction, props } from '@ngrx/store';
import { QimaOptionalType } from '@qima/ngx-qima';

export const LOAD_WORKFLOW = '[Workflow] Load workflow';

export const LOADED_SUCCESS_WORKFLOW = '[Workflow] Load workflow success';

export const UPDATE_AVAILABLE_CARTONS = '[Workflow] Available Cartons update';

export const UPDATE_AVAILABLE_CARTONS_SUCCESS = '[Workflow] Cartons To Pick Success';

export const UPDATE_GLOBAL_REMARKS = '[Workflow] Global remarks update';

export const UPDATE_GLOBAL_REMARKS_SUCCESS = '[Workflow] Global remarks update success';

export const UPDATE_IS_INSPECTION_MARKED_AS_PENDING = '[Workflow] Update is inspection marked as pending';

export const UPDATE_IS_INSPECTION_MARKED_AS_PENDING_SUCCESS = '[Workflow] Update is inspection marked as pending success';

export const updateAvailableCartons = createAction(
  UPDATE_AVAILABLE_CARTONS,
  props<{
    availableCartons: QimaOptionalType<number>;
    calculationMethod: CartonCalculationMethod;
    cartonsToPick: QimaOptionalType<number>;
  }>()
);

export const updateAvailableCartonsSuccess = createAction(UPDATE_AVAILABLE_CARTONS_SUCCESS, props<{ cartonsToPick: number }>());

export const loadWorkflow = createAction(LOAD_WORKFLOW, props<{ id: InspectionId }>());

export const loadWorkflowSuccess = createAction(LOADED_SUCCESS_WORKFLOW, props<{ workflow: IWorkflow }>());

export const updateGlobalRemarks = createAction(UPDATE_GLOBAL_REMARKS, props<{ remarks: IChecklistComment }>());

export const updateGlobalRemarksSuccess = createAction(UPDATE_GLOBAL_REMARKS_SUCCESS, props<{ remarks: IChecklistComment }>());

export const updateIsInspectionMarkedAsPending = createAction(
  UPDATE_IS_INSPECTION_MARKED_AS_PENDING,
  props<{ isInspectionMarkedAsPending: boolean }>()
);

export const updateIsInspectionMarkedAsPendingSuccess = createAction(
  UPDATE_IS_INSPECTION_MARKED_AS_PENDING_SUCCESS,
  props<{ isInspectionMarkedAsPending: boolean }>()
);

// Update actual quantities
export const UPDATE_PRODUCT_ACTUAL_QUANTITIES = '[Workflow] Update product actual quantities';

export const UPDATE_PRODUCT_ACTUAL_QUANTITIES_SUCCESS = '[Workflow] Update product actual quantities success';

export const UPDATE_PRODUCT_ACTUAL_QUANTITIES_ERROR = '[Workflow] Update product actual quantities error';

export interface IUpdateProductActualQuantitiesProps {
  productId: number;
  purchaseOrderProductId: number;
  produced: number;
  packed: number;
  updateAvailableProductsAction?: Action;
}

export interface IUpdateProductActualQuantitiesActionWithError {
  readonly type: '[Workflow] Update product actual quantities error';
  readonly error: unknown;
}

export interface IUpdateProductActualQuantitiesActionWithSuccess {
  readonly type: '[Workflow] Update product actual quantities success';
  readonly productActualQuantities: IProductActualQuantities;
}

export type UpdateProductActualQuantitiesActionType =
  | IUpdateProductActualQuantitiesActionWithError
  | IUpdateProductActualQuantitiesActionWithSuccess;

export const updateProductActualQuantities = createAction(UPDATE_PRODUCT_ACTUAL_QUANTITIES, props<IUpdateProductActualQuantitiesProps>());

export const updateProductActualQuantitiesSuccess = createAction(
  UPDATE_PRODUCT_ACTUAL_QUANTITIES_SUCCESS,
  props<Omit<IUpdateProductActualQuantitiesActionWithSuccess, 'type'>>()
);

export const updateProductActualQuantitiesError = createAction(
  UPDATE_PRODUCT_ACTUAL_QUANTITIES_ERROR,
  props<Omit<IUpdateProductActualQuantitiesActionWithError, 'type'>>()
);

// Test checklist 🧪

export const SAVE_TEST_ANSWER = '[Workflow - Test checklist] Save test answer';

export const SAVE_NULLABLE_TEST_ANSWER = '[Workflow - Test checklist] Save text test answer';

export const SAVE_TEST_ANSWER_SUCCESS = '[Workflow - Test checklist] Save test answer success';

export const COMMENT_TEST = '[Workflow - Test checklist] Comment test';

export const COMMENT_TEST_SUCCESS = '[Workflow - Test checklist] Comment test success';

export const MARK_AS_NOT_APPLICABLE = '[Workflow - Test checklist] Mark as not applicable';

export const MARK_AS_NOT_APPLICABLE_SUCCESS = '[Workflow - Test checklist] Mark as not applicable success';

export const SAVE_TEST_TABLE_ROWS = '[Workflow - Test checklist] Save table rows';

export const DELETE_TEST_CHECKLIST_TABLE_ROW = '[Workflow - Test checklist] Delete table row';

export const DELETE_TEST_CHECKLIST_TABLE_ROW_SUCCESS = '[Workflow - Test checklist] Delete table row success';

export interface ISaveTestAnswer {
  id: string;
  value: Answer;
  elementType: EQpQuestionType;
}

export const saveTestAnswer = createAction(SAVE_TEST_ANSWER, props<ISaveTestAnswer>());

export const saveNullableTestAnswer = createAction(SAVE_NULLABLE_TEST_ANSWER, props<{ id: string; value: Answer | null }>());

export const saveTestAnswerSuccess = createAction(
  SAVE_TEST_ANSWER_SUCCESS,
  props<{ id: string; value: Answer; elementType: EQpQuestionType }>()
);

export const commentTest = createAction(COMMENT_TEST, props<{ id: string; comment: IChecklistComment }>());

export const commentTestSuccess = createAction(COMMENT_TEST_SUCCESS, props<{ id: string; comment: IChecklistComment }>());

export const markAsNotApplicable = createAction(MARK_AS_NOT_APPLICABLE, props<{ id: string; notApplicable: boolean }>());

export const markAsNotApplicableSuccess = createAction(MARK_AS_NOT_APPLICABLE_SUCCESS, props<{ id: string; notApplicable: boolean }>());

export const saveTestTableRows = createAction(SAVE_TEST_TABLE_ROWS, props<{ id: string; rows: IQpTestsChecklistElementTableRows[] }>());

export const deleteTestChecklistTableRow = createAction(
  DELETE_TEST_CHECKLIST_TABLE_ROW,
  props<{ id: string; answerId: string; numberOfColumns: number }>()
);

export const deleteTestChecklistTableRowSuccess = createAction(
  DELETE_TEST_CHECKLIST_TABLE_ROW_SUCCESS,
  props<{ id: string; answerId: string; noOfColumns: number }>()
);

// Instruction 🧾

export const SAVE_CHECK = '[Workflow - Instruction] Save check';

export const SAVE_CHECK_SUCCESS = '[Workflow - Instruction] Save check success';

export const saveCheck = createAction(SAVE_CHECK, props<{ actionId: string; check: WorkflowCheckType | WorkflowAuditCheckType }>());

export const saveCheckSuccess = createAction(
  SAVE_CHECK_SUCCESS,
  props<{ actionId: string; check: WorkflowCheckType | WorkflowAuditCheckType }>()
);

// Defect checklist ❌

export const CREATE_DEFECT = '[Workflow - Defect] Create defect';

export const CREATE_DEFECT_SUCCESS = '[Workflow - Defect] Create defect success';

export const UPDATE_DEFECT = '[Workflow - Defect] Update defect';

export const UPDATE_DEFECT_SUCCESS = '[Workflow - Defect] Update defect success';

export const UPDATE_DEFECT_COMMENT = '[Workflow - Defect] Update defect comment';

export const UPDATE_DEFECT_COMMENT_SUCCESS = '[Workflow - Defect] Update defect comment success';

export const COMPLETE_DEFECTS_CHECKLIST = '[Workflow - Defect] Complete defects checklist';

export const COMPLETE_DEFECTS_CHECKLIST_SUCCESS = '[Workflow - Defect] Complete defects checklist success';

export const createDefect = createAction(
  CREATE_DEFECT,
  props<{
    name: string;
    defectType: string;
    stepId: string;
    classification: DefectClassification;
    purchaseOrderProductId?: QimaOptionalType<number>;
  }>()
);

export const createDefectSuccess = createAction(CREATE_DEFECT_SUCCESS, props<{ defect: IDefectsChecklistDefect }>());

export interface IUpdateDefectProps {
  defectId: string;
  quantity: number;
  classification: string;
  purchaseOrderProductId?: QimaOptionalType<number>;
}

export const updateDefect = createAction(UPDATE_DEFECT, props<IUpdateDefectProps>());

export const updateDefectSuccess = createAction(UPDATE_DEFECT_SUCCESS, props<IUpdateDefectProps>());

export interface IUpdateDefectCommentProps {
  defectId: string;
  comment: IChecklistComment;
  purchaseOrderProductId?: QimaOptionalType<number>;
}

export const updateDefectComment = createAction(UPDATE_DEFECT_COMMENT, props<IUpdateDefectCommentProps>());

export const updateDefectCommentSuccess = createAction(UPDATE_DEFECT_COMMENT_SUCCESS, props<IUpdateDefectCommentProps>());

export interface ICompleteDefectsChecklistProps {
  stepId: number;
  actionId: number;
  purchaseOrderProductId?: QimaOptionalType<number>;
}

export const completeDefectsChecklist = createAction(COMPLETE_DEFECTS_CHECKLIST, props<ICompleteDefectsChecklistProps>());

export const completeDefectsChecklistSuccess = createAction(COMPLETE_DEFECTS_CHECKLIST_SUCCESS, props<ICompleteDefectsChecklistProps>());

// Images 📷

export const ADD_INSPECTION_PICTURE_SUCCESS = '[Workflow] Add inspection picture success';

export const addInspectionPictureSuccess = createAction(
  ADD_INSPECTION_PICTURE_SUCCESS,
  props<{ data: IChecklistImageData; elementType: QimaOptionalType<string>; elementPathId: QimaOptionalType<string>; newImageId: string }>()
);

export const ADD_OR_UPDATE_INSPECTION_PICTURE_DATA_SUCCESS = '[Workflow] Update inspection picture data success';

export const updateInspectionPictureDataSuccess = createAction(
  ADD_OR_UPDATE_INSPECTION_PICTURE_DATA_SUCCESS,
  props<{
    data: { caption?: string; isSpotlight?: boolean };
    elementType: QimaOptionalType<EQpImageElementType>;
    elementPathId: string;
    imageId: string;
  }>()
);

export const ADD_OR_UPDATE_INSPECTION_PICTURES_DATA_SUCCESS = '[Workflow] Update inspection pictures data success';

export const updateInspectionPicturesDataSuccess = createAction(
  ADD_OR_UPDATE_INSPECTION_PICTURES_DATA_SUCCESS,
  props<{
    data: { caption?: string; isSpotlight?: boolean; isLinkedToTestResult?: boolean };
    elementType: QimaOptionalType<EQpImageElementType>;
    elementPathId: string;
    imageIds: string[];
  }>()
);

export const ORDER_INSPECTION_PICTURES_SUCCESS = '[Workflow] Reorder inspection pictures success';

export const orderInspectionPicturesSuccess = createAction(
  ORDER_INSPECTION_PICTURES_SUCCESS,
  props<{ orderedPicturesIds: string[]; entity: QimaOptionalType<string>; entityId: QimaOptionalType<string> }>()
);

export interface IDeleteWorkflowImage {
  data: Partial<IChecklistImageData>;
  imageElementType: QimaOptionalType<EQpImageElementType>;
  elementId: string;
  inspectionId: InspectionId;
}

export interface IDeleteWorkflowImageSuccess extends IDeleteWorkflowImage {
  type: '[Workflow] Delete inspection image success';
}

export const DELETE_INSPECTION_IMAGE = '[Workflow] Delete inspection image';

export const deleteInspectionImage = createAction(DELETE_INSPECTION_IMAGE, props<IDeleteWorkflowImage>());

export const DELETE_INSPECTION_IMAGE_SUCCESS = '[Workflow] Delete inspection image success';

export const deleteInspectionImageSuccess = createAction(DELETE_INSPECTION_IMAGE_SUCCESS, props<IDeleteWorkflowImage>());

// Attachments
export const ADD_INSPECTION_ATTACHMENT_SUCCESS = '[Workflow] Add inspection attachment success';

export const DELETE_INSPECTION_ATTACHMENT_SUCCESS = '[Workflow] Delete inspection attachment success';

export const addInspectionAttachmentSuccess = createAction(
  ADD_INSPECTION_ATTACHMENT_SUCCESS,
  props<{ data: IAttachmentData; entity: string; entityId: string }>()
);

export const deleteInspectionAttachmentSuccess = createAction(
  DELETE_INSPECTION_ATTACHMENT_SUCCESS,
  props<{ attachmentId: string; entity: string; entityId: string }>()
);

// Site Location 📍

export const ADD_SITE_LOCATION = '[Workflow] Add site location of inspection';

export const ADD_SITE_LOCATION_SUCCESS = '[Workflow] Add site location of inspection success';

export const CLEAR_SITE_LOCATION = '[Workflow] Clear site location of inspection';

export const clearSiteLocation = createAction(CLEAR_SITE_LOCATION, props<{ entity: null; stepId: number; actionId: number }>());

export const addSiteLocation = createAction(
  ADD_SITE_LOCATION,
  props<{ entity: IEntityConsultation | IEntity; stepId: number; actionId: number }>()
);

// Measurements 📏

export const SAVE_MEASUREMENT = '[Workflow] Save measurement';

export const SAVE_MEASUREMENT_SUCCESS = '[Workflow] Save measurement success';

export const ADD_CUSTOM_MEASUREMENT_PRODUCT_SAMPLE = '[Workflow] Add custom measurement product sample';

export const ADD_CUSTOM_MEASUREMENT_PRODUCT_SAMPLE_SUCCESS = '[Workflow] Add custom measurement product sample success';

export const saveMeasurement = createAction(SAVE_MEASUREMENT, props<{ measurementId: string; measure: number; sectionName: string }>());

export const saveMeasurementSuccess = createAction(
  SAVE_MEASUREMENT_SUCCESS,
  props<{ measurementId: string; sectionName?: QimaOptionalType<string>; measure: number }>()
);

export const addCustomMeasurementProductSample = createAction(
  ADD_CUSTOM_MEASUREMENT_PRODUCT_SAMPLE,
  props<{ path: string; sectionName?: QimaOptionalType<string> }>()
);

export const addCustomMeasurementProductSampleSuccess = createAction(
  ADD_CUSTOM_MEASUREMENT_PRODUCT_SAMPLE_SUCCESS,
  props<{ path: string; sectionName?: QimaOptionalType<string> }>()
);

// Simplified Measurements 📏

export const SAVE_SIMPLIFIED_MEASURES = '[Workflow - Simplified measurements] Save simplified measures';

export const SAVE_SIMPLIFIED_MEASURES_SUCCESS = '[Workflow - Simplified measurements] Save simplified measures success';

export const SAVE_SIMPLIFIED_MEASURES_ERROR = '[Workflow - Simplified measurements] Save simplified measures error';

export const SAVE_SIMPLIFIED_MEASURES_MARKED_AS_NOT_APPLICABLE =
  '[Workflow - Simplified measurements] Save simplified measures marked as not applicable';

export const SAVE_SIMPLIFIED_MEASURES_COMMENT = '[Workflow - Simplified measurements] Save simplified measures comment';

export const UPDATE_SIMPLIFIED_MEASURES_RESULT = '[Workflow - Simplified measurements] Update simplified measures result';

export const SAVE_SIMPLIFIED_MEASURES_MARKED_AS_NOT_APPLICABLE_SUCCESS =
  '[Workflow - Simplified measurements] Save simplified measures marked as not applicable success';

export const SAVE_SIMPLIFIED_MEASURES_COMMENT_SUCCESS = '[Workflow - Simplified measurements] Save simplified measures comment success';

export const saveSimplifiedMeasures = createAction(
  SAVE_SIMPLIFIED_MEASURES,
  props<{ simplifiedMeasures: SimplifiedMeasurementChecklistMeasuresDTO; inspectionId: InspectionId }>()
);

export const saveSimplifiedMeasuresSuccess = createAction(
  SAVE_SIMPLIFIED_MEASURES_SUCCESS,
  props<{ simplifiedMeasures: SimplifiedMeasurementChecklistMeasuresDTO }>()
);

export const saveSimplifiedMeasuresError = createAction(SAVE_SIMPLIFIED_MEASURES_ERROR, props<{ error: string }>());

export const saveSimplifiedMeasuresMarkedAsNotApplicable = createAction(
  SAVE_SIMPLIFIED_MEASURES_MARKED_AS_NOT_APPLICABLE,
  props<{ inspectionId: InspectionId; notApplicable: boolean }>()
);

export const saveSimplifiedMeasuresComment = createAction(
  SAVE_SIMPLIFIED_MEASURES_COMMENT,
  props<{ inspectionId: InspectionId; comment: string }>()
);

export const updateSimplifiedMeasuresResult = createAction(
  UPDATE_SIMPLIFIED_MEASURES_RESULT,
  props<{ inspectionId: InspectionId; result: EInspectionResult }>()
);

export const saveSimplifiedMeasuresMarkedAsNotApplicableSuccess = createAction(
  SAVE_SIMPLIFIED_MEASURES_MARKED_AS_NOT_APPLICABLE_SUCCESS,
  props<{ notApplicable: boolean }>()
);

export const saveSimplifiedMeasuresCommentSuccess = createAction(SAVE_SIMPLIFIED_MEASURES_COMMENT_SUCCESS, props<{ comment: string }>());

// Product picture 📸

export const SAVE_PRODUCT_PICTURE = '[Workflow - Product picture] Save';

export const SAVE_PRODUCT_PICTURE_SUCCESS = '[Workflow - Product picture] Save success';

export const saveProductPicture = createAction(SAVE_PRODUCT_PICTURE, props<{ actionId: string; action: { documentId: string } }>());

export const saveProductPictureSuccess = createAction(
  SAVE_PRODUCT_PICTURE_SUCCESS,
  props<{ actionId: string; action: { documentId: string } }>()
);

// Findings summary review 📝

export const CONFIRM_FINDINGS_SUMMARY_REVIEW = '[Workflow - Findings summary review] Confirm';

export const CONFIRM_FINDINGS_SUMMARY_REVIEW_SUCCESS = '[Workflow - Findings summary review] Confirm success';

export const confirmFindingsSummaryReview = createAction(CONFIRM_FINDINGS_SUMMARY_REVIEW);

export const confirmFindingsSummaryReviewSuccess = createAction(CONFIRM_FINDINGS_SUMMARY_REVIEW_SUCCESS);

// Sample collection
export const COMPLETE_SAMPLE_COLLECTION = '[Workflow - Sample collection] Complete';

export const COMPLETE_SAMPLE_COLLECTION_SUCCESS = '[Workflow - Sample collection] Complete success';

export const completeSampleCollection = createAction(
  COMPLETE_SAMPLE_COLLECTION,
  props<{ stepId: string; actionId: string; stepCompletionTime: IIspInspectionIdStepCompletionTime }>()
);

export const completeSampleCollectionSuccess = createAction(COMPLETE_SAMPLE_COLLECTION_SUCCESS);

// Reference sample
export const COMPLETE_REFERENCE_SAMPLE = '[Workflow - Reference sample] Complete';

export const COMPLETE_REFERENCE_SAMPLE_SUCCESS = '[Workflow - Reference sample] Complete success';

export const completeReferenceSample = createAction(
  COMPLETE_REFERENCE_SAMPLE,
  props<{ stepId: string; actionId: string; stepCompletionTime: IIspInspectionIdStepCompletionTime }>()
);

export const completeReferenceSampleSuccess = createAction(COMPLETE_REFERENCE_SAMPLE_SUCCESS);

// Recipients

export const SAVE_RECIPIENTS = '[Workflow - Recipients] Save';

export const SAVE_RECIPIENTS_SUCCESS = '[Workflow - Recipients] Save success';

export const SAVE_RECIPIENTS_ERROR = '[Workflow - Recipients] Save error';

export const saveRecipients = createAction(SAVE_RECIPIENTS, props<{ actionId: string; recipients: string[] }>());

export const saveRecipientsSuccess = createAction(SAVE_RECIPIENTS_SUCCESS, props<{ actionId: string; recipients: string[] }>());

export const saveRecipientsError = createAction(SAVE_RECIPIENTS_ERROR, props<{ actionId: string; recipients: string[] }>());

export interface ISaveRecipientsActionSuccess {
  readonly type: '[Workflow - Recipients] Save success';
  readonly actionId: string;
  readonly recipients: string[];
}

export interface ISaveRecipientsActionError {
  readonly type: '[Workflow - Recipients] Save error';
  readonly error: unknown;
}

export type ISaveRecipientsActionType = ISaveRecipientsActionSuccess | ISaveRecipientsActionError;

// Custom fields ✨

export const CUSTOM_FIELDS_FEATURE = '[Workflow - Custom fields]';

export const saveWorkflowActionCustomFieldsValue = createAction(
  `${CUSTOM_FIELDS_FEATURE} Save`,
  props<{ fieldId: number; answer: CustomFieldValueDTO }>()
);

export const saveWorkflowActionCustomFieldsValueSuccess = createAction(
  `${CUSTOM_FIELDS_FEATURE} Save success`,
  props<{ fieldId: number; answer: CustomFieldValueDTO }>()
);
