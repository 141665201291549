import { AbstractControl, ValidationErrors } from '@angular/forms';

/**
 * @param control
 */
export function qpNotBlankValidator(control: AbstractControl): ValidationErrors | null {
  const isWhitespace = (control.value || '').toString().length > 0 && control.value.trim().length === 0;

  return isWhitespace ? { whitespace: true } : null;
}
