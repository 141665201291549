import { RatingCriteriaDTO } from '@library/dto/rating-criteria.dto';
import { NumberSuccessCriteria } from '@library/dto-enums/number-success-criteria.dto-enum';
import { NumberUnit } from '@library/dto-enums/number-unit.dto-enum';
import { RatingTypes } from '@library/dto-enums/rating-types.dto-enum';

interface IQpNumberConstraint {
  criteria: NumberSuccessCriteria;
  values: number[];
  unit: NumberUnit;
  type: RatingTypes.PASS_FAIL;
}

type QpNumberBinaryConstraintMinValueType = number;

type QpNumberBinaryConstraintMaxValueType = number;

/**
 * @deprecated IQpNumberRatingConstraint is deprecated, use {@link RatingCriteriaDTO} instead
 */
export { RatingCriteriaDTO as IQpNumberRatingConstraint };

export interface IQpNumberBinaryConstraint extends IQpNumberConstraint {
  criteria: NumberSuccessCriteria.BETWEEN | NumberSuccessCriteria.BETWEEN_INCLUSIVE;
  values: [QpNumberBinaryConstraintMinValueType, QpNumberBinaryConstraintMaxValueType];
}

export interface IQpNumberUnaryConstraint extends IQpNumberConstraint {
  criteria: NumberSuccessCriteria.GT | NumberSuccessCriteria.GTE | NumberSuccessCriteria.LT | NumberSuccessCriteria.LTE;
  values: [number];
}

export type QpNumberConstraintType = IQpNumberBinaryConstraint | IQpNumberUnaryConstraint;

export type QpNumberRatingType = RatingCriteriaDTO | QpNumberConstraintType;

/**
 * @deprecated EQpConstraintCriterion is deprecated, use {@link NumberSuccessCriteria} instead
 */
export { NumberSuccessCriteria as EQpConstraintCriterion };

export enum EQpBrandConstraintUnit {
  CM = 'CM',
  INCH = 'INCH',
  PCS = 'PCS',
  KG = 'KG',
  G = 'G',
  PERCENT = 'PERCENT',
}

/**
 * @deprecated EQpConstraintUnit is deprecated, use {@link NumberUnit} instead
 */
export { NumberUnit as EQpConstraintUnit };

export enum EQpConstraintUnitLabel {
  MASS = 'MASS',
  QUANTITY = 'QUANTITY',
  LENGTH = 'LENGTH',
  TEMPERATURE = 'TEMPERATURE',
  FIRMNESS = 'FIRMNESS',
  OTHERS = 'OTHERS',
}

export interface IQpLabeledConstraint<T, M> {
  label: M;
  value: T[];
}
