import { InspectionMethod } from '@library/dto-enums/inspection-method.dto-enum';
import { InspectionStatus } from '@library/dto-enums/inspection-status.dto-enum';
import { EQpHttpStatusCode } from '@library/models/qp-http.models';
import { EQpInspectionTypeLabel } from '@library/models/qp-inspection.models';
import { EQpProfile } from '@library/models/qp-profile.models';
import { NetworkStatusService } from '@library/services/qp-network-status/qp-network-status.service';
import { QpQueryParamsService } from '@library/services/qp-query-params/qp-query-params.service';
import { SERVER_API_URL } from '@one/app/app.constants';
import { IEntity } from '@one/app/pages/brd/shared/models/entity.model';
import {
  IESInspectionConsultation,
  IESInspectionConsultationProduct,
} from '@one/app/pages/brd/shared/models/es-inspection-consultation.model';
import { INTERCEPTOR_SKIP_HEADER } from '@one/app/shared/interceptors/inspection.interceptor';
import { IInspectionConsultation } from '@one/app/shared/models/inspection-consultation/inspection-consultation.models';
import { IAbstractMultiProduct } from '@one/app/shared/models/products/product.models';
import { AccountService } from '@one/app/shared/services/account/account.service';
import { DatabaseService } from '@one/app/shared/services/database/database-inspection.service';
import { HttpHandlerFn, HttpRequest, HttpEvent, HttpResponse, HttpHeaders } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

const GET_INSPECTIONS_SEARCH = RegExp(`^${SERVER_API_URL}api/inspections/search$`);

/**
 * @param  {IAbstractMultiProduct[]} products list to convert
 * @returns {IESInspectionConsultationProduct[]} the converted list ✨
 */
function convertIAbstractMultiProductToIESInspectionConsultationProduct(
  products: IAbstractMultiProduct[]
): IESInspectionConsultationProduct[] {
  return products.map((p): IESInspectionConsultationProduct => {
    return {
      purchaseOrderReference: p.purchaseOrderReference,
      identifierValue: p.identifierValue,
      name: p.specification['NAME'] ?? '',
      reference: p.specification['REFERENCE'] ?? '',
      shipmentDate: p.shipmentDate,
      brandName: '',
      id: p.productId,
      identifierType: p.identifierType,
      purchaseOrderId: p.purchaseOrderId,
      quantity: p.productQuantity,
      unit: p.unit,
    };
  });
}

/**
 * @param  {IInspectionConsultation[]} inspections list of inspections to convert
 * @returns {IESInspectionConsultation[]} the converted list ✨
 */
function convertIInspectionConsultationToIESInspectionConsultation(inspections: IInspectionConsultation[]): IESInspectionConsultation[] {
  return inspections.map((i): IESInspectionConsultation => {
    const entity: IEntity | undefined = i.entity ?? i.responsibleEntity;

    return {
      id: i.id!,
      inspectionUuid: i.inspectionUuid,
      createdOffline: i.createdOffline,
      version: 0,
      typeLabel: i.inspectionType.label as EQpInspectionTypeLabel,
      plannedDate: i.plannedDate,
      inspectionDate: i.plannedDate,
      createdDate: i.plannedDate,
      shipmentDate: i.purchaseOrder?.shipmentDate ?? '',
      status: i.status,
      method: i.method! as InspectionMethod,
      inspectorFullName: `${i.inspector?.firstName} ${i.inspector?.lastName}`,
      inspectorCompanyId: i.inspector?.brandId?.toString() ?? '',
      inspectorCompanyName: i.inspector?.companyName ?? '',
      inspectorId: i.inspector?.id ?? 0,
      entityId: entity?.id ?? 0,
      entityName: entity?.name ?? '',
      productId: i.products[0]?.productId ?? 0,
      productIdentifierValue: i.products[0]?.identifierValue ?? '',
      productQuantity: i.products[0]?.productQuantity ?? 0,
      unit: i.products[0]?.unit ?? '',
      brandName: i.brand?.name ?? '',
      brandId: i.brand?.id ?? 0,
      entity,
      entityCountry: entity?.address.country ?? '',
      entityCity: entity?.address.city ?? '',
      result: i.result,
      products: convertIAbstractMultiProductToIESInspectionConsultationProduct(i.products),
    } as unknown as IESInspectionConsultation;
  });
}

/**
 * @param filterParam
 */
function _parseInspectionStatuses(filterParam: string): InspectionStatus[] | undefined {
  if (filterParam.includes('status,EQUALS,')) {
    const inspectionStatus = InspectionStatus[filterParam.split('status,EQUALS,')[1]];

    return [inspectionStatus];
  }

  if (filterParam.includes('status,IN,')) {
    const inspectionStatuses = filterParam.replaceAll('[', '').replaceAll(']', '').split('status,IN,')[1].split(',');

    return inspectionStatuses.map((status): InspectionStatus => InspectionStatus[status]);
  }

  return [];
}

/**
 * @param {HttpRequest} request the request to intercept
 * @param {HttpHandlerFn} next the request handler
 * @returns {Observable<HttpEvent<unknown>> | Observable<HttpResponse<undefined>>} the request or the response to return
 */
export function inspectorHomeInterceptor$(
  request: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> | Observable<HttpResponse<undefined>> {
  const databaseService: DatabaseService = inject(DatabaseService);
  const accountService: AccountService = inject(AccountService);
  const networkStatusService: NetworkStatusService = inject(NetworkStatusService);
  const queryParamsService: QpQueryParamsService = inject(QpQueryParamsService);

  if (
    !accountService.hasAnyAuthority([EQpProfile.ROLE_SERVICE_PROVIDER_INSPECTOR]) ||
    request.headers.has(INTERCEPTOR_SKIP_HEADER) ||
    networkStatusService.isOnline
  ) {
    return next(request);
  }

  const pathname: string = queryParamsService.getPathname(request.url);

  // 🔗 GET: /api/inspections/search&filter=status,EQUALS,PLANNED
  if (request.method === 'GET' && GET_INSPECTIONS_SEARCH.test(pathname)) {
    const filterParam: string = queryParamsService.getQueryParams(request.urlWithParams)['filter']?.toString() ?? '';
    const statuses: InspectionStatus[] | undefined = _parseInspectionStatuses(filterParam);

    if (!statuses) {
      return next(request);
    }

    return from(databaseService.getInspections()).pipe(
      map((resp): HttpResponse<IESInspectionConsultation[]> => {
        let inspections: IInspectionConsultation[] = resp;

        inspections = inspections
          .filter((inspection): boolean => statuses.includes(inspection.status))
          .filter((inspection): boolean => !!inspection.createdOffline && !!inspection.inspectionUuid)
          .sort((inspectionA, inspectionB): number => {
            if (inspectionA.inspectionDate && inspectionB.inspectionDate) {
              return new Date(inspectionB.inspectionDate).getTime() - new Date(inspectionA.inspectionDate).getTime();
            }

            return 0;
          });

        const body: IESInspectionConsultation[] = convertIInspectionConsultationToIESInspectionConsultation(inspections);

        return new HttpResponse({
          status: EQpHttpStatusCode.OK,
          body,
          headers: new HttpHeaders().append('x-total-count', body.length.toString()).append('x-total-page', '1'),
        });
      })
    );
  }

  return next(request);
}
