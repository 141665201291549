import { IspWorkflowStepActionMeasuresChecklist } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/isp-workflow-step-action-measures-checklist/isp-workflow-step-action-measures-checklist';
import { IspWorkflowStepGroupAction } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/isp-workflow-step-grouped-action';
import { EWorkflowActionType } from '@one/app/shared/models/workflow/workflow.models';

export class IspWorkflowStepGroupMeasuresChecklistAction extends IspWorkflowStepGroupAction<IspWorkflowStepActionMeasuresChecklist> {
  public get type(): EWorkflowActionType.MEASURES_CHECKLIST {
    return EWorkflowActionType.MEASURES_CHECKLIST;
  }

  public constructor(actions: IspWorkflowStepActionMeasuresChecklist[]) {
    super(actions);
  }
}
