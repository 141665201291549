import { logrocketEnvironmentIdFactory } from '@one/app/shared/factories/logrocket-environment-id.factory';
import { LOGROCKET_ENVIRONMENT_ID } from '@one/app/shared/tokens/logrocket-environment-id.token';
import { WINDOW } from '@one/app/shared/tokens/window.token';
import { FactoryProvider } from '@angular/core';

export const LOGROCKET_ENVIRONMENT_ID_PROVIDER: FactoryProvider = {
  deps: [WINDOW],
  provide: LOGROCKET_ENVIRONMENT_ID,
  useFactory: logrocketEnvironmentIdFactory,
};
