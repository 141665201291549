import { AqlTypes } from '@library/dto-enums/aql-types.dto-enum';
import { AqlValue } from '@library/dto-enums/aql-value.dto-enum';
import { MeasurementsSamplingSizeType } from '@library/dto-enums/measurements-sampling-size-types.dto-enum';
import { EQpApiManagedStateStatus } from '@library/models/qp-api-managed-state.models';
import { EQpAQLInspectionLevel } from '@library/models/qp-aql.models';
import { DEFAULT_IS_AQL_PER_PRODUCT, DEFAULT_IS_DEFECTS_PER_PRODUCT } from '@one/app/app.constants';
import { ReferenceSampleFetchingMode } from '@one/app/pages/brd/pages/inspection/shared/models/brd-inspection.models';
import {
  IBrdInspectionBookingState,
  IReferenceSample,
} from '@one/app/pages/brd/pages/inspection/store-features/inspection-booking/brd-inspection-booking.models';
import { AqlDefects } from '@one/app/shared/classes/defects/aql-defects';

export const DEFAULT_MEASUREMENTS_FLEXIBLE_AQL = '10';

export const DEFAULT_MEASUREMENTS_CUSTOM_AQL = AqlValue.AQL_4_0;

export const DEFAULT_MEASUREMENTS_INSPECTION_LEVEL = EQpAQLInspectionLevel.S4;

export const DEFAULT_DEFECTS_AQL: AqlDefects = new AqlDefects({
  critical: AqlValue.NOT_ALLOWED,
  major: AqlValue.AQL_2_5,
  minor: AqlValue.AQL_4_0,
});

export const DEFAULT_DEFECTS_INSPECTION_LEVEL = EQpAQLInspectionLevel.II;

export const DEFAULT_CARRIER_ID: number = 1;

export const DEFAULT_REFERENCE_SAMPLE: IReferenceSample = {
  fetchingMode: ReferenceSampleFetchingMode.SEND_TO_INSPECTION_SITE,
  carrierId: DEFAULT_CARRIER_ID,
  isToPickup: false,
  instructions: null,
  trackingNumber: null,
};

/**
 * @description
 * The key at the root of the NgRx object
 * @type {string}
 */
export const BRD_INSPECTION_BOOKING_STORE_FEATURE_KEY = 'brdInspectionBooking';

/**
 * @description
 * The default state of the brd inspection booking
 * @type {IBrdInspectionBookingState}
 */
export const BRD_INSPECTION_BOOKING_INITIAL_STATE: IBrdInspectionBookingState = {
  inspectionVersion: undefined,
  poProductsSearchResult: { data: [], status: EQpApiManagedStateStatus.EMPTY },
  poProducts: [],
  additionalPoProducts: null,
  additionalPoProductsDatatableConfig: {
    pageQuery: {
      page: 0,
      size: 20, // Let's wait datatable refactoring to use correct CONST here
    },
    filtersAndSortsParams: [],
  },
  isPOTwoTiers: false,
  sitesSearchResult: { data: [], status: EQpApiManagedStateStatus.EMPTY },
  childrenSites: { data: [], status: EQpApiManagedStateStatus.EMPTY },
  inspectionSite: null,
  responsibleEntity: null,
  productionSite: null,
  destinationSite: null,
  shouldNotifySite: true,
  isSiteDelegatedByEntity: false,
  inspectionTypes: { data: [], status: EQpApiManagedStateStatus.EMPTY },
  expectedQuantities: {},
  inspectionType: null,
  plannedDate: null,
  inspectionMethod: null,
  inspectorsSearchResult: { data: [], status: EQpApiManagedStateStatus.EMPTY },
  hasExistingInspectors: false,
  inspector: null,
  customFieldsWithValues: [],
  measurementSamplingMethod: MeasurementsSamplingSizeType.STANDARD_MEASUREMENTS_SAMPLING_SIZE,
  measurementsFixedSampleSize: 0,
  measurementsFixedAcceptPoint: 0,
  measurementsInspectionLevel: DEFAULT_MEASUREMENTS_INSPECTION_LEVEL,
  measurementsCustomAql: DEFAULT_MEASUREMENTS_CUSTOM_AQL,
  measurementsFlexibleAql: DEFAULT_MEASUREMENTS_FLEXIBLE_AQL,
  expectedMeasurementsSamplingSize: null,
  measurementsDefectsAllowed: null,
  referenceSample: null,
  sampleCollection: null,
  carriers: { data: [], status: EQpApiManagedStateStatus.EMPTY },
  countries: { data: [], status: EQpApiManagedStateStatus.EMPTY },
  aqlDefects: DEFAULT_DEFECTS_AQL,
  isDefectsPerProduct: DEFAULT_IS_DEFECTS_PER_PRODUCT,
  isAqlPerProduct: DEFAULT_IS_AQL_PER_PRODUCT,
  aqlDefectsType: AqlTypes.STANDARD_AQL_DEFECTS,
  aqlDefectsInspectionLevel: DEFAULT_DEFECTS_INSPECTION_LEVEL,
  workmanshipSamplingSize: null,
  workmanshipMaxDefectsAllowed: null,
  workflows: { data: [], status: EQpApiManagedStateStatus.EMPTY },
  workflow: null,
  workflowBrandLockedActions: [],
  workflowDetails: { data: null, status: EQpApiManagedStateStatus.EMPTY },
  hasExistingWorkflows: false,
  measurementStatus: { data: null, status: EQpApiManagedStateStatus.EMPTY },
  draftId: null,
  capabilities: { isBrand: false, isEntity: false },
  bookingEntityId: null,
  linkedBrand: null,
  additionalComment: null,
  additionalDocuments: [],
  inspectionStatus: null,
  purchaseOrderSites: null,
};
