// @ts-strict-ignore
import { CustomFieldWithValueDTO } from '@library/dto/customfield/custom-field-with-value.dto';
import { InspectionMethod } from '@library/dto-enums/inspection-method.dto-enum';
import { InspectionStatus } from '@library/dto-enums/inspection-status.dto-enum';
import {
  IBrdInspectionEdition,
  IBrdInspectionReferenceSample,
  IInspectionEditionDto,
  IInspectionEditionWorkflow,
  IInspectionProduct,
  ISampleCollection,
  SampleCollection,
} from '@one/app/pages/brd/pages/inspection/shared/models/brd-inspection.models';
import { AqlDefectsType } from '@one/app/shared/models/defects/aql-defects.models';
import { IInspectionAdditionalInformation } from '@one/app/shared/models/inspection-consultation/inspection-consultation.models';
import { IMeasurementSamplingSize, ISamplingSize } from '@one/app/shared/models/sampling/sampling-size.models';
import { isNil } from 'lodash/index';

export class BrdInspectionEdition implements IBrdInspectionEdition {
  public static doesActionExist(inspection: IBrdInspectionEdition, stepIndex: number, actionIndex: number): boolean {
    return (
      BrdInspectionEdition.doesStepHasAction(inspection, stepIndex) &&
      !isNil(inspection.workflow.content.steps[stepIndex].actions[actionIndex])
    );
  }

  public static doesStepHasAction(inspection: IBrdInspectionEdition, stepIndex: number): boolean {
    return !isNil(inspection?.workflow?.content?.steps[stepIndex]?.actions);
  }

  public static create(inspection: {
    id: number;
    notifyEntity: boolean;
    date: Date;
    products: IInspectionProduct[];
    entityId: number;
    delegatedById?: number;
    inspectorId?: number;
    productId: number;
    workflowId: number;
    inspectionTypeId: number;
    producedRatio: number;
    packedRatio: number;
    isDefectsPerProduct: boolean;
    isAqlPerProduct: boolean;
    aqlDefects: AqlDefectsType;
    samplingSize: ISamplingSize;
    measurementsSamplingSize: IMeasurementSamplingSize;
    updateDate: Date;
    updateTimezone: string;
    workflow: IInspectionEditionWorkflow;
    draftInspectionId: string;
    referenceSample?: IBrdInspectionReferenceSample;
    sampleCollection?: ISampleCollection;
    method: InspectionMethod;
    status: InspectionStatus;
    supplierRequest?: boolean;
    responsibleEntityId?: number;
    brandId?: number;
    inspectionUuid?: string;
    createdOffline: boolean;
    additionalInformation?: IInspectionAdditionalInformation;
  }): IBrdInspectionEdition {
    return new BrdInspectionEdition(
      inspection.id,
      inspection.inspectionUuid,
      inspection.createdOffline,
      null,
      inspection.notifyEntity,
      inspection.date,
      inspection.products,
      inspection.entityId,
      inspection.workflowId,
      inspection.inspectionTypeId,
      inspection.producedRatio,
      inspection.packedRatio,
      inspection.isDefectsPerProduct,
      inspection.isAqlPerProduct,
      inspection.aqlDefects,
      inspection.samplingSize,
      inspection.measurementsSamplingSize,
      inspection.draftInspectionId,
      inspection.method,
      inspection.updateDate,
      inspection.updateTimezone,
      inspection.workflow,
      inspection.referenceSample,
      inspection.inspectorId,
      inspection.status,
      inspection.supplierRequest,
      inspection.sampleCollection,
      inspection.delegatedById,
      inspection.responsibleEntityId,
      inspection.brandId,
      inspection.additionalInformation
    );
  }

  public static createFromDto(inspectionDto: IInspectionEditionDto): BrdInspectionEdition {
    return new BrdInspectionEdition(
      inspectionDto.id,
      inspectionDto.inspectionUuid,
      inspectionDto.createdOffline,
      inspectionDto.version,
      inspectionDto.notifyEntity,
      inspectionDto.plannedDate,
      inspectionDto.products,
      inspectionDto.entityId,
      inspectionDto.workflowId,
      inspectionDto.inspectionTypeId,
      inspectionDto.producedRatio,
      inspectionDto.packedRatio,
      inspectionDto.isDefectsPerProduct,
      inspectionDto.isAqlPerProduct,
      inspectionDto.aqlDefects,
      inspectionDto.samplingSize,
      inspectionDto.measurementsSamplingSize,
      inspectionDto.draftInspectionId,
      inspectionDto.method,
      inspectionDto.updateDate,
      inspectionDto.updateTimezone,
      inspectionDto.workflow,
      inspectionDto.referenceSample,
      inspectionDto.inspectorId,
      inspectionDto.status,
      inspectionDto.supplierRequest,
      inspectionDto.sampleCollection ? SampleCollection.create(inspectionDto.sampleCollection) : null,
      inspectionDto.delegatedById,
      inspectionDto.responsibleEntityId,
      inspectionDto.brandId,
      inspectionDto.additionalInformation,
      inspectionDto.customFieldsWithValues
    );
  }

  public static newEmpty(): IBrdInspectionEdition {
    return new BrdInspectionEdition(
      null,
      null,
      false,
      null,
      true,
      null,
      [],
      null,
      null,
      null,
      null,
      null,
      true,
      false,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
  }

  private constructor(
    public id: number,
    public inspectionUuid: string,
    public createdOffline: boolean,
    public version: number,
    public notifyEntity: boolean,
    public plannedDate: Date,
    public products: IInspectionProduct[],
    public entityId: number,
    public workflowId: number,
    public inspectionTypeId: number,
    public producedRatio: number,
    public packedRatio: number,
    public isDefectsPerProduct: boolean,
    public isAqlPerProduct: boolean,
    public aqlDefects: AqlDefectsType,
    public samplingSize: ISamplingSize,
    public measurementsSamplingSize: IMeasurementSamplingSize,
    public draftInspectionId: string,
    public method: InspectionMethod,
    public updateDate?: Date,
    public updateTimezone?: string,
    public workflow?: IInspectionEditionWorkflow,
    public referenceSample?: IBrdInspectionReferenceSample,
    public inspectorId?: number,
    public status?: InspectionStatus,
    public supplierRequest?: boolean,
    public sampleCollection?: ISampleCollection,
    public delegatedById?: number,
    public responsibleEntityId?: number,
    public brandId?: number,
    public additionalInformation?: IInspectionAdditionalInformation,
    public customFieldsWithValues?: CustomFieldWithValueDTO[]
  ) {}
}
