import { EQpHttpStatusCode } from '@library/models/qp-http.models';
import { AuthenticationService } from '@one/app/shared/services/auth/authentication.service';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

/**
 * @description
 * Not provided in root on purpose
 */
@Injectable()
export class AuthExpiredInterceptor implements HttpInterceptor {
  public constructor(private readonly _authenticationService: AuthenticationService) {}

  public intercept<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    return next.handle(request).pipe(
      catchError((err: unknown): Observable<never> => {
        if (err instanceof HttpErrorResponse && err.status === EQpHttpStatusCode.UNAUTHORIZED) {
          this._authenticationService.handleUnauthorized(err);
        }

        return throwError((): unknown => err);
      })
    );
  }
}
