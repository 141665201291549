import { ChecklistRatingSystemDTO } from '@library/dto/checklist/checklist-rating-system.dto';
import { IspWorkflowStepActionTestsChecklistContent } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/isp-workflow-step-action-checklist-content/isp-workflow-step-action-checklist-content';
import {
  ETestChecklistStatus,
  ITestsChecklistDetails,
  IChecklistTestProductCategory,
} from '@one/app/shared/models/checklist/tests-checklist.models';

export class IspWorkflowStepTestsChecklistAction implements ITestsChecklistDetails {
  public id: number | undefined = undefined;
  public name: string;
  public content: IspWorkflowStepActionTestsChecklistContent;
  public publicationStatus: ETestChecklistStatus;
  public productCategories: IChecklistTestProductCategory[] = [];
  public isQima: boolean;
  public rating?: ChecklistRatingSystemDTO = undefined;

  public constructor(testsChecklist: ITestsChecklistDetails) {
    this.id = testsChecklist.id;
    this.name = testsChecklist.name;
    this.content = new IspWorkflowStepActionTestsChecklistContent(testsChecklist.content);
    this.publicationStatus = testsChecklist.publicationStatus;
    this.productCategories = testsChecklist.productCategories ?? [];
    this.isQima = testsChecklist.isQima ?? false;
    this.rating = testsChecklist.rating;
  }
}
