// @ts-strict-ignore
import { EQpMeasurementUnit } from '@library/models/qp-measurements.models';
import { EInspectionResult } from '@one/app/pages/brd/pages/report/pages/inspection/pages/id/brd-report-inspection-id.models';
import {
  IMeasure,
  IMeasureConfiguration,
  IMeasuresChecklist,
  IMeasuresChecklistSection,
  IMeasuresSampleAnswer,
  IProductVariances,
  ISectionVariance,
  IVariance,
} from '@one/app/pages/isp/shared/models/variances/inspection-variances.models';
import { EMeasurementUnitTolerance } from '@one/app/shared/models/measurements/measurements.models';
import { EWorkflowPath } from '@one/app/shared/models/workflow/workflow.models';
import { has, head, isEmpty, isNil } from 'lodash/index';

export class MeasuresChecklistAction {
  public unit: EQpMeasurementUnit;
  public unitTolerance: EMeasurementUnitTolerance;
  public variances: IVariance[];
  public result: EInspectionResult;
  public isQima: boolean;
  public sections: IMeasuresChecklistSection[] | IMeasuresChecklist[];

  public constructor(productVariances: IProductVariances) {
    this.unit = productVariances.unit;
    this.unitTolerance = productVariances.unitTolerance;
    this.variances = productVariances.variances;
    this.result = productVariances.result ?? EInspectionResult.FAIL;
    this.isQima = productVariances.isQima ?? false;
    this.sections = this.sectionedVariance(this.variances);
  }

  public sectionedVariance(variances: IVariance[]): IMeasuresChecklistSection[] | IMeasuresChecklist[] {
    const hasSection: boolean = this.hasSections(variances);

    if (!hasSection) {
      return [{ variances }];
    }

    const measureChecklistMap: Map<string, IVariance[]> = new Map<string, IVariance[]>();

    variances.forEach((measureVariance: IVariance): void => {
      const { id, measureModel, measuresEntries, variance } = measureVariance;

      measureModel.forEach((varianceMeasureModel: IMeasureConfiguration): void => {
        if (!measureChecklistMap.has(varianceMeasureModel.section)) {
          measureChecklistMap.set(varianceMeasureModel.section, []);
        }
      });
      Array.from(measureChecklistMap.keys()).forEach((section: string): void => {
        const sectionMeasureModels = measureModel.filter(
          (varianceMeasureModel: IMeasureConfiguration): boolean => varianceMeasureModel.section === section
        );
        const sectionMeasuresEntries: IMeasuresSampleAnswer[] = this._getMeasureSample(measuresEntries, section);
        const varianceMeasureModelVariance = {
          id,
          section,
          numberOfEntries: sectionMeasuresEntries.length,
          measureModel: sectionMeasureModels,
          variance,
          measuresEntries: sectionMeasuresEntries,
        };

        if (!isEmpty(sectionMeasuresEntries)) {
          measureChecklistMap.get(section).push(varianceMeasureModelVariance);
        }
      });
    });

    const measureChecklist: IMeasuresChecklistSection[] = Array.from(
      measureChecklistMap,
      ([name, variances]): IMeasuresChecklistSection => ({
        name,
        variances: this._addSectionVarianceId(variances),
      })
    );

    return measureChecklist;
  }

  public hasSections(variances: IVariance[]): boolean {
    if (isEmpty(variances)) {
      return false;
    }

    const { measureModel } = head(variances);

    if (isNil(measureModel)) {
      return false;
    }

    return measureModel.some((measureModel: IMeasureConfiguration): boolean => has(measureModel, 'section'));
  }

  private _getMeasureSample(measuresEntries: IMeasuresSampleAnswer[], section: string): IMeasuresSampleAnswer[] {
    return measuresEntries
      .map((measureEntry: IMeasuresSampleAnswer): IMeasuresSampleAnswer => {
        const measureAnswers: IMeasure[] = measureEntry.measureAnswers.filter((measureAnswer: IMeasure): boolean => {
          return measureAnswer.section === section;
        });

        return { ...measureEntry, measureAnswers };
      })
      .filter((measureEntry: IMeasuresSampleAnswer): boolean => !isEmpty(measureEntry.measureAnswers));
  }

  private _addSectionVarianceId(variances: IVariance[]): ISectionVariance[] {
    return variances.map((variance: IVariance, index: number): ISectionVariance => {
      const paths: string[] = variance.id.split('-');

      paths[EWorkflowPath.PRODUCT_VARIANCE] = `${index + EWorkflowPath.INDEX_SUFFIX}`;

      return { ...variance, sectionVarianceId: paths.join('-') };
    });
  }
}
