<div *ngIf="subMenu" [ngClass]="currentMenuPosition === menuPositions.COLLAPSE ? 'collapse' : 'expand'" class="nav-sub-menu">
  <div *ngIf="subMenu" class="entry-menu">
    <div class="menu-title qp-d-flex qp-align-items-center">
      <one-subnav-breadcrumbs></one-subnav-breadcrumbs>
      <div class="margin-left-auto">
        <div class="menu-item__quick-search" *ngIf="shouldDisplayQuickSearch">
          <one-quick-search></one-quick-search>
        </div>
        <div class="menu-item__notifuse" *ngIf="isNotifuseUser">
          <one-notifuse-nav class="bell-icon"></one-notifuse-nav>
        </div>
        <div *ngIf="subMenu.defaultActionRoutes && subMenu.defaultActionRoutes.length > 0" class="action-button">
          <qp-dropdown-nav
            *ngIf="subMenu.defaultActionRoutes.length > 1"
            [buttonLabel]="subMenu.creationButtonLabel"
            [displayedRoutes]="creationRoutes"
          ></qp-dropdown-nav>
          <a
            *ngIf="subMenu.defaultActionRoutes.length === 1"
            [qpButtonSize]="buttonSizes.MEDIUM"
            [qpButtonType]="buttonTypes.HERO_BLUE"
            [routerLink]="(subMenu?.defaultActionRoutes)?.[0].routerLink"
            (click)="onSubNavLinkClick()"
            qpButton
          >
            <div class="creationButton">
              <span class="creationButton_text">{{ subMenu.creationButtonLabel | translate }}</span>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div *ngIf="subRoutesDisplayed" class="entry-sub-menu">
      <div *ngFor="let subEntity of subMenu.children" (click)="onSubRouteClick(subEntity)">
        <a *ngIf="!subEntity.isDefaultAction && subEntity.title">
          <span
            routerLinkActive="enable"
            [routerLinkActiveOptions]="subEntity.routerLinkActiveOption"
            [routerLink]="subEntity.routerLink"
            class="submenu-title interactive-1"
          >
            {{ subEntity.title | translate }}
            <ng-container *ngIf="subEntity.isCounterDisplayed">({{ subEntity.counter$ | async }})</ng-container>
          </span>
        </a>
      </div>
    </div>
  </div>
</div>
