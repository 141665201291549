import { DEBUG_INFO_ENABLED } from '@one/app/app.constants';
import { AppRouteReuseStrategy } from '@one/app/app.route-reuse-strategy';
import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules, RouteReuseStrategy } from '@angular/router';

const ROUTES: Routes = [
  {
    path: 'account',
    loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule),
  },
  {
    path: 'activate',
    loadChildren: () => import('./pages/activate/activate.module').then(m => m.ActivateModule),
  },
  {
    path: 'brd',
    loadChildren: () => import('./pages/brd/brd.module').then(m => m.BrdModule),
  },
  {
    path: 'sp',
    loadChildren: () => import('./pages/sp/sp.module').then(m => m.SpModule),
  },
  {
    path: 'isp',
    loadChildren: () => import('./pages/isp/isp.module').then(m => m.IspModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'connect',
    loadChildren: () => import('./pages/connect/connect.module').then(m => m.ConnectModule),
  },
  {
    path: 'coming-soon',
    loadChildren: () => import('./pages/coming-soon/coming-soon.module').then(m => m.ComingSoonModule),
  },
  {
    path: 'forbidden',
    loadChildren: () => import('./pages/forbidden/forbidden.module').then(m => m.ForbiddenModule),
  },
  {
    path: 'reset',
    loadChildren: () => import('./pages/reset/reset.module').then(m => m.ResetModule),
  },
  {
    path: 'select-brand',
    loadChildren: () => import('./pages/select-brand/select-brand.module').then(m => m.SelectBrandModule),
  },
  {
    path: 'error',
    loadChildren: () => import('./pages/error/error.module').then(m => m.ErrorModule),
  },
  {
    path: 'invitation',
    loadChildren: () => import('./pages/invitation/invitation.module').then(m => m.InvitationModule),
  },
  {
    path: 'entity',
    loadChildren: () => import('./pages/entity/entity.module').then(m => m.EntityModule),
  },
  {
    path: '**',
    redirectTo: '/error/404',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(ROUTES, {
      useHash: true,
      enableTracing: DEBUG_INFO_ENABLED,
      scrollPositionRestoration: 'top',
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: AppRouteReuseStrategy,
    },
  ],
  exports: [RouterModule],
})
export class QimaBrandFrontAppRoutingModule {}
