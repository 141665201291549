import { IspWorkflowStepGroupAction } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/isp-workflow-step-grouped-action';
import { IspWorkflowStepGroupDefectsChecklistAction } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/isp-workflow-step-grouped-action-defects-checklist/isp-workflow-step-grouped-action-defects-checklist';
import { IspWorkflowStepGroupMeasuresChecklistAction } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/isp-workflow-step-grouped-action-measures-checklist/isp-workflow-step-grouped-action-measures-checklist';
import { IspWorkflowStepGroupSimplifiedMeasurementChecklistAction } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/isp-workflow-step-grouped-action-simplified-measurement-checklist/isp-workflow-step-grouped-action-simplified-measurement-checklist';
import { IspWorkflowStepGroupTestsChecklistAction } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/isp-workflow-step-grouped-action-tests-checklist/isp-workflow-step-grouped-action-tests-checklist';
import { IspWorkflowStepActionType } from '@one/app/pages/isp/pages/inspection/pages/id/shared/models/isp-workflow-step-action.models';
import { IspWorkflowStepGroupActionType } from '@one/app/pages/isp/pages/inspection/pages/id/shared/models/isp-workflow-step-grouped-action.models';
import { isEmpty } from 'lodash/index';

/**
 * @todo is there a better way to reduce the complexity here?
 * @todo use generic types to avoid to check the type of the first action
 * @param actions
 */
export function createWorkflowStepGroupAction(actions: IspWorkflowStepActionType[]): IspWorkflowStepGroupActionType | null {
  if (isEmpty(actions)) {
    return null;
  }

  if (IspWorkflowStepGroupAction.isTestsChecklistActions(actions)) {
    return new IspWorkflowStepGroupTestsChecklistAction(actions);
  } else if (IspWorkflowStepGroupAction.isDefectsChecklistActions(actions)) {
    return new IspWorkflowStepGroupDefectsChecklistAction(actions);
  } else if (IspWorkflowStepGroupAction.isMeasuresChecklistActions(actions)) {
    return new IspWorkflowStepGroupMeasuresChecklistAction(actions);
  } else if (IspWorkflowStepGroupAction.isSimplifiedMeasurementChecklistActions(actions)) {
    return new IspWorkflowStepGroupSimplifiedMeasurementChecklistAction(actions);
  }

  return null;
}
