import { qpChangeDomain } from '@library/functions/changeDomain/qp-change-domain';
import { IQimaConnectConfig } from '@one/app/shared/models/connect/connect.models';
import { QIMA_CONNECT_CONFIG } from '@one/app/shared/tokens/qima-connect-config.token';
import { WINDOW } from '@one/app/shared/tokens/window.token';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class QimaConnectInterceptor implements HttpInterceptor {
  public constructor(
    @Inject(QIMA_CONNECT_CONFIG) private readonly _qimaConnectConfig: IQimaConnectConfig,
    @Inject(WINDOW) private readonly _window: Window
  ) {}

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let request = req;
    let overridenUrl = req.url;

    if (this._qimaConnectConfig.url && req.url.startsWith(this._qimaConnectConfig.url) && this._window.location.origin.endsWith('.cn')) {
      const qimaConnectUrl = this._qimaConnectConfig.cnUrl ?? qpChangeDomain(this._qimaConnectConfig.url, '.cn');

      overridenUrl = req.url.replace(this._qimaConnectConfig.url, qimaConnectUrl);
    }

    if (
      this._qimaConnectConfig.enabled &&
      ((this._qimaConnectConfig.url && req.url.startsWith(this._qimaConnectConfig.url)) || req.url.endsWith('/api/auth/qima-connect'))
    ) {
      request = req.clone({
        url: overridenUrl,
        withCredentials: false,
      });
    }

    return next.handle(request);
  }
}
