import { QpNamedMiniRange } from '@library/classes/qp-named-mini-range/qp-named-mini-range';
import { AqlDefectsDTO } from '@library/dto/aql-defects.dto';
import {
  AQL_CODE_LETTER_QUALITY_LEVEL_SAMPLING_PLANS,
  AQL_LOT_SIZE_LEVEL_CODE_LETTERS,
  EAqlCodeLetter,
  EAqlLotSize,
  EInspectionLevel,
  LOT_SIZE_RANGES,
} from '@library/dto/workflow/aql.dto';
import { ESamplingSizeLotSizeSource, IInspectionSamplingSizesDTO, ISamplingPlan } from '@library/dto/workflow/sampling-size.dto';
import { AqlTypes } from '@library/dto-enums/aql-types.dto-enum';
import { AqlValue } from '@library/dto-enums/aql-value.dto-enum';
import { SamplingSizeType } from '@library/dto-enums/sampling-size-type.dto-enum';
import { qpIsNotNil } from '@library/functions/checks/qp-is-not-nil';
import { qpParseInt } from '@library/functions/math/qp-parse-int';
import { AqlDefectsType } from '@one/app/shared/models/defects/aql-defects.models';
import {
  ESamplingSizeTypes,
  IWorkflowFixedSamplingSize,
  IWorkflowNonAqlSamplingSize,
  IWorkflowSamplingSize,
  SamplingSize,
  WorkflowSamplingSizeType,
} from '@one/app/shared/models/sampling/sampling-size.models';
import { QimaOptionalType } from '@qima/ngx-qima';
import { isNil } from 'lodash/index';

export interface IDefectSamplingPlan {
  criticalPlan: QimaOptionalType<ISamplingPlan>;
  majorPlan: QimaOptionalType<ISamplingPlan>;
  minorPlan: QimaOptionalType<ISamplingPlan>;
}

export class DefectSamplingSizeSettingsUtils {
  public static convertDefectToCalculationSamplingSizeType(defectSamplingSizeType: AqlTypes): SamplingSizeType {
    switch (defectSamplingSizeType) {
      case AqlTypes.STANDARD_AQL_DEFECTS:
      case AqlTypes.CUSTOM_AQL_DEFECTS:
        return SamplingSizeType.AQL_SAMPLE_SIZE;
      case AqlTypes.QUANTITY_AQL_DEFECTS:
        return SamplingSizeType.QUANTITY_SAMPLE_SIZE;
      case AqlTypes.NOT_USED:
        return SamplingSizeType.NON_AQL_SAMPLE_SIZE;
    }
  }

  public static fromLegacySamplingSizes(
    samplingSize: WorkflowSamplingSizeType | SamplingSize
  ): QimaOptionalType<IInspectionSamplingSizesDTO> {
    return samplingSize
      ? {
          type: samplingSize.type as string as SamplingSizeType,
          sampleSize: 'sampleSize' in samplingSize ? samplingSize.sampleSize : null,
          inspectionLevel: 'inspectionLevel' in samplingSize ? (samplingSize.inspectionLevel as string as EInspectionLevel) : null,
          lotSize: 'lotSize' in samplingSize ? samplingSize.lotSize : null,
          products: 'products' in samplingSize ? samplingSize.products : [],
          criticalSampleSize: 'criticalSampleSize' in samplingSize ? samplingSize.criticalSampleSize : null,
          majorSampleSize: 'majorSampleSize' in samplingSize ? samplingSize.majorSampleSize : null,
          minorSampleSize: 'minorSampleSize' in samplingSize ? samplingSize.minorSampleSize : null,
          sampleUnit: 'sampleUnit' in samplingSize ? samplingSize.sampleUnit : null,
          aqlModificationReason: 'aqlModificationReason' in samplingSize ? samplingSize.aqlModificationReason : null,
        }
      : null;
  }

  public static fromLegacyAql(aql: AqlDefectsType): AqlDefectsDTO {
    const { critical, major, minor } =
      'critical' in aql && 'major' in aql && 'minor' in aql ? aql : { critical: null, major: null, minor: null };

    switch (aql.type) {
      case AqlTypes.STANDARD_AQL_DEFECTS:
      case AqlTypes.CUSTOM_AQL_DEFECTS:
        return {
          type: aql.type,
          critical: critical ?? AqlValue.NOT_ALLOWED,
          major: major ?? AqlValue.NOT_ALLOWED,
          minor: minor ?? AqlValue.NOT_ALLOWED,
        };
      case AqlTypes.NOT_USED:
      case AqlTypes.QUANTITY_AQL_DEFECTS:
        return {
          type: aql.type,
          critical: qpParseInt(critical?.toString() ?? '') ?? 0,
          major: qpParseInt(major?.toString() ?? '') ?? 0,
          minor: qpParseInt(minor?.toString() ?? '') ?? 0,
        };
    }
  }

  // Used when we only have workflow data available: it's enough as long as we don't have to delve into AQL computation
  public static fromLegacyWorkflowSamplingSize(
    workflowSamplingSizeType: QimaOptionalType<WorkflowSamplingSizeType>
  ): QimaOptionalType<IInspectionSamplingSizesDTO> {
    switch (workflowSamplingSizeType?.type) {
      case ESamplingSizeTypes.AQL_SAMPLE_SIZE:
        return DefectSamplingSizeSettingsUtils._fromLegacyAqlModel(workflowSamplingSizeType);
      case ESamplingSizeTypes.QUANTITY_SAMPLE_SIZE:
        return DefectSamplingSizeSettingsUtils._fromLegacyQuantityModel(workflowSamplingSizeType);
      case ESamplingSizeTypes.NON_AQL_SAMPLE_SIZE:
        return DefectSamplingSizeSettingsUtils._fromLegacyNonAqlModel(workflowSamplingSizeType as unknown as IWorkflowNonAqlSamplingSize);
      default:
        return null;
    }
  }

  private static _fromLegacyAqlModel(oldModel: IWorkflowSamplingSize): IInspectionSamplingSizesDTO {
    return {
      type: SamplingSizeType.AQL_SAMPLE_SIZE,
      products: oldModel.products,
      inspectionLevel: oldModel.inspectionLevel as unknown as EInspectionLevel,
      criticalSampleSize: oldModel.criticalSampleSize,
      majorSampleSize: oldModel.majorSampleSize,
      minorSampleSize: oldModel.minorSampleSize,
    };
  }

  private static _fromLegacyQuantityModel(oldModel: IWorkflowFixedSamplingSize): IInspectionSamplingSizesDTO {
    return {
      type: SamplingSizeType.QUANTITY_SAMPLE_SIZE,
      products: oldModel.products,
      sampleSize: oldModel.sampleSize,
    };
  }

  private static _fromLegacyNonAqlModel(oldModel: IWorkflowNonAqlSamplingSize): IInspectionSamplingSizesDTO {
    return {
      type: SamplingSizeType.NON_AQL_SAMPLE_SIZE,
      products: oldModel.products,
      sampleUnit: oldModel.sampleUnit,
      sampleSize: oldModel.sampleSize,
      lotSize: oldModel.lotSize,
    };
  }

  private readonly _samplingSizes: IInspectionSamplingSizesDTO;
  private readonly _aql: AqlDefectsDTO;

  public constructor(samplingSizes: IInspectionSamplingSizesDTO, aql: AqlDefectsDTO) {
    this._samplingSizes = samplingSizes;
    this._aql = aql;
  }

  public productsTotalLotSize(lotSizeSource: ESamplingSizeLotSizeSource): QimaOptionalType<number> {
    const productsLotSizes = this._samplingSizes.products?.map(
      (product): QimaOptionalType<number> =>
        lotSizeSource === ESamplingSizeLotSizeSource.PACKED_QUANTITY ? product?.lotSize : product?.productQuantity
    );

    if (!productsLotSizes.length || productsLotSizes.some((lotSize): boolean => isNil(lotSize))) {
      return null;
    }

    return productsLotSizes.reduce((acc: number, curr: QimaOptionalType<number>): number => acc + (curr ?? 0), 0);
  }

  /**
   * Convert the produced or ordered quantity into a lot size range
   *
   * @param {QimaOptionalType<number>} quantity The product produced or ordered quantity
   * @returns {EAqlLotSize|never} The matching lot size range, or an exception if the lot size is not in any known range
   */
  public lotSizeRange(quantity: QimaOptionalType<number>): EAqlLotSize | never {
    const range: QimaOptionalType<EAqlLotSize> = !isNil(quantity)
      ? LOT_SIZE_RANGES.find((range): boolean => new QpNamedMiniRange<EAqlLotSize>(range.min, range.max, range.lotSize).contains(quantity))
          ?.lotSize
      : null;

    if (isNil(range)) {
      throw new Error(`Could not find the AQL range for the quantity ${quantity}`);
    }

    return range;
  }

  /**
   * Compute the sampling plan the inspector will have to follow when inspecting for defects
   * (lookup AQL Sampling table to understand)
   *
   * @param {ESamplingSizeLotSizeSource} lotSizeSource Whether the sample size should be based on produced or ordered quantity
   * @returns {ISamplingPlan} The sampling values matching all the parameters
   */
  public computeSamplingPlan(lotSizeSource: ESamplingSizeLotSizeSource): QimaOptionalType<IDefectSamplingPlan> {
    switch (this._samplingSizes.type) {
      case SamplingSizeType.AQL_SAMPLE_SIZE:
        return this._computeAqlSamplingPlan(lotSizeSource);
      case SamplingSizeType.QUANTITY_SAMPLE_SIZE:
        return this._computeFixedQuantitySamplingPlan();
    }

    return null;
  }

  private _computeAqlSamplingPlan(lotSizeSource: ESamplingSizeLotSizeSource): QimaOptionalType<IDefectSamplingPlan> {
    if (!this._samplingSizes.inspectionLevel) {
      return null;
    }

    try {
      const lotSize = this.productsTotalLotSize(lotSizeSource);
      const lotSizeRange = this.lotSizeRange(lotSize);
      const codeLetter = AQL_LOT_SIZE_LEVEL_CODE_LETTERS[lotSizeRange][this._samplingSizes.inspectionLevel];

      return {
        criticalPlan: this._computeSamplingPlanForLevel(lotSize, codeLetter, this._aql.critical as AqlValue),
        majorPlan: this._computeSamplingPlanForLevel(lotSize, codeLetter, this._aql.major as AqlValue),
        minorPlan: this._computeSamplingPlanForLevel(lotSize, codeLetter, this._aql.minor as AqlValue),
      };
    } catch (_) {
      return null;
    }
  }

  private _computeSamplingPlanForLevel(lotSize: QimaOptionalType<number>, codeLetter: EAqlCodeLetter, level: AqlValue): ISamplingPlan {
    const samplingPlan = AQL_CODE_LETTER_QUALITY_LEVEL_SAMPLING_PLANS[codeLetter][level];
    // Sample size cannot be larger than the inspected quantity
    const sampleSize = isNil(lotSize) || samplingPlan.sampleSize > lotSize ? lotSize : samplingPlan.sampleSize;

    return { ...samplingPlan, sampleSize };
  }

  private _computeFixedQuantitySamplingPlan(): IDefectSamplingPlan {
    const getSamplingPlanForLevel = (acceptPoint: number): ISamplingPlan => ({
      acceptPoint,
      rejectPoint: acceptPoint + 1,
      sampleSize: this._samplingSizes.sampleSize,
    });

    return {
      criticalPlan: qpIsNotNil(this._aql.critical) ? getSamplingPlanForLevel(+this._aql.critical) : null,
      majorPlan: qpIsNotNil(this._aql.major) ? getSamplingPlanForLevel(+this._aql.major) : null,
      minorPlan: qpIsNotNil(this._aql.minor) ? getSamplingPlanForLevel(+this._aql.minor) : null,
    };
  }
}
