import { qpChangeDomain } from '@library/functions/changeDomain/qp-change-domain';
import { SERVER_API_URL } from '@one/app/app.constants';
import { IQimaConnectAuthenticationResponse, IRefreshTokenResponse } from '@one/app/pages/connect/connect.models';
import { IQimaConnectConfig } from '@one/app/shared/models/connect/connect.models';
import { QIMA_CONNECT_CONFIG } from '@one/app/shared/tokens/qima-connect-config.token';
import { WINDOW } from '@one/app/shared/tokens/window.token';
import { Location } from '@angular/common';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ConnectService {
  public constructor(
    private readonly _httpClient: HttpClient,
    @Inject(QIMA_CONNECT_CONFIG) private readonly _qimaConnectConfig: IQimaConnectConfig,
    @Inject(WINDOW) private readonly _window: Window,
    private readonly _location: Location
  ) {}

  public retrieveToken$(accessToken: string): Observable<HttpResponse<IQimaConnectAuthenticationResponse>> {
    const headers = new HttpHeaders().append('QConnect-Access-Token', accessToken);

    return this._httpClient.post<IQimaConnectAuthenticationResponse>(`${SERVER_API_URL}/api/auth/qima-connect`, null, {
      observe: 'response',
      headers,
    });
  }

  public logout$(accessToken: Readonly<string>, userName: Readonly<string>): Observable<void> {
    const headers = new HttpHeaders().append('Authorization', `Bearer ${accessToken}`);

    return this._httpClient.post<void>(`${this._qimaConnectConfig.url}/api/v1/auth/sign-out`, { email: userName }, { headers });
  }

  public refreshToken$(clientId: Readonly<string>, sub: Readonly<string>, refreshToken: Readonly<string>): Observable<string> {
    return this._httpClient
      .post<IRefreshTokenResponse>(`${this._qimaConnectConfig.url}/api/v1/auth/refresh`, { clientId, sub, refreshToken })
      .pipe(
        map((response): string => {
          return response.accessToken;
        })
      );
  }

  public redirectToConnectOrNothing(): void {
    if (this._qimaConnectConfig.enabled) {
      let targetUrl = this._qimaConnectConfig.url ?? '';

      if (this._window.origin.endsWith('.cn')) {
        targetUrl = this._qimaConnectConfig.cnUrl ?? qpChangeDomain(targetUrl, '.cn');
      }

      this._window.location.assign(
        `${targetUrl}${this._location.prepareExternalUrl('/login')}?origin=${this._window.location.origin}&redirect=/connect`
      );
    }
  }

  public isEnabled(): boolean {
    return this._qimaConnectConfig.enabled;
  }
}
