import { IspWorkflowStepActionCustomFields } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/isp-workflow-step-action-custom-fields/isp-workflow-step-action-custom-fields';
import { IspWorkflowStepActionDefectsChecklist } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/isp-workflow-step-action-defects-checklist/isp-workflow-step-action-defects-checklist';
import { IspWorkflowStepActionFindingsSummaryReview } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/isp-workflow-step-action-findings-summary-review/isp-workflow-step-action-findings-summary-review';
import { IspWorkflowStepActionInspectionDetails } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/isp-workflow-step-action-inspection-details/isp-workflow-step-action-inspection-details';
import { IspWorkflowStepActionInspectionPreparation } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/isp-workflow-step-action-inspection-preparation/isp-workflow-step-action-inspection-preparation';
import { IspWorkflowStepActionMeasuresChecklist } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/isp-workflow-step-action-measures-checklist/isp-workflow-step-action-measures-checklist';
import { IspWorkflowStepActionProductPicture } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/isp-workflow-step-action-product-picture/isp-workflow-step-action-product-picture';
import { IspWorkflowStepActionReport } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/isp-workflow-step-action-report/isp-workflow-step-action-report';
import { IspWorkflowStepActionSimplifiedMeasurementChecklist } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/isp-workflow-step-action-simplified-measurement-checklist/isp-workflow-step-action-simplified-measurement-checklist';
import { IspWorkflowStepActionTestsChecklist } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/isp-workflow-step-action-tests-checklist/isp-workflow-step-action-tests-checklist';
import { EWorkflowActionType, IWorkflow, IWorkflowAction } from '@one/app/shared/models/workflow/workflow.models';
import { IWorkflowInspection } from '@one/app/shared/models/workflows/workflow.models';

export const ISP_WORKFLOW_STEP_ACTION_STRATEGY = {
  [EWorkflowActionType.TESTS_CHECKLIST]: (action: Readonly<IWorkflowAction>): IspWorkflowStepActionTestsChecklist =>
    new IspWorkflowStepActionTestsChecklist(action),
  [EWorkflowActionType.DEFECTS_CHECKLIST]: (
    action: Readonly<IWorkflowAction>,
    inspection: Readonly<IWorkflowInspection>,
    workflow?: Readonly<IWorkflow>
  ): IspWorkflowStepActionDefectsChecklist =>
    new IspWorkflowStepActionDefectsChecklist(
      action,
      inspection?.isDefectsPerProduct,
      workflow?.aqlDefects ?? inspection?.aqlDefects,
      workflow?.samplingSize,
      inspection?.samplingSize,
      inspection?.products,
      inspection?.isAqlPerProduct
    ),
  [EWorkflowActionType.MEASURES_CHECKLIST]: (
    action: Readonly<IWorkflowAction>,
    inspection: Readonly<IWorkflowInspection>
  ): IspWorkflowStepActionMeasuresChecklist => new IspWorkflowStepActionMeasuresChecklist(action, inspection?.measurementsSamplingSize),
  [EWorkflowActionType.SIMPLIFIED_MEASUREMENTS_CHECKLIST]: (
    action: Readonly<IWorkflowAction>,
    inspection: Readonly<IWorkflowInspection>,
    workflow?: Readonly<IWorkflow>
  ): IspWorkflowStepActionSimplifiedMeasurementChecklist =>
    new IspWorkflowStepActionSimplifiedMeasurementChecklist(action, inspection, workflow),
  [EWorkflowActionType.FINDINGS_SUMMARY_REVIEW]: (action: Readonly<IWorkflowAction>): IspWorkflowStepActionFindingsSummaryReview =>
    new IspWorkflowStepActionFindingsSummaryReview(action),
  [EWorkflowActionType.INSPECTION_PREPARATION]: (action: Readonly<IWorkflowAction>): IspWorkflowStepActionInspectionPreparation =>
    new IspWorkflowStepActionInspectionPreparation(action),
  [EWorkflowActionType.INSPECTION_DETAILS]: (action: Readonly<IWorkflowAction>): IspWorkflowStepActionInspectionDetails =>
    new IspWorkflowStepActionInspectionDetails(action),
  [EWorkflowActionType.REPORT]: (action: Readonly<IWorkflowAction>): IspWorkflowStepActionReport => new IspWorkflowStepActionReport(action),
  [EWorkflowActionType.PRODUCT_PICTURE]: (action: Readonly<IWorkflowAction>): IspWorkflowStepActionProductPicture =>
    new IspWorkflowStepActionProductPicture(action),
  [EWorkflowActionType.CUSTOM_FIELDS]: (action: Readonly<IWorkflowAction>): IspWorkflowStepActionCustomFields =>
    new IspWorkflowStepActionCustomFields(action),
};
