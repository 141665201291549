// @ts-strict-ignore
import { QpAql } from '@library/classes/qp-aql/qp-aql';
import { ChecklistRatingResultDTO } from '@library/dto/checklist/checklist-rating-result.dto';
import { ChecklistRatingDTO } from '@library/dto/checklist/checklist-rating.dto';
import { DefectsCategoryDTO } from '@library/dto/checklist/defect/defects-category.dto';
import { DefectsChecklistDTO, DefectsChecklistGetDTO } from '@library/dto/checklist/defect/defects-checklist.dto';
import { DefectsChecklistUtils } from '@library/dto/checklist/defect/defects-checklist.utils';
import { RatingCriteriaDTO } from '@library/dto/rating-criteria.dto';
import { DefectAnsweredDTO } from '@library/dto/testschecklist/answer/defect-answered.dto';
import { SumRatingResultDTO } from '@library/dto/testschecklist/answer/sum-rating-result.dto';
import { WorkflowAnswerActionDefectDTO } from '@library/dto/testschecklist/answer/workflow/action/workflow-answer-action-defect.dto';
import { AqlTypes } from '@library/dto-enums/aql-types.dto-enum';
import { AqlValue } from '@library/dto-enums/aql-value.dto-enum';
import { DefectClassification } from '@library/dto-enums/defect-classification.dto-enum';
import { RatingTypes } from '@library/dto-enums/rating-types.dto-enum';
import { qpInfinityWhenNotFiniteNumber } from '@library/functions/checks/qp-infinity-when-not-finite-number';
import { qpIsFiniteNumber } from '@library/functions/checks/qp-is-finite-number';
import { qpZeroWhenNotFiniteNumber } from '@library/functions/checks/qp-zero-when-not-finite-number';
import { qpGetConstraintMatchingValue } from '@library/functions/qp-get-constraint-matching-value/qp-get-constraint-matching-value';
import { qpComputeWorstRatingIndex } from '@library/functions/ratings/qp-compute-worst-rating-index';
import { qpGetRatingWeight } from '@library/functions/ratings/qp-get-mixed-rating-result';
import { DEFAULT_RATING_RESULT } from '@library/models/qp-ratings.models';
import { DEFAULT_IS_AQL_PER_PRODUCT, DEFAULT_IS_DEFECTS_PER_PRODUCT } from '@one/app/app.constants';
import { EInspectionResult } from '@one/app/pages/brd/pages/report/pages/inspection/pages/id/brd-report-inspection-id.models';
import { EDefectClassificationAqlMapping } from '@one/app/pages/brd/pages/workflow/pages/defect-checklist/shared/models/defect-classification.enums';
import {
  IDefectsCategories,
  IDefectsChecklistDefect,
} from '@one/app/pages/isp/pages/inspection/pages/id/pages/defects-checklist/isp-inspection-id-defect.models';
import {
  IInspectionFindingsSummaryReviewAcceptPoints,
  IInspectionFindingsSummaryReviewDefectsDetails,
} from '@one/app/pages/isp/pages/inspection/pages/id/pages/findings-summary-review/pages/id/components/isp-inspection-id-findings-summary-review-id-defects-details/isp-inspection-id-findings-summary-review-id-defects-details.models';
import { IspDefectsChecklistAction } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/isp-defects-checklist-action';
import { IspWorkflowStepAction } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/isp-workflow-step-action';
import {
  INITIAL_COUNT,
  IspDefectsClassificationMapType,
  TWO_DIGITS,
  ZERO,
} from '@one/app/pages/isp/pages/inspection/pages/id/shared/models/isp-workflow-step-defects-checklist-action.models';
import { CUSTOM_QIMA_DEFECT_CHECKLIST } from '@one/app/shared/constants/custom-qima-defect-checklist';
import { AqlDefectsType } from '@one/app/shared/models/defects/aql-defects.models';
import { IAbstractMultiProduct } from '@one/app/shared/models/products/product.models';
import {
  ESamplingSizeTypes,
  ISamplingSize,
  ISamplingSizeProduct,
  IWorkflowSamplingSize,
  SamplingSize,
  WorkflowSamplingSizeType,
} from '@one/app/shared/models/sampling/sampling-size.models';
import {
  EWorkflowActionType,
  IWorkflowAction,
  WorkflowStepActionDefectsChecklistSamplingSizeType,
} from '@one/app/shared/models/workflow/workflow.models';
import { QimaOptionalType } from '@qima/ngx-qima';
import { add, compact, flatMap, flatten, isNil, round, toFinite } from 'lodash/index';

const DEFAULT_COUNT = 0;

export class IspWorkflowStepActionDefectsChecklist extends IspWorkflowStepAction {
  public static findDefectRating(
    quantity: number,
    samplingSize: number,
    ratingSystem: ChecklistRatingDTO[],
    criterias: RatingCriteriaDTO[]
  ): ChecklistRatingResultDTO {
    if (isNil(quantity) || isNaN(quantity) || samplingSize === ZERO || isNaN(samplingSize)) {
      return DEFAULT_RATING_RESULT;
    }

    const defectPercentage = (quantity * 100) / samplingSize;
    const matchingCriteria = qpGetConstraintMatchingValue(criterias, defectPercentage);

    if (defectPercentage > 100) {
      return DEFAULT_RATING_RESULT;
    }

    const checklistRatingIndex = ratingSystem.findIndex((rating): boolean => rating.id === matchingCriteria?.checklistRatingId);

    if (checklistRatingIndex === -1) {
      return DEFAULT_RATING_RESULT;
    }

    return {
      ...ratingSystem[checklistRatingIndex],
      weight: qpGetRatingWeight(ratingSystem.length, checklistRatingIndex),
    };
  }

  private static _isDefectPass(defectQuantity: Readonly<QimaOptionalType<number>>): boolean {
    return isNil(defectQuantity) || isNaN(defectQuantity) || defectQuantity <= ZERO;
  }

  private static _isWorkflowSamplingSize(samplingSize: Readonly<WorkflowSamplingSizeType>): samplingSize is IWorkflowSamplingSize {
    return samplingSize.type === ESamplingSizeTypes.AQL_SAMPLE_SIZE;
  }

  private static _isInspectionSamplingSize(samplingSize: Readonly<SamplingSize>): samplingSize is ISamplingSize {
    return samplingSize.type === ESamplingSizeTypes.AQL_SAMPLE_SIZE;
  }

  public type = EWorkflowActionType.DEFECTS_CHECKLIST;
  public defectsChecklist: IspDefectsChecklistAction | undefined = undefined;
  public orderedQuantity: QimaOptionalType<number>;
  private readonly _isDefectsPerProduct: boolean;
  private readonly _isAqlPerProduct: boolean;
  private readonly _aqlDefects: QimaOptionalType<AqlDefectsType> = undefined;
  private readonly _inspectionProducts: QimaOptionalType<IAbstractMultiProduct[]> = undefined;

  // @todo handle properly the standard AQL
  private readonly _samplingSize: QimaOptionalType<WorkflowStepActionDefectsChecklistSamplingSizeType>;

  public get aqlDefects(): AqlDefectsType | never | undefined {
    if (this._isAqlPerProduct) {
      return undefined;
    }

    if (!this._aqlDefects) {
      throw new Error('No AQL defects');
    }

    return this._aqlDefects;
  }

  // @todo handle properly the standard AQL
  public get samplingSize(): WorkflowStepActionDefectsChecklistSamplingSizeType | never | undefined {
    if (this._isAqlPerProduct) {
      return undefined;
    }

    if (!this._samplingSize) {
      throw new Error('No sampling size for this defects checklist');
    }

    return this._samplingSize;
  }

  protected get _isOngoing(): boolean {
    return !new DefectsChecklistUtils(
      this.defectsChecklist as unknown as DefectsChecklistDTO<DefectsCategoryDTO<DefectAnsweredDTO>>
    ).isCompletedAndValid(this._isDefectsPerProduct, this.action as unknown as WorkflowAnswerActionDefectDTO, this._inspectionProducts);
  }

  public get ratingSystem(): ChecklistRatingDTO[] {
    return this.defectsChecklist?.rating?.type === RatingTypes.RATINGS ? this.defectsChecklist?.rating.ratings ?? [] : [];
  }

  public get result(): EInspectionResult {
    if (this._isAqlPerProduct) {
      return EInspectionResult.FAIL;
    }

    const defects: IDefectsChecklistDefect[] = this.getFlattenedDefects();
    const defectsMap: IspDefectsClassificationMapType = this._defectsToClassificationMap(defects);
    const defectClassificationStrategy = {
      [DefectClassification.MINOR]: (defectsCount: Readonly<number>): boolean =>
        this._isDefectsClassificationPass(defectsCount, EDefectClassificationAqlMapping.MINOR),
      [DefectClassification.MAJOR]: (defectsCount: Readonly<number>): boolean =>
        this._isDefectsClassificationPass(defectsCount, EDefectClassificationAqlMapping.MAJOR),
      [DefectClassification.CRITICAL]: (defectsCount: Readonly<number>): boolean =>
        this._isDefectsClassificationPass(defectsCount, EDefectClassificationAqlMapping.CRITICAL),
    };

    return Array.from(defectsMap).every(([classification, defectsCount]): boolean =>
      defectClassificationStrategy[classification](defectsCount)
    )
      ? EInspectionResult.PASS
      : EInspectionResult.FAIL;
  }

  public constructor(
    protected action: Readonly<IWorkflowAction>,
    isDefectsPerProduct?: boolean,
    aqlDefects?: Readonly<AqlDefectsType>,
    workflowSamplingSize?: Readonly<QimaOptionalType<WorkflowSamplingSizeType>>,
    inspectionSamplingSize?: Readonly<QimaOptionalType<SamplingSize>>,
    inspectionProducts?: IAbstractMultiProduct[],
    isAqlPerProduct?: boolean
  ) {
    super(action);

    if (action.defectsChecklist) {
      this.defectsChecklist = new IspDefectsChecklistAction(action.defectsChecklist);
    }

    if (!isNil(workflowSamplingSize)) {
      if (IspWorkflowStepActionDefectsChecklist._isWorkflowSamplingSize(workflowSamplingSize)) {
        // @todo handle properly the standard AQL
        this._samplingSize = {
          inspectionLevel: workflowSamplingSize.inspectionLevel,
          sampleSize: QpAql.getHighestSampleSize([
            workflowSamplingSize.criticalSampleSize,
            workflowSamplingSize.majorSampleSize,
            workflowSamplingSize.minorSampleSize,
          ]),
          type: ESamplingSizeTypes.QUANTITY_SAMPLE_SIZE,
          products: workflowSamplingSize.products,
        };
      } else {
        this._samplingSize = workflowSamplingSize as WorkflowStepActionDefectsChecklistSamplingSizeType;
      }
    } else if (!isNil(inspectionSamplingSize)) {
      if (IspWorkflowStepActionDefectsChecklist._isInspectionSamplingSize(inspectionSamplingSize)) {
        // @todo handle properly the standard AQL
        this._samplingSize = {
          inspectionLevel: inspectionSamplingSize.inspectionLevel,
          sampleSize: QpAql.getHighestSampleSize([
            inspectionSamplingSize.criticalSampleSize,
            inspectionSamplingSize.majorSampleSize,
            inspectionSamplingSize.minorSampleSize,
          ]),
          type: ESamplingSizeTypes.QUANTITY_SAMPLE_SIZE,
          products: [],
        };
      } else {
        this._samplingSize = {
          sampleSize: 'sampleSize' in inspectionSamplingSize ? inspectionSamplingSize.sampleSize : ZERO,
          type: ESamplingSizeTypes.QUANTITY_SAMPLE_SIZE,
          products: [],
        };
      }
    }

    this._isDefectsPerProduct = isDefectsPerProduct ?? DEFAULT_IS_DEFECTS_PER_PRODUCT;
    this._isAqlPerProduct = isAqlPerProduct ?? DEFAULT_IS_AQL_PER_PRODUCT;
    this._aqlDefects = aqlDefects;
    this._inspectionProducts = inspectionProducts;
  }

  public computeSumRatingResult(): SumRatingResultDTO | undefined {
    const hasNoSumRating = isNil(this.defectsChecklist.sumRatingConfig);

    if (hasNoSumRating) {
      return undefined;
    }

    const quantities: number = this.getFlattenedDefects()
      .filter((defect: IDefectsChecklistDefect): boolean =>
        this.defectsChecklist.sumRatingConfig.defectIdsToInclude.includes(defect.defectId)
      )
      .reduce(
        (sum: number, defect: IDefectsChecklistDefect): number =>
          sum + defect.defectsFound?.reduce((acc, defectFound): number => acc + (defectFound.quantity ?? 0), 0) ?? 0,
        0
      );
    const ratingResult = IspWorkflowStepActionDefectsChecklist.findDefectRating(
      quantities,
      this.samplingSize.sampleSize,
      this.ratingSystem,
      this.defectsChecklist.sumRatingConfig?.ratings.criterias ?? []
    );
    let percentages = 0;

    if (!isNil(this.samplingSize) && quantities > 0 && this.samplingSize.sampleSize > 0) {
      percentages = (quantities * 100) / this.samplingSize.sampleSize;
    }

    return {
      ratingResult,
      percentages,
      quantities,
    };
  }

  public computeRatingResult(): ChecklistRatingResultDTO {
    const defects: IDefectsChecklistDefect[] = this.getFlattenedDefects();
    const ratingResults = defects.map((defect: Readonly<IDefectsChecklistDefect>): ChecklistRatingResultDTO => {
      return IspWorkflowStepActionDefectsChecklist.findDefectRating(
        defect.defectsFound?.reduce((acc, defectFound): number => acc + (defectFound.quantity ?? 0), 0) ?? 0,
        this.samplingSize.sampleSize,
        this.ratingSystem,
        defect.ratings.criterias ?? []
      );
    });
    const defectsQuantity: number = defects.reduce(
      (sum: number, defect: IDefectsChecklistDefect): number =>
        sum + defect.defectsFound?.reduce((acc, defectFound): number => acc + (defectFound.quantity ?? 0), 0) ?? 0,
      0
    );
    const sumRatingResult = this.computeSumRatingResult();

    if (!isNil(sumRatingResult)) {
      ratingResults.push(sumRatingResult.ratingResult);
    }

    const worstRatingIndex = qpComputeWorstRatingIndex(this.ratingSystem, ratingResults);

    if (worstRatingIndex >= 0) {
      return {
        ...this.ratingSystem[worstRatingIndex],
        weight: qpGetRatingWeight(this.ratingSystem.length, worstRatingIndex),
      };
    } else if (defectsQuantity > 0) {
      return DEFAULT_RATING_RESULT;
    }

    return this.ratingSystem[0]
      ? {
          ...this.ratingSystem[0],
          weight: 0,
        }
      : DEFAULT_RATING_RESULT;
  }

  public getDefectsCount(): number {
    return this.defectsChecklist?.categories.reduce(
      (count: Readonly<number>, categories: Readonly<IDefectsCategories>): number =>
        count +
        categories.defects.reduce(
          (defectsCount: Readonly<number>, defect: Readonly<IDefectsChecklistDefect>): number =>
            add(
              defectsCount,
              qpZeroWhenNotFiniteNumber(defect.defectsFound?.reduce((acc, defectFound): number => acc + (defectFound.quantity ?? 0), 0))
            ),
          INITIAL_COUNT
        ),
      INITIAL_COUNT
    );
  }

  /**
   * @description
   * It's not a rate, it's a percentage so a number between 0 and 100
   * Keep two digits (for example 12.45)
   * @returns {number} The defects percentage (from 0 to 100)
   */
  public getDefectsPercentage(): number | never {
    if (this.samplingSize.sampleSize === ZERO) {
      return 0;
    }

    return round((this.getDefectsCount() / this.samplingSize.sampleSize) * 100, TWO_DIGITS);
  }

  public setOrderedQuantity(orderedQuantity: number): void {
    this.orderedQuantity = orderedQuantity;
  }

  public getDefectsDetails(): IInspectionFindingsSummaryReviewDefectsDetails {
    const defectsChecklist: IDefectsChecklistDefect[] = compact(
      flatten(
        this.defectsChecklist?.categories.map((defectsCategories: Readonly<IDefectsCategories>): (IDefectsChecklistDefect | null)[] =>
          defectsCategories.defects.map(
            (
              defectsChecklistDefect: Readonly<IDefectsChecklistDefect>
            ): (IDefectsChecklistDefect & { defectTotalQuantity?: number }) | null => {
              const defectTotalQuantity =
                defectsChecklistDefect.defectsFound?.reduce((acc, defectFound): number => acc + (defectFound?.quantity ?? 0), 0) ?? 0;

              if (!defectTotalQuantity) {
                return null;
              }

              return {
                id: String(defectsChecklistDefect.id),
                defectId: String(defectsChecklistDefect.defectId),
                name: defectsChecklistDefect.name,
                description: defectsChecklistDefect.description,
                classification: defectsChecklistDefect.classification,
                defectsFound: defectsChecklistDefect.defectsFound,
                defectTotalQuantity,
                documents: defectsChecklistDefect.documents,
                isCustom: defectsCategories.name === CUSTOM_QIMA_DEFECT_CHECKLIST,
              };
            }
          )
        )
      )
    );
    const defectsClassificationsQuantities = new DefectsChecklistUtils(
      this.defectsChecklist as unknown as DefectsChecklistGetDTO
    ).computeDefectsQuantityByClassification();

    return {
      minor: defectsClassificationsQuantities[DefectClassification.MINOR],
      major: defectsClassificationsQuantities[DefectClassification.MAJOR],
      critical: defectsClassificationsQuantities[DefectClassification.CRITICAL],
      defectsChecklist,
      checklistName: this.defectsChecklist?.name || '',
    };
  }

  public getAcceptPoints(): IInspectionFindingsSummaryReviewAcceptPoints | never {
    if (this.aqlDefects.type === AqlTypes.NOT_USED) {
      throw new Error('Cannot get accept points for aql defects type not used');
    }

    if (this.aqlDefects.type === AqlTypes.QUANTITY_AQL_DEFECTS) {
      return {
        critical: this.aqlDefects.critical ? toFinite(this.aqlDefects.critical) : DEFAULT_COUNT,
        major: this.aqlDefects.major ? toFinite(this.aqlDefects.major) : DEFAULT_COUNT,
        minor: this.aqlDefects.minor ? toFinite(this.aqlDefects.minor) : DEFAULT_COUNT,
      };
    }

    if (isNil(this.samplingSize.inspectionLevel)) {
      throw new Error('Should have an inspection level');
    }

    const totalProductQuantityCount: number = !isNil(this.orderedQuantity) ? this.orderedQuantity : this.getTotalProductQuantityCount();

    // Instead of throwing (because 0 is not a valid AQL quantity)
    // Just return an empty count
    if (totalProductQuantityCount === 0) {
      return {
        critical: DEFAULT_COUNT,
        major: DEFAULT_COUNT,
        minor: DEFAULT_COUNT,
      };
    }

    const aql: QpAql = new QpAql({
      inspectionLevel: this.samplingSize.inspectionLevel,
      criticalAQL: this.aqlDefects.critical,
      majorAQL: this.aqlDefects.major,
      minorAQL: this.aqlDefects.minor,
      quantity: totalProductQuantityCount,
    });

    return {
      critical: aql.criticalSamplingSize.acceptPoint,
      major: aql.majorSamplingSize.acceptPoint,
      minor: aql.minorSamplingSize.acceptPoint,
    };
  }

  public getTotalProductQuantityCount(): number {
    return this.samplingSize.products.reduce((totalQuantity: Readonly<number>, product: Readonly<ISamplingSizeProduct>): number => {
      return totalQuantity + (qpIsFiniteNumber(product.lotSize) ? product.lotSize : 0);
    }, 0);
  }

  public getFlattenedDefects(): IDefectsChecklistDefect[] {
    return flatMap(this.defectsChecklist.categories, (category: Readonly<IDefectsCategories>): IDefectsChecklistDefect[] => {
      return category.defects.map((defect: IDefectsChecklistDefect): IDefectsChecklistDefect & { defectChecklistName?: string } => ({
        ...defect,
        defectChecklistName: this.defectsChecklist.name,
      }));
    });
  }

  private _defectsToClassificationMap(defects: IDefectsChecklistDefect[]): IspDefectsClassificationMapType {
    const defectsMap: IspDefectsClassificationMapType = new Map<DefectClassification, number>();

    defects.forEach((defect: Readonly<IDefectsChecklistDefect>): void => {
      const defectTotalQuantity = defect.defectsFound?.reduce((acc, defectFound): number => acc + (defectFound.quantity ?? 0), 0) ?? 0;

      if (!IspWorkflowStepActionDefectsChecklist._isDefectPass(defectTotalQuantity)) {
        defectsMap.set(defect.classification, add(defectTotalQuantity, qpZeroWhenNotFiniteNumber(defectsMap.get(defect.classification))));
      }
    });

    return defectsMap;
  }

  private _isDefectsClassificationPass(
    defectsCount: Readonly<number>,
    classificationAqlMapping: Readonly<EDefectClassificationAqlMapping>
  ): boolean {
    /**
     * @todo PL-12824 - This is a temporary fix waiting the rating result calcultion to be implemented
     */
    if (this.aqlDefects.type === AqlTypes.NOT_USED) {
      return true;
    }

    if (this.aqlDefects.type === AqlTypes.QUANTITY_AQL_DEFECTS) {
      const aqlValue: QimaOptionalType<number> = this.aqlDefects[classificationAqlMapping];

      if (isNil(aqlValue)) {
        return true;
      }

      return defectsCount <= aqlValue;
    }

    const aqlValue: QimaOptionalType<AqlValue> = this.aqlDefects[classificationAqlMapping];

    if (isNil(aqlValue)) {
      return true;
    }

    const acceptPoints: IInspectionFindingsSummaryReviewAcceptPoints = this.getAcceptPoints();

    return defectsCount <= qpInfinityWhenNotFiniteNumber(acceptPoints[classificationAqlMapping]);
  }
}
