<div class="one-subnav-breadcrumbs">
  <ng-container *qpLargeDevice>
    <ol class="one-subnav-breadcrumbs-list">
      <li *ngFor="let crumb of breadcrumbs; let last = last" class="one-subnav-breadcrumbs-list__crumb">
        <span
          *ngIf="last"
          class="one-subnav-breadcrumbs-list__crumb-last"
          [innerText]="crumb.label | translate: crumb.translationParams"
        ></span>
        <a
          class="one-subnav-breadcrumbs-list__crumb-link"
          *ngIf="!last"
          [routerLink]="crumb.url"
          [innerText]="crumb.label | translate: crumb.translationParams"
        >
        </a>
        <span *ngIf="!last" class="one-subnav-breadcrumbs-list__crumb-separator">/</span>
      </li>
    </ol>
  </ng-container>

  <ng-container *qpSmallDevice>
    <div *ngIf="currentCrumb" class="one-subnav-breadcrumbs-mobile">
      <qp-icon
        *ngIf="hasPrevious"
        [name]="iconNames.IC_ARROW_LEFT"
        (click)="goToPrevious()"
        class="one-subnav-breadcrumbs-mobile__icon"
      ></qp-icon>
      <span class="one-subnav-breadcrumbs-mobile__label">{{ currentCrumb.label | translate: currentCrumb.translationParams }}</span>
    </div>
  </ng-container>
</div>
