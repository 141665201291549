import { AutoUnsubscriber } from '@library/classes/auto-unsubscriber/auto-unsubscriber';
import { QpLoggerService } from '@library/services/qp-logger/qp-logger.service';
import { Injectable } from '@angular/core';
import { SwUpdate, UpdateActivatedEvent, UpdateAvailableEvent } from '@angular/service-worker';

@Injectable({ providedIn: 'root' })
export class SwLogUpdateService extends AutoUnsubscriber {
  public constructor(private readonly _swUpdate: SwUpdate, private readonly _qpLoggerService: QpLoggerService) {
    super();
  }

  public init(): void {
    this.registerSubscription(
      this._swUpdate.available.subscribe(
        (event: UpdateAvailableEvent): void => {
          this._qpLoggerService.info('📣 Current version is', event.current);
          this._qpLoggerService.info('📣 Available version is', event.available);
        },
        (error: Error): void => {
          this._qpLoggerService.error(error);
        }
      )
    );

    this.registerSubscription(
      this._swUpdate.activated.subscribe(
        (event: UpdateActivatedEvent): void => {
          this._qpLoggerService.info('📣 Old version was', event.previous);
          this._qpLoggerService.info('📣 New version is', event.current);
        },
        (error: Error): void => {
          this._qpLoggerService.error(error);
        }
      )
    );
  }
}
