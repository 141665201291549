/* eslint-disable jsdoc/require-jsdoc */
import { RatingSystemDTO } from '@library/dto/rating-system.dto';
import { CustomFieldWithClientsDTO } from '@library/dto/workflow/custom-fields.dto';
import { ICreateInspectionDTO } from '@one/app/pages/brd/pages/inspection/pages/create-v2/brd-inspection-create-v2.dto';
import { IBrdInspectionEdition, IInspectionProduct } from '@one/app/pages/brd/pages/inspection/shared/models/brd-inspection.models';
import {
  EBulkInspectionMode,
  EBulkInspectionSiteMode,
} from '@one/app/pages/brd/pages/product-po/pages/po/pages/consult/models/brd-product-po-po-consult.models';
import { InspectionId } from '@one/app/pages/isp/shared/models/isp-inspection.models';
import { EGeolocationPositionError } from '@one/app/pages/isp/shared/services/isp-coordinates.models';
import { IAccount } from '@one/app/shared/models/account/account.models';
import { IInspectionConsultation } from '@one/app/shared/models/inspection-consultation/inspection-consultation.models';
import { IAbstractMultiProduct, IProduct } from '@one/app/shared/models/products/product.models';
import { ETrackingPictureSource, IInspectorPictureSettings } from '@one/app/shared/services/pictures/picture-importer.models';
import { AnalyticsManagerEventType } from '@one/app/shared/services/third-party/analytics-manager/models/analytics-manager.models';
import {
  EDataLayerEventAction,
  EDataLayerEventValue,
  EDataLayerType,
  EGTMTitle,
} from '@one/app/shared/services/third-party/analytics-manager/models/login.models';
import { ETrackingEventId, ITrackingEvent } from '@one/app/shared/services/third-party/analytics-manager/tracking/tracking.models';
import { QimaOptionalType } from '@qima/ngx-qima';
import { Dict } from 'mixpanel-browser';

/**
 * @param { IBrdInspectionEdition | IInspectionConsultation } inspection the full inspection object
 * @returns { Dict } Event properties
 */
function getInspectorInspectionEventProperties(inspection: IBrdInspectionEdition | IInspectionConsultation): Dict {
  const isIBrdInspectionEdition = (inspection): inspection is IBrdInspectionEdition => 'inspectionTypeId' in inspection;
  const getEventFromSelfStartedInspection = getEventFromIBrdInspectionEdition;
  const getEventFromStandardInspection = getEventFromIInspectionConsultation;

  return isIBrdInspectionEdition(inspection) ? getEventFromSelfStartedInspection(inspection) : getEventFromStandardInspection(inspection);
}

/**
 * @param { IProduct } product the full product object
 * @returns { Dict } Event properties
 */
function getProductProperties(product: IProduct): Dict {
  return {
    productId: product.id,
    brandId: product.brandId,
    categoryName: product.categoryName,
    createdBy: product.createdBy,
    createdDate: product.createdDate,
    lastModifiedBy: product.lastModifiedBy,
    lastModifiedDate: product.lastModifiedDate,
  };
}

/**
 * @param { IBrdInspectionEdition } inspection the full inspection object
 * @returns { Dict } Event properties
 */
function getEventFromIBrdInspectionEdition(inspection: IBrdInspectionEdition | ICreateInspectionDTO): Dict {
  const mainProduct: QimaOptionalType<IInspectionProduct> = inspection.products?.[0];

  return {
    inspectionId: inspection['id'] ?? null, // 👈 edition only
    inspectionTypeId: inspection.inspectionTypeId,
    workflowTemplateId: inspection.workflow?.workflowTemplateId,
    samplingType: inspection.samplingSize?.type,
    inspectionMethod: inspection.method,
    numbersOfProducts: inspection.products?.length,
    purchaseOrderId: mainProduct?.purchaseOrderId,
    productId: mainProduct?.productId,
    clientId: null, // @todo PL-15051 will add this missing information
    entityId: null, // @todo PL-15051 will add this missing information
  };
}

/**
 * @param { IInspectionConsultation } inspection the full inspection object
 * @returns { Dict } Event properties
 */
function getEventFromIInspectionConsultation(inspection: IInspectionConsultation): Dict {
  const mainProduct: QimaOptionalType<IAbstractMultiProduct> = inspection.products?.[0];

  return {
    inspectionId: inspection.id,
    inspectionTypeId: inspection.inspectionType.id,
    workflowTemplateId: inspection.workflowSnapshot?.id,
    samplingType: inspection.samplingSize?.type,
    inspectionMethod: inspection.method,
    numbersOfProducts: inspection.products?.length,
    purchaseOrderId: mainProduct?.purchaseOrderId,
    productId: mainProduct?.productId,
    clientId: null, // @todo PL-15051 will add this missing information
    entityId: null, // @todo PL-15051 will add this missing information
  };
}

/**
 * This function is used to craft a tracking event
 * of type INSPECTOR_INSPECTION_STARTED
 *
 * @param { IBrdInspectionEdition | IInspectionConsultation } inspection the full inspection object
 * @returns { ITrackingEvent } An event including the id and the properties
 */
export function anInspectionStartEvent(inspection: IBrdInspectionEdition | IInspectionConsultation): ITrackingEvent {
  return { id: ETrackingEventId.INSPECTOR_INSPECTION_STARTED, properties: getInspectorInspectionEventProperties(inspection) };
}

/**
 * This function is used to craft a tracking event
 * of type INSPECTOR_INSPECTION_COMPLETED
 *
 * @param { IBrdInspectionEdition | IInspectionConsultation } inspection the full inspection object
 * @returns { ITrackingEvent } An event including the id and the properties
 */
export function anInspectionCompleteEvent(inspection: IBrdInspectionEdition | IInspectionConsultation): ITrackingEvent {
  return { id: ETrackingEventId.INSPECTOR_INSPECTION_COMPLETED, properties: getInspectorInspectionEventProperties(inspection) };
}

/**
 * This function is used to craft a tracking event
 * of type BOOKING_SCHEDULED
 *
 * @param { IBrdInspectionEdition } inspection the full inspection object
 * @param {string} bookingVersion the version of the booking
 * @returns { ITrackingEvent } An event including the id and the properties
 */
export function aBookingScheduleEvent(inspection: IBrdInspectionEdition, bookingVersion: string): ITrackingEvent {
  return {
    id: ETrackingEventId.BOOKING_SCHEDULED,
    properties: { ...getEventFromIBrdInspectionEdition(inspection), bookingVersion },
  };
}

/**
 * This function is used to craft a tracking event
 * of type BOOKING_UPDATED
 *
 * @param { IBrdInspectionEdition } inspection the full inspection object
 * @param {string} bookingVersion the version of the booking
 * @returns { ITrackingEvent } An event including the id and the properties
 */
export function aBookingUpdateEvent(inspection: IBrdInspectionEdition | ICreateInspectionDTO, bookingVersion: string): ITrackingEvent {
  return {
    id: ETrackingEventId.BOOKING_UPDATED,
    properties: { ...getEventFromIBrdInspectionEdition(inspection), bookingVersion },
  };
}

/**
 * This function is used to craft a tracking event of type BOOKING_FAST_TRACK
 *
 * @param {EBulkInspectionMode}bulkMode bulk mode
 * @param {EBulkInspectionSiteMode} inspectionSiteMode inspection site mode
 * @param {number} inspectionAmount amount of created inspection
 */
export function aBookingFastTrackEvent(
  bulkMode: EBulkInspectionMode,
  inspectionSiteMode: EBulkInspectionSiteMode,
  inspectionAmount: number
): ITrackingEvent {
  return {
    id: ETrackingEventId.BOOKING_FAST_TRACK,
    properties: {
      bulkMode,
      inspectionSiteMode,
      createdInspectionAmount: inspectionAmount,
    },
  };
}

/**
 * This function is used to craft a tracking event
 * of type LOGIN
 *
 * @param { IAccount } account just an account
 * @returns { ITrackingEvent } An event including the id and the properties
 */
export function aLoginEvent(account: IAccount): ITrackingEvent {
  const event: AnalyticsManagerEventType = {
    category: EDataLayerType.LOGIN,
    action: EDataLayerEventAction.LOGIN,
    label: EGTMTitle.LOGIN,
    event: 'login',
    value: EDataLayerEventValue.USER_DATA,
    brandName: account.companyName,
    position: account.position,
    profiles: account.profiles,
  };

  return {
    id: ETrackingEventId.LOGIN,
    properties: event,
  };
}

/**
 * This function is used to craft a tracking event
 * of type SETTINGS_CUSTOM_FIELD_CREATE
 *
 * @param { CustomFieldWithClientsDTO } customFieldWithClients just a customFieldWithClientsDTO
 * @returns { ITrackingEvent } An event including the id and the properties
 */
export function aSettingsCustomFieldCreate(customFieldWithClients: CustomFieldWithClientsDTO): ITrackingEvent {
  return {
    id: ETrackingEventId.SETTINGS_CUSTOM_FIELD_CREATE,
    properties: {
      ...customFieldWithClients.field,
      clients: customFieldWithClients.clients,
    },
  };
}

/**
 * This function is used to craft a tracking event
 * of type SETTINGS_CUSTOM_FIELD_UPDATE
 *
 * @param { CustomFieldWithClientsDTO } customFieldWithClients just a customFieldWithClientsDTO
 * @returns { ITrackingEvent } An event including the id and the properties
 */
export function aSettingsCustomFieldUpdate(customFieldWithClients: CustomFieldWithClientsDTO): ITrackingEvent {
  return {
    id: ETrackingEventId.SETTINGS_CUSTOM_FIELD_UPDATE,
    properties: {
      ...customFieldWithClients.field,
      clients: customFieldWithClients.clients,
    },
  };
}

/**
 * This function is used to craft a tracking event
 * of type PO_PRODUCT_PRODUCT_CREATE
 *
 * @param { IProduct } product just a product
 * @returns { ITrackingEvent } An event including the id and the properties
 */
export function aProductCreate(product: IProduct): ITrackingEvent {
  return {
    id: ETrackingEventId.PO_PRODUCT_PRODUCT_CREATE,
    properties: getProductProperties(product),
  };
}

/**
 * This function is used to craft a tracking event
 * of type PO_PRODUCT_PRODUCT_UPDATE
 *
 * @param { IProduct } product just a product
 * @returns { ITrackingEvent } An event including the id and the properties
 */
export function aProductUpdate(product: IProduct): ITrackingEvent {
  return {
    id: ETrackingEventId.PO_PRODUCT_PRODUCT_UPDATE,
    properties: getProductProperties(product),
  };
}

/**
 * This function is used to craft a tracking event for WORKFLOW_ADDENDUM_***
 *
 * @param trackingEvent a tracking event related to workflow addendum
 * @param workflowId a workflow id
 * @returns { ITrackingEvent } An event including the id and the properties
 */
export function aWorkflowAddendumEvent(
  trackingEvent:
    | ETrackingEventId.WORKFLOW_ADDENDUM_SET_PICTURE
    | ETrackingEventId.WORKFLOW_ADDENDUM_DELETE_PICTURE
    | ETrackingEventId.WORKFLOW_ADDENDUM_SET_TEXT
    | ETrackingEventId.WORKFLOW_ADDENDUM_DELETE_TEXT,
  workflowId: number
): ITrackingEvent {
  return {
    id: trackingEvent,
    properties: {
      workflowId,
    },
  };
}

/**
 * This function is used to craft a tracking event
 * of type INSPECTOR_IMAGES_MASS_***
 *
 * @param trackingEvent a tracking event related to image mass edit
 * @param inspectionPictureSettings identifiers for the image set
 * @param imageCount number of images modified
 * @returns { ITrackingEvent } An event including the id and the properties
 */
export function anImageMassEditEvent(
  trackingEvent:
    | ETrackingEventId.INSPECTOR_IMAGES_MASS_CAPTION_SET
    | ETrackingEventId.INSPECTOR_IMAGES_MASS_SPOTLIGHT
    | ETrackingEventId.INSPECTOR_IMAGES_MASS_DELETE
    | ETrackingEventId.INSPECTOR_IMAGES_MASS_LINK_TO_TEST_RESULT,
  inspectionPictureSettings: IInspectorPictureSettings,
  imageCount: number
): ITrackingEvent {
  return {
    id: trackingEvent,
    properties: {
      ...inspectionPictureSettings,
      imageCount,
    },
  };
}

/**
 * This function is used to craft a tracking event
 * of type INSPECTOR_IMAGES_ANALYSIS_***_CHECK
 *
 * @param { IProduct } product just a product
 * @param trackingEvent
 * @param timeTaken
 * @param pictureCount
 * @returns { ITrackingEvent } An event including the id and the properties
 */
export function anImageAnalysisCheck(
  trackingEvent: ETrackingEventId.INSPECTOR_IMAGES_ANALYSIS_BLUR_CHECK | ETrackingEventId.INSPECTOR_IMAGES_ANALYSIS_ROTATION_CHECK,
  timeTaken: number,
  pictureCount: number
): ITrackingEvent {
  return {
    id: trackingEvent,
    properties: {
      timeTaken,
      pictureCount,
    },
  };
}

/**
 * This function is used to craft a tracking event
 * of type INSPECTOR_IMAGES_ANALYSIS_BLUR_CHECK_ACTION
 *
 * @param { 'REMOVE' | 'KEEP' } action the action taken by the user
 * @returns { ITrackingEvent } An event including the id and the properties
 */
export function anImageAnalysisCheckAction(action: 'REMOVE' | 'KEEP'): ITrackingEvent {
  return {
    id: ETrackingEventId.INSPECTOR_IMAGES_ANALYSIS_BLUR_CHECK_ACTION,
    properties: {
      action,
    },
  };
}

/**
 * This function is used to craft a tracking event
 * of type INSPECTOR_INSPECTION_COORDINATES_ISSUE
 *
 * @param { InspectionId } inspectionId - the inspection id
 * @param { EGeolocationPositionError } error - the error that occurred
 * @returns { ITrackingEvent } An event including the id and the properties
 */
export function anInspectionCoordinatesIssueEvent(inspectionId: InspectionId, error: EGeolocationPositionError): ITrackingEvent {
  return {
    id: ETrackingEventId.INSPECTOR_INSPECTION_COORDINATES_ISSUE,
    properties: {
      inspectionId,
      error,
    },
  };
}

/**
 * This function is used to craft a tracking event
 * of type INSPECTOR_IMAGE_SINGLE_***
 *
 * @param { ETrackingEventId } trackingEvent a tracking event related to image single edit
 * @param { IInspectorPictureSettings } inspectionPictureSettings identifiers for the image set
 * @returns { ITrackingEvent } An event including the id and the properties
 */
export function anImageSingleEditEvent(
  trackingEvent:
    | ETrackingEventId.INSPECTOR_IMAGE_SINGLE_CAPTION_SET
    | ETrackingEventId.INSPECTOR_IMAGE_SINGLE_SPOTLIGHT
    | ETrackingEventId.INSPECTOR_IMAGE_SINGLE_DELETE
    | ETrackingEventId.INSPECTOR_IMAGE_SINGLE_LINK_TO_TEST_RESULT,
  inspectionPictureSettings: IInspectorPictureSettings
): ITrackingEvent {
  return {
    id: trackingEvent,
    properties: {
      ...inspectionPictureSettings,
    },
  };
}

export function aNotificationOpenedEvent(): ITrackingEvent {
  return {
    id: ETrackingEventId.NOTIFICATION_OPEN,
  };
}

export function aQuickSearchExpandEvent(): ITrackingEvent {
  return {
    id: ETrackingEventId.HEADER_QUICK_SEARCH_EXPAND,
  };
}

export function aQuickSearchSelectResultEvent(): ITrackingEvent {
  return {
    id: ETrackingEventId.HEADER_QUICK_SEARCH_SELECT_RESULT,
  };
}

export function aHomeAnalyticsCardInteractionsEvent(): ITrackingEvent {
  return {
    id: ETrackingEventId.HOME_ANALYTICS_CARD_INTERACTIONS,
  };
}

export function aHomeAnalyticsCardViewAllEvent(): ITrackingEvent {
  return {
    id: ETrackingEventId.HOME_ANALYTICS_CARD_VIEW_ALL,
  };
}

/**
 * This function is used to craft a tracking event of type TESTS_CHECKLIST_SCAN
 * To keep track of what our scan partner should bill us for
 *
 * @param barcodesCount number of barcodes scanned
 */
export function aTestsChecklistScanEvent(barcodesCount?: number): ITrackingEvent {
  return {
    id: ETrackingEventId.TESTS_CHECKLIST_SCAN,
    properties: {
      barcodesCount,
    },
  };
}

export function aSearchProductByScanEvent(scannedValue: string): ITrackingEvent {
  return {
    id: ETrackingEventId.SEARCH_PRODUCT_BY_SCAN,
    properties: {
      scannedValue,
    },
  };
}

export function anInspectionDateChangedEvent(): ITrackingEvent {
  return {
    id: ETrackingEventId.REPORTS_INSPECTION_DATE_CHANGED,
    properties: {},
  };
}

/**
 * This function is used to craft a tracking event
 * of type SETTINGS_RATINGS_ADD_NEW_RATING
 *
 * @returns { ITrackingEvent } An event including the id
 */
export function settingsAddNewRatingEvent(): ITrackingEvent {
  return {
    id: ETrackingEventId.SETTINGS_RATINGS_ADD_NEW_RATING,
  };
}

/**
 * This function is used to craft a tracking event
 * of type SETTINGS_RATINGS_ADD_NEW_GRADE
 *
 * @returns { ITrackingEvent } An event including the id
 */
export function settingsRatingsAddNewGradeEvent(): ITrackingEvent {
  return {
    id: ETrackingEventId.SETTINGS_RATINGS_ADD_NEW_GRADE,
  };
}

/**
 * This function is used to craft a tracking event
 * of type SETTINGS_RATINGS_DELETE_RATING
 *
 * @param rating number of barcodes scanned
 * @returns { ITrackingEvent } An event including the id
 */
export function settingsDeleteRatingEvent(rating: RatingSystemDTO): ITrackingEvent {
  return {
    id: ETrackingEventId.SETTINGS_RATINGS_DELETE_RATING,
    properties: { rating },
  };
}

/**
 * This function is used to craft a tracking event of type INSPECTOR_SETTINGS_CLEAR_CACHE
 */
export function anInspectorSettingsClearOfflineCacheEvent(): ITrackingEvent {
  return {
    id: ETrackingEventId.INSPECTOR_SETTINGS_CLEAR_OFFLINE_CACHE,
  };
}

/**
 * This function is used to craft a tracking event of type INSPECTOR_SETTINGS_ACTIVATE_OFFLINE_MODE
 */
export function anInspectorSettingsActivateOfflineEvent(): ITrackingEvent {
  return {
    id: ETrackingEventId.INSPECTOR_SETTINGS_ACTIVATE_OFFLINE_MODE,
  };
}

/**
 * This function is used to craft a tracking event of type INSPECTOR_SETTINGS_DEACTIVATE_OFFLINE_MODE
 */
export function anInspectorSettingsDeactivateOfflineEvent(): ITrackingEvent {
  return {
    id: ETrackingEventId.INSPECTOR_SETTINGS_DEACTIVATE_OFFLINE_MODE,
  };
}

export function anInspectorPictureUploadEvent(inspectionId: InspectionId, pictureSource: ETrackingPictureSource): ITrackingEvent {
  return {
    id: ETrackingEventId.INSPECTOR_UPLOAD_PICTURE,
    properties: {
      inspectionId,
      pictureSource,
    },
  };
}
