export enum EQuickSearchType {
  PRODUCT = 'PRODUCT',
  PURCHASE_ORDER = 'PURCHASE_ORDER',
  ENTITY = 'ENTITY',
}

export interface IQuickSearchDTO {
  id: number;
  type: EQuickSearchType;
  title: string;
}
