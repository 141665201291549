// @ts-strict-ignore
import { createWorkflowStepAction } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/isp-create-workflow-step-action';
import { IspWorkflowStepActionType } from '@one/app/pages/isp/pages/inspection/pages/id/shared/models/isp-workflow-step-action.models';
import { IWorkflow, IWorkflowAction, IWorkflowStep } from '@one/app/shared/models/workflow/workflow.models';
import { IWorkflowInspection, WorkflowIdType, WorkflowPathIdType } from '@one/app/shared/models/workflows/workflow.models';
import { compact } from 'lodash/index';

export class IspWorkflowStep implements IWorkflowStep {
  private static _isWorkflowIdWithPath(id: Readonly<WorkflowIdType | WorkflowPathIdType>): id is WorkflowPathIdType {
    return id.split('-').length > 1;
  }

  public id: WorkflowIdType | undefined = undefined;
  public name: string;
  public actions: IspWorkflowStepActionType[] = [];

  public constructor(step: Readonly<IWorkflowStep>, inspection?: Readonly<IWorkflowInspection>, workflow?: Readonly<IWorkflow>) {
    this.id = step.id;
    this.name = step.name;
    this._createActions(step, inspection, workflow);
  }

  /**
   * @description
   * You can either pass the complete action id (a.k: "1-1")
   * Or the partial (a.k: "1") one
   * @param {Readonly<WorkflowIdType | WorkflowPathIdType>} id The action id (complete or partial)
   * @returns {IspWorkflowStepActionType | null} The action or null
   */
  public getActionById(id: Readonly<WorkflowIdType | WorkflowPathIdType>): IspWorkflowStepActionType | null {
    const workflowActionPathId: WorkflowPathIdType = IspWorkflowStep._isWorkflowIdWithPath(id) ? id : this._getWorkflowActionPathId(id);

    return this.actions.find((action: Readonly<IspWorkflowStepActionType>): boolean => action.id === workflowActionPathId) ?? null;
  }

  private _createActions(step: Readonly<IWorkflowStep>, inspection?: Readonly<IWorkflowInspection>, workflow?: Readonly<IWorkflow>): void {
    this.actions = compact(
      step.actions.map((action: Readonly<IWorkflowAction>): IspWorkflowStepActionType | null =>
        createWorkflowStepAction(action, inspection, workflow)
      )
    );
  }

  private _getWorkflowActionPathId(actionId: Readonly<WorkflowIdType>): WorkflowPathIdType {
    return `${this.id}-${actionId}`;
  }
}
