<div [ngClass]="{ 'read-only': isReadOnly, 'disabled': disabled }" class="formPhone">
  <qp-label *ngIf="title" [for]="phoneNumber.id" [optional]="!isRequired">{{ title | translate }}</qp-label>
  <nz-input-group class="phone-group" nzCompact>
    <nz-select
      (ngModelChange)="onCountryChanges($event)"
      [disabled]="isReadOnly || disabled"
      [ngModel]="selectedCountry"
      [nzCustomTemplate]="customLabel"
      [title]="selectedCountry?.label"
      class="country-select"
      nzDropdownClassName="country-select"
    >
      <ng-template #customLabel let-selected>
        <span [class]="'flag flag-' + selected.nzValue.isoCode.toLowerCase()"></span>
      </ng-template>
      <nz-option *ngFor="let country of countries" [nzValue]="country" class="option" nzCustomContent>
        <span [class]="'flag flag-' + country.isoCode.toLowerCase()"></span>
        <span class="option__label">{{ country.label }}</span>
        <span class="option__prefix">{{ country.callPrefix }}</span>
      </nz-option>
    </nz-select>
    <nz-input-group [hidden]="!selectedCountry?.isoCode" [nzPrefix]="indicatorTemplate" nzCompact class="phone-container">
      <input
        #phoneNumber
        (ngModelChange)="onValueChange($event)"
        [disabled]="isReadOnly || disabled"
        [ngModel]="value | qpPhone: selectedCountry"
        [title]="title | translate"
        [value]="value"
        class="phone"
        nz-input
        type="text"
      />
    </nz-input-group>
  </nz-input-group>
  <ng-template #indicatorTemplate>
    <span class="indicator">{{ '(' + selectedCountry?.callPrefix + ')' }}</span>
  </ng-template>
  <qp-label *ngIf="controlDirective?.control.touched && controlDirective?.control.errors?.required" error="true" type="informative">
    {{ 'error.phone.required' | translate: { fieldName: title | translate } }}
  </qp-label>
  <qp-label *ngIf="controlDirective?.control.touched && controlDirective?.control.errors?.invalid" error="true" type="informative"
    >{{ 'error.phone.invalid' | translate }}
  </qp-label>
</div>
