<div class="one-blur-check-verification-modal">
  <div class="one-blur-check-verification-modal__header">
    <h4 class="one-blur-check-verification-modal__header__title">{{ 'modal.blur-check.photos' | translate }}</h4>
  </div>
  <div class="one-blur-check-verification-modal__image">
    <img class="one-blur-check-verification-modal__image__img" [src]="imageSrc" [alt]="'modal.blur-check.img-alt' | translate" />
  </div>
  <div class="one-blur-check-verification-modal__bottom-card">
    <p class="one-blur-check-verification-modal__bottom-card__counter">
      {{ 'modal.blur-check.counter' | translate: { index: index + 1, total: total } }}
    </p>
    <div class="one-blur-check-verification-modal__bottom-card__blob">
      <qp-icon class="one-blur-check-verification-modal__bottom-card__blob__icon" [name]="iconNames.IC_INFORMATIVE_ALERT"></qp-icon>
      <span class="one-blur-check-verification-modal__bottom-card__blob__information">{{
        'modal.blur-check.' + blurType.toLowerCase() | translate
      }}</span>
    </div>
    <div class="one-blur-check-verification-modal__bottom-card__buttons">
      <qp-button [type]="buttonTypes.SECONDARY" [width]="buttonWidths.FULL" (buttonClick)="onKeepClick()"
        ><qp-button-label>{{ 'modal.blur-check.keep' | translate }}</qp-button-label></qp-button
      >
      <qp-button [type]="buttonTypes.DANGER" [width]="buttonWidths.FULL" (buttonClick)="onRemoveClick()"
        ><qp-button-label>{{ 'modal.blur-check.remove' | translate }}</qp-button-label></qp-button
      >
    </div>
  </div>
</div>
