import { InspectionStatus } from '@library/dto-enums/inspection-status.dto-enum';
import { NetworkStatusService } from '@library/services/qp-network-status/qp-network-status.service';
import { InspectionId } from '@one/app/pages/isp/shared/models/isp-inspection.models';
import { IspInspectionCleanService } from '@one/app/pages/isp/shared/services/isp-inspection-clean.service';
import { InspectionInterceptor, INTERCEPTOR_SKIP_HEADER } from '@one/app/shared/interceptors/inspection.interceptor';
import { ActionRequest, EHttpMethod, SerializedHttpHeaders, SerializedHttpParams } from '@one/app/shared/models/action-request.models';
import { IChecklistImageData } from '@one/app/shared/models/inspection-consultation/inspection-consultation.models';
import { InspectionFile } from '@one/app/shared/models/inspection-file.models';
import { ActionsQueueService } from '@one/app/shared/services/actions-queue/actions-queue.service';
import { DatabaseService } from '@one/app/shared/services/database/database-inspection.service';
import { HttpClient, HttpHeaders, HttpRequest, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EQimaSnackbarType, QimaOptionalType, QimaSnackbarService } from '@qima/ngx-qima';
import { isNil } from 'lodash/index';
import { BehaviorSubject, firstValueFrom, lastValueFrom, Subject } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';

const MAX_TIME_OUT_TIMER = 60000;
const TIME_OUT_TIMER_DEFAULT = 5000;
const TIME_OUT_TIMER_MULTIPLICATOR = 0.2;
const SKIP_SYNC_STATUS = [0, 401, 502, 503, 504];

@Injectable({
  providedIn: 'root',
})
export class ActionsQueueSyncService {
  public readonly syncStackCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public readonly submissionError$: Subject<{ message: string; inspectionId: number }> = new Subject<{
    message: string;
    inspectionId: number;
  }>();

  public readonly inspectionsSyncStackCount$: BehaviorSubject<{ [inspectionId: string]: number }> = new BehaviorSubject<{
    [inspectionId: string]: number;
  }>({});

  public readonly completedInspectionId$: Subject<InspectionId> = new Subject<InspectionId>();

  private _isSyncing = false;
  private _fetchActionsFromDb: QimaOptionalType<Promise<void>>;

  public constructor(
    private readonly _httpClient: HttpClient,
    private readonly _databaseService: DatabaseService,
    private readonly _networkStatusService: NetworkStatusService,
    private readonly _actionsQueueService: ActionsQueueService,
    private readonly _ispInspectionCleanService: IspInspectionCleanService,
    private readonly _qimaSnackbarService: QimaSnackbarService,
    private readonly _translateService: TranslateService
  ) {}

  public init(): void {
    // Recover actions fromDB once the DB has been init
    this._fetchActionsFromDb = firstValueFrom(this._databaseService.isDBInit$).then(
      (): Promise<void> =>
        this._databaseService.retrieveActionRequests().then((actions): void => {
          this._actionsQueueService.queue.push(...actions);

          if (actions.length > 0 && !this._isSyncing) {
            void this.synchronize();
          }
        })
    );

    this._actionsQueueService.addRequestObservable
      .pipe(
        tap((request): void => void this.addAction(request)),
        filter((request): boolean => this._isRequestAnImageAction(request)),
        tap((): void => void this._databaseService.verifyStorageRemainingSpace())
      )
      .subscribe();
  }

  public async addAction(request: HttpRequest<unknown>): Promise<void> {
    await this._fetchActionsFromDb;
    request = this.removeSensitiveHeaders(request);

    const serializedHeaders: SerializedHttpHeaders = request.headers
      .keys()
      .reduce((acc, key): SerializedHttpHeaders => ({ ...acc, [key]: request.headers.get(key) }), {});
    const serializedParams: SerializedHttpParams = request.params
      .keys()
      .reduce((acc, key): SerializedHttpParams => ({ ...acc, [key]: request.params.get(key) }), {});
    const inspectionIdRetrievalRegex = /\/inspections\/([0-9]+)\//; // match /inspections/1234/something
    const inspectionId = inspectionIdRetrievalRegex.exec(request.url)?.[1];
    // Add the action to DB
    const dbAction: ActionRequest = {
      method: request.method as EHttpMethod,
      body: request.body,
      headers: serializedHeaders,
      params: serializedParams,
      isSynced: 0,
      url: request.url,
      inspectionId,
    };
    const savedActionId = await this._databaseService.addActionRequest(dbAction);

    dbAction.id = savedActionId;
    this._actionsQueueService.queue.push(dbAction);

    if (!this._isSyncing) {
      void this.synchronize();
    }
  }

  public removeSensitiveHeaders(httpRequest: HttpRequest<unknown>): HttpRequest<unknown> {
    return httpRequest.clone({
      headers: httpRequest.headers.delete('authorization'),
    });
  }

  // 💡: Re-instanciating an HttpHeaders otherwise requests stored in the Dexie database won't have prototype functions (like .append())
  public httpRequestFromAction(actionRequest: ActionRequest): HttpRequest<unknown> {
    let headers = new HttpHeaders().append(INTERCEPTOR_SKIP_HEADER, INTERCEPTOR_SKIP_HEADER);

    Object.entries(actionRequest.headers).forEach(([key, value]): void => {
      headers = headers.append(key, value);
    });

    let params = new HttpParams();

    Object.entries(actionRequest.params).forEach(([key, value]): void => {
      params = params.append(key, value);
    });

    return new HttpRequest(actionRequest.method, actionRequest.url, actionRequest.body, { headers, params });
  }

  public async synchronize(timeOutTimer = TIME_OUT_TIMER_DEFAULT): Promise<void> {
    this._isSyncing = true;

    let continueSync = true;
    const isOnline = this._networkStatusService.isOnline;

    this.syncStackCount$.next(this._actionsQueueService.queue.length);
    this._calculateInspectionsQueueStatus();

    if (!isOnline) {
      this._isSyncing = false;
      this._networkStatusService.isOnline$
        .pipe(
          filter((online): boolean => online),
          take(1)
        )
        .subscribe((): void => {
          if (!this._isSyncing) {
            void this.synchronize();
          }
        });

      return;
    }

    /* eslint-disable no-await-in-loop */
    while (this._actionsQueueService.queue.length > 0 && continueSync) {
      const action = this._actionsQueueService.queue[0];

      try {
        let request = this.httpRequestFromAction(action);
        const linkedFiles: QimaOptionalType<{ document?: InspectionFile; image?: InspectionFile; attachment?: InspectionFile }> =
          await this._retrieveLinkedFiles(request);

        if (linkedFiles) {
          const formData: QimaOptionalType<FormData> = this._createFormDataWithFile(request, linkedFiles);

          if (formData) {
            request = this._createHttpRequestWithHeadersAndFormData(request, formData);
          }
        } else if (this._shouldHaveLinkedFile(request)) {
          // If there's no linked files while we were supposed to retrieve it, then we throw an error and discard this request
          this._qimaSnackbarService.open({
            type: EQimaSnackbarType.ERROR,
            message: this._translateService.instant('error.actions-queue.no-file-found'),
          });
          throw new Error('No file found for this request');
        }

        await this._synchronizeAction(request);

        this._databaseService.markActionRequestAsSynced(action);
        this._actionsQueueService.queue.shift();
        this.syncStackCount$.next(this._actionsQueueService.queue.length);
        this._calculateInspectionsQueueStatus();

        void this._tryCleanParentImage(linkedFiles?.image);
      } catch (error) {
        console.error(error);

        // If the code is 500, 400 then skip the update
        // If the code is 0 or other type of issues (server non reachable for instance) -> stop sync
        // If the code is 401, user isn't authenticated -> stop sync
        // If the code is 502, Bad Gateway -> stop sync
        // If the code is 503, Service Unavailable -> stop sync
        // If the code is 504, Gateway Timeout -> stop sync
        if (error instanceof HttpErrorResponse && SKIP_SYNC_STATUS.includes(error?.status)) {
          continueSync = false;
          // If the error comes from the fact that we cannot reach the back-end, just retry first time TIME_OUT_TIMER_DEFAULT seconds after
          setTimeout((): void => {
            if (!this._isSyncing) {
              void this.synchronize(this._calculateTimer(timeOutTimer));
            }
          }, timeOutTimer);
        } else {
          if (error instanceof HttpErrorResponse) {
            const endpoint = (error as HttpErrorResponse)?.url?.slice(error?.url?.indexOf('api/'));

            // We failed to sync the inspection, we must notify the user
            if (
              endpoint &&
              InspectionInterceptor.PATCH_INSPECTION_STATUS_REGEX.test(endpoint) &&
              error?.error &&
              error.error.find((e): boolean => e.logref === 'INSPECTION_PREPARATION_NOT_FULLFILLED')
            ) {
              const inspectionId = +(InspectionInterceptor.PATCH_INSPECTION_STATUS_REGEX.exec(endpoint)?.groups?.id || 0);

              this.submissionError$.next({ message: error.error[0].logref, inspectionId });
            }
          }

          this._databaseService.markActionRequestAsSynced(action);
          this._actionsQueueService.queue.shift();
          this.syncStackCount$.next(this._actionsQueueService.queue.length);
          this._calculateInspectionsQueueStatus();
        }
      }
    }

    this._isSyncing = false;
  }

  private _calculateInspectionsQueueStatus(): void {
    const inspectionsQueueStatus = this._actionsQueueService.queue.reduce((statuses, action): { [inspectionId: string]: number } => {
      if (action.inspectionId) {
        return { ...statuses, [action.inspectionId]: (statuses[action.inspectionId] ?? 0) + 1 };
      }

      return statuses;
    }, {});

    this.inspectionsSyncStackCount$.next(inspectionsQueueStatus);
  }

  private async _retrieveLinkedFiles(
    request: HttpRequest<unknown>
  ): Promise<QimaOptionalType<{ document?: InspectionFile; image?: InspectionFile; attachment?: InspectionFile }>> {
    if (this._isRequestAnImageAction(request)) {
      const requestBody = JSON.parse((request.body as string) || 'null');
      const fileId = requestBody.imageId;
      const image = await this._databaseService.getFile(fileId);

      return image ? { image } : null;
    } else if (request.method === 'POST' && InspectionInterceptor.POST_DOCUMENT_REGEX.test(request.url)) {
      const fileId = request.url.split('?id=')[1];
      const document = await this._databaseService.getFile(fileId);

      return document ? { document } : null;
    } else if (
      request.method === 'POST' &&
      (InspectionInterceptor.POST_ATTACHMENT_REGEX.test(request.url) ||
        InspectionInterceptor.POST_SIMPLIFIED_MEASUREMENT_ATTACHMENTS_REGEX.test(request.url))
    ) {
      const fileId = request.params.get('id');

      if (isNil(fileId)) {
        return null;
      }

      const attachment = await this._databaseService.getFile(fileId);

      return attachment ? { attachment } : null;
    }

    return null;
  }

  private _shouldHaveLinkedFile(request: HttpRequest<unknown>): boolean {
    return (
      this._isRequestAnImageAction(request) ||
      (request.method === 'POST' && InspectionInterceptor.POST_DOCUMENT_REGEX.test(request.url)) ||
      (request.method === 'POST' && InspectionInterceptor.POST_ATTACHMENT_REGEX.test(request.url)) ||
      (request.method === 'POST' && InspectionInterceptor.POST_SIMPLIFIED_MEASUREMENT_ATTACHMENTS_REGEX.test(request.url))
    );
  }

  private _createFormDataWithFile(
    request: HttpRequest<unknown>,
    file: { document?: InspectionFile; image?: InspectionFile; attachment?: InspectionFile }
  ): QimaOptionalType<FormData> {
    const formData = new FormData();

    if (file.image) {
      const imageBlob = new Blob([file.image.file], { type: (file.image.data as IChecklistImageData).mimeType });

      formData.append('image', imageBlob);
      formData.append('data', new Blob([request.body as BlobPart], { type: 'application/json' }));

      return formData;
    } else if (file.document) {
      const fileBlob = new Blob([file.document.file], { type: 'image/png' });

      formData.append('file', fileBlob);

      return formData;
    } else if (file.attachment) {
      const attachmentBlob = new File([file.attachment.file], file.attachment.filename ?? 'filename', { type: file.attachment.mimeType });

      formData.append('attachment', attachmentBlob);

      return formData;
    }

    return null;
  }

  private _createHttpRequestWithHeadersAndFormData(request: HttpRequest<unknown>, formData: FormData): HttpRequest<unknown> {
    let headers = new HttpHeaders();
    const keys = request.headers.keys();

    keys.forEach((key): void => {
      const value = request.headers.get(key);

      if (!isNil(value)) {
        headers = headers.append(key, value.toString());
      }
    });

    return new HttpRequest(request.method, request.url, formData, {
      headers,
      params: request.params,
    });
  }

  private async _synchronizeAction(request: HttpRequest<unknown>): Promise<void> {
    const requestPromise = lastValueFrom(this._httpClient.request(request));
    const requestBody: QimaOptionalType<Record<string, unknown>> = request.body ? (request.body as Record<string, unknown>) : null;

    /**
     * If is the last action of an inspection (completed status)
     */
    if (
      request.method === 'PATCH' &&
      InspectionInterceptor.PATCH_INSPECTION_STATUS_REGEX.test(request.url) &&
      requestBody?.status === InspectionStatus.COMPLETED
    ) {
      const regexGroups = InspectionInterceptor.PATCH_INSPECTION_STATUS_REGEX.exec(request.url)?.groups;
      const inspectionId: QimaOptionalType<InspectionId> = regexGroups?.id ? +regexGroups.id : regexGroups?.uuid;

      if (!isNil(inspectionId)) {
        this.completedInspectionId$.next(inspectionId);
        await requestPromise.then((): void => {
          void this._ispInspectionCleanService.cleanInspection(inspectionId);
        });
      } else {
        console.warn('Could not clean inspection with id:', inspectionId);
      }
    } else {
      await requestPromise;
    }
  }

  private _tryCleanParentImage(imageFile: QimaOptionalType<InspectionFile>): Promise<void> {
    const parentImageId: QimaOptionalType<string> = (imageFile?.data as IChecklistImageData)?.parentImage;

    if (parentImageId) {
      try {
        return this._databaseService.deleteFile(parentImageId);
      } catch (error) {
        console.warn('Could not delete image parent:', error);
      }
    }

    return Promise.resolve();
  }

  private _calculateTimer(timeOutTimer: number): number {
    return timeOutTimer > MAX_TIME_OUT_TIMER ? MAX_TIME_OUT_TIMER : timeOutTimer * TIME_OUT_TIMER_MULTIPLICATOR + timeOutTimer;
  }

  private _isRequestAnImageAction(request: HttpRequest<unknown>): boolean {
    return (
      request.method === EHttpMethod.POST &&
      (InspectionInterceptor.POST_GLOBAL_IMAGE_REGEX.test(request.url) || InspectionInterceptor.POST_ENTITY_IMAGE_REGEX.test(request.url))
    );
  }
}
