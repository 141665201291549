import { IspBarcodeScannerService } from '@one/app/pages/isp/shared/services/isp-barcode-scanner.service';
import { ispBarcodeScannerInitInitFactory } from '@one/app/shared/factories/isp-barcode-scanner-init-init.factory';
import { FactoryProvider, APP_BOOTSTRAP_LISTENER } from '@angular/core';

export const ISP_BARCODE_SCANNER_INIT_PROVIDER: FactoryProvider = {
  deps: [IspBarcodeScannerService],
  provide: APP_BOOTSTRAP_LISTENER,
  multi: true,
  useFactory: ispBarcodeScannerInitInitFactory,
};
