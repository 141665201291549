/**
 * @description This enum reflects `com.qima.platform.domain.enumeration.MeasurementsSamplingSizeType`
 */
export enum MeasurementsSamplingSizeType {
  STANDARD_MEASUREMENTS_SAMPLING_SIZE = 'STANDARD_MEASUREMENTS_SAMPLING_SIZE',
  CUSTOM_MEASUREMENTS_SAMPLING_SIZE = 'CUSTOM_MEASUREMENTS_SAMPLING_SIZE',
  FLEXIBLE_MEASUREMENTS_SAMPLING_SIZE = 'FLEXIBLE_MEASUREMENTS_SAMPLING_SIZE',
  NON_AQL_MEASUREMENTS_SAMPLING_SIZE = 'NON_AQL_MEASUREMENTS_SAMPLING_SIZE',
  FIXED_MEASUREMENTS_SAMPLING_SIZE = 'FIXED_MEASUREMENTS_SAMPLING_SIZE',
}
