/* eslint-disable jsdoc/require-returns */
import { AppRouteReuseStrategyService } from '@one/app/app.route-reuse-strategy.service';
import { Injectable } from '@angular/core';
import { RouteReuseStrategy, ActivatedRouteSnapshot, DetachedRouteHandle } from '@angular/router';
import { isNil } from 'lodash/index';

export const REUSE_STRATEGY_ON_CHILDREN = 'reuseStrategyOnChildren';

/**
 * 📖 : To reuse route (and prevent destruction of components), add `reuseStrategyOnChildren` on parent of affected routes.
 * Route instances will be persisted and restored on future route call.
 */
@Injectable()
export class AppRouteReuseStrategy implements RouteReuseStrategy {
  public constructor(private readonly _appRouteReuseStrategyService: AppRouteReuseStrategyService) {}

  /**
   * Determines if this route (and its subtree) should be detached to be reused later
   * Always returns false for `BaseRouteReuseStrategy`.
   *
   * @param {ActivatedRouteSnapshot} route The route that we are leaving
   */
  public shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return route.parent?.data[REUSE_STRATEGY_ON_CHILDREN] === true;
  }

  /**
   * Stores the detached route.
   * Storing a null value should erase the previously stored value.
   *
   * @param {ActivatedRouteSnapshot} route The previous route to store
   * @param {DetachedRouteHandle} handle The page to store
   */
  public store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    const key = this._getResolvedUrl(route);

    this._appRouteReuseStrategyService.saveRoute(key, handle);
  }

  /**
   * Determines if this route (and its subtree) should be reattached
   * Always returns false for `BaseRouteReuseStrategy`.
   *
   * @param {ActivatedRouteSnapshot} route The route to check
   */
  public shouldAttach(route: ActivatedRouteSnapshot): boolean {
    const key = this._getResolvedUrl(route);

    return this._appRouteReuseStrategyService.hasRoute(key);
  }

  /**
   * Retrieves the previously stored route
   *
   * @param {ActivatedRouteSnapshot} route The route to retrieve from cache
   */
  public retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    const key = this._getResolvedUrl(route);

    return this._appRouteReuseStrategyService.getRoute(key) ?? null;
  }

  /**
   * Determines if a route should be reused
   * This is the default `BaseRouteReuseStrategy` implementation.
   *
   * @param {ActivatedRouteSnapshot} future The future route
   * @param {ActivatedRouteSnapshot} curr The current route
   */
  public shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    if (!isNil(future.routeConfig?.data?.shouldReuseRoute)) {
      return future.routeConfig?.data?.shouldReuseRoute;
    }

    return future.routeConfig === curr.routeConfig;
  }

  private _getResolvedUrl(route: ActivatedRouteSnapshot): string {
    return route.pathFromRoot.map((v): string => v.url.map((segment): string => segment.toString()).join('/')).join('/');
  }
}
