export enum EYesNoNaType {
  YES_NO = 'YES_NO',
  YES_NO_NA = 'YES_NO_NA',
}

export enum EYesNoNa {
  YES = 'YES',
  NO = 'NO',
  NA = 'NA',
}
