import { WorkflowTemplateGetDTO } from '@library/dto/workflow/workflow-template.dto';
import { SERVER_API_URL } from '@one/app/app.constants';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WorkflowTemplateService {
  private readonly _resource = `api/workflows`;
  private readonly _resourceUrl = `${SERVER_API_URL}${this._resource}`;

  public constructor(private readonly _httpClient: HttpClient) {}

  public getWorkflowTemplate$(workflowId: Readonly<number>): Observable<WorkflowTemplateGetDTO> {
    const url = `${this._resourceUrl}/${workflowId}`;

    return this._httpClient.get<WorkflowTemplateGetDTO>(url, { observe: 'body' });
  }
}
