import { EQuickSearchType, IQuickSearchDTO } from '@library/dto/quick-search.dto';
import { QuickSearchService } from '@one/app/shared/services/quick-search/quick-search.service';
import { TrackingService } from '@one/app/shared/services/third-party/analytics-manager/tracking/tracking.service';
import {
  aQuickSearchExpandEvent,
  aQuickSearchSelectResultEvent,
} from '@one/app/shared/services/third-party/analytics-manager/tracking/tracking.utils';
import { CommonModule } from '@angular/common';
import { Component, inject, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { ReactiveFormsModule, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  QimaInputSearchSelectModule,
  QimaMenuModule,
  QimaMenuItemIceCubeModule,
  EQimaIconName,
  EQimaIceCubeStyle,
  QimaButtonIconModule,
  EQimaButtonIconStyle,
  QimaInputSearchSelectComponent,
} from '@qima/ngx-qima';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, debounceTime, switchMap, catchError, finalize, tap, distinctUntilChanged } from 'rxjs/operators';

const DEBOUNCE_SEARCH_TIME_IN_MS = 300;
const TYPES_CONFIGS = {
  [EQuickSearchType.PRODUCT]: {
    icon: EQimaIconName.PRODUCT,
    color: EQimaIceCubeStyle.MANDARIN,
    link: (id: string): string => `/brd/product-po/product/${id}/information`,
  },
  [EQuickSearchType.PURCHASE_ORDER]: {
    icon: EQimaIconName.PURCHASE_ORDER,
    color: EQimaIceCubeStyle.PLUM,
    link: (id: string): string => `/brd/product-po/po/${id}`,
  },
  [EQuickSearchType.ENTITY]: {
    icon: EQimaIconName.SUPPLIER_FACTORY,
    color: EQimaIceCubeStyle.AVOCADO,
    link: (id: string): string => `/brd/network/entity/${id}/information`,
  },
};

@UntilDestroy()
@Component({
  selector: 'one-quick-search',
  standalone: true,
  imports: [
    CommonModule,
    QimaButtonIconModule,
    QimaInputSearchSelectModule,
    QimaMenuItemIceCubeModule,
    QimaMenuModule,
    ReactiveFormsModule,
  ],
  templateUrl: './quick-search.component.html',
  styleUrls: ['./quick-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuickSearchComponent {
  public readonly searchTerm$ = new BehaviorSubject<string>('');
  public readonly quickSearchResults$: Observable<IQuickSearchDTO[]> = this._getQuickSearchResults$(this.searchTerm$);
  public readonly iconNames = EQimaIconName;
  public readonly buttonIconStyles = EQimaButtonIconStyle;
  public readonly typesConfigs = TYPES_CONFIGS;
  public readonly control = new FormControl<IQuickSearchDTO | null>(null);
  public isSearchBarDisplayed = false;
  public isLoading = false;

  @ViewChild(QimaInputSearchSelectComponent) public select!: QimaInputSearchSelectComponent<IQuickSearchDTO | null>;

  private readonly _quickSearchService = inject(QuickSearchService);
  private readonly _router = inject(Router);
  private readonly _trackingService = inject(TrackingService);

  public search(searchTerm: string): void {
    this.searchTerm$.next(searchTerm);
  }

  public trackByIdAndType(_index: number, item: IQuickSearchDTO): string {
    return `${item.id}-${item.type}`;
  }

  public selectQuickSearchResult(result: IQuickSearchDTO): void {
    this._trackingService.track(aQuickSearchExpandEvent());
    this.control.reset(null);
    void this._router.navigateByUrl(TYPES_CONFIGS[result.type].link(result.id.toString()));
  }

  public displaySearchBar(): void {
    this._trackingService.track(aQuickSearchSelectResultEvent());
    this.isSearchBarDisplayed = true;
    this.select.focus();
  }

  public hideSearchBar(): void {
    this.isSearchBarDisplayed = false;
  }

  private _getQuickSearchResults$(searchTerm$: Observable<string>): Observable<IQuickSearchDTO[]> {
    return searchTerm$.pipe(
      filter((searchTerm): boolean => !!searchTerm),
      tap((): void => void (this.isLoading = true)),
      debounceTime(DEBOUNCE_SEARCH_TIME_IN_MS),
      distinctUntilChanged(),
      switchMap((searchTerm: string): Observable<IQuickSearchDTO[]> => {
        this.isLoading = true;

        return this._quickSearchService.getQuickSearchResults$(searchTerm).pipe(
          finalize((): void => {
            this.isLoading = false;
          }),
          catchError((): [] => [])
        );
      }),
      untilDestroyed(this)
    );
  }
}
