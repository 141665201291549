import { QpDateService } from '@library/services/qp-date/qp-date.service';
import { Injectable } from '@angular/core';
import { QimaDateFactory, QimaOptionalType, QimaDateType, EQimaDateUnit } from '@qima/ngx-qima';
import dayjs from 'dayjs';
import { times } from 'lodash/index';

const FIRST_DAY_OF_THE_WEEK: number = 0;
const LAST_DAY_OF_THE_WEEK: number = 6;

/**
 * Date service for NGX QIMA.
 * NGX requires its own date service implementation to run.
 * Please use QpDateService instead for all your app usages
 */
@Injectable({
  providedIn: 'root',
})
export class QpNgxQimaDateService implements QimaDateFactory {
  public format(date: Readonly<QimaOptionalType<QimaDateType>>, format: Readonly<string>): string {
    return dayjs(date).format(format);
  }

  public getToday(): Date {
    return QpDateService.now;
  }

  public isValid(date: Readonly<QimaDateType>): boolean {
    return dayjs(date).isValid();
  }

  public isEqual(date1: Readonly<QimaDateType>, date2: Readonly<QimaDateType>, unit: EQimaDateUnit = EQimaDateUnit.MILLISECONDS): boolean {
    return dayjs(date1).isSame(dayjs(date2), unit);
  }

  public getWeekdayNames(): string[] {
    return dayjs.weekdaysShort();
  }

  public getLastDateInWeek(date: Readonly<QimaDateType>): Date {
    return dayjs(date).day(LAST_DAY_OF_THE_WEEK).toDate();
  }

  public getFirstDateInWeek(date: Readonly<QimaDateType>): Date {
    return dayjs(date).day(FIRST_DAY_OF_THE_WEEK).toDate();
  }

  public getMonthName(date: Readonly<QimaDateType>): string {
    return dayjs.months()[dayjs(date).month()];
  }

  public getYear(date: Readonly<QimaDateType>): number {
    return dayjs(date).year();
  }

  /**
   * Get the next 6 weeks' dates, starting on the first day of the week including the 1st of the month given as input
   *
   * @param date
   */
  public getDatesInMonth(date: Readonly<QimaDateType>): Date[] {
    const currentMonthStartDate = this.getStartDateOfMonth(date);
    const previousMonthStartDate = this.getPreviousMonthDate(currentMonthStartDate);
    const nextMonthStartDate = this.getNextMonthDate(currentMonthStartDate);
    const currentMonthStartDay = dayjs(currentMonthStartDate).toDate().getDay();
    // The amount days in the current month.
    const currentMonthDaysCount = this.getDaysInMonth(date);
    const previousMonthDaysCount = this.getDaysInMonth(previousMonthStartDate);
    const monthDates: Date[] = [];

    times(currentMonthStartDay, (index: Readonly<number>): void => {
      const targetDate = dayjs(previousMonthStartDate)
        .date(previousMonthDaysCount - currentMonthStartDay + index + 1)
        .toDate();

      monthDates.push(targetDate);
    });

    times(currentMonthDaysCount, (index: Readonly<number>): void => {
      const targetDate = dayjs(currentMonthStartDate)
        .date(index + 1)
        .toDate();

      monthDates.push(targetDate);
    });

    let dateIndex = 0;

    while (monthDates.length < NB_OF_DATES_DISPLAYED) {
      ++dateIndex;
      const targetDate = dayjs(nextMonthStartDate).date(dateIndex).toDate();

      monthDates.push(targetDate);
    }

    return monthDates;
  }

  public getNextMonthDate(date: Readonly<QimaDateType>): Date {
    return dayjs(date).add(1, 'month').toDate();
  }

  public getPreviousMonthDate(date: Readonly<QimaDateType>): Date {
    return dayjs(date).subtract(1, 'month').toDate();
  }

  public isDateAfterMaximum(date: Readonly<QimaDateType>, maximum: Readonly<QimaDateType>): boolean {
    return dayjs(date).isAfter(dayjs(maximum), 'day');
  }

  public isDateBeforeMinimum(date: Readonly<QimaDateType>, minimum: Readonly<QimaDateType>): boolean {
    return dayjs(date).isBefore(dayjs(minimum), 'day');
  }

  private getDaysInMonth(date: Readonly<QimaDateType>): number {
    return dayjs(date).daysInMonth();
  }

  private getStartDateOfMonth(date: Readonly<QimaDateType>): Date {
    return dayjs(date).startOf('month').toDate();
  }
}

export const NB_OF_DATES_DISPLAYED: number = 42;
