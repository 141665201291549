export enum EQpDividerType {
  SOLID = 'solid',
  DASHED = 'dashed',
  DOTTED = 'dotted',
}

export enum EQpDividerPosition {
  CENTER = 'center',
  START = 'start',
  END = 'end',
}

export enum EQpDividerOrientation {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

export enum EQpDividerColor {
  PEARL = 'pearl',
  ORANGE = 'orange',
  MIDNIGHT = 'midnight',
  SMOKE = 'smoke',
  POOL = 'pool',
  ALIZARIN_CRIMSON_OPACITY = 'alizarin-crimson-opacity',
  KIWI_OPACITY = 'kiwi-opacity',
}
