// @ts-strict-ignore
import { EQpIconName } from '@library/components/qp-icon/qp-icon.models';
import { EQpProfile } from '@library/models/qp-profile.models';
import { QimaOptionalType } from '@qima/ngx-qima';
import { Observable } from 'rxjs';

export type QpRouterCommandsType = unknown[] | string;

export interface IQpDisplayedRoute {
  /**
   * @description
   * You can omit the title for children to no display them in the sub-menu entries
   */
  title: QimaOptionalType<string>;
  routerLink: string;
  routerLinkActiveOption: { exact: boolean };
  profiles: EQpProfile[];
  children?: IQpDisplayedRoute[];
  iconName?: EQpIconName;
  isDefaultPage?: boolean;
  defaultPageRoute?: IQpDisplayedRoute;
  isDefaultAction?: boolean;
  defaultActionRoutes?: IQpDisplayedRoute[];
  isSecondaryMenu?: boolean;
  dataCy?: string;
  creationTitle?: string;
  creationSubtitle?: string;
  creationButtonLabel?: string;
  creationButtonClickCallback?: () => void;
  isMobileOnly?: boolean;
  isSubMenuHidden?: boolean;
  isCounterDisplayed?: boolean;
  subNavButtonClickCallback?: () => Observable<void>;
  counter$?: Observable<number>;
}

export class QpDisplayedRoute implements IQpDisplayedRoute {
  public static create(displayedRoute: {
    title?: QimaOptionalType<string>;
    routerLink: string;
    routerLinkActiveOption: { exact: boolean };
    profiles: EQpProfile[];
    children?: IQpDisplayedRoute[];
    iconName?: EQpIconName;
    isDefaultPage?: boolean;
    defaultPageRoute?: IQpDisplayedRoute;
    isDefaultAction?: boolean;
    defaultActionRoutes?: IQpDisplayedRoute[];
    isSecondaryMenu?: boolean;
    dataCy?: string;
    creationTitle?: string;
    creationSubtitle?: string;
    creationButtonLabel?: string;
    isMobileOnly?: boolean;
    isSubMenuHidden?: boolean;
    isRouterLinkDisabled?: boolean;
    isCounterDisplayed?: boolean;
    creationButtonClickCallback?: () => void;
    subNavButtonClickCallback?: () => Observable<void>;
    counter$?: Observable<number>;
  }): QpDisplayedRoute {
    return new QpDisplayedRoute(
      displayedRoute.title,
      displayedRoute.routerLink,
      displayedRoute.routerLinkActiveOption,
      displayedRoute.profiles,
      displayedRoute.children,
      displayedRoute.iconName,
      displayedRoute.isDefaultPage,
      displayedRoute.defaultPageRoute,
      displayedRoute.isDefaultAction,
      displayedRoute.defaultActionRoutes,
      displayedRoute.isSecondaryMenu,
      displayedRoute.dataCy,
      displayedRoute.creationTitle,
      displayedRoute.creationSubtitle,
      displayedRoute.creationButtonLabel,
      displayedRoute.isMobileOnly,
      displayedRoute.isSubMenuHidden,
      displayedRoute.isRouterLinkDisabled,
      displayedRoute.isCounterDisplayed,
      displayedRoute.creationButtonClickCallback,
      displayedRoute.subNavButtonClickCallback,
      displayedRoute.counter$
    );
  }

  public constructor(
    public title: QimaOptionalType<string>,
    public routerLink: string,
    public routerLinkActiveOption: { exact: boolean },
    public profiles: EQpProfile[],
    public children: IQpDisplayedRoute[],
    public iconName: EQpIconName,
    public isDefaultPage: boolean,
    public defaultPageRoute: IQpDisplayedRoute,
    public isDefaultAction: boolean,
    public defaultActionRoutes: IQpDisplayedRoute[],
    public isSecondaryMenu: boolean,
    public dataCy: string,
    public creationTitle: string,
    public creationSubtitle: string,
    public creationButtonLabel: string,
    public isMobileOnly: boolean,
    public isSubMenuHidden: boolean,
    public isRouterLinkDisabled: boolean,
    public isCounterDisplayed: boolean,
    public creationButtonClickCallback?: () => void,
    public subNavButtonClickCallback?: () => Observable<void>,
    public counter$?: Observable<number>
  ) {}
}
