import { QimaOptionalType } from '@qima/ngx-qima';

export enum EHttpMethod {
  POST = 'POST',
  PATCH = 'PATCH',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export type SerializedHttpHeaders = { [name: string]: string | string[] };

export type SerializedHttpParams = { [name: string]: string | number | boolean };

export interface ActionRequest<T = unknown> {
  id?: number;
  inspectionId?: QimaOptionalType<string>;
  method: EHttpMethod;
  url: string;
  body: T;
  headers: SerializedHttpHeaders;
  params: SerializedHttpParams;
  // 1 => true and 0 => false
  // We need to use numbers because booleans are not indexable
  isSynced: 1 | 0;
  syncDate?: Date;
}
