// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { HammerGestureConfig } from '@angular/platform-browser';

/**
 * @description
 * The configuration below is specific to the usage of hammerjs events made in the voice measurements component.
 * If you need to use hammerjs somewhere else in the app, then we can override the buildHammer method to
 * enable different options based on element attributes.
 * Not provided in root on purpose
 */
@Injectable()
export class HammerConfig extends HammerGestureConfig {
  public overrides: Record<string, unknown> = {
    pan: { direction: Hammer.DIRECTION_VERTICAL, threshold: 1 },
    pinch: { enable: false },
    rotate: { enable: false },
    swipe: { enable: false },
  };
}
