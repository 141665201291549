import { AqlValue } from '@library/dto-enums/aql-value.dto-enum';

/**
 * AQL card specifications
 */

export enum EInspectionLevel {
  // General inspection levels
  I = 'I',
  II = 'II',
  III = 'III',
  // Special inspection levels
  S1 = 'S1',
  S2 = 'S2',
  S3 = 'S3',
  S4 = 'S4',
}

export enum EAqlCodeLetter {
  LETTER_A = 'A',
  LETTER_B = 'B',
  LETTER_C = 'C',
  LETTER_D = 'D',
  LETTER_E = 'E',
  LETTER_F = 'F',
  LETTER_G = 'G',
  LETTER_H = 'H',
  LETTER_J = 'J',
  LETTER_K = 'K',
  LETTER_L = 'L',
  LETTER_M = 'M',
  LETTER_N = 'N',
  LETTER_P = 'P',
  LETTER_Q = 'Q',
  LETTER_R = 'R',
  LETTER_Z = 'Z', //  Non standard
  LETTER_X = 'X', //  Non standard
}

export enum EAqlLotSize {
  LOT_SIZE_0_TO_0 = '0-0',
  LOT_SIZE_1_TO_1 = '1-1',
  LOT_SIZE_2_TO_8 = '2-8',
  LOT_SIZE_9_TO_15 = '9-15',
  LOT_SIZE_16_TO_25 = '16-25',
  LOT_SIZE_26_TO_50 = '26-50',
  LOT_SIZE_51_TO_90 = '51-90',
  LOT_SIZE_91_TO_150 = '91-150',
  LOT_SIZE_151_TO_280 = '151-280',
  LOT_SIZE_281_TO_500 = '281-500',
  LOT_SIZE_501_TO_1200 = '501-1200',
  LOT_SIZE_1201_TO_3200 = '1201-3200',
  LOT_SIZE_3201_TO_10000 = '3201-10000',
  LOT_SIZE_10001_TO_35000 = '10001-35000',
  LOT_SIZE_35001_TO_150000 = '35001-150000',
  LOT_SIZE_150001_TO_500000 = '150001-500000',
  LOT_SIZE_500001_TO_INFINITY = '500001-infinity',
}

/**
 * List of possible sample sizes, according to the AQL sampling table
 */
export enum EAqlSampleSize {
  SIZE_0 = 0, // Non standard
  SIZE_1 = 1, // Non standard
  SIZE_2 = 2,
  SIZE_3 = 3,
  SIZE_5 = 5,
  SIZE_8 = 8,
  SIZE_13 = 13,
  SIZE_20 = 20,
  SIZE_32 = 32,
  SIZE_50 = 50,
  SIZE_80 = 80,
  SIZE_125 = 125,
  SIZE_200 = 200,
  SIZE_315 = 315,
  SIZE_500 = 500,
  SIZE_800 = 800,
  SIZE_1250 = 1250,
  SIZE_2000 = 2000,
}

/**
 * List of possible acceptance or rejection number, according to the AQL sampling table
 */
export enum EAqlPoint {
  POINT_0 = 0,
  POINT_1 = 1,
  POINT_2 = 2,
  POINT_3 = 3,
  POINT_4 = 4,
  POINT_5 = 5,
  POINT_6 = 6,
  POINT_7 = 7,
  POINT_8 = 8,
  POINT_10 = 10,
  POINT_11 = 11,
  POINT_14 = 14,
  POINT_15 = 15,
  POINT_21 = 21,
  POINT_22 = 22,
}

export const LOT_SIZE_RANGES = [
  { min: 0, max: 0, lotSize: EAqlLotSize.LOT_SIZE_0_TO_0 }, //  Non standard
  { min: 1, max: 1, lotSize: EAqlLotSize.LOT_SIZE_1_TO_1 }, //  Non standard
  { min: 2, max: 8, lotSize: EAqlLotSize.LOT_SIZE_2_TO_8 },
  { min: 9, max: 15, lotSize: EAqlLotSize.LOT_SIZE_9_TO_15 },
  { min: 16, max: 25, lotSize: EAqlLotSize.LOT_SIZE_16_TO_25 },
  { min: 26, max: 50, lotSize: EAqlLotSize.LOT_SIZE_26_TO_50 },
  { min: 51, max: 90, lotSize: EAqlLotSize.LOT_SIZE_51_TO_90 },
  { min: 91, max: 150, lotSize: EAqlLotSize.LOT_SIZE_91_TO_150 },
  { min: 151, max: 280, lotSize: EAqlLotSize.LOT_SIZE_151_TO_280 },
  { min: 281, max: 500, lotSize: EAqlLotSize.LOT_SIZE_281_TO_500 },
  { min: 501, max: 1200, lotSize: EAqlLotSize.LOT_SIZE_501_TO_1200 },
  { min: 1201, max: 3200, lotSize: EAqlLotSize.LOT_SIZE_1201_TO_3200 },
  { min: 3201, max: 10000, lotSize: EAqlLotSize.LOT_SIZE_3201_TO_10000 },
  { min: 10001, max: 35000, lotSize: EAqlLotSize.LOT_SIZE_10001_TO_35000 },
  { min: 35001, max: 150000, lotSize: EAqlLotSize.LOT_SIZE_35001_TO_150000 },
  { min: 150001, max: 500000, lotSize: EAqlLotSize.LOT_SIZE_150001_TO_500000 },
  { min: 500001, max: Infinity, lotSize: EAqlLotSize.LOT_SIZE_500001_TO_INFINITY },
];

/**
 * Map a lot size and an inspection level to the matching AQL code letter.
 * (Front side of the AQL sampling table)
 */
export const AQL_LOT_SIZE_LEVEL_CODE_LETTERS: Record<EAqlLotSize, Record<EInspectionLevel, EAqlCodeLetter>> = {
  //  Non standard
  [EAqlLotSize.LOT_SIZE_0_TO_0]: {
    [EInspectionLevel.I]: EAqlCodeLetter.LETTER_X,
    [EInspectionLevel.II]: EAqlCodeLetter.LETTER_X,
    [EInspectionLevel.III]: EAqlCodeLetter.LETTER_X,
    [EInspectionLevel.S1]: EAqlCodeLetter.LETTER_X,
    [EInspectionLevel.S2]: EAqlCodeLetter.LETTER_X,
    [EInspectionLevel.S3]: EAqlCodeLetter.LETTER_X,
    [EInspectionLevel.S4]: EAqlCodeLetter.LETTER_X,
  },
  //  Non standard
  [EAqlLotSize.LOT_SIZE_1_TO_1]: {
    [EInspectionLevel.I]: EAqlCodeLetter.LETTER_Z,
    [EInspectionLevel.II]: EAqlCodeLetter.LETTER_Z,
    [EInspectionLevel.III]: EAqlCodeLetter.LETTER_Z,
    [EInspectionLevel.S1]: EAqlCodeLetter.LETTER_Z,
    [EInspectionLevel.S2]: EAqlCodeLetter.LETTER_Z,
    [EInspectionLevel.S3]: EAqlCodeLetter.LETTER_Z,
    [EInspectionLevel.S4]: EAqlCodeLetter.LETTER_Z,
  },
  [EAqlLotSize.LOT_SIZE_2_TO_8]: {
    [EInspectionLevel.I]: EAqlCodeLetter.LETTER_A,
    [EInspectionLevel.II]: EAqlCodeLetter.LETTER_A,
    [EInspectionLevel.III]: EAqlCodeLetter.LETTER_B,
    [EInspectionLevel.S1]: EAqlCodeLetter.LETTER_A,
    [EInspectionLevel.S2]: EAqlCodeLetter.LETTER_A,
    [EInspectionLevel.S3]: EAqlCodeLetter.LETTER_A,
    [EInspectionLevel.S4]: EAqlCodeLetter.LETTER_A,
  },
  [EAqlLotSize.LOT_SIZE_9_TO_15]: {
    [EInspectionLevel.I]: EAqlCodeLetter.LETTER_A,
    [EInspectionLevel.II]: EAqlCodeLetter.LETTER_B,
    [EInspectionLevel.III]: EAqlCodeLetter.LETTER_C,
    [EInspectionLevel.S1]: EAqlCodeLetter.LETTER_A,
    [EInspectionLevel.S2]: EAqlCodeLetter.LETTER_A,
    [EInspectionLevel.S3]: EAqlCodeLetter.LETTER_A,
    [EInspectionLevel.S4]: EAqlCodeLetter.LETTER_A,
  },
  [EAqlLotSize.LOT_SIZE_16_TO_25]: {
    [EInspectionLevel.I]: EAqlCodeLetter.LETTER_B,
    [EInspectionLevel.II]: EAqlCodeLetter.LETTER_C,
    [EInspectionLevel.III]: EAqlCodeLetter.LETTER_D,
    [EInspectionLevel.S1]: EAqlCodeLetter.LETTER_A,
    [EInspectionLevel.S2]: EAqlCodeLetter.LETTER_A,
    [EInspectionLevel.S3]: EAqlCodeLetter.LETTER_B,
    [EInspectionLevel.S4]: EAqlCodeLetter.LETTER_B,
  },
  [EAqlLotSize.LOT_SIZE_26_TO_50]: {
    [EInspectionLevel.I]: EAqlCodeLetter.LETTER_C,
    [EInspectionLevel.II]: EAqlCodeLetter.LETTER_D,
    [EInspectionLevel.III]: EAqlCodeLetter.LETTER_E,
    [EInspectionLevel.S1]: EAqlCodeLetter.LETTER_A,
    [EInspectionLevel.S2]: EAqlCodeLetter.LETTER_B,
    [EInspectionLevel.S3]: EAqlCodeLetter.LETTER_B,
    [EInspectionLevel.S4]: EAqlCodeLetter.LETTER_C,
  },
  [EAqlLotSize.LOT_SIZE_51_TO_90]: {
    [EInspectionLevel.I]: EAqlCodeLetter.LETTER_C,
    [EInspectionLevel.II]: EAqlCodeLetter.LETTER_E,
    [EInspectionLevel.III]: EAqlCodeLetter.LETTER_F,
    [EInspectionLevel.S1]: EAqlCodeLetter.LETTER_B,
    [EInspectionLevel.S2]: EAqlCodeLetter.LETTER_B,
    [EInspectionLevel.S3]: EAqlCodeLetter.LETTER_C,
    [EInspectionLevel.S4]: EAqlCodeLetter.LETTER_C,
  },
  [EAqlLotSize.LOT_SIZE_91_TO_150]: {
    [EInspectionLevel.I]: EAqlCodeLetter.LETTER_D,
    [EInspectionLevel.II]: EAqlCodeLetter.LETTER_F,
    [EInspectionLevel.III]: EAqlCodeLetter.LETTER_G,
    [EInspectionLevel.S1]: EAqlCodeLetter.LETTER_B,
    [EInspectionLevel.S2]: EAqlCodeLetter.LETTER_B,
    [EInspectionLevel.S3]: EAqlCodeLetter.LETTER_C,
    [EInspectionLevel.S4]: EAqlCodeLetter.LETTER_D,
  },
  [EAqlLotSize.LOT_SIZE_151_TO_280]: {
    [EInspectionLevel.I]: EAqlCodeLetter.LETTER_E,
    [EInspectionLevel.II]: EAqlCodeLetter.LETTER_G,
    [EInspectionLevel.III]: EAqlCodeLetter.LETTER_H,
    [EInspectionLevel.S1]: EAqlCodeLetter.LETTER_B,
    [EInspectionLevel.S2]: EAqlCodeLetter.LETTER_C,
    [EInspectionLevel.S3]: EAqlCodeLetter.LETTER_D,
    [EInspectionLevel.S4]: EAqlCodeLetter.LETTER_E,
  },
  [EAqlLotSize.LOT_SIZE_281_TO_500]: {
    [EInspectionLevel.I]: EAqlCodeLetter.LETTER_F,
    [EInspectionLevel.II]: EAqlCodeLetter.LETTER_H,
    [EInspectionLevel.III]: EAqlCodeLetter.LETTER_J,
    [EInspectionLevel.S1]: EAqlCodeLetter.LETTER_B,
    [EInspectionLevel.S2]: EAqlCodeLetter.LETTER_C,
    [EInspectionLevel.S3]: EAqlCodeLetter.LETTER_D,
    [EInspectionLevel.S4]: EAqlCodeLetter.LETTER_E,
  },
  [EAqlLotSize.LOT_SIZE_501_TO_1200]: {
    [EInspectionLevel.I]: EAqlCodeLetter.LETTER_G,
    [EInspectionLevel.II]: EAqlCodeLetter.LETTER_J,
    [EInspectionLevel.III]: EAqlCodeLetter.LETTER_K,
    [EInspectionLevel.S1]: EAqlCodeLetter.LETTER_C,
    [EInspectionLevel.S2]: EAqlCodeLetter.LETTER_C,
    [EInspectionLevel.S3]: EAqlCodeLetter.LETTER_E,
    [EInspectionLevel.S4]: EAqlCodeLetter.LETTER_F,
  },
  [EAqlLotSize.LOT_SIZE_1201_TO_3200]: {
    [EInspectionLevel.I]: EAqlCodeLetter.LETTER_H,
    [EInspectionLevel.II]: EAqlCodeLetter.LETTER_K,
    [EInspectionLevel.III]: EAqlCodeLetter.LETTER_L,
    [EInspectionLevel.S1]: EAqlCodeLetter.LETTER_C,
    [EInspectionLevel.S2]: EAqlCodeLetter.LETTER_D,
    [EInspectionLevel.S3]: EAqlCodeLetter.LETTER_E,
    [EInspectionLevel.S4]: EAqlCodeLetter.LETTER_G,
  },
  [EAqlLotSize.LOT_SIZE_3201_TO_10000]: {
    [EInspectionLevel.I]: EAqlCodeLetter.LETTER_J,
    [EInspectionLevel.II]: EAqlCodeLetter.LETTER_L,
    [EInspectionLevel.III]: EAqlCodeLetter.LETTER_M,
    [EInspectionLevel.S1]: EAqlCodeLetter.LETTER_C,
    [EInspectionLevel.S2]: EAqlCodeLetter.LETTER_D,
    [EInspectionLevel.S3]: EAqlCodeLetter.LETTER_F,
    [EInspectionLevel.S4]: EAqlCodeLetter.LETTER_G,
  },
  [EAqlLotSize.LOT_SIZE_10001_TO_35000]: {
    [EInspectionLevel.I]: EAqlCodeLetter.LETTER_K,
    [EInspectionLevel.II]: EAqlCodeLetter.LETTER_M,
    [EInspectionLevel.III]: EAqlCodeLetter.LETTER_N,
    [EInspectionLevel.S1]: EAqlCodeLetter.LETTER_C,
    [EInspectionLevel.S2]: EAqlCodeLetter.LETTER_D,
    [EInspectionLevel.S3]: EAqlCodeLetter.LETTER_F,
    [EInspectionLevel.S4]: EAqlCodeLetter.LETTER_H,
  },
  [EAqlLotSize.LOT_SIZE_35001_TO_150000]: {
    [EInspectionLevel.I]: EAqlCodeLetter.LETTER_L,
    [EInspectionLevel.II]: EAqlCodeLetter.LETTER_N,
    [EInspectionLevel.III]: EAqlCodeLetter.LETTER_P,
    [EInspectionLevel.S1]: EAqlCodeLetter.LETTER_D,
    [EInspectionLevel.S2]: EAqlCodeLetter.LETTER_E,
    [EInspectionLevel.S3]: EAqlCodeLetter.LETTER_G,
    [EInspectionLevel.S4]: EAqlCodeLetter.LETTER_J,
  },
  [EAqlLotSize.LOT_SIZE_150001_TO_500000]: {
    [EInspectionLevel.I]: EAqlCodeLetter.LETTER_M,
    [EInspectionLevel.II]: EAqlCodeLetter.LETTER_P,
    [EInspectionLevel.III]: EAqlCodeLetter.LETTER_Q,
    [EInspectionLevel.S1]: EAqlCodeLetter.LETTER_D,
    [EInspectionLevel.S2]: EAqlCodeLetter.LETTER_E,
    [EInspectionLevel.S3]: EAqlCodeLetter.LETTER_G,
    [EInspectionLevel.S4]: EAqlCodeLetter.LETTER_J,
  },
  [EAqlLotSize.LOT_SIZE_500001_TO_INFINITY]: {
    [EInspectionLevel.I]: EAqlCodeLetter.LETTER_N,
    [EInspectionLevel.II]: EAqlCodeLetter.LETTER_Q,
    [EInspectionLevel.III]: EAqlCodeLetter.LETTER_R,
    [EInspectionLevel.S1]: EAqlCodeLetter.LETTER_D,
    [EInspectionLevel.S2]: EAqlCodeLetter.LETTER_E,
    [EInspectionLevel.S3]: EAqlCodeLetter.LETTER_H,
    [EInspectionLevel.S4]: EAqlCodeLetter.LETTER_K,
  },
};

/**
 * Map a code letter and a quality level to a sampling plan, which includes a sample size and accept/reject points
 * (Back side of the AQL sampling table)
 */
export const AQL_CODE_LETTER_QUALITY_LEVEL_SAMPLING_PLANS = {
  [EAqlCodeLetter.LETTER_A]: {
    [AqlValue.NOT_ALLOWED]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_0,
      sampleSize: EAqlSampleSize.SIZE_2,
    },
    [AqlValue.AQL_0_065]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_200,
    },
    [AqlValue.AQL_0_1]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_125,
    },
    [AqlValue.AQL_0_15]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_80,
    },
    [AqlValue.AQL_0_25]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_50,
    },
    [AqlValue.AQL_0_4]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_32,
    },
    [AqlValue.AQL_0_65]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_20,
    },
    [AqlValue.AQL_1_0]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_13,
    },
    [AqlValue.AQL_1_5]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_8,
    },
    [AqlValue.AQL_2_5]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_5,
    },
    [AqlValue.AQL_4_0]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_3,
    },
    [AqlValue.AQL_6_5]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_2,
    },
  },
  [EAqlCodeLetter.LETTER_B]: {
    [AqlValue.NOT_ALLOWED]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_0,
      sampleSize: EAqlSampleSize.SIZE_3,
    },
    [AqlValue.AQL_0_065]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_200,
    },
    [AqlValue.AQL_0_1]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_125,
    },
    [AqlValue.AQL_0_15]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_80,
    },
    [AqlValue.AQL_0_25]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_50,
    },
    [AqlValue.AQL_0_4]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_32,
    },
    [AqlValue.AQL_0_65]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_20,
    },
    [AqlValue.AQL_1_0]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_13,
    },
    [AqlValue.AQL_1_5]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_8,
    },
    [AqlValue.AQL_2_5]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_5,
    },
    [AqlValue.AQL_4_0]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_3,
    },
    [AqlValue.AQL_6_5]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_2,
    },
  },
  [EAqlCodeLetter.LETTER_C]: {
    [AqlValue.NOT_ALLOWED]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_0,
      sampleSize: EAqlSampleSize.SIZE_5,
    },
    [AqlValue.AQL_0_065]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_200,
    },
    [AqlValue.AQL_0_1]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_125,
    },
    [AqlValue.AQL_0_15]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_80,
    },
    [AqlValue.AQL_0_25]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_50,
    },
    [AqlValue.AQL_0_4]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_32,
    },
    [AqlValue.AQL_0_65]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_20,
    },
    [AqlValue.AQL_1_0]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_13,
    },
    [AqlValue.AQL_1_5]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_8,
    },
    [AqlValue.AQL_2_5]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_5,
    },
    [AqlValue.AQL_4_0]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_3,
    },
    [AqlValue.AQL_6_5]: {
      acceptPoint: EAqlPoint.POINT_1,
      rejectPoint: EAqlPoint.POINT_2,
      sampleSize: EAqlSampleSize.SIZE_8,
    },
  },
  [EAqlCodeLetter.LETTER_D]: {
    [AqlValue.NOT_ALLOWED]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_0,
      sampleSize: EAqlSampleSize.SIZE_8,
    },
    [AqlValue.AQL_0_065]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_200,
    },
    [AqlValue.AQL_0_1]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_125,
    },
    [AqlValue.AQL_0_15]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_80,
    },
    [AqlValue.AQL_0_25]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_50,
    },
    [AqlValue.AQL_0_4]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_32,
    },
    [AqlValue.AQL_0_65]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_20,
    },
    [AqlValue.AQL_1_0]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_13,
    },
    [AqlValue.AQL_1_5]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_8,
    },
    [AqlValue.AQL_2_5]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_5,
    },
    [AqlValue.AQL_4_0]: {
      acceptPoint: EAqlPoint.POINT_1,
      rejectPoint: EAqlPoint.POINT_2,
      sampleSize: EAqlSampleSize.SIZE_13,
    },
    [AqlValue.AQL_6_5]: {
      acceptPoint: EAqlPoint.POINT_1,
      rejectPoint: EAqlPoint.POINT_2,
      sampleSize: EAqlSampleSize.SIZE_8,
    },
  },
  [EAqlCodeLetter.LETTER_E]: {
    [AqlValue.NOT_ALLOWED]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_0,
      sampleSize: EAqlSampleSize.SIZE_13,
    },
    [AqlValue.AQL_0_065]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_200,
    },
    [AqlValue.AQL_0_1]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_125,
    },
    [AqlValue.AQL_0_15]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_80,
    },
    [AqlValue.AQL_0_25]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_50,
    },
    [AqlValue.AQL_0_4]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_32,
    },
    [AqlValue.AQL_0_65]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_20,
    },
    [AqlValue.AQL_1_0]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_13,
    },
    [AqlValue.AQL_1_5]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_8,
    },
    [AqlValue.AQL_2_5]: {
      acceptPoint: EAqlPoint.POINT_1,
      rejectPoint: EAqlPoint.POINT_2,
      sampleSize: EAqlSampleSize.SIZE_20,
    },
    [AqlValue.AQL_4_0]: {
      acceptPoint: EAqlPoint.POINT_1,
      rejectPoint: EAqlPoint.POINT_2,
      sampleSize: EAqlSampleSize.SIZE_13,
    },
    [AqlValue.AQL_6_5]: {
      acceptPoint: EAqlPoint.POINT_2,
      rejectPoint: EAqlPoint.POINT_3,
      sampleSize: EAqlSampleSize.SIZE_13,
    },
  },
  [EAqlCodeLetter.LETTER_F]: {
    [AqlValue.NOT_ALLOWED]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_0,
      sampleSize: EAqlSampleSize.SIZE_20,
    },
    [AqlValue.AQL_0_065]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_200,
    },
    [AqlValue.AQL_0_1]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_125,
    },
    [AqlValue.AQL_0_15]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_80,
    },
    [AqlValue.AQL_0_25]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_50,
    },
    [AqlValue.AQL_0_4]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_32,
    },
    [AqlValue.AQL_0_65]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_20,
    },
    [AqlValue.AQL_1_0]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_13,
    },
    [AqlValue.AQL_1_5]: {
      acceptPoint: EAqlPoint.POINT_1,
      rejectPoint: EAqlPoint.POINT_2,
      sampleSize: EAqlSampleSize.SIZE_32,
    },
    [AqlValue.AQL_2_5]: {
      acceptPoint: EAqlPoint.POINT_1,
      rejectPoint: EAqlPoint.POINT_2,
      sampleSize: EAqlSampleSize.SIZE_20,
    },
    [AqlValue.AQL_4_0]: {
      acceptPoint: EAqlPoint.POINT_2,
      rejectPoint: EAqlPoint.POINT_3,
      sampleSize: EAqlSampleSize.SIZE_20,
    },
    [AqlValue.AQL_6_5]: {
      acceptPoint: EAqlPoint.POINT_3,
      rejectPoint: EAqlPoint.POINT_4,
      sampleSize: EAqlSampleSize.SIZE_20,
    },
  },
  [EAqlCodeLetter.LETTER_G]: {
    [AqlValue.NOT_ALLOWED]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_0,
      sampleSize: EAqlSampleSize.SIZE_32,
    },
    [AqlValue.AQL_0_065]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_200,
    },
    [AqlValue.AQL_0_1]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_125,
    },
    [AqlValue.AQL_0_15]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_80,
    },
    [AqlValue.AQL_0_25]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_50,
    },
    [AqlValue.AQL_0_4]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_32,
    },
    [AqlValue.AQL_0_65]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_20,
    },
    [AqlValue.AQL_1_0]: {
      acceptPoint: EAqlPoint.POINT_1,
      rejectPoint: EAqlPoint.POINT_2,
      sampleSize: EAqlSampleSize.SIZE_50,
    },
    [AqlValue.AQL_1_5]: {
      acceptPoint: EAqlPoint.POINT_1,
      rejectPoint: EAqlPoint.POINT_2,
      sampleSize: EAqlSampleSize.SIZE_32,
    },
    [AqlValue.AQL_2_5]: {
      acceptPoint: EAqlPoint.POINT_2,
      rejectPoint: EAqlPoint.POINT_3,
      sampleSize: EAqlSampleSize.SIZE_32,
    },
    [AqlValue.AQL_4_0]: {
      acceptPoint: EAqlPoint.POINT_3,
      rejectPoint: EAqlPoint.POINT_4,
      sampleSize: EAqlSampleSize.SIZE_32,
    },
    [AqlValue.AQL_6_5]: {
      acceptPoint: EAqlPoint.POINT_5,
      rejectPoint: EAqlPoint.POINT_6,
      sampleSize: EAqlSampleSize.SIZE_32,
    },
  },
  [EAqlCodeLetter.LETTER_H]: {
    [AqlValue.NOT_ALLOWED]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_0,
      sampleSize: EAqlSampleSize.SIZE_50,
    },
    [AqlValue.AQL_0_065]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_200,
    },
    [AqlValue.AQL_0_1]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_125,
    },
    [AqlValue.AQL_0_15]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_80,
    },
    [AqlValue.AQL_0_25]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_50,
    },
    [AqlValue.AQL_0_4]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_32,
    },
    [AqlValue.AQL_0_65]: {
      acceptPoint: EAqlPoint.POINT_1,
      rejectPoint: EAqlPoint.POINT_2,
      sampleSize: EAqlSampleSize.SIZE_80,
    },
    [AqlValue.AQL_1_0]: {
      acceptPoint: EAqlPoint.POINT_1,
      rejectPoint: EAqlPoint.POINT_2,
      sampleSize: EAqlSampleSize.SIZE_50,
    },
    [AqlValue.AQL_1_5]: {
      acceptPoint: EAqlPoint.POINT_2,
      rejectPoint: EAqlPoint.POINT_3,
      sampleSize: EAqlSampleSize.SIZE_50,
    },
    [AqlValue.AQL_2_5]: {
      acceptPoint: EAqlPoint.POINT_3,
      rejectPoint: EAqlPoint.POINT_4,
      sampleSize: EAqlSampleSize.SIZE_50,
    },
    [AqlValue.AQL_4_0]: {
      acceptPoint: EAqlPoint.POINT_5,
      rejectPoint: EAqlPoint.POINT_6,
      sampleSize: EAqlSampleSize.SIZE_50,
    },
    [AqlValue.AQL_6_5]: {
      acceptPoint: EAqlPoint.POINT_7,
      rejectPoint: EAqlPoint.POINT_8,
      sampleSize: EAqlSampleSize.SIZE_50,
    },
  },
  [EAqlCodeLetter.LETTER_J]: {
    [AqlValue.NOT_ALLOWED]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_0,
      sampleSize: EAqlSampleSize.SIZE_80,
    },
    [AqlValue.AQL_0_065]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_200,
    },
    [AqlValue.AQL_0_1]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_125,
    },
    [AqlValue.AQL_0_15]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_80,
    },
    [AqlValue.AQL_0_25]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_50,
    },
    [AqlValue.AQL_0_4]: {
      acceptPoint: EAqlPoint.POINT_1,
      rejectPoint: EAqlPoint.POINT_2,
      sampleSize: EAqlSampleSize.SIZE_125,
    },
    [AqlValue.AQL_0_65]: {
      acceptPoint: EAqlPoint.POINT_1,
      rejectPoint: EAqlPoint.POINT_2,
      sampleSize: EAqlSampleSize.SIZE_80,
    },
    [AqlValue.AQL_1_0]: {
      acceptPoint: EAqlPoint.POINT_2,
      rejectPoint: EAqlPoint.POINT_3,
      sampleSize: EAqlSampleSize.SIZE_80,
    },
    [AqlValue.AQL_1_5]: {
      acceptPoint: EAqlPoint.POINT_3,
      rejectPoint: EAqlPoint.POINT_4,
      sampleSize: EAqlSampleSize.SIZE_80,
    },
    [AqlValue.AQL_2_5]: {
      acceptPoint: EAqlPoint.POINT_5,
      rejectPoint: EAqlPoint.POINT_6,
      sampleSize: EAqlSampleSize.SIZE_80,
    },
    [AqlValue.AQL_4_0]: {
      acceptPoint: EAqlPoint.POINT_7,
      rejectPoint: EAqlPoint.POINT_8,
      sampleSize: EAqlSampleSize.SIZE_80,
    },
    [AqlValue.AQL_6_5]: {
      acceptPoint: EAqlPoint.POINT_10,
      rejectPoint: EAqlPoint.POINT_11,
      sampleSize: EAqlSampleSize.SIZE_80,
    },
  },
  [EAqlCodeLetter.LETTER_K]: {
    [AqlValue.NOT_ALLOWED]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_0,
      sampleSize: EAqlSampleSize.SIZE_125,
    },
    [AqlValue.AQL_0_065]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_200,
    },
    [AqlValue.AQL_0_1]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_125,
    },
    [AqlValue.AQL_0_15]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_80,
    },
    [AqlValue.AQL_0_25]: {
      acceptPoint: EAqlPoint.POINT_1,
      rejectPoint: EAqlPoint.POINT_2,
      sampleSize: EAqlSampleSize.SIZE_200,
    },
    [AqlValue.AQL_0_4]: {
      acceptPoint: EAqlPoint.POINT_1,
      rejectPoint: EAqlPoint.POINT_2,
      sampleSize: EAqlSampleSize.SIZE_125,
    },
    [AqlValue.AQL_0_65]: {
      acceptPoint: EAqlPoint.POINT_2,
      rejectPoint: EAqlPoint.POINT_3,
      sampleSize: EAqlSampleSize.SIZE_125,
    },
    [AqlValue.AQL_1_0]: {
      acceptPoint: EAqlPoint.POINT_3,
      rejectPoint: EAqlPoint.POINT_4,
      sampleSize: EAqlSampleSize.SIZE_125,
    },
    [AqlValue.AQL_1_5]: {
      acceptPoint: EAqlPoint.POINT_5,
      rejectPoint: EAqlPoint.POINT_6,
      sampleSize: EAqlSampleSize.SIZE_125,
    },
    [AqlValue.AQL_2_5]: {
      acceptPoint: EAqlPoint.POINT_7,
      rejectPoint: EAqlPoint.POINT_8,
      sampleSize: EAqlSampleSize.SIZE_125,
    },
    [AqlValue.AQL_4_0]: {
      acceptPoint: EAqlPoint.POINT_10,
      rejectPoint: EAqlPoint.POINT_11,
      sampleSize: EAqlSampleSize.SIZE_125,
    },
    [AqlValue.AQL_6_5]: {
      acceptPoint: EAqlPoint.POINT_14,
      rejectPoint: EAqlPoint.POINT_15,
      sampleSize: EAqlSampleSize.SIZE_125,
    },
  },
  [EAqlCodeLetter.LETTER_L]: {
    [AqlValue.NOT_ALLOWED]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_0,
      sampleSize: EAqlSampleSize.SIZE_200,
    },
    [AqlValue.AQL_0_065]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_200,
    },
    [AqlValue.AQL_0_1]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_125,
    },
    [AqlValue.AQL_0_15]: {
      acceptPoint: EAqlPoint.POINT_1,
      rejectPoint: EAqlPoint.POINT_2,
      sampleSize: EAqlSampleSize.SIZE_315,
    },
    [AqlValue.AQL_0_25]: {
      acceptPoint: EAqlPoint.POINT_1,
      rejectPoint: EAqlPoint.POINT_2,
      sampleSize: EAqlSampleSize.SIZE_200,
    },
    [AqlValue.AQL_0_4]: {
      acceptPoint: EAqlPoint.POINT_2,
      rejectPoint: EAqlPoint.POINT_3,
      sampleSize: EAqlSampleSize.SIZE_200,
    },
    [AqlValue.AQL_0_65]: {
      acceptPoint: EAqlPoint.POINT_3,
      rejectPoint: EAqlPoint.POINT_4,
      sampleSize: EAqlSampleSize.SIZE_200,
    },
    [AqlValue.AQL_1_0]: {
      acceptPoint: EAqlPoint.POINT_5,
      rejectPoint: EAqlPoint.POINT_6,
      sampleSize: EAqlSampleSize.SIZE_200,
    },
    [AqlValue.AQL_1_5]: {
      acceptPoint: EAqlPoint.POINT_7,
      rejectPoint: EAqlPoint.POINT_8,
      sampleSize: EAqlSampleSize.SIZE_200,
    },
    [AqlValue.AQL_2_5]: {
      acceptPoint: EAqlPoint.POINT_10,
      rejectPoint: EAqlPoint.POINT_11,
      sampleSize: EAqlSampleSize.SIZE_200,
    },
    [AqlValue.AQL_4_0]: {
      acceptPoint: EAqlPoint.POINT_14,
      rejectPoint: EAqlPoint.POINT_15,
      sampleSize: EAqlSampleSize.SIZE_200,
    },
    [AqlValue.AQL_6_5]: {
      acceptPoint: EAqlPoint.POINT_21,
      rejectPoint: EAqlPoint.POINT_22,
      sampleSize: EAqlSampleSize.SIZE_200,
    },
  },
  [EAqlCodeLetter.LETTER_M]: {
    [AqlValue.NOT_ALLOWED]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_0,
      sampleSize: EAqlSampleSize.SIZE_315,
    },
    [AqlValue.AQL_0_065]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_200,
    },
    [AqlValue.AQL_0_1]: {
      acceptPoint: EAqlPoint.POINT_1,
      rejectPoint: EAqlPoint.POINT_2,
      sampleSize: EAqlSampleSize.SIZE_500,
    },
    [AqlValue.AQL_0_15]: {
      acceptPoint: EAqlPoint.POINT_1,
      rejectPoint: EAqlPoint.POINT_2,
      sampleSize: EAqlSampleSize.SIZE_315,
    },
    [AqlValue.AQL_0_25]: {
      acceptPoint: EAqlPoint.POINT_2,
      rejectPoint: EAqlPoint.POINT_3,
      sampleSize: EAqlSampleSize.SIZE_315,
    },
    [AqlValue.AQL_0_4]: {
      acceptPoint: EAqlPoint.POINT_3,
      rejectPoint: EAqlPoint.POINT_4,
      sampleSize: EAqlSampleSize.SIZE_315,
    },
    [AqlValue.AQL_0_65]: {
      acceptPoint: EAqlPoint.POINT_5,
      rejectPoint: EAqlPoint.POINT_6,
      sampleSize: EAqlSampleSize.SIZE_315,
    },
    [AqlValue.AQL_1_0]: {
      acceptPoint: EAqlPoint.POINT_7,
      rejectPoint: EAqlPoint.POINT_8,
      sampleSize: EAqlSampleSize.SIZE_315,
    },
    [AqlValue.AQL_1_5]: {
      acceptPoint: EAqlPoint.POINT_10,
      rejectPoint: EAqlPoint.POINT_11,
      sampleSize: EAqlSampleSize.SIZE_315,
    },
    [AqlValue.AQL_2_5]: {
      acceptPoint: EAqlPoint.POINT_14,
      rejectPoint: EAqlPoint.POINT_15,
      sampleSize: EAqlSampleSize.SIZE_315,
    },
    [AqlValue.AQL_4_0]: {
      acceptPoint: EAqlPoint.POINT_21,
      rejectPoint: EAqlPoint.POINT_22,
      sampleSize: EAqlSampleSize.SIZE_315,
    },
    [AqlValue.AQL_6_5]: {
      acceptPoint: EAqlPoint.POINT_21,
      rejectPoint: EAqlPoint.POINT_22,
      sampleSize: EAqlSampleSize.SIZE_200,
    },
  },
  [EAqlCodeLetter.LETTER_N]: {
    [AqlValue.NOT_ALLOWED]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_0,
      sampleSize: EAqlSampleSize.SIZE_500,
    },
    [AqlValue.AQL_0_065]: {
      acceptPoint: EAqlPoint.POINT_1,
      rejectPoint: EAqlPoint.POINT_2,
      sampleSize: EAqlSampleSize.SIZE_800,
    },
    [AqlValue.AQL_0_1]: {
      acceptPoint: EAqlPoint.POINT_1,
      rejectPoint: EAqlPoint.POINT_2,
      sampleSize: EAqlSampleSize.SIZE_500,
    },
    [AqlValue.AQL_0_15]: {
      acceptPoint: EAqlPoint.POINT_2,
      rejectPoint: EAqlPoint.POINT_3,
      sampleSize: EAqlSampleSize.SIZE_500,
    },
    [AqlValue.AQL_0_25]: {
      acceptPoint: EAqlPoint.POINT_3,
      rejectPoint: EAqlPoint.POINT_4,
      sampleSize: EAqlSampleSize.SIZE_500,
    },
    [AqlValue.AQL_0_4]: {
      acceptPoint: EAqlPoint.POINT_5,
      rejectPoint: EAqlPoint.POINT_6,
      sampleSize: EAqlSampleSize.SIZE_500,
    },
    [AqlValue.AQL_0_65]: {
      acceptPoint: EAqlPoint.POINT_7,
      rejectPoint: EAqlPoint.POINT_8,
      sampleSize: EAqlSampleSize.SIZE_500,
    },
    [AqlValue.AQL_1_0]: {
      acceptPoint: EAqlPoint.POINT_10,
      rejectPoint: EAqlPoint.POINT_11,
      sampleSize: EAqlSampleSize.SIZE_500,
    },
    [AqlValue.AQL_1_5]: {
      acceptPoint: EAqlPoint.POINT_14,
      rejectPoint: EAqlPoint.POINT_15,
      sampleSize: EAqlSampleSize.SIZE_500,
    },
    [AqlValue.AQL_2_5]: {
      acceptPoint: EAqlPoint.POINT_21,
      rejectPoint: EAqlPoint.POINT_22,
      sampleSize: EAqlSampleSize.SIZE_500,
    },
    [AqlValue.AQL_4_0]: {
      acceptPoint: EAqlPoint.POINT_21,
      rejectPoint: EAqlPoint.POINT_22,
      sampleSize: EAqlSampleSize.SIZE_315,
    },
    [AqlValue.AQL_6_5]: {
      acceptPoint: EAqlPoint.POINT_21,
      rejectPoint: EAqlPoint.POINT_22,
      sampleSize: EAqlSampleSize.SIZE_200,
    },
  },
  [EAqlCodeLetter.LETTER_P]: {
    [AqlValue.NOT_ALLOWED]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_0,
      sampleSize: EAqlSampleSize.SIZE_800,
    },
    [AqlValue.AQL_0_065]: {
      acceptPoint: EAqlPoint.POINT_1,
      rejectPoint: EAqlPoint.POINT_2,
      sampleSize: EAqlSampleSize.SIZE_800,
    },
    [AqlValue.AQL_0_1]: {
      acceptPoint: EAqlPoint.POINT_2,
      rejectPoint: EAqlPoint.POINT_3,
      sampleSize: EAqlSampleSize.SIZE_800,
    },
    [AqlValue.AQL_0_15]: {
      acceptPoint: EAqlPoint.POINT_3,
      rejectPoint: EAqlPoint.POINT_4,
      sampleSize: EAqlSampleSize.SIZE_800,
    },
    [AqlValue.AQL_0_25]: {
      acceptPoint: EAqlPoint.POINT_5,
      rejectPoint: EAqlPoint.POINT_6,
      sampleSize: EAqlSampleSize.SIZE_800,
    },
    [AqlValue.AQL_0_4]: {
      acceptPoint: EAqlPoint.POINT_7,
      rejectPoint: EAqlPoint.POINT_8,
      sampleSize: EAqlSampleSize.SIZE_800,
    },
    [AqlValue.AQL_0_65]: {
      acceptPoint: EAqlPoint.POINT_10,
      rejectPoint: EAqlPoint.POINT_11,
      sampleSize: EAqlSampleSize.SIZE_800,
    },
    [AqlValue.AQL_1_0]: {
      acceptPoint: EAqlPoint.POINT_14,
      rejectPoint: EAqlPoint.POINT_15,
      sampleSize: EAqlSampleSize.SIZE_800,
    },
    [AqlValue.AQL_1_5]: {
      acceptPoint: EAqlPoint.POINT_21,
      rejectPoint: EAqlPoint.POINT_22,
      sampleSize: EAqlSampleSize.SIZE_800,
    },
    [AqlValue.AQL_2_5]: {
      acceptPoint: EAqlPoint.POINT_21,
      rejectPoint: EAqlPoint.POINT_22,
      sampleSize: EAqlSampleSize.SIZE_500,
    },
    [AqlValue.AQL_4_0]: {
      acceptPoint: EAqlPoint.POINT_21,
      rejectPoint: EAqlPoint.POINT_22,
      sampleSize: EAqlSampleSize.SIZE_315,
    },
    [AqlValue.AQL_6_5]: {
      acceptPoint: EAqlPoint.POINT_21,
      rejectPoint: EAqlPoint.POINT_22,
      sampleSize: EAqlSampleSize.SIZE_200,
    },
  },
  [EAqlCodeLetter.LETTER_Q]: {
    [AqlValue.NOT_ALLOWED]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_0,
      sampleSize: EAqlSampleSize.SIZE_1250,
    },
    [AqlValue.AQL_0_065]: {
      acceptPoint: EAqlPoint.POINT_2,
      rejectPoint: EAqlPoint.POINT_3,
      sampleSize: EAqlSampleSize.SIZE_1250,
    },
    [AqlValue.AQL_0_1]: {
      acceptPoint: EAqlPoint.POINT_3,
      rejectPoint: EAqlPoint.POINT_4,
      sampleSize: EAqlSampleSize.SIZE_1250,
    },
    [AqlValue.AQL_0_15]: {
      acceptPoint: EAqlPoint.POINT_5,
      rejectPoint: EAqlPoint.POINT_6,
      sampleSize: EAqlSampleSize.SIZE_1250,
    },
    [AqlValue.AQL_0_25]: {
      acceptPoint: EAqlPoint.POINT_7,
      rejectPoint: EAqlPoint.POINT_8,
      sampleSize: EAqlSampleSize.SIZE_1250,
    },
    [AqlValue.AQL_0_4]: {
      acceptPoint: EAqlPoint.POINT_10,
      rejectPoint: EAqlPoint.POINT_11,
      sampleSize: EAqlSampleSize.SIZE_1250,
    },
    [AqlValue.AQL_0_65]: {
      acceptPoint: EAqlPoint.POINT_14,
      rejectPoint: EAqlPoint.POINT_15,
      sampleSize: EAqlSampleSize.SIZE_1250,
    },
    [AqlValue.AQL_1_0]: {
      acceptPoint: EAqlPoint.POINT_21,
      rejectPoint: EAqlPoint.POINT_22,
      sampleSize: EAqlSampleSize.SIZE_1250,
    },
    [AqlValue.AQL_1_5]: {
      acceptPoint: EAqlPoint.POINT_21,
      rejectPoint: EAqlPoint.POINT_22,
      sampleSize: EAqlSampleSize.SIZE_800,
    },
    [AqlValue.AQL_2_5]: {
      acceptPoint: EAqlPoint.POINT_21,
      rejectPoint: EAqlPoint.POINT_22,
      sampleSize: EAqlSampleSize.SIZE_500,
    },
    [AqlValue.AQL_4_0]: {
      acceptPoint: EAqlPoint.POINT_21,
      rejectPoint: EAqlPoint.POINT_22,
      sampleSize: EAqlSampleSize.SIZE_315,
    },
    [AqlValue.AQL_6_5]: {
      acceptPoint: EAqlPoint.POINT_21,
      rejectPoint: EAqlPoint.POINT_22,
      sampleSize: EAqlSampleSize.SIZE_200,
    },
  },
  [EAqlCodeLetter.LETTER_R]: {
    [AqlValue.NOT_ALLOWED]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_0,
      sampleSize: EAqlSampleSize.SIZE_2000,
    },
    [AqlValue.AQL_0_065]: {
      acceptPoint: EAqlPoint.POINT_3,
      rejectPoint: EAqlPoint.POINT_4,
      sampleSize: EAqlSampleSize.SIZE_2000,
    },
    [AqlValue.AQL_0_1]: {
      acceptPoint: EAqlPoint.POINT_5,
      rejectPoint: EAqlPoint.POINT_6,
      sampleSize: EAqlSampleSize.SIZE_2000,
    },
    [AqlValue.AQL_0_15]: {
      acceptPoint: EAqlPoint.POINT_7,
      rejectPoint: EAqlPoint.POINT_8,
      sampleSize: EAqlSampleSize.SIZE_2000,
    },
    [AqlValue.AQL_0_25]: {
      acceptPoint: EAqlPoint.POINT_10,
      rejectPoint: EAqlPoint.POINT_11,
      sampleSize: EAqlSampleSize.SIZE_2000,
    },
    [AqlValue.AQL_0_4]: {
      acceptPoint: EAqlPoint.POINT_14,
      rejectPoint: EAqlPoint.POINT_15,
      sampleSize: EAqlSampleSize.SIZE_2000,
    },
    [AqlValue.AQL_0_65]: {
      acceptPoint: EAqlPoint.POINT_21,
      rejectPoint: EAqlPoint.POINT_22,
      sampleSize: EAqlSampleSize.SIZE_2000,
    },
    [AqlValue.AQL_1_0]: {
      acceptPoint: EAqlPoint.POINT_21,
      rejectPoint: EAqlPoint.POINT_22,
      sampleSize: EAqlSampleSize.SIZE_1250,
    },
    [AqlValue.AQL_1_5]: {
      acceptPoint: EAqlPoint.POINT_21,
      rejectPoint: EAqlPoint.POINT_22,
      sampleSize: EAqlSampleSize.SIZE_800,
    },
    [AqlValue.AQL_2_5]: {
      acceptPoint: EAqlPoint.POINT_21,
      rejectPoint: EAqlPoint.POINT_22,
      sampleSize: EAqlSampleSize.SIZE_500,
    },
    [AqlValue.AQL_4_0]: {
      acceptPoint: EAqlPoint.POINT_21,
      rejectPoint: EAqlPoint.POINT_22,
      sampleSize: EAqlSampleSize.SIZE_315,
    },
    [AqlValue.AQL_6_5]: {
      acceptPoint: EAqlPoint.POINT_21,
      rejectPoint: EAqlPoint.POINT_22,
      sampleSize: EAqlSampleSize.SIZE_200,
    },
  },
  // Non standard
  [EAqlCodeLetter.LETTER_Z]: {
    [AqlValue.NOT_ALLOWED]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_0,
      sampleSize: EAqlSampleSize.SIZE_1,
    },
    [AqlValue.AQL_0_065]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_200,
    },
    [AqlValue.AQL_0_1]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_125,
    },
    [AqlValue.AQL_0_15]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_80,
    },
    [AqlValue.AQL_0_25]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_50,
    },
    [AqlValue.AQL_0_4]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_32,
    },
    [AqlValue.AQL_0_65]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_20,
    },
    [AqlValue.AQL_1_0]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_13,
    },
    [AqlValue.AQL_1_5]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_8,
    },
    [AqlValue.AQL_2_5]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_5,
    },
    [AqlValue.AQL_4_0]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_3,
    },
    [AqlValue.AQL_6_5]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_1,
      sampleSize: EAqlSampleSize.SIZE_2,
    },
  },
  // Non standard
  [EAqlCodeLetter.LETTER_X]: {
    [AqlValue.NOT_ALLOWED]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_0,
      sampleSize: EAqlSampleSize.SIZE_0,
    },
    [AqlValue.AQL_0_065]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_0,
      sampleSize: EAqlSampleSize.SIZE_0,
    },
    [AqlValue.AQL_0_1]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_0,
      sampleSize: EAqlSampleSize.SIZE_0,
    },
    [AqlValue.AQL_0_15]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_0,
      sampleSize: EAqlSampleSize.SIZE_0,
    },
    [AqlValue.AQL_0_25]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_0,
      sampleSize: EAqlSampleSize.SIZE_0,
    },
    [AqlValue.AQL_0_4]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_0,
      sampleSize: EAqlSampleSize.SIZE_0,
    },
    [AqlValue.AQL_0_65]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_0,
      sampleSize: EAqlSampleSize.SIZE_0,
    },
    [AqlValue.AQL_1_0]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_0,
      sampleSize: EAqlSampleSize.SIZE_0,
    },
    [AqlValue.AQL_1_5]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_0,
      sampleSize: EAqlSampleSize.SIZE_0,
    },
    [AqlValue.AQL_2_5]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_0,
      sampleSize: EAqlSampleSize.SIZE_0,
    },
    [AqlValue.AQL_4_0]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_0,
      sampleSize: EAqlSampleSize.SIZE_0,
    },
    [AqlValue.AQL_6_5]: {
      acceptPoint: EAqlPoint.POINT_0,
      rejectPoint: EAqlPoint.POINT_0,
      sampleSize: EAqlSampleSize.SIZE_0,
    },
  },
};
