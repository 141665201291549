import { qpIsFiniteNumber } from '@library/functions/checks/qp-is-finite-number';
import { QimaOptionalType } from '@qima/ngx-qima';

const FALLBACK_VALUE = Infinity;

/**
 * @param {QimaOptionalType<string | number>} value The value to check if not finite
 *
 * @returns {number} The value or the fallback value when not finite
 */
export function qpInfinityWhenNotFiniteNumber(value: QimaOptionalType<string | number>): number {
  return qpIsFiniteNumber(value) ? value : FALLBACK_VALUE;
}
