import { Directive, ElementRef, Input, Renderer2, OnInit } from '@angular/core';
import { kebabCase } from 'lodash/index';

/**
 * @deprecated
 * Use {@link QpButtonComponent} instead
 */
export enum EQpButtonDirectiveSize {
  /**
   * @deprecated
   * @type {EQpButtonDirectiveSize.SMALL}
   */
  SMALL = 'SMALL',
  /**
   * @deprecated
   * @type {EQpButtonDirectiveSize.MEDIUM}
   */
  MEDIUM = 'MEDIUM',
  /**
   * @deprecated
   * @type {EQpButtonDirectiveSize.LARGE}
   */
  LARGE = 'LARGE',
}

/**
 * @deprecated
 * Use {@link QpButtonComponent} instead
 */
export enum EQpButtonDirectiveType {
  /**
   * @deprecated
   * @type {EQpButtonDirectiveType.HERO_POOL}
   */
  HERO_POOL = 'HERO_POOL',
  /**
   * @deprecated
   * Replaced by {@link EQpButtonDirectiveType#DANGER}
   * @type {EQpButtonDirectiveType.HERO_RED}
   */
  HERO_RED = 'HERO_RED',
  /**
   * @deprecated
   * @type {EQpButtonDirectiveType.HERO_BLUE}
   */
  HERO_BLUE = 'HERO_BLUE',
  /**
   * @deprecated
   * @type {EQpButtonDirectiveType.HERO_GREEN}
   */
  HERO_GREEN = 'HERO_GREEN',
  /**
   * @deprecated
   * Replaced by {@link EQpButtonDirectiveType#SECONDARY}
   * @type {EQpButtonDirectiveType.HOLLOW}
   */
  HOLLOW = 'HOLLOW',
  /**
   * @deprecated
   * @type {EQpButtonDirectiveType.SIMPLE}
   */
  SIMPLE = 'SIMPLE',
}

/**
 * @deprecated
 * Use {@link QpButtonComponent} instead
 */
export enum EQpButtonDirectiveWidth {
  /**
   * @deprecated
   * @type {EQpButtonDirectiveWidth.COMPACT}
   */
  COMPACT = 'COMPACT',
  /**
   * @deprecated
   * @type {EQpButtonDirectiveWidth.FULL}
   */
  FULL = 'FULL',
  /**
   * @deprecated
   * @type {EQpButtonDirectiveWidth.LARGE}
   */
  LARGE = 'LARGE',
  /**
   * @deprecated
   * @type {EQpButtonDirectiveWidth.MEDIUM}
   */
  MEDIUM = 'MEDIUM',
  /**
   * @deprecated
   * @type {EQpButtonDirectiveWidth.SMALL}
   */
  SMALL = 'SMALL',
}

/**
 * @deprecated
 * Use {@link QpButtonComponent} instead
 */
@Directive({
  selector: 'button[qpButton],a[qpButton]',
  standalone: true,
})
export class QpButtonDirective implements OnInit {
  private _buttonType = EQpButtonDirectiveType.HERO_RED;
  private _buttonSize = EQpButtonDirectiveSize.LARGE;
  private _buttonWidth = EQpButtonDirectiveWidth.COMPACT;

  public constructor(private readonly _elementRef: ElementRef, private readonly _renderer: Renderer2) {}

  /**
   * @deprecated
   * Use {@link QpButtonComponent} instead
   * @returns {EQpButtonDirectiveType}
   */
  @Input()
  public get qpButtonType(): EQpButtonDirectiveType {
    return this._buttonType;
  }

  public set qpButtonType(type: EQpButtonDirectiveType) {
    this._removeTypeClass();
    this._buttonType = type;
    this._addTypeClass();
  }

  /**
   * @deprecated
   * Use {@link QpButtonComponent} instead
   * @returns {EQpButtonDirectiveSize}
   */
  @Input()
  public get qpButtonSize(): EQpButtonDirectiveSize {
    return this._buttonSize;
  }

  public set qpButtonSize(size: EQpButtonDirectiveSize) {
    this._removeSizeClass();
    this._buttonSize = size;
    this._addSizeClass();
  }

  /**
   * @deprecated
   * Use {@link QpButtonComponent} instead
   * @returns {EQpButtonDirectiveWidth}
   */
  @Input()
  public get qpButtonWidth(): EQpButtonDirectiveWidth {
    return this._buttonWidth;
  }

  public set qpButtonWidth(width: EQpButtonDirectiveWidth) {
    this._removeWidthClass();
    this._buttonWidth = width;
    this._addWidthClass();
  }

  public ngOnInit(): void {
    this._createSpan();
    this._addTypeClass();
    this._addSizeClass();
    this._addWidthClass();
  }

  private _removeTypeClass(): void {
    this._renderer.removeClass(this._elementRef.nativeElement, this._getButtonTypeClass());
  }

  private _addTypeClass(): void {
    this._renderer.addClass(this._elementRef.nativeElement, this._getButtonTypeClass());
  }

  private _removeSizeClass(): void {
    this._renderer.removeClass(this._elementRef.nativeElement, this._getButtonSizeClass());
  }

  private _addSizeClass(): void {
    this._renderer.addClass(this._elementRef.nativeElement, this._getButtonSizeClass());
  }

  private _removeWidthClass(): void {
    this._renderer.removeClass(this._elementRef.nativeElement, this._getButtonWidthClass());
  }

  private _addWidthClass(): void {
    this._renderer.addClass(this._elementRef.nativeElement, this._getButtonWidthClass());
  }

  private _createSpan(): void {
    const wrappingSpan: HTMLSpanElement = this._renderer.createElement('span');

    wrappingSpan.classList.add('qp-button');

    this._renderer.insertBefore(this._elementRef.nativeElement.parentNode, wrappingSpan, this._elementRef.nativeElement);
    this._renderer.appendChild(wrappingSpan, this._elementRef.nativeElement);
  }

  private _getButtonTypeClass(): string {
    return `type-${kebabCase(this._buttonType)}`;
  }

  private _getButtonSizeClass(): string {
    return `size-${kebabCase(this._buttonSize)}`;
  }

  private _getButtonWidthClass(): string {
    return `width-${kebabCase(this._buttonWidth)}`;
  }
}
