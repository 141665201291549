import { IspBarcodeScannerService } from '@one/app/pages/isp/shared/services/isp-barcode-scanner.service';

/**
 * @description
 * Factory that will initialize the Inspector barcode scanner service
 *
 * @param {IspBarcodeScannerService} ispBarcodeScannerService The Inspector barcode scanner service to initialize
 *
 * @returns {() => void} Function that initialize the Inspector barcode scanner service
 */
export function ispBarcodeScannerInitInitFactory(ispBarcodeScannerService: IspBarcodeScannerService): () => void {
  return (): void => {
    ispBarcodeScannerService.init();
  };
}
