import { DefectClassification } from '@library/dto-enums/defect-classification.dto-enum';

export const INITIAL_COUNT = 0;

export const ZERO = 0;

export const ZERO_DEFECTS = 0;

export const TWO_DIGITS = 2;

export type IspDefectsClassificationMapType = Map<DefectClassification, number>;
