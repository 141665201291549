// @ts-strict-ignore
import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';

/**
 * @description
 * Languages codes are ISO_639-1 codes, see http://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
 * They are written in English to avoid character encoding issues (not a perfect solution)
 * @type {string[]}
 */
const LANGUAGES: string[] = [
  'en',
  'en-produce',
  'zh',
  'es',
  'es-produce',
  'hi',
  'bn',
  'ta',
  'vi',
  'fr',
  'pt',
  'en-action',
  'de',
  'ja',
  'ko',
];

@Injectable({ providedIn: 'root' })
export class LanguageHelperService {
  public renderer: Renderer2 = null;
  private readonly _language: BehaviorSubject<string>;

  public constructor(
    private readonly translateService: TranslateService,
    private readonly titleService: Title,
    private readonly router: Router,
    rootRenderer: RendererFactory2
  ) {
    this._language = new BehaviorSubject<string>(this.translateService.currentLang);
    this.renderer = rootRenderer.createRenderer(document.querySelector('html'), null);
    this._init();
  }

  public getAll(): Promise<string[]> {
    return Promise.resolve(LANGUAGES);
  }

  public get language(): Observable<string> {
    return this._language.asObservable();
  }

  /**
   * Update the window title using params in the following
   * order:
   * 1. titleKey parameter
   * 2. $state.$current.data.pageTitle (current state page title)
   * 3. 'global.title'
   *
   * @param titleKey
   */
  public updateTitle(titleKey?: string): void {
    if (!titleKey) {
      titleKey = this._getPageTitle(this.router.routerState.snapshot.root);
    }

    this.translateService.get(titleKey).subscribe((title): void => {
      this.titleService.setTitle(title);
    });
  }

  private _init(): void {
    this.translateService.onLangChange.subscribe((): void => {
      this._language.next(this.translateService.currentLang);
      this.renderer.setAttribute(document.querySelector('html'), 'lang', this.translateService.currentLang);
      this.updateTitle();
    });
  }

  private _getPageTitle(routeSnapshot: ActivatedRouteSnapshot): string {
    let title: string = routeSnapshot.data?.['pageTitle'] ? routeSnapshot.data['pageTitle'] : 'QIMAone';

    if (routeSnapshot.firstChild) {
      title = this._getPageTitle(routeSnapshot.firstChild) || title;
    }

    return title;
  }
}
