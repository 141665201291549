import { QpLoggerService } from '@library/services/qp-logger/qp-logger.service';
import { SERVER_API_URL } from '@one/app/app.constants';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QimaOptionalType } from '@qima/ngx-qima';
import { isEmpty, isNil } from 'lodash/index';
import { LocalStorageService } from 'ngx-webstorage';
import { BehaviorSubject, Observable, of, throwError, firstValueFrom } from 'rxjs';
import { catchError, switchMap, map, take, tap } from 'rxjs/operators';

const BARCODE_SCANNER_LICENSE_KEY = 'BARCODE_SCANNER_LICENSE_KEY';

type LicenseType = string;

export interface IBarcodeScannerLicenseDTO {
  license: LicenseType;
}

@Injectable({
  providedIn: 'root',
})
export class IspBarcodeScannerService {
  private readonly _resourceUrl = `${SERVER_API_URL}api/scandit/license`;
  private readonly _barcodeScannerLicense$: BehaviorSubject<QimaOptionalType<LicenseType>> = new BehaviorSubject<
    QimaOptionalType<LicenseType>
  >(null);

  private _licenseHasBeenRefreshed = false;

  public constructor(
    protected _httpClient: HttpClient,
    private readonly _localStorageService: LocalStorageService,
    private readonly _qpLoggerService: QpLoggerService
  ) {}

  public init(): void {
    const savedLicense: QimaOptionalType<LicenseType> = this._getSavedLicense();

    this._qpLoggerService.group('IspBarcodeScannerService initialized', true);

    if (savedLicense) {
      this._qpLoggerService.info('Found a local persisted license');
    } else {
      this._qpLoggerService.info('There is no local persisted license');
    }

    this._qpLoggerService.groupEnd();
    this._barcodeScannerLicense$.next(savedLicense);
  }

  public getBarcodeScannerLicense(): Promise<LicenseType> {
    return firstValueFrom(
      this._barcodeScannerLicense$.pipe(
        take(1),
        switchMap((license: QimaOptionalType<LicenseType>): Observable<LicenseType> => {
          if (!isNil(license) && !isEmpty(license) && this._licenseHasBeenRefreshed) {
            return of(license);
          }

          return this._fetchBarcodeScannerLicense$();
        })
      )
    );
  }

  private _fetchBarcodeScannerLicense$(): Observable<LicenseType> {
    return this._httpClient.get<IBarcodeScannerLicenseDTO>(this._resourceUrl).pipe(
      catchError((err): Observable<never> => {
        this._qpLoggerService.error(err);

        return throwError((): Error => err);
      }),
      tap((resp: IBarcodeScannerLicenseDTO): void => {
        this._saveLicense(resp.license);
        this._licenseHasBeenRefreshed = true;
      }),
      map((resp: IBarcodeScannerLicenseDTO): LicenseType => resp.license)
    );
  }

  private _saveLicense(license: Readonly<LicenseType>): void {
    this._barcodeScannerLicense$.next(license);
    this._localStorageService.store(BARCODE_SCANNER_LICENSE_KEY, license);
  }

  private _getSavedLicense(): QimaOptionalType<LicenseType> {
    return this._localStorageService.retrieve(BARCODE_SCANNER_LICENSE_KEY);
  }
}
