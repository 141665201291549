import { googleTagManagerAuthFactory } from '@one/app/shared/factories/google-tag-manager-auth.factory';
import { GOOGLE_TAG_MANAGER_AUTH } from '@one/app/shared/tokens/google-tag-manager-auth.token';
import { WINDOW } from '@one/app/shared/tokens/window.token';
import { FactoryProvider } from '@angular/core';

export const GOOGLE_TAG_MANAGER_AUTH_PROVIDER: FactoryProvider = {
  deps: [WINDOW],
  provide: GOOGLE_TAG_MANAGER_AUTH,
  useFactory: googleTagManagerAuthFactory,
};
