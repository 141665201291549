<qima-button-icon
  [qimaButtonIconStyle]="buttonIconStyles.TERTIARY_UTILITY"
  [qimaButtonIconName]="iconNames.SEARCH"
  (qimaButtonIconClick)="displaySearchBar()"
  class="search-icon"
  [ngClass]="{ 'search-icon--displayed': !isSearchBarDisplayed }"
></qima-button-icon>

<qima-input-search-select
  [formControl]="control"
  [qimaInputSearchSelectHasClearButton]="false"
  (qimaInputSearchSelectSearchInputChange)="search($event)"
  [qimaInputSearchSelectIsLoading]="isLoading"
  (focusout)="hideSearchBar()"
  class="search-bar"
  [ngClass]="{ 'search-bar--displayed': isSearchBarDisplayed }"
  data-cy="quick-search-searchbar"
>
  <qima-menu [qimaMenuMaxHeight]="500" *ngIf="searchTerm$ | async">
    <qima-menu-item-ice-cube
      *ngFor="let result of quickSearchResults$ | async; trackBy: trackByIdAndType"
      [qimaMenuItemIceCubeIconName]="typesConfigs[result.type].icon"
      [qimaMenuItemIceCubeIconStyle]="typesConfigs[result.type].color"
      [qimaMenuItemIceCubeIconHasOutline]="false"
      [qimaMenuItemIceCubeLabel]="result.title"
      [qimaMenuItemIceCubeValue]="result"
      (qimaMenuItemIceCubeClick)="selectQuickSearchResult(result)"
    >
    </qima-menu-item-ice-cube>
  </qima-menu>
</qima-input-search-select>
