// @ts-strict-ignore
import {
  EChecklistImageType,
  IChecklistComment,
  IChecklistImageData,
} from '@one/app/shared/models/inspection-consultation/inspection-consultation.models';
import { UntypedFormGroup } from '@angular/forms';

export class InspectionImage implements IChecklistImageData {
  public static fromInspectionImageData(image: IChecklistImageData): InspectionImage {
    const imageData = new InspectionImage();

    imageData.content = image.content;
    imageData.date = image.date;
    imageData.timezone = image.timezone;
    imageData.type = image.type;
    imageData.isSpotlight = image.isSpotlight;
    imageData.imageId = image.imageId;
    imageData.comment = image.comment;
    imageData.parentImage = image.parentImage;

    imageData.form = new UntypedFormGroup({});

    return imageData;
  }

  public static fromIChecklistImageDatas(images: IChecklistImageData[]): InspectionImage[] {
    return images.map(InspectionImage.fromInspectionImageData);
  }

  public content?: string;
  public date: Date;
  public timezone: string;
  public type: EChecklistImageType;
  public imageId?: string;
  public isSpotlight: boolean;
  public comment?: IChecklistComment;
  public parentImage?: string;
  public form: UntypedFormGroup;
}
