// @ts-strict-ignore
import { EQpIconName } from '@library/components/qp-icon/qp-icon.models';
import { ChecklistElasticDTO } from '@library/dto/checklist/elastic/checklist-elastic.dto';
import { TestsChecklistCreationDTO } from '@library/dto/tests-checklist-creation.dto';
import { TestsChecklistDTO } from '@library/dto/tests-checklist.dto';
import { EQpAQLInspectionLevel } from '@library/models/qp-aql.models';
import { IQpTestsChecklistElement, QpTestsChecklistElementType } from '@library/models/qp-tests-checklist-element.models';
import { QpDateService } from '@library/services/qp-date/qp-date.service';
import { QpFileUploadService } from '@library/services/qp-file-upload/qp-file-upload.service';
import { IQpFileUploadArrayBuffer, IQpFileUploadMetadata } from '@library/services/qp-file-upload/qp-file-upload.service.models';
import { SERVER_API_URL } from '@one/app/app.constants';
import { IDefectArrayBuffer } from '@one/app/pages/brd/shared/services/defects-checklist.service';
import { ChecklistRequirement } from '@one/app/shared/classes/checklist/checklist-requirement';
import { Answer } from '@one/app/shared/models/answer/answer.models';
import { AqlTestSamplingSize } from '@one/app/shared/models/aql/aql.models';
import { EChecklistRequirementType, IChecklistRequirement } from '@one/app/shared/models/checklist/checklist-requirement.models';
import { ITestsChecklistDetails } from '@one/app/shared/models/checklist/tests-checklist.models';
import { ESamplingSizeType, FlatSamplingSizeType, TestSamplingSizeMapType } from '@one/app/shared/models/sampling/sampling-size.models';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TestsChecklistService {
  public static testSamplingSizeMap = new Map<ESamplingSizeType | FlatSamplingSizeType, (AqlTestSamplingSize) => TestSamplingSizeMapType>([
    // TODO: have to be back when PL-xxxx is done
    // [
    //   ESamplingSizeType.NON_AQL_SAMPLE_SIZE,
    //   (): TestSamplingSizeMapType => {
    //     return {
    //       type: ESamplingSizeType.NON_AQL_SAMPLE_SIZE,
    //     };
    //   },
    // ],
    [
      ESamplingSizeType.QUANTITY_SAMPLE_SIZE,
      (samplingSize: AqlTestSamplingSize): TestSamplingSizeMapType => {
        return {
          type: ESamplingSizeType.QUANTITY_SAMPLE_SIZE,
          quantity: samplingSize.quantity,
          unit: samplingSize.unit,
        };
      },
    ],
    [
      ESamplingSizeType.PERCENTAGE_SAMPLE_SIZE,
      (samplingSize: AqlTestSamplingSize): TestSamplingSizeMapType => {
        return {
          type: FlatSamplingSizeType.PERCENTAGE_SAMPLE_SIZE,
          percentage: samplingSize.percentage,
        };
      },
    ],
    [
      FlatSamplingSizeType.INSPECTION_LEVEL_SAMPLE_SIZE_I,
      (_samplingSize: AqlTestSamplingSize): TestSamplingSizeMapType => {
        return {
          type: ESamplingSizeType.INSPECTION_LEVEL_SAMPLE_SIZE,
          inspectionLevel: EQpAQLInspectionLevel.I,
        };
      },
    ],
    [
      FlatSamplingSizeType.INSPECTION_LEVEL_SAMPLE_SIZE_II,
      (_samplingSize: AqlTestSamplingSize): TestSamplingSizeMapType => {
        return {
          type: ESamplingSizeType.INSPECTION_LEVEL_SAMPLE_SIZE,
          inspectionLevel: EQpAQLInspectionLevel.II,
        };
      },
    ],
    [
      FlatSamplingSizeType.INSPECTION_LEVEL_SAMPLE_SIZE_III,
      (_samplingSize: AqlTestSamplingSize): TestSamplingSizeMapType => {
        return {
          type: ESamplingSizeType.INSPECTION_LEVEL_SAMPLE_SIZE,
          inspectionLevel: EQpAQLInspectionLevel.III,
        };
      },
    ],
    [
      FlatSamplingSizeType.INSPECTION_LEVEL_SAMPLE_SIZE_S1,
      (_samplingSize: AqlTestSamplingSize): TestSamplingSizeMapType => {
        return {
          type: ESamplingSizeType.INSPECTION_LEVEL_SAMPLE_SIZE,
          inspectionLevel: EQpAQLInspectionLevel.S1,
        };
      },
    ],
    [
      FlatSamplingSizeType.INSPECTION_LEVEL_SAMPLE_SIZE_S2,
      (_samplingSize: AqlTestSamplingSize): TestSamplingSizeMapType => {
        return {
          type: ESamplingSizeType.INSPECTION_LEVEL_SAMPLE_SIZE,
          inspectionLevel: EQpAQLInspectionLevel.S2,
        };
      },
    ],
    [
      FlatSamplingSizeType.INSPECTION_LEVEL_SAMPLE_SIZE_S3,
      (_samplingSize: AqlTestSamplingSize): TestSamplingSizeMapType => {
        return {
          type: ESamplingSizeType.INSPECTION_LEVEL_SAMPLE_SIZE,
          inspectionLevel: EQpAQLInspectionLevel.S3,
        };
      },
    ],
    [
      FlatSamplingSizeType.INSPECTION_LEVEL_SAMPLE_SIZE_S4,
      (_samplingSize: AqlTestSamplingSize): TestSamplingSizeMapType => {
        return {
          type: ESamplingSizeType.INSPECTION_LEVEL_SAMPLE_SIZE,
          inspectionLevel: EQpAQLInspectionLevel.S4,
        };
      },
    ],
  ]);

  public static mapTestSamplingSize(samplingSize: AqlTestSamplingSize): TestSamplingSizeMapType {
    if (TestsChecklistService.testSamplingSizeMap.has(samplingSize.type)) {
      return TestsChecklistService.testSamplingSizeMap.get(samplingSize.type)(samplingSize);
    }

    return { type: ESamplingSizeType.AQL_NOT_APPLICABLE };
  }

  private static _toArrayBuffer(response: Readonly<IQpFileUploadArrayBuffer>, documentId: Readonly<string>): IDefectArrayBuffer {
    return {
      ...response,
      id: documentId,
    };
  }

  public readonly newRequirementsList: ChecklistRequirement[] = [
    ChecklistRequirement.create({
      type: EChecklistRequirementType.PICTURE,
      icon: EQpIconName.IC_CAMERA,
      translationKey: 'global.checklist.picture',
      isEnabled: true,
      isRequired: false,
    }),
    ChecklistRequirement.create({
      type: EChecklistRequirementType.SCAN,
      icon: EQpIconName.IC_SCAN,
      translationKey: 'global.checklist.scan',
      isEnabled: true,
      isRequired: false,
    }),
    ChecklistRequirement.create({
      type: EChecklistRequirementType.COMMENT,
      icon: EQpIconName.IC_NOTE,
      translationKey: 'global.checklist.comment',
      isEnabled: true,
      isRequired: false,
    }),
    ChecklistRequirement.create({
      type: EChecklistRequirementType.SPOTLIGHT,
      icon: EQpIconName.IC_SPOTLIGHT,
      translationKey: 'global.checklist.spotlight',
      isEnabled: true,
      isRequired: false,
    }),
    ChecklistRequirement.create({
      type: EChecklistRequirementType.ATTACHMENT,
      icon: EQpIconName.IC_ATTACHMENT,
      translationKey: 'global.checklist.attachment',
      isEnabled: true,
      isRequired: false,
    }),
  ];

  private readonly _checklistsUrl = `${SERVER_API_URL}api/checklists`;
  private readonly _testsChecklistsUrl = `${SERVER_API_URL}api/tests-checklists`;

  public constructor(
    private readonly _httpClient: HttpClient,
    private readonly _qpDateService: QpDateService,
    private readonly _qpFileUploadService: QpFileUploadService
  ) {}

  public createTestsChecklist$(testsChecklist: TestsChecklistCreationDTO): Observable<TestsChecklistDTO> {
    return this._httpClient.post<TestsChecklistDTO>(this._testsChecklistsUrl, testsChecklist);
  }

  public saveTestsChecklist$(testsChecklist: ITestsChecklistDetails): Observable<ITestsChecklistDetails> {
    return this._httpClient.put<ITestsChecklistDetails>(this._testsChecklistsUrl, testsChecklist);
  }

  public uploadInstructionDocument(testsChecklistId: number, file: NzUploadFile): Observable<HttpResponse<unknown>> {
    const path = `${this._testsChecklistsUrl}/${testsChecklistId}/upload`;

    return this._qpFileUploadService.upload$(path, file);
  }

  public getInstructionDocumentMetadata(testsChecklistId: number, documentId: string): Observable<IQpFileUploadMetadata> {
    const path = `${this._testsChecklistsUrl}/${testsChecklistId}/documents/${documentId}/metadata`;

    return this._qpFileUploadService.loadMetadata$(path);
  }

  public getInstructionDocumentUrl(testsChecklistId: number, documentId: string): string {
    return `${this._testsChecklistsUrl}/${testsChecklistId}/documents/${documentId}`;
  }

  public getInstructionDocument(testsChecklistId: number, documentId: string, isThumbnail = true): Observable<IDefectArrayBuffer> {
    const path = this.getInstructionDocumentUrl(testsChecklistId, documentId);

    return this._qpFileUploadService
      .loadArrayBuffer$(path, {
        params: {
          isThumbnail,
        },
      })
      .pipe(
        map(
          (response: Readonly<IQpFileUploadArrayBuffer>): IDefectArrayBuffer => TestsChecklistService._toArrayBuffer(response, documentId)
        )
      );
  }

  /**
   * @deprecated use instead {@link TestsChecklistService.getTestsChecklistDTO$}
   * getTestsChecklistDTO$ return the real DTO type...
   * @param {number} testsChecklistId the id of the tests checklist
   * @returns {Observable<ITestsChecklistDetails>} The details of the tests checklist
   */
  public getTestsChecklist$(testsChecklistId: number): Observable<ITestsChecklistDetails> {
    const url = `${this._testsChecklistsUrl}/${testsChecklistId}`;

    return this._httpClient.get<ITestsChecklistDetails>(url, { observe: 'body' });
  }

  public getTestsChecklistDTO$(testsChecklistId: number): Observable<TestsChecklistDTO> {
    const url = `${this._testsChecklistsUrl}/${testsChecklistId}`;

    return this._httpClient.get<TestsChecklistDTO>(url, { observe: 'body' });
  }

  public getPublishedTestChecklists$(): Observable<ChecklistElasticDTO[]> {
    const url = `${this._checklistsUrl}?filter=publicationStatus,EQUALS,PUBLISHED&filter=type,EQUALS,TESTS&size=500&sort=lastModifiedDate,DESC`;

    return this._httpClient.get<ChecklistElasticDTO[]>(url, { observe: 'body' });
  }

  public getPublishedTestChecklistsByIds$(ids: number[]): Observable<ChecklistElasticDTO[]> {
    const url = `${this._checklistsUrl}?filter=publicationStatus,EQUALS,PUBLISHED&filter=type,EQUALS,TESTS&filter=id,IN,[${ids.join(',')}]`;

    return this._httpClient.get<ChecklistElasticDTO[]>(url, { observe: 'body' });
  }

  public mapRequirements(currentTestsChecklist: ITestsChecklistDetails, isTestsChecklistSave: boolean): ITestsChecklistDetails {
    if (this.hasSection(currentTestsChecklist)) {
      const sectionList: IQpTestsChecklistElement<Answer>[] = currentTestsChecklist.content['elements'] ?? [];

      sectionList.forEach((section: QpTestsChecklistElementType): void => {
        if (this.hasQuestion(section)) {
          section['elements'] = section['elements'].map((question: QpTestsChecklistElementType): QpTestsChecklistElementType => {
            return {
              ...question,
              requirements: question?.requirements
                ? isTestsChecklistSave
                  ? this.mapRequirementToBack(question.requirements)
                  : this.mapRequirement(question.requirements)
                : [],
            };
          });
        }
      });
    }

    return currentTestsChecklist;
  }

  public duplicate(id: number): Observable<HttpResponse<void>> {
    return this._httpClient.post<void>(
      `${this._testsChecklistsUrl}/${id}/duplicate`,
      {
        timezone: this._qpDateService.getTimezone(),
      },
      {
        observe: 'response',
      }
    );
  }

  public deleteTestsChecklist(testsChecklistId: Readonly<number>): Observable<void> {
    return this._httpClient.request<void>('delete', `${this._testsChecklistsUrl}/${testsChecklistId}`, {
      body: {
        deletionTimezone: this._qpDateService.getTimezone(),
        deletionDate: this._qpDateService.getDate(),
      },
    });
  }

  private mapRequirement(requirementToMapList: IChecklistRequirement[]): IChecklistRequirement[] {
    // We want to create a new list without modifying the initialisation list
    return Object.assign([], this.newRequirementsList).map((requirement: ChecklistRequirement): ChecklistRequirement => {
      return {
        ...requirement,
        isRequired: requirementToMapList.some(
          (requirementToMap: IChecklistRequirement): boolean => requirementToMap.type === requirement.type
        ),
      };
    });
  }

  private mapRequirementToBack(requirementToMapList: IChecklistRequirement[]): IChecklistRequirement[] {
    return requirementToMapList.filter((requirement: IChecklistRequirement): boolean => requirement.isRequired);
  }

  private hasSection(value: ITestsChecklistDetails): boolean {
    return !!(value.content?.['elements'] && value.content['elements'].length > 0);
  }

  private hasQuestion(section: QpTestsChecklistElementType): boolean {
    return section?.['elements'] && section['elements'].length > 0;
  }
}
