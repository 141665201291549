import { EQpProfile } from '@library/models/qp-profile.models';
import { IDataLayer } from '@one/app/shared/services/third-party/analytics-manager/models/analytics-manager.models';

export interface IDataLayerLoginEvent
  extends Pick<IDataLayer, 'category' | 'action' | 'label' | 'value' | 'brandName' | 'position' | 'profiles'> {
  category: EDataLayerType.LOGIN;
  action: EDataLayerEventAction.LOGIN;
  label: EGTMTitle;
  value: EDataLayerEventValue.USER_DATA;
  brandName: string;
  position: string;
  profiles: EQpProfile[];
}

export type DataLayerLoginEventType = IDataLayerLoginEvent & Pick<IDataLayer, 'event'>;

export enum EDataLayerType {
  LOGIN = 'login',
}

export enum EDataLayerEventAction {
  LOGIN = 'login',
}

export enum EDataLayerEventValue {
  USER_DATA = 'userData',
}

export enum EGTMTitle {
  LOGIN = 'login',
}
