import { EQpFileExtension } from '@library/models/qp-file-type.models';

export enum EQpMimeType {
  APPLICATION_PDF = 'application/pdf',
  VIDEO_MP4 = 'video/mp4',
  VIDEO_WMV = 'video/x-ms-wmv',
  VIDEO_MPG = 'video/mpg',
  VIDEO_XMPG = 'video/x-mpg',
  VIDEO_MPEG = 'video/mpeg',
  VIDEO_XMPEG = 'video/x-mpeg',
  VIDEO_AVI = 'video/avi',
  VIDEO_MSVIDEO = 'video/msvideo',
  VIDEO_XMSVIDEO = 'video/x-msvideo',
  VIDEO_QUICKTIME = 'video/quicktime',
  IMAGE_JPEG = 'image/jpeg',
  IMAGE_JPG = 'image/jpg',
  IMAGE_PNG = 'image/png',
  IMAGE_GIF = 'image/gif',
  IMAGE_WEBP = 'image/webp',
  CSV = 'text/csv',
  WORD_DOC = 'application/msword',
  WORD_OPENXML = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  WORD_WITH_MACROS = 'application/vnd.ms-word.document.macroEnabled.12',
  EXCEL_XLS = 'application/vnd.ms-excel',
  EXCEL_OPENXML = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  EXCEL_WITH_MACROS = 'application/vnd.ms-excel.sheet.macroEnabled.12',
  EXCEL_ODS = 'application/vnd.oasis.opendocument.spreadsheet',
  TEXT_TXT = 'text/plain',
  EML = 'message/rfc822',
  APPLICATION_RAR = 'application/vnd.rar',
  APPLICATION_XRAR = 'application/x-rar',
  APPLICATION_X_COMPRESSED = 'application/x-compressed',
  APPLICATION_XRAR_COMPRESSED = 'application/x-rar-compressed',
  APPLICATION_ZIP = 'application/zip',
  APPLICATION_XZIP_COMPRESSED = 'application/x-zip-compressed',
  APPLICATION_PPT = 'application/vnd.ms-powerpoint',
  APPLICATION_PPTX = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
}

export const MIME_TYPES_GROUPS = {
  images: [EQpMimeType.IMAGE_JPEG, EQpMimeType.IMAGE_JPG, EQpMimeType.IMAGE_PNG, EQpMimeType.IMAGE_GIF],
  videos: [
    EQpMimeType.VIDEO_MP4,
    EQpMimeType.VIDEO_WMV,
    EQpMimeType.VIDEO_MPG,
    EQpMimeType.VIDEO_XMPG,
    EQpMimeType.VIDEO_MPEG,
    EQpMimeType.VIDEO_XMPEG,
    EQpMimeType.VIDEO_MSVIDEO,
    EQpMimeType.VIDEO_XMSVIDEO,
    EQpMimeType.VIDEO_QUICKTIME,
    EQpMimeType.VIDEO_AVI,
  ],
  documents: [
    EQpMimeType.CSV,
    EQpMimeType.APPLICATION_PDF,
    EQpMimeType.WORD_DOC,
    EQpMimeType.WORD_OPENXML,
    EQpMimeType.WORD_WITH_MACROS,
    EQpMimeType.EXCEL_XLS,
    EQpMimeType.EXCEL_OPENXML,
    EQpMimeType.EXCEL_WITH_MACROS,
    EQpMimeType.EXCEL_ODS,
    EQpMimeType.TEXT_TXT,
    EQpMimeType.EML,
    EQpMimeType.APPLICATION_RAR,
    EQpMimeType.APPLICATION_XRAR,
    EQpMimeType.APPLICATION_X_COMPRESSED,
    EQpMimeType.APPLICATION_XRAR_COMPRESSED,
    EQpMimeType.APPLICATION_ZIP,
    EQpMimeType.APPLICATION_XZIP_COMPRESSED,
    EQpMimeType.APPLICATION_PPT,
    EQpMimeType.APPLICATION_PPTX,

    // When MimeType is not enough for the browser and need to be specified using the file extension
    EQpFileExtension.RAR,
    EQpFileExtension.PPS,
    EQpFileExtension.TTX,
    EQpFileExtension.LTD,
    EQpFileExtension.TTV,
    EQpFileExtension.ODS,
  ],
};

export enum EQpMimeTypeCategory {
  IMAGE = 'image',
  VIDEO = 'video',
  TEXT = 'text',
}
