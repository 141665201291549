import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NetworkStatusService {
  private readonly isOnLineSubject = new BehaviorSubject<boolean>(navigator.onLine);
  private _manualOffline = false;

  public get isOnline$(): Observable<boolean> {
    return this.isOnLineSubject.asObservable();
  }

  public get isOnline(): boolean {
    return this.isOnLineSubject.getValue();
  }

  public get isManualOffline(): boolean {
    return this._manualOffline;
  }

  public constructor() {
    window.addEventListener(
      'offline',
      (): void => {
        if (!this._manualOffline) {
          this.isOnLineSubject.next(false);
        }
      },
      false
    );
    window.addEventListener(
      'online',
      (): void => {
        if (!this._manualOffline) {
          this.isOnLineSubject.next(true);
        }
      },
      false
    );
  }

  public toggleManualOffline(shouldGoOffline?: boolean): void {
    this._manualOffline = shouldGoOffline ?? !this._manualOffline;

    if (!this._manualOffline) {
      if (!navigator.onLine) {
        throw new Error('Navigator is offline');
      }

      this.isOnLineSubject.next(true);
    } else if (this._manualOffline && navigator.onLine) {
      this.isOnLineSubject.next(false);
    }
  }
}
