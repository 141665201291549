import { MixpanelService } from '@one/app/shared/services/third-party/analytics-manager/mixpanel/mixpanel.service';
import { ITrackingEvent } from '@one/app/shared/services/third-party/analytics-manager/tracking/tracking.models';
import { Injectable } from '@angular/core';
import { Dict } from 'mixpanel-browser';

@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  public constructor(private readonly _mixpanelService: MixpanelService) {}

  public init(): void {
    this._mixpanelService.init();
  }

  public identify(userToken: string, properties: Dict): void {
    this._mixpanelService.identify(userToken, properties);
  }

  public track(event: ITrackingEvent): void {
    this._mixpanelService.track(event.id, event.properties);
  }

  public reset(): void {
    this._mixpanelService.reset();
  }
}
