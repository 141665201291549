<div class="navbar-wrapper" data-cy="mobile-navbar">
  <ul class="navbar collapsed">
    <li *ngFor="let menu of bottomMenuList" class="navbar_item">
      <a
        [attr.data-cy]="menu.dataCy"
        [routerLinkActiveOptions]="menu.routerLinkActiveOption"
        [routerLinkActive]="(menu.defaultPageRoute?.routerLink || menu.routerLink) !== commonRoutes.comingSoon() ? 'menu-selected' : ''"
        [routerLink]="menu.defaultPageRoute?.routerLink || menu.routerLink"
      >
        <div class="icon--badge">
          <qp-icon class="menu-icon" [name]="menu.iconName"></qp-icon>
        </div>
        <span class="menu menu--primary label-bottom-bar">{{ menu.title | translate }}</span>
      </a>
    </li>
    <li *ngIf="expandMenuPrimary.length && expandMenuSecondary.length" class="navbar_item">
      <a (click)="expandMenu()" data-cy="expand-menu">
        <qp-icon class="menu-icon" [name]="iconNames.IC_MENU"></qp-icon>
        <span class="menu label-bottom-bar">{{ 'global.menu.more' | translate }}</span>
      </a>
    </li>
  </ul>

  <nz-drawer
    (nzOnClose)="expandMenu()"
    [nzClosable]="false"
    [nzVisible]="isExpandMenuVisible"
    nzHeight="fit-content"
    nzPlacement="bottom"
    nzWrapClassName="expandMenuFitToContent"
  >
    <ng-container *nzDrawerContent>
      <div class="navbar-container">
        <div class="close-button">
          <qp-icon (click)="expandMenu()" [name]="iconNames.IC_CLOSE_12"></qp-icon>
        </div>
        <ul class="navbar expanded">
          <li *ngFor="let menu of expandMenuPrimary" class="navbar_item">
            <a
              (click)="expandMenu()"
              [attr.data-cy]="menu.dataCy"
              [routerLinkActiveOptions]="menu.routerLinkActiveOption"
              [routerLinkActive]="
                (menu.defaultPageRoute?.routerLink || menu.routerLink) !== commonRoutes.comingSoon() ? 'menu-selected' : ''
              "
              [routerLink]="menu.defaultPageRoute?.routerLink || menu.routerLink"
            >
              <qp-icon class="menu-icon" [name]="menu.iconName"></qp-icon>
              <span class="menu label-bottom-bar">{{ menu.title | translate }}</span>
            </a>
          </li>
          <qp-divider *ngIf="expandMenuPrimary?.length > 0 && expandMenuSecondary?.length > 0" class="divider"></qp-divider>
          <li *ngFor="let menu of expandMenuSecondary" class="navbar_item">
            <a
              (click)="expandMenu()"
              [attr.data-cy]="menu.dataCy"
              [routerLinkActiveOptions]="menu.routerLinkActiveOption"
              [routerLinkActive]="
                (menu.defaultPageRoute?.routerLink || menu.routerLink) !== commonRoutes.comingSoon() ? 'menu-selected' : ''
              "
              [routerLink]="menu.defaultPageRoute?.routerLink || menu.routerLink"
            >
              <qp-icon class="menu-icon" [name]="menu.iconName"></qp-icon>
              <span class="menu label-bottom-bar">{{ menu.title | translate }}</span>
            </a>
          </li>
        </ul>
      </div>
      <qp-divider class="navbar--divider"></qp-divider>
      <div class="account-container">
        <a
          (click)="expandMenu()"
          [attr.data-cy]="accountRoute?.dataCy"
          [routerLinkActiveOptions]="accountRoute?.routerLinkActiveOption"
          [routerLinkActive]="
            (accountRoute?.defaultPageRoute?.routerLink || accountRoute?.routerLink) !== commonRoutes.comingSoon() ? 'menu-selected' : ''
          "
          [routerLink]="accountRoute?.defaultPageRoute?.routerLink || accountRoute?.routerLink"
          class="profile menu-item"
        >
          <div class="picture">
            <img
              class="picture__asset"
              [src]="'/api/users/' + account?.id + '/profile-picture'"
              qpFallbackImage
              [qpFallbackImageUrl]="defaultPictureUrl"
              alt="profile picture"
            />
          </div>
          <div class="information">
            <div class="information__name">{{ account?.firstName }}</div>
            <div class="information__role">{{ account?.profiles[0] | oneAuthorityLabel | translate }}</div>
          </div>
          <a *ngFor="let subEntity of accountRoute?.children" [routerLink]="subEntity?.routerLink"></a>
        </a>
      </div>
    </ng-container>
  </nz-drawer>
</div>
