// @ts-strict-ignore
import {
  IMeasurement,
  IProductMeasure,
  MeasurementsType,
  IMeasurementsDTO,
  IMeasurementsWithSection,
  IProductMeasureDTO,
  EMeasurementUnitTolerance,
} from '@one/app/shared/models/measurements/measurements.models';
import { QimaOptionalType } from '@qima/ngx-qima';
import { isEmpty } from 'lodash/index';

export class ProductMeasurement {
  public static formatMeasurementToRequest(measurementsData: MeasurementsType): IMeasurementsDTO {
    const sectionMeasurements: IProductMeasureDTO[] = [];
    let allUnitTolerance: EMeasurementUnitTolerance;

    measurementsData.forEach((currentMeasurement: IMeasurementsWithSection): void => {
      const { unitTolerance, measurements, section } = currentMeasurement;

      allUnitTolerance = unitTolerance;
      const productSectionMeasurement: IProductMeasureDTO[] = measurements.map((measurement: IProductMeasure): IProductMeasureDTO => {
        if (isEmpty(section)) {
          return measurement;
        }

        return { ...measurement, section };
      });

      sectionMeasurements.push(...productSectionMeasurement);
    });

    return { measurements: sectionMeasurements, unitTolerance: allUnitTolerance };
  }

  public static formatMeasurementToSections(measurementsData: QimaOptionalType<IMeasurementsDTO>): MeasurementsType {
    if (isEmpty(measurementsData)) {
      return [];
    }

    const { measurements, unitTolerance } = measurementsData;
    const measurementWithoutSection: QimaOptionalType<IMeasurement> = this._getMeasurementsWithoutSections(measurements, unitTolerance);
    const measurementWithSection: IMeasurementsWithSection[] = this._getMeasurementsWithSections(measurements, unitTolerance);

    if (isEmpty(measurementWithoutSection)) {
      return measurementWithSection;
    }

    return [measurementWithoutSection];
  }

  private static _getMeasurementsWithoutSections(
    measurementsData: IProductMeasureDTO[],
    unitTolerance: EMeasurementUnitTolerance
  ): QimaOptionalType<IMeasurement> {
    const measurements = measurementsData.filter((measurement: IProductMeasureDTO): boolean => isEmpty(measurement.section));

    if (isEmpty(measurements)) {
      return undefined;
    }

    return { unitTolerance, measurements };
  }

  private static _getMeasurementsWithSections(
    measurementsData: IProductMeasureDTO[],
    unitTolerance: EMeasurementUnitTolerance
  ): IMeasurementsWithSection[] {
    const measurementWithSectionMap: Map<string, IProductMeasure[]> = new Map<string, IProductMeasure[]>();

    measurementsData.forEach((measurement: IProductMeasureDTO): void => {
      const { section, ...otherMeasurementData } = measurement;

      if (!isEmpty(section)) {
        if (!measurementWithSectionMap.has(section)) {
          measurementWithSectionMap.set(section, [otherMeasurementData]);
        } else {
          const sectionMeasurements: IProductMeasure[] = measurementWithSectionMap.get(section);

          measurementWithSectionMap.set(section, [...sectionMeasurements, otherMeasurementData]);
        }
      }
    });

    const measurementWithSection: IMeasurementsWithSection[] = Array.from(
      measurementWithSectionMap,
      ([section, measurements]): IMeasurementsWithSection => ({
        section,
        measurements,
        unitTolerance,
      })
    );

    return measurementWithSection;
  }
}
