import { googleTagManagerInitFactory } from '@one/app/shared/factories/google-tag-manager-init.factory';
import { GoogleTagManagerService } from '@one/app/shared/services/third-party/analytics-manager/google-tag-manager/services/google-tag-manager.service';
import { FactoryProvider, APP_BOOTSTRAP_LISTENER } from '@angular/core';

export const GOOGLE_TAG_MANAGER_INIT_PROVIDER: FactoryProvider = {
  deps: [GoogleTagManagerService],
  provide: APP_BOOTSTRAP_LISTENER,
  multi: true,
  useFactory: googleTagManagerInitFactory,
};
