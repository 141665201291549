import { QpLoggerService } from '@library/services/qp-logger/qp-logger.service';
import { LOGROCKET_ENVIRONMENT_ID } from '@one/app/shared/tokens/logrocket-environment-id.token';
import { Inject, Injectable } from '@angular/core';
import { isEmpty } from 'lodash/index';
import LogRocket from 'logrocket';

export const ENDPOINTS_WITH_PASSWORD: string[] = [
  'api/authenticate', // Login page
  'api/account/set-password', // Forgot password page
  'api/account/change-password', // Change password page
  'api/invitation/inspector/', // Inspectors invitation confirmation page
  'api/factories/invite', // Factories invitation confirmation page
  'api/invitation/auditor/', // Auditors invitation confirmation page
];

@Injectable({
  providedIn: 'root',
})
export class LogrocketInitService {
  public constructor(
    @Inject(LOGROCKET_ENVIRONMENT_ID) private readonly _logrocketEnvironmentId: Readonly<string>,
    private readonly _qpLoggerService: QpLoggerService
  ) {}

  public init(): void {
    if (!this._logrocketEnvironmentId || isEmpty(this._logrocketEnvironmentId.trim())) {
      this._qpLoggerService.warn('LogRocket Environment Id not defined');

      return;
    }

    LogRocket.init(this._logrocketEnvironmentId, {
      console: {
        shouldAggregateConsoleErrors: true,
      },
      network: {
        requestSanitizer: this.requestSanitizerLogRocket,
        responseSanitizer: this.responseSanitizerLogRocket,
      },
    });
  }

  /**
   * @todo waiting interface of LogRocket 👉 https://github.com/LogRocket/logrocket/issues/24
   * @param {undefined} request // LR.IResquet
   * @returns {undefined} request // LR.IResquet
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
  public requestSanitizerLogRocket(request) {
    if (ENDPOINTS_WITH_PASSWORD.some((path: string): boolean => request?.url?.toLowerCase().includes(path))) {
      delete request.body;
    }

    delete request.headers['Authorization'];
    delete request.headers['Cookie'];

    return request;
  }

  /**
   * @todo waiting interface of LogRocket 👉 https://github.com/LogRocket/logrocket/issues/24
   * @param {undefined} response // LR.IResquet
   * @returns {undefined} response // LR.IResquet
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
  public responseSanitizerLogRocket(response) {
    if (ENDPOINTS_WITH_PASSWORD.some((path: string): boolean => response?.url?.toLowerCase().includes(path))) {
      delete response.body;
    }

    delete response.headers['authorization'];

    return response;
  }
}
