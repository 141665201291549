import { AqlTypes } from '@library/dto-enums/aql-types.dto-enum';
import { AqlValue } from '@library/dto-enums/aql-value.dto-enum';
import { IStandardAqlDefects } from '@one/app/shared/models/defects/aql-defects.models';

export class AqlDefects implements IStandardAqlDefects {
  public readonly type: AqlTypes.STANDARD_AQL_DEFECTS = AqlTypes.STANDARD_AQL_DEFECTS;
  public critical: AqlValue;
  public major: AqlValue;
  public minor: AqlValue;
  public sampleSize?: number;

  public constructor(data: Readonly<Pick<IStandardAqlDefects, 'critical' | 'major' | 'minor' | 'sampleSize'>>) {
    this.critical = data.critical;
    this.major = data.major;
    this.minor = data.minor;
    this.sampleSize = data.sampleSize;
  }
}
