export enum Role {
  SELF_STARTING = 'SELF_STARTING',
  FULL_ACCESS = 'FULL_ACCESS',
}

export interface IRoleAssignmenRule {
  key: ERuleKey;
  rule: RuleType;
}

export enum ERuleKey {
  ONGOING_INSPECTION = 'ONGOING_INSPECTION',
}

export type RuleType = IInspectionOnGoingRule;

export interface IInspectionOnGoingRule {
  count: number;
}
