// @ts-strict-ignore
export interface IQpCountry {
  isoCode: string;
  label: string;
  callPrefix?: string;
}

export class QpCountry implements IQpCountry {
  public static create(country: { isoCode: string; label: string; callPrefix: string }): IQpCountry {
    return new QpCountry(country.isoCode, country.label, country.callPrefix);
  }

  public static newEmpty(): QpCountry {
    return new QpCountry(null, null, null);
  }

  private constructor(public isoCode: string, public label: string, public callPrefix?: string) {}
}
