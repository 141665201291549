// @ts-strict-ignore
import { StorageService } from '@one/app/shared/services/storage/storage.service';
import { HttpResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QimaOptionalType } from '@qima/ngx-qima';
import { Observable, Subject } from 'rxjs';
import { tap, take } from 'rxjs/operators';

interface ISerializedResponse<T> {
  type: 'HttpResponse';
  body: T;
  headers: { [key: string]: string };
  status: number;
  url: string;
  ok: boolean;
  statusText: string;
}

@Injectable({
  providedIn: 'root',
})
export class OfflineStoreService {
  public constructor(private readonly storageService: StorageService) {}

  public offlineStore<T, G = T extends HttpResponse<infer G> ? G : never, A = unknown>(
    target: Observable<T>,
    options: { storageKey: string; dependsOnArgs: boolean; args: ArrayLike<A> | Iterable<A>; login: string }
  ): Observable<T> {
    const key = this.calculateKey(options.storageKey, options.dependsOnArgs, options.args, options.login);

    if (!navigator.onLine) {
      return this.getFromStorage(key);
    }

    target = target.pipe(
      tap((rawResponse): void => {
        let serializedResponse: QimaOptionalType<ISerializedResponse<G>>;

        if (rawResponse instanceof HttpResponse) {
          serializedResponse = this.serializeHttpResponse(rawResponse);
        }

        this.storageService.saveData(key, serializedResponse ?? rawResponse);
      })
    );

    return target;
  }

  private serializeHttpResponse<T>(response: HttpResponse<T>): ISerializedResponse<T> {
    const stubResponse: ISerializedResponse<T> = {
      type: 'HttpResponse',
      body: response.body,
      headers: {},
      status: response.status,
      url: response.url,
      ok: response.ok,
      statusText: response.statusText,
    };

    response.headers.keys().forEach((key): void => {
      stubResponse.headers['key'] = response.headers.get(key);
    });

    return stubResponse;
  }

  private deserializeHttpResponse<T>(response: ISerializedResponse<T>): HttpResponse<T> {
    return new HttpResponse({
      body: response.body,
      status: response.status,
      headers: new HttpHeaders(response.headers),
      statusText: response.statusText,
      url: response.url,
    });
  }

  private getFromStorage<T, G = T extends HttpResponse<infer G> ? G : never>(key: string): Observable<T> {
    const subject: Subject<T> = new Subject();

    this.storageService
      .getData(key)
      .then((value: ISerializedResponse<G> | T): void => {
        if (this.isSerializedHttpResponse(value)) {
          subject.next(this.deserializeHttpResponse(value) as unknown as T);
        } else {
          subject.next(value);
        }
      })
      .catch((error): void => {
        subject.error(error);
      });

    return subject.asObservable().pipe(take(1));
  }

  private calculateKey<T>(storageKey: string, dependsOnArgs: boolean, callArguments: ArrayLike<T> | Iterable<T>, login: string): string {
    let key = storageKey;

    if (login) {
      key = login + key;
    }

    if (dependsOnArgs) {
      const stringArguments = Array.from(callArguments)
        .map((argument): string => JSON.stringify(argument))
        .join();

      key += this.hashCode(stringArguments);
    }

    return key;
  }

  private hashCode(s: string): string {
    let h = 0;
    let i = 0;

    if (s.length > 0) {
      while (i < s.length) {
        h = ((h << 5) - h + s.charCodeAt(i++)) | 0;
      }
    }

    return String(h);
  }

  private isSerializedHttpResponse<T, G = T extends HttpResponse<infer G> ? G : never>(
    data: ISerializedResponse<G> | T
  ): data is ISerializedResponse<G> {
    return data && typeof data === 'object' && 'type' in data && data.type === 'HttpResponse';
  }
}
