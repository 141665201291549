import { QpPasswordService } from '@library/classes/qp-password/qp-password.service';
import { QpAddressService } from '@library/components/qp-address/services/qp-address.service';
import { QpCountryService } from '@library/components/qp-country/services/qp-country.service';
import { QpPhoneModule } from '@library/components/qp-phone/qp-phone.module';
import { QpLargeDeviceDirective } from '@library/directives/qp-large-device/qp-large-device.directive';
import { QpSmallDeviceDirective } from '@library/directives/qp-small-device/qp-small-device.directive';
import { qpNgxQimaDateFactory } from '@library/factories/qp-ngx-qima-date.factory';
import { QpHttpParamsInterceptor } from '@library/interceptors/qp-http-params/qp-http-params.interceptor';
import { EQpLocale } from '@library/models/i18n/qp-locales.models';
import { QP_NGX_QIMA_TRANSLATION_PROVIDER } from '@library/providers/qp-ngx-qima-translation.provider';
import { QpLoggerService } from '@library/services/qp-logger/qp-logger.service';
import { QpNotificationBarModule } from '@library/services/qp-notification-bar/qp-notification-bar.module';
import { QimaBrandFrontAppRoutingModule } from '@one/app/app-routing.module';
import { EXT_MODULES } from '@one/app/build-specifics';
import { HomeModule } from '@one/app/components/home/home.module';
import { MainComponent } from '@one/app/components/main/main.component';
import { MobileMainNavbarComponent } from '@one/app/components/mobile-main-navbar/mobile-main-navbar.component';
import { SubnavComponent } from '@one/app/components/subnav/subnav.component';
import { SubnavBreadcrumbsModule } from '@one/app/components/subnav-breadcrumbs/subnav-breadcrumbs.module';
import { HammerConfig } from '@one/app/config/hammer-config';
import { ConnectService } from '@one/app/pages/connect/connect.service';
import { DesktopMainNavbarComponent } from '@one/app/shared/components/desktop-main-navbar/desktop-main-navbar.component';
import { AuthExpiredInterceptor } from '@one/app/shared/interceptors/auth-expired.interceptor';
import { AuthInterceptor } from '@one/app/shared/interceptors/auth.interceptor';
import { ErrorHandlerInterceptor } from '@one/app/shared/interceptors/error-handler.interceptor';
import { inspectionDataInterceptor$ } from '@one/app/shared/interceptors/inspection-data.interceptor';
import { InspectionInterceptor } from '@one/app/shared/interceptors/inspection.interceptor';
import { inspectorHomeInterceptor$ } from '@one/app/shared/interceptors/inspector-home.interceptor';
import { ngswBypassInterceptor$ } from '@one/app/shared/interceptors/ngsw-bypass/ngsw-bypass.interceptor';
import { QimaConnectInterceptor } from '@one/app/shared/interceptors/qima-connect.interceptor';
import { UserTimeZoneInterceptor } from '@one/app/shared/interceptors/user-timezone.interceptor';
import { BROWSER_WINDOW_PROVIDER } from '@one/app/shared/providers/browser-window.provider';
import { GLOBAL_ERROR_HANDLER_PROVIDER } from '@one/app/shared/providers/global-error-handler.provider';
import { GOOGLE_TAG_MANAGER_AUTH_PROVIDER } from '@one/app/shared/providers/google-tag-manager-auth.provider';
import { GOOGLE_TAG_MANAGER_ID_PROVIDER } from '@one/app/shared/providers/google-tag-manager-id.provider';
import { GOOGLE_TAG_MANAGER_INIT_PROVIDER } from '@one/app/shared/providers/google-tag-manager-init.provider';
import { GOOGLE_TAG_MANAGER_PREVIEW_PROVIDER } from '@one/app/shared/providers/google-tag-manager-preview.provider';
import { IAM_URL_PROVIDER } from '@one/app/shared/providers/iam-url.provider';
import { ISP_BARCODE_SCANNER_INIT_PROVIDER } from '@one/app/shared/providers/isp-barcode-scanner-init.provider';
import { LOCALE_INIT_PROVIDER } from '@one/app/shared/providers/locale-init.provider';
import { LOGROCKET_ENVIRONMENT_ID_PROVIDER } from '@one/app/shared/providers/logrocket-environment-id.provider';
import { MIXPANEL_ID_PROVIDER } from '@one/app/shared/providers/mixpanel.provider';
import { NOTIFUSE_CONFIG_PROVIDER } from '@one/app/shared/providers/notifuse-config.provider';
import { QIMA_CONNECT_CONFIG_PROVIDER } from '@one/app/shared/providers/qima-connect-config.provider';
import { QP_LOGGER_CONFIG_PROVIDER } from '@one/app/shared/providers/qp-logger-config.provider';
import { SERVICE_WORKER_PROVIDER } from '@one/app/shared/providers/service-worker.provider';
import { TRACKING_INIT_PROVIDER } from '@one/app/shared/providers/tracking-init.provider';
import { WINDOW_PROVIDER } from '@one/app/shared/providers/window.provider';
import { AuthenticationFactory } from '@one/app/shared/services/auth/authentication.factory';
import { AuthenticationService } from '@one/app/shared/services/auth/authentication.service';
import { AddressService } from '@one/app/shared/services/location/address.service';
import { CountryService } from '@one/app/shared/services/location/country.service';
import { PasswordService } from '@one/app/shared/services/password/password.service';
import { AppStoreModule } from '@one/app/store/store.module';
import { ENVIRONMENT } from '@one/environments/environment';
import packageJson from '@packageJson';
import { DatePipe } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpBackend,
  HttpClient,
  HttpClientModule,
  provideHttpClient,
  withInterceptors,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { APP_INITIALIZER, ClassProvider, FactoryProvider, Injector, LOCALE_ID, NgModule, ValueProvider } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  EQimaLoggerLevel,
  QIMA_ASSETS_PATH_TOKEN,
  QIMA_DATE_FACTORY_TOKEN,
  QimaDateModule,
  QimaDialogModule,
  QimaInputNumberModule,
  QimaLoggerModule,
  QimaModule,
  QimaTooltipDirectiveModule,
  QimaTranslationModule,
} from '@qima/ngx-qima';
import { OAuthModule, OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { en_US, NZ_I18N } from 'ng-zorro-antd/i18n';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { CookieModule, CookieService } from 'ngx-cookie';
import { NgxWebstorageModule, SessionStorageService } from 'ngx-webstorage';
import { lastValueFrom } from 'rxjs';
import 'dayjs/locale/bn';
import 'dayjs/locale/en';
import 'dayjs/locale/es';
import 'dayjs/locale/fr';
import 'dayjs/locale/hi';
import 'dayjs/locale/ta';
import 'dayjs/locale/vi';
import 'dayjs/locale/zh-cn';

/**
 * @description
 * ⚠️ Use {@link HttpBackend} instead of {@link HttpClient} to avoid circular dependency due to interceptors catching the requests
 * @param {HttpBackend} httpBackend The http backend instance
 * @returns {TranslateHttpLoader} The loader
 */
export function httpLoaderFactory(httpBackend: HttpBackend): TranslateHttpLoader {
  return new TranslateHttpLoader(new HttpClient(httpBackend), '/assets/i18n/', `.json?cacheBuster=v${packageJson.version}`);
}

/**
 * @description For IAM, use local storage instead of session storage for persistence
 * @returns {OAuthStorage} Local storage
 */
export function storageFactory(): OAuthStorage {
  return localStorage;
}

/**
 * @description Wait for translations ot be loaded before readying the app
 * @param {TranslateService} translate Translation service
 * @returns {() => Promise<unknown>} Promise resolving once translations are loaded
 */
export function waitForTranslations(translate: TranslateService): () => Promise<unknown> {
  return (): Promise<unknown> => {
    const langToSet: string = EQpLocale.ENGLISH;

    translate.setDefaultLang(langToSet);

    return lastValueFrom(translate.use(langToSet));
  };
}

@NgModule({
  imports: [
    BrowserModule,
    NgxWebstorageModule.forRoot({
      prefix: 'jhi',
      separator: '-',
    }),
    HomeModule,
    QimaBrandFrontAppRoutingModule,
    FormsModule,
    // ⚠️ HttpClientModule must be imported before TranslateModule
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: EQpLocale.ENGLISH,
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpBackend],
      },
    }),
    BrowserAnimationsModule,
    NzDropDownModule,
    AppStoreModule,
    EXT_MODULES,
    HammerModule,
    QpPhoneModule,
    NzDividerModule,
    NzIconModule,
    NzDrawerModule,
    NzSelectModule,
    ReactiveFormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: ENVIRONMENT.production }),
    CookieModule.forRoot(),
    SubnavBreadcrumbsModule,
    // Used by ngx-qima library
    AngularSvgIconModule.forRoot(),
    QimaModule.forRoot(),
    QimaTranslationModule.forRoot(),
    QimaDateModule,
    QimaLoggerModule.forRoot({ loggerLevel: EQimaLoggerLevel.WARNING }),
    OAuthModule.forRoot(),
    QpNotificationBarModule,
    QimaTooltipDirectiveModule,
    QimaDialogModule,
    QimaInputNumberModule.forRoot({ maximum: Number.MAX_SAFE_INTEGER }),
    SubnavComponent,
    DesktopMainNavbarComponent,
    MobileMainNavbarComponent,
    QpLargeDeviceDirective,
    QpSmallDeviceDirective,
  ],
  declarations: [MainComponent],
  providers: [
    provideHttpClient(withInterceptors([inspectionDataInterceptor$, inspectorHomeInterceptor$, ngswBypassInterceptor$])),
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthExpiredInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InspectionInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: QpHttpParamsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UserTimeZoneInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: QimaConnectInterceptor, multi: true },
    {
      provide: NZ_I18N,
      useValue: en_US,
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerConfig,
    },
    {
      provide: QpPasswordService,
      useClass: PasswordService,
    } as ClassProvider,
    {
      provide: QpAddressService,
      useClass: AddressService,
    } as ClassProvider,
    {
      provide: QpCountryService,
      useClass: CountryService,
    } as ClassProvider,
    BROWSER_WINDOW_PROVIDER,
    WINDOW_PROVIDER,
    GLOBAL_ERROR_HANDLER_PROVIDER,
    {
      provide: LOCALE_ID,
      useValue: EQpLocale.ENGLISH,
    } as ValueProvider,
    {
      provide: QIMA_ASSETS_PATH_TOKEN,
      useValue: 'ngx-qima/assets/',
    } as ValueProvider,
    LOCALE_INIT_PROVIDER,
    SERVICE_WORKER_PROVIDER,
    GOOGLE_TAG_MANAGER_ID_PROVIDER,
    GOOGLE_TAG_MANAGER_AUTH_PROVIDER,
    GOOGLE_TAG_MANAGER_PREVIEW_PROVIDER,
    GOOGLE_TAG_MANAGER_INIT_PROVIDER,
    MIXPANEL_ID_PROVIDER,
    TRACKING_INIT_PROVIDER,
    LOGROCKET_ENVIRONMENT_ID_PROVIDER,
    QP_LOGGER_CONFIG_PROVIDER,
    NOTIFUSE_CONFIG_PROVIDER,
    ISP_BARCODE_SCANNER_INIT_PROVIDER,
    QP_NGX_QIMA_TRANSLATION_PROVIDER,
    Title,
    DatePipe,
    QIMA_CONNECT_CONFIG_PROVIDER,
    IAM_URL_PROVIDER,
    {
      provide: AuthenticationService,
      useFactory: AuthenticationFactory,
      deps: [CookieService, OAuthService, HttpClient, Router, Injector, QpLoggerService, ConnectService, SessionStorageService],
    },
    { provide: OAuthStorage, useFactory: storageFactory },
    {
      provide: APP_INITIALIZER,
      useFactory: waitForTranslations,
      deps: [TranslateService],
      multi: true,
    },
    {
      provide: QIMA_DATE_FACTORY_TOKEN,
      useFactory: qpNgxQimaDateFactory,
    } as FactoryProvider,
  ],
  bootstrap: [MainComponent],
})
export class QimaBrandFrontAppModule {}
