import { EQpHttpStatusCode } from '@library/models/qp-http.models';
import { IHttpOptions } from '@one/app/shared/models/http/http.models';

// FYI: the workflow may be pre-fetch too soon, before inspection accepted
// The result is a 409 error that is catch by the interceptors
// This workaround allow to not catch the error and avoid this bad UX
// @todo remove this hack!
export const HTTP_SKIP_409_OPTIONS: IHttpOptions = {
  ignoredInterceptorStatusCodes: new Set<EQpHttpStatusCode>([EQpHttpStatusCode.CONFLICT]),
};
