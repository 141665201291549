import { EQpMeasurementUnit } from '@library/models/qp-measurements.models';

export enum EMeasurementUnitTolerance {
  PERCENTAGE = 'PERCENTAGE',
  UNIT = 'UNIT',
}

export interface IMeasurementLineDTO {
  variance: string;
  values: string[];
}

export interface IProductMeasure {
  reference: string;
  description: string;
  toleranceMax: number;
  toleranceMin: number;
  toInspect: boolean;
  measurePoints: IMeasurementLineDTO[];
  unit: EQpMeasurementUnit;
}

export interface IProductMeasureDTO extends IProductMeasure {
  section?: string;
}

/**
 * @description
 * Either you got a list of measurements inside a section
 * Either you got a list of one group of measurements without a section
 */
export type MeasurementsType = [IMeasurement] | IMeasurementsWithSection[];

export interface IMeasurement {
  measurements: IProductMeasure[];
  unitTolerance: EMeasurementUnitTolerance;
}

export interface IMeasurementsWithSection extends IMeasurement {
  section: string;
}

export interface IMeasurementsDTO {
  measurements: IProductMeasureDTO[];
  unitTolerance: EMeasurementUnitTolerance;
}
