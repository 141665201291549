import { DefectsCategoryDTO } from '@library/dto/checklist/defect/defects-category.dto';
import { IDefectsQuantityByClassification } from '@library/dto/checklist/defect/defects-checklist.utils';
import { DefectAnsweredDTO } from '@library/dto/testschecklist/answer/defect-answered.dto';
import { DefectAnsweredUtils } from '@library/dto/testschecklist/answer/defect-answered.utils';
import { WorkflowAnswerActionDefectDTO } from '@library/dto/testschecklist/answer/workflow/action/workflow-answer-action-defect.dto';
import { DefectClassification } from '@library/dto-enums/defect-classification.dto-enum';
import { QimaOptionalType } from '@qima/ngx-qima';

export class DefectsCategoryUtils {
  public constructor(private readonly _defectsCategoryDTO: DefectsCategoryDTO<DefectAnsweredDTO>) {}

  public computeTotalDefectsQuantity(purchaseOrderProductId?: QimaOptionalType<number>): number {
    return this._defectsCategoryDTO.defects.reduce(
      (accumulator, defect): number => accumulator + new DefectAnsweredUtils(defect).computeTotalDefectsQuantity(purchaseOrderProductId),
      0
    );
  }

  public computeDefectsQuantityByClassification(purchaseOrderProductId?: QimaOptionalType<number>): IDefectsQuantityByClassification {
    return Object.keys(DefectClassification).reduce(
      (accumulator, classification): IDefectsQuantityByClassification => ({
        ...accumulator,
        [classification]: this._defectsCategoryDTO.defects
          .filter((defect): boolean => defect.classification === classification)
          .reduce(
            (accumulator, defect): number =>
              accumulator + new DefectAnsweredUtils(defect).computeTotalDefectsQuantity(purchaseOrderProductId),
            0
          ),
      }),
      {}
    );
  }

  public extractNonEmptyDefects(purchaseOrderProductId?: QimaOptionalType<number>, allowedEmptyDefectId?: string[]): DefectAnsweredDTO[] {
    return this._defectsCategoryDTO.defects
      .filter(
        (defect): boolean =>
          !!defect.defectsFound?.some(
            (defectFound): boolean =>
              (!purchaseOrderProductId || defectFound.purchaseOrderProductId === purchaseOrderProductId) &&
              (!!defectFound.quantity || !!allowedEmptyDefectId?.includes(defect.id))
          )
      )
      .map((defect): DefectAnsweredDTO => {
        return {
          ...defect,
          defectsFound: defect.defectsFound?.filter(
            (defectFound): boolean => !purchaseOrderProductId || defectFound.purchaseOrderProductId === purchaseOrderProductId
          ),
        };
      });
  }

  public isValid(action: WorkflowAnswerActionDefectDTO, purchaseOrderProductId?: QimaOptionalType<number>): boolean {
    return this._defectsCategoryDTO.defects.every((defect): boolean =>
      new DefectAnsweredUtils(defect).isValid(action, purchaseOrderProductId)
    );
  }
}
