import { HttpHandler, HttpRequest, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class UserTimeZoneInterceptor implements HttpInterceptor {
  public intercept<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    const editedRequest = request.clone({
      headers: request.headers.set('timeZoneOffset', Intl.DateTimeFormat().resolvedOptions().timeZone),
    });

    return next.handle(editedRequest);
  }
}
