import { EInspectionResult } from '@one/app/pages/brd/pages/report/pages/inspection/pages/id/brd-report-inspection-id.models';
import { IspWorkflowStepAction } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/isp-workflow-step-action';
import { EWorkflowActionType, IWorkflowAction } from '@one/app/shared/models/workflow/workflow.models';

export class IspWorkflowStepActionReport extends IspWorkflowStepAction {
  public recipients: string[];
  public readonly type = EWorkflowActionType.REPORT;

  // @todo make sure it is the right implementation
  protected get _isOngoing(): boolean {
    return false;
  }

  // @todo make sure it is the right implementation
  public get result(): EInspectionResult {
    return EInspectionResult.PASS;
  }

  public constructor(action: Readonly<IWorkflowAction>) {
    super(action);
    this.recipients = action.recipients ?? [];
  }
}
