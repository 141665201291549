export class QpNamedMiniRange<TName> {
  public readonly name: TName;
  private readonly _min: number;
  private readonly _max: number;

  public constructor(min: Readonly<number>, max: Readonly<number>, name: Readonly<TName>) {
    this._min = min;
    this._max = max;
    this.name = name;
  }

  public contains(x: Readonly<number>): boolean {
    return x >= this._min && x <= this._max;
  }
}
