import { isString } from 'lodash/index';

/**
 * @description
 * Used to define the GTM preview
 * Based on the environments variable injection in the index file
 *
 * @param {Window} window The window object containing possibly the "googleTagManagerPreview" variable
 *
 * @returns {string} Return the GTM preview Id if it exists or null otherwise
 */
export function googleTagManagerPreviewFactory(window: Window): string | null {
  const gtmPreview = window['googleTagManagerPreview'];

  if (isString(gtmPreview)) {
    return gtmPreview;
  }

  return null;
}
