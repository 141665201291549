import { BrandDTO } from '@library/dto/brand.dto';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BrandService {
  public constructor(private readonly _httpClient: HttpClient) {}

  public getBrand$(id: number): Observable<BrandDTO> {
    return this._httpClient.get<BrandDTO>(`api/brands/${id}`);
  }

  public getBrandLogo$(id: number): Observable<Blob> {
    return this._httpClient.get(`api/brands/${id}/logo`, { responseType: 'blob' });
  }

  public getBrandReportHeaderLogo$(id: number): Observable<Blob> {
    return this._httpClient.get(`api/brands/${id}/report-header-logo`, { responseType: 'blob' });
  }
}
