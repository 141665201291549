export enum EQpFileExtension {
  TXT = '.txt',
  EML = '.eml',
  ZIP = '.zip',
  RAR = '.rar',
  MP4 = '.mp4',
  XLS = '.xls',
  XLSX = '.xlsx',
  XLSM = '.xlsm',
  CSV = '.csv',
  ODS = '.ods',
  DOC = '.doc',
  DOCX = '.docx',
  DOCM = '.docm',
  PDF = '.pdf',
  PPS = '.pps',
  AVI = '.avi',
  MOV = '.mov',
  MPG = '.mpg',
  MPEG = '.mpeg',
  WMV = '.wmv',
  TTX = '.ttx',
  LTD = '.ltd',
  TTV = '.ttv',
}
