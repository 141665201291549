// @ts-strict-ignore
import { EQpIconName } from '@library/components/qp-icon/qp-icon.models';
import { InspectionStatus } from '@library/dto-enums/inspection-status.dto-enum';
import { EQpProfile } from '@library/models/qp-profile.models';
import { IQpDisplayedRoute, QpDisplayedRoute } from '@library/models/qp-router.models';
import { BrdRoutes } from '@one/app/pages/brd/brd-routes';
import { EBrdInspectionStepTitle } from '@one/app/pages/brd/pages/inspection/shared/models/step.models';
import { EInvitationStatus } from '@one/app/pages/brd/shared/components/brd-user-invite-status/brd-user-invite-status.model';
import { IspRoutes } from '@one/app/pages/isp/isp-routes';
import { SpRoutes } from '@one/app/pages/sp/sp-routes';
import { Account } from '@one/app/shared/classes/accounts/account';
import { EntityRoutes } from '@one/app/shared/classes/routes/entity-routes';
import { EMenuPosition } from '@one/app/shared/models/menu/menu.models';
import { AccountService } from '@one/app/shared/services/account/account.service';
import { InspectorService } from '@one/app/shared/services/inspector/inspector.service';
import { GoogleTagManagerBookingInspectionService } from '@one/app/shared/services/third-party/analytics-manager/google-tag-manager/services/google-tag-manager-booking-inspection.service';
import {
  EDataLayerBookingInspectionEventAction,
  EDataLayerBookingInspectionEventCategoryType,
  EDataLayerBookingInspectionEventValue,
  IDataLayerBrdBookingInspectionEvent,
} from '@one/app/shared/services/third-party/analytics-manager/models/booking-inspection.models';
import { Injectable } from '@angular/core';
import { QimaOptionalType } from '@qima/ngx-qima';
import { intersection, isNil, sortBy } from 'lodash/index';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, mapTo, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DisplayedRoutesService {
  public static readonly networkDetailsInformations = [
    QpDisplayedRoute.create({
      title: 'one-brd-network-entity-details.information',
      routerLinkActiveOption: { exact: false },
      routerLink: 'brd/network/entity/:id/information',
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR],
      isDefaultPage: true,
    }),
  ];

  public static readonly networkWorkflowsInformations = [
    QpDisplayedRoute.create({
      title: 'one-brd-network-entity-id-workflows.tab-name',
      routerLinkActiveOption: { exact: true },
      routerLink: 'brd/network/entity/:id/workflows',
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR],
      isDefaultPage: false,
    }),
  ];

  public static readonly networkEntityIdAudit = [
    QpDisplayedRoute.create({
      title: 'one-brd-network-entity-id-audit-consult.tab-name',
      routerLinkActiveOption: { exact: false },
      routerLink: 'brd/network/entity/:id/audit/consult',
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR],
      isDefaultPage: false,
    }),
  ];

  public static readonly networkEntityIdAuthorizations = [
    QpDisplayedRoute.create({
      title: 'one-brd-network-entity-id-authorizations.tab-name',
      routerLinkActiveOption: { exact: false },
      routerLink: 'brd/network/entity/:id/authorizations',
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR],
      isDefaultPage: false,
    }),
  ];

  public static readonly productInformation = [
    QpDisplayedRoute.create({
      title: 'global.menu.entities.product.details',
      routerLinkActiveOption: { exact: true },
      routerLink: `${SpRoutes.productPoProduct()}/:id/information`,
      profiles: [EQpProfile.ROLE_SERVICE_PROVIDER],
    }),
  ];

  public static readonly copilotRoutes = [
    QpDisplayedRoute.create({
      title: 'home.navigation-menu.settings-copilot-booking',
      routerLinkActiveOption: { exact: true },
      routerLink: BrdRoutes.settingsCopilotBooking(),
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR, EQpProfile.ROLE_ENTITY],
    }),
    QpDisplayedRoute.create({
      title: 'home.navigation-menu.settings-copilot-report-decision',
      routerLinkActiveOption: { exact: true },
      routerLink: BrdRoutes.settingsCopilotReportDecision(),
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR],
    }),
  ];

  public static readonly poProductInformation = [
    QpDisplayedRoute.create({
      title: 'global.menu.entities.product.informations',
      routerLinkActiveOption: { exact: false },
      routerLink: 'brd/product-po/product/:id/information',
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR, EQpProfile.ROLE_ENTITY],
      isDefaultPage: true,
      dataCy: 'consult-products-informations-dropdown-button',
    }),
    QpDisplayedRoute.create({
      title: 'global.menu.entities.product.measurements',
      routerLinkActiveOption: { exact: true },
      routerLink: 'brd/product-po/product/:id/measurements',
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR, EQpProfile.ROLE_ENTITY],
      isDefaultPage: false,
      dataCy: 'consult-products-measurements-dropdown-button',
    }),
    QpDisplayedRoute.create({
      title: 'global.menu.entities.product.documents',
      routerLinkActiveOption: { exact: true },
      routerLink: 'brd/product-po/product/:id/documents',
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR, EQpProfile.ROLE_ENTITY],
      isDefaultPage: false,
      dataCy: 'consult-products-documents-dropdown-button',
    }),
  ];

  public static readonly poProductInformationLabTestReport = [
    QpDisplayedRoute.create({
      title: 'global.menu.entities.product.test-reports',
      routerLinkActiveOption: { exact: true },
      routerLink: 'brd/product-po/product/:id/lab-tests/reports',
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR],
      isDefaultPage: false,
      dataCy: 'consult-products-lab-test-reports-dropdown-button',
    }),
  ];

  public static readonly inspectionsDropdownValues = [
    QpDisplayedRoute.create({
      title: '',
      routerLinkActiveOption: { exact: false },
      routerLink: BrdRoutes.inspection(),
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR, EQpProfile.ROLE_ENTITY],
      isDefaultPage: true,
    }),
    QpDisplayedRoute.create({
      title: 'global.menu.entities.inspection.create',
      routerLinkActiveOption: { exact: true },
      routerLink: BrdRoutes.inspectionCreationV2(),
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR],
      isDefaultAction: true,
      dataCy: 'create-inspection-nav-dropdown-button',
    }),
    QpDisplayedRoute.create({
      title: 'global.menu.entities.inspection.consult',
      routerLinkActiveOption: { exact: false },
      routerLink: BrdRoutes.inspectionConsultation(),
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR, EQpProfile.ROLE_ENTITY],
      isDefaultPage: false,
      dataCy: 'consult-inspections-nav-dropdown-button',
    }),
    QpDisplayedRoute.create({
      title: 'global.menu.entities.inspection.consult',
      routerLinkActiveOption: { exact: false },
      routerLink: IspRoutes.inspectionConsultation(),
      profiles: [EQpProfile.ROLE_INSPECTOR, EQpProfile.ROLE_SERVICE_PROVIDER_INSPECTOR],
      isDefaultPage: true,
      dataCy: 'consult-inspections-nav-dropdown-button',
    }),
  ];

  public static readonly auditsDropdownValues = [
    QpDisplayedRoute.create({
      title: 'global.menu.entities.audit.consult',
      routerLinkActiveOption: { exact: false },
      routerLink: BrdRoutes.auditConsultation(),
      profiles: [EQpProfile.ROLE_SERVICE_PROVIDER],
      isDefaultPage: false,
      dataCy: 'consult-audits-nav-dropdown-button',
    }),
    QpDisplayedRoute.create({
      title: 'global.menu.entities.audit.create',
      routerLinkActiveOption: { exact: true },
      routerLink: BrdRoutes.auditCreation(),
      profiles: [EQpProfile.ROLE_SERVICE_PROVIDER],
      isDefaultAction: true,
      dataCy: 'create-audit-nav-dropdown-button',
    }),
  ];

  public static readonly spProductsDropdownValues = [
    QpDisplayedRoute.create({
      title: 'global.menu.entities.product.create',
      routerLinkActiveOption: { exact: false },
      routerLink: SpRoutes.productPoProductCreation(),
      profiles: [EQpProfile.ROLE_SERVICE_PROVIDER],
      isDefaultAction: true,
      dataCy: 'create-product-nav-dropdown-button',
      iconName: EQpIconName.IC_PRODUCT,
    }),
  ];

  public static readonly productsDropdownValues = [
    QpDisplayedRoute.create({
      title: 'global.menu.entities.product.create',
      routerLinkActiveOption: { exact: true },
      routerLink: BrdRoutes.productPoProductCreation(),
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR],
      isDefaultAction: true,
      dataCy: 'create-product-nav-dropdown-button',
      iconName: EQpIconName.IC_PRODUCT,
    }),
    QpDisplayedRoute.create({
      title: 'global.menu.entities.product.import',
      routerLinkActiveOption: { exact: true },
      routerLink: BrdRoutes.productPoProductImport(),
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR],
      isDefaultAction: true,
      dataCy: 'create-product-nav-dropdown-button',
      iconName: EQpIconName.IC_IMPORT,
    }),
    QpDisplayedRoute.create({
      title: 'global.menu.entities.product.consultation',
      routerLinkActiveOption: { exact: false },
      routerLink: BrdRoutes.productPoProductConsult(),
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR],
      isDefaultPage: true,
      dataCy: 'consult-products-nav-dropdown-button',
    }),
    QpDisplayedRoute.create({
      title: 'global.menu.entities.axis-variant.create',
      routerLinkActiveOption: { exact: true },
      routerLink: 'brd/product-po/axis-variant',
      profiles: [EQpProfile.ROLE_DENY_ALL],
      dataCy: 'create-axis-variant-nav-dropdown-button',
    }),
    QpDisplayedRoute.create({
      title: 'global.menu.entities.axis-value.create',
      routerLinkActiveOption: { exact: true },
      routerLink: 'brd/product-po/axis-value',
      profiles: [EQpProfile.ROLE_DENY_ALL],
      dataCy: 'create-axis-value-nav-dropdown-button',
    }),
  ];

  public static readonly factoriesDropdownValues = [
    QpDisplayedRoute.create({
      title: 'global.menu.entities.entity.consultation',
      routerLinkActiveOption: { exact: false },
      routerLink: BrdRoutes.entityConsultation(),
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR, EQpProfile.ROLE_ENTITY],
      dataCy: 'consult-factories-nav-dropdown-button',
      isDefaultPage: true,
    }),
    QpDisplayedRoute.create({
      title: 'global.menu.entities.entity.map',
      routerLinkActiveOption: { exact: false },
      routerLink: 'brd/network/entity/map',
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR],
      dataCy: 'map-factories-nav-dropdown-button',
    }),
    QpDisplayedRoute.create({
      title: 'global.menu.entities.entity.create',
      routerLinkActiveOption: { exact: true },
      routerLink: 'brd/network/entity/create',
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR],
      isDefaultAction: true,
      dataCy: 'create-entity-nav-dropdown-button',
      iconName: EQpIconName.IC_FACTORIES,
    }),
    QpDisplayedRoute.create({
      title: 'global.menu.entities.entity.import',
      routerLinkActiveOption: { exact: true },
      routerLink: BrdRoutes.entityImport(),
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR],
      isDefaultAction: true,
      dataCy: 'consult-entity-nav-dropdown-button',
      iconName: EQpIconName.IC_IMPORT,
    }),
  ];

  public static readonly clientDropdownValues = [
    QpDisplayedRoute.create({
      title: 'global.menu.entities.client.consultation',
      routerLinkActiveOption: { exact: false },
      routerLink: BrdRoutes.networkClientConsult(),
      profiles: [EQpProfile.ROLE_SERVICE_PROVIDER],
      dataCy: 'consult-clients-nav-dropdown-button',
      isDefaultPage: true,
    }),
    QpDisplayedRoute.create({
      title: 'global.menu.entities.grower.consultation',
      routerLinkActiveOption: { exact: false },
      routerLink: BrdRoutes.networkClientGrowerConsult(),
      profiles: [EQpProfile.ROLE_SERVICE_PROVIDER],
      dataCy: 'consult-clients-nav-dropdown-button',
    }),
    QpDisplayedRoute.create({
      title: 'global.menu.entities.client.create',
      routerLinkActiveOption: { exact: true },
      routerLink: 'brd/network/client/create',
      profiles: [EQpProfile.ROLE_SERVICE_PROVIDER],
      isDefaultAction: true,
      dataCy: 'create-clients-nav-dropdown-button',
      iconName: EQpIconName.IC_FACTORIES,
    }),
    QpDisplayedRoute.create({
      title: 'global.menu.entities.entity.create',
      routerLinkActiveOption: { exact: true },
      routerLink: 'brd/network/client/create-grower',
      profiles: [EQpProfile.ROLE_SERVICE_PROVIDER],
      isDefaultAction: true,
      dataCy: 'create-clients-nav-dropdown-button',
      iconName: EQpIconName.IC_FACTORIES,
    }),
  ];

  public static readonly teamDropdownValues = [
    QpDisplayedRoute.create({
      title: 'global.menu.entities.team.user.consultation',
      routerLinkActiveOption: { exact: false },
      routerLink: BrdRoutes.userConsultation(),
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR, EQpProfile.ROLE_ENTITY, EQpProfile.ROLE_SERVICE_PROVIDER],
      dataCy: 'users-team-consultation-nav-dropdown-button',
    }),
    QpDisplayedRoute.create({
      title: 'global.menu.entities.team.consultation',
      routerLinkActiveOption: { exact: false },
      routerLink: BrdRoutes.inspectorConsultation(),
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR, EQpProfile.ROLE_ENTITY, EQpProfile.ROLE_SERVICE_PROVIDER],
      dataCy: 'consult-inspectors-nav-dropdown-button',
    }),
    QpDisplayedRoute.create({
      title: 'global.menu.entities.team.invite',
      routerLinkActiveOption: { exact: true },
      routerLink: BrdRoutes.userCreation(),
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR, EQpProfile.ROLE_ENTITY, EQpProfile.ROLE_SERVICE_PROVIDER],
      isDefaultAction: true,
      dataCy: 'invite-member-nav-dropdown-button',
      iconName: EQpIconName.IC_USERNAME,
    }),
    QpDisplayedRoute.create({
      title: 'global.menu.entities.team.import',
      routerLinkActiveOption: { exact: true },
      routerLink: BrdRoutes.inspectorImport(),
      isDefaultAction: true,
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR],
      dataCy: 'import-inspectors-nav-dropdown-button',
      iconName: EQpIconName.IC_IMPORT,
    }),
  ];

  public static entitiesDropdownValues = DisplayedRoutesService.inspectionsDropdownValues.concat(
    DisplayedRoutesService.auditsDropdownValues,
    DisplayedRoutesService.productsDropdownValues,
    DisplayedRoutesService.factoriesDropdownValues,
    DisplayedRoutesService.clientDropdownValues,
    DisplayedRoutesService.teamDropdownValues
  );

  public static readonly mainMenuDropdownValues = [
    QpDisplayedRoute.create({
      title: 'home.navigation-menu.home-menu',
      routerLinkActiveOption: { exact: true },
      routerLink: '/brd/home',
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR, EQpProfile.ROLE_ENTITY],
      iconName: EQpIconName.IC_HOME,
      isDefaultPage: true,
      dataCy: 'home-nav-button',
    }),
    QpDisplayedRoute.create({
      title: 'global.menu.dashboard.team',
      routerLinkActiveOption: { exact: false },
      routerLink: BrdRoutes.userConsultation(),
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR],
      isDefaultAction: true,
      iconName: EQpIconName.IC_MYTEAM,
      isRouterLinkDisabled: true,
    }),
    QpDisplayedRoute.create({
      title: 'global.menu.dashboard.entity',
      routerLinkActiveOption: { exact: false },
      routerLink: BrdRoutes.entityConsultation(),
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR],
      isDefaultAction: true,
      iconName: EQpIconName.IC_FACTORIES,
      isRouterLinkDisabled: true,
    }),
    QpDisplayedRoute.create({
      title: 'global.menu.dashboard.purchase-order',
      routerLinkActiveOption: { exact: true },
      routerLink: BrdRoutes.purchaseOrderImport(),
      isDefaultAction: true,
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR],
      iconName: EQpIconName.IC_IMPORT,
      isRouterLinkDisabled: true,
    }),
    QpDisplayedRoute.create({
      title: 'global.menu.dashboard.workflow',
      routerLinkActiveOption: { exact: false },
      routerLink: BrdRoutes.workflowCreation(),
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR],
      isDefaultAction: true,
      dataCy: 'create-workflow-nav-dropdown-button',
      iconName: EQpIconName.IC_WORKFLOW,
      isRouterLinkDisabled: true,
    }),
    QpDisplayedRoute.create({
      title: 'global.menu.dashboard.inspection',
      routerLinkActiveOption: { exact: false },
      routerLink: BrdRoutes.inspectionCreationV2(),
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR],
      isDefaultAction: true,
      dataCy: 'create-inspection-nav-dropdown-button',
      iconName: EQpIconName.IC_ASSIGNMENTS,
      isRouterLinkDisabled: true,
    }),
  ];

  public static readonly brandReportsConsultationDropdownValues = [
    QpDisplayedRoute.create({
      title: 'one-brd-external-report-create.title-create',
      routerLinkActiveOption: { exact: true },
      routerLink: BrdRoutes.externalReportCreate(),
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR],
      isDefaultAction: true,
      dataCy: 'create-external-report-button',
    }),
  ];

  public static readonly brandReportsDropdownValues = [
    QpDisplayedRoute.create({
      title: 'home.navigation-menu.report',
      routerLinkActiveOption: { exact: false },
      routerLink: BrdRoutes.reportConsultation(),
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR],
      isDefaultPage: true,
      children: DisplayedRoutesService.brandReportsConsultationDropdownValues,
    }),
    QpDisplayedRoute.create({
      title: 'home.navigation-menu.cap',
      routerLinkActiveOption: { exact: false },
      routerLink: BrdRoutes.capReportConsultation(),
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR],
    }),
  ];

  public static readonly serviceProviderReportsDropdownValues = [
    QpDisplayedRoute.create({
      title: 'home.navigation-menu.inspections-groups',
      routerLinkActiveOption: { exact: false },
      routerLink: SpRoutes.reportGroups(),
      profiles: [EQpProfile.ROLE_SERVICE_PROVIDER],
      isDefaultPage: true,
    }),
    QpDisplayedRoute.create({
      title: 'home.navigation-menu.sample-inspected-reports',
      routerLinkActiveOption: { exact: false },
      routerLink: SpRoutes.reportsConsultation(),
      profiles: [EQpProfile.ROLE_SERVICE_PROVIDER],
    }),
  ];

  public static readonly entityReportsDropdownValues = [
    QpDisplayedRoute.create({
      title: 'home.navigation-menu.report',
      routerLinkActiveOption: { exact: false },
      routerLink: BrdRoutes.reportConsultation(),
      profiles: [EQpProfile.ROLE_ENTITY],
      isDefaultPage: true,
    }),
    QpDisplayedRoute.create({
      title: 'home.navigation-menu.cap',
      routerLinkActiveOption: { exact: false },
      routerLink: BrdRoutes.capReportConsultation(),
      profiles: [EQpProfile.ROLE_ENTITY],
    }),
  ];

  public static readonly spPurchaseOrderDropdownValues = [
    QpDisplayedRoute.create({
      title: 'global.menu.entities.purchase-order.create',
      routerLinkActiveOption: { exact: true },
      routerLink: SpRoutes.productPoPurchaseOrderCreation(),
      isDefaultAction: true,
      profiles: [EQpProfile.ROLE_SERVICE_PROVIDER],
      dataCy: 'create-purchase-order-nav-dropdown-button',
      iconName: EQpIconName.IC_COST,
    }),
  ];

  public static readonly purchaseOrderDropdownValues = [
    QpDisplayedRoute.create({
      title: 'global.menu.entities.purchase-order.create',
      routerLinkActiveOption: { exact: true },
      routerLink: BrdRoutes.productPoPurchaseOrderCreation(),
      isDefaultAction: true,
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR],
      dataCy: 'create-purchase-order-nav-dropdown-button',
      iconName: EQpIconName.IC_COST,
    }),
    QpDisplayedRoute.create({
      title: 'global.menu.entities.purchase-order.import',
      routerLinkActiveOption: { exact: true },
      routerLink: BrdRoutes.purchaseOrderImport(),
      isDefaultAction: true,
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR],
      iconName: EQpIconName.IC_IMPORT,
      isSubMenuHidden: true,
    }),
    QpDisplayedRoute.create({
      title: 'global.menu.entities.purchase-order.consult',
      routerLinkActiveOption: { exact: false },
      routerLink: BrdRoutes.productPoPurchaseOrderConsult(),
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR],
      dataCy: 'consult-purchase-order-nav-dropdown-button',
    }),
    QpDisplayedRoute.create({
      title: 'global.menu.entities.purchase-order.consult',
      routerLinkActiveOption: { exact: false },
      isDefaultPage: true,
      routerLink: BrdRoutes.productPoPurchaseOrder(),
      profiles: [EQpProfile.ROLE_ENTITY],
      dataCy: 'consult-purchase-order-nav-dropdown-button',
    }),
  ];

  public static readonly spProductPoDropdownValues = [
    QpDisplayedRoute.create({
      title: 'home.navigation-menu.product-menu',
      routerLinkActiveOption: { exact: false },
      routerLink: SpRoutes.productPoProductConsult(),
      children: DisplayedRoutesService.spProductsDropdownValues,
      profiles: [EQpProfile.ROLE_SERVICE_PROVIDER],
      dataCy: 'products-nav-button',
    }),
    QpDisplayedRoute.create({
      title: 'home.navigation-menu.po-menu',
      routerLinkActiveOption: { exact: false },
      routerLink: SpRoutes.productPoPurchaseOrderConsult(),
      children: DisplayedRoutesService.spPurchaseOrderDropdownValues,
      profiles: [EQpProfile.ROLE_SERVICE_PROVIDER],
    }),
    QpDisplayedRoute.create({
      title: '',
      routerLinkActiveOption: { exact: false },
      routerLink: SpRoutes.productPo(),
      profiles: [EQpProfile.ROLE_SERVICE_PROVIDER],
      isDefaultPage: true,
    }),
  ];

  public static readonly productsPoDropdownValues = [
    QpDisplayedRoute.create({
      title: 'home.navigation-menu.products-menu',
      routerLinkActiveOption: { exact: false },
      routerLink: BrdRoutes.productPoProduct(),
      children: DisplayedRoutesService.productsDropdownValues,
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR],
      isDefaultPage: true,
    }),
    QpDisplayedRoute.create({
      title: 'home.navigation-menu.po-menu',
      routerLinkActiveOption: { exact: false },
      routerLink: BrdRoutes.productPoPurchaseOrder(),
      children: DisplayedRoutesService.purchaseOrderDropdownValues,
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR, EQpProfile.ROLE_ENTITY],
    }),
    QpDisplayedRoute.create({
      title: '',
      routerLinkActiveOption: { exact: false },
      routerLink: BrdRoutes.productPo(),
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR, EQpProfile.ROLE_ENTITY],
    }),
  ];

  public static readonly entityProductsPoDropdownValues = [
    QpDisplayedRoute.create({
      title: 'home.navigation-menu.products-menu',
      routerLinkActiveOption: { exact: false },
      routerLink: BrdRoutes.productPoProduct(),
      children: [
        QpDisplayedRoute.create({
          title: 'global.menu.entities.product.consultation',
          routerLinkActiveOption: { exact: false },
          routerLink: BrdRoutes.productPoProductConsult(),
          profiles: [EQpProfile.ROLE_ENTITY],
          dataCy: 'consult-products-nav-dropdown-button',
          isDefaultPage: true,
        }),
        QpDisplayedRoute.create({
          title: 'global.menu.entities.purchase-order.consult',
          routerLinkActiveOption: { exact: false },
          routerLink: BrdRoutes.productPoProduct(),
          profiles: [EQpProfile.ROLE_ENTITY],
          dataCy: 'consult-purchase-order-nav-dropdown-button',
          isDefaultPage: true,
        }),
      ],
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR, EQpProfile.ROLE_ENTITY],
      isDefaultPage: true,
    }),
    QpDisplayedRoute.create({
      title: 'home.navigation-menu.po-menu',
      routerLinkActiveOption: { exact: false },
      routerLink: BrdRoutes.productPoPurchaseOrder(),
      children: [
        QpDisplayedRoute.create({
          title: 'global.menu.entities.purchase-order.consult',
          routerLinkActiveOption: { exact: false },
          routerLink: BrdRoutes.productPoPurchaseOrderConsult(),
          profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR],
          dataCy: 'consult-purchase-order-nav-dropdown-button',
        }),
        QpDisplayedRoute.create({
          title: 'global.menu.entities.purchase-order.consult',
          routerLinkActiveOption: { exact: false },
          routerLink: BrdRoutes.productPoPurchaseOrder(),
          profiles: [EQpProfile.ROLE_ENTITY],
          dataCy: 'consult-purchase-order-nav-dropdown-button',
        }),
      ],
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR, EQpProfile.ROLE_ENTITY],
    }),
    QpDisplayedRoute.create({
      title: '',
      routerLinkActiveOption: { exact: false },
      routerLink: BrdRoutes.productPo(),
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR, EQpProfile.ROLE_ENTITY],
    }),
  ];

  public static readonly accountDropdownValues = [
    QpDisplayedRoute.create({
      title: 'global.menu.account.main',
      routerLinkActiveOption: { exact: true },
      routerLink: BrdRoutes.profileConsultation(),
      profiles: [
        EQpProfile.ROLE_BRAND,
        EQpProfile.ROLE_INSPECTOR,
        EQpProfile.ROLE_SERVICE_PROVIDER_INSPECTOR,
        EQpProfile.ROLE_SUPERVISOR,
        EQpProfile.ROLE_ENTITY,
        EQpProfile.ROLE_SERVICE_PROVIDER,
      ],
      isDefaultPage: true,
      dataCy: 'profile-nav-dropdown-button',
    }),
    QpDisplayedRoute.create({
      title: 'global.menu.account.billing',
      routerLinkActiveOption: { exact: true },
      routerLink: BrdRoutes.profileBilling(),
      profiles: [EQpProfile.ROLE_ADMIN, EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR],
      isDefaultPage: true,
      dataCy: 'profile-billing-nav-dropdown-button',
    }),
  ];

  public static readonly checklistsDropdownValues = [
    QpDisplayedRoute.create({
      title: 'global.menu.entities.checklists.workflow',
      routerLinkActiveOption: { exact: false },
      routerLink: BrdRoutes.workflowConsultation(),
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR, EQpProfile.ROLE_SERVICE_PROVIDER],
      dataCy: 'consult-workflow-nav-dropdown-button',
    }),
    QpDisplayedRoute.create({
      title: 'global.menu.entities.workflows.inspection.create',
      routerLinkActiveOption: { exact: false },
      routerLink: BrdRoutes.workflowCreation(),
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR, EQpProfile.ROLE_SERVICE_PROVIDER],
      isDefaultAction: true,
      dataCy: 'create-workflow-nav-dropdown-button',
      iconName: EQpIconName.IC_WORKFLOW,
    }),
    QpDisplayedRoute.create({
      title: 'global.menu.entities.checklists.create',
      routerLinkActiveOption: { exact: true },
      routerLink: BrdRoutes.testChecklistCreation(),
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR, EQpProfile.ROLE_SERVICE_PROVIDER],
      isDefaultAction: true,
      dataCy: 'create-checklist-nav-dropdown-button',
      iconName: EQpIconName.IC_TEST,
    }),
    QpDisplayedRoute.create({
      title: 'global.menu.entities.checklists.consult',
      routerLinkActiveOption: { exact: false },
      routerLink: BrdRoutes.checklistsConsultation(),
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR, EQpProfile.ROLE_SERVICE_PROVIDER],
      dataCy: 'consult-checklists-nav-dropdown-button',
    }),
    QpDisplayedRoute.create({
      title: 'global.menu.entities.checklists.defects.create',
      routerLinkActiveOption: { exact: true },
      routerLink: BrdRoutes.defectChecklistCreation(),
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR, EQpProfile.ROLE_SERVICE_PROVIDER],
      isDefaultAction: true,
      dataCy: 'create-defects-nav-dropdown-button',
      iconName: EQpIconName.IC_DEFECT,
    }),
    QpDisplayedRoute.create({
      title: 'global.menu.entities.checklists.import',
      routerLinkActiveOption: { exact: true },
      routerLink: BrdRoutes.testChecklistImport(),
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR],
      isDefaultAction: true,
      dataCy: 'consult-checklists-nav-dropdown-button',
      iconName: EQpIconName.IC_IMPORT,
    }),
    QpDisplayedRoute.create({
      title: 'global.menu.entities.checklists.defects.import',
      routerLinkActiveOption: { exact: true },
      routerLink: BrdRoutes.defectChecklistImport(),
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR],
      isDefaultAction: true,
      dataCy: 'consult-checklists-nav-dropdown-button',
      iconName: EQpIconName.IC_IMPORT,
    }),
    QpDisplayedRoute.create({
      title: '',
      routerLinkActiveOption: { exact: false },
      routerLink: BrdRoutes.workflow(),
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR, EQpProfile.ROLE_SERVICE_PROVIDER],
      isDefaultPage: true,
    }),
    QpDisplayedRoute.create({
      title: 'global.menu.entities.checklists.workflow',
      routerLinkActiveOption: { exact: false },
      routerLink: EntityRoutes.workflowConsult(),
      profiles: [EQpProfile.ROLE_ENTITY],
      dataCy: 'consult-workflow-nav-dropdown-button',
    }),
    QpDisplayedRoute.create({
      title: '',
      routerLinkActiveOption: { exact: false },
      routerLink: EntityRoutes.workflow(),
      profiles: [EQpProfile.ROLE_ENTITY],
      isDefaultPage: true,
    }),
    QpDisplayedRoute.create({
      title: 'global.menu.entities.checklists.consult',
      routerLinkActiveOption: { exact: false },
      routerLink: EntityRoutes.workflowChecklistConsult(),
      profiles: [EQpProfile.ROLE_ENTITY],
      dataCy: 'consult-checklists-nav-dropdown-button',
    }),
  ];

  private static readonly _settingsValues = [
    QpDisplayedRoute.create({
      title: 'home.navigation-menu.settings-menu',
      routerLinkActiveOption: { exact: false },
      routerLink: BrdRoutes.settings(),
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR, EQpProfile.ROLE_ENTITY, EQpProfile.ROLE_SERVICE_PROVIDER],
      iconName: EQpIconName.IC_SETTINGS,
    }),
  ];

  private static readonly _middleMenuValues = [
    QpDisplayedRoute.create({
      title: 'home.navigation-menu.settings-menu',
      routerLinkActiveOption: { exact: false },
      routerLink: BrdRoutes.settings(),
      children: DisplayedRoutesService._settingsValues,
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR, EQpProfile.ROLE_ENTITY, EQpProfile.ROLE_SERVICE_PROVIDER],
      isSubMenuHidden: true,
      iconName: EQpIconName.IC_SETTINGS,
    }),
  ];

  public static getRoutesForNetworkEntity(routeTitleKey: string, entityId: number, invitationStatus: EInvitationStatus): IQpDisplayedRoute {
    const routes = QpDisplayedRoute.create({
      title: routeTitleKey,
      routerLinkActiveOption: { exact: false },
      routerLink: '/brd/network/entity',
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR],
      children: this._getNetworkEntityRouteChildren(invitationStatus),
    });

    routes.children = routes.children.map((route: IQpDisplayedRoute): IQpDisplayedRoute => {
      return {
        ...route,
        routerLink: route.routerLink.replace(':id', `${entityId}`),
      };
    });

    return routes;
  }

  private static _getNetworkEntityRouteChildren(invitationStatus: EInvitationStatus): IQpDisplayedRoute[] {
    return isNil(invitationStatus)
      ? DisplayedRoutesService.networkDetailsInformations.concat(DisplayedRoutesService.networkEntityIdAudit)
      : DisplayedRoutesService.networkDetailsInformations
          .concat(DisplayedRoutesService.networkWorkflowsInformations)
          .concat(DisplayedRoutesService.networkEntityIdAudit)
          .concat(DisplayedRoutesService.networkEntityIdAuthorizations);
  }

  public menuPosition$ = new BehaviorSubject<EMenuPosition>(EMenuPosition.COLLAPSE);
  public isSubmenuActive$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly scheduledCounter$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public readonly changeRequestsCounter$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  public readonly inspectionConsultation = [
    QpDisplayedRoute.create({
      title: 'global.menu.entities.inspection.planning',
      routerLinkActiveOption: { exact: false },
      routerLink: IspRoutes.inspectionConsultationPlanning(),
      isDefaultPage: true,
      isCounterDisplayed: true,
      profiles: [EQpProfile.ROLE_INSPECTOR, EQpProfile.ROLE_SERVICE_PROVIDER_INSPECTOR],
      counter$: this.scheduledCounter$,
      subNavButtonClickCallback: this.setInspectorInspectionsStatistics$.bind(this),
    }),
    QpDisplayedRoute.create({
      title: 'global.menu.entities.inspection.to-modify',
      routerLinkActiveOption: { exact: false },
      routerLink: IspRoutes.inspectionConsultationToModify(),
      isCounterDisplayed: true,
      profiles: [EQpProfile.ROLE_INSPECTOR, EQpProfile.ROLE_SERVICE_PROVIDER_INSPECTOR],
      counter$: this.changeRequestsCounter$,
      subNavButtonClickCallback: this.setInspectorInspectionsStatistics$.bind(this),
    }),
    QpDisplayedRoute.create({
      title: 'global.menu.entities.inspection.completed',
      routerLinkActiveOption: { exact: false },
      routerLink: IspRoutes.inspectionConsultationCompleted(),
      profiles: [EQpProfile.ROLE_INSPECTOR, EQpProfile.ROLE_SERVICE_PROVIDER_INSPECTOR],
    }),
    QpDisplayedRoute.create({
      routerLinkActiveOption: { exact: false },
      routerLink: IspRoutes.inspectionConsultationErrorForbidden(),
      profiles: [EQpProfile.ROLE_INSPECTOR, EQpProfile.ROLE_SERVICE_PROVIDER_INSPECTOR],
    }),
  ];

  public readonly mainMenuValues = [
    QpDisplayedRoute.create({
      title: 'home.navigation-menu.home-menu',
      routerLinkActiveOption: { exact: true },
      routerLink: '/isp/home',
      profiles: [EQpProfile.ROLE_INSPECTOR, EQpProfile.ROLE_SERVICE_PROVIDER_INSPECTOR],
      iconName: EQpIconName.IC_HOME,
      dataCy: 'home-nav-button',
    }),
    QpDisplayedRoute.create({
      title: 'home.navigation-menu.home-menu',
      routerLinkActiveOption: { exact: true },
      routerLink: '/brd/home',
      isDefaultPage: true,
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR, EQpProfile.ROLE_ENTITY],
      iconName: EQpIconName.IC_HOME,
      children: DisplayedRoutesService.mainMenuDropdownValues,
      dataCy: 'home-nav-button',
      creationButtonLabel: 'common.actions.creationButton.dashboard',
      isSubMenuHidden: true,
    }),
    QpDisplayedRoute.create({
      title: 'home.navigation-menu.inspections-menu',
      routerLinkActiveOption: { exact: false },
      routerLink: '/brd/inspection',
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR, EQpProfile.ROLE_ENTITY],
      children: DisplayedRoutesService.inspectionsDropdownValues,
      iconName: EQpIconName.IC_DATE,
      dataCy: 'inspections-nav-button',
      creationButtonLabel: 'common.actions.creationButton.inspection',
      creationButtonClickCallback: (): void => this._addGoogleTagManagerEvent(),
    }),
    QpDisplayedRoute.create({
      title: 'home.navigation-menu.inspections-menu',
      routerLinkActiveOption: { exact: false },
      routerLink: IspRoutes.inspectionConsultation(),
      children: this.inspectionConsultation,
      profiles: [EQpProfile.ROLE_INSPECTOR, EQpProfile.ROLE_SERVICE_PROVIDER_INSPECTOR],
      iconName: EQpIconName.IC_DATE,
      dataCy: 'inspections-nav-button-toto',
    }),
    QpDisplayedRoute.create({
      title: 'home.navigation-menu.settings-menu',
      routerLinkActiveOption: { exact: true },
      routerLink: '/isp/settings',
      profiles: [EQpProfile.ROLE_SERVICE_PROVIDER_INSPECTOR],
      isSubMenuHidden: true,
      iconName: EQpIconName.IC_SETTINGS,
      dataCy: 'settings-nav-button',
    }),
    QpDisplayedRoute.create({
      title: 'home.navigation-menu.report',
      routerLinkActiveOption: { exact: false },
      routerLink: BrdRoutes.report(),
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR],
      iconName: EQpIconName.IC_REPORT,
      children: DisplayedRoutesService.brandReportsDropdownValues,
      creationButtonLabel: 'common.actions.creationButton.external-report',
    }),
    QpDisplayedRoute.create({
      title: 'home.navigation-menu.report',
      routerLinkActiveOption: { exact: false },
      routerLink: SpRoutes.report(),
      profiles: [EQpProfile.ROLE_SERVICE_PROVIDER],
      iconName: EQpIconName.IC_REPORT,
      children: DisplayedRoutesService.serviceProviderReportsDropdownValues,
    }),
    QpDisplayedRoute.create({
      title: 'home.navigation-menu.report',
      routerLinkActiveOption: { exact: false },
      routerLink: BrdRoutes.report(),
      profiles: [EQpProfile.ROLE_ENTITY],
      iconName: EQpIconName.IC_REPORT,
      children: DisplayedRoutesService.entityReportsDropdownValues,
      creationButtonLabel: 'common.actions.creationButton.external-report',
    }),
    QpDisplayedRoute.create({
      title: 'home.navigation-menu.analytics',
      routerLinkActiveOption: { exact: false },
      routerLink: BrdRoutes.analytics(),
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR, EQpProfile.ROLE_ENTITY],
      iconName: EQpIconName.IC_DASHBOARD,
    }),
    QpDisplayedRoute.create({
      title: 'home.navigation-menu.product-po-menu',
      routerLinkActiveOption: { exact: false },
      routerLink: '/brd/product-po',
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR],
      children: DisplayedRoutesService.productsPoDropdownValues,
      iconName: EQpIconName.IC_PRODUCT,
      creationTitle: 'home.creation-titles.products-and-po',
      dataCy: 'products-nav-button',
      creationButtonLabel: 'common.actions.creationButton.product',
    }),
    QpDisplayedRoute.create({
      title: 'home.navigation-menu.product-po-menu',
      routerLinkActiveOption: { exact: false },
      routerLink: SpRoutes.productPo(),
      profiles: [EQpProfile.ROLE_SERVICE_PROVIDER],
      children: DisplayedRoutesService.spProductPoDropdownValues,
      iconName: EQpIconName.IC_PRODUCT,
      creationTitle: 'home.creation-titles.products-and-po',
      dataCy: 'products-nav-button',
      creationButtonLabel: 'common.actions.creationButton.product',
    }),
    QpDisplayedRoute.create({
      title: 'home.navigation-menu.product-po-menu',
      routerLinkActiveOption: { exact: false },
      routerLink: '/brd/product-po',
      profiles: [EQpProfile.ROLE_ENTITY],
      children: DisplayedRoutesService.entityProductsPoDropdownValues,
      iconName: EQpIconName.IC_PRODUCT,
      creationTitle: 'home.creation-titles.products-and-po',
      dataCy: 'products-nav-button',
      creationButtonLabel: 'common.actions.creationButton.product',
    }),
    QpDisplayedRoute.create({
      title: 'global.menu.account.account',
      routerLinkActiveOption: { exact: false },
      routerLink: '/account',
      profiles: [
        EQpProfile.ROLE_BRAND,
        EQpProfile.ROLE_SUPERVISOR,
        EQpProfile.ROLE_INSPECTOR,
        EQpProfile.ROLE_SERVICE_PROVIDER_INSPECTOR,
        EQpProfile.ROLE_ENTITY,
        EQpProfile.ROLE_SERVICE_PROVIDER,
      ],
      children: DisplayedRoutesService.accountDropdownValues,
      iconName: EQpIconName.IC_USERNAME,
    }),
    QpDisplayedRoute.create({
      title: 'home.navigation-menu.workflows-menu',
      routerLinkActiveOption: { exact: false },
      routerLink: BrdRoutes.workflows(),
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR, EQpProfile.ROLE_SERVICE_PROVIDER],
      children: DisplayedRoutesService.checklistsDropdownValues,
      iconName: EQpIconName.IC_WORKFLOW,
      dataCy: 'checklists-nav-button',
      creationTitle: 'home.creation-titles.checklists-and-workflows',
      creationButtonLabel: 'common.actions.creationButton.checklist',
    }),
    QpDisplayedRoute.create({
      title: 'home.navigation-menu.workflows-menu',
      routerLinkActiveOption: { exact: false },
      routerLink: EntityRoutes.workflow(),
      profiles: [EQpProfile.ROLE_ENTITY],
      children: DisplayedRoutesService.checklistsDropdownValues,
      iconName: EQpIconName.IC_WORKFLOW,
      dataCy: 'checklists-nav-button',
      creationTitle: 'home.creation-titles.checklists-and-workflows',
      creationButtonLabel: 'common.actions.creationButton.checklist',
    }),
    QpDisplayedRoute.create({
      title: 'home.navigation-menu.entities-menu',
      routerLinkActiveOption: { exact: false },
      routerLink: '/brd/network',
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR, EQpProfile.ROLE_ENTITY],
      children: DisplayedRoutesService.factoriesDropdownValues,
      iconName: EQpIconName.IC_NETWORK,
      dataCy: 'factories-nav-button',
      creationButtonLabel: 'common.actions.creationButton.entity',
    }),
    QpDisplayedRoute.create({
      title: 'home.navigation-menu.entities-menu',
      routerLinkActiveOption: { exact: false },
      routerLink: '/brd/network',
      profiles: [EQpProfile.ROLE_SERVICE_PROVIDER],
      children: DisplayedRoutesService.clientDropdownValues,
      iconName: EQpIconName.IC_NETWORK,
      dataCy: 'clients-nav-button',
      creationButtonLabel: 'common.actions.creationButton.entity',
    }),
    QpDisplayedRoute.create({
      title: 'home.navigation-menu.team-menu',
      routerLinkActiveOption: { exact: false },
      routerLink: '/brd/team',
      profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR, EQpProfile.ROLE_ENTITY],
      children: DisplayedRoutesService.teamDropdownValues,
      iconName: EQpIconName.IC_MYTEAM,
      creationTitle: 'home.creation-titles.team-members',
      creationSubtitle: 'home.creation-subtitles.how-to-invite',
      creationButtonLabel: 'common.actions.creationButton.member',
      dataCy: 'team-nav-button',
    }),
    QpDisplayedRoute.create({
      title: 'home.navigation-menu.team-menu',
      routerLinkActiveOption: { exact: false },
      routerLink: '/brd/team',
      profiles: [EQpProfile.ROLE_SERVICE_PROVIDER],
      children: DisplayedRoutesService.teamDropdownValues,
      iconName: EQpIconName.IC_MYTEAM,
      creationTitle: 'home.creation-titles.team-members',
      creationSubtitle: 'home.creation-subtitles.how-to-invite',
      creationButtonLabel: 'common.actions.creationButton.invite-member',
      dataCy: 'team-nav-button',
    }),
  ];

  public constructor(
    private readonly _inspectorService: InspectorService,
    private readonly _accountService: AccountService,
    private readonly _googleTagManagerBookingInspectionService: GoogleTagManagerBookingInspectionService
  ) {}

  public getRoutesForEntities(): Observable<IQpDisplayedRoute[]> {
    return this._accountService.getAccount$().pipe(
      map((account: QimaOptionalType<Account>): IQpDisplayedRoute[] => {
        if (account) {
          return DisplayedRoutesService.entitiesDropdownValues.filter(
            (route: QpDisplayedRoute): boolean => intersection(account.profiles, route.profiles).length > 0
          );
        }

        return [];
      })
    );
  }

  public getRoutes(): Observable<IQpDisplayedRoute[]> {
    return this._accountService.getAccount$().pipe(
      map((account: QimaOptionalType<Account>): IQpDisplayedRoute[] => {
        return this._filterRoutesByAuthorities(this.mainMenuValues, account);
      })
    );
  }

  public getMiddleRoutes(): Observable<IQpDisplayedRoute[]> {
    return this._accountService.getAccount$().pipe(
      map((account: QimaOptionalType<Account>): IQpDisplayedRoute[] => {
        if (account) {
          return this._filterRoutesByAuthorities(DisplayedRoutesService._middleMenuValues, account);
        }

        return [];
      })
    );
  }

  public setDefaultPageSettings(mainMenu: IQpDisplayedRoute): IQpDisplayedRoute {
    if (mainMenu.children && mainMenu.children.length > 0) {
      const defaultSubMenu = mainMenu.children.find((subMenu: IQpDisplayedRoute): boolean => subMenu.isDefaultPage);

      if (defaultSubMenu) {
        mainMenu.defaultPageRoute = QpDisplayedRoute.create({
          title: defaultSubMenu.title,
          routerLinkActiveOption: defaultSubMenu.routerLinkActiveOption,
          routerLink: defaultSubMenu.routerLink,
          profiles: defaultSubMenu.profiles,
          iconName: defaultSubMenu.iconName,
          dataCy: defaultSubMenu.dataCy,
        });
      } else {
        mainMenu.defaultPageRoute = QpDisplayedRoute.create({
          title: mainMenu.children[0].title,
          routerLinkActiveOption: mainMenu.children[0].routerLinkActiveOption,
          routerLink: mainMenu.children[0].routerLink,
          profiles: mainMenu.children[0].profiles,
          iconName: mainMenu.children[0].iconName,
          dataCy: mainMenu.dataCy,
        });
      }
    }

    return mainMenu;
  }

  public setDefaultActionSettings(mainMenu: IQpDisplayedRoute): IQpDisplayedRoute {
    if (mainMenu.children && mainMenu.children.length > 0) {
      const defaultSubMenus = mainMenu.children.filter((subMenu: IQpDisplayedRoute): boolean => subMenu.isDefaultAction);

      if (defaultSubMenus) {
        mainMenu.defaultActionRoutes = defaultSubMenus.map(
          (defaultSubMenu: IQpDisplayedRoute): IQpDisplayedRoute =>
            QpDisplayedRoute.create({
              title: defaultSubMenu.title,
              routerLinkActiveOption: defaultSubMenu.routerLinkActiveOption,
              routerLink: defaultSubMenu.routerLink,
              profiles: defaultSubMenu.profiles,
              iconName: defaultSubMenu.iconName,
              dataCy: defaultSubMenu.dataCy,
            })
        );
      }
    }

    return mainMenu;
  }

  public sortMenu(routes: IQpDisplayedRoute[]): IQpDisplayedRoute[] {
    return sortBy(routes, (route: IQpDisplayedRoute): boolean => route.isSecondaryMenu ?? false);
  }

  public setInspectorInspectionsStatistics$(): Observable<void> {
    return this._inspectorService
      .getInspectorInspectionsStatistics$()
      .pipe(
        tap((inspectorInspectionsStatistics): void => {
          this.scheduledCounter$.next(
            this._processInspectorStatisticts(
              [InspectionStatus.PENDING, InspectionStatus.ACCEPTED, InspectionStatus.STARTED],
              inspectorInspectionsStatistics.inspectionsByStatuses
            )
          );
        }),
        tap((inspectorInspectionsStatistics): void => {
          this.changeRequestsCounter$.next(
            this._processInspectorStatisticts(
              [InspectionStatus.REPORT_REVISION_REQUESTED, InspectionStatus.REPORT_IN_REVISION],
              inspectorInspectionsStatistics.inspectionsByStatuses
            )
          );
        })
      )
      .pipe(mapTo(null));
  }

  private _processInspectorStatisticts(
    statuses: InspectionStatus[],
    inspectorStatistics: Partial<Record<InspectionStatus, number>>
  ): number {
    return statuses.reduce(
      (count: Readonly<number>, inspectionStatus: InspectionStatus): number =>
        inspectorStatistics?.[inspectionStatus] ? count + +inspectorStatistics[inspectionStatus] : count,
      0
    );
  }

  private _filterRoutesByAuthorities(routes: IQpDisplayedRoute[], account: QimaOptionalType<Account>): IQpDisplayedRoute[] {
    let mainMenuList = routes.map((mainMenu: IQpDisplayedRoute): IQpDisplayedRoute => {
      let currentMainMenu: IQpDisplayedRoute = Object.assign({}, mainMenu);

      if (currentMainMenu.children) {
        currentMainMenu.children = currentMainMenu.children.filter(
          (route: IQpDisplayedRoute): boolean => intersection(account?.profiles, route.profiles).length > 0
        );
      }

      currentMainMenu = this.setDefaultPageSettings(currentMainMenu);

      return this.setDefaultActionSettings(currentMainMenu);
    });

    mainMenuList = mainMenuList.filter((route: IQpDisplayedRoute): boolean => intersection(account?.profiles, route.profiles).length > 0);

    return mainMenuList;
  }

  private _addGoogleTagManagerEvent(): void {
    const event: IDataLayerBrdBookingInspectionEvent = {
      category: EDataLayerBookingInspectionEventCategoryType.BRD_SCHEDULE_INSPECTION,
      action: EDataLayerBookingInspectionEventAction.ENTER,
      label: EBrdInspectionStepTitle.PRODUCT_TAB,
      value: EDataLayerBookingInspectionEventValue.FROM_BUTTON,
    };

    this._googleTagManagerBookingInspectionService.sendBookingGoogleTagManagerEvent(event);
  }
}
