import { isNil } from 'lodash/index';

/**
 * @description Compare two rating systems
 * @param {{ name: string; color: string }[]} ratingSystem1 the rating system #1
 * @param {{ name: string; color: string }[]} ratingSystem2 the rating system #2
 * @returns {boolean} true if the two rating system groups are equals
 */
export function qpAreRatingSystemsEqual(
  ratingSystem1: ReadonlyArray<{ name: string; color: string }>,
  ratingSystem2: ReadonlyArray<{ name: string; color: string }>
): boolean {
  if (ratingSystem1.length !== ratingSystem2.length) {
    return false;
  }

  return ratingSystem1.every((ratingSystem1Item, index: number): boolean => {
    const ratingSystem2Item = ratingSystem2[index];

    if (isNil(ratingSystem2Item)) {
      return false;
    }

    return ratingSystem1Item.color === ratingSystem2Item.color && ratingSystem1Item.name === ratingSystem2Item.name;
  });
}
