// @ts-strict-ignore
import { IQpBrandName, IQpBrandWithAddress } from '@library/models/qp-brand.models';
import { EQpProfile } from '@library/models/qp-profile.models';
import { QpLocaleService } from '@library/services/qp-locale/qp-locale.service';
import { SERVER_API_URL } from '@one/app/app.constants';
import { EditAccount } from '@one/app/pages/account/pages/profile/pages/edit/account-profile-edit.models';
import { IspBarcodeScannerService } from '@one/app/pages/isp/shared/services/isp-barcode-scanner.service';
import { Account } from '@one/app/shared/classes/accounts/account';
import { IAccount } from '@one/app/shared/models/account/account.models';
import { Permission } from '@one/app/shared/models/permission/permission.models';
import { Role } from '@one/app/shared/models/role/role.models';
import { AuthenticationService } from '@one/app/shared/services/auth/authentication.service';
import { OfflineStoreService } from '@one/app/shared/services/store/offline-store.service';
import { GoogleTagManagerService } from '@one/app/shared/services/third-party/analytics-manager/google-tag-manager/services/google-tag-manager.service';
import { AnalyticsManagerEventType } from '@one/app/shared/services/third-party/analytics-manager/models/analytics-manager.models';
import {
  EDataLayerEventAction,
  EDataLayerEventValue,
  EDataLayerType,
  EGTMTitle,
} from '@one/app/shared/services/third-party/analytics-manager/models/login.models';
import { TrackingService } from '@one/app/shared/services/third-party/analytics-manager/tracking/tracking.service';
import { LogrocketInitService } from '@one/app/shared/services/third-party/logrocket-init/logrocket-init.service';
import packageJson from '@packageJson';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { QimaOptionalType } from '@qima/ngx-qima';
import { isNil } from 'lodash/index';
import LogRocket from 'logrocket';
import { SessionStorageService } from 'ngx-webstorage';
import { EMPTY, Observable, of, ReplaySubject } from 'rxjs';
import { delayWhen, filter, first, map, switchMap, take, tap } from 'rxjs/operators';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class AccountService {
  private _account: ReplaySubject<QimaOptionalType<IAccount>> = new ReplaySubject(1);
  private _synchronousAccount: QimaOptionalType<IAccount>;
  private _isIdentityRequested = false;
  private _isAuthenticated = false;

  public constructor(
    private readonly _qpLocaleService: QpLocaleService,
    private readonly _sessionStorageService: SessionStorageService,
    private readonly _offlineStoreService: OfflineStoreService,
    private readonly _httpClient: HttpClient,
    private readonly _authServerProvider: AuthenticationService,
    private readonly _ispBarcodeScannerService: IspBarcodeScannerService,
    private readonly _googleTagManagerService: GoogleTagManagerService,
    private readonly _trackingService: TrackingService,
    private readonly _logrocketInitService: LogrocketInitService
  ) {}

  public save$(account: EditAccount, picture?: File): Observable<HttpResponse<void>> {
    const formData = new FormData();

    formData.append('user', new Blob([JSON.stringify(account)], { type: 'application/json' }));

    if (picture) {
      formData.append('photo', picture);
    }

    return this._httpClient.put<void>(`${SERVER_API_URL}api/account`, formData, { observe: 'response' }).pipe(
      tap((response): void => {
        // when update success update local data
        if (this._synchronousAccount && response.status >= 200 && response.status < 300) {
          this._updateLocalUserIdentity(account);
        }
      })
    );
  }

  public hasAnyAuthority(profiles: EQpProfile[]): boolean {
    if (!this._isAuthenticated || !this._synchronousAccount?.profiles) {
      return false;
    }

    for (let i = 0; i < profiles.length; i++) {
      // we had a !this.getEntityId() because if you're an entity the function return false
      // when the SUPERVISOR profile is before FACTORY in the permission array
      if (profiles[i] === EQpProfile.ROLE_SUPERVISOR && !this.getBrandId() && !this.getEntityId()) {
        return false;
      }

      if (this._synchronousAccount?.profiles.includes(profiles[i])) {
        return true;
      }
    }

    return false;
  }

  public hasAuthority(authority: Readonly<EQpProfile>): Promise<boolean> {
    if (!this.isAuthenticated()) {
      return Promise.resolve(false);
    }

    return this.identity().then(
      (account: QimaOptionalType<Account>): Promise<boolean> => Promise.resolve(account.profiles?.includes(authority)),
      (): Promise<false> => Promise.resolve(false)
    );
  }

  /* Returns the user account data, fetch it only the first time */
  public identity(): Promise<QimaOptionalType<IAccount>> {
    return this._identity();
  }

  /* Fetches and returns the user account data */
  public forceIdentity(): Promise<QimaOptionalType<IAccount>> {
    return this._identity(true);
  }

  public getCurrentLogin(): string {
    return this._synchronousAccount?.email;
  }

  public getUserName(): string {
    return `${this._synchronousAccount?.firstName} ${this._synchronousAccount?.lastName}`;
  }

  public getCompanyName(): string | undefined {
    return this._synchronousAccount?.companyName;
  }

  public getClientName(): string | undefined {
    return this._synchronousAccount?.clientName;
  }

  public isAuthenticated(): boolean {
    return this._isAuthenticated;
  }

  public getAccount$(): Observable<QimaOptionalType<IAccount>> {
    return this._account.asObservable();
  }

  public getProfilePicture$(): Observable<Blob> {
    return this._httpClient.get(`${SERVER_API_URL}/api/users/${this.getUserId()}/profile-picture`, {
      observe: 'body',
      responseType: 'blob',
    });
  }

  public getUserId(): QimaOptionalType<number> {
    return this._synchronousAccount?.id;
  }

  public getBrandId(): QimaOptionalType<string> {
    return this._synchronousAccount?.brandId;
  }

  public getEntityId(): QimaOptionalType<string> {
    return this._synchronousAccount?.factoryId;
  }

  public getPermissions(): QimaOptionalType<Permission[]> {
    return this._synchronousAccount?.permissions;
  }

  public getBrand$(): Observable<IQpBrandWithAddress> {
    if (!this.getBrandId()) {
      return EMPTY;
    }

    return this._httpClient.get<IQpBrandWithAddress>(`api/brands/${this.getBrandId()}`);
  }

  public getBrandName$(): Observable<IQpBrandName> {
    if (!this.getBrandId()) {
      return of({ name: '' });
    }

    return this._httpClient.get<IQpBrandName>(`api/brands/${this.getBrandId()}`);
  }

  public isInspector(): boolean {
    return (
      this.isAuthenticated() &&
      [EQpProfile.ROLE_INSPECTOR, EQpProfile.ROLE_SERVICE_PROVIDER_INSPECTOR].filter((profile): boolean =>
        this._synchronousAccount?.profiles.includes(profile)
      ).length > 0
    );
  }

  public isEntity(): boolean {
    return this.hasAnyAuthority([EQpProfile.ROLE_ENTITY]);
  }

  public hasAuthority$(profile: EQpProfile): Observable<boolean> {
    return this._account.pipe(switchMap((): Promise<boolean> => this.hasAuthority(profile)));
  }

  public hasAnyAuthority$(profiles: EQpProfile[]): Observable<boolean> {
    return this._account.pipe(
      switchMap((): Promise<boolean[]> => Promise.all(profiles.map((profile): Promise<boolean> => this.hasAuthority(profile)))),
      map((results: boolean[]): boolean => results.some((result): boolean => result))
    );
  }

  public hasPermission(permission: Permission): boolean {
    return this.getPermissions()?.includes(permission) ?? false;
  }

  public hasSelfStartingRights$(): Observable<boolean> {
    return this.getAccount$().pipe(map((account): boolean => account?.role === Role.SELF_STARTING || account?.role === Role.FULL_ACCESS));
  }

  public getNotifuseUserId(): QimaOptionalType<string> {
    return this._synchronousAccount?.notifuseUserId;
  }

  public getNotifuseUserHash(): QimaOptionalType<string> {
    return this._synchronousAccount?.notifuseUserHash;
  }

  public accountLangKey$(): Observable<string> {
    return this._account.pipe(map((account): string => this._sessionStorageService.retrieve('locale') || account?.langKey));
  }

  public unsetAccount(): void {
    this._account = new ReplaySubject(1);
    this._synchronousAccount = null;
    this._isIdentityRequested = false;
    this._isAuthenticated = false;
  }

  private async _identity(shouldForce: Readonly<boolean> = false): Promise<QimaOptionalType<IAccount>> {
    const isAuthenticated = await this._authServerProvider.isAuthenticated();

    if (!isAuthenticated) {
      return Promise.resolve(null);
    }

    if (!this._isIdentityRequested || shouldForce) {
      this._isIdentityRequested = true;

      return this._fetchAccountData$().toPromise();
    }

    const account = await this._account.pipe(first()).toPromise();

    return account;
  }

  private _fetchAccountData$(): Observable<Account> {
    return this._fetch$().pipe(
      take(1),
      map((response: IAccount): Account => Account.create(response)),
      tap((account: QimaOptionalType<Account>): void => {
        this._isAuthenticated = !isNil(account);
        this._synchronousAccount = account;
        this._account.next(account);
      }),
      filter((account: QimaOptionalType<Account>): boolean => !isNil(account)),
      delayWhen((account: Account): Observable<true> => {
        const langKey = this._sessionStorageService.retrieve('locale') || account.langKey;

        return this._qpLocaleService.setCurrentLocale$(langKey);
      }),
      tap((account: Account): void => void this._initThirdPartyServices(account))
    );
  }

  private async _initThirdPartyServices(account: IAccount): Promise<void> {
    const isInspector = account.profiles.some((profile: EQpProfile): boolean =>
      [EQpProfile.ROLE_INSPECTOR, EQpProfile.ROLE_SERVICE_PROVIDER_INSPECTOR].includes(profile)
    );
    const event: AnalyticsManagerEventType = {
      category: EDataLayerType.LOGIN,
      action: EDataLayerEventAction.LOGIN,
      label: EGTMTitle.LOGIN,
      event: 'login',
      value: EDataLayerEventValue.USER_DATA,
      brandName: account.companyName,
      position: account.position,
      profiles: account.profiles,
    };

    this._googleTagManagerService.sendEvent(event);
    this._trackingService.identify(account?.id?.toString(), {
      appVersion: packageJson.version,
      brandId: account.brandId,
      brandName: account.companyName,
      accountProfiles: account.profiles,
    });

    this._initLogRocketIfApplicable(account);

    if (isInspector) {
      await this._ispBarcodeScannerService.getBarcodeScannerLicense();
    }
  }

  private _fetch$(): Observable<IAccount> {
    return this._offlineStoreService.offlineStore(this._httpClient.get<IAccount>(`${SERVER_API_URL}api/account`), {
      storageKey: 'account',
      dependsOnArgs: false,
      args: [],
      login: null,
    });
  }

  private _updateLocalUserIdentity(account: Readonly<EditAccount>): void {
    this._account.next(account);
    this._synchronousAccount = account;
  }

  private _initLogRocketIfApplicable(account: IAccount): void {
    const isCompanyShouldBeRecorded = this._isCompanyShouldBeRecorded(this.getCompanyName(), this.getClientName());
    const isInspector = this.isInspector();

    if (isInspector || isCompanyShouldBeRecorded) {
      this._logrocketInitService.init();

      LogRocket.identify(account.id.toString(), {
        brandId: account.brandId,
        brandName: account.companyName,
        accountProfiles: account.profiles?.join(','),
      });
      LogRocket.track('application', { version: packageJson.version });
    }
  }

  private _isCompanyShouldBeRecorded(companyName: string = '', clientName: string = ''): boolean {
    const recordedCompaniesPrefixes = ['qima', 'pura', 'kik'];

    return recordedCompaniesPrefixes.some(
      (searchString: string): boolean =>
        companyName.toLowerCase().startsWith(searchString.toLocaleLowerCase()) ||
        clientName.toLowerCase().startsWith(searchString.toLocaleLowerCase())
    );
  }
}
