import { IQuickSearchDTO } from '@library/dto/quick-search.dto';
import { SERVER_API_URL } from '@one/app/app.constants';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class QuickSearchService {
  public constructor(private readonly _http: HttpClient) {}

  public getQuickSearchResults$(searchTerm: string): Observable<IQuickSearchDTO[]> {
    return this._http.get<IQuickSearchDTO[]>(`${SERVER_API_URL}api/quick-search`, {
      params: { query: searchTerm },
    });
  }
}
