import { ChecklistRatingSystemDTO } from '@library/dto/checklist/checklist-rating-system.dto';
import { DefectChecklistSumRatingConfigDTO } from '@library/dto/checklist/defect/defect-checklist-sum-rating-config.dto';
import { DefectCompletionDTO } from '@library/dto/defect-completion.dto';
import { ProductCategoryDTO } from '@library/dto/product-category.dto';
import { SumRatingResultDTO } from '@library/dto/testschecklist/answer/sum-rating-result.dto';
import { ChecklistPublicationStatus } from '@library/dto-enums/checklist-publication-status.dto-enum';
import { EInspectionResult } from '@one/app/pages/brd/pages/report/pages/inspection/pages/id/brd-report-inspection-id.models';
import {
  IDefectsCategories,
  IDefectsChecklist,
} from '@one/app/pages/isp/pages/inspection/pages/id/pages/defects-checklist/isp-inspection-id-defect.models';

export class IspDefectsChecklistAction {
  public id: number;
  public name: string;
  public categories: IDefectsCategories[];
  public result: EInspectionResult;
  public isQima: boolean;
  public completions: DefectCompletionDTO[];
  public rating: ChecklistRatingSystemDTO | undefined;
  public publicationStatus: ChecklistPublicationStatus | undefined;
  public productCategories: ProductCategoryDTO[] | undefined;
  public sumRatingConfig: DefectChecklistSumRatingConfigDTO | undefined;
  public sumRatingResult: SumRatingResultDTO | undefined;

  public constructor(defectsChecklist: IDefectsChecklist) {
    this.id = defectsChecklist.id;
    this.name = defectsChecklist.name;
    this.categories = defectsChecklist.categories ?? [];
    this.result = defectsChecklist.result ?? EInspectionResult.FAIL;
    this.isQima = defectsChecklist.isQima;
    this.completions = defectsChecklist.completions ?? [];
    this.rating = defectsChecklist.rating;
    this.sumRatingConfig = defectsChecklist.sumRatingConfig;
    this.sumRatingResult = defectsChecklist.sumRatingResult;
  }
}
