import { EQpProfile } from '@library/models/qp-profile.models';
import { Pipe, PipeTransform } from '@angular/core';
import { QimaOptionalType } from '@qima/ngx-qima';

@Pipe({
  name: 'oneAuthorityLabel',
  standalone: true,
})
export class AuthorityLabelPipe implements PipeTransform {
  public transform(value: QimaOptionalType<string>): string {
    return value ? this._getAuthorityKey(value) : 'global.role.unknown';
  }

  private _getAuthorityKey(value: string): string {
    let key = 'global.role.unknown';

    if (Object.values(EQpProfile).some((v: EQpProfile): boolean => v === value)) {
      key = `global.${value.toLowerCase().replace('_', '.')}`;
    }

    return key;
  }
}
