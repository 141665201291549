import { AqlValue } from '@library/dto-enums/aql-value.dto-enum';
import { MeasurementsSamplingSizeType } from '@library/dto-enums/measurements-sampling-size-types.dto-enum';
import { SamplingSizeType as ESamplingSizeTypes } from '@library/dto-enums/sampling-size-type.dto-enum';
import { EQpAQLCodeLetter, EQpAQLInspectionLevel } from '@library/models/qp-aql.models';
import { EQpConstraintUnit } from '@library/models/qp-tests-checklist-questions-constraints.models';
import { AqlNotApplicableSamplingSizeValue } from '@one/app/shared/models/aql/aql.models';
import { QimaOptionalType } from '@qima/ngx-qima';

export type AbstractSamplingSizeType = WorkflowSamplingSizeType | SamplingSize;

export interface IAbstractSamplingSize {
  inspectionLevel: EQpAQLInspectionLevel;
  codeLetter?: QimaOptionalType<EQpAQLCodeLetter>;
  criticalSampleSize: number;
  majorSampleSize: number;
  minorSampleSize: number;
  type: ESamplingSizeTypes.AQL_SAMPLE_SIZE;
}

export interface IAbstractFixedSamplingSize {
  sampleSize: number;
  type: ESamplingSizeTypes.QUANTITY_SAMPLE_SIZE | ESamplingSizeTypes.NON_AQL_SAMPLE_SIZE;
}

export type WorkflowSamplingSizeType = IWorkflowSamplingSize | IWorkflowFixedSamplingSize | IWorkflowNonAqlSamplingSize;

export interface IWorkflowSamplingSize extends IAbstractSamplingSize {
  products: ISamplingSizeProduct[];
  /**
   * @deprecated
   * @todo remove the lotSize key when report will be migrate too
   * Pair Design -> https://asiainspection.atlassian.net/browse/PL-7298
   */
  lotSize?: number;
  sampleSize?: number;
  aqlModificationReason?: string;
}

export interface IWorkflowFixedSamplingSize extends IAbstractFixedSamplingSize {
  products: ISamplingSizeProduct[];
  /**
   * @deprecated
   * @todo remove the lotSize key when report will be migrate too
   * Pair Design -> https://asiainspection.atlassian.net/browse/PL-7298
   */
  lotSize?: number;
  aqlModificationReason?: string;
}

export interface IWorkflowNonAqlSamplingSize {
  products: ISamplingSizeProduct[];
  type: ESamplingSizeTypes.NON_AQL_SAMPLE_SIZE;
  sampleUnit: EQpConstraintUnit;
  sampleSize: number;
  /**
   * @deprecated
   * @todo remove the lotSize key when report will be migrate too
   * Pair Design -> https://asiainspection.atlassian.net/browse/PL-7298
   */
  lotSize?: number;
}

export enum ESamplingSizeType {
  INSPECTION_LEVEL_SAMPLE_SIZE = 'INSPECTION_LEVEL_SAMPLE_SIZE',
  QUANTITY_SAMPLE_SIZE = 'QUANTITY_SAMPLE_SIZE',
  PERCENTAGE_SAMPLE_SIZE = 'PERCENTAGE_SAMPLE_SIZE',
  AQL_NOT_APPLICABLE = 'AQL_NOT_APPLICABLE',
  NON_AQL_SAMPLE_SIZE = 'NON_AQL_SAMPLE_SIZE',
}

export enum FlatSamplingSizeType {
  INSPECTION_LEVEL_SAMPLE_SIZE_I = 'INSPECTION_LEVEL_SAMPLE_SIZE_I',
  INSPECTION_LEVEL_SAMPLE_SIZE_II = 'INSPECTION_LEVEL_SAMPLE_SIZE_II',
  INSPECTION_LEVEL_SAMPLE_SIZE_III = 'INSPECTION_LEVEL_SAMPLE_SIZE_III',
  INSPECTION_LEVEL_SAMPLE_SIZE_S1 = 'INSPECTION_LEVEL_SAMPLE_SIZE_S1',
  INSPECTION_LEVEL_SAMPLE_SIZE_S2 = 'INSPECTION_LEVEL_SAMPLE_SIZE_S2',
  INSPECTION_LEVEL_SAMPLE_SIZE_S3 = 'INSPECTION_LEVEL_SAMPLE_SIZE_S3',
  INSPECTION_LEVEL_SAMPLE_SIZE_S4 = 'INSPECTION_LEVEL_SAMPLE_SIZE_S4',
  QUANTITY_SAMPLE_SIZE = 'QUANTITY_SAMPLE_SIZE',
  PERCENTAGE_SAMPLE_SIZE = 'PERCENTAGE_SAMPLE_SIZE',
  AQL_NOT_APPLICABLE = 'AQL_NOT_APPLICABLE',
}

/**
 * @deprecated use instead {@link SamplingSizeType }
 */
export { ESamplingSizeTypes };

export interface SamplingSizeModelValue<T> {
  type: T;
}

export interface QuantitySampleSizeValue extends SamplingSizeModelValue<ESamplingSizeType> {
  quantity: number;
  unit: string;
}

export interface PercentageSampleSizeValue extends SamplingSizeModelValue<FlatSamplingSizeType> {
  percentage: number;
}

export interface InspectionLevelSamplingSizeValue extends SamplingSizeModelValue<FlatSamplingSizeType> {
  inspectionLevel: EQpAQLInspectionLevel;
}

export type TestSamplingSizeMapType =
  | QuantitySampleSizeValue
  | PercentageSampleSizeValue
  | InspectionLevelSamplingSizeValue
  | AqlNotApplicableSamplingSizeValue;

export type SamplingSize = ISamplingSize | IFixedSamplingSize | INonAqlSamplingSize;

export type UsedAqlSamplingSizeType = Exclude<SamplingSize, INonAqlSamplingSize>;

export interface ISamplingSize extends IAbstractSamplingSize {
  /**
   * It's actually the quantity
   */
  lotSize: number;
  type: ESamplingSizeTypes.AQL_SAMPLE_SIZE;
}

export interface IFixedSamplingSize {
  /**
   * It's actually the quantity
   */
  lotSize: number;
  type: ESamplingSizeTypes.QUANTITY_SAMPLE_SIZE;
  sampleSize: number;
}

export interface INonAqlSamplingSize {
  lotSize: number;
  type: ESamplingSizeTypes.NON_AQL_SAMPLE_SIZE;
  sampleSize: number;
}

export interface ISamplingSizeProduct {
  productName?: string;
  productId?: number;
  purchaseOrderProductId?: number;
  purchaseOrderId?: number;
  purchaseOrderReference?: string;
  /**
   * It's actually a quantity, not a lot size (a lot size is a range)
   */
  lotSize?: number;
}

export interface IMeasurementSamplingSize {
  type: MeasurementsSamplingSizeType;
  level?: EQpAQLInspectionLevel | null;
  aql?: MeasurementSamplingSizeAqlType | null;
  sampleSize?: number;
  canInspectorAddSample?: boolean;
  acceptanceNumber?: number;
}

/**
 * @todo backend return a string but we should have a number (percentage)
 * Create a mapper to handle this issue to convert to number instead
 * /!\ it's a string when user wants a flexible sampling count
 */
export type MeasurementSamplingSizeAqlType = AqlValue | string;
