import { QpAddressService } from '@library/components/qp-address/services/qp-address.service';
import { QpArea } from '@library/models/qp-administrative-area.models';
import { SERVER_API_URL } from '@one/app/app.constants';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AddressService implements QpAddressService {
  public constructor(private readonly _httpClient: HttpClient) {}

  public getAdministrativeAreas$(countryCode: string): Observable<HttpResponse<QpArea[]>> {
    return this._httpClient.get<QpArea[]>(`${SERVER_API_URL}api/countries/${countryCode}/administrative-areas`, {
      observe: 'response',
    });
  }
}
