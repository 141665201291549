export interface IIspInspectionIdInstructionDocuments {
  isLoading?: boolean;
  thumbUrl: string;
  url: string;
  type: string;
  filename: string;
  id: string;
}

export enum EInspectionDocumentType {
  DEFECT = 'defects-checklists',
  TEST = 'tests-checklists',
  GLOBAL = 'inspections',
}
