// @ts-strict-ignore
import { IProductVariances } from '@one/app/pages/isp/shared/models/variances/inspection-variances.models';
import { MeasuresChecklistActionSection } from '@one/app/shared/classes/measurements/measures-checklist-action-section';
import {
  IWorkflowTemplate,
  IWorkflowTemplateContent,
  IWorkflowTemplateStep,
  IWorkflowTemplateStepAction,
} from '@one/app/shared/models/workflows/workflows-templates.models';
import { isEmpty } from 'lodash/index';

export class WorkflowTemplate implements IWorkflowTemplate {
  public id: number;
  public name: string;
  public content?: IWorkflowTemplateContent;
  public isQima?: boolean;
  public inspectionTypeLabel?: string;
  public inspectionTypeDescription?: string;
  public inspectionTypeId: number;
  public auditTypeId: number;

  public constructor(workflowSnapshot: IWorkflowTemplate) {
    this.id = workflowSnapshot.id;
    this.name = workflowSnapshot.name;
    this.content = workflowSnapshot.content;
    this.isQima = workflowSnapshot.isQima;
    this.inspectionTypeLabel = workflowSnapshot.inspectionTypeLabel;
    this.inspectionTypeDescription = workflowSnapshot.inspectionTypeDescription;
    this.inspectionTypeId = workflowSnapshot.inspectionTypeId;
    this.auditTypeId = workflowSnapshot.auditTypeId;

    if (!isEmpty(workflowSnapshot?.content?.steps)) {
      const steps: IWorkflowTemplateStep[] = workflowSnapshot.content.steps.map((step: IWorkflowTemplateStep): IWorkflowTemplateStep => {
        if (!isEmpty(step.actions)) {
          const stepActions: IWorkflowTemplateStepAction[] = step.actions.map(
            (action: IWorkflowTemplateStepAction): IWorkflowTemplateStepAction => {
              if (!isEmpty(action.measuresChecklists)) {
                const measuresChecklists: IProductVariances[] = action.measuresChecklists.map(
                  (measureChecklist: IProductVariances): IProductVariances => {
                    return new MeasuresChecklistActionSection(measureChecklist);
                  }
                );

                return { ...action, measuresChecklists };
              }

              return action;
            }
          );

          return { ...step, actions: stepActions };
        }

        return step;
      });

      this.content = { steps };
    }
  }
}
