// @ts-strict-ignore
import { QpDividerComponent } from '@library/components/qp-divider/qp-divider.component';
import { EQpDividerColor } from '@library/components/qp-divider/qp-divider.models';
import { QpIconComponent } from '@library/components/qp-icon/qp-icon.component';
import { EQpIconName } from '@library/components/qp-icon/qp-icon.models';
import { QpFallbackImageDirective } from '@library/directives/qp-fallback-image/qp-fallback-image.directive';
import { EQpFallbackImageSource } from '@library/directives/qp-fallback-image/qp-fallback-image.models';
import { QpTooltipDirective } from '@library/directives/qp-tooltip/qp-tooltip.directive';
import { EQpProfile } from '@library/models/qp-profile.models';
import { IQpDisplayedRoute, QpDisplayedRoute } from '@library/models/qp-router.models';
import { QpResponsiveService } from '@library/services/qp-responsive/qp-responsive.service';
import { ACADEMY_URL } from '@one/app/app.constants';
import { CommonRoutes } from '@one/app/common-routes';
import { ActionsQueueSyncService } from '@one/app/components/main/services/actions-queue-sync.service';
import { Account } from '@one/app/shared/classes/accounts/account';
import { LogoutConfirmModalComponent } from '@one/app/shared/components/logout-confirm-modal/logout-confirm-modal.component';
import { EMenuPosition } from '@one/app/shared/models/menu/menu.models';
import { AuthorityLabelPipe } from '@one/app/shared/pipes/authority-label/authority-label.pipe';
import { AccountService } from '@one/app/shared/services/account/account.service';
import { DynamicSubnavRoutingStore } from '@one/app/shared/services/dynamic-subnav-routing-store/dynamic-subnav-routing-store.service';
import { LoginService } from '@one/app/shared/services/login/login.service';
import { DisplayedRoutesService } from '@one/app/shared/services/router/displayed-routes.service';
import { WINDOW } from '@one/app/shared/tokens/window.token';
import { Overlay } from '@angular/cdk/overlay';
import { NgClass, NgIf, NgFor } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, Inject } from '@angular/core';
import { RouterLinkActive, RouterLink } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { QimaOptionalType, EQimaPosition, QimaDialogService, QimaTooltipDirectiveModule } from '@qima/ngx-qima';
import { head, isEmpty } from 'lodash/index';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { filter, take } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'one-desktop-main-navbar',
  templateUrl: './desktop-main-navbar.component.html',
  styleUrls: ['./desktop-main-navbar.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    QpIconComponent,
    NzMenuModule,
    NgFor,
    QimaTooltipDirectiveModule,
    RouterLinkActive,
    RouterLink,
    NzIconModule,
    QpDividerComponent,
    QpTooltipDirective,
    QpFallbackImageDirective,
    TranslateModule,
    AuthorityLabelPipe,
  ],
})
export class DesktopMainNavbarComponent implements OnInit {
  public readonly commonRoutes = CommonRoutes;
  public isCollapsed = false;
  public menuList: IQpDisplayedRoute[] = [];
  public middleMenuList: IQpDisplayedRoute[] = [];
  public name = '';
  public account: QimaOptionalType<Account> = undefined;
  public accountRoute: QimaOptionalType<IQpDisplayedRoute> = undefined;
  public academyUrl = '';
  public readonly iconNames: typeof EQpIconName = EQpIconName;
  public readonly qimaPositions: typeof EQimaPosition = EQimaPosition;
  public readonly dividerColors: typeof EQpDividerColor = EQpDividerColor;
  public defaultPictureUrl: EQpFallbackImageSource = EQpFallbackImageSource.PLACEHOLDER_USER;

  private _menuPosition = EMenuPosition.COLLAPSE;

  public constructor(
    private readonly _displayedRoutesService: DisplayedRoutesService,
    private readonly _accountService: AccountService,
    private readonly _loginService: LoginService,
    private readonly _dynamicSubnavRoutingStore: DynamicSubnavRoutingStore,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _qpResponsiveService: QpResponsiveService,
    private readonly _dialogService: QimaDialogService,
    private readonly _actionsQueueSyncService: ActionsQueueSyncService,
    private readonly _overlay: Overlay,
    @Inject(WINDOW) private readonly _window: Window
  ) {
    this._qpResponsiveService
      .isMainNavBarCollapsed$()
      .pipe(untilDestroyed(this))
      .subscribe((isSmallSize: Readonly<boolean>): void => {
        this.isCollapsed = isSmallSize;
        this._menuPosition = isSmallSize ? EMenuPosition.COLLAPSE : EMenuPosition.EXPAND;
        this._displayedRoutesService.menuPosition$.next(this._menuPosition);
      });
  }

  public ngOnInit(): Promise<Account> {
    if (this._window.location.href.split('/')[2].includes('cn')) {
      this.academyUrl = `${ACADEMY_URL}cn`;
    } else {
      this.academyUrl = `${ACADEMY_URL}com`;
    }

    this._displayedRoutesService
      .getRoutes()
      .pipe(untilDestroyed(this))
      .subscribe((routes: IQpDisplayedRoute[]): void => {
        this.accountRoute = head(routes.filter((route: IQpDisplayedRoute): boolean => route.routerLink === '/account'));
        this.menuList = this._displayedRoutesService
          .sortMenu(routes)
          .filter((route: IQpDisplayedRoute): boolean => route.routerLink !== '/account')
          .filter((route: IQpDisplayedRoute): boolean => !route.isMobileOnly)
          .map((route: IQpDisplayedRoute): IQpDisplayedRoute => {
            if (!isEmpty(route.children)) {
              route.children = route.children.filter((childRoute: QpDisplayedRoute): boolean => !childRoute.isRouterLinkDisabled);
            }

            return route;
          });
      });

    this._displayedRoutesService
      .getMiddleRoutes()
      .pipe(untilDestroyed(this))
      .subscribe((routes: IQpDisplayedRoute[]): void => {
        this.middleMenuList = routes;
      });

    this._dynamicSubnavRoutingStore.routes$.pipe(untilDestroyed(this)).subscribe((routes: IQpDisplayedRoute): void => {
      if (this._dynamicSubnavRoutingStore.isCustomRoute(routes)) {
        const index = this.menuList.findIndex((item: IQpDisplayedRoute): boolean => item.routerLink === routes.routerLink);

        this.menuList[index] = routes;
        this._changeDetectorRef.detectChanges();
      }
    });

    return this.getIdentity();
  }

  public toggleCollapsed(): void {
    this.isCollapsed = !this.isCollapsed;
    this._menuPosition = this.isCollapsed ? EMenuPosition.COLLAPSE : EMenuPosition.EXPAND;
    this._displayedRoutesService.menuPosition$.next(this._menuPosition);
  }

  public tryLoggingOut(): void {
    if (
      this.account?.profiles.some((profile: Readonly<EQpProfile>): boolean =>
        [EQpProfile.ROLE_INSPECTOR, EQpProfile.ROLE_SERVICE_PROVIDER_INSPECTOR].includes(profile)
      ) &&
      this._actionsQueueSyncService.syncStackCount$.getValue() > 0
    ) {
      this.showLogoutPopup();
    } else {
      void this._loginService.logout();
    }
  }

  public showLogoutPopup(): void {
    this._dialogService
      .open<boolean>(LogoutConfirmModalComponent, {
        hasBackdrop: true,
        scrollStrategy: this._overlay.scrollStrategies.block(),
        panelClass: 'modal-panel',
      })
      .closed.pipe(
        take(1),
        filter((isConfirmed): boolean => !!isConfirmed),
        untilDestroyed(this)
      )
      .subscribe((): void => {
        void this._loginService.logout();
      });
  }

  public getIdentity(): Promise<Account> {
    return this._accountService.identity().then((account: Account): Account => {
      this.account = account;
      this._displayedRoutesService.menuPosition$.next(this.isCollapsed ? EMenuPosition.COLLAPSE : EMenuPosition.EXPAND);

      return account;
    });
  }
}
