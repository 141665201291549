import { SERVER_API_URL } from '@one/app/app.constants';
import { IQimaConnectConfig } from '@one/app/shared/models/connect/connect.models';
import { AuthenticationService } from '@one/app/shared/services/auth/authentication.service';
import { QIMA_CONNECT_CONFIG } from '@one/app/shared/tokens/qima-connect-config.token';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { QimaOptionalType } from '@qima/ngx-qima';
import { isNil } from 'lodash/index';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

/**
 * @description
 * Not provided in root on purpose
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  public constructor(
    private readonly _authenticationService: AuthenticationService,
    @Inject(QIMA_CONNECT_CONFIG) private readonly _qimaConnectConfig: IQimaConnectConfig
  ) {}

  public intercept<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    if (
      !request?.url ||
      (/^http/.test(request.url) && !(SERVER_API_URL && request.url.startsWith(SERVER_API_URL))) ||
      (this._qimaConnectConfig.enabled &&
        ((this._qimaConnectConfig.url && request.url.startsWith(this._qimaConnectConfig.url)) ||
          request.url.endsWith('/auth/qima-connect')))
    ) {
      return next.handle(request);
    }

    return this._authenticationService.getToken$().pipe(
      switchMap((token): Observable<HttpEvent<T>> => {
        request = this._addTokenToRequest(token, request);

        return next.handle(request);
      })
    );
  }

  private _addTokenToRequest<T>(token: QimaOptionalType<string>, request: HttpRequest<T>): HttpRequest<T> {
    if (isNil(token)) {
      return request;
    }

    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
