import { qpParseInt } from '@library/functions/math/qp-parse-int';
import { QpLoggerService } from '@library/services/qp-logger/qp-logger.service';
import { ScriptLoaderService } from '@one/app/services/script-loader.service';
import { IAccount } from '@one/app/shared/models/account/account.models';
import { WINDOW } from '@one/app/shared/tokens/window.token';
import { Inject, Injectable } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { QimaOptionalType } from '@qima/ngx-qima';
import { from, Observable, of, switchMap, throwError } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';

declare let appcuesId;

@Injectable({
  providedIn: 'root',
})
export class AppcuesService extends ScriptLoaderService {
  private readonly USER_DATA_SENT_TO_APPCUES: string[] = [
    'brandId',
    'clientName',
    'companyName',
    'createdBy',
    'createdDate',
    'email',
    'factoryId',
    'firstName',
    'id',
    'langKey',
    'lastName',
    'login',
    'position',
    'profiles',
    'role',
  ];

  private readonly id: QimaOptionalType<string>;

  public constructor(
    private readonly _router: Router,
    @Inject(WINDOW) private readonly _window: Window,
    private readonly _qpLoggerService: QpLoggerService
  ) {
    super();
    this.id = window['appcuesId'] ?? appcuesId;

    if (this.id && !isNaN(qpParseInt(this.id))) {
      this.scriptUrl = `//fast.appcues.com/${this.id}.js`;
    }
  }

  public load$(userData: Partial<IAccount>): Observable<void> {
    if (!this.scriptUrl) {
      return of(undefined);
    }

    // Give only the values you need to a third party!
    const dataToSend = this._filterUserData(userData);

    return from(this.loadScript(this.scriptUrl)).pipe(
      switchMap((): Observable<Event> => this._router.events),
      filter((event): boolean => event instanceof NavigationEnd),
      tap((): void => {
        this._window['Appcues'].identify(dataToSend.id, dataToSend);
      }),
      map((): undefined => undefined),
      catchError((err): Observable<never> => {
        this._qpLoggerService.debug('unable to load Appcues', err);

        return throwError(err);
      })
    );
  }

  private _filterUserData(initialData: Partial<IAccount>): Partial<IAccount> {
    return Object.keys(initialData)
      .filter((dataKey): boolean => this.USER_DATA_SENT_TO_APPCUES.includes(dataKey))
      .reduce(
        (acc, dataKey): Partial<IAccount> => ({
          ...acc,
          [dataKey]: Array.isArray(initialData[dataKey]) ? initialData[dataKey].join(',') : initialData[dataKey],
        }),
        {}
      );
  }
}
