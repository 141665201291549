<div [ngClass]="['qp-divider--position-' + position, 'qp-divider--orientation-' + orientation]" class="qp-divider">
  <ng-container *ngTemplateOutlet="item"></ng-container>
  <div class="qp-divider__content">
    <ng-content></ng-content>
  </div>
  <ng-container *ngTemplateOutlet="item"></ng-container>
</div>

<!-- Item -->
<ng-template #item>
  <div [ngClass]="['qp-divider__item--type-' + type, 'qp-divider__item--color-' + color]" class="qp-divider__item"></div>
</ng-template>
