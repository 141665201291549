export class CommonRoutes {
  public static root(): string {
    return '/';
  }

  public static forbidden(): string {
    return '/forbidden';
  }

  public static login(): string {
    return '/login';
  }

  public static comingSoon(): string {
    return '/coming-soon';
  }

  public static selectBrand(): string {
    return '/select-brand';
  }

  public static errorAccessDenied(): string {
    return '/error/access-denied';
  }

  public static error404(): string {
    return '/error/404';
  }

  public static errorUnknown(): string {
    return '/error/unknown';
  }

  public static reset(): string {
    return '/reset';
  }

  public static connect(): string {
    return '/connect';
  }

  /**
   * @description
   * redirect to error/deleted when you have http status code 410 and try to access the deleted data
   * @returns string
   */
  public static errorDeleted(): string {
    return '/error/deleted';
  }
}
