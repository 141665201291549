/**
 * @description ensure to update {@link QpActualQuestionType} on update
 */
export enum EQpQuestionType {
  TABLE = 'TABLE',
  BARCODE_SCAN = 'BARCODE_SCAN',
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
  NUMBER = 'NUMBER',
  AVERAGE_NUMBER = 'AVERAGE_NUMBER',
  TEXT = 'TEXT',
  YES_NO = 'YES_NO',
  YES_NO_NA = 'YES_NO_NA',
  DIV = 'DIV',
  CATEGORY = 'CATEGORY',
  DOCUMENT = 'DOCUMENT',
  RATINGS = 'RATINGS',
  DISTRIBUTION = 'DISTRIBUTION',
}

/**
 * @description is an actual question (= anything but a DIV)
 * This should be updated when {@link EQpQuestionType} is updated
 */
export type QpActualQuestionType =
  | EQpQuestionType.BARCODE_SCAN
  | EQpQuestionType.MULTIPLE_CHOICE
  | EQpQuestionType.NUMBER
  | EQpQuestionType.AVERAGE_NUMBER
  | EQpQuestionType.TEXT
  | EQpQuestionType.YES_NO
  | EQpQuestionType.YES_NO_NA
  | EQpQuestionType.DOCUMENT
  | EQpQuestionType.TABLE
  | EQpQuestionType.DISTRIBUTION;
