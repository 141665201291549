// @ts-strict-ignore
/* eslint-disable no-magic-numbers */
import { IBrdProductLinkedToPurchaseOrder } from '@one/app/pages/brd/pages/inspection/shared/components/brd-inspection-product-tab/brd-inspection-product-tab.models';
import {
  IExternalPurchaseOrderProduct,
  IInternalPurchaseOrderProduct,
} from '@one/app/pages/brd/pages/inspection/store-features/inspection-booking/brd-inspection-booking.models';
import { IProduct } from '@one/app/shared/models/products/product.models';
import { IPurchaseOrderDetail, IPurchaseOrderProduct } from '@one/app/shared/models/purchase-orders/purchase-orders.models';
import { isNil } from 'lodash/index';

/**
 * @param {IBrdProductLinkedToPurchaseOrder} productLinkedToPurchaseOrder The productLinkedToPurchaseOrder to map
 * @returns {IInternalPurchaseOrderProduct | IExternalPurchaseOrderProduct} The mapped IInternalPurchaseOrderProduct or IExternalPurchaseOrderProduct
 */
export function mapDTOPoProductToAbstractPoProduct(
  productLinkedToPurchaseOrder: IBrdProductLinkedToPurchaseOrder
): IInternalPurchaseOrderProduct | IExternalPurchaseOrderProduct {
  const isInternalPo: boolean = !isNil(productLinkedToPurchaseOrder.purchaseOrderId);

  return isInternalPo
    ? mapDTOBrdPoProductToInternalPoProduct(productLinkedToPurchaseOrder)
    : mapDTOPoProductToExternalPoProduct(productLinkedToPurchaseOrder);
}

/**
 * @param {IBrdProductLinkedToPurchaseOrder} productLinkedToPurchaseOrder The productLinkedToPurchaseOrder to map
 * @returns {IInternalPurchaseOrderProduct} The mapped IInternalPurchaseOrderProduct
 */
export function mapDTOBrdPoProductToInternalPoProduct(
  productLinkedToPurchaseOrder: IBrdProductLinkedToPurchaseOrder
): IInternalPurchaseOrderProduct {
  return {
    purchaseOrderId: productLinkedToPurchaseOrder.purchaseOrderId,
    productIdentifierValue: productLinkedToPurchaseOrder.productIdentifierValue,
    purchaseOrderProductId: productLinkedToPurchaseOrder.productPurchaseOrderId,
    reference: productLinkedToPurchaseOrder.purchaseOrderReference,
    productQuantity: productLinkedToPurchaseOrder.productQuantity,
    productId: productLinkedToPurchaseOrder.productId,
    shipmentDate: productLinkedToPurchaseOrder.purchaseOrderShipmentDate?.toString(),
    unit: productLinkedToPurchaseOrder.productUnit,
    brand: { id: productLinkedToPurchaseOrder.brandId, name: productLinkedToPurchaseOrder.productBrandName },
    purchaseOrderTier1Id: productLinkedToPurchaseOrder.purchaseOrderTier1Id,
    purchaseOrderTier2Id: productLinkedToPurchaseOrder.purchaseOrderTier2Id,
    purchaseOrderDestinationId: productLinkedToPurchaseOrder.purchaseOrderDestinationId,
  };
}

/**
 * @param {IPurchaseOrderProduct} productLinkedToPurchaseOrder The productLinkedToPurchaseOrder to map
 * @param {IPurchaseOrderDetail} purchaseOrder
 * @returns {IInternalPurchaseOrderProduct} The mapped IInternalPurchaseOrderProduct
 */
export function mapDTOPoProductToInternalPoProduct(
  productLinkedToPurchaseOrder: IPurchaseOrderProduct,
  { id, reference, brand, destination }: IPurchaseOrderDetail
): IInternalPurchaseOrderProduct {
  return {
    purchaseOrderId: id,
    productIdentifierValue: productLinkedToPurchaseOrder.productIdentifierValue,
    purchaseOrderProductId: productLinkedToPurchaseOrder.id,
    reference,
    productQuantity: productLinkedToPurchaseOrder.quantity,
    productId: productLinkedToPurchaseOrder.productId,
    shipmentDate: productLinkedToPurchaseOrder.shipmentDate,
    unit: productLinkedToPurchaseOrder.unit,
    brand: brand ? { id: brand.id, name: brand.name } : null,
    purchaseOrderDestinationId: destination?.id,
  };
}

/**
 * @param {IBrdProductLinkedToPurchaseOrder} productLinkedToPurchaseOrder The productLinkedToPurchaseOrder to map
 * @returns {IExternalPurchaseOrderProduct} The mapped IExternalPurchaseOrderProduct
 */
export function mapDTOPoProductToExternalPoProduct(
  productLinkedToPurchaseOrder: IBrdProductLinkedToPurchaseOrder
): IExternalPurchaseOrderProduct {
  return {
    productId: productLinkedToPurchaseOrder.productId,
    productIdentifierValue: productLinkedToPurchaseOrder.productIdentifierValue,
    unit: productLinkedToPurchaseOrder.productUnit,
    productQuantity: productLinkedToPurchaseOrder.productQuantity,
    shipmentDate: productLinkedToPurchaseOrder.purchaseOrderShipmentDate?.toString(),
  };
}

/**
 * @param {IProduct} product The product to map
 * @returns {IExternalPurchaseOrderProduct} The mapped IExternalPurchaseOrderProduct
 */
export function mapDTOProductToExternalPoProduct(product: IProduct): IExternalPurchaseOrderProduct {
  return {
    productId: product.id,
    productIdentifierValue: product.identifierValue,
    unit: null,
    productQuantity: null,
    shipmentDate: null,
  };
}
