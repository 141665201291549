import { RatingCriteriaDTO } from '@library/dto/rating-criteria.dto';
import { EQpConstraintCriterion } from '@library/models/qp-tests-checklist-questions-constraints.models';

/**
 * @description
 * return the contrain matching the value or not
 * @param {RatingCriteriaDTO[]} criterias to use
 * @param {number} value to check
 * @returns {RatingCriteriaDTO | undefined} the contrain matching
 */
export function qpGetConstraintMatchingValue(criterias: RatingCriteriaDTO[] = [], value: number): RatingCriteriaDTO | undefined {
  return criterias.find((constraint): boolean => {
    if (!constraint.enabled) {
      return false;
    }

    switch (constraint.criteria) {
      case EQpConstraintCriterion.GT:
        return value > constraint.values[0];
      case EQpConstraintCriterion.GTE:
        return value >= constraint.values[0];
      case EQpConstraintCriterion.LT:
        return value < constraint.values[0];
      case EQpConstraintCriterion.LTE:
        return value <= constraint.values[0];
      case EQpConstraintCriterion.BETWEEN:
        return value > constraint.values[0] && value < constraint.values[1];
      case EQpConstraintCriterion.BETWEEN_INCLUSIVE:
        return value >= constraint.values[0] && value <= constraint.values[1];
      default:
        console.error(`Unexpected criteria: ${constraint.criteria}`);

        return false;
    }
  });
}
