// @ts-strict-ignore
import { IQpArea } from '@library/models/qp-administrative-area.models';
import { IQpCoordinates } from '@library/models/qp-coordinates.models';
import { QpCountry, IQpCountry } from '@library/models/qp-country.models';

export interface IQpAddress {
  id: number;
  country: IQpCountry;
  street: string;
  city: string;
  postCode: string;
  administrativeArea?: IQpArea;
  coordinates?: IQpCoordinates;
}

export class QpAddress implements IQpAddress {
  public static create(address: {
    country: IQpCountry;
    street: string;
    city: string;
    postCode: string;
    administrativeArea?: IQpArea;
    coordinates?: IQpCoordinates;
  }): IQpAddress {
    return new QpAddress(
      null,
      address.country,
      address.street,
      address.city,
      address.postCode,
      address.coordinates || null,
      address.administrativeArea || null
    );
  }

  public static newEmpty(): QpAddress {
    const country = QpCountry.newEmpty();

    return new QpAddress(null, country, '', '', '', null, null);
  }

  private constructor(
    public id: number,
    public country: IQpCountry,
    public street: string,
    public city: string,
    public postCode: string,
    public coordinates?: IQpCoordinates,
    public administrativeArea?: IQpArea
  ) {}
}

export interface IQpSimpleAddress {
  id: number;
  country: IQpCountry | string;
  street: string;
  city: string;
  postCode: string;
  administrativeArea?: string;
  coordinates?: IQpCoordinates;
}

export class QpSimpleAddress implements IQpSimpleAddress {
  public static create(simpleAddressDto: IQpSimpleAddressDto): QpSimpleAddress {
    return {
      id: null,
      country: simpleAddressDto.country?.isoCode,
      street: simpleAddressDto.street,
      city: simpleAddressDto.city,
      postCode: simpleAddressDto.postCode,
      coordinates: simpleAddressDto.coordinates || null,
      administrativeArea: simpleAddressDto.administrativeArea?.isoCode || null,
    };
  }

  public id: number;
  public country: string;
  public street: string;
  public city: string;
  public postCode: string;
  public coordinates?: IQpCoordinates;
  public administrativeArea?: string;
}

export interface IQpSimpleAddressDto {
  id: number;
  country: IQpCountry;
  street: string;
  city: string;
  postCode: string;
  administrativeArea?: IQpArea;
  coordinates?: IQpCoordinates;
}
