import { EQpFallbackImageSource } from '@library/directives/qp-fallback-image/qp-fallback-image.models';
import { AfterViewInit, Directive, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2 } from '@angular/core';
import { endsWith } from 'lodash/index';

@Directive({
  selector: 'img[qpFallbackImage]',
  standalone: true,
})
export class QpFallbackImageDirective implements AfterViewInit {
  @Output('qpFallbackImageVisibilityChange')
  public visibilityChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * @private
   * @description
   * Useful to avoid to replace the current src
   * It needs to wait for the img to build it's src then if the source is not good, replace it with the fallback one
   * @type {boolean}
   */
  private _isInitialized = false;
  private _fallbackImageSrc = '';
  private _fallbackImageUrl: EQpFallbackImageSource = EQpFallbackImageSource.PLACEHOLDER_USER;

  @Input('qpFallbackImageUrl')
  public get fallbackImageUrl(): EQpFallbackImageSource {
    return this._fallbackImageUrl;
  }

  public set fallbackImageUrl(imageSource: EQpFallbackImageSource) {
    this._fallbackImageUrl = imageSource;

    this._setFallbackImageHtmlElementSrc();
  }

  public constructor(private readonly _elementRef: ElementRef, private readonly _renderer: Renderer2) {}

  @HostListener('error')
  public onError(): void {
    const isCurrentErrorCausedByFallbackImage: boolean = this._isFallbackImageVisible();

    if (!isCurrentErrorCausedByFallbackImage) {
      this._showFallbackImage();
    }
  }

  @HostListener('load')
  public onLoad(): void {
    this.visibilityChange.emit(this._isFallbackImageVisible());
  }

  public ngAfterViewInit(): void {
    this._isInitialized = true;

    this._setFallbackImageHtmlElementSrc();
  }

  private _showFallbackImage(): void {
    this._renderer.setAttribute(this._elementRef.nativeElement, 'src', this._fallbackImageSrc);
  }

  private _setFallbackImageHtmlElementSrc(): void {
    if (this._isInitialized) {
      this._fallbackImageSrc = this.fallbackImageUrl;

      this._updateVisibleFallbackImage();
    }
  }

  private _updateVisibleFallbackImage(): void {
    if (this._isFallbackImageVisible()) {
      this._showFallbackImage();
    }
  }

  private _isFallbackImageVisible(): boolean {
    return endsWith(this._elementRef.nativeElement.src, this._fallbackImageSrc);
  }
}
