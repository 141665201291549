import { WindowRef } from '@one/app/shared/classes/window-ref';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BrowserWindowRef extends WindowRef {
  public get nativeWindow(): Window | Record<string, void> {
    return window;
  }
}
