import { EBrdInspectionStepTitle } from '@one/app/pages/brd/pages/inspection/shared/models/step.models';
import { IDataLayer } from '@one/app/shared/services/third-party/analytics-manager/models/analytics-manager.models';

export interface IDataLayerBrdBookingInspectionEvent extends Pick<IDataLayer, 'category' | 'action' | 'label' | 'value'> {
  category: EDataLayerBookingInspectionEventCategoryType.BRD_SCHEDULE_INSPECTION;
  action: EDataLayerBookingInspectionEventAction.ENTER;
  label: EBrdInspectionStepTitle;
  value: EDataLayerBookingInspectionEventValue.FROM_BUTTON | EDataLayerBookingInspectionEventValue.FROM_STEPPER;
}

export type DataLayerBrdBookingInspectionEventType = IDataLayerBrdBookingInspectionEvent & Pick<IDataLayer, 'event'>;

export enum EDataLayerBookingInspectionEventCategoryType {
  BRD_SCHEDULE_INSPECTION = 'brand-schedule-inspection',
}

export enum EDataLayerBookingInspectionEventAction {
  ENTER = 'enter',
}

export enum EDataLayerBookingInspectionEventValue {
  FROM_STEPPER = 'from-stepper',
  FROM_BUTTON = 'from-button',
}
