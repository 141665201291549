// @ts-strict-ignore
import { QpCoordinates } from '@library/classes/qp-coordinates/qp-coordinates';
import { DefectSamplingSizeSettingsUtils } from '@library/dto/workflow/sampling-size.utils';
import { InspectionStatus } from '@library/dto-enums/inspection-status.dto-enum';
import { EQpHttpStatusCode } from '@library/models/qp-http.models';
import { QpDateService } from '@library/services/qp-date/qp-date.service';
import { CommonRoutes } from '@one/app/common-routes';
import { IspRoutes } from '@one/app/pages/isp/isp-routes';
import {
  ILoadInspectionActionProps,
  ILoadInspectionErrorForbiddenAction,
  LOAD_INSPECTION_ERROR_FORBIDDEN,
  LOAD_INSPECTION_SUCCESS,
  loadInspection,
  UPDATE_AQL_DEFECTS_SUCCESS,
  UPDATE_AQL_REASON_FOR_CHANGES_SUCCESS,
  UPDATE_SAMPLING_SIZES_SUCCESS,
  updateAqlDefects,
  updateAqlReasonForChanges,
  UPDATED_INSPECTION_STATUS_SUCCESS,
  updateInspectionStatus,
  updateInspectionStatusWithoutBackendCall,
  updateSamplingSizes,
} from '@one/app/pages/isp/pages/inspection/pages/id/shared/services/store/isp-inspection-id-store.actions';
import { LOAD_WORKFLOW } from '@one/app/pages/isp/pages/inspection/pages/id/shared/services/store/isp-inspection-id-workflow-store.actions';
import { getInspectionIdOrUuid } from '@one/app/pages/isp/shared/functions/isp-get-inspection-id-or-uuid';
import { InspectionId } from '@one/app/pages/isp/shared/models/isp-inspection.models';
import { IspInspectionPreloadService } from '@one/app/pages/isp/shared/services/isp-inspection-preload.service';
import { AqlDefectsType } from '@one/app/shared/models/defects/aql-defects.models';
import { IInspectionConsultation } from '@one/app/shared/models/inspection-consultation/inspection-consultation.models';
import { ERouteType } from '@one/app/shared/models/routes/route-type.models';
import { WorkflowSamplingSizeType } from '@one/app/shared/models/sampling/sampling-size.models';
import { AqlService } from '@one/app/shared/services/aql/aql.service';
import { InspectionService } from '@one/app/shared/services/inspection/inspection.service';
import { IStoreState } from '@one/app/store/store.models';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { QimaOptionalType } from '@qima/ngx-qima';
import { isNil } from 'lodash/index';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

interface IUpdateInspectionStatusSuccessAction {
  type: typeof UPDATED_INSPECTION_STATUS_SUCCESS;
  status: InspectionStatus;
}

interface ILoadWorkflowAction {
  type: typeof LOAD_WORKFLOW;
  id: InspectionId;
}

interface ILoadInspectionSuccessAction {
  type: typeof LOAD_INSPECTION_SUCCESS;
  inspection: IInspectionConsultation;
}

/**
 * @description
 * Not provided in root on purpose
 */
@Injectable()
export class IspInspectionIdStoreEffects {
  public updateInspectionStatus$ = createEffect(
    (): Observable<IUpdateInspectionStatusSuccessAction | ILoadInspectionSuccessAction | ILoadWorkflowAction> =>
      this._actions.pipe(
        ofType(updateInspectionStatus),
        mergeMap((action): Observable<IUpdateInspectionStatusSuccessAction | ILoadInspectionSuccessAction | ILoadWorkflowAction> => {
          return this._inspectionService
            .updateInspectionStatus$(
              action.id,
              action.status,
              action.position ? QpCoordinates.fromPosition(action.position) : null,
              this._qpDateService.getDate(),
              this._qpDateService.getTimezone(),
              null,
              null
            )
            .pipe(
              tap((): void => {
                this._ispInspectionPreloadService.preloadInspectionData(action.id, action.status);
              }),
              switchMap((): IUpdateInspectionStatusSuccessAction[] => {
                return [
                  {
                    type: UPDATED_INSPECTION_STATUS_SUCCESS,
                    status: action.status,
                  },
                ];
              }),
              catchError((error: Error | HttpErrorResponse): Observable<never> => {
                if (error instanceof HttpErrorResponse && error.status === EQpHttpStatusCode.GONE) {
                  void this._router.navigate([CommonRoutes.errorDeleted()], { queryParams: { type: ERouteType.INSPECTION } });
                }

                return EMPTY;
              })
            );
        })
      )
  );

  public updateInspectionStatusWithoutBackendCalls$ = createEffect(
    (): Observable<IUpdateInspectionStatusSuccessAction> =>
      this._actions.pipe(
        ofType(updateInspectionStatusWithoutBackendCall),
        switchMap((action): IUpdateInspectionStatusSuccessAction[] => {
          return [
            {
              type: UPDATED_INSPECTION_STATUS_SUCCESS,
              status: action.status,
            },
          ];
        })
      )
  );

  public loadInspection$ = createEffect(
    (): Observable<{ type: string; inspection: IInspectionConsultation } | ILoadInspectionErrorForbiddenAction> =>
      this._actions.pipe(
        ofType(loadInspection),
        mergeMap(
          (
            action: ILoadInspectionActionProps
          ): Observable<{ type: string; inspection: IInspectionConsultation } | ILoadInspectionErrorForbiddenAction> => {
            return this._inspectionService.getInspection$(action.id).pipe(
              map((inspection: IInspectionConsultation): { type: string; inspection: IInspectionConsultation } => {
                this._ispInspectionPreloadService.preloadInspectionData(action.id, inspection.status);

                return {
                  type: LOAD_INSPECTION_SUCCESS,
                  inspection,
                };
              }),
              catchError((error: Error | HttpErrorResponse): Observable<never | ILoadInspectionErrorForbiddenAction> => {
                // Since it can throw HTTP error
                // When the inspector doesn't have the right to access the inspection
                // We navigate to a dedicated error page which is a bit better than the default forbidden page
                if (error instanceof HttpErrorResponse) {
                  if (error.status === EQpHttpStatusCode.FORBIDDEN) {
                    void this._router.navigate([IspRoutes.inspectionConsultationErrorForbidden()]);
                  } else {
                    // @todo handle with better dedicated screens the errors
                    console.log('Unknown error', error);
                    void this._router.navigate([IspRoutes.inspectionConsultationErrorUnknown()]);
                  }

                  return of({
                    type: LOAD_INSPECTION_ERROR_FORBIDDEN,
                  });
                }

                return EMPTY;
              })
            );
          }
        )
      )
  );

  public updateSamplingSizes$ = createEffect(
    (): Observable<{ type: string; samplingSize: WorkflowSamplingSizeType; purchaseOrderProductId?: QimaOptionalType<number> }> =>
      this._actions.pipe(
        ofType(updateSamplingSizes),
        withLatestFrom(this._store),
        filter(
          ([action, storeState]): boolean =>
            !(storeState.inspectionStore.inspection.isAqlPerProduct && isNil(action.purchaseOrderProductId))
        ),
        mergeMap(([action, storeState]): Observable<{ type: string; samplingSize: WorkflowSamplingSizeType }> => {
          const actionSamplingSize = { ...action.samplingSize };

          if (action.purchaseOrderProductId) {
            // purchaseOrderProductId is specified only if we use AQL per product. In this case we only send the targeted product
            actionSamplingSize.products = action.samplingSize.products.filter(
              (product): boolean => product.purchaseOrderProductId === action.purchaseOrderProductId
            );
          }

          const inspectionSamplingSize = DefectSamplingSizeSettingsUtils.fromLegacyWorkflowSamplingSize(actionSamplingSize);

          return this._inspectionService
            .updateInspectionSamplingSizes$(getInspectionIdOrUuid(storeState.inspectionStore.inspection), inspectionSamplingSize)
            .pipe(
              map((): { type: string; samplingSize: WorkflowSamplingSizeType; purchaseOrderProductId?: QimaOptionalType<number> } => {
                return {
                  type: UPDATE_SAMPLING_SIZES_SUCCESS,
                  samplingSize: actionSamplingSize,
                  purchaseOrderProductId: action.purchaseOrderProductId,
                };
              })
            );
        })
      )
  );

  public updateAqlDefects$ = createEffect(
    (): Observable<{ type: string; aqlDefects: AqlDefectsType }> =>
      this._actions.pipe(
        ofType(updateAqlDefects),
        withLatestFrom(this._store),
        mergeMap(
          ([action, storeState]): Observable<{
            type: string;
            aqlDefects: AqlDefectsType;
            purchaseOrderProductId?: QimaOptionalType<number>;
          }> =>
            this._aqlService
              .updateAqlByInspectionId$(storeState.inspectionStore.inspection.id, action.aqlDefects, action.purchaseOrderProductId)
              .pipe(
                map((): { type: string; aqlDefects: AqlDefectsType; purchaseOrderProductId?: QimaOptionalType<number> } => {
                  return {
                    type: UPDATE_AQL_DEFECTS_SUCCESS,
                    aqlDefects: action.aqlDefects,
                    purchaseOrderProductId: action.purchaseOrderProductId,
                  };
                })
              )
        )
      )
  );

  public updateAqlReasonForChanges$ = createEffect(
    (): Observable<{ type: string; reason: string }> =>
      this._actions.pipe(
        ofType(updateAqlReasonForChanges),
        withLatestFrom(this._store),
        mergeMap(
          ([action, storeState]): Observable<{ type: string; reason: string }> =>
            this._inspectionService
              .updateAqlReasonForChangesByInspectionId$(
                storeState.inspectionStore.inspection.id,
                action.reason,
                action.purchaseOrderProductId
              )
              .pipe(
                map((): { type: string; reason: string; purchaseOrderProductId?: QimaOptionalType<number> } => {
                  return {
                    type: UPDATE_AQL_REASON_FOR_CHANGES_SUCCESS,
                    reason: action.reason,
                    purchaseOrderProductId: action.purchaseOrderProductId,
                  };
                })
              )
        )
      )
  );

  public constructor(
    private readonly _inspectionService: InspectionService,
    private readonly _qpDateService: QpDateService,
    private readonly _actions: Actions,
    private readonly _store: Store<IStoreState>,
    private readonly _ispInspectionPreloadService: IspInspectionPreloadService,
    private readonly _aqlService: AqlService,
    private readonly _router: Router
  ) {}
}
