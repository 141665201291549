import { IQpDatatableFilterAndSortParam } from '@library/components/qp-datatable/qp-datatable.models';
import { BrandDTO } from '@library/dto/brand.dto';
import { DefectsChecklistGetDTO } from '@library/dto/checklist/defect/defects-checklist.dto';
import { InspectionTypeSettingDTO } from '@library/dto/inspection-type-settings.dto';
import { InspectionTypeDTO } from '@library/dto/inspection-type.dto';
import { ProductDetailDTO } from '@library/dto/product-detail.dto';
import { PurchaseOrderViewDTO } from '@library/dto/purchase-order-view.dto';
import { ServiceProviderClientElasticDTO } from '@library/dto/service-provider-client-elastic.dto';
import { TestsChecklistDTO } from '@library/dto/tests-checklist.dto';
import { CustomFieldDTO } from '@library/dto/workflow/custom-fields.dto';
import { WorkflowTemplateGetDTO } from '@library/dto/workflow/workflow-template.dto';
import { InspectionStatus } from '@library/dto-enums/inspection-status.dto-enum';
import { QpIdType, QpUuidType } from '@library/models/qp-alias.models';
import { IQpConsultationPage } from '@library/models/qp-consultation-page.models';
import { EQpProfile } from '@library/models/qp-profile.models';
import { QpDateService } from '@library/services/qp-date/qp-date.service';
import { IPurchaseOrderInfo } from '@one/app/pages/brd/pages/product-po/pages/po/models/purchase-orders.model';
import {
  BrdWorkflowChecklistConsultationType,
  EBrdWorkflowChecklistConsultationType,
} from '@one/app/pages/brd/pages/workflow/pages/checklist/pages/consult/brd-workflow-checklist-consult.models';
import { IWorkflowConsultation } from '@one/app/pages/brd/pages/workflow/pages/consult/brd-workflow-consult.models';
import { IBrdProductConsultation } from '@one/app/pages/brd/shared/models/brd-product-consult.model';
import { DefectsChecklistService } from '@one/app/pages/brd/shared/services/defects-checklist.service';
import { EntityConsultationService } from '@one/app/pages/brd/shared/services/entity-consultation.service';
import { EInspectionDocumentType } from '@one/app/pages/isp/pages/inspection/pages/id/shared/components/instruction-documents/instruction-documents.models';
import { InstructionDocumentsService } from '@one/app/pages/isp/pages/inspection/pages/id/shared/components/instruction-documents/instruction-documents.service';
import WorkflowUtils from '@one/app/pages/isp/pages/inspection/pages/id/shared/services/workflow.utils';
import { HTTP_SKIP_409_OPTIONS } from '@one/app/shared/constants/http-skip-409-options';
import { IAccount } from '@one/app/shared/models/account/account.models';
import { IWorkflow } from '@one/app/shared/models/workflow/workflow.models';
import { AccountService } from '@one/app/shared/services/account/account.service';
import { BrandService } from '@one/app/shared/services/brand/brand.service';
import { TestsChecklistService } from '@one/app/shared/services/checklist/tests-checklist.service';
import { CustomFieldsService } from '@one/app/shared/services/custom-fields/custom-fields.service';
import { DatabaseDataService } from '@one/app/shared/services/database/database-data.service';
import { InspectionService } from '@one/app/shared/services/inspection/inspection.service';
import { SettingsInspectionTypesService } from '@one/app/shared/services/inspection/settings-inspection-types.service';
import { ProductService } from '@one/app/shared/services/product/product.service';
import { PurchaseOrderService } from '@one/app/shared/services/purchase-order/purchase-order.service';
import { ServiceProviderClientService } from '@one/app/shared/services/service-provider-client.service';
import { WorkflowTemplateService } from '@one/app/shared/services/workflow-template/workflow-template.service';
import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { QimaOptionalType } from '@qima/ngx-qima';
import { isNil, isObject, isNumber } from 'lodash/index';
import { Observable, of, combineLatest, BehaviorSubject, forkJoin, from, EMPTY } from 'rxjs';
import { switchMap, map, filter, take, finalize, tap, catchError, defaultIfEmpty } from 'rxjs/operators';

const MAX_ITEMS_TO_PRELOAD = 100;

type IdLastModifiedDateMapType = Map<number, string>;

interface PreloadItemState {
  items: number;
  preloadedItems: number;
}

interface PreloadItem {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
  id: number;
  lastModifiedDate?: string | Date;
}

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class IspInspectionPreloadService {
  public readonly LOCAL_STORAGE_OFFLINE_KEY = 'isStartOfflineFeatureEnabled';
  public readonly LOCAL_STORAGE_LAST_PRELOADING_TIME_KEY = 'lastPreloadingTime';
  public preloadState$ = new BehaviorSubject<PreloadState>({
    [PreloadKey.PRODUCT]: {
      items: 0,
      preloadedItems: 0,
    },
    [PreloadKey.PO]: {
      items: 0,
      preloadedItems: 0,
    },
    [PreloadKey.INSPECTION_TYPE]: {
      items: 0,
      preloadedItems: 0,
    },
    [PreloadKey.INSPECTION_TYPE_SETTING]: {
      items: 0,
      preloadedItems: 0,
    },
    [PreloadKey.WORKFLOW]: {
      items: 0,
      preloadedItems: 0,
    },
    [PreloadKey.TESTS_CHECKLIST]: {
      items: 0,
      preloadedItems: 0,
    },
    [PreloadKey.DEFECTS_CHECKLIST]: {
      items: 0,
      preloadedItems: 0,
    },
    [PreloadKey.CLIENT]: {
      items: 0,
      preloadedItems: 0,
    },
  });

  public isPreloading$ = new BehaviorSubject<boolean>(true);

  private readonly _tomorowDateParams = this._qpDateService.getDateWithoutTimezoneConvert(
    this._qpDateService.addDays(QpDateService.now, 1)
  );

  private readonly _oneMonthAgoDateParams = this._qpDateService.getDateWithoutTimezoneConvert(
    this._qpDateService.subtractDays(QpDateService.now, 30)
  );

  private readonly _defaultEntityPageSize = 50;
  private readonly _lastSynchronizationBehaviorSubject = new BehaviorSubject<string>('');

  public constructor(
    private readonly _inspectionService: InspectionService,
    private readonly _settingsInspectionTypesService: SettingsInspectionTypesService,
    private readonly _instructionDocuments: InstructionDocumentsService,
    private readonly _entityConsultationService: EntityConsultationService,
    private readonly _databaseDataService: DatabaseDataService,
    private readonly _productService: ProductService,
    private readonly _purchaseOrderService: PurchaseOrderService,
    private readonly _accountService: AccountService,
    private readonly _qpDateService: QpDateService,
    private readonly _translateService: TranslateService,
    private readonly _workflowTemplateService: WorkflowTemplateService,
    private readonly _defectsChecklistService: DefectsChecklistService,
    private readonly _testsChecklistService: TestsChecklistService,
    private readonly _customFieldService: CustomFieldsService,
    private readonly _serviceProviderClientService: ServiceProviderClientService,
    private readonly _brandService: BrandService
  ) {
    this._databaseDataService.isDBInit$
      .pipe(
        filter((isDBInit: boolean): boolean => isDBInit),
        take(1),
        switchMap((): Observable<void> => this._preloadInspectionDataIfApplicable$())
      )
      .subscribe();

    this._getlastSynchronization$()
      .pipe(take(1), untilDestroyed(this))
      .subscribe((lastSynchronization: string): void => {
        this._lastSynchronizationBehaviorSubject.next(lastSynchronization);
      });
  }

  public get lastSynchronization$(): Observable<string> {
    return this._lastSynchronizationBehaviorSubject.pipe(switchMap((): Observable<string> => this._getlastSynchronization$()));
  }

  public preloadDocuments(workflow: IWorkflow, inspectionId: QpIdType | QpUuidType): void {
    const testFileList = WorkflowUtils.getTestFileListByWorkflow(workflow);
    const defectFileList = WorkflowUtils.getDefectFileListByWorkflow(workflow);
    const documentFileList = WorkflowUtils.getDocumentFileListByWorkflow(workflow);

    testFileList.forEach((value: number, key: string): void => {
      this._instructionDocuments
        .getInstructionDocumentByInspection(value, key, false, EInspectionDocumentType.TEST)
        .pipe(
          switchMap(
            (): Observable<{
              name: string;
              type: string;
              size: string;
            }> => this._instructionDocuments.getInstructionDocumentMetadataByInspection$(value, key, EInspectionDocumentType.TEST)
          ),
          untilDestroyed(this)
        )
        .subscribe();
    });

    defectFileList.forEach((value: number, key: string): void => {
      this._instructionDocuments
        .getInstructionDocumentByInspection(value, key, false, EInspectionDocumentType.DEFECT)
        .pipe(
          switchMap(
            (): Observable<{
              name: string;
              type: string;
              size: string;
            }> => this._instructionDocuments.getInstructionDocumentMetadataByInspection$(value, key, EInspectionDocumentType.DEFECT)
          ),
          untilDestroyed(this)
        )
        .subscribe();
    });

    documentFileList.forEach((fileId: string): void => {
      this._inspectionService
        .getDocument$(inspectionId, fileId)
        .pipe(
          switchMap(
            (): Observable<{
              name: string;
              type: string;
              size: string;
            }> => this._instructionDocuments.getInstructionDocumentMetadataByInspection$(inspectionId, fileId)
          ),
          untilDestroyed(this)
        )
        .subscribe();
    });
  }

  public manualAskForPreload$(): Observable<void> {
    return this._preloadInspectionDataIfApplicable$();
  }

  public preloadInspectionData(inspectionId: QpIdType | QpUuidType, inspectionStatus: InspectionStatus): void {
    if (inspectionStatus === InspectionStatus.ACCEPTED) {
      this._inspectionService
        .getWorkflow$(inspectionId, true, HTTP_SKIP_409_OPTIONS)
        .pipe(
          catchError((): Observable<never> => EMPTY),
          filter((workflow: QimaOptionalType<IWorkflow>): workflow is IWorkflow => {
            return !isNil(workflow);
          })
        )
        .subscribe({
          next: (workflow: IWorkflow): void => {
            this.preloadDocuments(workflow, inspectionId);
          },
        });
    }
  }

  public restorePreloadState(): void {
    Object.keys(PreloadKey).forEach((key): void =>
      this._updatePreloadState(PreloadKey[key], {
        preloadedItems: 0,
        items: 0,
      })
    );
  }

  private _preloadInspectionDataIfApplicable$(): Observable<void> {
    if (localStorage.getItem(this.LOCAL_STORAGE_OFFLINE_KEY) !== 'true') {
      this.isPreloading$.next(false);

      return of(void 0);
    }

    return this._accountService.getAccount$().pipe(
      filter(
        (account: QimaOptionalType<IAccount>): account is IAccount =>
          account?.profiles.includes(EQpProfile.ROLE_SERVICE_PROVIDER_INSPECTOR) ?? false
      ),
      take(1),
      switchMap((account): Observable<void> => this._preloadData$(account))
    );
  }

  private _preloadData$(account: IAccount): Observable<void> {
    this.isPreloading$.next(true);

    return forkJoin([
      this._preloadProductsAndPOs$().pipe(this._endPreloadOperation$),
      this._preloadWorkflows$().pipe(this._endPreloadOperation$),
      this._preloadDefectsChecklist$().pipe(this._endPreloadOperation$),
      this._preloadTestsChecklist$().pipe(this._endPreloadOperation$),
      this._preloadInspectionTypes$().pipe(this._endPreloadOperation$),
      this._preloadInspectionTypeSettings$(account.brandId).pipe(this._endPreloadOperation$),
      this._preloadCustomFields$().pipe(this._endPreloadOperation$),
      this._preloadCliends$(account.brandId).pipe(this._endPreloadOperation$),
    ]).pipe(
      finalize((): void => {
        this.isPreloading$.next(false);
        this._updateLastSync();
      }),
      map((): void => {
        return void 0;
      })
    );
  }

  // CLIENTS 👩‍🚀

  private _preloadCliends$(inspectorBrandId: string | undefined): Observable<void> {
    return this._serviceProviderClientService.getClients$().pipe(
      take(1),
      switchMap((clients: ServiceProviderClientElasticDTO[]): Observable<void[]> => {
        this._updatePreloadState(PreloadKey.CLIENT, {
          items: clients.length + 1, // +1 for the inspector brand
          preloadedItems: 0,
        });

        const clientsMap: IdLastModifiedDateMapType = new Map();

        clients.forEach((client: ServiceProviderClientElasticDTO): void => {
          clientsMap.set(client.clientId, client.lastModifiedDate ?? new Date().toISOString());
        });

        if (!isNil(inspectorBrandId)) {
          clientsMap.set(parseInt(inspectorBrandId, 10), new Date().toISOString());
        }

        return combineLatest(
          Array.from(clientsMap).map(([id, lastModifiedDate]): Observable<void> => this._preloadClientsIfNotUpToDate$(id, lastModifiedDate))
        );
      }),
      map((): void => void 0)
    );
  }

  private _preloadClientsIfNotUpToDate$(id: number, lastModifiedDate: string): Observable<void> {
    return from(this._databaseDataService.getBrandById(id)).pipe(
      filter((client: BrandDTO | undefined): client is BrandDTO => {
        const isNeedToBePreload = !!(isNil(client) || client.lastModifiedDate !== lastModifiedDate);

        if (!isNeedToBePreload) {
          this._updatePreloadState(PreloadKey.CLIENT, {
            preloadedItems: this.preloadState$.value.client.preloadedItems + 1,
          });
        }

        return isNeedToBePreload;
      }),
      switchMap((): Observable<BrandDTO> => this._brandService.getBrand$(id)),
      switchMap((brand: BrandDTO): Observable<BrandDTO | undefined> => {
        this._updatePreloadState(PreloadKey.CLIENT, {
          preloadedItems: this.preloadState$.value.client.preloadedItems + 1,
        });

        return from(this._databaseDataService.saveBrand(brand));
      }),
      map((): void => void 0)
    );
  }

  // CUSTOM FIELDS 🎨

  private _preloadCustomFields$(): Observable<void> {
    return this._customFieldService.getClientsCustomFields$().pipe(
      switchMap((customFields: CustomFieldDTO[]): Observable<CustomFieldDTO[]> => {
        if (customFields.length === 0) {
          return of([]);
        }

        return combineLatest(
          customFields.map((customField: CustomFieldDTO): Observable<CustomFieldDTO> => {
            return from(this._databaseDataService.saveCustomField(customField));
          })
        );
      }),
      map((): void => void 0)
    );
  }

  // DEFECTS CHECKLISTS 🐛

  private _preloadDefectsChecklist$(): Observable<void> {
    return this._getChecklistsIdLastModifiedDateMap$(EBrdWorkflowChecklistConsultationType.DEFECTS).pipe(
      take(1),
      switchMap((defectsChecklistsMap: IdLastModifiedDateMapType): Observable<void[]> => {
        this._updatePreloadState(PreloadKey.DEFECTS_CHECKLIST, {
          items: defectsChecklistsMap.size,
          preloadedItems: 0,
        });

        return combineLatest(
          Array.from(defectsChecklistsMap).map(
            ([id, lastModifiedDate]): Observable<void> => this._preloadDefectsChecklistIfNotUpToDate$(id, lastModifiedDate)
          )
        );
      }),
      map((): void => void 0)
    );
  }

  private _preloadDefectsChecklistIfNotUpToDate$(checklistId: number, lastModifiedDate: string): Observable<void> {
    return from(this._databaseDataService.getDefectsChecklistById(checklistId)).pipe(
      filter((checklist: DefectsChecklistGetDTO | undefined): checklist is DefectsChecklistGetDTO => {
        const isNeedToBePreload = !!(isNil(checklist) || checklist.lastModifiedDate !== lastModifiedDate);

        if (!isNeedToBePreload) {
          this._updatePreloadState(PreloadKey.DEFECTS_CHECKLIST, {
            preloadedItems: this.preloadState$.value.defectsChecklist.preloadedItems + 1,
          });
        }

        return isNeedToBePreload;
      }),
      switchMap((): Observable<DefectsChecklistGetDTO> => this._defectsChecklistService.getDefectChecklist$(checklistId.toString())),
      switchMap((checklist: DefectsChecklistGetDTO): Observable<DefectsChecklistGetDTO | undefined> => {
        this._updatePreloadState(PreloadKey.DEFECTS_CHECKLIST, {
          preloadedItems: this.preloadState$.value.defectsChecklist.preloadedItems + 1,
        });

        return from(this._databaseDataService.saveDefectsChecklist(checklist));
      }),
      map((): void => void 0)
    );
  }

  // TESTS CHECKLIST 📝

  private _preloadTestsChecklist$(): Observable<void> {
    return this._getChecklistsIdLastModifiedDateMap$(EBrdWorkflowChecklistConsultationType.TESTS).pipe(
      take(1),
      switchMap((testsChecklistsMap: IdLastModifiedDateMapType): Observable<void[]> => {
        this._updatePreloadState(PreloadKey.TESTS_CHECKLIST, {
          items: testsChecklistsMap.size,
          preloadedItems: 0,
        });

        return combineLatest(
          Array.from(testsChecklistsMap).map(
            ([id, lastModifiedDate]): Observable<void> => this._preloadTestsChecklistIfNotUpToDate$(id, lastModifiedDate)
          )
        );
      }),
      map((): void => void 0)
    );
  }

  private _preloadTestsChecklistIfNotUpToDate$(checklistId: number, lastModifiedDate: string): Observable<void> {
    return from(this._databaseDataService.getTestsChecklistById(checklistId)).pipe(
      filter((checklist: TestsChecklistDTO | undefined): checklist is TestsChecklistDTO => {
        const isNeedToBePreload = !!(isNil(checklist) || checklist.lastModifiedDate !== lastModifiedDate);

        if (!isNeedToBePreload) {
          this._updatePreloadState(PreloadKey.TESTS_CHECKLIST, {
            preloadedItems: this.preloadState$.value.testsChecklist.preloadedItems + 1,
          });
        }

        return isNeedToBePreload;
      }),
      switchMap((): Observable<TestsChecklistDTO> => this._testsChecklistService.getTestsChecklistDTO$(checklistId)),
      switchMap((checklist: TestsChecklistDTO): Observable<TestsChecklistDTO | undefined> => {
        this._updatePreloadState(PreloadKey.TESTS_CHECKLIST, {
          preloadedItems: this.preloadState$.value.testsChecklist.preloadedItems + 1,
        });

        return from(this._databaseDataService.saveTestsChecklist(checklist));
      }),
      map((): void => void 0)
    );
  }

  // COMMON CHECKLIST 📝

  private _getChecklistsIdLastModifiedDateMap$(type: EBrdWorkflowChecklistConsultationType): Observable<IdLastModifiedDateMapType> {
    return this._getEntityIdLastModifiedDateMap$(
      (pageQuery, filtersAndSorts): Observable<IQpConsultationPage<BrdWorkflowChecklistConsultationType>> =>
        this._entityConsultationService.getChecklists$(pageQuery, filtersAndSorts),
      [
        {
          type: 'filter',
          value: `type,EQUALS,${type}`,
        },
        { type: 'sort', value: 'lastModifiedDate,DESC' },
      ]
    );
  }

  // WORKFLOW 🏗️

  private _preloadWorkflows$(): Observable<void> {
    return this._getWorkflowIdLastModifiedDateMap$().pipe(
      take(1),
      switchMap((workflowIdsMap: IdLastModifiedDateMapType): Observable<void[]> => {
        this._updatePreloadState(PreloadKey.WORKFLOW, {
          items: workflowIdsMap.size,
          preloadedItems: 0,
        });

        return combineLatest(
          Array.from(workflowIdsMap).map(
            ([id, lastModifiedDate]): Observable<void> => this._preloadWorkflowIfNotUpToDate$(id, lastModifiedDate)
          )
        );
      }),
      map((): void => void 0)
    );
  }

  private _getWorkflowIdLastModifiedDateMap$(): Observable<IdLastModifiedDateMapType> {
    return this._getEntityIdLastModifiedDateMap$(
      (pageQuery, filtersAndSorts): Observable<IQpConsultationPage<IWorkflowConsultation>> =>
        this._entityConsultationService.getWorkflows$(pageQuery, filtersAndSorts),
      [{ type: 'sort', value: 'lastModifiedDate,DESC' }]
    );
  }

  private _preloadWorkflowIfNotUpToDate$(workflowId: number, lastModifiedDate: string): Observable<void> {
    return from(this._databaseDataService.getWorkflowTemplateById(workflowId)).pipe(
      filter((w: WorkflowTemplateGetDTO | undefined): w is WorkflowTemplateGetDTO => {
        const isNeedToBePreload = !!(isNil(w) || w.lastModifiedDate !== lastModifiedDate);

        if (!isNeedToBePreload) {
          this._updatePreloadState(PreloadKey.WORKFLOW, {
            preloadedItems: this.preloadState$.value.workflow.preloadedItems + 1,
          });
        }

        return isNeedToBePreload;
      }),
      switchMap((): Observable<WorkflowTemplateGetDTO> => this._workflowTemplateService.getWorkflowTemplate$(workflowId)),
      switchMap((w: WorkflowTemplateGetDTO): Observable<WorkflowTemplateGetDTO | undefined> => {
        this._updatePreloadState(PreloadKey.WORKFLOW, {
          preloadedItems: this.preloadState$.value.workflow.preloadedItems + 1,
        });

        return from(this._databaseDataService.saveWorkflowTemplate(w));
      }),
      map((): void => void 0)
    );
  }

  // INSPECTION TYPE 🌈

  private _preloadInspectionTypes$(): Observable<InspectionTypeDTO[]> {
    return this._inspectionService.getAllInspectionTypes$().pipe(
      take(1),
      switchMap((inspectionTypes: InspectionTypeDTO[]): Observable<InspectionTypeDTO[]> => {
        this._updatePreloadState(PreloadKey.INSPECTION_TYPE, {
          items: inspectionTypes.length,
          preloadedItems: 0,
        });

        return combineLatest(
          inspectionTypes.map((inspectionType: InspectionTypeDTO): Observable<InspectionTypeDTO> => {
            return from(this._databaseDataService.saveInspectionType(inspectionType)).pipe(
              tap((): void => {
                this._updatePreloadState(PreloadKey.INSPECTION_TYPE, {
                  preloadedItems: this.preloadState$.value.inspectionType.preloadedItems + 1,
                });
              })
            );
          })
        );
      })
    );
  }

  private _preloadInspectionTypeSettings$(brandId: string | undefined): Observable<InspectionTypeSettingDTO[]> {
    if (isNil(brandId)) {
      return of([]);
    }

    return this._settingsInspectionTypesService.getInspectionTypes$(brandId).pipe(
      take(1),
      switchMap((inspectionTypeSetting: InspectionTypeSettingDTO[]): Observable<InspectionTypeSettingDTO[]> => {
        this._updatePreloadState(PreloadKey.INSPECTION_TYPE_SETTING, {
          items: inspectionTypeSetting.length,
          preloadedItems: 0,
        });

        return combineLatest(
          inspectionTypeSetting.map((inspectionTypeSetting: InspectionTypeSettingDTO): Observable<InspectionTypeSettingDTO> => {
            return from(this._databaseDataService.saveInspectionTypeSettings(inspectionTypeSetting)).pipe(
              tap((): void => {
                this._updatePreloadState(PreloadKey.INSPECTION_TYPE_SETTING, {
                  preloadedItems: this.preloadState$.value.inspectionTypeSetting.preloadedItems + 1,
                });
              })
            );
          })
        );
      })
    );
  }

  // PRODUCT & PO 📦

  private _preloadProductsAndPOs$(): Observable<(ProductDetailDTO | PurchaseOrderViewDTO | undefined)[]> {
    return forkJoin([this._getProductsIdLastModifiedDateMap$(), this._getPOsIdLastModifiedDateMap$()]).pipe(
      switchMap(([productsMap, posMap]): Observable<(ProductDetailDTO | PurchaseOrderViewDTO | undefined)[]> => {
        this._updatePreloadState(PreloadKey.PRODUCT, {
          items: productsMap.size,
          preloadedItems: 0,
        });

        this._updatePreloadState(PreloadKey.PO, {
          items: posMap.size,
          preloadedItems: 0,
        });

        return combineLatest([
          ...Array.from(productsMap).map(
            ([id, lastModifiedDate]): Observable<ProductDetailDTO | undefined> => this._preloadProductIfNotUpToDate$(id, lastModifiedDate)
          ),
          ...Array.from(posMap).map(
            ([id, lastModifiedDate]): Observable<PurchaseOrderViewDTO | undefined> => this._preloadPOIfNotUpToDate$(id, lastModifiedDate)
          ),
        ]);
      })
    );
  }

  private _getProductsIdLastModifiedDateMap$(): Observable<IdLastModifiedDateMapType> {
    return this._getEntityIdLastModifiedDateMap$(
      (pageQuery, filtersAndSorts): Observable<IQpConsultationPage<IBrdProductConsultation>> =>
        this._entityConsultationService.getProducts$(pageQuery, filtersAndSorts),
      [{ type: 'sort', value: 'lastModifiedDate,DESC' }]
    );
  }

  private _getPOsIdLastModifiedDateMap$(): Observable<IdLastModifiedDateMapType> {
    return this._getEntityIdLastModifiedDateMap$(
      (pageQuery, filtersAndSorts): Observable<IQpConsultationPage<IPurchaseOrderInfo>> =>
        this._entityConsultationService.getPurchaseOrders$(pageQuery, filtersAndSorts),
      [
        {
          type: 'filter',
          value: `lastModifiedDate,BETWEEN,[${this._oneMonthAgoDateParams},${this._tomorowDateParams},${QpDateService.timezone}]`,
        },
        { type: 'sort', value: 'lastModifiedDate,DESC' },
      ]
    );
  }

  private _preloadProductIfNotUpToDate$(productId: number, lastModifiedDate: string): Observable<ProductDetailDTO | undefined> {
    return from(this._databaseDataService.getProduct(productId)).pipe(
      filter((product: ProductDetailDTO | undefined): product is ProductDetailDTO => {
        const isNeedToBePreload = !!(isNil(product) || product.lastModifiedDate !== lastModifiedDate);

        if (!isNeedToBePreload) {
          this._updatePreloadState(PreloadKey.PRODUCT, {
            preloadedItems: this.preloadState$.value.product.preloadedItems + 1,
          });
        }

        return isNeedToBePreload;
      }),
      switchMap((): Observable<ProductDetailDTO> => this._productService.getProductDTOById$(productId)),
      switchMap((product: ProductDetailDTO): Observable<ProductDetailDTO> => {
        this._updatePreloadState(PreloadKey.PRODUCT, {
          preloadedItems: this.preloadState$.value.product.preloadedItems + 1,
        });

        return from(this._databaseDataService.saveProduct(product));
      })
    );
  }

  private _preloadPOIfNotUpToDate$(poId: number, lastModifiedDate: string): Observable<PurchaseOrderViewDTO | undefined> {
    return from(this._databaseDataService.getPurchaseOrder(poId)).pipe(
      filter((po: PurchaseOrderViewDTO | undefined): po is PurchaseOrderViewDTO => {
        const isNeedToBePreload = !!(isNil(po) || po.lastModifiedDate !== lastModifiedDate);

        if (!isNeedToBePreload) {
          this._updatePreloadState(PreloadKey.PO, {
            preloadedItems: this.preloadState$.value.po.preloadedItems + 1,
          });
        }

        return isNeedToBePreload;
      }),
      switchMap((): Observable<PurchaseOrderViewDTO> => this._purchaseOrderService.getPurchaseOrder$(poId)),
      switchMap((po: PurchaseOrderViewDTO): Observable<PurchaseOrderViewDTO | undefined> => {
        this._updatePreloadState(PreloadKey.PO, {
          preloadedItems: this.preloadState$.value.po.preloadedItems + 1,
        });

        return from(this._databaseDataService.savePurchaseOrder(po));
      })
    );
  }

  private _updatePreloadState(key: PreloadKey, state: Partial<PreloadItemState>): void {
    this.preloadState$.next({
      ...this.preloadState$.value,
      [key]: {
        ...this.preloadState$.value[key],
        ...state,
      },
    });
  }

  private _updateLastSync(): void {
    localStorage.setItem(this.LOCAL_STORAGE_LAST_PRELOADING_TIME_KEY, this._qpDateService.getTimestamp().toString());

    this._getlastSynchronization$()
      .pipe(take(1), untilDestroyed(this))
      .subscribe((lastSynchronization: string): void => {
        this._lastSynchronizationBehaviorSubject.next(lastSynchronization);
      });
  }

  private _getlastSynchronization$(): Observable<string> {
    const lastPreloadingTime = localStorage.getItem(this.LOCAL_STORAGE_LAST_PRELOADING_TIME_KEY);

    if (!lastPreloadingTime) {
      return of('');
    }

    return this._accountService.accountLangKey$().pipe(
      take(1),
      map((langKey: string): string => {
        return this._translateService.instant('isp-inspection-preload.last-synchronisation', {
          time: this._getHumanizeTimeDiff(this._qpDateService.getTimestamp(), parseInt(lastPreloadingTime), langKey),
        });
      })
    );
  }

  private _getHumanizeTimeDiff(currentTimestamp, compareTimestamp, locale: string): string {
    return this._qpDateService.getDateDiffDuration(new Date(currentTimestamp), new Date(compareTimestamp)).locale(locale).humanize();
  }

  private _getEntityIdLastModifiedDateMap$(
    // eslint-disable-next-line rxjs/finnish
    getEntity$: (
      params: { page: number; size: number },
      queryParams: IQpDatatableFilterAndSortParam[]
    ) => Observable<IQpConsultationPage<PreloadItem>>,
    params: IQpDatatableFilterAndSortParam[] = [],
    maxItems: number = MAX_ITEMS_TO_PRELOAD
  ): Observable<IdLastModifiedDateMapType> {
    return getEntity$({ page: 0, size: this._defaultEntityPageSize }, params).pipe(
      switchMap((data: IQpConsultationPage<PreloadItem>): Observable<IQpConsultationPage<PreloadItem>[]> => {
        if (data.pageSize === 1) {
          return of([data]);
        }

        const requestTodo: Observable<IQpConsultationPage<PreloadItem>>[] = [of(data)];

        for (let i = 1; i < data.totalPageCount && i * this._defaultEntityPageSize <= maxItems; i++) {
          requestTodo.push(getEntity$({ page: i, size: this._defaultEntityPageSize }, params));
        }

        return combineLatest(requestTodo);
      }),
      map((data: IQpConsultationPage<PreloadItem>[]): IdLastModifiedDateMapType => {
        const map: IdLastModifiedDateMapType = new Map();

        data.forEach((page: IQpConsultationPage<PreloadItem>): void => {
          page.items.forEach((item: PreloadItem): void => {
            if (isObject(item) && 'id' in item && 'lastModifiedDate' in item && isNumber(item.id) && item.lastModifiedDate) {
              map.set(item.id, item.lastModifiedDate.toString());
            } else {
              console.error(`Cannot preload ${item} because it's not a valid entity`);
            }
          });
        });

        return map;
      })
    );
  }

  private _endPreloadOperation$<T>(operation: Observable<T>): Observable<T | void> {
    return operation.pipe(
      take(1),
      catchError((err): Observable<void> => {
        console.error(err);

        return of(void 0);
      }),
      defaultIfEmpty(void 0)
    );
  }
}

export enum PreloadKey {
  PRODUCT = 'product',
  PO = 'po',
  INSPECTION_TYPE = 'inspectionType',
  INSPECTION_TYPE_SETTING = 'inspectionTypeSetting',
  WORKFLOW = 'workflow',
  DEFECTS_CHECKLIST = 'defectsChecklist',
  TESTS_CHECKLIST = 'testsChecklist',
  CLIENT = 'client',
}

export type PreloadState = {
  [key in PreloadKey]: PreloadItemState;
};
