import { IspMeasuresSampleAnswer } from '@one/app/pages/isp/shared/classes/isp-measures-sample-answer/isp-measures-sample-answer';
import {
  IMeasurementProduct,
  IMeasuresChecklist,
  IMeasuresChecklistSection,
  IMeasuresSampleAnswer,
  IVariance,
} from '@one/app/pages/isp/shared/models/variances/inspection-variances.models';
import { Injectable } from '@angular/core';
import { size } from 'lodash/index';

@Injectable({
  providedIn: 'root',
})
export class InspectionProductMeasurementService {
  public static getTotalPointOfMeasureByProduct(productMeasure: IMeasurementProduct): number {
    let numberOfPointOfMeasure = 0;

    productMeasure.variances.forEach((variance): void => {
      variance.measuresEntries.forEach((measuresSampleAnswer): void => {
        numberOfPointOfMeasure += IspMeasuresSampleAnswer.fromIMeasuresSampleAnswer(measuresSampleAnswer).nbMeasuresLeft;
      });
    });

    return numberOfPointOfMeasure;
  }

  public static getTotalPointOfMeasureByMeasurementSection(measureChecklist: IMeasuresChecklistSection | IMeasuresChecklist): number {
    let numberOfPointOfMeasure = 0;

    measureChecklist.variances.forEach((variance): void => {
      variance.measuresEntries.forEach((measuresSampleAnswer): void => {
        numberOfPointOfMeasure += IspMeasuresSampleAnswer.fromIMeasuresSampleAnswer(measuresSampleAnswer).nbMeasuresLeft;
      });
    });

    return numberOfPointOfMeasure;
  }

  public static getNumberOfSamplesByProduct(variances: IVariance[]): number {
    return variances.reduce((numberOfSample: number, variance: IVariance): number => {
      return numberOfSample + size(variance?.measuresEntries);
    }, 0);
  }

  public static getNumberOfMeasurePointsByProduct(variances: IVariance[]): number {
    return (
      variances.reduce(
        (numberOfMeasurePoint: number, variance: IVariance): number =>
          numberOfMeasurePoint + InspectionProductMeasurementService._getNumberOfMeasureAnswers(variance),
        0
      ) ?? 0
    );
  }

  private static _getNumberOfMeasureAnswers(variance: IVariance): number {
    return (
      variance?.measuresEntries.reduce(
        (numberOfMeasureAnswers: number, measureEntry: IMeasuresSampleAnswer): number =>
          numberOfMeasureAnswers + size(measureEntry.measureAnswers),
        0
      ) ?? 0
    );
  }
}
