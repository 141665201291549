import { IQpPageQuery } from '@library/classes/qp-page/qp-page.models';
import { IQpDatatableFilterAndSortParam } from '@library/components/qp-datatable/qp-datatable.models';
import { EQpIconName } from '@library/components/qp-icon/qp-icon.models';
import { ProductDetailDTO } from '@library/dto/product-detail.dto';
import {
  ProductPostRequestDTO,
  ProductPutRequestDTO,
  ProductPostResponseDTO,
  IProductPutResponseDTO,
  IProductSpecification,
} from '@library/dto/product.dto';
import { qpAsHttpParams } from '@library/functions/qp-as-http-params/qp-as-http-params';
import { QpConsultationPage, IQpConsultationPage } from '@library/models/qp-consultation-page.models';
import { EQpProfile } from '@library/models/qp-profile.models';
import { IQpDisplayedRoute, QpDisplayedRoute } from '@library/models/qp-router.models';
import { QpDateService } from '@library/services/qp-date/qp-date.service';
import { SERVER_API_URL } from '@one/app/app.constants';
import { BrdRoutes } from '@one/app/pages/brd/brd-routes';
import { IBrdProductLinkedToPurchaseOrder } from '@one/app/pages/brd/pages/inspection/shared/components/brd-inspection-product-tab/brd-inspection-product-tab.models';
import { IInternalPurchaseOrderProduct } from '@one/app/pages/brd/pages/inspection/store-features/inspection-booking/brd-inspection-booking.models';
import { mapDTOBrdPoProductToInternalPoProduct } from '@one/app/pages/brd/pages/inspection/store-features/inspection-booking/brd-inspection-booking.utils';
import { IPurchaseOrderInfo } from '@one/app/pages/brd/pages/product-po/pages/po/models/purchase-orders.model';
import { IBrdProductConsultation } from '@one/app/pages/brd/shared/models/brd-product-consult.model';
import { ISpecificationEntry } from '@one/app/shared/models/products/criteria.models';
import { IProduct, ISpecificationData } from '@one/app/shared/models/products/product.models';
import { DisplayedRoutesService } from '@one/app/shared/services/router/displayed-routes.service';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QimaOptionalType } from '@qima/ngx-qima';
import { isNil } from 'lodash/index';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

type EntityResponseType = HttpResponse<IProduct>;

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  public mainProductSelectedSubject$ = new BehaviorSubject<IProduct | null>(null);

  private readonly _resourceUrl = `${SERVER_API_URL}api/products`;
  private readonly _poResourceUrl = `${SERVER_API_URL}api/purchase-order-products`;

  public constructor(protected _httpClient: HttpClient, private readonly _qpDateService: QpDateService) {}

  public createProduct$(product: IProduct, photo: QimaOptionalType<File>): Observable<EntityResponseType> {
    const formData = new FormData();

    formData.append('product', JSON.stringify(this._adaptSpecification(product as ProductPostRequestDTO)));

    if (photo) {
      formData.append('file', photo, photo.name);
    }

    return this._httpClient.post<ProductPostResponseDTO>(this._resourceUrl, formData, { observe: 'response' });
  }

  public updateProduct$(product: IProduct, photo: QimaOptionalType<File>): Observable<EntityResponseType> {
    const formData = new FormData();

    formData.append('product', JSON.stringify(this._adaptSpecification(product as ProductPutRequestDTO)));

    if (photo) {
      formData.append('file', photo, photo.name);
    }

    return this._httpClient.put<IProductPutResponseDTO>(`${this._resourceUrl}/${product.id}`, formData, { observe: 'response' });
  }

  public getAllProducts$(): Observable<IProduct[]> {
    return this._httpClient.get<IProduct[]>(this._resourceUrl, { observe: 'body' });
  }

  public getAllProductsWithInspectionDate$(pageQuery: IQpPageQuery): Observable<HttpResponse<IBrdProductConsultation[]>> {
    const params = new HttpParams()
      .set('usage', 'LISTING')
      .set('page', String(pageQuery.page))
      .set('size', String(pageQuery.size))
      .set('sort', 'identifierValue,desc');

    return this._httpClient.get<IBrdProductConsultation[]>(`${this._resourceUrl}/search`, {
      params,
      observe: 'response',
    });
  }

  public getProductsByParams$(params: HttpParams): Observable<IBrdProductConsultation[]> {
    return this._httpClient.get<IBrdProductConsultation[]>(`${this._resourceUrl}/search`, { params });
  }

  public getAllProductsAsPage$(pageQuery: IQpPageQuery): Observable<IQpConsultationPage<IBrdProductConsultation>> {
    let { page } = pageQuery;

    if (page !== undefined) {
      page += 1;
    }

    return this.getAllProductsWithInspectionDate$(pageQuery).pipe(
      map((response): IQpConsultationPage<IBrdProductConsultation> => {
        const items = response.body ?? [];
        const totalItems: number = parseInt(response.headers.get('X-Total-Count') || '0', 10);
        const totalPageCount: number = parseInt(response.headers.get('X-Total-Page') || '0', 10);

        return QpConsultationPage.create<IBrdProductConsultation>({
          items,
          page,
          pageSize: pageQuery.size,
          totalItems,
          totalPageCount,
        });
      })
    );
  }

  public getPurchaseOrdersByProduct$(productId: number, pageQuery: IQpPageQuery): Observable<IQpConsultationPage<IPurchaseOrderInfo>> {
    const params = new HttpParams().set('page', String(pageQuery.page)).set('size', String(pageQuery.size));

    return this._httpClient
      .get<IPurchaseOrderInfo[]>(`${this._resourceUrl}/${productId}/purchase-orders`, { params, observe: 'response' })
      .pipe(
        map((response: Readonly<HttpResponse<IPurchaseOrderInfo[]>>): IQpConsultationPage<IPurchaseOrderInfo> => {
          const purchaseOrders: IPurchaseOrderInfo[] = response.body ?? [];
          const totalItems: number = parseInt(response.headers.get('X-Total-Count') || '0', 10);
          const totalPageCount: number = parseInt(response.headers.get('X-Total-Page') || '0', 10);

          return QpConsultationPage.create({
            items: purchaseOrders,
            page: pageQuery.page + 1,
            pageSize: pageQuery.size,
            totalItems,
            totalPageCount,
          });
        })
      );
  }

  public getSpecificationList$(): Observable<ISpecificationEntry[]> {
    return this._httpClient.get<ISpecificationEntry[]>(`${this._resourceUrl}/identifiers`);
  }

  public getProductById$(id: Readonly<string> | Readonly<number>): Observable<IProduct> {
    const url = `${this._resourceUrl}/${id}`;

    return this._getProduct$(url);
  }

  public getProductDTOById$(id: Readonly<string> | Readonly<number>): Observable<ProductDetailDTO> {
    const url = `${this._resourceUrl}/${id}`;

    return this._httpClient.get<ProductDetailDTO>(url, { observe: 'body' });
  }

  public loadProductByInput$(input: string): Observable<IBrdProductLinkedToPurchaseOrder[]> {
    const url = `${this._poResourceUrl}/search?input=${encodeURIComponent(input)}`;

    return this._httpClient
      .get<QimaOptionalType<IBrdProductLinkedToPurchaseOrder[]>>(url)
      .pipe(map((res): IBrdProductLinkedToPurchaseOrder[] => res ?? []));
  }

  public searchInProductLinkedToPurchaseOrderInspectionBooking$(
    productPurchaseOrderId: number,
    pageQuery: IQpPageQuery,
    filtersAndSortsParams: IQpDatatableFilterAndSortParam[]
  ): Observable<IQpConsultationPage<IInternalPurchaseOrderProduct>> {
    const url = `${this._poResourceUrl}?productPurchaseOrderId=${productPurchaseOrderId}`;
    const params = qpAsHttpParams(pageQuery, filtersAndSortsParams);

    return this._httpClient.get<IBrdProductLinkedToPurchaseOrder[]>(url, { params, observe: 'response' }).pipe(
      map((response): IQpConsultationPage<IInternalPurchaseOrderProduct> => {
        const items = response.body ?? [];
        const totalItems: number = parseInt(response.headers.get('X-Total-Count') || '0', 10);
        const totalPageCount: number = parseInt(response.headers.get('X-Total-Page') || '0', 10);

        return QpConsultationPage.create<IInternalPurchaseOrderProduct>({
          items: items.map(mapDTOBrdPoProductToInternalPoProduct),
          page: pageQuery.page + 1,
          pageSize: pageQuery.size,
          totalItems,
          totalPageCount,
        });
      })
    );
  }

  public initSubnavById(id: string, isServiceProvider: boolean, isUserEntity: boolean): IQpDisplayedRoute {
    let routes: QpDisplayedRoute;

    if (isServiceProvider) {
      routes = QpDisplayedRoute.create({
        title: 'home.navigation-menu.products-menu',
        routerLinkActiveOption: { exact: false },
        routerLink: BrdRoutes.productProduct(),
        profiles: [EQpProfile.ROLE_SERVICE_PROVIDER],
        children: DisplayedRoutesService.productInformation,
        iconName: EQpIconName.IC_PRODUCT,
        creationTitle: 'home.creation-titles.products-and-po',
        dataCy: 'products-nav-button',
      });
    } else {
      routes = QpDisplayedRoute.create({
        title: 'home.navigation-menu.products-menu',
        routerLinkActiveOption: { exact: false },
        routerLink: BrdRoutes.productPoProduct(),
        profiles: [EQpProfile.ROLE_BRAND, EQpProfile.ROLE_SUPERVISOR],
        children: isUserEntity
          ? DisplayedRoutesService.poProductInformation
          : DisplayedRoutesService.poProductInformation.concat(DisplayedRoutesService.poProductInformationLabTestReport),
        iconName: EQpIconName.IC_PRODUCT,
        creationTitle: 'home.creation-titles.products-and-po',
        dataCy: 'products-nav-button',
      });
    }

    routes.children = routes.children.map((route): IQpDisplayedRoute => {
      return {
        ...route,
        routerLink: route.routerLink.replace(':id', id),
      };
    });

    return routes;
  }

  public resolveSimplePhotoUrl(productId: number, productPhotoUuid: string): string {
    if (isNil(productPhotoUuid)) {
      throw new Error(`Product ${productId} has no photo uuid`);
    }

    return `${this._resourceUrl}/${productId}/photos/${productPhotoUuid}`;
  }

  public resolvePhotoUrl(product: Readonly<Pick<IProduct, 'photo' | 'id' | 'identifierType' | 'identifierValue'>>): string | never {
    if (isNil(product.photo)) {
      throw new Error(`Product ${product.id} (${product.identifierType}: ${product.identifierValue}) has no photo`);
    }

    if (isNil(product.photo?.uuid)) {
      throw new Error(`Product ${product.id} (${product.identifierType}: ${product.identifierValue}) has no photo uuid`);
    }

    return `${this._resourceUrl}/${product.id}/photos/${product.photo.uuid}`;
  }

  public deleteProduct$(id: Readonly<number>): Observable<void> {
    return this._httpClient.request<void>('delete', `${this._resourceUrl}/${id}`, {
      body: {
        deletionTimezone: this._qpDateService.getTimezone(),
        deletionDate: this._qpDateService.getDate(),
      },
    });
  }

  private _getProduct$(url: string): Observable<IProduct> {
    return this._httpClient.get<ProductDetailDTO>(url, { observe: 'body' }).pipe(
      map((productDTO: ProductDetailDTO): IProduct => {
        const iProduct: IProduct = Object.assign({ categoryId: undefined, categoryName: undefined }, productDTO);

        if (productDTO.category.id !== -1) {
          iProduct.categoryId = productDTO.category.id;
          iProduct.categoryName = productDTO.category.name;
        }

        return iProduct;
      })
    );
  }

  private _adaptSpecification<T extends ProductPostRequestDTO | ProductPutRequestDTO>(product: T): T {
    const specificationCriterion: IProductSpecification = {};
    const specificationAsArray: QimaOptionalType<ISpecificationData[]> = product.specification as unknown as ISpecificationData[];

    if (specificationAsArray?.length > 0) {
      specificationAsArray.forEach((specification): void => {
        specificationCriterion[specification['criterion']] = specification['value'];
      });
    }

    product.specification = specificationCriterion;

    return product;
  }
}
