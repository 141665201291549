import { InspectionStatus } from '@library/dto-enums/inspection-status.dto-enum';
import { qpIsNotNil } from '@library/functions/checks/qp-is-not-nil';
import { QpDateService } from '@library/services/qp-date/qp-date.service';
import { getInspectionIdOrUuid } from '@one/app/pages/isp/shared/functions/isp-get-inspection-id-or-uuid';
import { InspectionId } from '@one/app/pages/isp/shared/models/isp-inspection.models';
import { DatabaseService } from '@one/app/shared/services/database/database-inspection.service';
import { Injectable } from '@angular/core';
import { QimaOptionalType } from '@qima/ngx-qima';
import { firstValueFrom } from 'rxjs';

declare let daysBeforeCleanInspection: number;

@Injectable({
  providedIn: 'root',
})
export class IspInspectionCleanService {
  public DAYS_BEFORE_CLEAN;
  private readonly _completedStatuses: InspectionStatus[] = [
    InspectionStatus.COMPLETED,
    InspectionStatus.ACCEPTED_REPORT,
    InspectionStatus.REJECTED_REPORT,
  ];

  public constructor(private readonly _databaseService: DatabaseService, private readonly _qpDateService: QpDateService) {
    try {
      this.DAYS_BEFORE_CLEAN = daysBeforeCleanInspection;
    } catch (error) {
      this.DAYS_BEFORE_CLEAN = 7; // Default value if daysBeforeCleanInspection not set
    }
  }

  public async clean(): Promise<void> {
    await firstValueFrom(this._databaseService.isDBInit$);

    let inspections = await this._databaseService.getInspections();
    let ids: InspectionId[] = inspections
      .filter((insp): boolean => {
        if (!this._completedStatuses.includes(insp.status)) {
          return false;
        }

        if (this._qpDateService.howManyDaysAgo(insp.plannedDate) >= this.DAYS_BEFORE_CLEAN) {
          return true;
        }

        return false;
      })
      .map((insp): QimaOptionalType<InspectionId> => getInspectionIdOrUuid(insp))
      .filter(qpIsNotNil);

    ids = await this._returnInspectionIdsWithoutPendingRequests(ids);

    await this._databaseService.deleteInspectionsFiles(ids);
    await this._databaseService.deleteWorkflows(ids);
    await this._databaseService.deleteInspections(ids);

    await this._databaseService.deleteSyncedActionRequests(this.DAYS_BEFORE_CLEAN);

    inspections = await this._databaseService.getInspections();

    if (!inspections || inspections.length === 0) {
      // If no inspection -> delete generic files (file with inspection id set to 0)
      await this._databaseService.deleteInspectionsFiles([0]);
    }
  }

  public async cleanInspection(id: InspectionId): Promise<void> {
    await this._databaseService.deleteInspectionsFiles([id]);
    await this._databaseService.deleteWorkflows([id]);
    await this._databaseService.deleteInspections([id]);
  }

  private async _returnInspectionIdsWithoutPendingRequests(inspectionIds: InspectionId[]): Promise<InspectionId[]> {
    const areInspectionsWithPendingRequestPromises = inspectionIds.map(
      (inspectionId): Promise<boolean> => this._databaseService.hasPendingRequests(inspectionId)
    );
    const areInspectionsWithPendingRequest = await Promise.all(areInspectionsWithPendingRequestPromises);

    return inspectionIds.filter((_, index): boolean => !areInspectionsWithPendingRequest[index]);
  }
}
