import { IspWorkflowStepActionDefectsChecklist } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/isp-workflow-step-action-defects-checklist/isp-workflow-step-action-defects-checklist';
import { IspWorkflowStepGroupAction } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/isp-workflow-step-grouped-action';
import { EWorkflowActionType } from '@one/app/shared/models/workflow/workflow.models';

export class IspWorkflowStepGroupDefectsChecklistAction extends IspWorkflowStepGroupAction<IspWorkflowStepActionDefectsChecklist> {
  public get type(): EWorkflowActionType.DEFECTS_CHECKLIST {
    return EWorkflowActionType.DEFECTS_CHECKLIST;
  }

  public constructor(actions: IspWorkflowStepActionDefectsChecklist[]) {
    super(actions);
  }
}
