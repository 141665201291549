<div class="one-blur-check-progress-modal">
  <img
    class="one-blur-check-progress-modal__illustration"
    src="assets/pictures/illustrations-squares-photos.svg"
    [alt]="'modal.blur-check.illustr-alt' | translate"
  />
  <h2>{{ 'modal.blur-check.title' | translate }}</h2>
  <p class="one-blur-check-progress-modal__description">{{ 'modal.blur-check.description' | translate }}</p>
  <qp-progress-bar
    class="one-blur-check-progress-modal__progress-bar"
    [value]="percentage"
    [color]="progressBarColors.SUCCESS"
  ></qp-progress-bar>
  <p class="one-blur-check-progress-modal__counter">{{ 'modal.blur-check.counter' | translate: { index: done, total: total } }}</p>
</div>
