import { EQpLocale } from '@library/models/i18n/qp-locales.models';
import { registerLocaleData } from '@angular/common';
import { Injectable } from '@angular/core';

/**
 * @description
 * Internal service
 * Exceptionally provided in root
 */
@Injectable({
  providedIn: 'root',
})
export class QpLocaleLoaderService {
  private readonly _localeFileLoader: { [key in EQpLocale]: () => Promise<void> } = {
    [EQpLocale.BANGLADESHI]: (): Promise<void> => {
      return this._loadBangladeshiFile();
    },
    [EQpLocale.ENGLISH](): Promise<void> {
      // Angular embed by default the `en` locale so no need to load it once again
      return Promise.resolve();
    },
    [EQpLocale.ENGLISH_PRODUCE](): Promise<void> {
      // Angular embed by default the `en` locale so no need to load it once again
      return Promise.resolve();
    },
    [EQpLocale.SPANISH]: (): Promise<void> => {
      return this._loadSpanishFile();
    },
    [EQpLocale.SPANISH_PRODUCE]: (): Promise<void> => {
      return this._loadSpanishFile();
    },
    [EQpLocale.FRENCH]: (): Promise<void> => {
      return this._loadFrenchFile();
    },
    [EQpLocale.GERMAN]: (): Promise<void> => {
      return this._loadGermanFile();
    },
    [EQpLocale.JAPANESE]: (): Promise<void> => {
      return this._loadJapaneseFile();
    },
    [EQpLocale.KOREAN]: (): Promise<void> => {
      return this._loadKoreanFile();
    },
    [EQpLocale.INDIAN]: (): Promise<void> => {
      return this._loadIndianFile();
    },
    [EQpLocale.PORTUGUESE]: (): Promise<void> => {
      return this._loadPortugueseFile();
    },
    [EQpLocale.THAI]: (): Promise<void> => {
      return this._loadThaiFile();
    },
    [EQpLocale.VIETNAMESE]: (): Promise<void> => {
      return this._loadVietnameseFile();
    },
    [EQpLocale.CHINESE]: (): Promise<void> => {
      return this._loadChineseFile();
    },
    [EQpLocale.EN_ACTION](): Promise<void> {
      return Promise.resolve();
    },
  };

  public async loadFile(localeId: Readonly<EQpLocale>): Promise<void> {
    return this._localeFileLoader[localeId]();
  }

  private _loadBangladeshiFile(): Promise<void> {
    return import(
      /* webpackInclude: /bn\.mjs/ */
      /* webpackMode: "lazy" */
      /* webpackChunkName: "i18n-base-bn" */
      `@/../@angular/common/locales/bn.mjs`
    ).then((module): void => registerLocaleData(module.default));
  }

  private _loadSpanishFile(): Promise<void> {
    return import(
      /* webpackInclude: /es\.mjs/ */
      /* webpackMode: "lazy" */
      /* webpackChunkName: "i18n-base-es" */
      `@/../@angular/common/locales/es.mjs`
    ).then((module): void => registerLocaleData(module.default));
  }

  private _loadFrenchFile(): Promise<void> {
    return import(
      /* webpackInclude: /fr\.mjs/ */
      /* webpackMode: "lazy" */
      /* webpackChunkName: "i18n-base-fr" */
      `@/../@angular/common/locales/fr.mjs`
    ).then((module): void => registerLocaleData(module.default));
  }

  private _loadGermanFile(): Promise<void> {
    return import(
      /* webpackInclude: /de\.mjs/ */
      /* webpackMode: "lazy" */
      /* webpackChunkName: "i18n-base-de" */
      `@/../@angular/common/locales/de.mjs`
    ).then((module): void => registerLocaleData(module.default));
  }

  private _loadJapaneseFile(): Promise<void> {
    return import(
      /* webpackInclude: /ja\.mjs/ */
      /* webpackMode: "lazy" */
      /* webpackChunkName: "i18n-base-ja" */
      `@/../@angular/common/locales/ja.mjs`
    ).then((module): void => registerLocaleData(module.default));
  }

  private _loadKoreanFile(): Promise<void> {
    return import(
      /* webpackInclude: /ko\.mjs/ */
      /* webpackMode: "lazy" */
      /* webpackChunkName: "i18n-base-ko" */
      `@/../@angular/common/locales/ko.mjs`
    ).then((module): void => registerLocaleData(module.default));
  }

  private _loadIndianFile(): Promise<void> {
    return import(
      /* webpackInclude: /hi\.mjs/ */
      /* webpackMode: "lazy" */
      /* webpackChunkName: "i18n-base-hi" */
      `@/../@angular/common/locales/hi.mjs`
    ).then((module): void => registerLocaleData(module.default));
  }

  private _loadPortugueseFile(): Promise<void> {
    return import(
      /* webpackInclude: /pt\.mjs/ */
      /* webpackMode: "lazy" */
      /* webpackChunkName: "i18n-base-pt" */
      `@/../@angular/common/locales/pt.mjs`
    ).then((module): void => registerLocaleData(module.default));
  }

  private _loadThaiFile(): Promise<void> {
    return import(
      /* webpackInclude: /ta\.mjs/ */
      /* webpackMode: "lazy" */
      /* webpackChunkName: "i18n-base-ta" */
      `@/../@angular/common/locales/ta.mjs`
    ).then((module): void => registerLocaleData(module.default));
  }

  private _loadVietnameseFile(): Promise<void> {
    return import(
      /* webpackInclude: /vi\.mjs/ */
      /* webpackMode: "lazy" */
      /* webpackChunkName: "i18n-base-vi" */
      `@/../@angular/common/locales/vi.mjs`
    ).then((module): void => registerLocaleData(module.default));
  }

  private _loadChineseFile(): Promise<void> {
    return import(
      /* webpackInclude: /zh\.mjs/ */
      /* webpackMode: "lazy" */
      /* webpackChunkName: "i18n-base-zh" */
      `@/../@angular/common/locales/zh.mjs`
    ).then((module): void => registerLocaleData(module.default));
  }
}
