import { IQpExpertise } from '@library/components/qp-expertise/qp-expertise.models';
import { IQpAddress } from '@library/models/address/qp-address.model';
import { EQpLocale } from '@library/models/i18n/qp-locales.models';
import { EQpProfile } from '@library/models/qp-profile.models';
import { QpSafeUrlType } from '@library/models/qp-url.models';
import { IAccount } from '@one/app/shared/models/account/account.models';
import { Permission } from '@one/app/shared/models/permission/permission.models';

export class Account implements IAccount {
  public static create(account: Readonly<IAccount>): Account {
    return new Account(
      account.id,
      account.activated,
      account.profiles,
      account.email,
      account.firstName,
      account.langKey,
      account.lastName,
      account.hash,
      account.login,
      account.imageUrl,
      account.position,
      account.mobile,
      account.phone,
      account.localName,
      account.clientName,
      account.companyName,
      account.permissions,
      account.notifuseUserHash,
      account.notifuseUserId,
      account.brandId,
      account.factoryId,
      account.role,
      account.inspectorInfo,
      account.createdDate,
      account.createdBy
    );
  }

  public constructor(
    public id: number,
    public activated: boolean,
    public profiles: EQpProfile[],
    public email: string,
    public firstName: string,
    public langKey: EQpLocale,
    public lastName: string,
    public hash: string,
    public login: string,
    public imageUrl: string | QpSafeUrlType | undefined,
    public position: string,
    public mobile: string,
    public phone: string,
    public localName: string,
    public clientName: string,
    public companyName: string,
    public permissions: Permission[],
    public notifuseUserHash?: string,
    public notifuseUserId?: string,
    public brandId?: string,
    public factoryId?: string,
    public role?: string,
    public inspectorInfo?: {
      address?: IQpAddress;
      expertises?: IQpExpertise[];
    },
    public createdDate?: Date,
    public createdBy?: number
  ) {}

  public hasInspectorRole(): boolean {
    return this._hasThisProfile(EQpProfile.ROLE_INSPECTOR);
  }

  public hasServiceProviderInspectorRole(): boolean {
    return this._hasThisProfile(EQpProfile.ROLE_SERVICE_PROVIDER_INSPECTOR);
  }

  public hasAnyInspectorRole(): boolean {
    return this.hasInspectorRole() || this.hasServiceProviderInspectorRole();
  }

  public hasAuditorRole(): boolean {
    return this._hasThisProfile(EQpProfile.ROLE_AUDITOR);
  }

  public hasBrandRole(): boolean {
    return this._hasThisProfile(EQpProfile.ROLE_BRAND);
  }

  public hasSupervisorRole(): boolean {
    return this._hasThisProfile(EQpProfile.ROLE_SUPERVISOR);
  }

  public hasFactoryRole(): boolean {
    return this._hasThisProfile(EQpProfile.ROLE_ENTITY);
  }

  public hasServiceProviderRole(): boolean {
    return this._hasThisProfile(EQpProfile.ROLE_SERVICE_PROVIDER);
  }

  private _hasThisProfile(profile: Readonly<EQpProfile>): boolean {
    return this.profiles.includes(profile);
  }
}
