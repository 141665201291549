import { QP_LOGGER_CONFIG } from '@library/tokens/qp-logger-config.token';
import { qpLoggerConfigFactory } from '@one/app/shared/factories/qp-logger-config.factory';
import { WINDOW } from '@one/app/shared/tokens/window.token';
import { FactoryProvider } from '@angular/core';

export const QP_LOGGER_CONFIG_PROVIDER: FactoryProvider = {
  deps: [WINDOW],
  provide: QP_LOGGER_CONFIG,
  useFactory: qpLoggerConfigFactory,
};
