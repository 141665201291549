import { AqlValue } from '@library/dto-enums/aql-value.dto-enum';
import { EQpAQLInspectionLevel } from '@library/models/qp-aql.models';
import { SERVER_API_URL } from '@one/app/app.constants';
import { Pair, TwoKeysMap } from '@one/app/shared/classes/collection';
import { AqlDefects } from '@one/app/shared/classes/defects/aql-defects';
import { FixedQuantityAqlDefects } from '@one/app/shared/classes/defects/fixed-quantity-aql-defects';
import { AqlDefectsType } from '@one/app/shared/models/defects/aql-defects.models';
import { MeasurementSamplingSizeAqlType } from '@one/app/shared/models/sampling/sampling-size.models';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * @deprecated
 * Use instead {@link QpAql}
 */
@Injectable({
  providedIn: 'root',
})
export class AqlService {
  // Visible for testing
  // value '1' is a non-standard value
  public static readonly validSamplingSizes: number[] = [1, 2, 3, 5, 8, 13, 20, 32, 50, 80, 125, 200, 315, 500, 800, 1250, 2000];

  public static readonly DEFAULT_QUANTITY_AQL_SAMPLE_SIZE = 0;

  public static readonly DEFAULT_DEFECTS_INSPECTION_LEVEL: EQpAQLInspectionLevel = EQpAQLInspectionLevel.II;

  public static readonly DEFAULT_DEFECTS_AQL: AqlDefects = new AqlDefects({
    critical: AqlValue.NOT_ALLOWED,
    major: AqlValue.AQL_2_5,
    minor: AqlValue.AQL_4_0,
  });

  public static readonly DEFAULT_FIXED_DEFECTS_AQL: FixedQuantityAqlDefects = new FixedQuantityAqlDefects({
    critical: 0,
    major: 0,
    minor: 0,
    sampleSize: 1,
  });

  private static readonly _aqlValuesMap = new TwoKeysMap<string, number, Pair<number, number>>([
    [AqlValue.AQL_0_065, 0, Pair.of(0, 1)], // Non standard
    [AqlValue.AQL_0_065, 1, Pair.of(0, 1)], // Non standard
    [AqlValue.AQL_0_065, 2, Pair.of(0, 1)],
    [AqlValue.AQL_0_065, 3, Pair.of(0, 1)],
    [AqlValue.AQL_0_065, 5, Pair.of(0, 1)],
    [AqlValue.AQL_0_065, 8, Pair.of(0, 1)],
    [AqlValue.AQL_0_065, 13, Pair.of(0, 1)],
    [AqlValue.AQL_0_065, 20, Pair.of(0, 1)],
    [AqlValue.AQL_0_065, 32, Pair.of(0, 1)],
    [AqlValue.AQL_0_065, 50, Pair.of(0, 1)],
    [AqlValue.AQL_0_065, 80, Pair.of(0, 1)],
    [AqlValue.AQL_0_065, 125, Pair.of(0, 1)],
    [AqlValue.AQL_0_065, 200, Pair.of(0, 1)],
    [AqlValue.AQL_0_065, 315, Pair.of(0, 1)],
    [AqlValue.AQL_0_065, 500, Pair.of(1, 2)],
    [AqlValue.AQL_0_065, 800, Pair.of(1, 2)],
    [AqlValue.AQL_0_065, 1250, Pair.of(2, 3)],
    [AqlValue.AQL_0_065, 2000, Pair.of(3, 4)],

    [AqlValue.AQL_0_1, 0, Pair.of(0, 1)], // Non standard
    [AqlValue.AQL_0_1, 1, Pair.of(0, 1)], // Non standard
    [AqlValue.AQL_0_1, 2, Pair.of(0, 1)],
    [AqlValue.AQL_0_1, 3, Pair.of(0, 1)],
    [AqlValue.AQL_0_1, 5, Pair.of(0, 1)],
    [AqlValue.AQL_0_1, 8, Pair.of(0, 1)],
    [AqlValue.AQL_0_1, 13, Pair.of(0, 1)],
    [AqlValue.AQL_0_1, 20, Pair.of(0, 1)],
    [AqlValue.AQL_0_1, 32, Pair.of(0, 1)],
    [AqlValue.AQL_0_1, 50, Pair.of(0, 1)],
    [AqlValue.AQL_0_1, 80, Pair.of(0, 1)],
    [AqlValue.AQL_0_1, 125, Pair.of(0, 1)],
    [AqlValue.AQL_0_1, 200, Pair.of(0, 1)],
    [AqlValue.AQL_0_1, 315, Pair.of(1, 2)],
    [AqlValue.AQL_0_1, 500, Pair.of(1, 2)],
    [AqlValue.AQL_0_1, 800, Pair.of(2, 3)],
    [AqlValue.AQL_0_1, 1250, Pair.of(3, 4)],
    [AqlValue.AQL_0_1, 2000, Pair.of(5, 6)],

    [AqlValue.AQL_0_15, 0, Pair.of(0, 1)], // Non standard
    [AqlValue.AQL_0_15, 1, Pair.of(0, 1)], // Non standard
    [AqlValue.AQL_0_15, 2, Pair.of(0, 1)],
    [AqlValue.AQL_0_15, 3, Pair.of(0, 1)],
    [AqlValue.AQL_0_15, 5, Pair.of(0, 1)],
    [AqlValue.AQL_0_15, 8, Pair.of(0, 1)],
    [AqlValue.AQL_0_15, 13, Pair.of(0, 1)],
    [AqlValue.AQL_0_15, 20, Pair.of(0, 1)],
    [AqlValue.AQL_0_15, 32, Pair.of(0, 1)],
    [AqlValue.AQL_0_15, 50, Pair.of(0, 1)],
    [AqlValue.AQL_0_15, 80, Pair.of(0, 1)],
    [AqlValue.AQL_0_15, 125, Pair.of(0, 1)],
    [AqlValue.AQL_0_15, 200, Pair.of(1, 2)],
    [AqlValue.AQL_0_15, 315, Pair.of(1, 2)],
    [AqlValue.AQL_0_15, 500, Pair.of(2, 3)],
    [AqlValue.AQL_0_15, 800, Pair.of(3, 4)],
    [AqlValue.AQL_0_15, 1250, Pair.of(5, 6)],
    [AqlValue.AQL_0_15, 2000, Pair.of(7, 8)],

    [AqlValue.AQL_0_25, 0, Pair.of(0, 1)], // Non standard
    [AqlValue.AQL_0_25, 1, Pair.of(0, 1)], // Non standard
    [AqlValue.AQL_0_25, 2, Pair.of(0, 1)],
    [AqlValue.AQL_0_25, 3, Pair.of(0, 1)],
    [AqlValue.AQL_0_25, 5, Pair.of(0, 1)],
    [AqlValue.AQL_0_25, 8, Pair.of(0, 1)],
    [AqlValue.AQL_0_25, 13, Pair.of(0, 1)],
    [AqlValue.AQL_0_25, 20, Pair.of(0, 1)],
    [AqlValue.AQL_0_25, 32, Pair.of(0, 1)],
    [AqlValue.AQL_0_25, 50, Pair.of(0, 1)],
    [AqlValue.AQL_0_25, 80, Pair.of(0, 1)],
    [AqlValue.AQL_0_25, 125, Pair.of(1, 2)],
    [AqlValue.AQL_0_25, 200, Pair.of(1, 2)],
    [AqlValue.AQL_0_25, 315, Pair.of(2, 3)],
    [AqlValue.AQL_0_25, 500, Pair.of(3, 4)],
    [AqlValue.AQL_0_25, 800, Pair.of(5, 6)],
    [AqlValue.AQL_0_25, 1250, Pair.of(7, 8)],
    [AqlValue.AQL_0_25, 2000, Pair.of(10, 11)],

    [AqlValue.AQL_0_4, 0, Pair.of(0, 1)], // Non standard
    [AqlValue.AQL_0_4, 1, Pair.of(0, 1)], // Non standard
    [AqlValue.AQL_0_4, 2, Pair.of(0, 1)],
    [AqlValue.AQL_0_4, 3, Pair.of(0, 1)],
    [AqlValue.AQL_0_4, 5, Pair.of(0, 1)],
    [AqlValue.AQL_0_4, 8, Pair.of(0, 1)],
    [AqlValue.AQL_0_4, 13, Pair.of(0, 1)],
    [AqlValue.AQL_0_4, 20, Pair.of(0, 1)],
    [AqlValue.AQL_0_4, 32, Pair.of(0, 1)],
    [AqlValue.AQL_0_4, 50, Pair.of(0, 1)],
    [AqlValue.AQL_0_4, 80, Pair.of(1, 2)],
    [AqlValue.AQL_0_4, 125, Pair.of(1, 2)],
    [AqlValue.AQL_0_4, 200, Pair.of(2, 3)],
    [AqlValue.AQL_0_4, 315, Pair.of(3, 4)],
    [AqlValue.AQL_0_4, 500, Pair.of(5, 6)],
    [AqlValue.AQL_0_4, 800, Pair.of(7, 8)],
    [AqlValue.AQL_0_4, 1250, Pair.of(10, 11)],
    [AqlValue.AQL_0_4, 2000, Pair.of(14, 15)],

    [AqlValue.AQL_0_65, 0, Pair.of(0, 1)], // Non standard
    [AqlValue.AQL_0_65, 1, Pair.of(0, 1)], // Non standard
    [AqlValue.AQL_0_65, 2, Pair.of(0, 1)],
    [AqlValue.AQL_0_65, 3, Pair.of(0, 1)],
    [AqlValue.AQL_0_65, 5, Pair.of(0, 1)],
    [AqlValue.AQL_0_65, 8, Pair.of(0, 1)],
    [AqlValue.AQL_0_65, 13, Pair.of(0, 1)],
    [AqlValue.AQL_0_65, 20, Pair.of(0, 1)],
    [AqlValue.AQL_0_65, 32, Pair.of(0, 1)],
    [AqlValue.AQL_0_65, 50, Pair.of(1, 2)],
    [AqlValue.AQL_0_65, 80, Pair.of(1, 2)],
    [AqlValue.AQL_0_65, 125, Pair.of(2, 3)],
    [AqlValue.AQL_0_65, 200, Pair.of(3, 4)],
    [AqlValue.AQL_0_65, 315, Pair.of(5, 6)],
    [AqlValue.AQL_0_65, 500, Pair.of(7, 8)],
    [AqlValue.AQL_0_65, 800, Pair.of(10, 11)],
    [AqlValue.AQL_0_65, 1250, Pair.of(14, 15)],
    [AqlValue.AQL_0_65, 2000, Pair.of(21, 22)],

    [AqlValue.AQL_1_0, 0, Pair.of(0, 1)], // Non standard
    [AqlValue.AQL_1_0, 1, Pair.of(0, 1)], // Non standard
    [AqlValue.AQL_1_0, 2, Pair.of(0, 1)],
    [AqlValue.AQL_1_0, 3, Pair.of(0, 1)],
    [AqlValue.AQL_1_0, 5, Pair.of(0, 1)],
    [AqlValue.AQL_1_0, 8, Pair.of(0, 1)],
    [AqlValue.AQL_1_0, 13, Pair.of(0, 1)],
    [AqlValue.AQL_1_0, 20, Pair.of(0, 1)],
    [AqlValue.AQL_1_0, 32, Pair.of(1, 2)],
    [AqlValue.AQL_1_0, 50, Pair.of(1, 2)],
    [AqlValue.AQL_1_0, 80, Pair.of(2, 3)],
    [AqlValue.AQL_1_0, 125, Pair.of(3, 4)],
    [AqlValue.AQL_1_0, 200, Pair.of(5, 6)],
    [AqlValue.AQL_1_0, 315, Pair.of(7, 8)],
    [AqlValue.AQL_1_0, 500, Pair.of(10, 11)],
    [AqlValue.AQL_1_0, 800, Pair.of(14, 15)],
    [AqlValue.AQL_1_0, 1250, Pair.of(21, 22)],
    [AqlValue.AQL_1_0, 2000, Pair.of(21, 22)],

    [AqlValue.AQL_1_5, 0, Pair.of(0, 1)], // Non standard
    [AqlValue.AQL_1_5, 1, Pair.of(0, 1)], // Non standard
    [AqlValue.AQL_1_5, 2, Pair.of(0, 1)],
    [AqlValue.AQL_1_5, 3, Pair.of(0, 1)],
    [AqlValue.AQL_1_5, 5, Pair.of(0, 1)],
    [AqlValue.AQL_1_5, 8, Pair.of(0, 1)],
    [AqlValue.AQL_1_5, 13, Pair.of(0, 1)],
    [AqlValue.AQL_1_5, 20, Pair.of(1, 2)],
    [AqlValue.AQL_1_5, 32, Pair.of(1, 2)],
    [AqlValue.AQL_1_5, 50, Pair.of(2, 3)],
    [AqlValue.AQL_1_5, 80, Pair.of(3, 4)],
    [AqlValue.AQL_1_5, 125, Pair.of(5, 6)],
    [AqlValue.AQL_1_5, 200, Pair.of(7, 8)],
    [AqlValue.AQL_1_5, 315, Pair.of(10, 11)],
    [AqlValue.AQL_1_5, 500, Pair.of(14, 15)],
    [AqlValue.AQL_1_5, 800, Pair.of(21, 22)],
    [AqlValue.AQL_1_5, 1250, Pair.of(21, 22)],
    [AqlValue.AQL_1_5, 2000, Pair.of(21, 22)],

    [AqlValue.AQL_2_5, 0, Pair.of(0, 1)], // Non standard
    [AqlValue.AQL_2_5, 1, Pair.of(0, 1)], // Non standard
    [AqlValue.AQL_2_5, 2, Pair.of(0, 1)],
    [AqlValue.AQL_2_5, 3, Pair.of(0, 1)],
    [AqlValue.AQL_2_5, 5, Pair.of(0, 1)],
    [AqlValue.AQL_2_5, 8, Pair.of(0, 1)],
    [AqlValue.AQL_2_5, 13, Pair.of(1, 2)],
    [AqlValue.AQL_2_5, 20, Pair.of(1, 2)],
    [AqlValue.AQL_2_5, 32, Pair.of(2, 3)],
    [AqlValue.AQL_2_5, 50, Pair.of(3, 4)],
    [AqlValue.AQL_2_5, 80, Pair.of(5, 6)],
    [AqlValue.AQL_2_5, 125, Pair.of(7, 8)],
    [AqlValue.AQL_2_5, 200, Pair.of(10, 11)],
    [AqlValue.AQL_2_5, 315, Pair.of(14, 15)],
    [AqlValue.AQL_2_5, 500, Pair.of(21, 22)],
    [AqlValue.AQL_2_5, 800, Pair.of(21, 22)],
    [AqlValue.AQL_2_5, 1250, Pair.of(21, 22)],
    [AqlValue.AQL_2_5, 2000, Pair.of(21, 22)],

    [AqlValue.AQL_4_0, 0, Pair.of(0, 1)], // Non standard
    [AqlValue.AQL_4_0, 1, Pair.of(0, 1)], // Non standard
    [AqlValue.AQL_4_0, 2, Pair.of(0, 1)],
    [AqlValue.AQL_4_0, 3, Pair.of(0, 1)],
    [AqlValue.AQL_4_0, 5, Pair.of(0, 1)],
    [AqlValue.AQL_4_0, 8, Pair.of(1, 2)],
    [AqlValue.AQL_4_0, 13, Pair.of(1, 2)],
    [AqlValue.AQL_4_0, 20, Pair.of(2, 3)],
    [AqlValue.AQL_4_0, 32, Pair.of(3, 4)],
    [AqlValue.AQL_4_0, 50, Pair.of(5, 6)],
    [AqlValue.AQL_4_0, 80, Pair.of(7, 8)],
    [AqlValue.AQL_4_0, 125, Pair.of(10, 11)],
    [AqlValue.AQL_4_0, 200, Pair.of(14, 15)],
    [AqlValue.AQL_4_0, 315, Pair.of(21, 22)],
    [AqlValue.AQL_4_0, 500, Pair.of(21, 22)],
    [AqlValue.AQL_4_0, 800, Pair.of(21, 22)],
    [AqlValue.AQL_4_0, 1250, Pair.of(21, 22)],
    [AqlValue.AQL_4_0, 2000, Pair.of(21, 22)],

    [AqlValue.AQL_6_5, 0, Pair.of(0, 1)], // Non standard
    [AqlValue.AQL_6_5, 1, Pair.of(0, 1)], // Non standard
    [AqlValue.AQL_6_5, 2, Pair.of(0, 1)],
    [AqlValue.AQL_6_5, 3, Pair.of(0, 1)],
    [AqlValue.AQL_6_5, 5, Pair.of(1, 2)],
    [AqlValue.AQL_6_5, 8, Pair.of(1, 2)],
    [AqlValue.AQL_6_5, 13, Pair.of(2, 3)],
    [AqlValue.AQL_6_5, 20, Pair.of(3, 4)],
    [AqlValue.AQL_6_5, 32, Pair.of(5, 6)],
    [AqlValue.AQL_6_5, 50, Pair.of(7, 8)],
    [AqlValue.AQL_6_5, 80, Pair.of(10, 11)],
    [AqlValue.AQL_6_5, 125, Pair.of(14, 15)],
    [AqlValue.AQL_6_5, 200, Pair.of(21, 22)],
    [AqlValue.AQL_6_5, 315, Pair.of(21, 22)],
    [AqlValue.AQL_6_5, 500, Pair.of(21, 22)],
    [AqlValue.AQL_6_5, 800, Pair.of(21, 22)],
    [AqlValue.AQL_6_5, 1250, Pair.of(21, 22)],
    [AqlValue.AQL_6_5, 2000, Pair.of(21, 22)],
  ]);

  public static getAcceptanceNumber(aql: MeasurementSamplingSizeAqlType, samplingSize: number): number | never {
    if (!AqlService.validSamplingSizes.includes(samplingSize)) {
      throw new Error(`Invalid sampling size ${samplingSize}`);
    }

    return AqlValue.NOT_ALLOWED === aql ? 0 : AqlService._aqlValuesMap.get(aql, samplingSize).left;
  }

  public static getAcceptanceNumberForFlexibleMeasurement(aql: number, samplingNumber: number): number {
    return Math.floor(samplingNumber * (aql / 100));
  }

  private readonly _resourceUrl = `${SERVER_API_URL}api/inspections`;

  public constructor(private readonly _httpClient: HttpClient) {}

  public updateAqlByInspectionId$(
    inspectionId: Readonly<number>,
    aqlDefects: Readonly<AqlDefectsType>,
    purchaseOrderProductId?: Readonly<number>
  ): Observable<Record<string, unknown>> {
    const url = `${this._resourceUrl}/${inspectionId}/aql`;

    return this._httpClient.put<Record<string, unknown>>(url, { ...aqlDefects, purchaseOrderProductId }, { observe: 'body' });
  }
}
