// @ts-strict-ignore
export class Pair<L, R> {
  public static of<L, R>(left, right): Pair<L, R> {
    return new Pair<L, R>(left, right);
  }

  public constructor(private readonly _left: L, private readonly _right: R) {}

  public get left(): L {
    return this._left;
  }

  public get right(): R {
    return this._right;
  }
}

export class TwoKeysMap<K1, K2, V> {
  private readonly _entries: Map<K1, Map<K2, V>> = new Map();

  public constructor(entries?: readonly (readonly [K1, K2, V])[] | null) {
    if (entries) {
      this._fillWith(entries);
    }
  }

  public put(key1: K1, key2: K2, value: V): void {
    if (!this._entries.has(key1)) {
      this._entries.set(key1, new Map());
    }

    this._entries.get(key1).set(key2, value);
  }

  public get(key1: K1, key2: K2): V {
    const key1Map = this._entries.get(key1);
    const key1Key2Value = key1Map ? key1Map.get(key2) : null;

    return key1Key2Value ? key1Key2Value : null;
  }

  private _fillWith(entries: readonly (readonly [K1, K2, V])[]): void {
    entries
      // 1st step create every map for K1 keys
      .map((entry): readonly [K1, K2, V] => {
        this._entries.set(entry[0], new Map());

        return entry;
      })
      .forEach((entry): Map<K2, V> => this._entries.get(entry[0]).set(entry[1], entry[2]));
  }
}
