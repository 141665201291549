import { AqlValue } from '@library/dto-enums/aql-value.dto-enum';
import { EQpAQLInspectionLevel } from '@library/models/qp-aql.models';
import { EQpHttpStatusCode } from '@library/models/qp-http.models';

export interface IHttpOptions {
  /**
   * @description
   * A list of status code that will be explicitly ignored by the interceptor
   * Useful if you need to skip the default behaviour for a specific HTTP request
   */
  ignoredInterceptorStatusCodes?: Set<EQpHttpStatusCode>;
}

export const HTTP_QIMA_ENDPOINT_NAME = 'HTTP_QIMA_ENDPOINT_NAME';

export enum EHttpQimaEndpoints {
  GET_SAMPLING_SIZES = 'get-sampling-sizes',
  GET_EXPECTED_SAMPLING_SIZES = 'get-expected-sampling-sizes',
  GET_AQL_SAMPLING_SIZES = 'get-aql-sampling-sizes',
  DELETE_TEST_CHECKLIST_TABLE_ROW = 'delete-test-checklist-table-row',
}

export interface IHttpGetSamplingSizesQueryParams {
  /**
   * @todo should be a number
   * @todo BUT right now, the {@link AqlValue} will be trim and so values like {@link AqlValue#AQL_0_1} will no longer be mapped correctly
   * @type {IHttpGetSamplingSizesQueryParams}
   */
  readonly lotSize: string;
  readonly inspectionLevel: EQpAQLInspectionLevel;
  readonly critical: AqlValue;
  readonly major: AqlValue;
  readonly minor: AqlValue;
}
