// @ts-strict-ignore
import { UpsertDocumentDTO } from '@library/dto/upsert-document.dto';
import { WorkflowDetailsDTO } from '@library/dto/workflow-details.dto';
import { InspectionMethod } from '@library/dto-enums/inspection-method.dto-enum';
import { InspectionStatus } from '@library/dto-enums/inspection-status.dto-enum';
import { IQpCoordinates } from '@library/models/qp-coordinates.models';
import { EQpMimeType } from '@library/models/qp-mime-type.models';
import { EQpProfile } from '@library/models/qp-profile.models';
import { EQpQuestionType } from '@library/models/qp-question.models';
import { IInspection, IInspector } from '@one/app/pages/brd/pages/inspection/pages/consult/brd-inspection-consult.component.models';
import { IBrdInspectionReferenceSample, ISampleCollection } from '@one/app/pages/brd/pages/inspection/shared/models/brd-inspection.models';
import { IEntity } from '@one/app/pages/brd/shared/models/entity.model';
import { AqlDefectsType } from '@one/app/shared/models/defects/aql-defects.models';
import { IInspectionType } from '@one/app/shared/models/inspections/inspection.models';
import { IAbstractMultiProduct } from '@one/app/shared/models/products/product.models';
import { IMeasurementSamplingSize, SamplingSize } from '@one/app/shared/models/sampling/sampling-size.models';
import { IWorkflow } from '@one/app/shared/models/workflow/workflow.models';
import { IWorkflowTemplate } from '@one/app/shared/models/workflows/workflows-templates.models';
import { QimaOptionalType } from '@qima/ngx-qima';

export interface IInspectionStatisticsByStatusesDTO {
  inspectionsByStatuses: Partial<Record<InspectionStatus, number>>;
}

export class AllowedQuantity {
  public produced: number[];
  public packed: number[];
}

export enum EChecklistImageType {
  PHOTO = 'PHOTO',
  SCAN = 'SCAN',
}

export interface IChecklistImageData {
  content?: string;
  date: Date;
  timezone: string;
  type: EChecklistImageType;
  mimeType?: string;
  imageId?: string;
  comment?: IChecklistComment;
  parentImage?: string;
  caption?: string;
  isSpotlight?: boolean;
  isLinkedToTestResult?: boolean;
  purchaseOrderProductId?: QimaOptionalType<number>;
}

export interface IAttachmentData {
  filename: string;
  attachmentId: string;
  mimeType?: EQpMimeType;
  uploadError?: string;
  isLoading?: boolean;
  downloadUrl?: string;
}

export const ATTACHMENT_SIZE_LIMIT_MB: number = 20;

export const ATTACHMENTS_TOTAL_SIZE_LIMIT_MB: number = 30 * 1024 * 1024;

export interface IChecklistDocumentData {
  type: string;
  name: string;
  size: number;
}

export interface DefectImage {
  image: IChecklistImageData;
  defectName: string;
  defectId: string;
  defectClassification: string;
}

export interface TestImage {
  type: EQpQuestionType;
  image: IChecklistImageData;
  testName: string;
  testId: string;
  testSuccess: boolean;
}

export interface AuditUser {
  login: string;
  firstName: string;
  lastName: string;
  profiles: EQpProfile[];
}

export interface IInspectionAuditUser extends AuditUser {
  companyId: number;
  companyProfile: EQpProfile.ROLE_BRAND | EQpProfile.ROLE_ENTITY;
  companyName?: string;
}

export interface Audit {
  createdBy: AuditUser;
  createdDate: Date;
  lastModifiedBy: AuditUser;
  lastModifiedDate: Date;
}

export interface IInspectionAudit extends Audit {
  createdBy: IInspectionAuditUser;
  lastModifiedBy: IInspectionAuditUser;
}

export interface IChecklistComment {
  message: string;
  date: Date;
  timezone: string;
}

export interface IInspectionManager {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  position: string;
  phone: string;
}

export interface IInspectionCancellation {
  cancelledBy: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    brandName?: string;
    entityName?: string;
    profiles: EQpProfile[];
  };
  date?: {
    date: Date;
    timezone: string;
  };
  reasons: string[];
  comment?: string;
}

export interface IInspectionAdditionalInformation {
  comment: string;
  documents: UpsertDocumentDTO[];
}

export class InspectionStatusDTO {
  public status: InspectionStatus;
  public updateCoordinates: IQpCoordinates;
  public updateDate: Date;
  public updateTimezone: string;
  public cancellationReasons: string[];
  public cancellationComment: string;

  public constructor(
    inspectionStatus: InspectionStatus,
    updateCoordinates: IQpCoordinates = null,
    updateDate: Date,
    updateTimezone: string,
    cancellationReasons: string[],
    cancellationComment: string
  ) {
    this.status = inspectionStatus;
    this.updateCoordinates = updateCoordinates;
    this.updateDate = updateDate;
    this.updateTimezone = updateTimezone;
    this.cancellationReasons = cancellationReasons;
    this.cancellationComment = cancellationComment;
  }
}

export interface IInspectionConsultation extends IInspection {
  id: number;
  inspectionUuid?: string;
  createdOffline: boolean;
  plannedDate: Date;
  shipmentDate: Date;
  inspectionDate?: Date;
  entity: IEntity;
  entityCountry: string;
  entityCity: string;
  inspectionType: IInspectionType;
  typeLabel: string;
  status: InspectionStatus;
  brandName: string;
  brandId: number;
  products: IAbstractMultiProduct[];
  workflow?: IWorkflow;
  isDefectsPerProduct: boolean;
  isAqlPerProduct: boolean;
  aqlDefects: AqlDefectsType;
  initialAqlDefects?: AqlDefectsType;
  samplingSize: SamplingSize;
  measurementsSamplingSize: IMeasurementSamplingSize;
  unit?: string;
  method: InspectionMethod;
  inspector?: IInspector;
  manager?: IInspectionManager;
  workflowSnapshot?: IWorkflowTemplate;
  workflowDetails: WorkflowDetailsDTO;
  audit?: IInspectionAudit;
  delegatedBy?: IEntity;
  supplierRequest?: boolean;
  responsibleEntity?: IEntity;
  productionSiteEntity?: IEntity;
  referenceSample?: IBrdInspectionReferenceSample;
  sampleCollection?: ISampleCollection;
  cancellation?: IInspectionCancellation;
  additionalInformation?: IInspectionAdditionalInformation;
  destinations: IEntity[];
}
