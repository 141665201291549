import { googleTagManagerPreviewFactory } from '@one/app/shared/factories/google-tag-manager-preview.factory';
import { GOOGLE_TAG_MANAGER_PREVIEW } from '@one/app/shared/tokens/google-tag-manager-preview.token';
import { WINDOW } from '@one/app/shared/tokens/window.token';
import { FactoryProvider } from '@angular/core';

export const GOOGLE_TAG_MANAGER_PREVIEW_PROVIDER: FactoryProvider = {
  deps: [WINDOW],
  provide: GOOGLE_TAG_MANAGER_PREVIEW,
  useFactory: googleTagManagerPreviewFactory,
};
