import { QpProgressBarComponent } from '@library/components/qp-progress-bar/qp-progress-bar.component';
import { EQpProgressBarColor } from '@library/components/qp-progress-bar/qp-progress-bar.models';
import { qpFallbackWhenZero } from '@library/functions/fallbacks/qp-fallback-when-zero';
import { Input, Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'one-blur-check-progress-modal',
  templateUrl: './blur-check-progress-modal.component.html',
  styleUrls: ['./blur-check-progress-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [QpProgressBarComponent, TranslateModule],
})
export class BlurCheckProgressModalComponent {
  public readonly progressBarColors: typeof EQpProgressBarColor = EQpProgressBarColor;

  @Input()
  public total = 0;

  public get done(): number {
    return this._done;
  }

  public set done(done: number) {
    this._done = done;
    this.percentage = (100 * done) / qpFallbackWhenZero(this.total, 1);
  }

  public percentage: number = 0;

  private _done: number = 0;

  public constructor(private readonly _changeDetectorRef: ChangeDetectorRef) {}

  public incrementDoneCount(): void {
    this.done++;
    this._changeDetectorRef.detectChanges();
  }
}
