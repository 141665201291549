export class EntityRoutes {
  public static root(): string {
    return `/entity`;
  }

  public static inspectionCreationV2(): string {
    return `${EntityRoutes.root()}/inspection/create`;
  }

  public static inspectionCreationV2Product(): string {
    return `${EntityRoutes.root()}/inspection/create/product`;
  }

  public static inspectionCreationV2Site(): string {
    return `${EntityRoutes.root()}/inspection/create/site`;
  }

  public static inspectionCreationV2Inspection(): string {
    return `${EntityRoutes.root()}/inspection/create/inspection`;
  }

  public static inspectionCreationV2Inspector(): string {
    return `${EntityRoutes.root()}/inspection/create/inspector`;
  }

  public static inspectionCreationV2Workflow(): string {
    return `${EntityRoutes.root()}/inspection/create/workflow`;
  }

  public static inspectionCreationV2Summary(): string {
    return `${EntityRoutes.root()}/inspection/create/summary`;
  }

  public static inspectionEditionV2(id: Readonly<number>): string {
    return `${EntityRoutes.root()}/inspection/${id}/edit`;
  }

  public static inspectionEditionV2Product(id: Readonly<number>): string {
    return `${EntityRoutes.root()}/inspection/${id}/edit/product`;
  }

  public static inspectionEditionV2Site(id: Readonly<number>): string {
    return `${EntityRoutes.root()}/inspection/${id}/edit/site`;
  }

  public static inspectionEditionV2Inspection(id: Readonly<number>): string {
    return `${EntityRoutes.root()}/inspection/${id}/edit/inspection`;
  }

  public static inspectionEditionV2Inspector(id: Readonly<number>): string {
    return `${EntityRoutes.root()}/inspection/${id}/edit/inspector`;
  }

  public static inspectionEditionV2Workflow(id: Readonly<number>): string {
    return `${EntityRoutes.root()}/inspection/${id}/edit/workflow`;
  }

  public static inspectionEditionV2Summary(id: Readonly<number>): string {
    return `${EntityRoutes.root()}/inspection/${id}/edit/summary`;
  }

  public static workflow(): string {
    return `${EntityRoutes.root()}/workflow`;
  }

  public static workflowConsult(): string {
    return `${EntityRoutes.root()}/workflow/consult`;
  }

  public static workflowId(id: number): string {
    return `${EntityRoutes.root()}/workflow/${id}`;
  }

  public static workflowChecklistConsult(): string {
    return `${EntityRoutes.root()}/workflow/checklist/consult`;
  }

  public static workflowTestChecklistId(id: number): string {
    return `${EntityRoutes.root()}/workflow/test-checklist/${id}`;
  }

  public static workflowDefectChecklistId(id: number): string {
    return `${EntityRoutes.root()}/workflow/defect-checklist/${id}`;
  }
}
