import { ModalRef, MODAL_OVERLAY } from '@library/services/qp-modal/qp-modal.model';
import { ComponentType, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, Injectable, Injector } from '@angular/core';

// QpModalConfig for setting overlay cdk config
const DEFAULT_CONFIG: OverlayConfig = {
  panelClass: 'qp-modal-center',
  hasBackdrop: true,
  backdropClass: 'qp-modal-shadow',
};

/**
 * @deprecated use {@link QimaDialogService} instead
 */
@Injectable({
  providedIn: 'root',
})
export class QpModalService {
  public constructor(private readonly _overlay: Overlay, private readonly _injector: Injector) {}

  public open<C>(component: ComponentType<C>, config?: OverlayConfig): ModalRef<C> {
    const overlayRef: OverlayRef = this._overlay.create({ ...DEFAULT_CONFIG, ...config });
    const componentRef: ComponentRef<C> = overlayRef.attach(
      new ComponentPortal(
        component,
        null,
        Injector.create({
          parent: this._injector,
          providers: [{ provide: MODAL_OVERLAY, useValue: overlayRef }],
        })
      )
    );

    return new ModalRef<C>(componentRef, overlayRef);
  }
}
