import { NetworkStatusService } from '@library/services/qp-network-status/qp-network-status.service';
import { QpNotificationBarService } from '@library/services/qp-notification-bar/qp-notification-bar.service';
import { Injectable } from '@angular/core';
import { SvgIconRegistryService } from 'angular-svg-icon';
import { skip } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NetworkStatusAlertService {
  public constructor(
    private readonly _networkStatusService: NetworkStatusService,
    private readonly _qpNotificationBarService: QpNotificationBarService,
    private readonly _iconReg: SvgIconRegistryService
  ) {
    // Preload icons used in the offline notification bar
    this._iconReg.loadSvg('ngx-qima/assets/icons/error-filled.svg')?.subscribe();
    this._iconReg.loadSvg('ngx-qima/assets/icons/cross-small.svg')?.subscribe();
  }

  public subscribeToNetworkEvents(): void {
    this._networkStatusService.isOnline$.pipe(skip(1)).subscribe((isOnline: boolean): void => {
      if (isOnline) {
        this._qpNotificationBarService.success('global.network.backOnline');
      } else {
        this._qpNotificationBarService.error('global.network.offline');
      }
    });
  }
}
