import { BrandDTO } from '@library/dto/brand.dto';
import { DefectsChecklistGetDTO } from '@library/dto/checklist/defect/defects-checklist.dto';
import { InspectionTypeSettingDTO } from '@library/dto/inspection-type-settings.dto';
import { InspectionTypeDTO } from '@library/dto/inspection-type.dto';
import { ProductDetailDTO } from '@library/dto/product-detail.dto';
import { PurchaseOrderViewDTO } from '@library/dto/purchase-order-view.dto';
import { TestsChecklistDTO } from '@library/dto/tests-checklist.dto';
import { CustomFieldDTO } from '@library/dto/workflow/custom-fields.dto';
import { WorkflowTemplateGetDTO } from '@library/dto/workflow/workflow-template.dto';
import { QpIdType, QpUuidType } from '@library/models/qp-alias.models';
import { QpLoggerService } from '@library/services/qp-logger/qp-logger.service';
import { QpNotificationBarService } from '@library/services/qp-notification-bar/qp-notification-bar.service';
import { IInspectionConsultation } from '@one/app/shared/models/inspection-consultation/inspection-consultation.models';
import { IWorkflow } from '@one/app/shared/models/workflow/workflow.models';
import { AccountService } from '@one/app/shared/services/account/account.service';
import { DatabaseAbstract } from '@one/app/shared/services/database/database-abstract';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DatabaseDataService extends DatabaseAbstract {
  public constructor(
    _accountService: AccountService,
    protected readonly _qpNotificationBarService: QpNotificationBarService,
    protected readonly _qpLoggerService: QpLoggerService
  ) {
    super(_accountService, _qpNotificationBarService, _qpLoggerService);
  }

  // Products 📦

  public async getProduct(id: Readonly<QpIdType>): Promise<ProductDetailDTO | undefined> {
    return await this._database?.product.get(id);
  }

  public async getProducts(): Promise<ProductDetailDTO[]> {
    return (await this._database?.product.toArray()) ?? [];
  }

  public async getProductsByIdentifierValue(value: string): Promise<ProductDetailDTO[] | undefined> {
    return await this._database?.product.where('identifierValue').startsWithIgnoreCase(value).toArray();
  }

  public async saveProduct(product: Readonly<ProductDetailDTO>): Promise<ProductDetailDTO> {
    try {
      await this._database?.product.put(product, product.id);
    } catch (error: unknown) {
      if (error instanceof Error) {
        this._displayErrorNotification({ inner: error, name: 'Error while saving product' });
        throw error;
      }

      console.error(error);
      throw Error('Unknow error while saving Product');
    }

    return product;
  }

  // POs 📦

  public async getPurchaseOrder(id: Readonly<QpIdType>): Promise<PurchaseOrderViewDTO | undefined> {
    return await this._database?.purchaseOrder.get(id);
  }

  public async getPOsByReference(value: string): Promise<PurchaseOrderViewDTO[] | undefined> {
    return await this._database?.purchaseOrder.where('reference').startsWithIgnoreCase(value).toArray();
  }

  public async savePurchaseOrder(po: Readonly<PurchaseOrderViewDTO>): Promise<PurchaseOrderViewDTO> {
    try {
      await this._database?.purchaseOrder.put(po, po.id);
    } catch (error: unknown) {
      if (error instanceof Error) {
        this._displayErrorNotification({ inner: error, name: 'Error while saving PO' });
        throw error;
      }

      console.error(error);
      throw Error('Unknow error while saving PO');
    }

    return po;
  }

  // Inspection types 🧪

  public async getInspectionTypes(): Promise<InspectionTypeDTO[]> {
    return (await this._database?.inspectionType.toArray()) ?? [];
  }

  public async getInspectionType(id: number): Promise<InspectionTypeDTO | undefined> {
    return await this._database?.inspectionType.get(id);
  }

  public async saveInspectionType(inspectionType: Readonly<InspectionTypeDTO>): Promise<InspectionTypeDTO> {
    try {
      await this._database?.inspectionType.put(inspectionType, inspectionType.id);
    } catch (error: unknown) {
      if (error instanceof Error) {
        this._displayErrorNotification({ inner: error, name: 'Error while saving InspectionType' });
        throw error;
      }

      console.error(error);
      throw Error('Unknow error while saving InspectionType');
    }

    return inspectionType;
  }

  // Inspection types settings ⚙️

  public async getInspectionTypeSettings(): Promise<InspectionTypeSettingDTO[]> {
    return (await this._database?.inspectionTypeSetting.toArray()) ?? [];
  }

  public async getInspectionTypeSettingById(id: Readonly<QpIdType>): Promise<InspectionTypeSettingDTO | undefined> {
    return await this._database?.inspectionTypeSetting.get(id);
  }

  public async saveInspectionTypeSettings(inspectionTypeSetting: Readonly<InspectionTypeSettingDTO>): Promise<InspectionTypeSettingDTO> {
    try {
      await this._database?.inspectionTypeSetting.put(inspectionTypeSetting, inspectionTypeSetting.id);
    } catch (error: unknown) {
      if (error instanceof Error) {
        this._displayErrorNotification({ inner: error, name: 'Error while saving InspectionTypeSetting' });
        throw error;
      }

      console.error(error);
      throw Error('Unknow error while saving InspectionTypeSetting');
    }

    return inspectionTypeSetting;
  }

  // WorkflowTemplates 🏗️

  public async getWorkflowTemplates(): Promise<WorkflowTemplateGetDTO[]> {
    return (await this._database?.workflowTemplate.toArray()) ?? [];
  }

  public async getWorkflowTemplateById(id: Readonly<QpIdType>): Promise<WorkflowTemplateGetDTO | undefined> {
    return await this._database?.workflowTemplate.get(id);
  }

  public async getWorkflowTemplateByName(name: Readonly<string>): Promise<WorkflowTemplateGetDTO[]> {
    return (await this._database?.workflowTemplate.where('name').startsWithIgnoreCase(name).toArray()) ?? [];
  }

  public async saveWorkflowTemplate(workflowTemplate: Readonly<WorkflowTemplateGetDTO>): Promise<WorkflowTemplateGetDTO> {
    try {
      await this._database?.workflowTemplate.put(workflowTemplate, workflowTemplate.id);
    } catch (error: unknown) {
      if (error instanceof Error) {
        this._displayErrorNotification({ inner: error, name: 'Error while saving WorkflowTemplate' });
        throw error;
      }

      console.error(error);
      throw Error('Unknow error while saving WorkflowTemplate');
    }

    return workflowTemplate;
  }

  // Workflows 🚧

  public async getWorkflowByInspectionUuid(inspectionUuid: Readonly<QpUuidType>): Promise<IWorkflow | undefined> {
    return await this._database?.workflow.where('inspectionUuid').equals(inspectionUuid).first();
  }

  // Checklists 📋

  public async getChecklistsByIds(ids: QpIdType[]): Promise<(TestsChecklistDTO | DefectsChecklistGetDTO)[]> {
    const tests = (await this._database?.testsChecklist.where('id').anyOf(ids).toArray()) ?? [];
    const defects = (await this._database?.defectsChecklist.where('id').anyOf(ids).toArray()) ?? [];

    return [...tests, ...defects];
  }

  // Tests Checklists 📋

  public async getTestsChecklists(): Promise<TestsChecklistDTO[]> {
    return (await this._database?.testsChecklist.toArray()) ?? [];
  }

  public async getTestsChecklistById(id: Readonly<QpIdType>): Promise<TestsChecklistDTO | undefined> {
    return await this._database?.testsChecklist.get(id);
  }

  public async saveTestsChecklist(checklist: Readonly<TestsChecklistDTO>): Promise<TestsChecklistDTO> {
    try {
      await this._database?.testsChecklist.put(checklist, checklist.id);
    } catch (error: unknown) {
      if (error instanceof Error) {
        this._displayErrorNotification({ inner: error, name: 'Error while saving Tests Checklist' });
        throw error;
      }

      console.error(error);
      throw Error('Unknow error while saving Tests Checklist');
    }

    return checklist;
  }

  // Defects Checklists 🐛

  public async getDefectsChecklists(): Promise<DefectsChecklistGetDTO[]> {
    return (await this._database?.defectsChecklist.toArray()) ?? [];
  }

  public async getDefectsChecklistById(id: Readonly<QpIdType>): Promise<DefectsChecklistGetDTO | undefined> {
    return await this._database?.defectsChecklist.get(id);
  }

  public async saveDefectsChecklist(checklist: Readonly<DefectsChecklistGetDTO>): Promise<DefectsChecklistGetDTO> {
    try {
      await this._database?.defectsChecklist.put(checklist, checklist.id);
    } catch (error: unknown) {
      if (error instanceof Error) {
        this._displayErrorNotification({ inner: error, name: 'Error while saving Defects Checklist' });
        throw error;
      }

      console.error(error);
      throw Error('Unknow error while saving Defects Checklist');
    }

    return checklist;
  }

  // Brands 🏷️

  public async saveBrand(brand: Readonly<BrandDTO>): Promise<BrandDTO> {
    try {
      await this._database?.brand.put(brand, brand.id);
    } catch (error: unknown) {
      if (error instanceof Error) {
        this._displayErrorNotification({ inner: error, name: 'Error while saving Brand' });
        throw error;
      }

      console.error(error);
      throw Error('Unknow error while saving Brand');
    }

    return brand;
  }

  public async getBrandById(id: Readonly<QpIdType>): Promise<BrandDTO | undefined> {
    return await this._database?.brand.get(id);
  }

  // Custom fields 🎨

  public async saveCustomField(customField: Readonly<CustomFieldDTO>): Promise<CustomFieldDTO> {
    try {
      await this._database?.customField.put(customField, customField.id);
    } catch (error: unknown) {
      if (error instanceof Error) {
        this._displayErrorNotification({ inner: error, name: 'Error while saving Custom field' });
        throw error;
      }

      console.error(error);
      throw Error('Unknow error while saving Custom field');
    }

    return customField;
  }

  public async getCustomFields(): Promise<CustomFieldDTO[]> {
    return (await this._database?.customField.toArray()) ?? [];
  }

  // Inspections 🔦
  public async getOfflineInspections(): Promise<IInspectionConsultation[]> {
    return (await this._database?.inspection.toArray()) ?? [];
  }

  public async clearOfflineDb(): Promise<void[]> {
    const offlineDbNames: string[] = [
      'purchaseOrder',
      'product',
      'inspectionType',
      'inspectionTypeSetting',
      'workflowTemplate',
      'testsChecklist',
      'defectsChecklist',
      'brand',
      'customField',
    ];

    return Promise.all(offlineDbNames.map((tableName): Promise<void> | undefined => this._database?.table(tableName).clear()));
  }
}
