// @ts-strict-ignore
import { QpLoggerService } from '@library/services/qp-logger/qp-logger.service';
import { QpQueryParamsService } from '@library/services/qp-query-params/qp-query-params.service';
import { BrdRoutes } from '@one/app/pages/brd/brd-routes';
import { IspRoutes } from '@one/app/pages/isp/isp-routes';
import {
  EQpNotificationId,
  ICAPMessage,
  IInspectionUpdateMessage,
  IFactoryRequestMessage,
  IReportDecisionMessage,
} from '@one/app/shared/models/messages/messages.models';
import { AccountService } from '@one/app/shared/services/account/account.service';
import {
  INotifuseType,
  INotifuseConfig,
  INotifuseMessage,
  INotifuseMessageData,
  ENotifuseClassName,
} from '@one/app/shared/services/third-party/notifuse/notifuse.models';
import { NOTIFUSE_CONFIG } from '@one/app/shared/tokens/notifuse-config.token';
import { WINDOW } from '@one/app/shared/tokens/window.token';
import { DOCUMENT } from '@angular/common';
import { Injectable, Inject, RendererFactory2, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { QimaOptionalType } from '@qima/ngx-qima';
import { isEmpty } from 'lodash/index';

@Injectable({ providedIn: 'root' })
export class NotifuseService {
  private readonly _baseNotifuseWidgetUrl = 'https://notifuse.com/widget/widget2.min.js';
  private readonly _renderer: Renderer2;

  public constructor(
    @Inject(DOCUMENT) private readonly _document: Document,
    @Inject(WINDOW) private readonly _window: Window,
    @Inject(NOTIFUSE_CONFIG) private readonly _notifuseProjectId: string,
    private readonly _accountService: AccountService,
    private readonly _qpQueryParamsService: QpQueryParamsService,
    private readonly _rendererFactory: RendererFactory2,
    private readonly _qpLoggerService: QpLoggerService,
    private readonly _router: Router
  ) {
    this._renderer = this._rendererFactory.createRenderer(document.querySelector('html'), null);
  }

  public init(): void {
    this._notifuse?.init({
      ...this._defaultConfig,
    });
  }

  public open(): void {
    this._notifuse?.open();
  }

  public close(): void {
    this._notifuse?.close();
  }

  public destroy(): void {
    this._notifuse?.destroy();
  }

  public loadNotifuseWidgetScript(): void {
    if (isEmpty(this._notifuseProjectId)) {
      return;
    }

    // Prepend the Notifuse Widget <script> injection to the <head> of the page
    const notifuseWidgetScript = this._renderer.createElement('script');

    notifuseWidgetScript.id = 'NotifuseWidgetscript';
    notifuseWidgetScript.async = true;
    notifuseWidgetScript.src = this._qpQueryParamsService.addQueryParams(this._baseNotifuseWidgetUrl, {
      id: this._notifuseProjectId,
    });
    this._renderer.insertBefore(this._document.head, notifuseWidgetScript, this._document.head.firstChild);
  }

  private get _notifuse(): QimaOptionalType<INotifuseType> {
    if (!this._window['Notifuse']) {
      this._qpLoggerService.warn('👾 Notifuse is not properly integrated.');

      return null;
    }

    return this._window['Notifuse'];
  }

  private get _defaultConfig(): INotifuseConfig {
    return {
      projectId: this._notifuseProjectId,
      bellSelector: '#notifuse-bell',
      userId: this._accountService.getNotifuseUserId(),
      userHmac: this._accountService.getNotifuseUserHash(),
      onClickMessage: (message: INotifuseMessage<INotifuseMessageData>): void => {
        let redirectUrl: QimaOptionalType<string> = '';

        switch (message.notificationId) {
          case EQpNotificationId.REPORT_DECISION: {
            redirectUrl = BrdRoutes.inspectionDetails((<IReportDecisionMessage>message.data).inspectionId);
            break;
          }

          case EQpNotificationId.REPORT_DECISION_MADE_BY_ENTITY: {
            redirectUrl = BrdRoutes.inspectionDetails((<IReportDecisionMessage>message.data).inspectionId);
            break;
          }

          case EQpNotificationId.CAP_CREATION:
          case EQpNotificationId.CAP_CONFIRM:
          case EQpNotificationId.CAP_DECISION:
          case EQpNotificationId.CAP_COMPLETION_DATE: {
            const capMessage: ICAPMessage = <ICAPMessage>message.data;

            redirectUrl = BrdRoutes.capReportDetails(capMessage.reportId, capMessage.capId);

            break;
          }

          case EQpNotificationId.FACTORY_REQUEST: {
            redirectUrl = BrdRoutes.inspectionDetails((<IFactoryRequestMessage>message.data).inspectionId);
            break;
          }

          case EQpNotificationId.INSPECTION_UPDATE: {
            redirectUrl = IspRoutes.inspectionDetails((<IInspectionUpdateMessage>message.data).inspectionId);
            break;
          }
        }

        void this._router.navigate([redirectUrl]);
        this.close();
      },
      classNames: {
        container: ENotifuseClassName.CONTAINER,
        header: ENotifuseClassName.HEADER,
        footer: ENotifuseClassName.FOOTER,
        link: ENotifuseClassName.LINKS,
        itemCover: ENotifuseClassName.ITEM_COVER,
        itemsList: ENotifuseClassName.ITEMS_LIST,
        itemDescription: ENotifuseClassName.ITEM_DESCRIPTION,
        itemTime: ENotifuseClassName.ITEM_TIME,
      },
    };
  }
}
