import { Injectable } from '@angular/core';
import { DetachedRouteHandle } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AppRouteReuseStrategyService {
  private readonly storedRoutes = new Map<string, DetachedRouteHandle>();

  public saveRoute(key: string, route: DetachedRouteHandle): void {
    this.storedRoutes.set(key, route);
  }

  public getRoute(key: string): DetachedRouteHandle | undefined {
    return this.storedRoutes.get(key);
  }

  public hasRoute(key: string): boolean {
    return this.storedRoutes.has(key);
  }

  public clearCache(): void {
    this.storedRoutes.clear();
  }
}
