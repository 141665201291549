import { QpIdType } from '@library/models/qp-alias.models';

export class SpRoutes {
  public static root(): string {
    return `/sp`;
  }

  // PRODUCTS PO
  public static productPo(): string {
    return `${this.root()}/product-po`;
  }

  // PRODUCTS PO - PO

  public static productPoPurchaseOrder(): string {
    return `${this.productPo()}/po`;
  }

  public static productPoPurchaseOrderConsult(): string {
    return `${this.productPoPurchaseOrder()}/consult`;
  }

  public static productPoPurchaseOrderCreation(): string {
    return `${this.productPoPurchaseOrder()}/create`;
  }

  public static productPoPurchaseOrderEdit(id: Readonly<number>): string {
    return `${this.productPoPurchaseOrder()}/${id}/edit`;
  }

  public static productPoPurchaseOrderView(id: Readonly<number>): string {
    return `${this.productPoPurchaseOrder()}/${id}`;
  }

  public static productPoPurchaseOrderReport(id: Readonly<number>): string {
    return `${this.productPoPurchaseOrderView(id)}/print`;
  }

  // PRODUCTS PO - PRODUCT

  public static productPoProduct(): string {
    return `${this.productPo()}/product`;
  }

  public static productPoProductConsult(): string {
    return `${this.productPoProduct()}/consult`;
  }

  public static productPoProductCreation(): string {
    return `${this.productPoProduct()}/create`;
  }

  public static productPoProductIdMeasurements(id: Readonly<string>): string {
    return `${this.productPoProduct()}/${id}/measurements`;
  }

  public static productPoProductIdMeasurementsImport(id: Readonly<number>): string {
    return `${this.productPoProduct()}/${id}/measurements/import`;
  }

  public static productPoProductImport(): string {
    return `${this.productPoProduct()}/import`;
  }

  public static productPoProductView(id: Readonly<number>): string {
    return `${this.productPoProduct()}/${id}/information`;
  }

  public static productPoProductEdit(id: Readonly<number>): string {
    return `${this.productPoProduct()}/${id}/edit`;
  }

  public static productPoProductLabTestReports(id: Readonly<number>): string {
    return `${this.productPoProduct()}/${id}/lab-tests/reports`;
  }

  // REPORTS

  public static report(): string {
    return `${this.root()}/report/inspection`;
  }

  public static reportsConsultation(): string {
    return `${this.report()}/consult`;
  }

  public static reportDetail(inspectionId: QpIdType): string {
    return `${this.report()}/${inspectionId}`;
  }

  public static reportPrint(inspectionId: QpIdType): string {
    return `${this.reportDetail(inspectionId)}/print`;
  }

  public static groupReportPrint(reportGroupId: QpIdType): string {
    return `${this.reportGroup(reportGroupId)}/print`;
  }

  public static groupReporEditSamples(reportGroupId: QpIdType): string {
    return `${this.reportGroup(reportGroupId)}/edit-samples`;
  }

  public static reportGroups(): string {
    return `${this.report()}/group/consult`;
  }

  public static reportGroup(reportGroupId: QpIdType): string {
    return `${this.report()}/group/${reportGroupId}`;
  }

  public static groupReportDownloadApi(reportGroupId: QpIdType, version: number): string {
    return `api/report-groups/${reportGroupId}/download?v=${version}`;
  }
}
