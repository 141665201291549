// @ts-strict-ignore
import { QpConsultationPage, IQpConsultationPage } from '@library/models/qp-consultation-page.models';
import { QpSafeUrlType } from '@library/models/qp-url.models';
import { QpQueryParamsService } from '@library/services/qp-query-params/qp-query-params.service';
import { SERVER_API_URL } from '@one/app/app.constants';
import { IIspInspectorInviteConfirmConfirmation } from '@one/app/pages/isp/pages/inspector-invite-confirm/models/isp-inspector-invite-confirm-confirmation.models';
import { IInspectionStatisticsByStatusesDTO } from '@one/app/shared/models/inspection-consultation/inspection-consultation.models';
import { IInspectorProfile } from '@one/app/shared/models/inspector-profile/inspector-profile.models';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { QimaOptionalType } from '@qima/ngx-qima';
import { isEmpty } from 'lodash/index';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

const FETCH_INSPECTOR_PROFILES_DEFAULT_PAGE_NUMBER = 0;
const FETCH_INSPECTOR_PROFILES_DEFAULT_PAGE_SIZE = 1000;

@Injectable({
  providedIn: 'root',
})
export class InspectorService {
  private readonly _resourceUrl: Readonly<string> = `${SERVER_API_URL}api`;

  public constructor(
    private readonly _httpClient: HttpClient,
    private readonly _sanitizer: DomSanitizer,
    private readonly _qpQueryParamsService: QpQueryParamsService
  ) {}

  // This method allows a user to recover an invite information from the token that was generated upon creating the invite
  public getInvite$(token: Readonly<string>): Observable<IInspectorProfile> {
    const url = `${this._resourceUrl}/invitation/inspector/${token}`;

    return this._httpClient.get<IInspectorProfile>(url, { observe: 'body' });
  }

  // This method allows the user to confirm his/her invitation as an inspector and add information
  public confirmInvite$(
    token: Readonly<string>,
    confirmation: Readonly<IIspInspectorInviteConfirmConfirmation>,
    avatarFile: Readonly<File>
  ): Observable<IIspInspectorInviteConfirmConfirmation> {
    const url = `${this._resourceUrl}/invitation/inspector/${token}`;
    const formData = new FormData();

    if (avatarFile) {
      formData.append('file', avatarFile);
    }

    formData.append('inspector', new Blob([JSON.stringify(confirmation)], { type: 'application/json' }));

    return this._httpClient.post<IIspInspectorInviteConfirmConfirmation>(url, formData, { observe: 'body' });
  }

  public getInspectorProfile$(inspectorId: number): Observable<IInspectorProfile> {
    const inspectorProfileUrl: string = `${this._resourceUrl}/inspector-profiles/${inspectorId}`;

    return this._httpClient.get<IInspectorProfile>(inspectorProfileUrl, { observe: 'body' });
  }

  public searchInspectorProfiles$(search: string, page = 0, size = 20): Observable<IQpConsultationPage<IInspectorProfile>> {
    const inspectorProfilesUrl: string = this._qpQueryParamsService.addQueryParams(`${this._resourceUrl}/inspector-profiles`, {
      sort: ['lastName,asc'],
      name: search,
      page,
      size,
    });

    return this._httpClient.get<IInspectorProfile[]>(inspectorProfilesUrl, { observe: 'response' }).pipe(
      map(
        (response: HttpResponse<IInspectorProfile[]>): IQpConsultationPage<IInspectorProfile> =>
          QpConsultationPage.create({
            items: response.body,
            totalItems: parseInt(response.headers.get('x-total-count')),
            page,
            pageSize: size,
            totalPageCount: parseInt(response.headers.get('x-total-page')),
          })
      )
    );
  }

  public getAllInspectorProfiles$(): Observable<IQpConsultationPage<IInspectorProfile>>;
  public getAllInspectorProfiles$(
    latitude: Readonly<number>,
    longitude: Readonly<number>
  ): Observable<IQpConsultationPage<IInspectorProfile>>;

  public getAllInspectorProfiles$(
    latitude: Readonly<number>,
    longitude: Readonly<number>,
    page: Readonly<number>,
    size: Readonly<number>
  ): Observable<IQpConsultationPage<IInspectorProfile>>;

  public getAllInspectorProfiles$(
    latitude: Readonly<QimaOptionalType<number>>,
    longitude: Readonly<QimaOptionalType<number>>,
    page: Readonly<QimaOptionalType<number>>,
    size: Readonly<QimaOptionalType<number>>,
    nameSearch: Readonly<string>
  ): Observable<IQpConsultationPage<IInspectorProfile>>;

  public getAllInspectorProfiles$(
    latitude?: Readonly<QimaOptionalType<number>>,
    longitude?: Readonly<QimaOptionalType<number>>,
    page: Readonly<number> = FETCH_INSPECTOR_PROFILES_DEFAULT_PAGE_NUMBER,
    size: Readonly<number> = FETCH_INSPECTOR_PROFILES_DEFAULT_PAGE_SIZE,
    nameSearch?: Readonly<QimaOptionalType<string>>
  ): Observable<IQpConsultationPage<IInspectorProfile>> {
    let totalItems = 0;

    return this.requestAllInspectorProfiles$(latitude, longitude, page, size, nameSearch).pipe(
      tap((res: IQpConsultationPage<IInspectorProfile>): void => {
        totalItems = res.totalItems;
      }),
      map((inspectorProfiles: IQpConsultationPage<IInspectorProfile>): IInspectorProfile[] => {
        if (!isEmpty(inspectorProfiles.items)) {
          return inspectorProfiles.items.map(
            (inspectorProfile: IInspectorProfile): IInspectorProfile => this.completeInspectorProfilePicture(inspectorProfile)
          );
        }

        return [];
      }),
      map(
        (items: IInspectorProfile[]): IQpConsultationPage<IInspectorProfile> =>
          QpConsultationPage.create({
            items,
            totalItems,
            page,
            pageSize: size,
            totalPageCount: Math.floor(totalItems / size),
          })
      )
    );
  }

  public requestAllInspectorProfiles$(
    latitude: Readonly<QimaOptionalType<number>>,
    longitude: Readonly<QimaOptionalType<number>>,
    page: Readonly<number> = FETCH_INSPECTOR_PROFILES_DEFAULT_PAGE_NUMBER,
    size: Readonly<number> = FETCH_INSPECTOR_PROFILES_DEFAULT_PAGE_SIZE,
    name: Readonly<QimaOptionalType<string>>
  ): Observable<IQpConsultationPage<IInspectorProfile>> {
    const allInspectorProfilesUrl: string = this._qpQueryParamsService.addQueryParams(`${this._resourceUrl}/inspector-profiles`, {
      sort: ['distance,asc', 'lastName,asc'],
      latitude,
      longitude,
      name,
      page,
      size,
    });

    return this._httpClient.get<IInspectorProfile[]>(allInspectorProfilesUrl, { observe: 'response' }).pipe(
      map(
        (response: HttpResponse<IInspectorProfile[]>): IQpConsultationPage<IInspectorProfile> =>
          QpConsultationPage.create({
            items: response.body,
            totalItems: parseInt(response.headers.get('x-total-count')),
            page,
            pageSize: size,
            totalPageCount: parseInt(response.headers.get('x-total-page')),
          })
      )
    );
  }

  public completeInspectorProfilePicture(inspectorProfile: Readonly<IInspectorProfile>): IInspectorProfile {
    const inspectorProfilePictureUrl: QpSafeUrlType = this.getInspectorProfilePictureSafeUrl(inspectorProfile.id);

    return { ...inspectorProfile, picture: inspectorProfilePictureUrl };
  }

  public getInspectorProfilePictureSafeUrl(inspectorId: Readonly<number>): QpSafeUrlType {
    const inspectorProfilePictureUrl: string = this.getInspectorProfilePictureUrl(inspectorId);

    return this._sanitizer.bypassSecurityTrustUrl(inspectorProfilePictureUrl);
  }

  public getInspectorProfilePicture$(inspectorId: Readonly<number>): Observable<Blob> {
    const singleInspectorProfilePictureUrl = this.getInspectorProfilePictureUrl(inspectorId);

    return this._httpClient
      .get(singleInspectorProfilePictureUrl, { observe: 'body', responseType: 'blob' })
      .pipe(catchError((): Observable<Blob> => of(null)));
  }

  public getInspectorInspectionsStatistics$(): Observable<IInspectionStatisticsByStatusesDTO> {
    return this._httpClient.get<IInspectionStatisticsByStatusesDTO>(`${this._resourceUrl}/inspectors/statistics`);
  }

  public getInspectorProfilePictureUrl(inspectorId: Readonly<number>): string {
    return `${this._resourceUrl}/users/${inspectorId}/profile-picture`;
  }
}
