import { trackingInitFactory } from '@one/app/shared/factories/tracking-init.factory';
import { TrackingService } from '@one/app/shared/services/third-party/analytics-manager/tracking/tracking.service';
import { APP_BOOTSTRAP_LISTENER, FactoryProvider } from '@angular/core';

export const TRACKING_INIT_PROVIDER: FactoryProvider = {
  deps: [TrackingService],
  provide: APP_BOOTSTRAP_LISTENER,
  multi: true,
  useFactory: trackingInitFactory,
};
