// @ts-strict-ignore
import { QpTooltipComponent } from '@library/components/qp-tooltip/qp-tooltip.component';
import { EQpTooltipPosition } from '@library/directives/qp-tooltip/qp-tooltip.models';
import { EQpColorValue } from '@one/app/shared/models/colors/colors.models';
import { ConnectedPosition, Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, Directive, ElementRef, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { QimaOptionalType } from '@qima/ngx-qima';

@Directive({
  selector: '[qpTooltip]',
  standalone: true,
})
export class QpTooltipDirective implements OnInit, OnDestroy {
  private static _getTooltipPosition(position: Readonly<EQpTooltipPosition>): ConnectedPosition {
    switch (position) {
      case EQpTooltipPosition.BOTTOM:
        return {
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
        };
      case EQpTooltipPosition.RIGHT:
        return {
          originX: 'end',
          originY: 'center',
          overlayX: 'start',
          overlayY: 'center',
        };
      case EQpTooltipPosition.LEFT:
        return {
          originX: 'start',
          originY: 'center',
          overlayX: 'end',
          overlayY: 'center',
        };

      case EQpTooltipPosition.TOP:
      default:
        return {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom',
        };
    }
  }

  @Input()
  public qpTooltipShow = true;

  @Input()
  public qpTooltipText: string | string[] = '';

  @Input()
  public qpTooltipBackgroundColor: EQpColorValue = EQpColorValue.WHITE;

  @Input()
  public qpTooltipTextColor: EQpColorValue = EQpColorValue.MIDNIGHT;

  @Input()
  public get qpTooltipPosition(): QimaOptionalType<EQpTooltipPosition> {
    return this._qpTooltipPosition;
  }

  public set qpTooltipPosition(value: EQpTooltipPosition) {
    this._qpTooltipPosition = value;
    this._setPositionStrategy();
  }

  protected _overlayRef: OverlayRef;
  private _qpTooltipPosition: QimaOptionalType<EQpTooltipPosition> = undefined;

  public constructor(
    protected _overlayPositionBuilder: OverlayPositionBuilder,
    protected _elementRef: ElementRef,
    protected _overlay: Overlay
  ) {}

  public ngOnInit(): void {
    this._setPositionStrategy();
  }

  public ngOnDestroy(): void {
    this._overlayRef.detach();
  }

  @HostListener('mouseenter')
  public show(): void {
    const tooltipPortal = new ComponentPortal(QpTooltipComponent);
    const tooltipRef: ComponentRef<QpTooltipComponent> = this._overlayRef.attach(tooltipPortal);

    tooltipRef.instance.text = this.qpTooltipText;
    tooltipRef.instance.show = this.qpTooltipShow;
    tooltipRef.instance.backgroundColor = this.qpTooltipBackgroundColor;
    tooltipRef.instance.textColor = this.qpTooltipTextColor;
  }

  @HostListener('mouseleave')
  public hide(): void {
    this._overlayRef.detach();
  }

  private _setPositionStrategy(): void {
    const positionStrategy = this._overlayPositionBuilder
      .flexibleConnectedTo(this._elementRef)
      .withPositions([QpTooltipDirective._getTooltipPosition(this.qpTooltipPosition)]);

    this._overlayRef = this._overlay.create({ positionStrategy });
  }
}
