import { EQpMimeType } from '@library/models/qp-mime-type.models';
import { QpLoggerService } from '@library/services/qp-logger/qp-logger.service';
import { Injectable } from '@angular/core';
import { QimaOptionalType } from '@qima/ngx-qima';
import loadImage, { MetaData, WriteExifData } from 'blueimp-load-image';
import imageCompression from 'browser-image-compression';
import { from, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class QpPictureCompressionService {
  private readonly _imageMaxSideLength: number = 1920;
  private readonly _imageCompressionLevel: number = 0.8;

  public constructor(private readonly _qpLoggerService: QpLoggerService) {}

  /**
   * Resize an image if it's too big, and compress it
   *
   * @param {Blob} blob Image as a blob
   * @param file
   * @returns {Observable<QimaOptionalType<Blob>>} Compressed and (eventually) resized image
   */
  public resizeAndCompressImage$(file: File): Observable<QimaOptionalType<Blob>> {
    const compress = (options: { preserveExif: boolean }): Observable<QimaOptionalType<Blob>> =>
      from(
        imageCompression(file, {
          maxWidthOrHeight: this._imageMaxSideLength,
          fileType: EQpMimeType.IMAGE_JPEG,
          initialQuality: this._imageCompressionLevel,
          ...options,
        })
      );

    return compress({ preserveExif: true }).pipe(
      catchError((error: Error): Observable<QimaOptionalType<Blob>> => {
        this._qpLoggerService.warn('Error when compressing image: ', error, '\nRetrying without preserving exif...');

        // Pictures edited with Snapseed are not properly read by the library.
        // Tracking here: https://github.com/Donaldcwl/browser-image-compression/issues/187
        return compress({ preserveExif: false });
      })
    );
  }

  public convertCanvasToBlob$(canvas: HTMLCanvasElement, targetMimeType: string = EQpMimeType.IMAGE_JPEG): Observable<Blob> {
    return from(imageCompression.canvasToFile(canvas, targetMimeType, 'image', new Date().getTime()));
  }

  /**
   * Convert an image blob to a base 64 encoded string
   *
   * @param {Blob} blob Image as a blob
   * @returns {Observable<string>} Image data as a base64 encoded string
   */
  public convertBlobToBase64$(blob: Blob): Observable<string> {
    return from(
      new Promise<string>((resolve, reject): void => {
        const reader = new FileReader();

        reader.readAsDataURL(blob);
        reader.onloadend = (): void => {
          resolve(reader.result as string);
        };

        reader.onerror = (e): void => {
          reject(new Error(`An error occurred while loading image from blob. ${e}`));
        };
      })
    );
  }

  /*
   * Restore EXIF data from the original image to the compressed one, since they are lost when going through a canvas
   * ⚠️ On ios, having Settings > Camera > Format > "Most Compatible" is required to keep EXIF data on upload
   */
  public restoreImageExif$(image: Blob, metadataToRestore: MetaData): Observable<Blob> {
    if (!metadataToRestore?.exif || !metadataToRestore?.imageHead) {
      return of(image);
    }

    try {
      const imageHead = metadataToRestore.imageHead;

      // iOS handles orientation differently, we reset the exif to avoid having the uploaded image with a wrong orientation
      loadImage.writeExifData(imageHead, metadataToRestore as unknown as WriteExifData, 'Orientation', 1);

      return from(new Promise<QimaOptionalType<Blob>>((resolve): void => loadImage.replaceHead(image as Blob, imageHead, resolve))).pipe(
        map((editedImage): Blob => editedImage ?? image)
      );
    } catch (error) {
      this._qpLoggerService.warn('Error when restoring image exif: ', error);

      return of(image);
    }
  }
}
