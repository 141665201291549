import { QpButtonDirective, EQpButtonDirectiveSize, EQpButtonDirectiveType } from '@library/directives/qp-button/qp-button.directive';
import { IQpDisplayedRoute } from '@library/models/qp-router.models';
import { NgFor, NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzMenuModule } from 'ng-zorro-antd/menu';

@Component({
  selector: 'qp-dropdown-nav',
  templateUrl: './qp-dropdown-nav.component.html',
  styleUrls: ['./qp-dropdown-nav.component.scss'],
  standalone: true,
  imports: [NzDropDownModule, QpButtonDirective, NzIconModule, NzMenuModule, NgFor, NgClass, NgIf, TranslateModule],
})
export class QpDropdownNavComponent {
  @Input() public displayedRoutes: IQpDisplayedRoute[] = [];
  @Input() public buttonLabel = '';

  public isVisible = false;
  public buttonSizes: typeof EQpButtonDirectiveSize = EQpButtonDirectiveSize;
  public buttonTypes: typeof EQpButtonDirectiveType = EQpButtonDirectiveType;

  public constructor(private readonly _router: Router) {}

  public redirectTo(path: string): void {
    this.isVisible = false;
    void this._router.navigate([path]);
  }

  public isVisibleChanged(isVisible: boolean): void {
    this.isVisible = isVisible;
  }
}
