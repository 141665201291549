import { BRD_INSPECTION_BOOKING_STORE_FEATURE_KEY } from '@one/app/pages/brd/pages/inspection/store-features/inspection-booking/brd-inspection-booking.config';
import { IspInspectionIdStoreEffects } from '@one/app/pages/isp/pages/inspection/pages/id/shared/services/store/isp-inspection-id-store.effects';
import { ispInspectionIdReducer } from '@one/app/pages/isp/pages/inspection/pages/id/shared/services/store/isp-inspection-id-store.reducers';
import { IspInspectionIdWorkflowStoreEffects } from '@one/app/pages/isp/pages/inspection/pages/id/shared/services/store/isp-inspection-id-workflow-store.effects';
import { IStoreState } from '@one/app/store/store.models';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { NavigationActionTiming, routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule, ActionReducerMap, ActionReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';

const APP_REDUCERS: ActionReducerMap<IStoreState> = { router: routerReducer, inspectionStore: ispInspectionIdReducer };
const APP_EFFECTS = [IspInspectionIdStoreEffects, IspInspectionIdWorkflowStoreEffects];

/**
 * @description
 * NGRX locale storage sync reduce to store some part of the store in the local storage
 * @param {ActionReducer<IStoreState>} reducer The meta reducer to use
 * @returns {ActionReducer<IStoreState>} The new meta reducer to use
 */
function localStorageSyncReducer(reducer: ActionReducer<IStoreState>): ActionReducer<IStoreState> {
  return localStorageSync({
    keys: [BRD_INSPECTION_BOOKING_STORE_FEATURE_KEY],
    rehydrate: true,
    removeOnUndefined: false,
    restoreDates: false,
    checkStorageAvailability: false,
    storageKeySerializer(key: Readonly<string>): string {
      return `QIMAone-ngrx-${key}`;
    },
  })(reducer);
}

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forRoot(APP_REDUCERS, {
      metaReducers: [localStorageSyncReducer],
      runtimeChecks: {
        // @todo enable everything! It helps to detect errors while developing
        strictStateSerializability: false,
        strictActionSerializability: false,
        strictStateImmutability: false,
        strictActionImmutability: false,
        strictActionWithinNgZone: false,
        strictActionTypeUniqueness: false,
      },
    }),
    EffectsModule.forRoot(APP_EFFECTS),
    StoreRouterConnectingModule.forRoot({
      navigationActionTiming: NavigationActionTiming.PostActivation,
    }),
  ],
})
export class AppStoreModule {}
