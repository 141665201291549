export class QpMiniRange {
  public static between(min: Readonly<number>, max: Readonly<number>): QpMiniRange {
    return new QpMiniRange(min, max);
  }

  private readonly _min: number;
  private readonly _max: number;

  public constructor(min: Readonly<number>, max: Readonly<number>) {
    this._min = min;
    this._max = max;
  }

  public contains(x: Readonly<number>): boolean {
    return x >= this._min && x <= this._max;
  }
}
