import { isString } from 'lodash/index';

/**
 * @description
 * Used to define the GTM container ID
 * Based on the environments variable injection in the index file
 *
 * @param {Window} window The window object containing possibly the "googleTagManagerId" variable
 *
 * @returns {string} Return the container ID if it exists or null otherwise
 */
export function googleTagManagerIdFactory(window: Window): string | null {
  const gtmId = window['googleTagManagerId'];

  if (isString(gtmId)) {
    return gtmId;
  }

  return null;
}
