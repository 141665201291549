import { EQpInspectionTypeLabel } from '@library/models/qp-inspection.models';

export enum EInspectionStepStatus {
  Wait = 'wait',
  Progress = 'progress',
  Error = 'error',
  Finish = 'finish',
}

export interface IInspectionType {
  id: number;
  label: EQpInspectionTypeLabel | string;
  description?: string;
  defaultProducedRatio?: number;
  defaultPackedRatio?: number;
}

export enum EInspectionProductMeasurementStatus {
  OK = 'OK',
  NO_MEASUREMENTS = 'NO_MEASUREMENTS',
  BLANK_MEASURES = 'BLANK_MEASURES',
  NO_PRODUCT_SELECTED = 'NO_PRODUCT_SELECTED',
}
