// @ts-strict-ignore
import { QpLoggerService } from '@library/services/qp-logger/qp-logger.service';
import { ModalRef } from '@library/services/qp-modal/qp-modal.model';
import { QpModalService } from '@library/services/qp-modal/qp-modal.service';
import { QpNotificationBarService } from '@library/services/qp-notification-bar/qp-notification-bar.service';
import { BlurCheckProgressModalComponent } from '@one/app/shared/modals/blur-check-progress-modal/blur-check-progress-modal.component';
import { BlurCheckVerificationModalComponent } from '@one/app/shared/modals/blur-check-verification-modal/blur-check-verification-modal.component';
import { Injectable } from '@angular/core';
import { QimaOptionalType } from '@qima/ngx-qima';
import { BlurCategory, loadModel, predictBlur } from 'image-quality';
import { first } from 'rxjs/operators';

interface IAnalyzedImage {
  img: HTMLImageElement;
  blur: BlurCategory;
}

@Injectable({
  providedIn: 'root',
})
export class BlurDetectionService {
  private readonly _modelPath = 'assets/image-quality/';
  private _isLoaded = false;

  public constructor(
    private readonly _modalService: QpModalService,
    private readonly _qpLoggerService: QpLoggerService,
    private readonly _qpNotificationBarService: QpNotificationBarService
  ) {}

  public async filterImages(files: File[]): Promise<File[]> {
    let images = [];

    try {
      images = await this._loadAndAnalyzeFiles(files);
    } catch (err) {
      this._qpNotificationBarService.warning('blur-detection-service.hardware-acceleration-error');
      this._qpLoggerService.info('Error during blurcheck. Check hardware acceleration.', err);

      return files;
    }

    const blurryCount: number = images.filter((i: QimaOptionalType<IAnalyzedImage>): boolean => i && i.blur != 'SHARP').length;

    // Skip the verification modal if no blur has been detected
    if (blurryCount == 0) {
      return files;
    }

    // Open the blur verification modal
    const blurCheckVerificationModalComponentModalRef: ModalRef<BlurCheckVerificationModalComponent> = this._modalService.open(
      BlurCheckVerificationModalComponent,
      {
        panelClass: 'qp-modal-full',
        hasBackdrop: false,
      }
    );
    const blurCheckVerificationModalComponent: BlurCheckVerificationModalComponent =
      blurCheckVerificationModalComponentModalRef.componentRef.instance;

    blurCheckVerificationModalComponent.total = blurryCount;
    let blurryImageIndex = -1;
    // Loop through all images to keep the same ordering regardless of the blurry images
    // being verified
    const results: File[] = [];

    try {
      for (let i = 0; i < files.length; i++) {
        const image = images[i];

        if (image && image.blur != 'SHARP') {
          // If the image has been detected as blurry, present it to the user
          // to ask if it should be kept or removed
          blurCheckVerificationModalComponent.index = ++blurryImageIndex;
          blurCheckVerificationModalComponent.imageSrc = image.img.src;
          blurCheckVerificationModalComponent.blurType = image.blur;

          // Await for one click event only
          const keepImage = await blurCheckVerificationModalComponent.keepImage.pipe(first()).toPromise();

          // Do not add the image to the results if the user chooses to remove it
          if (!keepImage) {
            continue;
          }
        }

        results.push(files[i]);
      }
    } finally {
      blurCheckVerificationModalComponentModalRef.close();
    }

    return results;
  }

  private async _maybeLoadModel(): Promise<void> {
    if (!this._isLoaded) {
      await loadModel(this._modelPath);
      this._isLoaded = true;
    }
  }

  private async _loadFile(file: File): Promise<HTMLImageElement> {
    const imageData: string = await new Promise((resolve, reject): void => {
      const reader = new FileReader();

      reader.onload = (): void => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });

    return await new Promise((resolve, reject): void => {
      const img = document.createElement('img');

      img.onload = (): void => resolve(img);
      img.onerror = reject;
      img.src = imageData;
    });
  }

  private async _loadAndAnalyzeFiles(files: File[]): Promise<QimaOptionalType<IAnalyzedImage>[]> {
    // Open the blur checking progress bar modal
    const blurCheckProgressModalComponentModalRef: ModalRef<BlurCheckProgressModalComponent> =
      this._modalService.open(BlurCheckProgressModalComponent);
    const blurCheckProgressModalComponent: BlurCheckProgressModalComponent = blurCheckProgressModalComponentModalRef.componentRef.instance;

    blurCheckProgressModalComponent.total = files.length;
    const images: IAnalyzedImage[] = [];

    try {
      // Ensure the library is loaded first
      await this._maybeLoadModel();

      // Loop through images to load then and analyze them while updating the progress bar
      for (let i = 0; i < files.length; i++) {
        try {
          const img = await this._loadFile(files[i]);
          const result = predictBlur(img);

          images.push({ img, blur: result.cat });
        } catch {
          // If we get here, the image could not be loaded or analysed.
          // We push `null` to keep track of this unsupported file and
          // ensure that the output array has the same length as the input one.
          images.push(null);
        }

        blurCheckProgressModalComponent.incrementDoneCount();
      }
    } finally {
      // Dismiss the modal even if something wrong happened
      blurCheckProgressModalComponentModalRef.close();
    }

    return images;
  }
}
