import { IspWorkflowStepActionTestsChecklist } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/isp-workflow-step-action-tests-checklist/isp-workflow-step-action-tests-checklist';
import { IspWorkflowStepGroupAction } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/isp-workflow-step-grouped-action';
import { EWorkflowActionType } from '@one/app/shared/models/workflow/workflow.models';

export class IspWorkflowStepGroupTestsChecklistAction extends IspWorkflowStepGroupAction<IspWorkflowStepActionTestsChecklist> {
  public get type(): EWorkflowActionType.TESTS_CHECKLIST {
    return EWorkflowActionType.TESTS_CHECKLIST;
  }

  public constructor(actions: IspWorkflowStepActionTestsChecklist[]) {
    super(actions);
  }
}
