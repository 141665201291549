import { ChecklistRatingResultDTO } from '@library/dto/checklist/checklist-rating-result.dto';
import { ChecklistRatingDTO } from '@library/dto/checklist/checklist-rating.dto';
import { RatingTypes } from '@library/dto-enums/rating-types.dto-enum';
import { qpGetConstraintMatchingValue } from '@library/functions/qp-get-constraint-matching-value/qp-get-constraint-matching-value';
import { EQpQuestionType } from '@library/models/qp-question.models';
import { DEFAULT_RATING_RESULT } from '@library/models/qp-ratings.models';
import {
  IQpTestsChecklistElementV2,
  AverageNumberValue,
  IQpTestsChecklistElementDistributionValue,
} from '@library/models/qp-tests-checklist-element.models';
import { EQpConstraintUnit, IQpNumberRatingConstraint } from '@library/models/qp-tests-checklist-questions-constraints.models';
import { WorkflowPathIdType } from '@one/app/shared/models/workflows/workflow.models';

export interface IQpNumberRatingsConstraint {
  criterias: IQpNumberRatingConstraint[];
  type: RatingTypes.RATINGS;
  unit: EQpConstraintUnit;
}

export interface IQpTestsChecklistElementRating extends IQpTestsChecklistElementV2 {
  ratingResult: ChecklistRatingResultDTO;
  successCriteria: IQpNumberRatingsConstraint;
}

export interface IQpTestsChecklistElementRatingDiv extends IQpTestsChecklistElementRating {
  type: EQpQuestionType.DIV;
  value: undefined;
}

export interface IQpTestsChecklistElementRatingNumber extends IQpTestsChecklistElementRating {
  elements: undefined;
  successCriteria: IQpNumberRatingsConstraint;
  type: EQpQuestionType.NUMBER;
  value: number;
}

export interface IQpTestsChecklistElementRatingAverageNumber extends IQpTestsChecklistElementRating {
  elements: undefined;
  successCriteria: IQpNumberRatingsConstraint;
  type: EQpQuestionType.AVERAGE_NUMBER;
  value: AverageNumberValue;
}

export interface IQpTestsChecklistElementRatingDistribution extends IQpTestsChecklistElementRating {
  elements: undefined;
  type: EQpQuestionType.DISTRIBUTION;
  groupLabels?: string[];
  value: IQpTestsChecklistElementDistributionValue[];
}

export type QpTestsChecklistElementRatingType =
  | IQpTestsChecklistElementRatingDiv
  | IQpTestsChecklistElementRatingNumber
  | IQpTestsChecklistElementRatingAverageNumber
  | IQpTestsChecklistElementRatingDistribution;

/**
 * @description TestsChecklistElementRating is an 'final' element = not of type DIV
 */
export abstract class QpTestsChecklistElementRating {
  public id: WorkflowPathIdType;
  public ratingSystem: ChecklistRatingDTO[];

  protected constructor(id: WorkflowPathIdType, ratingSystem: ChecklistRatingDTO[]) {
    this.id = id;
    this.ratingSystem = ratingSystem;
  }

  public isPass(): boolean {
    return true;
  }

  public get ratingResult(): ChecklistRatingResultDTO {
    return DEFAULT_RATING_RESULT;
  }
}

export class QpTestsChecklistDistributionElementRating extends QpTestsChecklistElementRating {
  public constructor(element: Readonly<IQpTestsChecklistElementRatingDistribution>, ratingSystem: ChecklistRatingDTO[]) {
    super(element.id, ratingSystem);
  }
}

export class QpTestsChecklistAverageNumberElementRating extends QpTestsChecklistElementRating {
  private readonly _value: number | undefined;
  private readonly _successCriteria: IQpNumberRatingsConstraint;

  public constructor(element: Readonly<IQpTestsChecklistElementRatingAverageNumber>, ratingSystem: ChecklistRatingDTO[]) {
    super(element.id, ratingSystem);
    this._value = element?.value?.average;
    this._successCriteria = element.successCriteria;
  }

  public get ratingResult(): ChecklistRatingResultDTO {
    if (this._value === undefined) {
      return DEFAULT_RATING_RESULT;
    }

    const checklistRatingId = qpGetConstraintMatchingValue(this._successCriteria.criterias, this._value)?.checklistRatingId ?? 0;

    return this.ratingSystem.find((rating): boolean => rating.id === checklistRatingId) ?? DEFAULT_RATING_RESULT;
  }
}

export class QpTestsChecklistNumberElementRating extends QpTestsChecklistElementRating {
  private readonly _value: number | undefined;
  private readonly _successCriteria: IQpNumberRatingsConstraint;

  public constructor(element: Readonly<IQpTestsChecklistElementRatingNumber>, ratingSystem: ChecklistRatingDTO[]) {
    super(element.id, ratingSystem);
    this._value = element?.value;
    this._successCriteria = element.successCriteria;
  }

  public get ratingResult(): ChecklistRatingResultDTO {
    if (this._value === undefined) {
      return DEFAULT_RATING_RESULT;
    }

    const checklistRatingId = qpGetConstraintMatchingValue(this._successCriteria.criterias, this._value)?.checklistRatingId ?? 0;

    return this.ratingSystem.find((rating): boolean => rating.id === checklistRatingId) ?? DEFAULT_RATING_RESULT;
  }
}

export class QpTestsChecklistElementRatingFactory {
  public static buildElement(
    element: Readonly<QpTestsChecklistElementRatingType>,
    ratingSystem: ChecklistRatingDTO[]
  ): QpTestsChecklistElementRating | never {
    switch (element.type) {
      case EQpQuestionType.DISTRIBUTION:
        return new QpTestsChecklistDistributionElementRating(element, ratingSystem);
      case EQpQuestionType.NUMBER:
        return new QpTestsChecklistNumberElementRating(element, ratingSystem);
      case EQpQuestionType.AVERAGE_NUMBER:
        return new QpTestsChecklistAverageNumberElementRating(element, ratingSystem);
      default:
        throw new Error(`Unexpected tests checklist element type: ${element.type}`);
    }
  }
}
