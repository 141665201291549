import { QpResponsiveService } from '@library/services/qp-responsive/qp-responsive.service';
import { AccountService } from '@one/app/shared/services/account/account.service';
import { TrackingService } from '@one/app/shared/services/third-party/analytics-manager/tracking/tracking.service';
import { aNotificationOpenedEvent } from '@one/app/shared/services/third-party/analytics-manager/tracking/tracking.utils';
import { ENotifuseClassName } from '@one/app/shared/services/third-party/notifuse/notifuse.models';
import { NotifuseService } from '@one/app/shared/services/third-party/notifuse/notifuse.service';
import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { QimaOptionalType, EQimaIconName, QimaIconModule } from '@qima/ngx-qima';
import { isEmpty } from 'lodash/index';

@Component({
  selector: 'one-notifuse-nav',
  standalone: true,
  imports: [CommonModule, QimaIconModule],
  templateUrl: './notifuse-nav.component.html',
  styleUrls: ['./notifuse-nav.component.scss'],
})
export class NotifuseNavComponent implements OnInit {
  public readonly iconNames: typeof EQimaIconName = EQimaIconName;

  @ViewChild('notifuseTemplate', { static: false }) public notifuseTemplate: QimaOptionalType<ElementRef> = undefined;

  public constructor(
    private readonly _accountService: AccountService,
    private readonly _notifuseService: NotifuseService,
    private readonly _qpResponsiveService: QpResponsiveService,
    private readonly _trackingService: TrackingService
  ) {}

  public ngOnInit(): void {
    this._initNotifuse();
  }

  public isNotifuseUser(): boolean {
    return !isEmpty(this._accountService.getNotifuseUserId());
  }

  public isWidgetOpen(): boolean {
    const notifuseWidget: QimaOptionalType<HTMLDivElement> = this.notifuseTemplate?.nativeElement?.querySelector(
      `.${ENotifuseClassName.CONTAINER as string}`
    );

    return !!notifuseWidget;
  }

  public onNotifuseIconClick(): void {
    this._trackingService.track(aNotificationOpenedEvent());
  }

  public isMobile(): boolean {
    return this._qpResponsiveService.isMobile();
  }

  public showNotifuseMobileOverlay(): boolean {
    return this.isNotifuseUser() && this.isMobile() && this.isWidgetOpen();
  }

  private _initNotifuse(): void {
    if (this.isNotifuseUser()) {
      this._notifuseService.init();
    }
  }
}
