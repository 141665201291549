import { getQimaConnectConfigFactory } from '@one/app/shared/factories/qima-connect-config.factory';
import { QIMA_CONNECT_CONFIG } from '@one/app/shared/tokens/qima-connect-config.token';
import { WINDOW } from '@one/app/shared/tokens/window.token';
import { FactoryProvider } from '@angular/core';

export const QIMA_CONNECT_CONFIG_PROVIDER: FactoryProvider = {
  deps: [WINDOW],
  provide: QIMA_CONNECT_CONFIG,
  useFactory: getQimaConnectConfigFactory,
};
