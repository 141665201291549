import { ChecklistRatingResultDTO } from '@library/dto/checklist/checklist-rating-result.dto';
import { ChecklistRatingDTO } from '@library/dto/checklist/checklist-rating.dto';
import { ConsolidateResultByMethod } from '@library/dto-enums/report-group/settings/consolidate-result-by-method.dto-enum';
import { qpAreRatingSystemsEqual } from '@library/functions/ratings/qp-are-rating-systems-equal';
import { qpComputeWorstRatingIndex } from '@library/functions/ratings/qp-compute-worst-rating-index';
import { DEFAULT_RATING_RESULT } from '@library/models/qp-ratings.models';
import { isNil } from 'lodash/index';

/**
 * @param {OverallRatingResultComputationInfo[]} items the list of rating system, result and weights
 * @returns {OverallRatingResultComputationInfo[][]} the items grouped by rating system
 */
function groupItemsByRatingSystem(items: OverallRatingResultComputationInfo[]): OverallRatingResultComputationInfo[][] {
  const groupedItems: OverallRatingResultComputationInfo[][] = [];

  items.forEach((item): void => {
    const index = groupedItems.findIndex((group): boolean =>
      qpAreRatingSystemsEqual(group[0].ratingSystemRatingsUsed, item.ratingSystemRatingsUsed)
    );

    if (isNil(item.ratingResult) || item.ratingResult.name === DEFAULT_RATING_RESULT.name) {
      return;
    }

    if (index === -1) {
      groupedItems.push([item]);
    } else {
      groupedItems[index].push(item);
    }
  });

  return groupedItems;
}

/**
 * @description get the worst rating of each group
 * @param {OverallRatingResultComputationInfo[][]} groupedItems the items grouped by rating system
 * @returns {ChecklistRatingDTO[]} the worst rating of each group
 */
function getWorstRatingOfEachGroup(groupedItems: OverallRatingResultComputationInfo[][]): ChecklistRatingResultDTO[] {
  return groupedItems.map((group): ChecklistRatingResultDTO => {
    return qpGetWorstRating(
      group[0].ratingSystemRatingsUsed,
      group.map((item): ChecklistRatingResultDTO => item.ratingResult)
    );
  });
}

/**
 * @description get the average rating of each group
 * @param {OverallRatingResultComputationInfo[][]} groupedItems the items grouped by rating system
 * @returns {ChecklistRatingDTO[]} the worst rating of each group
 */
function getAverageRatingOfEachGroup(
  groupedItems: OverallRatingResultComputationInfo[][],
  isWeightageApplied: boolean
): ChecklistRatingResultDTO[] {
  return groupedItems.map((group): ChecklistRatingResultDTO => {
    return isWeightageApplied
      ? qpGetAverageRatingByWeight(group)
      : qpGetAverageRating(
          group[0].ratingSystemRatingsUsed,
          group.map((item): ChecklistRatingResultDTO => item.ratingResult)
        );
  });
}

/**
 * @description
 * @param {ChecklistRatingResultDTO[]} ratingSystem the rating system to get the worst rating from
 * @param {ChecklistRatingResultDTO[]} ratingResults the list of rating results
 * @returns {ChecklistRatingResultDTO} the worst rating of the rating system
 */
export function qpGetWorstRating(ratingSystem: ChecklistRatingDTO[], ratingResults: ChecklistRatingResultDTO[]): ChecklistRatingResultDTO {
  const worstRatingIndex = qpComputeWorstRatingIndex(ratingSystem, ratingResults);

  return worstRatingIndex === -1
    ? DEFAULT_RATING_RESULT
    : {
        ...ratingSystem[worstRatingIndex],
        weight: qpGetRatingWeight(ratingSystem.length, worstRatingIndex),
      };
}

/**
 * @description
 * Here we are doing the average of the indexes of the rating system
 * Not using the weight of the rating system
 * But using the index to simplify the calculation
 * @param {ChecklistRatingResultDTO[]} ratingSystem the rating system to get the worst rating from
 * @param {ChecklistRatingResultDTO[]} ratingResults the list of rating results
 * @returns {ChecklistRatingResultDTO} the worst rating of the rating system
 */
export function qpGetAverageRating(
  ratingSystem: ChecklistRatingDTO[],
  ratingResults: ChecklistRatingResultDTO[]
): ChecklistRatingResultDTO {
  const averageIndex = Math.round(
    ratingResults.reduce((acc, current): number => acc + ratingSystem.findIndex((r): boolean => r.name === current.name), 0) /
      ratingResults.length
  );

  return averageIndex === -1
    ? DEFAULT_RATING_RESULT
    : {
        ...ratingSystem[averageIndex],
        weight: qpGetRatingWeight(ratingSystem.length, averageIndex),
      };
}

/**
 * @description
 * Here we are doing the average of the indexes of the rating system
 * Not using the weight of the rating system
 * But using the index to simplify the calculation
 * @param {OverallRatingResultComputationInfo[]} group the list of rating results, rating systems and weight
 * @returns {ChecklistRatingResultDTO} the worst rating of the rating system
 */
export function qpGetAverageRatingByWeight(group: OverallRatingResultComputationInfo[]): ChecklistRatingResultDTO {
  const ratingSystem = group[0].ratingSystemRatingsUsed;
  const ratingResults = group.map((item): ChecklistRatingResultDTO => item.ratingResult);
  const averageIndex = Math.round(
    ratingResults.reduce(
      (acc, current, index): number => acc + ratingSystem.findIndex((r): boolean => r.name === current.name) * (group[index].weight ?? 0),
      0
    ) / group.reduce((acc, current): number => acc + (current.weight ?? 0), 0)
  );

  return averageIndex >= 0
    ? {
        ...ratingSystem[averageIndex],
        weight: qpGetRatingWeight(ratingSystem.length, averageIndex),
      }
    : DEFAULT_RATING_RESULT;
}

/**
 * @param {number} ratingSystemLength the length of the rating system
 * @param {number} ratingIndex the index of the rating
 * @returns {number} the weight of the rating
 */
export function qpGetRatingWeight(ratingSystemLength: number, ratingIndex: number): number {
  return (1 / ratingSystemLength) * (ratingIndex + 1);
}

export interface OverallRatingResultComputationInfo {
  ratingSystemRatingsUsed: ChecklistRatingDTO[];
  ratingResult: ChecklistRatingResultDTO;
  weight?: number;
}

/**
 * @description
 * @param {ConsolidateResultByMethod} method
 * @param {OverallRatingResultComputationInfo[]} items to get the mixed rating result from
 * @param {boolean} isWeightageApplied
 * @returns {ChecklistRatingResultDTO[]} the mixed rating result
 */
export function qpGetOverallRatingResult(
  items: OverallRatingResultComputationInfo[],
  method: ConsolidateResultByMethod = ConsolidateResultByMethod.WORST,
  isWeightageApplied: boolean = false
): ChecklistRatingResultDTO[] {
  const itemsGroupedByRatingSystem = groupItemsByRatingSystem(items);

  if (method === ConsolidateResultByMethod.WORST) {
    return getWorstRatingOfEachGroup(itemsGroupedByRatingSystem);
  }

  return getAverageRatingOfEachGroup(itemsGroupedByRatingSystem, isWeightageApplied);
}
