export enum EDefectClassificationAqlMapping {
  MINOR = 'minor',
  MAJOR = 'major',
  CRITICAL = 'critical',
}

export enum DefectClassificationIcons {
  MINOR = 'ic-defect-minor',
  MAJOR = 'ic-defect-major',
  CRITICAL = 'ic-defect-critical',
}
