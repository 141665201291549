// @ts-strict-ignore
import { EQpQuestionType } from '@library/models/qp-question.models';
import {
  IQpConstraint,
  IQpTestsChecklistElement,
  IQpTestsChecklistElementTableRows,
  IQpTestsChecklistElementTableValue,
  IQpYesNoModel,
  IQpYesNoNaModel,
  QpSuccessCriteriaType,
  QpTestsChecklistElementTableColumnType,
  QpTestsChecklistElementType,
} from '@library/models/qp-tests-checklist-element.models';
import { EYesNoNa } from '@one/app/pages/isp/pages/inspection/pages/id/pages/categories/pages/id/pages/test/pages/id/components/tests-checklist-element/tests-checklist-element-yes-no/models/isp-inspection-id-categories-id-test-id-yes-no-na.models';
import WorkflowUtils from '@one/app/pages/isp/pages/inspection/pages/id/shared/services/workflow.utils';
import { YesNoAnswerModel } from '@one/app/shared/classes/answer/yes-no-answer';
import { InspectionAttachment } from '@one/app/shared/classes/inspection/inspection-attachment';
import { INSPECTION_COMMENT_VALIDATORS, InspectionComment } from '@one/app/shared/classes/inspection/inspection-comment';
import { InspectionImage } from '@one/app/shared/classes/inspection/inspection-image';
import { SamplingSizeModel } from '@one/app/shared/classes/sampling/sampling-size-model';
import { Answer, MultipleChoiceAnswer } from '@one/app/shared/models/answer/answer.models';
import { AnswerModelType } from '@one/app/shared/models/answer/yes-no-answer.models';
import { EChecklistRequirementType, IChecklistRequirement } from '@one/app/shared/models/checklist/checklist-requirement.models';
import {
  EChecklistImageType,
  IAttachmentData,
  IChecklistComment,
  IChecklistImageData,
} from '@one/app/shared/models/inspection-consultation/inspection-consultation.models';
import { ESamplingSizeType } from '@one/app/shared/models/sampling/sampling-size.models';
import { IWorkflowAction } from '@one/app/shared/models/workflow/workflow.models';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { QimaOptionalType } from '@qima/ngx-qima';

const FORM_CONTROL_SCAN = 'scans';
const FORM_CONTROL_IMAGES = 'images';
const FORM_CONTROL_COMMENT = 'comment';
const FORM_CONTROL_ATTACHMENT = 'attachment';

export class TestsChecklistElement implements IQpTestsChecklistElement<Answer> {
  public static newEmpty(): TestsChecklistElement {
    return new TestsChecklistElement();
  }

  public static fromITestsChecklistElements(
    workflowAction: IWorkflowAction,
    elements: IQpTestsChecklistElement<Answer>[]
  ): TestsChecklistElement[] {
    return elements.map(
      (element): TestsChecklistElement =>
        TestsChecklistElement.fromITestsChecklistElement(element as QpTestsChecklistElementType, workflowAction)
    );
  }

  public static fromITestsChecklistElement(iElement: QpTestsChecklistElementType, workflowAction?: IWorkflowAction): TestsChecklistElement {
    const element = new TestsChecklistElement();

    element.type = iElement.type;
    element.guidelines = iElement.guidelines;
    element.id = iElement.id;
    element.name = iElement.name;
    element.requirements = iElement.requirements;
    element.successCriteria = iElement.successCriteria;
    element.constraint = iElement.constraint;
    element.instruction = iElement.instruction;
    element.instructionsDocuments = iElement.instructionsDocuments;
    element.expectedResult = iElement.expectedResult;
    element.samplingSize = iElement.samplingSize;
    element.providedByQima = iElement.providedByQima;
    element.qimaCertKey = iElement.qimaCertKey;
    element.elementValue = iElement.value;
    element.notApplicable = iElement.notApplicable;
    element.form = TestsChecklistElement.createForm(iElement);
    element.commentsMandatoryWhenNotApplicable = workflowAction?.commentsMandatoryWhenNotApplicable ?? false;

    if (iElement.elements) {
      element.elements = TestsChecklistElement.fromITestsChecklistElements(workflowAction, iElement.elements);
      element.form.addControl('elements', new UntypedFormArray(element.elements.map((el): UntypedFormGroup => el.form)));
    }

    const isCommentRequired: boolean =
      (element.notApplicable && element.commentsMandatoryWhenNotApplicable) ||
      (!element.notApplicable &&
        element.requirements?.some((req: IChecklistRequirement): boolean => req.type === EChecklistRequirementType.COMMENT));

    if (iElement.comment) {
      element.comment = InspectionComment.fromIInspectionComment(iElement.comment, isCommentRequired);
      element.form.addControl(FORM_CONTROL_COMMENT, element.comment.form);
    } else if (element.requirements && isCommentRequired) {
      element.form.addControl(
        FORM_CONTROL_COMMENT,
        new UntypedFormGroup({
          message: new UntypedFormControl('', isCommentRequired ? INSPECTION_COMMENT_VALIDATORS : []),
          timezone: new UntypedFormControl(''),
          date: new UntypedFormControl(''),
        })
      );
    }

    const images = iElement.images || [];

    element.images = InspectionImage.fromIChecklistImageDatas(images);
    const forms = element.images.map((img): UntypedFormGroup => img.form);

    element.form.addControl(FORM_CONTROL_IMAGES, new UntypedFormArray(forms));

    const scans = iElement.scans || [];

    element.scans = InspectionImage.fromIChecklistImageDatas(scans);
    const scanForms = element.scans.map((img): UntypedFormGroup => img.form);

    element.form.addControl(FORM_CONTROL_SCAN, new UntypedFormArray(scanForms));

    const attachment = iElement.attachments ?? [];

    element.attachments = InspectionAttachment.fromIChecklistAttachmentData(attachment);
    const attchmentForms = element.attachments.map((attachment): UntypedFormGroup => attachment.form);

    element.form.addControl(FORM_CONTROL_ATTACHMENT, new UntypedFormArray(attchmentForms));

    if (!element.notApplicable) {
      if (element.requirements?.some((req): boolean => req.type === EChecklistRequirementType.PICTURE)) {
        element.form.get(FORM_CONTROL_IMAGES).setValidators(Validators.required);
        element.form.get(FORM_CONTROL_IMAGES).updateValueAndValidity();

        if (element.requirements?.some((req): boolean => req.type === EChecklistRequirementType.SPOTLIGHT)) {
          element.form
            .get(FORM_CONTROL_IMAGES)
            .addValidators((): ValidationErrors | null =>
              element.images.some((image): boolean => image.isSpotlight) ? null : { spotlightError: true }
            );
          element.form.get(FORM_CONTROL_IMAGES).updateValueAndValidity();
        }
      }

      if (element.requirements?.some((req): boolean => req.type === EChecklistRequirementType.SCAN)) {
        element.form.get(FORM_CONTROL_SCAN).setValidators(Validators.required);
        element.form.get(FORM_CONTROL_SCAN).updateValueAndValidity();
      }

      if (element.requirements?.some((req): boolean => req.type === EChecklistRequirementType.ATTACHMENT)) {
        element.form.get(FORM_CONTROL_ATTACHMENT).setValidators(Validators.required);
        element.form.get(FORM_CONTROL_ATTACHMENT).updateValueAndValidity();
      }
    }

    return element;
  }

  public static isYesNoFailAnswer(answer: EYesNoNa, successCriteria: IQpYesNoModel): boolean {
    if (null == answer) {
      return false;
    }

    return answer !== successCriteria?.value;
  }

  public static isYesNoNaFailAnswer(answer: EYesNoNa, successCriteria: IQpYesNoNaModel): boolean {
    if (null == answer) {
      return false;
    }

    return successCriteria?.values.indexOf(answer) === -1;
  }

  private static _initializeChecklistElementTableRowValue(tableValue: IQpTestsChecklistElementTableValue): void {
    const row: IQpTestsChecklistElementTableRows = {
      columns: tableValue.header.columns.map((column: QpTestsChecklistElementTableColumnType): QpTestsChecklistElementTableColumnType => {
        return { ...column, id: this._generateColumnId(column.id) };
      }),
    };

    tableValue.rows.push(row);
  }

  private static _generateColumnId(id: string): string {
    // split the id into array 2-3-1-1-1-1 => [2, 3, 1, 1, 1, 1]
    const pathIds = id.split('-');

    // add '1' to the second last element of the array [2, 3, 1, 1, 1, '1', 1]
    pathIds.splice(pathIds.length - 1, 0, '1');

    // join the array to make the id again 2-3-1-1-1-1-1
    return pathIds.join('-');
  }

  private static isFailAnswer(iElement: QpTestsChecklistElementType): boolean {
    if (iElement.value == null) {
      return false;
    }

    if (iElement.type === EQpQuestionType.YES_NO) {
      const typedAnswer = <AnswerModelType>iElement.value;
      const typedAnswerValue = typedAnswer.value ? EYesNoNa.YES : EYesNoNa.NO;

      return this.isYesNoFailAnswer(typedAnswerValue, <IQpYesNoModel>iElement.successCriteria);
    } else if (iElement.type === EQpQuestionType.YES_NO_NA) {
      const typedAnswer = <AnswerModelType>iElement.value;
      const typedAnswerValue = <EYesNoNa>typedAnswer.value;

      return this.isYesNoNaFailAnswer(typedAnswerValue, <IQpYesNoNaModel>iElement.successCriteria);
    }

    return false;
  }

  private static createForm(iElement: QpTestsChecklistElementType): UntypedFormGroup {
    if (iElement.type === EQpQuestionType.YES_NO || iElement.type === EQpQuestionType.YES_NO_NA) {
      let yesNoAnswer = iElement.value as AnswerModelType;

      if (!iElement.value) {
        yesNoAnswer = YesNoAnswerModel.newEmpty();
      }

      let failureCount: number;
      const isFailAnswer = this.isFailAnswer(iElement);

      if (isFailAnswer) {
        failureCount = yesNoAnswer.failureCount === null || yesNoAnswer.failureCount === undefined ? 1 : yesNoAnswer.failureCount;
      }

      return new UntypedFormGroup({
        value: new UntypedFormGroup({
          value: new UntypedFormControl(yesNoAnswer.value),
          failureCount: new UntypedFormControl(failureCount, isFailAnswer ? Validators.required : null),
        }),
      });
    } else if (iElement.type === EQpQuestionType.MULTIPLE_CHOICE) {
      const currentValues = (iElement.value || []) as MultipleChoiceAnswer;
      const formGroup = new UntypedFormGroup({
        constraint: new UntypedFormGroup({}),
      });
      const constraints = formGroup.get('constraint') as UntypedFormGroup;

      iElement.constraint?.values.forEach((value): void =>
        constraints.addControl(value, new UntypedFormControl(currentValues.includes(value)))
      );

      return formGroup;
    } else if (iElement.type === EQpQuestionType.TABLE) {
      const testsChecklistElementTableValue: IQpTestsChecklistElementTableValue = iElement.value as IQpTestsChecklistElementTableValue;

      if (testsChecklistElementTableValue.rows.length === 0) {
        this._initializeChecklistElementTableRowValue(testsChecklistElementTableValue);
      }

      const formGroup = new UntypedFormGroup({
        rows: new UntypedFormArray(
          testsChecklistElementTableValue.rows.map(
            (row: IQpTestsChecklistElementTableRows): UntypedFormGroup =>
              new UntypedFormGroup({
                columns: new UntypedFormArray(
                  row.columns.map(
                    (column: QpTestsChecklistElementTableColumnType): UntypedFormGroup =>
                      new UntypedFormGroup({
                        id: new UntypedFormControl(column.id),
                        type: new UntypedFormControl(column.type),
                        name: new UntypedFormControl(column.name),
                        value: new UntypedFormControl(column.value),
                      })
                  )
                ),
              })
          )
        ),
      });

      return formGroup;
    }

    return new UntypedFormGroup({
      value: new UntypedFormControl(iElement.value),
    });
  }

  public type: EQpQuestionType;
  public guidelines: object;
  public id: string;
  public name: string;
  public samplingSize?: SamplingSizeModel<ESamplingSizeType>;
  public requirements?: IChecklistRequirement[];
  public constraint?: IQpConstraint;
  public successCriteria?: QpSuccessCriteriaType;
  public elements: TestsChecklistElement[];
  public comment: InspectionComment;
  public form: QimaOptionalType<UntypedFormGroup> = undefined;
  public images: InspectionImage[];
  public scans: InspectionImage[];
  public attachments: InspectionAttachment[];
  public expectedResult: string;
  public instruction: string;
  public instructionsDocuments: string[];
  public providedByQima: boolean;
  public isSpotlight: boolean;
  public qimaCertKey: string;
  public elementValue?: Answer;
  public notApplicable: boolean;
  public commentsMandatoryWhenNotApplicable: boolean = false;
  public excludeFromInspectionResult: boolean = false;

  public get value(): Answer {
    return this.form.getRawValue().value as Answer;
  }

  public set value(value: Answer) {
    this.form.controls.value.setValue(value);
  }

  public get valueAsFormGroup(): UntypedFormGroup {
    return this.form.controls.value as UntypedFormGroup;
  }

  public get valueAsFormControl(): UntypedFormControl {
    return this.form.controls.value as UntypedFormControl;
  }

  public get isNotFilled(): boolean {
    const value = [EQpQuestionType.MULTIPLE_CHOICE, EQpQuestionType.TEXT, EQpQuestionType.TABLE].includes(this.type)
      ? this.elementValue
      : this.value;

    return !WorkflowUtils.isTestChecklistElementFulfilled({ ...this, value });
  }

  public get notFilledCounter(): number {
    return this.elements.filter(
      (element): boolean => (!element.form.valid && !element.form.disabled && !element.notApplicable) || element.isNotFilled
    ).length;
  }

  public addImage(imageData: IChecklistImageData): void {
    const image = InspectionImage.fromInspectionImageData(imageData);

    if (image.type === EChecklistImageType.SCAN) {
      this.scans.push(image);
      (this.form.get(FORM_CONTROL_SCAN) as UntypedFormArray).push(image.form);
    } else {
      this.images.push(image);
      (this.form.get(FORM_CONTROL_IMAGES) as UntypedFormArray).push(image.form);
    }
  }

  public addAttachment(attachmentData: IAttachmentData): void {
    this.attachments.push(InspectionAttachment.fromInspectionAttachmentData(attachmentData));
  }

  public removeAttachment(attachmentId: string): void {
    this.attachments = this.attachments.filter((attachment: IAttachmentData): boolean => attachment.attachmentId !== attachmentId);
  }

  public reorderPictures(newPictureOrderIds: string[]): void {
    this.images.sort((imageA, imageB): number => newPictureOrderIds.indexOf(imageA.imageId) - newPictureOrderIds.indexOf(imageB.imageId));
  }

  public addComment(iComment: IChecklistComment): void {
    const comment = InspectionComment.fromIInspectionComment(iComment);

    this.comment = comment;
    this.form.addControl(FORM_CONTROL_COMMENT, comment.form);
  }
}
