/**
 * @description This enum reflects `com.qima.platform.service.dto.testschecklist.answer.success.enumeration.NumberSuccessCriteria`
 */
export enum NumberSuccessCriteria {
  GT = 'GT',
  GTE = 'GTE',
  LT = 'LT',
  LTE = 'LTE',
  BETWEEN = 'BETWEEN',
  BETWEEN_INCLUSIVE = 'BETWEEN_INCLUSIVE',
}
