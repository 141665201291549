export class BrdRoutes {
  public static root(): string {
    return `/brd`;
  }

  public static home(): string {
    return `${BrdRoutes.root()}/home`;
  }

  // ACCOUNT
  public static profileConsultation(): string {
    return '/account/profile';
  }

  public static profileBilling(): string {
    return '/account/profile/billing';
  }

  public static profileEdition(): string {
    return '/account/profile/edit';
  }

  public static passwordEdition(): string {
    return '/account/profile/password';
  }

  // WORKFLOWS
  public static workflow(): string {
    return `${BrdRoutes.root()}/workflow`;
  }

  public static workflowConsultation(): string {
    return `${BrdRoutes.root()}/workflow/consult`;
  }

  public static workflowView(id: Readonly<number>): string {
    return `${BrdRoutes.root()}/workflow/${id}`;
  }

  public static workflowEdit(id: Readonly<number>): string {
    return `${BrdRoutes.root()}/workflow/${id}/edit`;
  }

  public static workflowCreation(): string {
    return `${BrdRoutes.root()}/workflow/create`;
  }

  public static auditWorkflowCreation(): string {
    return `${BrdRoutes.root()}/workflow/audit/create`;
  }

  public static workflows(): string {
    return `${BrdRoutes.root()}/workflow`;
  }

  public static checklistsConsultation(): string {
    return `${BrdRoutes.root()}/workflow/checklist/consult`;
  }

  // INSPECTIONS
  public static inspection(): string {
    return `${BrdRoutes.root()}/inspection`;
  }

  public static inspectionConsultation(): string {
    return `${BrdRoutes.root()}/inspection/consult`;
  }

  public static inspectionAllocations(): string {
    return `${BrdRoutes.root()}/inspection/assignments`;
  }

  public static inspectionCreationV2(): string {
    return `${BrdRoutes.root()}/inspection/create`;
  }

  public static inspectionCreationV2Product(): string {
    return `${BrdRoutes.root()}/inspection/create/product`;
  }

  public static inspectionCreationV2Site(): string {
    return `${BrdRoutes.root()}/inspection/create/site`;
  }

  public static inspectionCreationV2Inspection(): string {
    return `${BrdRoutes.root()}/inspection/create/inspection`;
  }

  public static inspectionCreationV2Inspector(): string {
    return `${BrdRoutes.root()}/inspection/create/inspector`;
  }

  public static inspectionCreationV2Workflow(): string {
    return `${BrdRoutes.root()}/inspection/create/workflow`;
  }

  public static inspectionCreationV2Summary(): string {
    return `${BrdRoutes.root()}/inspection/create/summary`;
  }

  public static inspectionEditionV2(id: Readonly<number>): string {
    return `${BrdRoutes.root()}/inspection/${id}/edit`;
  }

  public static inspectionEditionV2Product(id: Readonly<number>): string {
    return `${BrdRoutes.root()}/inspection/${id}/edit/product`;
  }

  public static inspectionEditionV2Site(id: Readonly<number>): string {
    return `${BrdRoutes.root()}/inspection/${id}/edit/site`;
  }

  public static inspectionEditionV2Inspection(id: Readonly<number>): string {
    return `${BrdRoutes.root()}/inspection/${id}/edit/inspection`;
  }

  public static inspectionEditionV2Inspector(id: Readonly<number>): string {
    return `${BrdRoutes.root()}/inspection/${id}/edit/inspector`;
  }

  public static inspectionEditionV2Workflow(id: Readonly<number>): string {
    return `${BrdRoutes.root()}/inspection/${id}/edit/workflow`;
  }

  public static inspectionEditionV2Summary(id: Readonly<number>): string {
    return `${BrdRoutes.root()}/inspection/${id}/edit/summary`;
  }

  public static assignInspector(id: Readonly<number>): string {
    return `${BrdRoutes.root()}/inspection/${id}/assign`;
  }

  public static changeSite(id: Readonly<number>): string {
    return `${BrdRoutes.root()}/inspection/${id}/change-site`;
  }

  public static inspectionDetails(id: Readonly<number>): string {
    return `${BrdRoutes.root()}/inspection/${id}/details`;
  }

  // AUDITS
  public static audit(): string {
    return `${BrdRoutes.root()}/audit`;
  }

  public static auditConsultation(): string {
    return `${BrdRoutes.audit()}/consult`;
  }

  public static auditCreation(): string {
    return `${BrdRoutes.audit()}/create`;
  }

  // CAP
  public static capMenu(): string {
    return `${BrdRoutes.root()}/cap`;
  }

  public static capReport(): string {
    return `${BrdRoutes.root()}/report/cap`;
  }

  public static capReportConsultation(): string {
    return `${BrdRoutes.capReport()}/consult`;
  }

  public static capReportDetails(reportId: Readonly<number>, capId: Readonly<number>): string {
    return `${BrdRoutes.capReport()}/${capId}/${reportId}`;
  }

  // TO remove once the link between report and cap deleted
  public static capCreation(reportId: Readonly<number>): string {
    return `${BrdRoutes.root()}/report/${reportId}/cap/create`;
  }

  // REPORTS
  public static reportConsultation(): string {
    return `${BrdRoutes.root()}/report/inspection/consult`;
  }

  public static report(): string {
    return `${BrdRoutes.root()}/report`;
  }

  public static reportDetail(id: Readonly<number>): string {
    return `${BrdRoutes.root()}/report/inspection/${id}`;
  }

  public static reportPrint(id: Readonly<number>): string {
    return `${BrdRoutes.root()}/report/inspection/${id}/print`;
  }

  public static externalReportDetail(id: Readonly<number>): string {
    return `${BrdRoutes.root()}/report/external-report/${id}`;
  }

  public static externalReportCreate(): string {
    return `${BrdRoutes.root()}/report/external-report/create`;
  }

  public static externalReportCreateInspectionDetails(): string {
    return `${BrdRoutes.root()}/report/external-report/create/inspection-details`;
  }

  public static externalReportCreateInspectionSite(): string {
    return `${BrdRoutes.root()}/report/external-report/create/inspection-site`;
  }

  public static externalReportEdit(id: Readonly<number>): string {
    return `${BrdRoutes.root()}/report/external-report/${id}/edit`;
  }

  public static externalReportEditInspectionDetails(id: Readonly<number>): string {
    return `${BrdRoutes.root()}/report/external-report/${id}/edit/inspection-details`;
  }

  public static externalReportEditInspectionSite(id: Readonly<number>): string {
    return `${BrdRoutes.root()}/report/external-report/${id}/edit/inspection-site`;
  }

  // AUDIT CHECKLISTS
  public static auditChecklistCreation(): string {
    return `${BrdRoutes.root()}/workflow/audit-checklist/create`;
  }

  public static auditChecklistEdit(id: Readonly<number>): string {
    return `${BrdRoutes.root()}/workflow/audit-checklist/${id}/edit`;
  }

  public static auditChecklistView(id: Readonly<number>): string {
    return `${BrdRoutes.root()}/workflow/audit-checklist/${id}`;
  }

  // TEST CHECKLISTS
  public static testChecklistView(id: Readonly<number>): string {
    return `${BrdRoutes.root()}/workflow/test-checklist/${id}`;
  }

  public static testChecklistEdit(id: Readonly<number>): string {
    return `${BrdRoutes.root()}/workflow/test-checklist/${id}/edit`;
  }

  public static testChecklistCreation(): string {
    return `${BrdRoutes.root()}/workflow/test-checklist/create`;
  }

  public static testChecklistImport(): string {
    return `${BrdRoutes.root()}/workflow/test-checklist/import`;
  }

  // DEFECT CHECKLISTS
  public static defectChecklistView(id: Readonly<number>): string {
    return `${BrdRoutes.root()}/workflow/defect-checklist/${id}`;
  }

  public static defectChecklistEdit(id: Readonly<number>): string {
    return `${BrdRoutes.root()}/workflow/defect-checklist/${id}/edit`;
  }

  public static defectChecklistCreation(): string {
    return `${BrdRoutes.root()}/workflow/defect-checklist/create`;
  }

  public static defectChecklistImport(): string {
    return `${BrdRoutes.root()}/workflow/defect-checklist/import`;
  }

  // MEASUREMENT CHECKLIST
  public static measurementChecklistView(id: Readonly<number>): string {
    return `${BrdRoutes.root()}/workflow/measurement-checklist/${id}`;
  }

  // TEAM
  public static userConsultation(): string {
    return `${BrdRoutes.root()}/team/user`;
  }

  public static userCreation(): string {
    return `${BrdRoutes.root()}/team/create`;
  }

  public static userEdition(id: number): string {
    return `${BrdRoutes.root()}/team/user/${id}/edit`;
  }

  public static inspectorConsultation(): string {
    return `${BrdRoutes.root()}/team/inspector`;
  }

  public static inspectorImport(): string {
    return `${BrdRoutes.root()}/team/import`;
  }

  public static analytics(): string {
    return `${BrdRoutes.root()}/analytics`;
  }

  public static auditorConsultation(): string {
    return `${BrdRoutes.root()}/team/auditor`;
  }

  // PRODUCTS
  public static product(): string {
    return `${this.root()}/product`;
  }

  public static productProduct(): string {
    return `${this.product()}/product`;
  }

  public static productProductConsult(): string {
    return `${this.productProduct()}/consult`;
  }

  public static productProductCreation(): string {
    return `${this.productProduct()}/create`;
  }

  public static productProductView(id: Readonly<number>): string {
    return `${this.productProduct()}/${id}/information`;
  }

  public static productProductEdit(id: Readonly<number>): string {
    return `${this.productProduct()}/${id}/edit`;
  }

  // PRODUCTS PO
  public static productPo(): string {
    return `${this.root()}/product-po`;
  }

  public static productPoConsultation(id: Readonly<number>): string {
    return `${this.root()}/product-po/po/${id}`;
  }

  public static productPoProduct(): string {
    return `${this.productPo()}/product`;
  }

  public static productPoProductConsult(): string {
    return `${this.productPoProduct()}/consult`;
  }

  public static productPoProductCreation(): string {
    return `${this.productPoProduct()}/create`;
  }

  public static productPoProductIdMeasurements(id: Readonly<string>): string {
    return `${this.productPoProduct()}/${id}/measurements`;
  }

  public static productPoProductIdMeasurementsImport(id: Readonly<number>): string {
    return `${this.productPoProduct()}/${id}/measurements/import`;
  }

  public static productPoProductImport(): string {
    return `${this.productPoProduct()}/import`;
  }

  public static productPoProductView(id: Readonly<number>): string {
    return `${this.productPoProduct()}/${id}/information`;
  }

  public static productPoProductEdit(id: Readonly<number>): string {
    return `${this.productPoProduct()}/${id}/edit`;
  }

  public static productPoProductLabTestReports(id: Readonly<number>): string {
    return `${this.productPoProduct()}/${id}/lab-tests/reports`;
  }

  // PURCHASE ORDERS
  public static productPoPurchaseOrder(): string {
    return `${this.productPo()}/po`;
  }

  public static purchaseOrderImport(): string {
    return `${this.productPoPurchaseOrder()}/import`;
  }

  public static productPoPurchaseOrderConsult(): string {
    return `${this.productPoPurchaseOrder()}/consult`;
  }

  public static productPoPurchaseOrderCreation(): string {
    return `${this.productPoPurchaseOrder()}/create`;
  }

  public static productPoPurchaseOrderView(id: Readonly<number>): string {
    return `${this.productPoPurchaseOrder()}/${id}`;
  }

  public static productPoPurchaseOrderEdit(id: Readonly<number>): string {
    return `${this.productPoPurchaseOrder()}/${id}/edit`;
  }

  // ENTITY
  public static network(): string {
    return `${BrdRoutes.root()}/network`;
  }

  public static networkEntity(): string {
    return `${BrdRoutes.network()}/entity`;
  }

  public static entityCreation(): string {
    return `${BrdRoutes.networkEntity()}/create`;
  }

  public static entityEdition(id: number): string {
    return `${BrdRoutes.networkEntity()}/${id}/edit`;
  }

  public static entityConsultation(): string {
    return `${BrdRoutes.networkEntity()}/consult`;
  }

  public static entityImport(): string {
    return `${BrdRoutes.networkEntity()}/import`;
  }

  public static entityView(id: Readonly<number>): string {
    return `${BrdRoutes.networkEntity()}/${id}`;
  }

  public static networkEntityIdInformation(id: Readonly<number>): string {
    return `${BrdRoutes.networkEntity()}/${id}/information`;
  }

  public static networkEntityAuditCreate(id: Readonly<number>): string {
    return `${BrdRoutes.networkEntity()}/${id}/audit/create`;
  }

  public static networkEntityIdAuditConsult(id: Readonly<number>): string {
    return `${BrdRoutes.networkEntity()}/${id}/audit/consult`;
  }

  public static networkEntityIdAuditIdEdit(entityId: Readonly<number>, auditId: Readonly<number>): string {
    return `${BrdRoutes.networkEntity()}/${entityId}/audit/${auditId}/edit`;
  }

  public static entityMap(): string {
    return `${BrdRoutes.networkEntity()}/map`;
  }

  // SETTINGS

  public static settings(): string {
    return `${BrdRoutes.root()}/settings`;
  }

  public static settingsConsult(): string {
    return `${BrdRoutes.settings()}/consult`;
  }

  public static backOfficeRoles(): string {
    return `${BrdRoutes.settings()}/role/backoffice-user`;
  }

  public static complementaryInformations(): string {
    return `${BrdRoutes.settings()}/complementary-information`;
  }

  public static settingsRatings(): string {
    return `${BrdRoutes.settings()}/rating-systems`;
  }

  public static settingsInspectionTypes(): string {
    return `${BrdRoutes.settings()}/inspection-types`;
  }

  public static settingsCopilotBooking(): string {
    return `${BrdRoutes.settings()}/copilot/booking`;
  }

  public static settingsCopilotReportDecision(): string {
    return `${BrdRoutes.settings()}/copilot/report-decision`;
  }

  public static settingsInspectionTypesCreate(): string {
    return `${BrdRoutes.settingsInspectionTypes()}/create`;
  }

  public static settingsInspectionTypesIdEdit(id: Readonly<number>): string {
    return `${BrdRoutes.settingsInspectionTypes()}/${id}/edit`;
  }

  public static settingsInspectionTemplate(): string {
    return `${BrdRoutes.settings()}/inspection-template`;
  }

  public static settingsInspectionTemplateConsult(): string {
    return `${BrdRoutes.settingsInspectionTemplate()}/consult`;
  }

  public static settingsInspectionTemplateCreate(): string {
    return `${BrdRoutes.settingsInspectionTemplate()}/create`;
  }

  public static settingsInspectionTemplateIdEdit(id: Readonly<number>): string {
    return `${BrdRoutes.settingsInspectionTemplate()}/${id}/edit`;
  }

  public static settingsReportTemplateList(): string {
    return `${BrdRoutes.settings()}/report-templates`;
  }

  public static settingsReportTemplateCreate(): string {
    return `${BrdRoutes.settings()}/report-templates/create`;
  }

  public static settingsReportTemplateEdit(reportTemplateId: Readonly<number>): string {
    return `${BrdRoutes.settings()}/report-templates/${reportTemplateId}`;
  }

  // CLIENT

  public static networkClient(): string {
    return `${BrdRoutes.network()}/client`;
  }

  public static networkClientCreate(): string {
    return `${BrdRoutes.networkClient()}/create`;
  }

  public static networkClientCreateGrower(): string {
    return `${BrdRoutes.networkClient()}/create-grower`;
  }

  public static networkClientConsult(): string {
    return `${BrdRoutes.networkClient()}/client-consult`;
  }

  public static networkClientGrowerConsult(): string {
    return `${BrdRoutes.networkClient()}/entity-consult`;
  }

  public static networkClientEntityView(entityId: Readonly<number>): string {
    return `${BrdRoutes.networkClient()}/${entityId}/entity-view`;
  }

  public static networkClientEntityEdit(entityId: Readonly<number>): string {
    return `${BrdRoutes.networkClient()}/${entityId}/entity-edit`;
  }

  public static networkClientView(clientId: Readonly<number>): string {
    return `${BrdRoutes.networkClient()}/${clientId}/view`;
  }

  public static networkClientEdit(id: Readonly<number>): string {
    return `${BrdRoutes.networkClient()}/${id}/edit`;
  }
}
