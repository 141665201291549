import { IspWorkflowStepActionDefectsChecklist } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/isp-workflow-step-action-defects-checklist/isp-workflow-step-action-defects-checklist';
import { IspWorkflowStepActionMeasuresChecklist } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/isp-workflow-step-action-measures-checklist/isp-workflow-step-action-measures-checklist';
import { IspWorkflowStepActionSimplifiedMeasurementChecklist } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/isp-workflow-step-action-simplified-measurement-checklist/isp-workflow-step-action-simplified-measurement-checklist';
import { IspWorkflowStepActionTestsChecklist } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/isp-workflow-step-action-tests-checklist/isp-workflow-step-action-tests-checklist';
import {
  EIspWorkflowStepActionState,
  IspWorkflowStepActionType,
} from '@one/app/pages/isp/pages/inspection/pages/id/shared/models/isp-workflow-step-action.models';
import { EWorkflowActionType } from '@one/app/shared/models/workflow/workflow.models';
import { head, isEmpty } from 'lodash/index';
import { v4 as uuidv4 } from 'uuid';

/**
 * @description
 * Should always have some actions else it will throw some errors
 */
export abstract class IspWorkflowStepGroupAction<TAction extends IspWorkflowStepActionType = IspWorkflowStepActionType> {
  // @todo can't we find a way to pass the class name as a value to avoid code repetition?
  public static isTestsChecklistActions(actions: IspWorkflowStepActionType[]): actions is IspWorkflowStepActionTestsChecklist[] {
    return actions.every((action: Readonly<IspWorkflowStepActionType>): boolean => action instanceof IspWorkflowStepActionTestsChecklist);
  }

  public static isDefectsChecklistActions(actions: IspWorkflowStepActionType[]): actions is IspWorkflowStepActionDefectsChecklist[] {
    return actions.every((action: Readonly<IspWorkflowStepActionType>): boolean => action instanceof IspWorkflowStepActionDefectsChecklist);
  }

  public static isMeasuresChecklistActions(actions: IspWorkflowStepActionType[]): actions is IspWorkflowStepActionMeasuresChecklist[] {
    return actions.every(
      (action: Readonly<IspWorkflowStepActionType>): boolean => action instanceof IspWorkflowStepActionMeasuresChecklist
    );
  }

  public static isSimplifiedMeasurementChecklistActions(
    actions: IspWorkflowStepActionType[]
  ): actions is IspWorkflowStepActionSimplifiedMeasurementChecklist[] {
    return actions.every(
      (action: Readonly<IspWorkflowStepActionType>): boolean => action instanceof IspWorkflowStepActionSimplifiedMeasurementChecklist
    );
  }

  private static _throwNoActionError(): never {
    throw new Error('No action');
  }

  public actions: TAction[] = [];
  public id = uuidv4();

  public get type(): EWorkflowActionType | never {
    const firstAction: TAction | undefined = head(this.actions);

    if (!firstAction) {
      IspWorkflowStepGroupAction._throwNoActionError();
    }

    return firstAction.type;
  }

  public get state(): EIspWorkflowStepActionState {
    if (this._areOngoing) {
      return EIspWorkflowStepActionState.ONGOING;
    } else if (this._haveFail) {
      return EIspWorkflowStepActionState.FAIL;
    } else if (this._havePass) {
      return EIspWorkflowStepActionState.PASS;
    }

    return EIspWorkflowStepActionState.NA;
  }

  protected get _havePass(): boolean {
    return this.actions.some((action: Readonly<TAction>): boolean => action.state === EIspWorkflowStepActionState.PASS);
  }

  protected get _haveFail(): boolean {
    return this.actions.some((action: Readonly<TAction>): boolean => action.state === EIspWorkflowStepActionState.FAIL);
  }

  protected get _areOngoing(): boolean {
    return this.actions.some((action: Readonly<TAction>): boolean => action.state === EIspWorkflowStepActionState.ONGOING);
  }

  public get isEmpty(): boolean {
    return this.actions.every((action: Readonly<TAction>): boolean => action.isEmpty);
  }

  protected constructor(actions: TAction[]) {
    if (isEmpty(actions)) {
      IspWorkflowStepGroupAction._throwNoActionError();
    }

    this.actions = actions;
  }
}
