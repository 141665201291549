import { HttpResponse } from '@angular/common/http';

/**
 * @param response HTTP Response where the entity is indicated by 'Location' header.
 * @returns the ID of the entity, or null if 'Location' header is missing.
 */
export function getGeneratedEntityId<T>(response: HttpResponse<T>): string | undefined {
  // The location header is a URL pointing to the newly created entity
  // Here we only use the last part of the url to have the id of this
  const location: string | null = response.headers.get('location');

  if (location) {
    const splitArray = location.split('/');

    if (splitArray && splitArray.length > 0) {
      return splitArray[splitArray.length - 1];
    }
  }

  return undefined;
}
