import { IIamConfig } from '@one/app/shared/services/auth/iam-config.models';
import { QimaOptionalType } from '@qima/ngx-qima';
import { isObject } from 'lodash/index';

/**
 * @description
 * Used to define IAM configuration, and enable IAM login if present
 * Based on the global variable injection in the index file
 * @param {Window} window The window object containing possibly the "iamConfig" variable
 * @returns {string} Return the IAM config if it exists or null otherwise
 */
export function getIamConfig(window: Readonly<Window>): QimaOptionalType<IIamConfig> {
  if (isObject(window['iamConfig'])) {
    return window['iamConfig'] as IIamConfig;
  }

  return null;
}
