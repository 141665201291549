import { ActionRequest } from '@one/app/shared/models/action-request.models';
import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ActionsQueueService {
  private readonly _queue: ActionRequest[] = [];
  private readonly _addRequestSubject$ = new Subject<HttpRequest<unknown>>();

  public get queue(): ActionRequest[] {
    return this._queue;
  }

  public get addRequestObservable(): Observable<HttpRequest<unknown>> {
    return this._addRequestSubject$.asObservable();
  }

  public addAction(request: HttpRequest<unknown>): void {
    this._addRequestSubject$.next(request);
  }
}
