// @ts-strict-ignore
import { MeasurementsSamplingSizeType } from '@library/dto-enums/measurements-sampling-size-types.dto-enum';
import { qpIsFiniteNumber } from '@library/functions/checks/qp-is-finite-number';
import { EInspectionResult } from '@one/app/pages/brd/pages/report/pages/inspection/pages/id/brd-report-inspection-id.models';
import { InspectionProductMeasurementService } from '@one/app/pages/isp/pages/inspection/pages/id/pages/measurements/pages/category-id/services/inspection-product-measurements.service';
import { IspWorkflowStepAction } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/isp-workflow-step-action';
import { MeasuresChecklistAction } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/measurement-checklist-action/measures-checklist-action';
import { IspVariance } from '@one/app/pages/isp/shared/classes/isp-variance';
import {
  IMeasure,
  IMeasuresSampleAnswer,
  IProductVariances,
  IVariance,
} from '@one/app/pages/isp/shared/models/variances/inspection-variances.models';
import { EMeasurementUnitTolerance } from '@one/app/shared/models/measurements/measurements.models';
import { IMeasurementSamplingSize } from '@one/app/shared/models/sampling/sampling-size.models';
import { EWorkflowActionType, IWorkflowAction } from '@one/app/shared/models/workflow/workflow.models';
import { AqlService } from '@one/app/shared/services/aql/aql.service';
import { QimaOptionalType } from '@qima/ngx-qima';
import { isEmpty, isNil } from 'lodash/index';

const INITIAL_COUNT = 0;
const DEFAULT_RATIO = 100;

export class IspWorkflowStepActionMeasuresChecklist extends IspWorkflowStepAction {
  public static isMeasurePass(
    measure: Readonly<QimaOptionalType<number>>,
    expectedMeasure: Readonly<number>,
    toleranceMin: Readonly<number>,
    toleranceMax: Readonly<number>,
    toleranceUnit: Readonly<EMeasurementUnitTolerance>
  ): boolean {
    if (isNil(measure)) {
      return true;
    }

    return (
      qpIsFiniteNumber(measure) &&
      !IspWorkflowStepActionMeasuresChecklist._isMeasureTooLow(measure, expectedMeasure, toleranceMin, toleranceUnit) &&
      !IspWorkflowStepActionMeasuresChecklist._isMeasureTooHigh(measure, expectedMeasure, toleranceMax, toleranceUnit)
    );
  }

  private static _getDeltaMin(
    expectedMeasure: Readonly<number>,
    toleranceMin: Readonly<number>,
    toleranceUnit: Readonly<EMeasurementUnitTolerance>
  ): number {
    return this._getDelta(toleranceMin, expectedMeasure, toleranceUnit);
  }

  private static _getDeltaMax(
    expectedMeasure: Readonly<number>,
    toleranceMax: Readonly<number>,
    toleranceUnit: Readonly<EMeasurementUnitTolerance>
  ): number {
    return this._getDelta(toleranceMax, expectedMeasure, toleranceUnit);
  }

  private static _getDelta(
    tolerance: Readonly<number>,
    expectedMeasure: Readonly<number>,
    toleranceUnit: Readonly<EMeasurementUnitTolerance>
  ): number {
    if (toleranceUnit === EMeasurementUnitTolerance.PERCENTAGE) {
      return (tolerance * expectedMeasure) / DEFAULT_RATIO;
    }

    return tolerance;
  }

  private static _isMeasureTooLow(
    measure: Readonly<number>,
    expectedMeasure: Readonly<number>,
    toleranceMin: Readonly<number>,
    toleranceUnit: Readonly<EMeasurementUnitTolerance>
  ): boolean {
    return measure < expectedMeasure - this._getDeltaMin(expectedMeasure, toleranceMin, toleranceUnit);
  }

  private static _isMeasureTooHigh(
    measure: Readonly<number>,
    expectedMeasure: Readonly<number>,
    toleranceMax: Readonly<number>,
    toleranceUnit: Readonly<EMeasurementUnitTolerance>
  ): boolean {
    return measure > expectedMeasure + this._getDeltaMax(expectedMeasure, toleranceMax, toleranceUnit);
  }

  public type = EWorkflowActionType.MEASURES_CHECKLIST;
  public measuresChecklist: MeasuresChecklistAction[] = [];
  private readonly _samplingSize: IMeasurementSamplingSize | undefined;

  public get samplingSize(): IMeasurementSamplingSize {
    if (!this._samplingSize) {
      throw new Error('No sampling size for this measures checklist');
    }

    return this._samplingSize;
  }

  protected get _isOngoing(): boolean {
    return false;
  }

  public get result(): EInspectionResult {
    return this.getFailMeasuresCount() <= this._getMaxFailingVariancesCount() ? EInspectionResult.PASS : EInspectionResult.FAIL;
  }

  public constructor(action: Readonly<IWorkflowAction>, samplingSize?: Readonly<IMeasurementSamplingSize>) {
    super(action);

    if (!isEmpty(action.measuresChecklists)) {
      this.measuresChecklist = action.measuresChecklists.map(
        (measuresChecklist: IProductVariances): MeasuresChecklistAction => new MeasuresChecklistAction(measuresChecklist)
      );
    }

    this._samplingSize = samplingSize;
  }

  public getDoneProductVariancesCount(): number {
    return (
      this.measuresChecklist.reduce(
        (doneMeasureChecklist: Readonly<number>, measureChecklist: Readonly<MeasuresChecklistAction>): number =>
          doneMeasureChecklist + this._getDoneVariancesCount(measureChecklist),
        INITIAL_COUNT
      ) ?? INITIAL_COUNT
    );
  }

  public getFailMeasuresCount(): number {
    return (
      this.measuresChecklist.reduce(
        (allFailingMeasureChecklistCount: Readonly<number>, measureChecklist: Readonly<MeasuresChecklistAction>): number =>
          allFailingMeasureChecklistCount + this._getFailMeasureVariancesCount(measureChecklist),
        INITIAL_COUNT
      ) ?? INITIAL_COUNT
    );
  }

  public getDoneMeasuresCount(): number {
    return (
      this.measuresChecklist.reduce(
        (allMeasureChecklistCount: Readonly<number>, measureChecklist: Readonly<MeasuresChecklistAction>): number =>
          allMeasureChecklistCount + this._getDoneMeasureVariancesCount(measureChecklist),
        INITIAL_COUNT
      ) ?? INITIAL_COUNT
    );
  }

  private _getDoneMeasureVariancesCount(measureChecklist: Readonly<MeasuresChecklistAction>): number {
    return measureChecklist?.variances.reduce(
      (allVariancesCount: Readonly<number>, variance: Readonly<IVariance>): number =>
        allVariancesCount + this._getDoneMeasureEntriesCount(variance),
      INITIAL_COUNT
    );
  }

  private _getDoneMeasureEntriesCount(variance: Readonly<IVariance>): number {
    return variance.measuresEntries.reduce(
      (allMeasuresCount: Readonly<number>, measuresSampleAnswer: Readonly<IMeasuresSampleAnswer>): number =>
        allMeasuresCount + measuresSampleAnswer.measureAnswers.length,
      INITIAL_COUNT
    );
  }

  private _getDoneVariancesCount(measureChecklist: Readonly<MeasuresChecklistAction>): number {
    return measureChecklist?.variances.reduce((doneProductVariances: Readonly<number>, variance: Readonly<IVariance>): number => {
      if (IspVariance.fromIVariance(variance).areAllMeasuresSampleAnswerDone) {
        return ++doneProductVariances;
      }

      return doneProductVariances;
    }, INITIAL_COUNT);
  }

  private _getFailMeasureVariancesCount(measureChecklist: Readonly<MeasuresChecklistAction>): number {
    return measureChecklist?.variances.reduce(
      (allFailingVariancesCount: Readonly<number>, variance: Readonly<IVariance>): number =>
        allFailingVariancesCount + this._getFailMeasureEntriesCount(variance, measureChecklist),
      INITIAL_COUNT
    );
  }

  private _getFailMeasureEntriesCount(variance: Readonly<IVariance>, measureChecklist: Readonly<MeasuresChecklistAction>): number {
    return variance.measuresEntries.reduce(
      (allFailingMeasuresCount: Readonly<number>, measuresSampleAnswer: Readonly<IMeasuresSampleAnswer>): number =>
        allFailingMeasuresCount + this._getFailMeasureAnswersCount(measuresSampleAnswer, measureChecklist),
      INITIAL_COUNT
    );
  }

  private _getFailMeasureAnswersCount(
    measuresSampleAnswer: Readonly<IMeasuresSampleAnswer>,
    measureChecklist: Readonly<MeasuresChecklistAction>
  ): number {
    return measuresSampleAnswer.measureAnswers.reduce((failingMeasuresCount: number, measure: Readonly<IMeasure>): number => {
      if (!this._isMeasurePass(measure, measureChecklist.unitTolerance)) {
        return ++failingMeasuresCount;
      }

      return failingMeasuresCount;
    }, INITIAL_COUNT);
  }

  private _isMeasurePass(measure: Readonly<IMeasure>, unitTolerance: Readonly<EMeasurementUnitTolerance>): boolean {
    if (!this.measuresChecklist) {
      return false;
    }

    return IspWorkflowStepActionMeasuresChecklist.isMeasurePass(
      measure.measure,
      measure.expectedMeasure,
      measure.toleranceMin,
      measure.toleranceMax,
      unitTolerance
    );
  }

  private _getMaxFailingVariancesCount(): number {
    if (this.samplingSize.type === MeasurementsSamplingSizeType.FLEXIBLE_MEASUREMENTS_SAMPLING_SIZE) {
      /**
       * @todo we should compare the fail sample not the fail point of measure => change getNumberOfSamplesByProduct
       * Waiting https://asiainspection.atlassian.net/browse/PL-8835
       */
      const numberOfMeasurePoints = InspectionProductMeasurementService.getNumberOfMeasurePointsByProduct(
        this.measuresChecklists[0].variances
      );

      /**
       * @todo the parseFloat is linked to the todo of MeasurementSamplingSizeAqlType.
       */
      return AqlService.getAcceptanceNumberForFlexibleMeasurement(parseFloat(this.samplingSize.aql), numberOfMeasurePoints);
    }

    return AqlService.getAcceptanceNumber(this.samplingSize.aql, this.samplingSize.sampleSize);
  }
}
