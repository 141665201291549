import { qpIsFiniteNumber } from '@library/functions/checks/qp-is-finite-number';
import { EQpLoggerLevel, IQpLoggerConfig } from '@library/services/qp-logger/qp-logger.service.models';
import { QimaOptionalType } from '@qima/ngx-qima';

/**
 * @param {Window} window The window object containing possibly the "loggerConfig" variable
 *
 * @returns {QimaOptionalType<IQpLoggerConfig>} The logger config or nil
 */
function getLoggerConfig(window: Readonly<Window>): QimaOptionalType<IQpLoggerConfig> {
  return window['loggerConfig'];
}

/**
 * @description
 * Used to define the logger config
 * Based on the environments variable injection in the index file
 *
 * @param {Window} window The window object containing possibly the "loggerConfig" variable
 *
 * @returns {IQpLoggerConfig} Return the logger config
 */
export function qpLoggerConfigFactory(window: Readonly<Window>): IQpLoggerConfig {
  const loggerConfig: QimaOptionalType<IQpLoggerConfig> = getLoggerConfig(window);

  if (!loggerConfig) {
    return {
      loggerLevel: EQpLoggerLevel.DISABLED,
      httpLoggerLevel: EQpLoggerLevel.DISABLED,
    };
  }

  const { loggerLevel, httpLoggerLevel }: IQpLoggerConfig = loggerConfig;

  return {
    loggerLevel: qpIsFiniteNumber(loggerLevel) ? loggerLevel : EQpLoggerLevel.DISABLED,
    httpLoggerLevel: qpIsFiniteNumber(httpLoggerLevel) ? httpLoggerLevel : EQpLoggerLevel.DISABLED,
  };
}
