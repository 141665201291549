import { HomeComponent } from '@one/app/components/home/home.component';
import { AuthoritiesGuard } from '@one/app/shared/guards/authorities/authorities.guard';
import { Route } from '@angular/router';

export const HOME_ROUTE: Route = {
  path: '',
  component: HomeComponent,
  canActivate: [AuthoritiesGuard],
  data: {
    authorities: [],
    pageTitle: 'home.title',
  },
};
