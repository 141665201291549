import { AutoUnsubscriber } from '@library/classes/auto-unsubscriber/auto-unsubscriber';
import { QpLoggerService } from '@library/services/qp-logger/qp-logger.service';
import { ApplicationRef, Injectable, Injector } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { concat, interval } from 'rxjs';
import { first } from 'rxjs/operators';

const ONE_HOUR = 60 * 60 * 1000;

/**
 * @description
 * Allow the app to stabilize first, before starting polling for updates with `interval()`.
 */
@Injectable({ providedIn: 'root' })
export class SwCheckForUpdateService extends AutoUnsubscriber {
  private readonly _applicationRef: ApplicationRef;

  public constructor(
    private readonly _swUpdate: SwUpdate,
    private readonly _injector: Injector,
    private readonly _qpLoggerService: QpLoggerService
  ) {
    super();

    this._applicationRef = this._injector.get(ApplicationRef);
  }

  public init(): void {
    const appIsStable$ = this._applicationRef.isStable.pipe(first((isStable: Readonly<boolean>): boolean => isStable));
    const everyHours$ = interval(ONE_HOUR);
    const everyHoursOnceAppIsStable$ = concat(appIsStable$, everyHours$);

    this.registerSubscription(
      everyHoursOnceAppIsStable$.subscribe(
        (): void => {
          if (this._swUpdate.isEnabled) {
            void this._swUpdate.checkForUpdate();
          }
        },
        (error: Error): void => {
          this._qpLoggerService.error(error);
        }
      )
    );
  }
}
