const ZERO = 0;

/**
 * @description
 * When the given value is 0, fallback with the given fallback value
 * @param {Readonly<number>} value The value to check
 * @param {Readonly<number>} fallback The fallback value
 * @returns {number} Return a fallback value when the given value is 0
 */
export function qpFallbackWhenZero(value: Readonly<number>, fallback: Readonly<number>): number {
  return value === ZERO ? fallback : value;
}
