import { IQpExpertise } from '@library/components/qp-expertise/qp-expertise.models';
import { ChecklistRatingResultDTO } from '@library/dto/checklist/checklist-rating-result.dto';
import { BrandUserDTO, ProductDTO, PurchaseOrderDTO } from '@library/dto/report.dto';
import { AqlTypes } from '@library/dto-enums/aql-types.dto-enum';
import { InspectionMethod } from '@library/dto-enums/inspection-method.dto-enum';
import { InspectionStatus } from '@library/dto-enums/inspection-status.dto-enum';
import { IQpAddress } from '@library/models/address/qp-address.model';
import { IQpCoordinates } from '@library/models/qp-coordinates.models';
import { EQpProfile } from '@library/models/qp-profile.models';
import {
  IBrdReportInspectionIdMeasuresChecklistAqlMeasurements,
  IBrdReportInspectionIdMeasuresChecklistMeasurementStatistics,
} from '@one/app/pages/brd/pages/report/pages/inspection/pages/id/models/brd-report-inspection-id-measures-checklist.models';
import { IContact } from '@one/app/pages/brd/shared/components/brd-main-contact/models/brd-contact.models';
import { IEntity } from '@one/app/pages/brd/shared/models/entity.model';
import { IDefectsChecklistDefect } from '@one/app/pages/isp/pages/inspection/pages/id/pages/defects-checklist/isp-inspection-id-defect.models';
import { AqlDefectsType } from '@one/app/shared/models/defects/aql-defects.models';
import { IMaxDefectsAllowed } from '@one/app/shared/models/defects/defects.models';
import { IAttachmentData } from '@one/app/shared/models/inspection-consultation/inspection-consultation.models';
import { IInspectionType } from '@one/app/shared/models/inspections/inspection.models';
import { IMeasurementsDTO } from '@one/app/shared/models/measurements/measurements.models';
import { EReportDecision } from '@one/app/shared/models/report/report.models';
import { WorkflowSamplingSizeType } from '@one/app/shared/models/sampling/sampling-size.models';
import { UserProfile } from '@one/app/shared/models/user/user.models';
import { EWorkflowActionType, IWorkflow } from '@one/app/shared/models/workflow/workflow.models';
import { IWorkflowTemplate } from '@one/app/shared/models/workflows/workflows-templates.models';
import { QimaOptionalType } from '@qima/ngx-qima';

interface IReportFeedback {
  decision: EReportDecision;
  comments: IAnswerComment[];
  globalComment?: string;
  attachments?: IAttachmentData[];
}

interface IRejectedReportFeedback extends IReportFeedback {
  rejectReasons: EReportRejectReason[];
}

export type AcceptedReportFeedbackType = IReportFeedback;

export type PendingReportFeedbackType = IReportFeedback;

export type RejectedReportFeedbackType = IRejectedReportFeedback;

export type ReportDecisionFeedbackType = AcceptedReportFeedbackType | PendingReportFeedbackType | IRejectedReportFeedback;

export enum EReportRejectReason {
  // Visual defects
  VISUAL_DEFECTS_GEN_APPEARANCE = 'VISUAL_DEFECTS_GEN_APPEARANCE',
  VISUAL_DEFECTS_COLOR = 'VISUAL_DEFECTS_COLOR',
  VISUAL_DEFECTS_ARTWORK = 'VISUAL_DEFECTS_ARTWORK',
  VISUAL_DEFECTS_OTHER = 'VISUAL_DEFECTS_OTHER',

  // Functional defects
  FUNC_DEFECTS_OTHER = 'FUNC_DEFECTS_OTHER',

  // Measurement issues
  MEASURE_ISSUES_DIMENSION = 'MEASURE_ISSUES_DIMENSION',
  MEASURE_ISSUES_WEIGHT = 'MEASURE_ISSUES_WEIGHT',
  MEASURE_ISSUES_OTHER = 'MEASURE_ISSUES_OTHER',

  // Quantity issues
  QUANT_ISSUES_QUANT_NOT_READY = 'QUANT_ISSUES_QUANT_NOT_READY',
  QUANT_ISSUES_WRONG_SAMPLING_QUANT = 'QUANT_ISSUES_WRONG_SAMPLING_QUANT',
  QUANT_ISSUES_OTHER = 'QUANT_ISSUES_OTHER',

  // Packing / Packaging issues
  PACK_ISSUES_WRONG_PACKING = 'PACK_ISSUES_WRONG_PACKING',
  PACK_ISSUES_WRONG_PACKAGING = 'PACK_ISSUES_WRONG_PACKAGING',
  PACK_ISSUES_OTHER = 'PACK_ISSUES_OTHER',

  // Other issues
  OTHER_ISSUES = 'OTHER_ISSUES',
}

export type ReportDecisionType = InspectionStatus.REJECTED | InspectionStatus.ACCEPTED;

export interface IReportDecisionUser {
  id: number;
  firstName: string;
  lastName: string;
  companyName: string;
  profiles: EQpProfile[];
}

export interface IReportDecisionComment {
  workflowActionType: EWorkflowActionType;
  comment: string;
}

export interface IReportDecision {
  decision: EReportDecision;
  decisionDate: Date;
  decisionUser: IReportDecisionUser;
  globalComment?: string;
  rejectReasons?: EReportRejectReason[];
  comments?: IReportDecisionComment[];
  attachments?: IAttachmentData[];
}

export interface IReportBrand {
  id: number;
  name: string;
  type: string;
}

export interface IReport {
  products: IReportProduct[];
  inspectionId: number;
  reportId: number;
  status: InspectionStatus;
  inspectionDate: string;
  plannedDate: string;
  quantityAvailable: number;
  lastReportDecision?: IReportDecision;
  brand: IReportBrand;
  client?: IReportBrand;
  manager: {
    contact: IContact;
    address: IQpAddress;
  };
  entity?: Partial<IEntity>;
  delegatedBy?: Partial<IEntity>;
  inspector: BrandUserDTO;
  reportVersion: number;
  measurementsStatistics: { [key: string]: IBrdReportInspectionIdMeasuresChecklistMeasurementStatistics };
  measurementModels?: { [productId: string]: IMeasurementsDTO };
  startInspectionCoordinates: IQpCoordinates;
  completeInspectionCoordinates: IQpCoordinates;
  result: IWorkflowReport;
  inspectionResult: EInspectionResult;
  isDefectsPerProduct: boolean;
  isAqlPerProduct: boolean;
  aqlDefects: AqlDefectsType;
  aqlMeasurements: IBrdReportInspectionIdMeasuresChecklistAqlMeasurements;
  inspectionType: IInspectionType;
  workflowTemplate: IWorkflowTemplate;
  workflowTemplateUser: IWorkflowUserIdentity;
  inspectionSamplingSize: WorkflowSamplingSizeType;
  responsibleEntity?: Partial<IEntity>;
  productionSiteEntity?: Partial<IEntity>;
  inspectionMethod: InspectionMethod;
  destinations: Partial<IEntity>[];
}

export interface IReportProduct {
  product: ProductDTO;
  purchaseOrder?: PurchaseOrderDTO;
  purchaseOrderProductId?: number;
  shipmentDate?: Date;
  productQuantity: number;
  producedQuantity?: number;
  packedQuantity?: number;
  unit?: string;
}

export interface IWorkflowReport extends IWorkflow {
  result?: EInspectionResult;
  /**
   * @deprecated Use `ratingResults` instead
   */
  ratingResult?: ChecklistRatingResultDTO;
  ratingResults?: ChecklistRatingResultDTO[];
  comment?: string;
}

export interface IQuantities {
  quantityOrdered: number;
  quantityReady: number;
  quantityInspected: number;
  quantityCarton: number;
  unit: string;
}

export interface IGeneralInformation {
  inspectionId: number;
  brandName: string;
  inspectionStatus: InspectionStatus;
  reportDecision: EReportDecision | undefined;
  inspectionDate: string;
  plannedDate: string;
  entityName: string | undefined;
  entityAddress: IQpAddress | undefined;
  hasPictures: boolean;
  quantity: number | undefined;
  unit: string | undefined;
  reportVersion: number;
}

export interface IWorkmanship {
  defectsList: (IDefectsChecklistDefect & { totalQuantity?: number })[];
  samplingSize: WorkflowSamplingSizeType;
  totalCritical: number;
  totalMajor: number;
  totalMinor: number;
  isDefectsPerProduct: boolean;
  isAqlPerProduct: boolean;
  aqlDefects: AqlDefectsType;
  result: EInspectionResult;
  ratingResults?: ChecklistRatingResultDTO[];
  acceptPoints?: IMaxDefectsAllowed;
  sampleSize?: number;
}

export interface IReportInspector {
  firstName: string;
  lastName: string;
  email: string;
  id: number;
  brand: string;
  expertises: IQpExpertise[];
  profiles: UserProfile[];
  hasBrandRoles: boolean;
  hasFactoryRoles: boolean;
  phone: string;
}

export interface IInspectionDetails {
  inspector: string;
  aqlDefectsTypes: QimaOptionalType<AqlTypes>;
  order_location: string | null;
  sampling_level: string | null;
  workmanship_aql: string | null;
  entity_contact: string | null;
  entity_latitude: string | null;
  entity_longitude: string | null;
  startInspectionLatitude: string;
  startInspectionLongitude: string;
  completeInspectionLatitude: string;
  completeInspectionLongitude: string;
  perProductAqlAndSampling: IAqlAndSamplingPerProductForInspectionDetails[] | null;
}

export interface IAqlAndSamplingPerProductForInspectionDetails {
  productName: string;
  aqlDefectsTypes: AqlTypes;
  sampling_level: string | null;
  workmanship_aql: string | null;
}

export enum EInspectionResult {
  PASS = 'PASS',
  FAIL = 'FAIL',
  PENDING = 'PENDING',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  NO_RESULT = 'NO_RESULT',
}

export interface IWorkflowUserIdentity {
  login: string;
  firstName: string;
  lastName: string;
}

export interface IAnswerComment {
  workflowActionId: string;
  workflowActionType: EWorkflowActionType;
  comment: string;
}

export type DownloadedReportPDFType = Blob;

export type DownloadedInspectionFilesZipType = Blob;

export interface DestinationData {
  cardinality: 'NONE' | 'SINGLE' | 'MULTIPLE';
  destination?: Partial<IEntity>;
}
