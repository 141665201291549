import { isString } from 'lodash/index';

/**
 * @description
 * Used to define the GTM container ID
 * Based on the environments variable injection in the index file
 *
 * @param {Window} window The window object containing possibly the "notifuseProjectId" variable
 *
 * @returns {string} Return the project ID if it exists or null otherwise
 */
export function getNotifuseProjectIdFactory(window: Readonly<Window>): string | null {
  if (isString(window['notifuseProjectId'])) {
    return window['notifuseProjectId'];
  }

  return null;
}
