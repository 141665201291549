// @ts-strict-ignore
import { ProductCategoryDTO } from '@library/dto/product-category.dto';
import { ChecklistPublicationStatus } from '@library/dto-enums/checklist-publication-status.dto-enum';
import { IBrdInspectionReferenceSample } from '@one/app/pages/brd/pages/inspection/shared/models/brd-inspection.models';
import {
  EInspectionResult,
  IWorkflowUserIdentity,
} from '@one/app/pages/brd/pages/report/pages/inspection/pages/id/brd-report-inspection-id.models';
import { IDefectsChecklist } from '@one/app/pages/isp/pages/inspection/pages/id/pages/defects-checklist/isp-inspection-id-defect.models';
import { EIspWorkflowStepActionState } from '@one/app/pages/isp/pages/inspection/pages/id/shared/models/isp-workflow-step-action.models';
import { IProductVariances } from '@one/app/pages/isp/shared/models/variances/inspection-variances.models';
import { MeasuresChecklistActionSection } from '@one/app/shared/classes/measurements/measures-checklist-action-section';
import { ITestsChecklistDetails } from '@one/app/shared/models/checklist/tests-checklist.models';
import {
  EWorkflowActionType,
  IWorkflowAction,
  IWorkflowActionMetadata,
  IWorkflowInspectionFindingsSummaryReview,
  IWorkflowSampleCollection,
  WorkflowAuditCheckType,
  WorkflowCheckType,
} from '@one/app/shared/models/workflow/workflow.models';
import { WorkflowPathIdType } from '@one/app/shared/models/workflows/workflow.models';
import { EWorkflowTemplateStepActionInspectionPreparationCalculationMethod } from '@one/app/shared/models/workflows/workflows-templates.models';
import { QimaOptionalType, QimaUuidType } from '@qima/ngx-qima';
import { isEmpty } from 'lodash/index';

export abstract class IspWorkflowStepAction implements IWorkflowAction {
  public abstract type: EWorkflowActionType;
  public id: WorkflowPathIdType;
  public uuid: QimaUuidType;
  public actionName: QimaOptionalType<string> = undefined;
  public testsChecklist: QimaOptionalType<ITestsChecklistDetails> = undefined;
  public defectsChecklist: QimaOptionalType<IDefectsChecklist> = undefined;
  public instructions: QimaOptionalType<string> = undefined;
  public documentId: QimaOptionalType<string> = undefined;
  public referenceSample: QimaOptionalType<IBrdInspectionReferenceSample> = undefined;
  public sampleCollection: QimaOptionalType<IWorkflowSampleCollection> = undefined;
  public measuresChecklists: QimaOptionalType<IProductVariances[]> = undefined;
  public findingsSummaryReview: QimaOptionalType<IWorkflowInspectionFindingsSummaryReview> = undefined;
  public checks: QimaOptionalType<(WorkflowCheckType | WorkflowAuditCheckType)[]> = undefined;
  public user: QimaOptionalType<IWorkflowUserIdentity> = undefined;
  public metadata: QimaOptionalType<IWorkflowActionMetadata> = undefined;
  public publicationStatus: ChecklistPublicationStatus;
  public productCategories: ProductCategoryDTO[];
  public comment: QimaOptionalType<string> = undefined;
  public updatableByInspector: QimaOptionalType<boolean>;
  public calculationMethod: QimaOptionalType<EWorkflowTemplateStepActionInspectionPreparationCalculationMethod>;
  public ccRecipients: QimaOptionalType<string[]>;

  public get isEmpty(): boolean {
    return false;
  }

  protected abstract get _isOngoing(): boolean;

  // Test result can be PASS, FAIL or NOT_APPLICABLE
  public get result(): EInspectionResult {
    return EInspectionResult.PASS;
  }

  // State can be like result, but also ONGOING
  public get state(): EIspWorkflowStepActionState {
    if (this._isOngoing) {
      return EIspWorkflowStepActionState.ONGOING;
    }

    switch (this.result) {
      case EInspectionResult.PASS:
        return EIspWorkflowStepActionState.PASS;
      case EInspectionResult.FAIL:
        return EIspWorkflowStepActionState.FAIL;
      case EInspectionResult.NO_RESULT:
      case EInspectionResult.NOT_APPLICABLE:
        return EIspWorkflowStepActionState.NA;
      default:
        return EIspWorkflowStepActionState.NONE;
    }
  }

  protected constructor(action: Readonly<IWorkflowAction>) {
    this.id = action.id;
    this.uuid = action.uuid;
    this.actionName = action.actionName;
    this.testsChecklist = action.testsChecklist;
    this.defectsChecklist = action.defectsChecklist;
    this.instructions = action.instructions;
    this.documentId = action.documentId;
    this.referenceSample = action.referenceSample;
    this.sampleCollection = action.sampleCollection;
    this.findingsSummaryReview = action.findingsSummaryReview;
    this.checks = action.checks;
    this.user = action.user;
    this.metadata = action.metadata;
    this.comment = action.comment;
    this.updatableByInspector = action.updatableByInspector;
    this.calculationMethod = action.calculationMethod;

    if (!isEmpty(action.measuresChecklists)) {
      this.measuresChecklists = action.measuresChecklists.map(
        (measuresChecklist: IProductVariances): MeasuresChecklistActionSection => new MeasuresChecklistActionSection(measuresChecklist)
      );
    }
  }
}
