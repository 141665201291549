import { EQpProfile } from '@library/models/qp-profile.models';
import { INTERCEPTOR_SKIP_HEADER } from '@one/app/shared/interceptors/inspection.interceptor';
import { AccountService } from '@one/app/shared/services/account/account.service';
import { HttpHandlerFn, HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * @description
 * As the service worker is caching the requests, we need to add bypass headers if user is not an inspector
 * and more especially if the request is an upload request 👉 https://github.com/w3c/ServiceWorker/issues/1141
 * @see https://angular.dev/ecosystem/service-workers/devops#bypassing-the-service-worker
 * @param {HttpRequest} request the request to intercept
 * @param {HttpHandlerFn} next the request handler
 * @returns {Observable<HttpEvent<unknown>> | Observable<HttpResponse<undefined>>} the request or the response to return
 */
export function ngswBypassInterceptor$(
  request: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> | Observable<HttpResponse<undefined>> {
  const accountService: AccountService = inject(AccountService);

  if (
    accountService.hasAnyAuthority([EQpProfile.ROLE_INSPECTOR, EQpProfile.ROLE_SERVICE_PROVIDER_INSPECTOR]) ||
    request.headers.has(INTERCEPTOR_SKIP_HEADER)
  ) {
    return next(request);
  }

  const headers = request.headers.set('ngsw-bypass', 'true').set('X-Skip-Interceptor', 'true');
  const editedRequest = request.clone({
    headers,
  });

  return next(editedRequest);
}
