import { serviceWorkerFactory } from '@one/app/shared/factories/service-worker.factory';
import { SwCheckForUpdateService } from '@one/app/shared/services/sw/sw-check-for-update.service';
import { SwLogUpdateService } from '@one/app/shared/services/sw/sw-log-update.service';
import { SwReloadOnUpdateService } from '@one/app/shared/services/sw/sw-reload-on-update.service';
import { APP_BOOTSTRAP_LISTENER, FactoryProvider } from '@angular/core';

export const SERVICE_WORKER_PROVIDER: FactoryProvider = {
  deps: [SwLogUpdateService, SwReloadOnUpdateService, SwCheckForUpdateService],
  provide: APP_BOOTSTRAP_LISTENER,
  useFactory: serviceWorkerFactory,
  multi: true,
};
