import { QimaOptionalType } from '@qima/ngx-qima';
import { isString } from 'lodash/index';

/**
 * @description
 * Used to send user session data to appropriate project on mixpanel identified by environment id.
 *
 * @param {Window} window The window object containing possibly the "mixpanelId" variable
 *
 * @returns {string} Return the environment ID if it exists or null otherwise
 */
export function mixpanelFactory(window: Window): QimaOptionalType<string> {
  const mixpanelId = window['mixpanelId'];

  if (isString(mixpanelId)) {
    return mixpanelId;
  }

  return null;
}
