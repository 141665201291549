// @ts-strict-ignore
import { EQpMimeType } from '@library/models/qp-mime-type.models';
import { IAttachmentData } from '@one/app/shared/models/inspection-consultation/inspection-consultation.models';
import { UntypedFormGroup } from '@angular/forms';

export class InspectionAttachment implements IAttachmentData {
  public static fromInspectionAttachmentData(attachment: IAttachmentData): InspectionAttachment {
    const attachmentData = new InspectionAttachment();

    attachmentData.filename = attachment.filename;
    attachmentData.attachmentId = attachment.attachmentId;
    attachmentData.mimeType = attachment.mimeType;
    attachmentData.uploadError = attachment.uploadError;
    attachmentData.isLoading = attachment.isLoading;
    attachmentData.downloadUrl = attachment.downloadUrl;

    attachmentData.form = new UntypedFormGroup({});

    return attachmentData;
  }

  public static fromIChecklistAttachmentData(images: IAttachmentData[]): InspectionAttachment[] {
    return images.map(InspectionAttachment.fromInspectionAttachmentData);
  }

  public filename: string;
  public attachmentId: string;
  public mimeType?: EQpMimeType;
  public uploadError?: string;
  public isLoading?: boolean;
  public downloadUrl?: string;
  public form: UntypedFormGroup;
}
