export enum EChecklistRequirementType {
  PICTURE = 'PICTURE',
  SCAN = 'SCAN',
  COMMENT = 'COMMENT',
  SIGNATURE = 'SIGNATURE',
  ATTACHMENT = 'ATTACHMENT',
  SPOTLIGHT = 'SPOTLIGHT',
}

export interface IChecklistRequirement {
  type: EChecklistRequirementType;
  icon: string;
  isRequired: boolean;
  translationKey: string;
  isEnabled: boolean;
}
