import { getNotifuseProjectIdFactory } from '@one/app/shared/factories/notifuse-config.factory';
import { NOTIFUSE_CONFIG } from '@one/app/shared/tokens/notifuse-config.token';
import { WINDOW } from '@one/app/shared/tokens/window.token';
import { FactoryProvider } from '@angular/core';

export const NOTIFUSE_CONFIG_PROVIDER: FactoryProvider = {
  deps: [WINDOW],
  provide: NOTIFUSE_CONFIG,
  useFactory: getNotifuseProjectIdFactory,
};
