import { CustomFieldElementValueDTO } from '@library/dto/workflow/custom-fields.dto';
import { QpDocumentsType, QpDocumentType } from '@library/models/qp-document.models';
import { EQpProfile } from '@library/models/qp-profile.models';

/**
 * @description
 * These DTO are used on endpoints :
 * - [GET] /api/products
 * - [POST] `/api/products/{id}`
 * - [PUT] `/api/products/{id}`
 *
 * 🏷️ Fully synchronized with the backend on 2023-05-10
 * ✅ Same structure as backend (class inheritance & co)
 */
export interface ProductDTO {
  id: number;
  identifierType: EProductSpecificationType;
  description?: string;
  identifierValue: string;
  brandId: number;
  specification: IProductSpecification;
  category: IProductCategoryDTO;
  pictureId?: string;
  inspectionDate?: Date;
  plannedDate?: Date;
  documents?: QpDocumentsType;
  tags?: string[]; // 💡 not blank elements
  photo?: QpDocumentType;
}

export interface ProductPostRequestDTO {
  identifierType: EProductSpecificationType;
  categoryId?: number;
  clientId?: number; // 💡 mandatory for serviceProviders
  identifierValue: string;
  description?: string; // 💡 `null` or not blank
  specification: IProductSpecification;
  tags?: string[]; // 💡 not blank elements
  hasPhoto: boolean; // 🚨 if `false`, remove the product photo
  customFields?: CustomFieldElementValueDTO[];
}

export type ProductPostResponseDTO = ProductDTO;

export interface ProductPutRequestDTO extends ProductPostRequestDTO {
  id: number;
}

export type IProductPutResponseDTO = ProductDTO;

export enum EProductSpecificationType {
  NAME = 'NAME',
  GTIN = 'GTIN',
  SKU = 'SKU',
  REFERENCE = 'REFERENCE',
}

export type IProductSpecification = {
  [key in EProductSpecificationType]?: string;
};

/**
 * @description This DTO reflects `com.qima.platform.service.dto.ProductCategoryDTO`
 */
export interface IProductCategoryDTO {
  id: number;
  name: string;
  path?: string[]; // Used to manage category hierarchy
}

export interface IProductAuditUserDTO {
  login: string;
  firstName: string;
  lastName: string;
  profiles: EQpProfile[];
}
