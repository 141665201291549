import { IspWorkflowStepActionCustomFields } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/isp-workflow-step-action-custom-fields/isp-workflow-step-action-custom-fields';
import { IspWorkflowStepActionDefectsChecklist } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/isp-workflow-step-action-defects-checklist/isp-workflow-step-action-defects-checklist';
import { IspWorkflowStepActionFindingsSummaryReview } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/isp-workflow-step-action-findings-summary-review/isp-workflow-step-action-findings-summary-review';
import { IspWorkflowStepActionInspectionPreparation } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/isp-workflow-step-action-inspection-preparation/isp-workflow-step-action-inspection-preparation';
import { IspWorkflowStepActionMeasuresChecklist } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/isp-workflow-step-action-measures-checklist/isp-workflow-step-action-measures-checklist';
import { IspWorkflowStepActionRecipients } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/isp-workflow-step-action-recipients/isp-workflow-step-action-recipients';
import { IspWorkflowStepActionSimplifiedMeasurementChecklist } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/isp-workflow-step-action-simplified-measurement-checklist/isp-workflow-step-action-simplified-measurement-checklist';
import { IspWorkflowStepActionTestsChecklist } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/isp-workflow-step-action-tests-checklist/isp-workflow-step-action-tests-checklist';

// @todo add more classes
export type IspWorkflowStepActionType =
  | IspWorkflowStepActionTestsChecklist
  | IspWorkflowStepActionDefectsChecklist
  | IspWorkflowStepActionMeasuresChecklist
  | IspWorkflowStepActionSimplifiedMeasurementChecklist
  | IspWorkflowStepActionFindingsSummaryReview
  | IspWorkflowStepActionInspectionPreparation
  | IspWorkflowStepActionCustomFields
  | IspWorkflowStepActionRecipients;

export enum EIspWorkflowStepActionState {
  ONGOING = 'ongoing',
  PASS = 'pass',
  FAIL = 'fail',
  NA = 'na',
  NONE = 'none',
}
