import { QimaOptionalType } from '@qima/ngx-qima';
import { isString } from 'lodash/index';

/**
 * @description
 * Used to send user session data to appropriate project on logRocket identified by environment id.
 *
 * @param {Window} window The window object containing possibly the "logRocketEnvironmentId" variable
 *
 * @returns {string} Return the environment ID if it exists or null otherwise
 */
export function logrocketEnvironmentIdFactory(window: Window): QimaOptionalType<string> {
  const logrocketEnvironmentId = window['logRocketEnvironmentId'];

  if (isString(logrocketEnvironmentId)) {
    return logrocketEnvironmentId;
  }

  return null;
}
