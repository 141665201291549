// @ts-strict-ignore
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CartonsService {
  public calculateCartonsToPickUsingSquareRootRule(cartonsAvailable: number): number {
    let cartonToPick: number;
    const defaultCountToPick = 8;

    if (cartonsAvailable === undefined || cartonsAvailable === null) {
      cartonToPick = null;
    } else if (cartonsAvailable <= 0) {
      throw new Error(`Unexpected value of carton ${cartonsAvailable}`);
    } else if (cartonsAvailable <= defaultCountToPick) {
      cartonToPick = cartonsAvailable;
    } else {
      const sqrt = +Math.ceil(Math.sqrt(cartonsAvailable));

      if (sqrt <= defaultCountToPick) {
        cartonToPick = defaultCountToPick;
      } else {
        cartonToPick = sqrt;
      }
    }

    return cartonToPick;
  }
}
