// @ts-strict-ignore
import { QpMiniRange } from '@library/classes/qp-mini-range/qp-mini-range';
import { EQpAQLSampleSize, EQpAQLCodeLetter, EQpAQLInspectionLevel } from '@library/models/qp-aql.models';
import { Injectable } from '@angular/core';
import { QimaOptionalType } from '@qima/ngx-qima';
import { isNil } from 'lodash/index';

export class MiniTwoKeysMap<K1, K2, V> {
  private readonly _map: Map<K1, Map<K2, V>> = new Map();

  public constructor(private readonly _entries?: readonly (readonly [K1, K2, V])[] | null) {
    this._entries.forEach((entry: readonly [K1, K2, V]): void => {
      this.put(entry[0], entry[1], entry[2]);
    });
  }

  public put(key1: K1, key2: K2, value: V): void {
    if (!this._map.has(key1)) {
      this._map.set(key1, new Map());
    }

    this._map.get(key1).set(key2, value);
  }

  public get(key1: K1, key2: K2): V {
    if (this._map.has(key1) && this._map.get(key1).has(key2)) {
      return this._map.get(key1).get(key2);
    }

    return null;
  }
}

export class MiniPair<T, U> {
  public static of<T, U>(val1, val2): MiniPair<T, U> {
    return new MiniPair<T, U>(val1, val2);
  }

  public constructor(public val1: T, public val2: U) {}

  public getLeft(): T {
    return this.val1;
  }

  public getRight(): U {
    return this.val2;
  }
}

/**
 * @deprecated
 * Use instead {@link QpAql}
 */
@Injectable({
  providedIn: 'root',
})
export class SamplingSizeService {
  public static readonly _RANGE_0 = QpMiniRange.between(0, 0); // Non standard
  public static readonly _RANGE_1 = QpMiniRange.between(1, 1); // Non standard
  public static readonly _RANGE_2 = QpMiniRange.between(2, 8);
  public static readonly _RANGE_3 = QpMiniRange.between(9, 15);
  public static readonly _RANGE_4 = QpMiniRange.between(16, 25);
  public static readonly _RANGE_5 = QpMiniRange.between(26, 50);
  public static readonly _RANGE_6 = QpMiniRange.between(51, 90);
  public static readonly _RANGE_7 = QpMiniRange.between(91, 150);
  public static readonly _RANGE_8 = QpMiniRange.between(151, 280);
  public static readonly _RANGE_9 = QpMiniRange.between(281, 500);
  public static readonly _RANGE_10 = QpMiniRange.between(501, 1200);
  public static readonly _RANGE_11 = QpMiniRange.between(1201, 3200);
  public static readonly _RANGE_12 = QpMiniRange.between(3201, 10000);
  public static readonly _RANGE_13 = QpMiniRange.between(10001, 35000);
  public static readonly _RANGE_14 = QpMiniRange.between(35001, 150000);
  public static readonly _RANGE_15 = QpMiniRange.between(150001, 500000);
  public static readonly _RANGE_16 = QpMiniRange.between(500001, Infinity);

  public static readonly ranges = [
    SamplingSizeService._RANGE_0, // Non standard
    SamplingSizeService._RANGE_1, // Non standard
    SamplingSizeService._RANGE_2,
    SamplingSizeService._RANGE_3,
    SamplingSizeService._RANGE_4,
    SamplingSizeService._RANGE_5,
    SamplingSizeService._RANGE_6,
    SamplingSizeService._RANGE_7,
    SamplingSizeService._RANGE_8,
    SamplingSizeService._RANGE_9,
    SamplingSizeService._RANGE_10,
    SamplingSizeService._RANGE_11,
    SamplingSizeService._RANGE_12,
    SamplingSizeService._RANGE_13,
    SamplingSizeService._RANGE_14,
    SamplingSizeService._RANGE_15,
    SamplingSizeService._RANGE_16,
  ];

  private static readonly _codeLettersMap = new MiniTwoKeysMap<
    QpMiniRange,
    EQpAQLInspectionLevel,
    MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>
  >([
    [
      SamplingSizeService._RANGE_0,
      EQpAQLInspectionLevel.I,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_X, EQpAQLSampleSize.SIZE_0),
    ], // Non standard
    [
      SamplingSizeService._RANGE_1,
      EQpAQLInspectionLevel.I,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_Z, EQpAQLSampleSize.SIZE_1),
    ], // Non standard
    [
      SamplingSizeService._RANGE_2,
      EQpAQLInspectionLevel.I,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_A, EQpAQLSampleSize.SIZE_2),
    ],
    [
      SamplingSizeService._RANGE_3,
      EQpAQLInspectionLevel.I,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_A, EQpAQLSampleSize.SIZE_2),
    ],
    [
      SamplingSizeService._RANGE_4,
      EQpAQLInspectionLevel.I,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_B, EQpAQLSampleSize.SIZE_3),
    ],
    [
      SamplingSizeService._RANGE_5,
      EQpAQLInspectionLevel.I,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_C, EQpAQLSampleSize.SIZE_5),
    ],
    [
      SamplingSizeService._RANGE_6,
      EQpAQLInspectionLevel.I,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_C, EQpAQLSampleSize.SIZE_5),
    ],
    [
      SamplingSizeService._RANGE_7,
      EQpAQLInspectionLevel.I,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_D, EQpAQLSampleSize.SIZE_8),
    ],
    [
      SamplingSizeService._RANGE_8,
      EQpAQLInspectionLevel.I,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_E, EQpAQLSampleSize.SIZE_13),
    ],
    [
      SamplingSizeService._RANGE_9,
      EQpAQLInspectionLevel.I,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_F, EQpAQLSampleSize.SIZE_20),
    ],
    [
      SamplingSizeService._RANGE_10,
      EQpAQLInspectionLevel.I,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_G, EQpAQLSampleSize.SIZE_32),
    ],
    [
      SamplingSizeService._RANGE_11,
      EQpAQLInspectionLevel.I,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_H, EQpAQLSampleSize.SIZE_50),
    ],
    [
      SamplingSizeService._RANGE_12,
      EQpAQLInspectionLevel.I,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_J, EQpAQLSampleSize.SIZE_80),
    ],
    [
      SamplingSizeService._RANGE_13,
      EQpAQLInspectionLevel.I,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_K, EQpAQLSampleSize.SIZE_125),
    ],
    [
      SamplingSizeService._RANGE_14,
      EQpAQLInspectionLevel.I,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_L, EQpAQLSampleSize.SIZE_200),
    ],
    [
      SamplingSizeService._RANGE_15,
      EQpAQLInspectionLevel.I,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_M, EQpAQLSampleSize.SIZE_315),
    ],
    [
      SamplingSizeService._RANGE_16,
      EQpAQLInspectionLevel.I,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_N, EQpAQLSampleSize.SIZE_500),
    ],

    [
      SamplingSizeService._RANGE_0,
      EQpAQLInspectionLevel.II,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_X, EQpAQLSampleSize.SIZE_0),
    ], // Non standard
    [
      SamplingSizeService._RANGE_1,
      EQpAQLInspectionLevel.II,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_Z, EQpAQLSampleSize.SIZE_1),
    ], // Non standard
    [
      SamplingSizeService._RANGE_2,
      EQpAQLInspectionLevel.II,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_A, EQpAQLSampleSize.SIZE_2),
    ],
    [
      SamplingSizeService._RANGE_3,
      EQpAQLInspectionLevel.II,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_B, EQpAQLSampleSize.SIZE_3),
    ],
    [
      SamplingSizeService._RANGE_4,
      EQpAQLInspectionLevel.II,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_C, EQpAQLSampleSize.SIZE_5),
    ],
    [
      SamplingSizeService._RANGE_5,
      EQpAQLInspectionLevel.II,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_D, EQpAQLSampleSize.SIZE_8),
    ],
    [
      SamplingSizeService._RANGE_6,
      EQpAQLInspectionLevel.II,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_E, EQpAQLSampleSize.SIZE_13),
    ],
    [
      SamplingSizeService._RANGE_7,
      EQpAQLInspectionLevel.II,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_F, EQpAQLSampleSize.SIZE_20),
    ],
    [
      SamplingSizeService._RANGE_8,
      EQpAQLInspectionLevel.II,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_G, EQpAQLSampleSize.SIZE_32),
    ],
    [
      SamplingSizeService._RANGE_9,
      EQpAQLInspectionLevel.II,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_H, EQpAQLSampleSize.SIZE_50),
    ],
    [
      SamplingSizeService._RANGE_10,
      EQpAQLInspectionLevel.II,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_J, EQpAQLSampleSize.SIZE_80),
    ],
    [
      SamplingSizeService._RANGE_11,
      EQpAQLInspectionLevel.II,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_K, EQpAQLSampleSize.SIZE_125),
    ],
    [
      SamplingSizeService._RANGE_12,
      EQpAQLInspectionLevel.II,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_L, EQpAQLSampleSize.SIZE_200),
    ],
    [
      SamplingSizeService._RANGE_13,
      EQpAQLInspectionLevel.II,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_M, EQpAQLSampleSize.SIZE_315),
    ],
    [
      SamplingSizeService._RANGE_14,
      EQpAQLInspectionLevel.II,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_N, EQpAQLSampleSize.SIZE_500),
    ],
    [
      SamplingSizeService._RANGE_15,
      EQpAQLInspectionLevel.II,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_P, EQpAQLSampleSize.SIZE_800),
    ],
    [
      SamplingSizeService._RANGE_16,
      EQpAQLInspectionLevel.II,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_Q, EQpAQLSampleSize.SIZE_1250),
    ],

    [
      SamplingSizeService._RANGE_0,
      EQpAQLInspectionLevel.III,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_X, EQpAQLSampleSize.SIZE_0),
    ], // Non standard
    [
      SamplingSizeService._RANGE_1,
      EQpAQLInspectionLevel.III,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_Z, EQpAQLSampleSize.SIZE_1),
    ], // Non standard
    [
      SamplingSizeService._RANGE_2,
      EQpAQLInspectionLevel.III,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_B, EQpAQLSampleSize.SIZE_3),
    ],
    [
      SamplingSizeService._RANGE_3,
      EQpAQLInspectionLevel.III,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_C, EQpAQLSampleSize.SIZE_5),
    ],
    [
      SamplingSizeService._RANGE_4,
      EQpAQLInspectionLevel.III,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_D, EQpAQLSampleSize.SIZE_8),
    ],
    [
      SamplingSizeService._RANGE_5,
      EQpAQLInspectionLevel.III,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_E, EQpAQLSampleSize.SIZE_13),
    ],
    [
      SamplingSizeService._RANGE_6,
      EQpAQLInspectionLevel.III,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_F, EQpAQLSampleSize.SIZE_20),
    ],
    [
      SamplingSizeService._RANGE_7,
      EQpAQLInspectionLevel.III,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_G, EQpAQLSampleSize.SIZE_32),
    ],
    [
      SamplingSizeService._RANGE_8,
      EQpAQLInspectionLevel.III,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_H, EQpAQLSampleSize.SIZE_50),
    ],
    [
      SamplingSizeService._RANGE_9,
      EQpAQLInspectionLevel.III,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_J, EQpAQLSampleSize.SIZE_80),
    ],
    [
      SamplingSizeService._RANGE_10,
      EQpAQLInspectionLevel.III,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_K, EQpAQLSampleSize.SIZE_125),
    ],
    [
      SamplingSizeService._RANGE_11,
      EQpAQLInspectionLevel.III,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_L, EQpAQLSampleSize.SIZE_200),
    ],
    [
      SamplingSizeService._RANGE_12,
      EQpAQLInspectionLevel.III,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_M, EQpAQLSampleSize.SIZE_315),
    ],
    [
      SamplingSizeService._RANGE_13,
      EQpAQLInspectionLevel.III,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_N, EQpAQLSampleSize.SIZE_500),
    ],
    [
      SamplingSizeService._RANGE_14,
      EQpAQLInspectionLevel.III,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_P, EQpAQLSampleSize.SIZE_800),
    ],
    [
      SamplingSizeService._RANGE_15,
      EQpAQLInspectionLevel.III,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_Q, EQpAQLSampleSize.SIZE_1250),
    ],
    [
      SamplingSizeService._RANGE_16,
      EQpAQLInspectionLevel.III,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_R, EQpAQLSampleSize.SIZE_2000),
    ],

    [
      SamplingSizeService._RANGE_0,
      EQpAQLInspectionLevel.S1,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_X, EQpAQLSampleSize.SIZE_0),
    ], // Non standard
    [
      SamplingSizeService._RANGE_1,
      EQpAQLInspectionLevel.S1,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_Z, EQpAQLSampleSize.SIZE_1),
    ], // Non standard
    [
      SamplingSizeService._RANGE_2,
      EQpAQLInspectionLevel.S1,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_A, EQpAQLSampleSize.SIZE_2),
    ],
    [
      SamplingSizeService._RANGE_3,
      EQpAQLInspectionLevel.S1,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_A, EQpAQLSampleSize.SIZE_2),
    ],
    [
      SamplingSizeService._RANGE_4,
      EQpAQLInspectionLevel.S1,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_A, EQpAQLSampleSize.SIZE_2),
    ],
    [
      SamplingSizeService._RANGE_5,
      EQpAQLInspectionLevel.S1,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_A, EQpAQLSampleSize.SIZE_2),
    ],
    [
      SamplingSizeService._RANGE_6,
      EQpAQLInspectionLevel.S1,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_B, EQpAQLSampleSize.SIZE_3),
    ],
    [
      SamplingSizeService._RANGE_7,
      EQpAQLInspectionLevel.S1,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_B, EQpAQLSampleSize.SIZE_3),
    ],
    [
      SamplingSizeService._RANGE_8,
      EQpAQLInspectionLevel.S1,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_B, EQpAQLSampleSize.SIZE_3),
    ],
    [
      SamplingSizeService._RANGE_9,
      EQpAQLInspectionLevel.S1,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_B, EQpAQLSampleSize.SIZE_3),
    ],
    [
      SamplingSizeService._RANGE_10,
      EQpAQLInspectionLevel.S1,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_C, EQpAQLSampleSize.SIZE_5),
    ],
    [
      SamplingSizeService._RANGE_11,
      EQpAQLInspectionLevel.S1,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_C, EQpAQLSampleSize.SIZE_5),
    ],
    [
      SamplingSizeService._RANGE_12,
      EQpAQLInspectionLevel.S1,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_C, EQpAQLSampleSize.SIZE_5),
    ],
    [
      SamplingSizeService._RANGE_13,
      EQpAQLInspectionLevel.S1,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_C, EQpAQLSampleSize.SIZE_5),
    ],
    [
      SamplingSizeService._RANGE_14,
      EQpAQLInspectionLevel.S1,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_D, EQpAQLSampleSize.SIZE_8),
    ],
    [
      SamplingSizeService._RANGE_15,
      EQpAQLInspectionLevel.S1,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_D, EQpAQLSampleSize.SIZE_8),
    ],
    [
      SamplingSizeService._RANGE_16,
      EQpAQLInspectionLevel.S1,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_D, EQpAQLSampleSize.SIZE_8),
    ],

    [
      SamplingSizeService._RANGE_0,
      EQpAQLInspectionLevel.S2,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_X, EQpAQLSampleSize.SIZE_0),
    ], // Non standard
    [
      SamplingSizeService._RANGE_1,
      EQpAQLInspectionLevel.S2,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_Z, EQpAQLSampleSize.SIZE_1),
    ], // Non standard
    [
      SamplingSizeService._RANGE_2,
      EQpAQLInspectionLevel.S2,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_A, EQpAQLSampleSize.SIZE_2),
    ],
    [
      SamplingSizeService._RANGE_3,
      EQpAQLInspectionLevel.S2,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_A, EQpAQLSampleSize.SIZE_2),
    ],
    [
      SamplingSizeService._RANGE_4,
      EQpAQLInspectionLevel.S2,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_A, EQpAQLSampleSize.SIZE_2),
    ],
    [
      SamplingSizeService._RANGE_5,
      EQpAQLInspectionLevel.S2,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_B, EQpAQLSampleSize.SIZE_3),
    ],
    [
      SamplingSizeService._RANGE_6,
      EQpAQLInspectionLevel.S2,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_B, EQpAQLSampleSize.SIZE_3),
    ],
    [
      SamplingSizeService._RANGE_7,
      EQpAQLInspectionLevel.S2,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_B, EQpAQLSampleSize.SIZE_3),
    ],
    [
      SamplingSizeService._RANGE_8,
      EQpAQLInspectionLevel.S2,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_C, EQpAQLSampleSize.SIZE_5),
    ],
    [
      SamplingSizeService._RANGE_9,
      EQpAQLInspectionLevel.S2,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_C, EQpAQLSampleSize.SIZE_5),
    ],
    [
      SamplingSizeService._RANGE_10,
      EQpAQLInspectionLevel.S2,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_C, EQpAQLSampleSize.SIZE_5),
    ],
    [
      SamplingSizeService._RANGE_11,
      EQpAQLInspectionLevel.S2,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_D, EQpAQLSampleSize.SIZE_8),
    ],
    [
      SamplingSizeService._RANGE_12,
      EQpAQLInspectionLevel.S2,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_D, EQpAQLSampleSize.SIZE_8),
    ],
    [
      SamplingSizeService._RANGE_13,
      EQpAQLInspectionLevel.S2,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_D, EQpAQLSampleSize.SIZE_8),
    ],
    [
      SamplingSizeService._RANGE_14,
      EQpAQLInspectionLevel.S2,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_E, EQpAQLSampleSize.SIZE_13),
    ],
    [
      SamplingSizeService._RANGE_15,
      EQpAQLInspectionLevel.S2,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_E, EQpAQLSampleSize.SIZE_13),
    ],
    [
      SamplingSizeService._RANGE_16,
      EQpAQLInspectionLevel.S2,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_E, EQpAQLSampleSize.SIZE_13),
    ],

    [
      SamplingSizeService._RANGE_0,
      EQpAQLInspectionLevel.S3,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_X, EQpAQLSampleSize.SIZE_0),
    ], // Non standard
    [
      SamplingSizeService._RANGE_1,
      EQpAQLInspectionLevel.S3,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_Z, EQpAQLSampleSize.SIZE_1),
    ], // Non standard
    [
      SamplingSizeService._RANGE_2,
      EQpAQLInspectionLevel.S3,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_A, EQpAQLSampleSize.SIZE_2),
    ],
    [
      SamplingSizeService._RANGE_3,
      EQpAQLInspectionLevel.S3,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_A, EQpAQLSampleSize.SIZE_2),
    ],
    [
      SamplingSizeService._RANGE_4,
      EQpAQLInspectionLevel.S3,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_B, EQpAQLSampleSize.SIZE_3),
    ],
    [
      SamplingSizeService._RANGE_5,
      EQpAQLInspectionLevel.S3,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_B, EQpAQLSampleSize.SIZE_3),
    ],
    [
      SamplingSizeService._RANGE_6,
      EQpAQLInspectionLevel.S3,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_C, EQpAQLSampleSize.SIZE_5),
    ],
    [
      SamplingSizeService._RANGE_7,
      EQpAQLInspectionLevel.S3,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_C, EQpAQLSampleSize.SIZE_5),
    ],
    [
      SamplingSizeService._RANGE_8,
      EQpAQLInspectionLevel.S3,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_D, EQpAQLSampleSize.SIZE_8),
    ],
    [
      SamplingSizeService._RANGE_9,
      EQpAQLInspectionLevel.S3,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_D, EQpAQLSampleSize.SIZE_8),
    ],
    [
      SamplingSizeService._RANGE_10,
      EQpAQLInspectionLevel.S3,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_E, EQpAQLSampleSize.SIZE_13),
    ],
    [
      SamplingSizeService._RANGE_11,
      EQpAQLInspectionLevel.S3,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_E, EQpAQLSampleSize.SIZE_13),
    ],
    [
      SamplingSizeService._RANGE_12,
      EQpAQLInspectionLevel.S3,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_F, EQpAQLSampleSize.SIZE_20),
    ],
    [
      SamplingSizeService._RANGE_13,
      EQpAQLInspectionLevel.S3,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_F, EQpAQLSampleSize.SIZE_20),
    ],
    [
      SamplingSizeService._RANGE_14,
      EQpAQLInspectionLevel.S3,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_G, EQpAQLSampleSize.SIZE_32),
    ],
    [
      SamplingSizeService._RANGE_15,
      EQpAQLInspectionLevel.S3,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_G, EQpAQLSampleSize.SIZE_32),
    ],
    [
      SamplingSizeService._RANGE_16,
      EQpAQLInspectionLevel.S3,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_H, EQpAQLSampleSize.SIZE_50),
    ],

    [
      SamplingSizeService._RANGE_0,
      EQpAQLInspectionLevel.S4,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_X, EQpAQLSampleSize.SIZE_0),
    ], // Non standard
    [
      SamplingSizeService._RANGE_1,
      EQpAQLInspectionLevel.S4,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_Z, EQpAQLSampleSize.SIZE_1),
    ], // Non standard
    [
      SamplingSizeService._RANGE_2,
      EQpAQLInspectionLevel.S4,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_A, EQpAQLSampleSize.SIZE_2),
    ],
    [
      SamplingSizeService._RANGE_3,
      EQpAQLInspectionLevel.S4,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_A, EQpAQLSampleSize.SIZE_2),
    ],
    [
      SamplingSizeService._RANGE_4,
      EQpAQLInspectionLevel.S4,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_B, EQpAQLSampleSize.SIZE_3),
    ],
    [
      SamplingSizeService._RANGE_5,
      EQpAQLInspectionLevel.S4,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_C, EQpAQLSampleSize.SIZE_5),
    ],
    [
      SamplingSizeService._RANGE_6,
      EQpAQLInspectionLevel.S4,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_C, EQpAQLSampleSize.SIZE_5),
    ],
    [
      SamplingSizeService._RANGE_7,
      EQpAQLInspectionLevel.S4,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_D, EQpAQLSampleSize.SIZE_8),
    ],
    [
      SamplingSizeService._RANGE_8,
      EQpAQLInspectionLevel.S4,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_E, EQpAQLSampleSize.SIZE_13),
    ],
    [
      SamplingSizeService._RANGE_9,
      EQpAQLInspectionLevel.S4,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_E, EQpAQLSampleSize.SIZE_13),
    ],
    [
      SamplingSizeService._RANGE_10,
      EQpAQLInspectionLevel.S4,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_F, EQpAQLSampleSize.SIZE_20),
    ],
    [
      SamplingSizeService._RANGE_11,
      EQpAQLInspectionLevel.S4,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_G, EQpAQLSampleSize.SIZE_32),
    ],
    [
      SamplingSizeService._RANGE_12,
      EQpAQLInspectionLevel.S4,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_G, EQpAQLSampleSize.SIZE_32),
    ],
    [
      SamplingSizeService._RANGE_13,
      EQpAQLInspectionLevel.S4,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_H, EQpAQLSampleSize.SIZE_50),
    ],
    [
      SamplingSizeService._RANGE_14,
      EQpAQLInspectionLevel.S4,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_J, EQpAQLSampleSize.SIZE_80),
    ],
    [
      SamplingSizeService._RANGE_15,
      EQpAQLInspectionLevel.S4,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_J, EQpAQLSampleSize.SIZE_80),
    ],
    [
      SamplingSizeService._RANGE_16,
      EQpAQLInspectionLevel.S4,
      <MiniPair<EQpAQLCodeLetter, EQpAQLSampleSize>>MiniPair.of(EQpAQLCodeLetter.LETTER_K, EQpAQLSampleSize.SIZE_125),
    ],
  ]);

  /**
   * @param inspectionLevel
   * @param quantity
   * @deprecated
   * Use instead {@link QpAql}
   */
  public static getSamplingSize(
    inspectionLevel: Readonly<EQpAQLInspectionLevel>,
    quantity: Readonly<number>
  ): MiniPair<EQpAQLCodeLetter, number> | never {
    const range: QimaOptionalType<QpMiniRange> = SamplingSizeService._getRange(quantity);

    if (isNil(range)) {
      throw new Error(
        `The sampling size cannot be computed with the quantity ${quantity} is a invalid lot size and ${inspectionLevel} level`
      );
    }

    const pair: MiniPair<EQpAQLCodeLetter, number> = SamplingSizeService._codeLettersMap.get(range, inspectionLevel);

    return MiniPair.of(pair.getLeft(), Math.min(pair.getRight(), quantity));
  }

  /**
   * @param inspectionLevel
   * @param quantity
   * @deprecated
   * Use instead {@link QpAql}
   */
  public static getCodeLetter(inspectionLevel: Readonly<EQpAQLInspectionLevel>, quantity: Readonly<number>): EQpAQLCodeLetter | never {
    const range: QimaOptionalType<QpMiniRange> = SamplingSizeService._getRange(quantity);

    if (isNil(range)) {
      throw new Error(
        `The sampling size cannot be computed with the quantity ${quantity} is a invalid lot size and ${inspectionLevel} level`
      );
    }

    const pair: MiniPair<EQpAQLCodeLetter, number> = SamplingSizeService._codeLettersMap.get(range, inspectionLevel);

    return pair.getLeft();
  }

  private static _getRange(lotSize: Readonly<number>): QimaOptionalType<QpMiniRange> {
    return SamplingSizeService.ranges.find((range: QpMiniRange): boolean => range.contains(lotSize));
  }
}
