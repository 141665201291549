// @ts-strict-ignore
import { qpNotBlankValidator } from '@library/validators/qp-not-blank-validator';
import { IChecklistComment } from '@one/app/shared/models/inspection-consultation/inspection-consultation.models';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

export const INSPECTION_COMMENT_VALIDATORS = [Validators.required, qpNotBlankValidator];

export class InspectionComment implements IChecklistComment {
  public static fromIInspectionComment(comment: IChecklistComment, isMessageRequired: Readonly<boolean> = false): InspectionComment {
    const inspectionComment = new InspectionComment();

    inspectionComment.message = comment.message;
    inspectionComment.timezone = comment.timezone;
    inspectionComment.date = comment.date;
    inspectionComment.form = new UntypedFormGroup({
      message: new UntypedFormControl(comment.message, isMessageRequired ? INSPECTION_COMMENT_VALIDATORS : []),
      timezone: new UntypedFormControl(comment.timezone),
      date: new UntypedFormControl(comment.date),
    });

    return inspectionComment;
  }

  public message: string;
  public date: Date;
  public timezone: string;
  public form: UntypedFormGroup;
}
