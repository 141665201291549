import { EQpImageElementType } from '@library/models/qp-image.models';
import { InspectionId } from '@one/app/pages/isp/shared/models/isp-inspection.models';
import { QimaOptionalType } from '@qima/ngx-qima';

export enum ETrackingPictureSource {
  CAMERA = 'camera',
  GALLERY = 'gallery',
}

/**
 * Data needed to know for what part of the app the pictures are for
 * Which feature, for which inspection, which test/defect id and which product, when applicable
 */
export interface IInspectorPictureSettings {
  imageElementType: QimaOptionalType<EQpImageElementType>;
  inspectionId: InspectionId;
  entityId: QimaOptionalType<string>;
  purchaseOrderProductId?: QimaOptionalType<number>;
  pictureSource?: ETrackingPictureSource;
}
