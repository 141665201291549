import { QpCountry } from '@library/models/qp-country.models';
import { Pipe, PipeTransform } from '@angular/core';
import PhoneNumber from 'awesome-phonenumber';

@Pipe({
  name: 'qpPhone',
  standalone: true,
})
export class QpPhonePipe implements PipeTransform {
  public transform(phoneNumber: string, country: QpCountry): string {
    if (phoneNumber && phoneNumber.length > 0) {
      if (!country) {
        const awesomePhoneNumber = new PhoneNumber(phoneNumber, '');

        country = QpCountry.create({
          isoCode: awesomePhoneNumber.getRegionCode(),
          callPrefix: `+${awesomePhoneNumber.getCountryCode()}`,
          label: '',
        });
      }

      let significant = phoneNumber.replace(country.callPrefix ?? '', '').replace(/\D/g, '');

      if (significant.length > 15) {
        significant = significant.substring(0, 15);
      }

      const asYouType = PhoneNumber.getAsYouType(country.isoCode);

      asYouType.reset(`${country.callPrefix}${significant}`);
      const formattedPhoneNumber: string = asYouType.number();

      if (formattedPhoneNumber.includes(`${country.callPrefix} `)) {
        return formattedPhoneNumber.replace(`${country.callPrefix} `, '');
      }

      return formattedPhoneNumber.replace(`${country.callPrefix}`, '');
    }

    return '';
  }
}
