import { SwCheckForUpdateService } from '@one/app/shared/services/sw/sw-check-for-update.service';
import { SwLogUpdateService } from '@one/app/shared/services/sw/sw-log-update.service';
import { SwReloadOnUpdateService } from '@one/app/shared/services/sw/sw-reload-on-update.service';

/**
 * @param swLogUpdateService
 * @param swReloadOnUpdateService
 * @param swCheckForUpdateService
 */
export function serviceWorkerFactory(
  swLogUpdateService: SwLogUpdateService,
  swReloadOnUpdateService: SwReloadOnUpdateService,
  swCheckForUpdateService: SwCheckForUpdateService
): () => void {
  return (): void => {
    swLogUpdateService.init();
    swCheckForUpdateService.init();
    swReloadOnUpdateService.init();
  };
}
