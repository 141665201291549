import { AutoUnsubscriber } from '@library/classes/auto-unsubscriber/auto-unsubscriber';
import { Directive, OnInit, TemplateRef, ViewContainerRef, ChangeDetectorRef } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * @description
 * Toggle state handler for the device and medias queries for Angular
 */
@Directive()
export abstract class QpDeviceDirective<TComponent> extends AutoUnsubscriber implements OnInit {
  protected constructor(
    private readonly _templateRef: TemplateRef<TComponent>,
    private readonly _viewContainerRef: ViewContainerRef,
    private readonly _changeDetectorRef: ChangeDetectorRef
  ) {
    super();
  }

  public ngOnInit(): void {
    this.registerSubscription(
      this.onDeviceChange$().subscribe({
        next: (isVisible: Readonly<boolean>): void => {
          this._toggleVisibility(isVisible);
        },
      })
    );
  }

  private _toggleVisibility(isVisible: Readonly<boolean>): void {
    if (isVisible) {
      this._show();
    } else {
      this._hide();
    }
  }

  private _show(): void {
    this._viewContainerRef.createEmbeddedView(this._templateRef);
    this._changeDetectorRef.markForCheck();
  }

  private _hide(): void {
    this._viewContainerRef.clear();
    this._changeDetectorRef.markForCheck();
  }

  /**
   * @description
   * Provide an observable which will trigger the toggle process
   * So that the children can have their own logic
   *
   * @returns {Observable<boolean>}
   */
  public abstract onDeviceChange$(): Observable<boolean>;
}
