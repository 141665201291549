// @ts-strict-ignore
import { IQpPageQuery } from '@library/classes/qp-page/qp-page.models';
import { IQpDatatableFilterAndSortParam } from '@library/components/qp-datatable/qp-datatable.models';
import { ReportGroupFoundDTO } from '@library/dto/report-group/report-group-found.dto';
import { qpGetFilters } from '@library/functions/filters/qp-get-filters';
import { qpAsHttpParams } from '@library/functions/qp-as-http-params/qp-as-http-params';
import { qpHasSorts } from '@library/functions/sorts/qp-has-sorts';
import { IQpConsultationPage, QpConsultationPage } from '@library/models/qp-consultation-page.models';
import { SERVER_API_URL } from '@one/app/app.constants';
import { IPurchaseOrderInfo } from '@one/app/pages/brd/pages/product-po/pages/po/models/purchase-orders.model';
import { ICapConsultation } from '@one/app/pages/brd/pages/report/pages/cap/shared/models/cap.models';
import { BrdWorkflowChecklistConsultationType } from '@one/app/pages/brd/pages/workflow/pages/checklist/pages/consult/brd-workflow-checklist-consult.models';
import { IWorkflowConsultation } from '@one/app/pages/brd/pages/workflow/pages/consult/brd-workflow-consult.models';
import { IBrdProductConsultation } from '@one/app/pages/brd/shared/models/brd-product-consult.model';
import { IEntityConsultation } from '@one/app/pages/brd/shared/models/entity.model';
import { IESInspectionConsultation } from '@one/app/pages/brd/shared/models/es-inspection-consultation.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

enum Entity {
  CHECKLISTS = 'checklists',
  PURCHASE_ORDERS = 'purchase-orders',
  INSPECTIONS = 'inspections/search',
  WORKFLOWS = 'workflows',
  REPORTS = 'inspections/reports',
  ENTITIES = 'entities/search',
  CAPS = 'caps/search',
  PRODUCTS = 'products/search',
  REPORT_GROUPS = 'report-groups',
}

@Injectable({
  providedIn: 'root',
})
export class EntityConsultationService {
  public static readonly ENTITIES: Map<Entity, IQpDatatableFilterAndSortParam[]> = new Map([
    [Entity.PURCHASE_ORDERS, [{ type: 'sort', value: 'lastModifiedDate,desc' }]],
    [
      Entity.INSPECTIONS,
      [
        { type: 'sort', value: 'plannedDate,desc' },
        // Sort on id to have the most recently created
        // b/c we don't have history yet in Elastic
        { type: 'sort', value: 'id,desc' },
      ],
    ],
    [Entity.WORKFLOWS, [{ type: 'sort', value: 'lastModifiedDate,DESC' }]],
    [
      Entity.REPORTS,
      [
        { type: 'sort', value: 'inspectionDate,desc' },
        // Sort on id to have the most recently created
        // b/c we don't have history yet in Elastic
        { type: 'sort', value: 'createdDate,desc' },
      ],
    ],
    [
      Entity.ENTITIES,
      [
        { type: 'sort', value: 'lastModifiedDate,desc' },
        { type: 'sort', value: 'createdDate,desc' },
        { type: 'sort', value: 'name,asc' },
      ],
    ],
    [Entity.CAPS, [{ type: 'sort', value: 'lastModifiedDate,desc' }]],
    [Entity.PRODUCTS, [{ type: 'sort', value: 'lastModifiedDate,desc' }]],
    [Entity.CHECKLISTS, [{ type: 'sort', value: 'lastModifiedDate,DESC' }]],
    [Entity.REPORT_GROUPS, [{ type: 'sort', value: 'lastModifiedDate,DESC' }]],
  ]);

  private static _getDefaultSorts(entity: Entity): IQpDatatableFilterAndSortParam[] {
    return EntityConsultationService.ENTITIES.get(entity) ?? [];
  }

  private readonly _resourceUrl = `${SERVER_API_URL}api/`;

  public constructor(private readonly _httpClient: HttpClient) {}

  public getChecklists$(
    pageQuery: IQpPageQuery,
    filtersAndSorts: IQpDatatableFilterAndSortParam[] = []
  ): Observable<IQpConsultationPage<BrdWorkflowChecklistConsultationType>> {
    return this._getEntity$<BrdWorkflowChecklistConsultationType>(pageQuery, Entity.CHECKLISTS, filtersAndSorts);
  }

  public getWorkflows$(
    pageQuery: IQpPageQuery,
    filtersAndSorts: IQpDatatableFilterAndSortParam[] = []
  ): Observable<IQpConsultationPage<IWorkflowConsultation>> {
    return this._getEntity$<IWorkflowConsultation>(pageQuery, Entity.WORKFLOWS, filtersAndSorts).pipe(
      // ⚠️ The API GET workflows endpoint returns worflow templates
      //    For brand, "id" is the template id; but for entity it is "workflowTemplateId"
      map(
        (workflowResult): IQpConsultationPage<IWorkflowConsultation> => ({
          ...workflowResult,
          items: workflowResult.items?.map(
            (workflow): IWorkflowConsultation => ({ ...workflow, id: workflow.workflowTemplateId ?? workflow.id })
          ),
        })
      )
    );
  }

  public getWorkflowsWithInspectionTypeDifferentOf$(
    pageQuery: IQpPageQuery,
    filtersAndSorts: IQpDatatableFilterAndSortParam[] = []
  ): Observable<IQpConsultationPage<IWorkflowConsultation>> {
    return this.getWorkflows$(pageQuery, filtersAndSorts);
  }

  public getPurchaseOrders$(
    pageQuery: IQpPageQuery,
    filtersAndSorts: IQpDatatableFilterAndSortParam[] = []
  ): Observable<IQpConsultationPage<IPurchaseOrderInfo>> {
    return this._getEntity$<IPurchaseOrderInfo>(pageQuery, Entity.PURCHASE_ORDERS, filtersAndSorts);
  }

  public getReports$(
    pageQuery: IQpPageQuery,
    filtersAndSorts: IQpDatatableFilterAndSortParam[] = []
  ): Observable<IQpConsultationPage<IESInspectionConsultation>> {
    return this._getEntity$<IESInspectionConsultation>(pageQuery, Entity.REPORTS, filtersAndSorts);
  }

  public getEntities$(
    pageQuery: IQpPageQuery,
    filtersAndSorts: IQpDatatableFilterAndSortParam[] = []
  ): Observable<IQpConsultationPage<IEntityConsultation>> {
    return this._getEntity$<IEntityConsultation>(pageQuery, Entity.ENTITIES, filtersAndSorts);
  }

  public getInspections$(
    pageQuery: IQpPageQuery,
    filtersAndSorts: IQpDatatableFilterAndSortParam[] = []
  ): Observable<IQpConsultationPage<IESInspectionConsultation>> {
    return this._getEntity$<IESInspectionConsultation>(pageQuery, Entity.INSPECTIONS, filtersAndSorts);
  }

  public getCaps$(
    pageQuery: IQpPageQuery,
    filtersAndSorts: IQpDatatableFilterAndSortParam[] = []
  ): Observable<IQpConsultationPage<ICapConsultation>> {
    return this._getEntity$<ICapConsultation>(pageQuery, Entity.CAPS, filtersAndSorts);
  }

  public getProducts$(
    pageQuery: IQpPageQuery,
    filtersAndSorts: IQpDatatableFilterAndSortParam[] = []
  ): Observable<IQpConsultationPage<IBrdProductConsultation>> {
    return this._getEntity$<IBrdProductConsultation>(pageQuery, Entity.PRODUCTS, filtersAndSorts);
  }

  public getReportGroups$(
    pageQuery: IQpPageQuery,
    filtersAndSorts: IQpDatatableFilterAndSortParam[] = []
  ): Observable<IQpConsultationPage<ReportGroupFoundDTO>> {
    return this._getEntity$<ReportGroupFoundDTO>(pageQuery, Entity.REPORT_GROUPS, filtersAndSorts);
  }

  // public visibility only for testing
  public applyDefaultSortsIfNone(
    filtersAndSorts: IQpDatatableFilterAndSortParam[],
    defaultSorts: IQpDatatableFilterAndSortParam[] = []
  ): IQpDatatableFilterAndSortParam[] {
    const copy: IQpDatatableFilterAndSortParam[] = !qpHasSorts(filtersAndSorts)
      ? [...qpGetFilters(filtersAndSorts), ...defaultSorts]
      : [...filtersAndSorts];

    return copy;
  }

  private _getEntity$<T>(
    pageQuery: IQpPageQuery,
    entity: Entity,
    filtersAndSorts: IQpDatatableFilterAndSortParam[] = []
  ): Observable<IQpConsultationPage<T>> {
    const filtersAndSortsWithDefaults: IQpDatatableFilterAndSortParam[] = this.applyDefaultSortsIfNone(
      filtersAndSorts,
      EntityConsultationService._getDefaultSorts(entity)
    );
    const params = qpAsHttpParams(pageQuery, filtersAndSortsWithDefaults);

    return this._httpClient.get<T[]>(`${this._resourceUrl}${entity}`, { params, observe: 'response' }).pipe(
      map((response): IQpConsultationPage<T> => {
        const items: T[] = response.body ?? [];
        const totalItems = parseInt(response.headers.get('x-total-count'), 10);
        const totalPageCount = parseInt(response.headers.get('x-total-page'), 10);

        return QpConsultationPage.create<T>({
          items,
          page: pageQuery.page + 1,
          pageSize: pageQuery.size,
          totalItems,
          totalPageCount,
        });
      })
    );
  }
}
