import { IspWorkflowStepActionSimplifiedMeasurementChecklist } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/isp-workflow-step-action-simplified-measurement-checklist/isp-workflow-step-action-simplified-measurement-checklist';
import { IspWorkflowStepGroupAction } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/isp-workflow-step-grouped-action';
import { EWorkflowActionType } from '@one/app/shared/models/workflow/workflow.models';

export class IspWorkflowStepGroupSimplifiedMeasurementChecklistAction extends IspWorkflowStepGroupAction<IspWorkflowStepActionSimplifiedMeasurementChecklist> {
  public get type(): EWorkflowActionType.SIMPLIFIED_MEASUREMENTS_CHECKLIST {
    return EWorkflowActionType.SIMPLIFIED_MEASUREMENTS_CHECKLIST;
  }

  public constructor(actions: IspWorkflowStepActionSimplifiedMeasurementChecklist[]) {
    super(actions);
  }
}
