// @ts-strict-ignore
import { QpCountryService } from '@library/components/qp-country/services/qp-country.service';
import { QpCountry } from '@library/models/qp-country.models';
import { SERVER_API_URL } from '@one/app/app.constants';
import { StorageService } from '@one/app/shared/services/storage/storage.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CountryService implements QpCountryService {
  private readonly _resourceUrl = `${SERVER_API_URL}api/countries`;
  private readonly _countriesStorageKey = 'countries';

  public constructor(protected _httpClient: HttpClient, private readonly _storageService: StorageService) {}

  public getAllCountries$(): Observable<QpCountry[]> {
    const countriesSubject = new ReplaySubject<QpCountry[]>(1);

    this._storageService
      .getEphemeralData(this._countriesStorageKey)
      .then((countries: QpCountry[]): void => {
        countriesSubject.next(countries);
      })
      .catch((): void => {
        this._httpClient.get<QpCountry[]>(this._resourceUrl, { observe: 'body' }).subscribe((value: QpCountry[]): void => {
          this._storageService.saveEphemeralData(this._countriesStorageKey, value);
          countriesSubject.next(value);
        });
      });

    return countriesSubject.asObservable();
  }
}
