import { QimaBrandFrontAppModule } from '@one/app/app.module';
import { ENVIRONMENT } from '@one/environments/environment';
import { enableProdMode, NgModuleRef } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import 'hammerjs';

if (ENVIRONMENT.production) {
  enableProdMode();
}

/**
 * @description
 * It is not too late to run.
 * @returns PlatformRef
 */
function bootstrap(): Promise<NgModuleRef<QimaBrandFrontAppModule>> {
  return platformBrowserDynamic()
    .bootstrapModule(QimaBrandFrontAppModule, { preserveWhitespaces: true })
    .then((ngModuleRef: NgModuleRef<QimaBrandFrontAppModule>): Promise<NgModuleRef<QimaBrandFrontAppModule>> => {
      if (!('serviceWorker' in navigator)) {
        console.warn('Too bad, Service worker is not supported in current browser');

        return Promise.reject(new Error('Too bad, Service worker is not supported in current browser'));
      }

      return Promise.resolve(ngModuleRef);
    });
}

bootstrap().catch((error: unknown): void => {
  console.error(error);
});
