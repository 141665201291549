import { IQpAuthenticationCredentials } from '@library/models/auth/qp-auth.models';
import { SERVER_API_URL } from '@one/app/app.constants';
import { CommonRoutes } from '@one/app/common-routes';
import { ConnectService } from '@one/app/pages/connect/connect.service';
import { TokenInformations } from '@one/app/shared/models/auth/auth-tokens.models';
import { AuthenticationService } from '@one/app/shared/services/auth/authentication.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { QimaOptionalType } from '@qima/ngx-qima';
import dayjs from 'dayjs';
import { Base64 } from 'js-base64';
import { JwtPayload, jwtDecode } from 'jwt-decode';
import { isNil } from 'lodash/index';
import { CookieService } from 'ngx-cookie';
import { SessionStorageService } from 'ngx-webstorage';
import { Observable, lastValueFrom, of, throwError } from 'rxjs';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class JwtAuthenticationService extends AuthenticationService {
  private readonly qimaConnectAccessTokenKey = 'connect.accessToken';
  private readonly tokenKey = 'authenticationToken';
  private readonly rootSessionTokenKey = 'rootSessionTokenKey';
  private readonly qimaConnectRefreshTokenKey = 'connect.refreshToken';
  private readonly qimaConnectClientIdKey = 'connect.clientId';

  public constructor(
    private readonly _httpClient: HttpClient,
    private readonly _cookieStorage: CookieService,
    private readonly _connectService: ConnectService,
    private readonly _router: Router,
    private readonly _sessionStorageService: SessionStorageService
  ) {
    super();
  }

  public getToken$(): Observable<QimaOptionalType<string>> {
    return of(this._getToken());
  }

  public login$(credentials: IQpAuthenticationCredentials): Observable<string | never> {
    this._cookieStorage.remove(this.tokenKey);
    this._sessionStorageService.clear(this.rootSessionTokenKey);

    return this._httpClient.post(`${SERVER_API_URL}api/authenticate`, credentials, { observe: 'response' }).pipe(
      map((resp): string => {
        const bearerToken = resp.headers.get('Authorization');

        if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
          const jwt = bearerToken.slice(7, bearerToken.length);

          this.storeAuthenticationToken(jwt, credentials.rememberMe);

          return jwt;
        }

        throw new Error(`Unexpected format for the 'Authorization' headers`);
      })
    );
  }

  public loginAsBrand$(brandId: Readonly<number>): Observable<string> {
    return this._httpClient.post(`${SERVER_API_URL}api/authenticate/brand`, { brandId }, { observe: 'response' }).pipe(
      map((resp): string | never => {
        const bearerToken = resp.headers.get('Authorization');

        if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
          const jwt: string = bearerToken.slice(7, bearerToken.length);

          this.storeAuthenticationToken(jwt, true);

          return jwt;
        }

        throw new Error('No bearer token');
      })
    );
  }

  public loginAsEntity$(entityId: Readonly<number>): Observable<string> {
    const jwtToken: string | undefined = this._getToken();

    if (!jwtToken) {
      return throwError((): string => 'No bearer token');
    }

    const brandId = this._getBrandId(jwtToken);

    return this._httpClient
      .post(
        `${SERVER_API_URL}api/authenticate/entity`,
        {
          brandId,
          entityId,
        },
        { observe: 'response' }
      )
      .pipe(
        map((resp): string | never => {
          const bearerToken = resp.headers.get('Authorization');

          if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
            const jwt: string = bearerToken.slice(7, bearerToken.length);

            this.storeAuthenticationToken(jwt, true);

            return jwt;
          }

          throw new Error('No bearer token');
        })
      );
  }

  public loginAsInspector$(inspectorId: Readonly<number>): Observable<string> {
    const jwtToken: string | undefined = this._getToken();

    if (!jwtToken) {
      return throwError((): string => 'No bearer token');
    }

    // Store the current token in the session to be able to log back as supervisor after that
    this._copyAuthenticationTokenToRootSession();

    const brandId = this._getBrandId(jwtToken);

    return this._httpClient
      .post(
        `${SERVER_API_URL}api/authenticate/inspector`,
        {
          brandId,
          inspectorId,
        },
        { observe: 'response' }
      )
      .pipe(
        map((resp): string | never => {
          const bearerToken = resp.headers.get('Authorization');

          if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
            const jwt: string = bearerToken.slice(7, bearerToken.length);

            this.storeAuthenticationToken(jwt, true);

            return jwt;
          }

          throw new Error('No bearer token');
        })
      );
  }

  public storeAuthenticationToken(jwt, rememberMe): void {
    if (rememberMe) {
      this._cookieStorage.put(this.tokenKey, jwt, {
        expires: dayjs().add(30, 'days').toDate(),
      });
    } else {
      this._cookieStorage.put(this.tokenKey, jwt);
    }
  }

  public removeAuthenticationToken(): void {
    this._cookieStorage.remove(this.tokenKey);
  }

  public logBackWithRootSessionToken(): void {
    const jwt = this._getRootSessionToken();

    if (!jwt) {
      return;
    }

    this._cookieStorage.put(this.tokenKey, jwt);
    this._sessionStorageService.clear(this.rootSessionTokenKey);
  }

  public hasRootSessionToken(): boolean {
    return !isNil(this._getRootSessionToken());
  }

  public hasQimaConnectSession(): boolean {
    return (
      this._cookieStorage.hasKey(this.qimaConnectAccessTokenKey) &&
      this._cookieStorage.hasKey(this.qimaConnectRefreshTokenKey) &&
      this._cookieStorage.hasKey(this.qimaConnectClientIdKey)
    );
  }

  public storeQimaConnectTokens(accessToken: string, refreshToken: string, clientId: string): void {
    this._cookieStorage.put(this.qimaConnectAccessTokenKey, accessToken);
    this._cookieStorage.put(this.qimaConnectRefreshTokenKey, refreshToken);
    this._cookieStorage.put(this.qimaConnectClientIdKey, clientId);
  }

  public async isAuthenticated(): Promise<boolean> {
    const jwtToken = this._getToken();

    if (jwtToken) {
      const expirationDate = this._getExpirationTime(jwtToken);

      if (Date.now() / 1000 < expirationDate) {
        return Promise.resolve(true);
      }
    }

    return Promise.resolve(false);
  }

  public async logout(): Promise<void> {
    this._cookieStorage.remove(this.tokenKey);
    this._sessionStorageService.clear(this.rootSessionTokenKey);

    return lastValueFrom(
      this._logoutFromQimaConnect$().pipe(
        finalize((): void => {
          this.redirectToLogin();
        })
      )
    );
  }

  public redirectToLogin(): void {
    this._connectService.redirectToConnectOrNothing();
    void this._router.navigate([CommonRoutes.login()]);
  }

  public handleUnauthorized(_error: HttpErrorResponse): void {
    this.redirectToLogin();
  }

  public async setupAuthentication(): Promise<void> {
    return Promise.resolve();
  }

  public getQimaConnectToken$(): Observable<string> {
    const accessToken = this._cookieStorage.get(this.qimaConnectAccessTokenKey);

    if (accessToken && this._isQimaConnectSessionExpired()) {
      return this._connectService.refreshToken$(
        this._cookieStorage.get(this.qimaConnectClientIdKey) ?? '',
        jwtDecode(accessToken).sub ?? '',
        this._cookieStorage.get(this.qimaConnectRefreshTokenKey) ?? ''
      );
    }

    return of(accessToken ?? '');
  }

  private _getExpirationTime(token: string): number {
    const payloadJson = this._decodeToken(token);

    return payloadJson.exp;
  }

  private _getBrandId(token: string): number {
    const payloadJson = this._decodeToken(token);

    return payloadJson.brandId;
  }

  private _decodeToken(token: string): TokenInformations {
    const payload: string = Base64.decode(token.split('.')[1]);

    return JSON.parse(payload);
  }

  private _getToken(): string | undefined {
    return this._cookieStorage.get(this.tokenKey);
  }

  private _getRootSessionToken(): string | undefined {
    return this._sessionStorageService.retrieve(this.rootSessionTokenKey);
  }

  private _copyAuthenticationTokenToRootSession(): string | void {
    const jwt = this._getToken();

    if (!jwt) {
      return;
    }

    this._sessionStorageService.store(this.rootSessionTokenKey, jwt);

    return jwt;
  }

  private _isQimaConnectSessionExpired(): boolean {
    const accessToken = this._cookieStorage.get(this.qimaConnectAccessTokenKey) ?? '';
    const accessTokenPayload = jwtDecode(accessToken);

    return !isNil(accessTokenPayload.exp) && accessTokenPayload.exp * 1000 <= Date.now();
  }

  private _logoutFromQimaConnect$(): Observable<void> {
    if (this.hasQimaConnectSession()) {
      const accessToken = this._cookieStorage.get(this.qimaConnectAccessTokenKey) ?? '';
      const accessTokenPayload = jwtDecode<JwtPayload & { username: string }>(accessToken);
      let logoutCall$: Observable<void>;

      if (this._isQimaConnectSessionExpired()) {
        logoutCall$ = this._connectService
          .refreshToken$(
            this._cookieStorage.get(this.qimaConnectClientIdKey) ?? '',
            accessTokenPayload.sub ?? '',
            this._cookieStorage.get(this.qimaConnectRefreshTokenKey) ?? ''
          )
          .pipe(
            tap((refreshedAccessToken): void => this._cookieStorage.put(this.qimaConnectAccessTokenKey, refreshedAccessToken)),
            switchMap(
              (refreshedAccessToken): Observable<void> =>
                this._connectService.logout$(refreshedAccessToken, accessTokenPayload.username ?? '')
            ),
            catchError((): Observable<void> => new Observable<void>((observer): void => observer.complete()))
          );
      } else {
        logoutCall$ = this._connectService.logout$(accessToken ?? '', accessTokenPayload.username ?? '');
      }

      return logoutCall$.pipe(
        tap((): void => {
          this._cookieStorage.remove(this.qimaConnectAccessTokenKey);
          this._cookieStorage.remove(this.qimaConnectRefreshTokenKey);
          this._cookieStorage.remove(this.qimaConnectClientIdKey);
        })
      );
    }

    return of();
  }
}
