import { EQpNotificationId } from '@one/app/shared/models/messages/messages.models';
import { QimaOptionalType } from '@qima/ngx-qima';

export interface INotifuseMessage<T extends INotifuseMessageData> {
  notificationId: EQpNotificationId;
  data: T;
}

export interface INotifuseMessageData {
  userId: string;
}

export interface INotifuseConfig {
  projectId: string;
  userId: string;
  userHmac: string;
  onClickMessage?: <T extends INotifuseMessageData>(message: INotifuseMessage<T>) => void;
  bellSelector?: QimaOptionalType<string>;
  faviconSelector?: QimaOptionalType<string>;
  styles?: { [key: string]: QimaOptionalType<unknown> };
  classNames?: { [key: string]: QimaOptionalType<unknown> };
}

export interface INotifuseType {
  init: (config: INotifuseConfig) => void;
  open: () => void;
  close: () => void;
  destroy: () => void;
}

export enum ENotifuseClassName {
  CONTAINER = 'notifuse-dropdown-container',
  HEADER = 'notifuse-dropdown-container__header',
  FOOTER = 'notifuse-dropdown-container__footer',
  LINKS = 'notifuse-dropdown-container__links',
  ITEM_COVER = 'notifuse-dropdown-container__item-cover',
  ITEMS_LIST = 'notifuse-dropdown-container__items_list',
  ITEM_DESCRIPTION = 'notifuse-dropdown-container__item-description',
  ITEM_TIME = 'notifuse-dropdown-container__item-time',
}
