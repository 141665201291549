import { EQpProgressBarColor } from '@library/components/qp-progress-bar/qp-progress-bar.models';
import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'qp-progress-bar',
  templateUrl: './qp-progress-bar.component.html',
  styleUrls: ['./qp-progress-bar.component.scss'],
  standalone: true,
  imports: [NgClass],
})
export class QpProgressBarComponent {
  /**
   * @description
   * Min value should be 0
   * Max value should be 100
   */
  @Input() public value = 0;

  @Input() public color: EQpProgressBarColor = EQpProgressBarColor.DEFAULT;
}
