// @ts-strict-ignore
import { QpLabelComponent } from '../qp-label/qp-label.component';
import { AutoUnsubscriber } from '@library/classes/auto-unsubscriber/auto-unsubscriber';
import { QpCountryService } from '@library/components/qp-country/services/qp-country.service';
import { QpCountry } from '@library/models/qp-country.models';
import { QpPhonePipe } from '@library/pipes/qp-phone/qp-phone.pipe';
import { QpNotificationBarService } from '@library/services/qp-notification-bar/qp-notification-bar.service';
import { NgClass, NgIf, NgFor } from '@angular/common';
import { Component, Input, OnInit, Optional, Self } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl, Validators, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { QimaOptionalType } from '@qima/ngx-qima';
import PhoneNumber from 'awesome-phonenumber';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { Observable } from 'rxjs';

@Component({
  selector: 'qp-phone',
  templateUrl: './qp-phone.component.html',
  styleUrls: ['./qp-phone.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    QpLabelComponent,
    NzInputModule,
    NzSelectModule,
    ReactiveFormsModule,
    FormsModule,
    NgFor,
    TranslateModule,
    QpPhonePipe,
  ],
})
export class QpPhoneComponent extends AutoUnsubscriber implements ControlValueAccessor, OnInit {
  @Input() public value = '';
  @Input() public title = 'global.form.phone';
  @Input() public isRequired = false;
  @Input() public selectedCountry: QimaOptionalType<QpCountry> = undefined;
  @Input() public defaultCountryIsoCode: Observable<string> | string = '';
  /*
   * Show phone number as a label, instead of an input
   */
  @Input() public isReadOnly = false;
  @Input() public disabled = false;
  public countries: QpCountry[] = [];
  public phoneNumber;

  public constructor(
    private readonly _qpCountryService: QpCountryService,
    private readonly _qpNotificationBarService: QpNotificationBarService,
    @Optional() @Self() public controlDirective: NgControl
  ) {
    super();

    if (controlDirective) {
      controlDirective.valueAccessor = this;
    }
  }

  public ngOnInit(): void {
    if (this.controlDirective) {
      if (this.isRequired) {
        this.controlDirective.control.setValidators([Validators.required, this.validate.bind(this)]);
      } else {
        this.controlDirective.control.setValidators([this.validate.bind(this)]);
      }

      this.controlDirective.control.updateValueAndValidity();
    }

    this.getAllCountries();

    if (this.defaultCountryIsoCode && typeof this.defaultCountryIsoCode !== 'string' && !this.value) {
      this.registerSubscription(
        this.defaultCountryIsoCode.subscribe((countryIsoCode: string): void => {
          if (this.countries && this.countries.length > 0) {
            this.selectedCountry = this.countries.find((country: QpCountry): boolean => country.isoCode === countryIsoCode);
          }
        })
      );
    }
  }

  public validate({ value }: UntypedFormControl): QimaOptionalType<{ invalid: boolean }> {
    if (value && this.selectedCountry && this.selectedCountry.callPrefix && value.length > this.selectedCountry.callPrefix.length) {
      const pn = new PhoneNumber(value, this.selectedCountry.isoCode);

      return pn.isValid() ? null : { invalid: true };
    }

    return null;
  }

  public onCountryChanges(selectedCountry: QpCountry): void {
    this.selectedCountry = selectedCountry;
    this.writeValue(this.value);
  }

  public registerOnChange(fn: (val: string) => void): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: (val: string) => void): void {
    this._onTouch = fn;
  }

  public writeValue(value: string): void {
    if (value && value.length > 0) {
      this.value = value;
      const responseValue = this.selectedCountry?.callPrefix ? this.selectedCountry.callPrefix + value.replace(/\D/g, '') : `${value}`;

      this._onChange(responseValue);
      this._onTouch(responseValue);
    } else {
      this.value = '';
      this._onChange('');
      this._onTouch('');
    }
  }

  public onValueChange(event: string): void {
    if (event.length > 15) {
      this.writeValue(event.slice(0, 14));
    } else {
      this.writeValue((this.value = event));
    }
  }

  private _onTouch: (val: string) => void = (): void => {
    return;
  };

  private getAllCountries(): void {
    this.registerSubscription(
      this._qpCountryService.getAllCountries$().subscribe(
        (response: QpCountry[]): void => {
          if (response.length === 0) {
            this._qpNotificationBarService.error('error.no-country-available');

            return;
          }

          const supportedCountries: string[] = PhoneNumber.getSupportedRegionCodes();

          this.countries = response
            .filter((country: QpCountry): boolean => supportedCountries.some((key): boolean => key === country.isoCode))
            .map((country: QpCountry): QpCountry => {
              country.callPrefix = `+${PhoneNumber.getCountryCodeForRegionCode(country.isoCode)}`;

              return country;
            });
          const defaultCountryIsoCodeIsNotAnObservableAndValueIsEmpty =
            this.defaultCountryIsoCode && typeof this.defaultCountryIsoCode === 'string' && !this.value;

          if (defaultCountryIsoCodeIsNotAnObservableAndValueIsEmpty) {
            if (this.countries && this.countries.length > 0) {
              this.selectedCountry = this.countries.find((country: QpCountry): boolean => country.isoCode === this.defaultCountryIsoCode);
            }
          } else if (!this.selectedCountry) {
            try {
              this.phoneNumber = new PhoneNumber(this.value, '');
              this.selectedCountry = this.countries.find(
                (country: QpCountry): boolean => country.isoCode === this.phoneNumber.getRegionCode()
              );

              if (!this.selectedCountry) {
                this.selectedCountry = this.countries[0];
              }
            } catch (e) {
              this.selectedCountry = this.countries[0];
            }
          }

          if (this.value && this.value.length > 0) {
            this._onChange(this.value);
            this._onTouch(this.value);
          }
        },
        (): void => {
          this._qpNotificationBarService.error('error.no-country-available');
        }
      )
    );
  }

  private _onChange: (val: string) => void = (): void => {
    return;
  };
}
