import { InspectionTypeSettingDTO } from '@library/dto/inspection-type-settings.dto';
import { SERVER_API_URL } from '@one/app/app.constants';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
const API_URL = `${SERVER_API_URL}api`;

@Injectable({
  providedIn: 'root',
})
export class SettingsInspectionTypesService {
  public constructor(private readonly _httpClient: HttpClient) {}

  public getInspectionTypes$(brandId: Readonly<string>): Observable<InspectionTypeSettingDTO[]> {
    return this._httpClient.get<InspectionTypeSettingDTO[]>(`${API_URL}/brands/${brandId}/settings/inspection-types`, { observe: 'body' });
  }

  public getInspectionType$(brandId: Readonly<string>, inspectionTypeId: Readonly<number>): Observable<InspectionTypeSettingDTO> {
    return this._httpClient.get<InspectionTypeSettingDTO>(`${API_URL}/brands/${brandId}/settings/inspection-types/${inspectionTypeId}`, {
      observe: 'body',
    });
  }

  public createInspectionTypes$(
    brandId: Readonly<string>,
    settingInspectionType: Readonly<InspectionTypeSettingDTO>
  ): Observable<InspectionTypeSettingDTO> {
    return this._httpClient.post<InspectionTypeSettingDTO>(`${API_URL}/brands/${brandId}/settings/inspection-types`, settingInspectionType);
  }

  public editInspectionTypes$(
    brandId: Readonly<string>,
    inspectionSettingId: Readonly<number>,
    settingInspectionType: Readonly<InspectionTypeSettingDTO>
  ): Observable<InspectionTypeSettingDTO> {
    return this._httpClient.put<InspectionTypeSettingDTO>(
      `${API_URL}/brands/${brandId}/settings/inspection-types/${inspectionSettingId}`,
      settingInspectionType
    );
  }

  public deleteInspectionType$(brandId: Readonly<string>, inspectionSettingId: Readonly<number>): Observable<void> {
    return this._httpClient.delete<void>(`${API_URL}/brands/${brandId}/settings/inspection-types/${inspectionSettingId}`);
  }
}
