import { AutoUnsubscriber } from '@library/classes/auto-unsubscriber/auto-unsubscriber';
import { Injectable } from '@angular/core';
import { Router, NavigationEnd, Event } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class PreviousRouteService extends AutoUnsubscriber {
  private _previousUrl = '';
  private _currentUrl: string;

  public constructor(private readonly _router: Router) {
    super();
    this._currentUrl = this._router.url;
    this.registerSubscription(
      _router.events.subscribe((event: Event): void => {
        if (event instanceof NavigationEnd) {
          this._previousUrl = this._currentUrl;
          this._currentUrl = event.url;

          if (this._previousUrl === this._currentUrl) {
            this._previousUrl = '/';
          }
        }
      })
    );
  }

  public getPreviousUrl(): string {
    return this._previousUrl;
  }
}
