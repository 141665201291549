import { CreateInspectionDTO } from '@library/dto/inspection/create-inspection.dto';
import { CustomFieldSnapshotDTO } from '@library/dto/inspection/snapshot/custom-field-snapshot.dto';
import { InspectionProductSnapshotDTO } from '@library/dto/inspection/snapshot/inspection/inspection-product-snapshot.dto';
import { PlatformUserSnapshotDTO } from '@library/dto/inspection/snapshot/user/platform-user-snapshot.dto';
import { EProductSpecificationType } from '@library/dto/product.dto';
import { CustomFieldElementValueDetailsDTO, CustomFieldValueDTO } from '@library/dto/workflow/custom-fields.dto';
import { AqlTypes } from '@library/dto-enums/aql-types.dto-enum';
import { InspectionStatus } from '@library/dto-enums/inspection-status.dto-enum';
import { MeasurementsSamplingSizeType } from '@library/dto-enums/measurements-sampling-size-types.dto-enum';
import { SamplingSizeType } from '@library/dto-enums/sampling-size-type.dto-enum';
import { QpDateService } from '@library/services/qp-date/qp-date.service';
import { IEntity } from '@one/app/pages/brd/shared/models/entity.model';
import { IInspectionConsultation, IInspectionManager } from '@one/app/shared/models/inspection-consultation/inspection-consultation.models';
import { IAbstractMultiProduct } from '@one/app/shared/models/products/product.models';
import { IWorkflowTemplateContent } from '@one/app/shared/models/workflows/workflows-templates.models';
import { Injectable } from '@angular/core';
import { isNil } from 'lodash/index';

/**
 * @param {CustomFieldSnapshotDTO} c the custom field snapshot dto to convert
 * @returns {CustomFieldElementValueDetailsDTO} the converted custom field element value details dto
 */
function convertCustomFieldSnapshotDTOToCustomFieldElementValueDetailsDTO(c: CustomFieldSnapshotDTO): CustomFieldElementValueDetailsDTO {
  return {
    value: c.value as unknown as CustomFieldValueDTO,
    order: c.order ?? 0,
    customFieldLabel: c.customFieldLabel,
  };
}

/**
 * @param {InspectionProductSnapshotDTO} inspectionProduct to convert
 * @returns {IAbstractMultiProduct} the converted inspection product
 */
function convertInspectionProductSnapshotDTOToIAbstractMultiProduct(
  inspectionProduct: InspectionProductSnapshotDTO
): IAbstractMultiProduct {
  return {
    productId: inspectionProduct.product.id,
    purchaseOrderProductId: inspectionProduct.purchaseOrderProductId,
    producedQuantity: inspectionProduct.producedQuantity,
    productQuantity: inspectionProduct.productQuantity,
    packedQuantity: inspectionProduct.packedQuantity,
    specification: inspectionProduct.product.specification,
    category: inspectionProduct.product.category,
    identifierType: EProductSpecificationType[inspectionProduct.product.identifierType],
    identifierValue: inspectionProduct.product.identifierValue,
    purchaseOrderId: inspectionProduct.purchaseOrder?.id,
    purchaseOrderReference: inspectionProduct.purchaseOrder?.reference,
    unit: inspectionProduct.unit,
    productCustomFields: inspectionProduct.product.customFields.map(
      (c): CustomFieldElementValueDetailsDTO => convertCustomFieldSnapshotDTOToCustomFieldElementValueDetailsDTO(c)
    ),
    purchaseOrderCustomFields: inspectionProduct.purchaseOrder?.customFields as unknown as CustomFieldElementValueDetailsDTO[],
  };
}

/**
 * @param {PlatformUserSnapshotDTO} p the platform user snapshot dto to convert
 * @returns {IInspectionManager} the converted inspection manager
 */
function convertPlatformUserSnapshotDTOToIInspectionManager(p: PlatformUserSnapshotDTO | undefined): IInspectionManager | undefined {
  if (isNil(p)) {
    return p;
  }

  return {
    id: p.id,
    firstName: p.contact.firstName,
    lastName: p.contact.lastName,
    email: p.contact.email,
    position: p.contact.position ?? '',
    phone: p.contact.phone ?? '',
  };
}

@Injectable({
  providedIn: 'root',
})
export class InspectionConsultationMapper {
  public fromCreateInspectionDTO(inspection: CreateInspectionDTO): IInspectionConsultation {
    if (isNil(inspection.snapshotContent)) {
      throw new Error('Inspection snapshot content is null');
    }

    return {
      id: 0, // 💡 0 it's added because primary key must not be null in index DB
      inspectionUuid: inspection.inspectionUuid,
      createdOffline: true,
      plannedDate: QpDateService.now,
      inspectionDate: QpDateService.now,
      shipmentDate: QpDateService.now,
      entity: null as unknown as IEntity, // 💡 nullable in case of self inspection;
      entityCountry: '',
      entityCity: '',
      inspectionType: inspection.snapshotContent.inspectionType,
      typeLabel: inspection.snapshotContent.inspectionType.label,
      status: inspection.status ?? InspectionStatus.STARTED,
      brandName: inspection.snapshotContent.brand.name,
      brandId: inspection.snapshotContent.brand.id,
      products: inspection.snapshotContent.products.map(
        (i: InspectionProductSnapshotDTO): IAbstractMultiProduct => convertInspectionProductSnapshotDTOToIAbstractMultiProduct(i)
      ),
      aqlDefects: {
        type: AqlTypes.NOT_USED,
      },
      isDefectsPerProduct: false,
      isAqlPerProduct: false,
      initialAqlDefects: {
        type: AqlTypes.NOT_USED,
      },
      samplingSize: {
        type: SamplingSizeType.NON_AQL_SAMPLE_SIZE,
        lotSize: inspection.samplingSize.lotSize,
        sampleSize: inspection.samplingSize.lotSize,
      },
      measurementsSamplingSize: {
        type: MeasurementsSamplingSizeType.NON_AQL_MEASUREMENTS_SAMPLING_SIZE,
      },
      unit: inspection.snapshotContent.products[0].unit,
      method: inspection.method,
      inspector: {
        id: inspection.snapshotContent.inspector.id,
        companyName: '',
        lastName: inspection.snapshotContent.inspector.contact.lastName,
        firstName: inspection.snapshotContent.inspector.contact.firstName,
      },
      manager: convertPlatformUserSnapshotDTOToIInspectionManager(inspection.snapshotContent.manager),
      workflowDetails: {
        id: inspection.workflow.workflowTemplateId || 0,
        name: '',
        qima: false,
        inspectionType: inspection.snapshotContent.inspectionType,
      },
      workflowSnapshot: {
        id: inspection.workflowId || 0,
        name: '',
        content: inspection.snapshotContent.workflow.content as unknown as IWorkflowTemplateContent,
      },
      destinations: [],
    };
  }
}
