/**
 * QIMAone sampling size implementations
 */
import { EAqlPoint, EAqlSampleSize, EInspectionLevel } from '@library/dto/workflow/aql.dto';
import { AqlValue } from '@library/dto-enums/aql-value.dto-enum';
import { SamplingSizeType } from '@library/dto-enums/sampling-size-type.dto-enum';
import { QimaOptionalType } from '@qima/ngx-qima';

// Sampling size can be based on the PO ordered quantity, or the produced quantity (not "packed", but keeping name for breaking change...))
export enum ESamplingSizeLotSizeSource {
  PACKED_QUANTITY = 'PACKED_QUANTITY',
  ORDERED_QUANTITY = 'ORDERED_QUANTITY',
}

export interface IInspectionSamplingSizesDTO {
  type: SamplingSizeType;
  products: ISamplingSizeProduct[];
  aqlModificationReason?: QimaOptionalType<string>;
  // Fields required for AQL types (standard & custom):
  criticalSampleSize?: QimaOptionalType<number>;
  majorSampleSize?: QimaOptionalType<number>;
  minorSampleSize?: QimaOptionalType<number>;
  inspectionLevel?: QimaOptionalType<EInspectionLevel>;
  // Fields required for fixed quantity:
  lotSize?: QimaOptionalType<number>;
  sampleSize?: QimaOptionalType<number>;
  // Fields for produce
  sampleUnit?: QimaOptionalType<string>;
}

export interface ISamplingSizeProduct {
  productId?: QimaOptionalType<number>;
  productQuantity?: QimaOptionalType<number>; // it's the ordered quantity
  imageUrl?: QimaOptionalType<string>;
  identifierValue?: QimaOptionalType<string>;
  purchaseOrderId?: QimaOptionalType<number>;
  purchaseOrderProductId?: QimaOptionalType<number>;
  purchaseOrderReference?: QimaOptionalType<string>;
  lotSize?: QimaOptionalType<number>; // It's actually a quantity, not a lot size (a lot size is a range)
  unit?: QimaOptionalType<string>;
}

// Contains the sample size and acceptance values matching a lot size, an AQL and inspection level, once computed.
export interface ISamplingPlan {
  sampleSize: QimaOptionalType<EAqlSampleSize>;
  acceptPoint: EAqlPoint;
  rejectPoint: EAqlPoint;
}

// AQL set when selecting "standard". Industry de facto standard
export const STANDARD_ACCEPTANCE_QUALITY_LEVELS = {
  critical: AqlValue.NOT_ALLOWED,
  major: AqlValue.AQL_2_5,
  minor: AqlValue.AQL_4_0,
};

export const DEFAULT_INSPECTION_LEVEL = EInspectionLevel.II;
