<div class="main-container">
  <ng-container *qpLargeDevice>
    <one-desktop-main-navbar
      *ngIf="isAuthenticated && !isMenuHidden"
      class="navbar-container"
      [ngClass]="currentMenuPosition === menuPositions.COLLAPSE ? 'navbar-container-collapsed' : 'navbar-container-expand'"
    ></one-desktop-main-navbar>
  </ng-container>
  <div class="main-content">
    <one-subnav *ngIf="isAuthenticated && routerUrl !== '/' && !isMenuHidden"></one-subnav>
    <div
      [class.qp-container-fluid]="hasContainer && isAuthenticated && !isMenuHidden && hasFluidContainer"
      [class.container-solid]="hasContainer && isAuthenticated && !isMenuHidden && !hasFluidContainer"
      [class.no-container]="!hasContainer"
    >
      <router-outlet></router-outlet>
    </div>
  </div>
  <ng-container *qpSmallDevice>
    <div *ngIf="isAuthenticated && !isMenuHidden" class="main-mobile-menu">
      <one-mobile-main-navbar></one-mobile-main-navbar>
    </div>
  </ng-container>
  <router-outlet name="popup"></router-outlet>
</div>
