import { IBrdInspectionEdition } from '@one/app/pages/brd/pages/inspection/shared/models/brd-inspection.models';
import { IESInspectionConsultation } from '@one/app/pages/brd/shared/models/es-inspection-consultation.model';
import { InspectionSummary } from '@one/app/pages/isp/pages/home/isp-home.models';
import { InspectionId } from '@one/app/pages/isp/shared/models/isp-inspection.models';
import { IInspectionConsultation } from '@one/app/shared/models/inspection-consultation/inspection-consultation.models';

type InspectionWithIdOrUuid = IInspectionConsultation | InspectionSummary | IBrdInspectionEdition | IESInspectionConsultation;

/**
 * @param {IInspectionConsultation} inspection the inspection to get the id or uuid from
 * @returns {InspectionId} the inspection id or uuid depending on the inspection create offline flag
 */
export function getInspectionIdOrUuid(inspection: InspectionWithIdOrUuid): InspectionId {
  if (!inspection.inspectionUuid) {
    return inspection.id;
  }

  return !!inspection.createdOffline ? inspection.inspectionUuid : inspection.id;
}
