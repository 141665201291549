import { GoogleTagManagerService } from '@one/app/shared/services/third-party/analytics-manager/google-tag-manager/services/google-tag-manager.service';

/**
 * @description
 * Factory that will initialize the Google Tag Manager service
 * @param {GoogleTagManagerService} googleTagManagerService The Google Tag Manager service to initialize
 * @returns {() => void} Function that initialize the Google Tag Manager service
 */
export function googleTagManagerInitFactory(googleTagManagerService: GoogleTagManagerService): () => void {
  return (): void => {
    googleTagManagerService.init();
  };
}
