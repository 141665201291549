import { CustomFieldValueDTO } from '@library/dto/workflow/custom-fields.dto';
import { SERVER_API_URL } from '@one/app/app.constants';
import { InspectionId } from '@one/app/pages/isp/shared/models/isp-inspection.models';
import { Answer } from '@one/app/shared/models/answer/answer.models';
import { EHttpQimaEndpoints, HTTP_QIMA_ENDPOINT_NAME } from '@one/app/shared/models/http/http.models';
import { IChecklistComment } from '@one/app/shared/models/inspection-consultation/inspection-consultation.models';
import { IWorkflowCheck } from '@one/app/shared/models/workflow/workflow.models';
import { ISaveProductPictureAction, ISaveTestChecklistAnswerData } from '@one/app/shared/services/database/database-inspection.models';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IspInspectionIdWorkflowService {
  public constructor(private readonly _httpClient: HttpClient) {}

  public saveLocation$(inspectionId: InspectionId, entityId: number): Observable<HttpResponse<void>> {
    const url = `${SERVER_API_URL}api/inspections/${inspectionId}/workflow/site`;

    return this._httpClient.put<HttpResponse<void>>(url, { entityId });
  }

  public saveAnswer$(inspectionId: InspectionId, answer: Answer, answerId: string, answerType: string): Observable<HttpResponse<void>> {
    const url = `${SERVER_API_URL}api/inspections/${inspectionId}/workflow`;
    const body: ISaveTestChecklistAnswerData = {
      answer,
      answerId,
      type: answerType,
    };

    return this._httpClient.patch<HttpResponse<void>>(url, body);
  }

  public saveNullableTestAnswer$(
    inspectionId: InspectionId,
    answer: Answer | null,
    answerId: string,
    answerType: string
  ): Observable<HttpResponse<void>> {
    const url = `${SERVER_API_URL}api/inspections/${inspectionId}/workflow`;
    const body = {
      answer,
      answerId,
      type: answerType,
    };

    return this._httpClient.patch<HttpResponse<void>>(url, body);
  }

  public updateAnswerCommentToApi$(
    inspectionId: InspectionId,
    answerId: string,
    comment: IChecklistComment
  ): Observable<HttpResponse<IChecklistComment>> {
    const url = `${SERVER_API_URL}api/inspections/${inspectionId}/answers/${answerId}/comment`;

    return this._httpClient.patch<IChecklistComment>(url, comment, { observe: 'response' });
  }

  public markAsNotApplicable$(inspectionId: InspectionId, answerId: string, notApplicable: boolean): Observable<void> {
    const url = `${SERVER_API_URL}api/inspections/${inspectionId}/answers/${answerId}/status`;

    return this._httpClient.put<void>(url, { notApplicable });
  }

  public saveCheckToAPI$(inspectionId: InspectionId, actionId: string, check: IWorkflowCheck): Observable<HttpResponse<void>> {
    const url = `${SERVER_API_URL}api/inspections/${inspectionId}/workflow/checks/${check.type}`;

    return this._httpClient.put<HttpResponse<void>>(url, {
      ...check,
      actionId,
    });
  }

  public saveProductPictureToApi$(
    inspectionId: InspectionId,
    actionId: string,
    action: Omit<ISaveProductPictureAction, 'actionId'>
  ): Observable<HttpResponse<void>> {
    const url = `${SERVER_API_URL}api/inspections/${inspectionId}/product-picture`;

    return this._httpClient.put<HttpResponse<void>>(url, {
      actionId,
      ...action,
    });
  }

  public deleteTableRow$(inspectionId: InspectionId, id: string, answerId: string, noOfColumns: number): Observable<HttpResponse<void>> {
    const url = `${SERVER_API_URL}api/inspections/${inspectionId}/workflow/table-response/${id}/answer/${answerId}?no-of-columns=${noOfColumns}`;
    const headers = new HttpHeaders().append(HTTP_QIMA_ENDPOINT_NAME, EHttpQimaEndpoints.DELETE_TEST_CHECKLIST_TABLE_ROW);

    return this._httpClient.delete<HttpResponse<void>>(url, { headers });
  }

  public saveCustomFieldValueToApi$(
    inspectionId: InspectionId,
    fieldId: number,
    answer: CustomFieldValueDTO
  ): Observable<HttpResponse<void>> {
    const url = `${SERVER_API_URL}api/inspections/${inspectionId}/custom-fields/${fieldId}`;

    return this._httpClient.patch<HttpResponse<void>>(url, answer);
  }
}
