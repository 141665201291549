export enum EQpImageElementType {
  ANSWERS = 'answers', // = photo in a test checklist
  DEFECTS = 'defects',
  SCANS = 'scans', // = scan in a test checklist
  PRODUCT_PICTURE = 'product-picture', // cover photo
  // no element type = global photo (toolbox library)
  SIMPLIFIED_MEASUREMENTS = 'simplified_measurement',
}

export enum EQpImageThumbnailSize {
  S128 = 'S128',
  S256 = 'S256',
  S680 = 'S680',
}
