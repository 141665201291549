import { TrackingService } from '@one/app/shared/services/third-party/analytics-manager/tracking/tracking.service';

/**
 * Factory to initialize tracking service
 *
 * @param trackingService
 * @returns
 */
export function trackingInitFactory(trackingService: TrackingService): () => void {
  return (): void => {
    trackingService.init();
  };
}
