import { DefectsChecklistPutDTO, DefectsChecklistGetDTO } from '@library/dto/checklist/defect/defects-checklist.dto';
import { ChecklistElasticDTO } from '@library/dto/checklist/elastic/checklist-elastic.dto';
import { QpDateService } from '@library/services/qp-date/qp-date.service';
import { QpFileUploadService } from '@library/services/qp-file-upload/qp-file-upload.service';
import { IQpFileUploadArrayBuffer, IQpFileUploadMetadata } from '@library/services/qp-file-upload/qp-file-upload.service.models';
import { SERVER_API_URL } from '@one/app/app.constants';
import { IDefectsChecklistCreationDTO } from '@one/app/pages/brd/pages/workflow/pages/defect-checklist/shared/models/defects.models';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface IDefectArrayBuffer extends IQpFileUploadArrayBuffer {
  id: string;
}

@Injectable({
  providedIn: 'root',
})
export class DefectsChecklistService {
  private static _toArrayBuffer(response: Readonly<IQpFileUploadArrayBuffer>, documentId: Readonly<string>): IDefectArrayBuffer {
    return {
      ...response,
      id: documentId,
    };
  }

  private readonly resourceUrl = `${SERVER_API_URL}api/defects-checklists`;

  public constructor(
    private readonly _httpClient: HttpClient,
    private readonly _qpDateService: QpDateService,
    private readonly _qpFileUploadService: QpFileUploadService
  ) {}

  public createDefectsChecklist$(defectsChecklist: IDefectsChecklistCreationDTO): Observable<HttpResponse<void>> {
    return this._httpClient.post<void>(this.resourceUrl, defectsChecklist, { observe: 'response' });
  }

  public getDefectChecklist$(id: string): Observable<DefectsChecklistGetDTO> {
    return this._httpClient.get<DefectsChecklistGetDTO>(`${this.resourceUrl}/${id}`);
  }

  public updateDefectChecklist$(checklist: DefectsChecklistPutDTO): Observable<HttpResponse<void>> {
    return this._httpClient.put<void>(`${this.resourceUrl}/${checklist.id}`, checklist, { observe: 'response' });
  }

  public duplicate$(id: number): Observable<HttpResponse<void>> {
    return this._httpClient.post<void>(
      `${this.resourceUrl}/${id}/duplicate`,
      {
        timezone: this._qpDateService.getTimezone(),
      },
      {
        observe: 'response',
      }
    );
  }

  public deleteDefect$(id: Readonly<number>): Observable<void> {
    return this._httpClient.request<void>('delete', `${SERVER_API_URL}/api/defects-checklists/${id}`, {
      body: {
        deletionTimezone: this._qpDateService.getTimezone(),
        deletionDate: this._qpDateService.getDate(),
      },
    });
  }

  public uploadDocument$(checklistId: Readonly<number>, file: Readonly<NzUploadFile>): Observable<HttpResponse<Record<string, string>>> {
    const path = `${this.resourceUrl}/${checklistId}/upload`;

    return this._qpFileUploadService.upload$(path, file);
  }

  public getDocumentMetadata$(checklistId: Readonly<number>, documentId: Readonly<string>): Observable<IQpFileUploadMetadata> {
    const path = `${this.resourceUrl}/${checklistId}/documents/${documentId}/metadata`;

    return this._qpFileUploadService.loadMetadata$(path);
  }

  public getPublishedDefectsChecklists$(): Observable<ChecklistElasticDTO[]> {
    return this._httpClient.get<ChecklistElasticDTO[]>(
      `${SERVER_API_URL}api/checklists?filter=publicationStatus,EQUALS,PUBLISHED&filter=type,EQUALS,DEFECTS&size=500&sort=lastModifiedDate,DESC`
    );
  }

  public getPublishedDefectsChecklistsByIds$(ids: number[]): Observable<ChecklistElasticDTO[]> {
    return this._httpClient.get<ChecklistElasticDTO[]>(
      `${SERVER_API_URL}api/checklists?filter=publicationStatus,EQUALS,PUBLISHED&filter=type,EQUALS,DEFECTS&sort=lastModifiedDate,DESC&filter=id,IN,[${ids.join(
        ','
      )}]`
    );
  }

  public getDocumentUrl(checklistId: Readonly<number>, documentId: Readonly<string>): string {
    return `${this.resourceUrl}/${checklistId}/documents/${documentId}`;
  }

  public getDocument$(checklistId: Readonly<number>, documentId: Readonly<string>, isThumbnail = true): Observable<IDefectArrayBuffer> {
    const path = this.getDocumentUrl(checklistId, documentId);

    return this._qpFileUploadService
      .loadArrayBuffer$(path, {
        params: {
          isThumbnail,
        },
      })
      .pipe(
        map(
          (response: Readonly<IQpFileUploadArrayBuffer>): IDefectArrayBuffer => DefectsChecklistService._toArrayBuffer(response, documentId)
        )
      );
  }
}
