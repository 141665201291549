import { EQpProfile } from '@library/models/qp-profile.models';
import { QpDisplayedRoute, IQpDisplayedRoute } from '@library/models/qp-router.models';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DynamicSubnavRoutingStore {
  public static readonly defaultDummyRoute = QpDisplayedRoute.create({
    title: '',
    routerLink: '',
    routerLinkActiveOption: { exact: false },
    profiles: [EQpProfile.ROLE_DENY_ALL],
  });

  private readonly _routes: BehaviorSubject<IQpDisplayedRoute> = new BehaviorSubject<IQpDisplayedRoute>(
    DynamicSubnavRoutingStore.defaultDummyRoute
  );

  public broadcastRoutes(routes: IQpDisplayedRoute): void {
    this._routes.next(routes);
    this._routes.next(DynamicSubnavRoutingStore.defaultDummyRoute);
  }

  public get routes$(): Observable<IQpDisplayedRoute> {
    return this._routes.asObservable();
  }

  public get routes(): IQpDisplayedRoute {
    return this._routes.getValue();
  }

  public isCustomRoute(routes: IQpDisplayedRoute): boolean {
    return routes !== DynamicSubnavRoutingStore.defaultDummyRoute;
  }
}
