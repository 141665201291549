export enum EQpBadgeType {
  DEFAULT = 'default',
  CIRCLE = 'circle',
}

export enum EQpBadgeColor {
  PRIMARY = 'primary',
  MIDNIGHT = 'midnight',
  SUCCESS = 'success',
  BREEZE = 'breeze',
  WARNING = 'warning',
  DANGER = 'danger',
  SMOKE = 'smoke',
  ALIZARIN_CRIMSON = 'alizarin-crimson',
  ORANGE = 'orange',
  MANGO = 'mango',
  LEMON = 'lemon',
  SEAL = 'seal',
}

export enum EQpBadgeBorder {
  SMALL = 'small',
  MEDIUM = 'medium',
}

export enum EQpBadgeSize {
  EXTRA_SMALL = 'extra-small',
  MEDIUM = 'medium',
  LARGE = 'large',
}
