// @ts-strict-ignore
import { QpScript } from '@library/classes/qp-script/qp-script';
import { QpQueryParamsService } from '@library/services/qp-query-params/qp-query-params.service';
import {
  AnalyticsManagerDataType,
  AnalyticsManagerEventType,
} from '@one/app/shared/services/third-party/analytics-manager/models/analytics-manager.models';
import { GOOGLE_TAG_MANAGER_AUTH } from '@one/app/shared/tokens/google-tag-manager-auth.token';
import { GOOGLE_TAG_MANAGER_ID } from '@one/app/shared/tokens/google-tag-manager-id.token';
import { GOOGLE_TAG_MANAGER_PREVIEW } from '@one/app/shared/tokens/google-tag-manager-preview.token';
import { WINDOW } from '@one/app/shared/tokens/window.token';
import { DOCUMENT } from '@angular/common';
import { Injectable, Inject, Renderer2, RendererFactory2 } from '@angular/core';
import { QimaOptionalType } from '@qima/ngx-qima';

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerService {
  /**
   * @internal
   * @type {QimaOptionalType<Renderer2>}
   * @default undefined
   */
  public renderer: QimaOptionalType<Renderer2> = undefined;
  private readonly _baseGtmUrl = 'https://www.googletagmanager.com/gtm.js';
  private readonly _baseGtmUrlNoScript = 'https://www.googletagmanager.com/ns.html';
  private _isLoaded = false;

  public constructor(
    @Inject(DOCUMENT) private readonly _document: Document,
    @Inject(WINDOW) private readonly _window: Window,
    @Inject(GOOGLE_TAG_MANAGER_ID) private readonly _googleTagManagerId: string,
    @Inject(GOOGLE_TAG_MANAGER_AUTH) private readonly _googleTagManagerAuth: string,
    @Inject(GOOGLE_TAG_MANAGER_PREVIEW) private readonly _googleTagManagerPreview: string,
    private readonly _qpQueryParamsService: QpQueryParamsService,
    private readonly _rendererFactory: RendererFactory2
  ) {
    this.renderer = this._rendererFactory.createRenderer(document.querySelector('html'), null);
  }

  public init(): void {
    this._pushToDataLayer({
      'gtm.start': new Date().getTime(),
      'event': 'gtm.js',
    });

    this._addGtmToDom();
  }

  public sendEvent(data: Readonly<AnalyticsManagerEventType>): void {
    if (!this._isLoaded) {
      this._addGtmToDom();
    }

    this._pushToDataLayer(data);
  }

  private _addGtmToDom(): void {
    if (this._isLoaded || !this._googleTagManagerId) {
      return;
    }

    // Prepend the GTM <script> injection to the <head> of the page
    new QpScript(this._rendererFactory, this._document)
      .create(
        this._qpQueryParamsService.addQueryParams(this._baseGtmUrl, {
          id: this._googleTagManagerId,
          gtm_auth: this._googleTagManagerAuth,
          gtm_preview: this._googleTagManagerPreview,
        })
      )
      .addAttribute('id', 'GTMscript')
      .addAttribute('async', true)
      .load();

    // Prepend the GTM <iframe> to the <body> of the page to cover browsers without JS support
    const gtmIframe = this.renderer.createElement('iframe');

    gtmIframe.setAttribute(
      'src',
      this._qpQueryParamsService.addQueryParams(this._baseGtmUrlNoScript, {
        id: this._googleTagManagerId,
        gtm_auth: this._googleTagManagerAuth,
        gtm_preview: this._googleTagManagerPreview,
      })
    );

    gtmIframe.style.width = '0';
    gtmIframe.style.height = '0';
    gtmIframe.style.display = 'none';
    gtmIframe.style.visibility = 'hidden';
    const noscript = this.renderer.createElement('noscript');

    noscript.id = 'GTMiframe';
    noscript.appendChild(gtmIframe);
    this.renderer.insertBefore(this._document.body, noscript, this._document.body.firstChild);
    this._isLoaded = true;
  }

  private _pushToDataLayer(data: Readonly<AnalyticsManagerDataType>): void {
    this._getDataLayer().push(data);
  }

  private _getDataLayer(): AnalyticsManagerDataType[] {
    this._window['dataLayer'] = this._window['dataLayer'] || [];

    return this._window['dataLayer'];
  }
}
