import { QpIdType } from '@library/models/qp-alias.models';

/**
 * @description This DTO reflects `com.qima.platform.domain.Profile️`
 *
 * ⚠️: This DTO come from the `domain` package
 */
export interface ProfileDTO {
  id: QpIdType;
  name: ProfileDTOValue;
}

/**
 * 💡: This enum is come from the original QIMAone codebase
 */
export enum ProfileDTOValue {
  ROLE_BRAND = 'ROLE_BRAND',
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_SUPERVISOR = 'ROLE_SUPERVISOR',
  ROLE_INSPECTOR = 'ROLE_INSPECTOR',
  ROLE_SERVICE_PROVIDER_INSPECTOR = 'ROLE_SERVICE_PROVIDER_INSPECTOR',

  ROLE_DENY_ALL = 'ROLE_DENY_ALL',
  /**
   * @description
   * We have to keep the value ROLE_FACTORY since backend is using this for entity account #6466
   */
  ROLE_ENTITY = 'ROLE_FACTORY',
  ROLE_SERVICE_PROVIDER = `ROLE_SERVICE_PROVIDER`,
  ROLE_AUDITOR = 'ROLE_AUDITOR',
}
