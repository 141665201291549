import { IQimaConnectConfig } from '@one/app/shared/models/connect/connect.models';
import { isBoolean, isString } from 'lodash/index';

/**
 * @description
 * Used to define the QIMA connect url
 * Based on the environments variable injection in the index file
 * @param {Window} window The window object containing possibly the "qimaConnectUrl" variable
 * @returns {string} Return the url if it exists or null otherwise
 */
export function getQimaConnectConfigFactory(window: Readonly<Window>): IQimaConnectConfig {
  if (isString(window['qimaConnectUrl']) && isBoolean(window['qimaConnectEnabled'])) {
    return {
      url: window['qimaConnectUrl'],
      enabled: window['qimaConnectEnabled'],
      cnUrl: isString(window['qimaConnectUrlCN']) ? window['qimaConnectUrlCN'] : null,
    };
  }

  return { url: null, enabled: false, cnUrl: null };
}
