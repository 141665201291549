import { QP_FILTERS_AND_SORTS_QUERY_PARAMS } from '@library/constants/filters-and-sorts-query-params/qp-filters-and-sorts-query-params';
import { qpAssert } from '@library/functions/checks/qp-assert';
import { IQpAuthenticationCredentials } from '@library/models/auth/qp-auth.models';
import { IAccount } from '@one/app/shared/models/account/account.models';
import { AccountService } from '@one/app/shared/services/account/account.service';
import { AuthenticationService } from '@one/app/shared/services/auth/authentication.service';
import { TrackingService } from '@one/app/shared/services/third-party/analytics-manager/tracking/tracking.service';
import { NotifuseService } from '@one/app/shared/services/third-party/notifuse/notifuse.service';
import { WINDOW } from '@one/app/shared/tokens/window.token';
import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { Observable, throwError, firstValueFrom } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  public constructor(
    private readonly _accountService: AccountService,
    private readonly _authServerProvider: AuthenticationService,
    private readonly _notifuseService: NotifuseService,
    private readonly _localStorageService: LocalStorageService,
    @Inject(WINDOW) private readonly _window: Window,
    private readonly _trackingService: TrackingService
  ) {}

  /* This method is called with standalone login, unused with IAMS */
  public login(credentials: IQpAuthenticationCredentials): Promise<IAccount> {
    this._localStorageService.clear(QP_FILTERS_AND_SORTS_QUERY_PARAMS);

    return firstValueFrom(
      this._authServerProvider.login$(credentials).pipe(
        mergeMap(async (): Promise<IAccount> => {
          const identity = await this._accountService.forceIdentity();

          qpAssert(!!identity, 'Identity should be defined');

          return identity;
        }),
        catchError((error: HttpErrorResponse): Observable<never> => {
          void this.logout();

          return throwError((): Error => error);
        })
      )
    );
  }

  public async logout(): Promise<void> {
    this._notifuseService.destroy();
    this._window['Appcues']?.reset();
    this._localStorageService.clear(QP_FILTERS_AND_SORTS_QUERY_PARAMS);
    this._accountService.unsetAccount();
    this._trackingService.reset();
    await this._authServerProvider.logout();
  }
}
