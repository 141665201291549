import { QpLoggerService } from '@library/services/qp-logger/qp-logger.service';
import { ErrorHandler, Injectable } from '@angular/core';

/**
 * @description
 * Define the logic when an error occur anywhere in the app
 * Also execute the default behaviour of the error handler provided by Angular
 * So that we have a log with a better context
 * Not provided in root on purpose
 */
@Injectable()
export class GlobalErrorHandler extends ErrorHandler implements ErrorHandler {
  public constructor(private readonly _qpLoggerService: QpLoggerService) {
    super();
  }

  public handleError(error: unknown): void {
    // Better context error log made by Angular
    super.handleError(error);

    // Custom logger behaviour from this point
    this._qpLoggerService.error(error);
  }
}
