import { SubnavBreadcrumbsComponent } from '@one/app/components/subnav-breadcrumbs/subnav-breadcrumbs.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  exports: [SubnavBreadcrumbsComponent],
  imports: [CommonModule, TranslateModule.forChild(), RouterModule, SubnavBreadcrumbsComponent],
})
export class SubnavBreadcrumbsModule {}
