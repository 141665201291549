import { IQpAuthenticationCredentials } from '@library/models/auth/qp-auth.models';
import { QpLoggerService } from '@library/services/qp-logger/qp-logger.service';
import { buildOAuthConfig } from '@one/app/config/iams-auth-config';
import { AuthenticationService } from '@one/app/shared/services/auth/authentication.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QimaOptionalType } from '@qima/ngx-qima';
import { OAuthService } from 'angular-oauth2-oidc';
import { CookieService } from 'ngx-cookie';
import { BehaviorSubject, from, Observable, of, ReplaySubject } from 'rxjs';
import { filter, finalize, first, map, tap } from 'rxjs/operators';

@Injectable()
export class IamsAuthenticationService extends AuthenticationService {
  private _identityClaims: ReplaySubject<unknown> = new ReplaySubject(1);
  private _isConfigurationLoaded: boolean = false;
  private readonly _brandIdCookieName = 'qo-brand-id';
  private readonly _entityIdCookieName = 'qo-entity-id';
  private readonly _inspectorIdCookieName = 'qo-inspector-id';
  private readonly _accessTokenCookieName = 'authenticationToken';

  private _isTokenRefreshInProgress: boolean = false;
  private readonly _refreshAccessTokenSubject: BehaviorSubject<QimaOptionalType<string>> = new BehaviorSubject<QimaOptionalType<string>>(
    null
  );

  public constructor(
    private readonly _oauthService: OAuthService,
    private readonly _cookieStorage: CookieService,
    private readonly _qpLoggerService: QpLoggerService
  ) {
    super();
    this._oauthService.configure(buildOAuthConfig());
  }

  public getToken$(): Observable<QimaOptionalType<string>> {
    if (!this._oauthService.getAccessToken()) {
      return of(null);
    }

    if (this._oauthService.hasValidAccessToken()) {
      return of(this._oauthService.getAccessToken());
    }

    return this._refreshToken$();
  }

  public login$(_credentials: IQpAuthenticationCredentials): Observable<string | never> {
    return of();
  }

  public loginAsBrand$(brandId: Readonly<number>): Observable<string> {
    if (!brandId) {
      return of('');
    }

    this._storeCookie(this._brandIdCookieName, brandId.toString());

    return of('success');
  }

  public loginAsEntity$(entityId: Readonly<number>): Observable<string> {
    if (!entityId) {
      return of('');
    }

    this._storeCookie(this._entityIdCookieName, entityId.toString());

    return of('success');
  }

  public loginAsInspector$(inspectorId: Readonly<number>): Observable<string> {
    this._storeCookie(this._inspectorIdCookieName, inspectorId.toString());

    return of('success');
  }

  public storeAuthenticationToken(_jwt, _rememberMe): void {
    // No-op
  }

  public removeAuthenticationToken(): void {
    // No-op
  }

  public storeQimaConnectTokens(): void {
    // No-op
  }

  public getQimaConnectToken$(): Observable<string> {
    return of('');
  }

  public hasRootSessionToken(): boolean {
    return false;
  }

  public logBackWithRootSessionToken(): void {
    // No-op
  }

  public hasQimaConnectSession(): boolean {
    return false;
  }

  public async isAuthenticated(): Promise<boolean> {
    const claims = await this._identityClaims.pipe(first()).toPromise();

    if (!claims) {
      this.redirectToLogin();
    }

    return !!claims;
  }

  public async logout(): Promise<void> {
    this._deleteBrandIdCookie();
    this._identityClaims = new ReplaySubject(1);
    await this._oauthService.revokeTokenAndLogout();
  }

  public redirectToLogin(): void {
    void this._loadConfiguration().then((): void => this._oauthService.initLoginFlow());
  }

  public handleUnauthorized(_error: HttpErrorResponse): void {
    this._clearStorage();
  }

  public async setupAuthentication(): Promise<void> {
    await this._loadConfiguration();
    this._deleteAccessTokenCookie();

    // try to restore auth from storage
    const isAuthRestored: boolean = await this._tryAuthRestore();

    if (!isAuthRestored) {
      this._qpLoggerService.info('Could not restore auth, trying login');
      // Will check the current URl to see if we can process an oAuth2 callback
      await this._oauthService.tryLogin();

      if (!this._oauthService.hasValidAccessToken()) {
        this._qpLoggerService.info('No access token after try login, cleaning up storage');
        this._clearStorage();
      } else {
        this._qpLoggerService.info('Try login is a success');
      }
    }

    if (this._oauthService.hasValidAccessToken()) {
      this._storeAccessTokenCookie();
    }

    this._oauthService.events
      .pipe(filter((e): boolean => e.type === 'token_received'))
      .subscribe((_): void => this._storeAccessTokenCookie());

    const identityClaims = this._oauthService.getIdentityClaims();

    this._identityClaims.next(identityClaims);
  }

  private _clearStorage(): void {
    this._deleteBrandIdCookie();
    this._deleteAccessTokenCookie();
    // Forget all stored data, without redirecting the user
    this._oauthService.logOut(false);
  }

  private async _tryAuthRestore(): Promise<boolean> {
    let isAuthRestored = false;

    // We have valid ID token and a refresh token, let's try to see if we can restore auth.
    if (this._oauthService.hasValidIdToken() && this._oauthService.getRefreshToken()) {
      if (this._oauthService.hasValidAccessToken()) {
        isAuthRestored = true;
        this._qpLoggerService.info('Restored auth from storage');
      } else {
        await this._oauthService.refreshToken();
        isAuthRestored = this._oauthService.hasValidAccessToken();
        this._qpLoggerService.info(isAuthRestored ? 'Refreshed token and restored auth' : 'Refresh token failed');
      }
    }

    return isAuthRestored;
  }

  private async _loadConfiguration(): Promise<void> {
    if (this._isConfigurationLoaded) {
      return Promise.resolve();
    }

    return this._oauthService.loadDiscoveryDocument().then((): void => {
      this._isConfigurationLoaded = true;
    });
  }

  private _deleteBrandIdCookie(): void {
    this._deleteCookie(this._brandIdCookieName);
  }

  private _deleteAccessTokenCookie(): void {
    this._deleteCookie(this._accessTokenCookieName);
  }

  private _deleteCookie(cookieName: string): void {
    this._cookieStorage.remove(cookieName, { sameSite: 'strict' });
  }

  private _storeAccessTokenCookie(): void {
    this._storeCookie(this._accessTokenCookieName, this._oauthService.getAccessToken());
  }

  private _storeCookie(cookieName: string, value: string): void {
    this._cookieStorage.put(cookieName, value, { sameSite: 'strict' });
  }

  private _refreshToken$(): Observable<QimaOptionalType<string>> {
    if (this._isTokenRefreshInProgress) {
      // wait for token
      return this._refreshAccessTokenSubject.pipe(
        filter((result): boolean => result !== null),
        first()
      );
    }

    this._isTokenRefreshInProgress = true;

    return from(this._oauthService.refreshToken()).pipe(
      map((response): string => response.access_token),
      tap((token): void => {
        this._refreshAccessTokenSubject.next(token);
      }),
      finalize((): boolean => (this._isTokenRefreshInProgress = false))
    );
  }
}
