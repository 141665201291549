import { MeasuresChecklistAction } from '@one/app/pages/isp/pages/inspection/pages/id/shared/classes/measurement-checklist-action/measures-checklist-action';
import { IMeasurementChecklistProduct, IProductVariances } from '@one/app/pages/isp/shared/models/variances/inspection-variances.models';

export class MeasuresChecklistActionSection extends MeasuresChecklistAction implements IProductVariances {
  public id: string;
  public product: IMeasurementChecklistProduct;

  public constructor(measureChecklist: IProductVariances) {
    super(measureChecklist);
    this.id = measureChecklist.id;
    this.product = measureChecklist.product;
  }
}
