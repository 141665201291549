import { ChecklistRatingResultDTO } from '@library/dto/checklist/checklist-rating-result.dto';
import { ChecklistRatingDTO } from '@library/dto/checklist/checklist-rating.dto';

/**
 * @description
 * compute the worst rating index from a rating system
 * @param {ChecklistRatingDTO[]} ratingSystem the rating system
 * @param {ChecklistRatingResultDTO[]} ratingResults the rating results to loop on
 * @returns {number} the worst rating index or -1 if no rating is found
 */
export function qpComputeWorstRatingIndex(ratingSystem: ChecklistRatingDTO[] = [], ratingResults: ChecklistRatingResultDTO[] = []): number {
  return Math.max(...ratingResults.map((r): number => ratingSystem.findIndex((rating): boolean => rating.name === r.name)));
}
