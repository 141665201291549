import { DefectAnsweredDTO } from '@library/dto/testschecklist/answer/defect-answered.dto';
import { TestsChecklistAnswerElementImageDTO } from '@library/dto/testschecklist/answer/tests-checklist-answer-element-image.dto';
import { WorkflowAnswerActionDefectDTO } from '@library/dto/testschecklist/answer/workflow/action/workflow-answer-action-defect.dto';
import { QimaOptionalType } from '@qima/ngx-qima';

export class DefectAnsweredUtils {
  public constructor(private readonly _defectAnsweredDTO: DefectAnsweredDTO) {}

  public computeTotalDefectsQuantity(purchaseOrderProductId?: QimaOptionalType<number>): number {
    return (
      this._defectAnsweredDTO.defectsFound
        ?.filter((defectFound): boolean => !purchaseOrderProductId || defectFound.purchaseOrderProductId === purchaseOrderProductId)
        .reduce((accumulator, defectFound): number => accumulator + (defectFound.quantity ?? 0), 0) ?? 0
    );
  }

  public isValid(action: WorkflowAnswerActionDefectDTO, purchaseOrderProductId?: QimaOptionalType<number>): boolean {
    const defectsFoundToCheck =
      this._defectAnsweredDTO.defectsFound?.filter(
        (defectFound): boolean => !purchaseOrderProductId || defectFound.purchaseOrderProductId === purchaseOrderProductId
      ) ?? [];
    const isPhotosValid: boolean =
      !action?.photosMandatoryWhenDefectsFound ||
      (defectsFoundToCheck.every((defectFound): boolean => !defectFound.quantity || !!defectFound.images?.length) ?? true);
    const isCommentValid: boolean =
      !action?.commentsMandatoryWhenDefectsFound ||
      (defectsFoundToCheck.every((defectFound): boolean => !defectFound.quantity || !!defectFound.comment?.message) ?? true);

    return isPhotosValid && isCommentValid;
  }

  public getAnsweredImages(purchaseOrderProductId?: QimaOptionalType<number>): TestsChecklistAnswerElementImageDTO[] {
    return (
      this._defectAnsweredDTO.defectsFound
        ?.filter((defectFound): boolean => !purchaseOrderProductId || defectFound.purchaseOrderProductId === purchaseOrderProductId)
        .flatMap((defectFound): TestsChecklistAnswerElementImageDTO[] => defectFound.images ?? []) ?? []
    );
  }
}
