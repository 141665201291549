import { QpLocaleService } from '@library/services/qp-locale/qp-locale.service';
import { localeInitFactory } from '@one/app/shared/factories/locale-init.factory';
import { FactoryProvider, APP_INITIALIZER } from '@angular/core';

export const LOCALE_INIT_PROVIDER: FactoryProvider = {
  deps: [QpLocaleService],
  provide: APP_INITIALIZER,
  multi: true,
  useFactory: localeInitFactory,
};
