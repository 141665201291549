import { mixpanelFactory } from '@one/app/shared/factories/mixpanel.factory';
import { MIXPANEL_ID } from '@one/app/shared/tokens/mixpanel.token';
import { WINDOW } from '@one/app/shared/tokens/window.token';
import { FactoryProvider } from '@angular/core';

export const MIXPANEL_ID_PROVIDER: FactoryProvider = {
  deps: [WINDOW],
  provide: MIXPANEL_ID,
  useFactory: mixpanelFactory,
};
