import { EQpColorValue } from '@one/app/shared/models/colors/colors.models';
import { NgIf, NgStyle, NgFor } from '@angular/common';
import { Component, DoCheck, Input, IterableDifferFactory, IterableDiffers } from '@angular/core';
import { isString } from 'lodash/index';

@Component({
  selector: 'qp-tooltip',
  templateUrl: './qp-tooltip.component.html',
  styleUrls: ['./qp-tooltip.component.scss'],
  standalone: true,
  imports: [NgIf, NgStyle, NgFor],
})
export class QpTooltipComponent implements DoCheck {
  public textArray: string[] = [];

  @Input() public text: string | string[] = '';
  @Input() public show = false;
  @Input() public backgroundColor: EQpColorValue = EQpColorValue.WHITE;
  @Input() public textColor: EQpColorValue = EQpColorValue.MIDNIGHT;

  public constructor(private readonly _iterableDiffers: IterableDiffers) {}

  public ngDoCheck(): void {
    if (isString(this.text)) {
      this.textArray = [this.text];
    } else {
      const iterableDifferFactory: IterableDifferFactory = this._iterableDiffers.find(this.text);

      if (iterableDifferFactory) {
        this.textArray = this.text;
      }
    }
  }
}
