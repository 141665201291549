import { qpIsFiniteNumber } from '@library/functions/checks/qp-is-finite-number';
import { EQpHttpStatusCode } from '@library/models/qp-http.models';
import { CUSTOM_HTTP_HEADER_IGNORED_INTERCEPTOR_STATUS_CODES } from '@one/app/shared/functions/get-custom-http-headers/get-custom-http-headers';
import { HttpRequest } from '@angular/common/http';

/**
 * @param request
 */
export function qpGetIgnoredInterceptorStatusCodes<TRequest = unknown>(request: HttpRequest<TRequest>): EQpHttpStatusCode[] {
  return (
    request.headers
      .get(CUSTOM_HTTP_HEADER_IGNORED_INTERCEPTOR_STATUS_CODES)
      ?.split(',')
      .map((statusCode: Readonly<string>): EQpHttpStatusCode => {
        return parseInt(statusCode);
      })
      .filter((statusCode: Readonly<EQpHttpStatusCode>): boolean => {
        return qpIsFiniteNumber(statusCode);
      }) ?? []
  );
}
