import { getIamConfig } from '@one/app/shared/factories/iam-config.factory';
import { IIamProviderConfig } from '@one/app/shared/services/auth/iam-config.models';
import { QimaOptionalType } from '@qima/ngx-qima';
import { AuthConfig } from 'angular-oauth2-oidc';
import { isNil } from 'lodash/index';

export function buildOAuthConfig(): AuthConfig {
  const iamConfig = getIamConfig(window);
  let provider: QimaOptionalType<IIamProviderConfig>;

  if (!isNil(iamConfig)) {
    const origin = window.location.origin;

    provider = iamConfig.providers.find((it): boolean => origin.endsWith(it.hostSuffix)) ?? iamConfig.providers?.[0];
  }

  return {
    issuer: provider?.url ?? '',
    redirectUri: `${origin}/`,
    clientId: provider?.clientId ?? '',
    responseType: 'code',
    scope: 'openid profile email offline_access',
    showDebugInformation: false,
    requireHttps: provider?.requireHttps ?? true,
    clockSkewInSec: 1,
  };
}
