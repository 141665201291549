/**
 * @deprecated use instead {@link EQimaColorNeutral } and other enums coming from ngx-qima
 * @example import { EQimaColorCherry, EQimaColorDecorative, EQimaColorKiwi, QimaOptionalType } from '@qima/ngx-qima';
 */
export enum EQpColorValue {
  /** @deprecated use instead {@link EQimaColorBrand.RED } */
  QIMA_RED = '#E4002B',
  /** @deprecated use instead {@link EQimaColorNeutral.NEUTRAL_CARBON } */
  MIDNIGHT = '#191919',
  /** @deprecated use instead {@link EQimaColorPool.POOL_500 } */
  POOL = '#0BA2C4',
  /** @deprecated use instead {@link EQimaColorDecorative.GUAVA } */
  SUNSET = '#FF6159',
  /** @deprecated use instead {@link EQimaColorNeutral.NEUTRAL_WHITE } */
  WHITE = '#FFFFFF',
  /** @deprecated use instead {@link EQimaColorCherry.CHERRY_600 } */
  DANGER = '#E5353E',
  /** @deprecated use instead {@link EQimaColorNeutral.NEUTRAL_SEAL } */
  SEAL = '#737373',
  /** @deprecated use instead {@link EQimaColorNeutral.NEUTRAL_SMOKE } */
  SMOKE = '#B3B3B3',
  /** @deprecated use instead {@link EQimaColorNeutral.NEUTRAL_PEARL } */
  PEARL = '#D6D6D6',
  /** @deprecated use instead {@link EQimaColorPool.POOL_200 } */
  BREEZE = '#DDF3F8',
  /** @deprecated use instead {@link EQimaColorNeutral.NEUTRAL_CLOUD } */
  MIST = '#f3f3f3',
  /** @deprecated use instead {@link EQimaColorPool.POOL_100 } */
  SKY = '#f1fbfc',
  /** @deprecated use instead {@link EQimaColorNeutral.NEUTRAL_CLOUD } */
  CLOUD = '#F7F7F7',
  /** @deprecated use instead {@link EQimaColorCherry.CHERRY_500 } */
  CHERRY = '#FF3B44',
  /** @deprecated use instead {@link EQimaColorCherry.CHERRY_100 } */
  CHERRY_LIGHT = '#FEECED',
  /** @deprecated use instead {@link EQimaColorOrange.ORANGE_500 } */
  ORANGE = '#FF9A0C',
  /** @deprecated use instead {@link EQimaColorOrange.ORANGE_100 } */
  ORANGE_LIGHT = '#FEF3E4',
  /** @deprecated use instead {@link EQimaColorLemon.LEMON_500 } */
  LEMON = '#FFD815',
  /** @deprecated use instead {@link EQimaColorKiwi.KIWI_500 } */
  KIWI = '#00C389',
  /** @deprecated use instead {@link EQimaColorKiwi.KIWI_600 } */
  KIWI_HOVER = '#00A877',
  /** @deprecated use instead {@link EQimaColorKiwi.KIWI_200 } */
  KIWI_DESACTIVATED = '#BFE8DC',
  /** @deprecated use instead {@link EQimaColorKiwi.KIWI_100 } */
  KIWI_LIGHT = '#E4F9F3',
  /** @deprecated use instead {@link EQimaColorDecorative.BLUEBERRY } */
  BLUEBERRY = '#170F78',
  /** @deprecated use instead {@link EQimaColorDecorative.OLIVE } */
  OLIVE = '#00AEB7',
  /** @deprecated use instead {@link EQimaColorDecorative.PEACH } */
  PEACH = '#FF8261',
  /** @deprecated use instead {@link EQimaColorDecorative.GRAPES } */
  GRAPES = '#A2005D',
  /** @deprecated use instead {@link EQimaColorDecorative.MANDARIN } */
  MANDARIN = '#FFBE0F',
  /** @deprecated use instead {@link EQimaColorDecorative.PLUM } */
  PLUM = '#170F78',
  /** @deprecated use instead {@link EQimaColorDecorative.FOREST } */
  FOREST = '#29A37E',
  /** @deprecated use instead {@link EQimaColorOrange.ORANGE_100 } */
  SERENADE = '#FEF3E4',
  /** @deprecated use instead {@link EQimaColorOrange.ORANGE_600 } */
  MANGO = '#E5800B',
  /** @deprecated use instead {@link EQimaColorKiwi.KIWI_600 } */
  GREEN_CYAN = '#00A877',
}
