// @ts-strict-ignore
import { QpTestsChecklistElementType } from '@library/models/qp-tests-checklist-element.models';
import { IChecklistContent } from '@one/app/shared/models/checklist/tests-checklist.models';
import { WorkflowPathIdType, WorkflowIdType } from '@one/app/shared/models/workflows/workflow.models';
import { QimaOptionalType } from '@qima/ngx-qima';
import { isEmpty } from 'lodash/index';

export class IspWorkflowStepActionTestsChecklistContent {
  private static _getSplitWorkflowPathId(id: Readonly<WorkflowPathIdType>): WorkflowIdType[] {
    return id.split('-');
  }

  public elements: QpTestsChecklistElementType[] = [];

  public constructor(content: QimaOptionalType<IChecklistContent>) {
    if (content) {
      this.elements = (content.elements as QpTestsChecklistElementType[]) ?? [];
    }
  }

  public getElementById(id: Readonly<WorkflowPathIdType>): QpTestsChecklistElementType | null | never {
    const splitWorkflowPathId: WorkflowIdType[] = IspWorkflowStepActionTestsChecklistContent._getSplitWorkflowPathId(id);

    if (splitWorkflowPathId.length < 3) {
      throw Error('The workflow path id should contains the step id, the action id and at least one element id');
    }

    const parentPath: WorkflowPathIdType = splitWorkflowPathId.slice(0, 2).join('-');

    return this._getElementById(parentPath, splitWorkflowPathId.slice(2), this.elements);
  }

  private _getElementById(
    parentPath: WorkflowPathIdType,
    ids: WorkflowIdType[],
    elements: QpTestsChecklistElementType[]
  ): QpTestsChecklistElementType | null {
    const currentPath = `${parentPath}-${ids[0]}`;
    const element: QpTestsChecklistElementType | undefined = elements.find(
      (element: Readonly<QpTestsChecklistElementType>): boolean => element.id === currentPath
    );

    if (ids.length === 1) {
      return element ?? null;
    }

    if (element && !isEmpty(element.elements)) {
      return this._getElementById(currentPath, ids.slice(1), element.elements);
    }

    return null;
  }
}
