// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { Router, RoutesRecognized, Event } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RouterHistoryService {
  private _previousUrl: string;
  public constructor(private readonly router: Router) {}

  public startListening(): void {
    this.router.events
      .pipe(
        filter((e): boolean => e instanceof RoutesRecognized),
        pairwise()
      )
      .subscribe((event: [Event, Event]): void => {
        this._previousUrl = event[0]['urlAfterRedirects'];
      });
  }

  public previousUrl(): string {
    return this._previousUrl;
  }
}
