import { EQpHttpStatusCode } from '@library/models/qp-http.models';
import { QpAlertErrorService } from '@library/services/qp-alert-error/qp-alert-error.service';
import { QpLoggerService } from '@library/services/qp-logger/qp-logger.service';
import { QpNotificationBarService } from '@library/services/qp-notification-bar/qp-notification-bar.service';
import { CommonRoutes } from '@one/app/common-routes';
import { HttpInterceptor, HttpRequest, HttpErrorResponse, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

const AUTHENTICATE_SERVICE_ERRORS = [EQpHttpStatusCode.GATEWAY_TIMEOUT, EQpHttpStatusCode.NOT_FOUND, EQpHttpStatusCode.SERVICE_UNAVAILABLE];

/**
 * @description
 * Not provided in root on purpose
 */
@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  public constructor(
    private readonly _qpAlertErrorService: QpAlertErrorService,
    private readonly _router: Router,
    private readonly _qpLoggerService: QpLoggerService,
    private readonly _qpNotificationBarService: QpNotificationBarService
  ) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap({
        error: (error: HttpErrorResponse | Error | unknown): void | never => {
          this._qpLoggerService.debug('Caught HTTP error');
          this._qpLoggerService.error(error);

          if (error instanceof HttpErrorResponse) {
            if (AUTHENTICATE_SERVICE_ERRORS.includes(error.status) && error.url?.includes('/api/authenticate')) {
              void this._router.navigate([CommonRoutes.errorUnknown()]);

              return;
            }

            if (!(error.status === EQpHttpStatusCode.UNAUTHORIZED && (error.message === '' || error.url?.includes('/api/account')))) {
              this._qpAlertErrorService.handleError(error);
            }

            if (error.status === EQpHttpStatusCode.FORBIDDEN && error.url?.includes('/api/users')) {
              this._qpNotificationBarService.error('one-brd-team-inspector-consult.error.assigned-inspection');

              return;
            }

            if (error.status === EQpHttpStatusCode.FORBIDDEN && request.method !== 'HEAD') {
              void this._router.navigate([CommonRoutes.forbidden()]);

              return;
            }

            // Rethrow since not handled
            throw error;
          } else if (error instanceof Error) {
            // Rethrow since not handled
            throw error;
          }

          // Rethrow since not handled
          throw new Error('Unknown error thrown');
        },
      })
    );
  }
}
