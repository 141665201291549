import { QimaOptionalType } from '@qima/ngx-qima';

export class ScriptLoaderService {
  protected scriptUrl: QimaOptionalType<string>;
  protected isLoaded: boolean = false;

  protected loadScript(url: string): Promise<void> {
    return new Promise((resolve, reject): void => {
      const script = document.createElement('script');

      script.type = 'text/javascript';
      script.src = url;
      script.onload = (): void => {
        this.isLoaded = true;
        resolve();
      };

      script.onerror = (error: Event | string): void => reject(new Error(`Script load error for ${url}: ${error?.toString()}`));
      document.body.appendChild(script);
    });
  }
}
