import { QpPhoneComponent } from '@library/components/qp-phone/qp-phone.component';
import { QpNotificationBarModule } from '@library/services/qp-notification-bar/qp-notification-bar.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';

@NgModule({
  exports: [QpPhoneComponent],
  imports: [CommonModule, NzInputModule, NzSelectModule, FormsModule, TranslateModule, QpNotificationBarModule, QpPhoneComponent],
})
export class QpPhoneModule {}
