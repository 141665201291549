import { QpDeviceDirective } from '@library/classes/qp-device/qp-device.directive';
import { QpResponsiveService } from '@library/services/qp-responsive/qp-responsive.service';
import { Directive, TemplateRef, ViewContainerRef, ChangeDetectorRef } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * @description
 * Display some content when the screen match a large device
 * Check if the viewport has a min width of 767px
 * Basically, anything larger than a phone or a small tablet
 *
 * @example
 * ```html
 * <ng-container *qpLargeDevice>
 *   I am visible only in a large device!
 * </ng-container>
 * ```
 * @deprecated
 */
@Directive({
  selector: '[qpLargeDevice]',
  standalone: true,
})
export class QpLargeDeviceDirective<TComponent> extends QpDeviceDirective<TComponent> {
  public constructor(
    _templateRef: TemplateRef<TComponent>,
    _viewContainerRef: ViewContainerRef,
    _changeDetectorRef: ChangeDetectorRef,
    private readonly _qpResponsiveService: QpResponsiveService
  ) {
    super(_templateRef, _viewContainerRef, _changeDetectorRef);
  }

  public onDeviceChange$(): Observable<boolean> {
    return this._qpResponsiveService.isLargeDevice$();
  }
}
