// @ts-strict-ignore
import { EQpHttpStatusCode } from '@library/models/qp-http.models';
import { IQpTestsChecklistElement } from '@library/models/qp-tests-checklist-element.models';
import { QpLoggerService } from '@library/services/qp-logger/qp-logger.service';
import { DEBOUNCE_TIME_IN_MS } from '@one/app/app.constants';
import { CommonRoutes } from '@one/app/common-routes';
import { IEntity, IEntityConsultation } from '@one/app/pages/brd/shared/models/entity.model';
import { IspRoutes } from '@one/app/pages/isp/isp-routes';
import { IDefectsChecklistDefect } from '@one/app/pages/isp/pages/inspection/pages/id/pages/defects-checklist/isp-inspection-id-defect.models';
import { IspInspectionIdWorkflowService } from '@one/app/pages/isp/pages/inspection/pages/id/shared/services/isp-inspection-id-workflow.service';
import { IUpdateFindingsSummaryReviewAction } from '@one/app/pages/isp/pages/inspection/pages/id/shared/services/store/isp-inspection-id-store.actions';
import {
  ADD_CUSTOM_MEASUREMENT_PRODUCT_SAMPLE_SUCCESS,
  ADD_SITE_LOCATION_SUCCESS,
  addCustomMeasurementProductSample,
  addSiteLocation,
  clearSiteLocation,
  COMMENT_TEST_SUCCESS,
  commentTest,
  COMPLETE_DEFECTS_CHECKLIST_SUCCESS,
  COMPLETE_REFERENCE_SAMPLE_SUCCESS,
  COMPLETE_SAMPLE_COLLECTION_SUCCESS,
  completeDefectsChecklist,
  completeReferenceSample,
  completeSampleCollection,
  CONFIRM_FINDINGS_SUMMARY_REVIEW_SUCCESS,
  confirmFindingsSummaryReview,
  CREATE_DEFECT_SUCCESS,
  createDefect,
  DELETE_INSPECTION_IMAGE_SUCCESS,
  DELETE_TEST_CHECKLIST_TABLE_ROW_SUCCESS,
  deleteInspectionImage,
  deleteTestChecklistTableRow,
  ICompleteDefectsChecklistProps,
  IDeleteWorkflowImageSuccess,
  ISaveRecipientsActionError,
  ISaveRecipientsActionSuccess,
  ISaveRecipientsActionType,
  IUpdateDefectCommentProps,
  IUpdateDefectProps,
  IUpdateProductActualQuantitiesProps,
  LOADED_SUCCESS_WORKFLOW,
  loadWorkflow,
  MARK_AS_NOT_APPLICABLE_SUCCESS,
  markAsNotApplicable,
  SAVE_CHECK_SUCCESS,
  SAVE_MEASUREMENT_SUCCESS,
  SAVE_PRODUCT_PICTURE_SUCCESS,
  SAVE_RECIPIENTS_ERROR,
  SAVE_RECIPIENTS_SUCCESS,
  SAVE_TEST_ANSWER_SUCCESS,
  saveCheck,
  saveMeasurement,
  saveNullableTestAnswer,
  saveProductPicture,
  saveRecipients,
  saveSimplifiedMeasures,
  saveSimplifiedMeasuresComment,
  saveSimplifiedMeasuresCommentSuccess,
  saveSimplifiedMeasuresError,
  saveSimplifiedMeasuresMarkedAsNotApplicable,
  saveSimplifiedMeasuresMarkedAsNotApplicableSuccess,
  saveSimplifiedMeasuresSuccess,
  saveTestAnswer,
  saveWorkflowActionCustomFieldsValue,
  saveWorkflowActionCustomFieldsValueSuccess,
  UPDATE_DEFECT_COMMENT_SUCCESS,
  UPDATE_DEFECT_SUCCESS,
  UPDATE_GLOBAL_REMARKS_SUCCESS,
  updateAvailableCartons,
  updateDefect,
  updateDefectComment,
  updateGlobalRemarks,
  updateIsInspectionMarkedAsPending,
  updateIsInspectionMarkedAsPendingSuccess,
  updateProductActualQuantities,
  updateProductActualQuantitiesError,
  updateProductActualQuantitiesSuccess,
} from '@one/app/pages/isp/pages/inspection/pages/id/shared/services/store/isp-inspection-id-workflow-store.actions';
import WorkflowUtils from '@one/app/pages/isp/pages/inspection/pages/id/shared/services/workflow.utils';
import { getInspectionIdOrUuid } from '@one/app/pages/isp/shared/functions/isp-get-inspection-id-or-uuid';
import { HTTP_SKIP_409_OPTIONS } from '@one/app/shared/constants/http-skip-409-options';
import { Answer } from '@one/app/shared/models/answer/answer.models';
import { IChecklistComment } from '@one/app/shared/models/inspection-consultation/inspection-consultation.models';
import { ERouteType } from '@one/app/shared/models/routes/route-type.models';
import { IWorkflow, WorkflowAuditCheckType, WorkflowCheckType } from '@one/app/shared/models/workflow/workflow.models';
import { InspectionService } from '@one/app/shared/services/inspection/inspection.service';
import { ProductMeasurementsService } from '@one/app/shared/services/measurements/product-measurements.service';
import { IStoreState } from '@one/app/store/store.models';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { QimaOptionalType } from '@qima/ngx-qima';
import { EMPTY, filter, Observable, of } from 'rxjs';
import { catchError, concatMap, debounceTime, map, mapTo, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';

/**
 * @description
 * Not provided in root on purpose
 */
@Injectable()
export class IspInspectionIdWorkflowStoreEffects {
  public updateAvailableCartons$ = createEffect(
    (): Observable<never> =>
      this._actions.pipe(
        ofType(updateAvailableCartons),
        withLatestFrom(this._store),
        mergeMap(
          ([action, storeState]): Observable<number> =>
            this._inspectionService.saveCartonsQuantity$(
              getInspectionIdOrUuid(storeState.inspectionStore.inspection),
              action.cartonsToPick,
              action.availableCartons,
              action.calculationMethod
            )
        ),
        mergeMap((): Observable<never> => EMPTY)
      ),
    { dispatch: false }
  );

  public loadWorkflow$ = createEffect(
    (): Observable<{
      type: '[Workflow] Load workflow success';
      workflow: QimaOptionalType<IWorkflow>;
    }> =>
      this._actions.pipe(
        ofType(loadWorkflow),
        mergeMap(
          (
            action
          ): Observable<{
            type: '[Workflow] Load workflow success';
            workflow: QimaOptionalType<IWorkflow>;
          }> => {
            let workflow$: Observable<IWorkflow>;

            if (typeof action.id === 'number') {
              workflow$ = this._inspectionService.getWorkflow$(action.id, false, HTTP_SKIP_409_OPTIONS);
            } else if (typeof action.id === 'string') {
              workflow$ = this._inspectionService.getWorkflow$(action.id, false);
            }

            return workflow$.pipe(
              filter((workflow): boolean => !!workflow),
              map(
                (
                  workflow: QimaOptionalType<IWorkflow>
                ): {
                  type: '[Workflow] Load workflow success';
                  workflow: QimaOptionalType<IWorkflow>;
                } => {
                  return {
                    type: LOADED_SUCCESS_WORKFLOW,
                    workflow,
                  };
                }
              ),
              catchError((error: Error | HttpErrorResponse): Observable<never> => {
                // Since it can throw HTTP error
                // When the inspector doesn't have the right to access the inspection
                // We navigate to a dedicated error page which is a bit better than the default forbidden page
                if (error instanceof HttpErrorResponse) {
                  if (error.status === EQpHttpStatusCode.FORBIDDEN) {
                    void this._router.navigate([IspRoutes.inspectionConsultationErrorForbidden()]);
                  } else if (error.status === EQpHttpStatusCode.GONE) {
                    void this._router.navigate([CommonRoutes.errorDeleted()], { queryParams: { type: ERouteType.INSPECTION } });
                  } else {
                    // @todo handle with better dedicated screens the errors
                    void this._router.navigate([IspRoutes.inspectionConsultationErrorUnknown()]);
                  }
                }

                return EMPTY;
              })
            );
          }
        )
      )
  );

  public updateGlobalRemarks = createEffect(
    (): Observable<{ type: string; remarks: IChecklistComment }> =>
      this._actions.pipe(
        ofType(updateGlobalRemarks),
        withLatestFrom(this._store),
        mergeMap(
          ([action, storeState]): Observable<{ type: string; remarks: IChecklistComment }> =>
            this._inspectionService
              .updateInspectionComment$(getInspectionIdOrUuid(storeState.inspectionStore.inspection), action.remarks)
              .pipe(map((): { type: string; remarks: IChecklistComment } => action))
        ),
        map((action): { type: string; remarks: IChecklistComment } => {
          return {
            type: UPDATE_GLOBAL_REMARKS_SUCCESS,
            remarks: action.remarks,
          };
        })
      )
  );

  public updateIsInspectionMarkedAsPending = createEffect(
    (): Observable<{ type: string; isInspectionMarkedAsPending: boolean }> =>
      this._actions.pipe(
        ofType(updateIsInspectionMarkedAsPending),
        withLatestFrom(this._store),
        mergeMap(
          ([action, storeState]): Observable<{ type: string; isInspectionMarkedAsPending: boolean }> =>
            this._inspectionService
              .updateInspectionIsMarkedAsPending$(
                getInspectionIdOrUuid(storeState.inspectionStore.inspection),
                action.isInspectionMarkedAsPending
              )
              .pipe(map((): { type: string; isInspectionMarkedAsPending: boolean } => action))
        ),
        map(({ isInspectionMarkedAsPending }): { type: string; isInspectionMarkedAsPending: boolean } =>
          updateIsInspectionMarkedAsPendingSuccess({ isInspectionMarkedAsPending })
        )
      )
  );

  // Test checklist 🧪

  public saveTestAnswer$ = createEffect(
    (): Observable<{ type: string; id: string; value: Answer }> =>
      this._actions.pipe(
        ofType(saveTestAnswer),
        withLatestFrom(this._store),
        mergeMap(([action, storeState]): Observable<{ type: string; id: string; value: Answer }> => {
          return this._ispInspectionIdWorkflowService
            .saveAnswer$(getInspectionIdOrUuid(storeState.inspectionStore.inspection), action.value, action.id, action.elementType)
            .pipe(
              map((): { type: string; id: string; value: Answer } => {
                return {
                  type: SAVE_TEST_ANSWER_SUCCESS,
                  id: action.id,
                  value: action.value,
                };
              })
            );
        })
      )
  );

  public saveNullableTestAnswer$ = createEffect(
    (): Observable<{ type: string; id: string; value: Answer }> =>
      this._actions.pipe(
        ofType(saveNullableTestAnswer),
        debounceTime(DEBOUNCE_TIME_IN_MS),
        withLatestFrom(this._store),
        filter(([_, storeState]): boolean => !!storeState.inspectionStore.inspection?.id),
        mergeMap(([action, storeState]): Observable<{ type: string; id: string; value: Answer }> => {
          const element: IQpTestsChecklistElement<Answer> = WorkflowUtils.getTestById(storeState.inspectionStore.workflow, action.id);

          return this._ispInspectionIdWorkflowService
            .saveNullableTestAnswer$(getInspectionIdOrUuid(storeState.inspectionStore.inspection), action.value, action.id, element.type)
            .pipe(
              map((): { type: string; id: string; value: Answer } => {
                return {
                  type: SAVE_TEST_ANSWER_SUCCESS,
                  id: action.id,
                  value: action.value,
                };
              })
            );
        })
      )
  );

  public commentTest$ = createEffect(
    (): Observable<{ type: string; id: string; comment: IChecklistComment }> =>
      this._actions.pipe(
        ofType(commentTest),
        withLatestFrom(this._store),
        mergeMap(
          ([element, storeState]): Observable<{ type: string; id: string; comment: IChecklistComment }> =>
            this._ispInspectionIdWorkflowService
              .updateAnswerCommentToApi$(getInspectionIdOrUuid(storeState.inspectionStore.inspection), element.id, element.comment)
              .pipe(
                map((): { type: string; id: string; comment: IChecklistComment } => {
                  return {
                    type: COMMENT_TEST_SUCCESS,
                    id: element.id,
                    comment: element.comment,
                  };
                })
              )
        )
      )
  );

  public markAsNotApplicable$ = createEffect(
    (): Observable<{ type: string; id: string; notApplicable: boolean }> =>
      this._actions.pipe(
        ofType(markAsNotApplicable),
        withLatestFrom(this._store),
        mergeMap(
          ([element, storeState]): Observable<{ type: string; id: string; notApplicable: boolean }> =>
            this._ispInspectionIdWorkflowService
              .markAsNotApplicable$(getInspectionIdOrUuid(storeState.inspectionStore.inspection), element.id, element.notApplicable)
              .pipe(
                map((): { type: string; id: string; notApplicable: boolean } => {
                  return {
                    type: MARK_AS_NOT_APPLICABLE_SUCCESS,
                    id: element.id,
                    notApplicable: element.notApplicable,
                  };
                })
              )
        )
      )
  );

  public deleteTableRow$ = createEffect(
    (): Observable<{
      type: '[Workflow - Test checklist] Delete table row success';
      id: string;
      answerId: string;
      numberOfColumns: number;
    }> =>
      this._actions.pipe(
        ofType(deleteTestChecklistTableRow),
        withLatestFrom(this._store),
        mergeMap(
          ([action, storeState]): Observable<{
            type: '[Workflow - Test checklist] Delete table row success';
            id: string;
            answerId: string;
            numberOfColumns: number;
          }> => {
            const element: QimaOptionalType<IQpTestsChecklistElement<Answer>> = WorkflowUtils.getTestById(
              storeState.inspectionStore.workflow,
              action.id
            );

            if (!storeState.inspectionStore.inspection) {
              return EMPTY;
            }

            if (element) {
              return this._ispInspectionIdWorkflowService
                .deleteTableRow$(
                  getInspectionIdOrUuid(storeState.inspectionStore.inspection),
                  action.id,
                  action.answerId,
                  action.numberOfColumns
                )
                .pipe(
                  map(
                    (): {
                      type: '[Workflow - Test checklist] Delete table row success';
                      id: string;
                      answerId: string;
                      numberOfColumns: number;
                    } => {
                      return {
                        type: DELETE_TEST_CHECKLIST_TABLE_ROW_SUCCESS,
                        id: action.id,
                        answerId: action.answerId,
                        numberOfColumns: action.numberOfColumns,
                      };
                    }
                  )
                );
            }

            return EMPTY;
          }
        )
      )
  );

  // Instruction 🧾

  public saveCheck$ = createEffect(
    (): Observable<{ type: string; actionId: string; check: WorkflowCheckType | WorkflowAuditCheckType }> =>
      this._actions.pipe(
        ofType(saveCheck),
        withLatestFrom(this._store),
        mergeMap(
          ([props, storeState]): Observable<{ type: string; actionId: string; check: WorkflowCheckType | WorkflowAuditCheckType }> =>
            this._ispInspectionIdWorkflowService
              .saveCheckToAPI$(getInspectionIdOrUuid(storeState.inspectionStore.inspection), props.actionId, props.check)
              .pipe(
                map((): { type: string; actionId: string; check: WorkflowCheckType | WorkflowAuditCheckType } => {
                  return {
                    type: SAVE_CHECK_SUCCESS,
                    actionId: props.actionId,
                    check: props.check,
                  };
                })
              )
        )
      )
  );

  // Defect checklist ❌

  public createDefect$ = createEffect(
    (): Observable<{ type: string; defect: IDefectsChecklistDefect }> =>
      this._actions.pipe(
        ofType(createDefect),
        withLatestFrom(this._store),
        mergeMap(
          ([props, storeState]): Observable<{ type: string; defect: IDefectsChecklistDefect }> =>
            this._inspectionService
              .createDefect$(
                getInspectionIdOrUuid(storeState.inspectionStore.inspection),
                props.name,
                props.defectType,
                props.stepId,
                props.classification,
                props.purchaseOrderProductId
              )
              .pipe(
                map((created): { type: string; defect: IDefectsChecklistDefect } => {
                  return {
                    type: CREATE_DEFECT_SUCCESS,
                    defect: created,
                  };
                })
              )
        )
      )
  );

  public updateDefect$ = createEffect(
    (): Observable<IUpdateDefectProps & { type: string }> =>
      this._actions.pipe(
        ofType(updateDefect),
        withLatestFrom(this._store),
        mergeMap(
          ([props, storeState]): Observable<IUpdateDefectProps & { type: string }> =>
            this._inspectionService
              .updateDefect$(
                getInspectionIdOrUuid(storeState.inspectionStore.inspection),
                props.defectId,
                props.quantity,
                props.classification,
                props.purchaseOrderProductId
              )
              .pipe(
                map((): IUpdateDefectProps & { type: string } => {
                  return {
                    type: UPDATE_DEFECT_SUCCESS,
                    defectId: props.defectId,
                    quantity: props.quantity,
                    classification: props.classification,
                    purchaseOrderProductId: props.purchaseOrderProductId,
                  };
                })
              )
        )
      )
  );

  public updateDefectComment$ = createEffect(
    (): Observable<IUpdateDefectCommentProps & { type: string }> =>
      this._actions.pipe(
        ofType(updateDefectComment),
        withLatestFrom(this._store),
        mergeMap(
          ([props, storeState]): Observable<IUpdateDefectCommentProps & { type: string }> =>
            this._inspectionService
              .updateDefectComment$(
                getInspectionIdOrUuid(storeState.inspectionStore.inspection),
                props.defectId,
                props.comment,
                props.purchaseOrderProductId
              )
              .pipe(
                map((): IUpdateDefectCommentProps & { type: string } => {
                  return {
                    type: UPDATE_DEFECT_COMMENT_SUCCESS,
                    defectId: props.defectId,
                    comment: props.comment,
                    purchaseOrderProductId: props.purchaseOrderProductId,
                  };
                })
              )
        )
      )
  );

  public completeDefectsChecklist$ = createEffect(
    (): Observable<{ type: string } & ICompleteDefectsChecklistProps> =>
      this._actions.pipe(
        ofType(completeDefectsChecklist),
        withLatestFrom(this._store),
        mergeMap(
          ([props, storeState]: [ICompleteDefectsChecklistProps, IStoreState]): Observable<
            {
              type: string;
            } & ICompleteDefectsChecklistProps
          > =>
            this._inspectionService
              .completeDefectsChecklist$(
                getInspectionIdOrUuid(storeState.inspectionStore.inspection),
                props.stepId,
                props.actionId,
                props.purchaseOrderProductId
              )
              .pipe(
                map((): { type: string } & ICompleteDefectsChecklistProps => {
                  return {
                    type: COMPLETE_DEFECTS_CHECKLIST_SUCCESS,
                    stepId: props.stepId,
                    actionId: props.actionId,
                    purchaseOrderProductId: props.purchaseOrderProductId,
                  };
                })
              )
        )
      )
  );

  // Recipients 📧

  public saveRecipients = createEffect(
    (): Observable<ISaveRecipientsActionType> =>
      this._actions.pipe(
        ofType(saveRecipients),
        withLatestFrom(this._store),
        mergeMap(
          ([props, storeState]): Observable<ISaveRecipientsActionType> =>
            this._inspectionService
              .updateInspectionRecipients$(getInspectionIdOrUuid(storeState.inspectionStore.inspection), props.recipients)
              .pipe(
                map((): ISaveRecipientsActionSuccess => {
                  return {
                    type: SAVE_RECIPIENTS_SUCCESS,
                    actionId: props.actionId,
                    recipients: props.recipients,
                  };
                }),
                catchError((error: unknown): Observable<ISaveRecipientsActionError> => {
                  this._qpLoggerService.error(error);

                  return of({
                    type: SAVE_RECIPIENTS_ERROR,
                    error,
                  });
                })
              )
        )
      )
  );

  public updateProductActualQuantities$ = createEffect(
    (): Actions =>
      this._actions.pipe(
        ofType(updateProductActualQuantities),
        withLatestFrom(this._store),
        concatMap(
          ([action, storeState]): Observable<{
            isApiCallInError: boolean;
            action: IUpdateProductActualQuantitiesProps;
            error?: string;
          }> => {
            return this._inspectionService
              .updateInspectionActualQuantities$(getInspectionIdOrUuid(storeState.inspectionStore.inspection), {
                productId: action.productId,
                purchaseOrderProductId: action.purchaseOrderProductId,
                produced: action.produced,
                packed: action.packed,
              })
              .pipe(
                map(
                  (): {
                    isApiCallInError: boolean;
                    action: IUpdateProductActualQuantitiesProps;
                    error?: string;
                  } => ({
                    isApiCallInError: false,
                    action,
                  })
                ),
                catchError(
                  (
                    error: string
                  ): Observable<{
                    isApiCallInError: boolean;
                    action: IUpdateProductActualQuantitiesProps;
                    error?: string;
                  }> =>
                    of({
                      isApiCallInError: true,
                      action,
                      error,
                    })
                )
              );
          }
        ),
        switchMap(({ isApiCallInError, action, error }): Action[] => {
          if (!isApiCallInError) {
            const actions: Action[] = [
              updateProductActualQuantitiesSuccess({
                productActualQuantities: {
                  productId: action.productId,
                  purchaseOrderProductId: action.purchaseOrderProductId,
                  produced: action.produced,
                  packed: action.packed,
                },
              }),
            ];

            if (action.updateAvailableProductsAction) {
              actions.push(action.updateAvailableProductsAction);
            }

            return actions;
          }

          return [updateProductActualQuantitiesError({ error })];
        })
      )
  );

  // Site Location 📍

  public saveLocation = createEffect(
    (): Observable<{ type: string; entity: IEntity | IEntityConsultation; stepId: number; actionId: number }> =>
      this._actions.pipe(
        ofType(addSiteLocation, clearSiteLocation),
        withLatestFrom(this._store),
        mergeMap(
          ([props, storeState]): Observable<{
            type: string;
            entity: IEntity | IEntityConsultation;
            stepId: number;
            actionId: number;
          }> =>
            this._ispInspectionIdWorkflowService
              .saveLocation$(getInspectionIdOrUuid(storeState.inspectionStore.inspection), +props.entity?.id ?? null)
              .pipe(
                map(
                  (): {
                    type: string;
                    entity: IEntity | IEntityConsultation;
                    stepId: number;
                    actionId: number;
                  } => {
                    return {
                      type: ADD_SITE_LOCATION_SUCCESS,
                      actionId: props.actionId,
                      entity: props.entity,
                      stepId: props.stepId,
                    };
                  }
                )
              )
        )
      )
  );

  // Simplified Measurements 📏

  public saveSimplifiedMeasures$ = createEffect(
    (): Observable<Action> =>
      this._actions.pipe(
        ofType(saveSimplifiedMeasures),
        mergeMap((action): Actions => {
          return this._inspectionService.saveSimplifiedMeasures$(action.inspectionId, action.simplifiedMeasures).pipe(
            map((): Action => saveSimplifiedMeasuresSuccess({ simplifiedMeasures: action.simplifiedMeasures })),
            catchError((error): Observable<Action> => of(saveSimplifiedMeasuresError({ error })))
          );
        })
      )
  );

  public saveSimplifiedMeasuresMarkedAsNotApplicable$ = createEffect(
    (): Observable<Action> =>
      this._actions.pipe(
        ofType(saveSimplifiedMeasuresMarkedAsNotApplicable),
        mergeMap((action): Actions => {
          return this._inspectionService
            .saveSimplifiedMeasuresMarkedAsNotApplicable$(action.inspectionId, {
              notApplicable: action.notApplicable,
            })
            .pipe(
              map(
                (): Action =>
                  saveSimplifiedMeasuresMarkedAsNotApplicableSuccess({
                    notApplicable: action.notApplicable,
                  })
              ),
              catchError((error): Observable<Action> => of(saveSimplifiedMeasuresError({ error })))
            );
        })
      )
  );

  public saveSimplifiedMeasuresComment$ = createEffect(
    (): Observable<Action> =>
      this._actions.pipe(
        ofType(saveSimplifiedMeasuresComment),
        mergeMap((action): Actions => {
          return this._inspectionService.saveSimplifiedMeasurementComment$(action.inspectionId, action.comment).pipe(
            map((): Action => saveSimplifiedMeasuresCommentSuccess({ comment: action.comment })),
            catchError((error): Observable<Action> => of(saveSimplifiedMeasuresError({ error })))
          );
        })
      )
  );

  // Measurements 📏

  public saveMeasurement = createEffect(
    (): Observable<{ type: string; measurementId: string; measure: number; sectionName: QimaOptionalType<string> }> =>
      this._actions.pipe(
        ofType(saveMeasurement),
        withLatestFrom(this._store),
        mergeMap(
          ([props, storeState]): Observable<{
            type: string;
            measurementId: string;
            measure: number;
            sectionName: QimaOptionalType<string>;
          }> =>
            this._productMeasurementService
              .saveMeasure$(
                getInspectionIdOrUuid(storeState.inspectionStore.inspection),
                props.measurementId,
                props.measure,
                props.sectionName
              )
              .pipe(
                map((): { type: string; measurementId: string; measure: number; sectionName: QimaOptionalType<string> } => {
                  return {
                    type: SAVE_MEASUREMENT_SUCCESS,
                    measurementId: props.measurementId,
                    measure: props.measure,
                    sectionName: props.sectionName,
                  };
                })
              )
        )
      )
  );

  public addCustomMeasurementProductSample$ = createEffect(
    (): Observable<{ type: string; path: string; sectionName: string }> =>
      this._actions.pipe(
        ofType(addCustomMeasurementProductSample),
        withLatestFrom(this._store),
        mergeMap(([props, storeState]): Observable<{ type: string; path: string; sectionName: string }> => {
          return this._productMeasurementService
            .addCustomMeasurementProductSample$(getInspectionIdOrUuid(storeState.inspectionStore.inspection), props.path, props.sectionName)
            .pipe(
              map((): { type: string; path: string; sectionName: string } => {
                return {
                  type: ADD_CUSTOM_MEASUREMENT_PRODUCT_SAMPLE_SUCCESS,
                  path: props.path,
                  sectionName: props.sectionName,
                };
              })
            );
        })
      )
  );

  // Product picture 📸

  public saveProductPicture$ = createEffect(
    (): Observable<{ type: string; actionId: string; action: { documentId: string } }> =>
      this._actions.pipe(
        ofType(saveProductPicture),
        withLatestFrom(this._store),
        mergeMap(
          ([props, storeState]): Observable<{ type: string; actionId: string; action: { documentId: string } }> =>
            this._ispInspectionIdWorkflowService
              .saveProductPictureToApi$(getInspectionIdOrUuid(storeState.inspectionStore.inspection), props.actionId, props.action)
              .pipe(
                map((): { type: string; actionId: string; action: { documentId: string } } => {
                  return {
                    type: SAVE_PRODUCT_PICTURE_SUCCESS,
                    actionId: props.actionId,
                    action: props.action,
                  };
                })
              )
        )
      )
  );

  // Custom fields ✨

  public saveWorkflowActionCustomFieldsValue$ = createEffect(
    (): Actions =>
      this._actions.pipe(
        ofType(saveWorkflowActionCustomFieldsValue),
        withLatestFrom(this._store),
        switchMap(
          ([props, storeState]): Actions =>
            this._ispInspectionIdWorkflowService
              .saveCustomFieldValueToApi$(getInspectionIdOrUuid(storeState.inspectionStore.inspection), props.fieldId, props.answer)
              .pipe(
                map((): Action => {
                  return saveWorkflowActionCustomFieldsValueSuccess(props);
                })
              )
        )
      )
  );

  // Findings summary review 📝

  public updateFindingsSummaryReview$: Observable<IUpdateFindingsSummaryReviewAction> = createEffect(
    (): Observable<IUpdateFindingsSummaryReviewAction> =>
      this._actions.pipe(
        ofType(confirmFindingsSummaryReview),
        withLatestFrom(this._store),
        mergeMap(
          ([_props, storeState]): Observable<IUpdateFindingsSummaryReviewAction> =>
            this._inspectionService.confirmFindingsSummaryReview$(getInspectionIdOrUuid(storeState.inspectionStore.inspection)).pipe(
              mapTo({
                type: CONFIRM_FINDINGS_SUMMARY_REVIEW_SUCCESS,
              })
            )
        )
      )
  );

  // Images 📷

  public deleteWorkflowImage$: Observable<IDeleteWorkflowImageSuccess> = createEffect(
    (): Observable<IDeleteWorkflowImageSuccess> =>
      this._actions.pipe(
        ofType(deleteInspectionImage),
        withLatestFrom(this._store),
        mergeMap(
          ([props]): Observable<IDeleteWorkflowImageSuccess> =>
            this._inspectionService.deleteInspectionImage$(props).pipe(
              map((): IDeleteWorkflowImageSuccess => {
                return {
                  ...props,
                  type: DELETE_INSPECTION_IMAGE_SUCCESS,
                };
              })
            )
        )
      )
  );

  // Reference sample

  public completeReferenceSample$: Observable<{ type: string }> = createEffect(
    (): Observable<{ type: string }> =>
      this._actions.pipe(
        ofType(completeReferenceSample),
        withLatestFrom(this._store),
        mergeMap(
          ([_props, storeState]): Observable<{ type: string }> =>
            this._inspectionService
              .completeReferenceSample$(
                getInspectionIdOrUuid(storeState.inspectionStore.inspection),
                _props.stepId,
                _props.actionId,
                _props.stepCompletionTime
              )
              .pipe(
                mapTo({
                  type: COMPLETE_REFERENCE_SAMPLE_SUCCESS,
                })
              )
        )
      )
  );

  // Sample collection

  public completeSampleCollection$: Observable<{ type: string }> = createEffect(
    (): Observable<{ type: string }> =>
      this._actions.pipe(
        ofType(completeSampleCollection),
        withLatestFrom(this._store),
        mergeMap(
          ([_props, storeState]): Observable<{ type: string }> =>
            this._inspectionService
              .completeSampleCollection$(
                getInspectionIdOrUuid(storeState.inspectionStore.inspection),
                _props.stepId,
                _props.actionId,
                _props.stepCompletionTime
              )
              .pipe(
                mapTo({
                  type: COMPLETE_SAMPLE_COLLECTION_SUCCESS,
                })
              )
        )
      )
  );

  public constructor(
    private readonly _inspectionService: InspectionService,
    private readonly _ispInspectionIdWorkflowService: IspInspectionIdWorkflowService,
    private readonly _productMeasurementService: ProductMeasurementsService,
    private readonly _actions: Actions,
    private readonly _store: Store<IStoreState>,
    private readonly _router: Router,
    private readonly _qpLoggerService: QpLoggerService
  ) {}
}
