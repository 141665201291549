import { EQpHttpStatusCode } from '@library/models/qp-http.models';
import { IHttpOptions } from '@one/app/shared/models/http/http.models';

// FYI: the images may be pre-fetch too soon
// The result is a 404 error that is catch by the interceptors
// This workaround allow to not catch the error and avoid this bad UX
// @todo remove this hack!
export const HTTP_SKIP_404_OPTIONS: IHttpOptions = {
  ignoredInterceptorStatusCodes: new Set<EQpHttpStatusCode>([EQpHttpStatusCode.NOT_FOUND]),
};
