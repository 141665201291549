<div [ngClass]="isCollapsed ? 'collapse' : 'expand'" class="menu-container" data-cy="desktop-navbar">
  <div class="top-menu">
    <div (click)="toggleCollapsed()" class="navigation-button-collapse" data-cy="burger-btn">
      <div *ngIf="!isCollapsed; else collapsedIcon" class="row-menu row-menu--expanded">
        <img [alt]="'home.navigation-menu.brand-home' | translate" class="menu-title" src="assets/pictures/qima-one-logo.png" />
      </div>
      <ng-template #collapsedIcon>
        <qp-icon *ngIf="isCollapsed" class="navigation-button-collapse__logo" [name]="iconNames.IC_QIMA_Q_LOGO"></qp-icon>
      </ng-template>
      <div class="navigation-button-collapse__direction">
        <qp-icon [class.navigation-button-collapse__direction-icon]="!isCollapsed" [name]="iconNames.IC_CHEVRON_RIGHT"></qp-icon>
      </div>
    </div>
    <ul [ngClass]="isCollapsed ? 'collapse' : 'expand'" [nzInlineCollapsed]="isCollapsed" class="navigation-menu" nz-menu nzMode="inline">
      <li *ngFor="let menu of menuList" class="menu" nz-menu-item>
        <a
          [attr.data-cy]="menu.dataCy"
          qimaTooltip
          qimaTooltipHasArrow="true"
          [qimaTooltipText]="menu.title | translate"
          [qimaTooltipPosition]="qimaPositions.RIGHT"
          [qimaTooltipIsVisible]="isCollapsed"
          [routerLinkActiveOptions]="menu.routerLinkActiveOption"
          [routerLinkActive]="(menu.routerLink || menu.defaultPageRoute?.routerLink) !== commonRoutes.comingSoon() ? 'menu-selected' : ''"
          [routerLink]="menu.routerLink || menu.defaultPageRoute?.routerLink"
          class="menu-item"
        >
          <span class="row-menu" title>
            <em [nzType]="menu.iconName" nz-icon></em>
            <span class="item">{{ menu.title | translate }}</span>
          </span>
        </a>
      </li>
      <li *ngIf="academyUrl" class="menu" nz-menu-item>
        <a
          [href]="academyUrl"
          qimaTooltip
          qimaTooltipHasArrow="true"
          [qimaTooltipText]="'home.navigation-menu.academy-menu' | translate"
          [qimaTooltipPosition]="qimaPositions.RIGHT"
          [qimaTooltipIsVisible]="isCollapsed"
          class="menu-item"
          target="_blank"
        >
          <span class="row-menu" title>
            <em nz-icon nzType="ic-academy"></em>
            <span class="item">{{ 'home.navigation-menu.academy-menu' | translate }}</span>
          </span>
        </a>
      </li>
      <qp-divider *ngIf="middleMenuList.length > 0" class="menu-divider" [color]="dividerColors.PEARL"></qp-divider>
      <li *ngFor="let menu of middleMenuList" class="menu" nz-menu-item>
        <a
          [attr.data-cy]="menu.dataCy"
          qimaTooltip
          qimaTooltipHasArrow="true"
          [qimaTooltipText]="menu.title | translate"
          [qimaTooltipPosition]="qimaPositions.RIGHT"
          [qimaTooltipIsVisible]="isCollapsed"
          [routerLinkActiveOptions]="menu.routerLinkActiveOption"
          [routerLinkActive]="(menu.defaultPageRoute?.routerLink || menu.routerLink) !== commonRoutes.comingSoon() ? 'menu-selected' : ''"
          [routerLink]="menu.defaultPageRoute?.routerLink || menu.routerLink"
          class="menu-item"
        >
          <span class="row-menu" title>
            <em [nzType]="menu.iconName" nz-icon></em>
            <span class="item">{{ menu.title | translate }}</span>
          </span>
        </a>
      </li>
    </ul>
  </div>

  <div class="bottom-menu">
    <qp-divider [color]="dividerColors.PEARL"></qp-divider>
    <div *ngIf="!isCollapsed; else collapsed" class="brand-name" data-cy="brand-name">
      {{ account?.companyName }}
    </div>
    <ng-template #collapsed>
      <div [qpTooltipText]="account?.companyName" class="brand-ellipsis shown" qpTooltip>...</div>
    </ng-template>
    <div class="logout-container">
      <a
        [attr.data-cy]="accountRoute?.dataCy"
        [routerLinkActiveOptions]="accountRoute?.routerLinkActiveOption"
        [routerLinkActive]="
          (accountRoute?.defaultPageRoute?.routerLink || accountRoute?.routerLink) !== commonRoutes.comingSoon() ? 'menu-selected' : ''
        "
        [routerLink]="accountRoute?.defaultPageRoute?.routerLink || accountRoute?.routerLink"
        class="profile menu-item"
      >
        <div class="picture">
          <img
            [src]="'/api/users/' + account?.id + '/profile-picture'"
            qpFallbackImage
            [qpFallbackImageUrl]="defaultPictureUrl"
            alt="profile picture"
          />
        </div>
        <div [ngClass]="isCollapsed ? 'collapse' : 'expand'" class="information">
          <div class="name">{{ account?.firstName }}</div>
          <div class="role">{{ account?.profiles[0] | oneAuthorityLabel | translate }}</div>
        </div>
        <a *ngFor="let subEntity of accountRoute?.children" [routerLink]="subEntity?.routerLink"></a>
      </a>
      <div [ngClass]="isCollapsed ? 'collapse' : 'expand'" class="logout-btn">
        <div class="separate"></div>
        <a (click)="tryLoggingOut()" data-cy="logout-btn">
          <em [nzType]="'ic-logout'" nz-icon></em>
        </a>
      </div>
    </div>
  </div>
</div>
