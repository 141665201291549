import { QpDeviceDirective } from '@library/classes/qp-device/qp-device.directive';
import { QpResponsiveService } from '@library/services/qp-responsive/qp-responsive.service';
import { Directive, TemplateRef, ViewContainerRef, ChangeDetectorRef } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * @description
 * Display some content when the screen match a small device
 *
 * @example
 * ```html
 * <ng-container *qpSmallDevice>
 *   I am visible only in a small device!
 * </ng-container>
 * ```
 * @deprecated
 */
@Directive({
  selector: '[qpSmallDevice]',
  standalone: true,
})
export class QpSmallDeviceDirective<TComponent> extends QpDeviceDirective<TComponent> {
  public constructor(
    _templateRef: TemplateRef<TComponent>,
    _viewContainerRef: ViewContainerRef,
    _changeDetectorRef: ChangeDetectorRef,
    private readonly _qpResponsiveService: QpResponsiveService
  ) {
    super(_templateRef, _viewContainerRef, _changeDetectorRef);
  }

  public onDeviceChange$(): Observable<boolean> {
    return this._qpResponsiveService.isSmallDevice$();
  }
}
