import { QpLoggerService } from '@library/services/qp-logger/qp-logger.service';
import { ConnectService } from '@one/app/pages/connect/connect.service';
import { JwtAuthenticationService } from '@one/app/shared/services/auth/auth-jwt.service';
import { AuthenticationService } from '@one/app/shared/services/auth/authentication.service';
import { IamsAuthenticationService } from '@one/app/shared/services/auth/iams-authentication.service';
import { IAM_CONFIG } from '@one/app/shared/tokens/iam-config.token';
import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { isEmpty } from 'lodash/index';
import { CookieService } from 'ngx-cookie';
import { SessionStorageService } from 'ngx-webstorage';

/**
 * This factory allows new IAMS login and old standalone login to coexist.
 * Once IAMS is fully ready, we will be able to delete the older service
 *
 * @param _httpClient
 * @param _cookieService
 * @param _oauthService
 * @param _router
 * @param _injector
 * @param _qpLoggerService
 * @param _connectService
 * @param _sessionStorageService
 */
export function AuthenticationFactory(
  _cookieService: CookieService,
  _oauthService: OAuthService,
  _httpClient: HttpClient,
  _router: Router,
  _injector: Injector,
  _qpLoggerService: QpLoggerService,
  _connectService: ConnectService,
  _sessionStorageService: SessionStorageService
): AuthenticationService {
  return !isEmpty(_injector.get(IAM_CONFIG))
    ? new IamsAuthenticationService(_oauthService, _cookieService, _qpLoggerService)
    : new JwtAuthenticationService(_httpClient, _cookieService, _connectService, _router, _sessionStorageService);
}
