import { googleTagManagerIdFactory } from '@one/app/shared/factories/google-tag-manager-id.factory';
import { GOOGLE_TAG_MANAGER_ID } from '@one/app/shared/tokens/google-tag-manager-id.token';
import { WINDOW } from '@one/app/shared/tokens/window.token';
import { FactoryProvider } from '@angular/core';

export const GOOGLE_TAG_MANAGER_ID_PROVIDER: FactoryProvider = {
  deps: [WINDOW],
  provide: GOOGLE_TAG_MANAGER_ID,
  useFactory: googleTagManagerIdFactory,
};
