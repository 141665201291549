import { ChecklistRatingResultDTO } from '@library/dto/checklist/checklist-rating-result.dto';

export const DEFAULT_RATING_RESULT_NAME = 'N/A';

export const DEFAULT_RATING_RESULT_COLOR = 'abb8c4';

export const DEFAULT_RATING_RESULT: ChecklistRatingResultDTO = {
  name: DEFAULT_RATING_RESULT_NAME,
  color: DEFAULT_RATING_RESULT_COLOR,
  id: 0,
  weight: 0,
};
