import { ISP_WORKFLOW_STEP_ACTION_STRATEGY } from '@one/app/pages/isp/pages/inspection/pages/id/shared/models/isp-create-workflow-step-action.models';
import { IspWorkflowStepActionType } from '@one/app/pages/isp/pages/inspection/pages/id/shared/models/isp-workflow-step-action.models';
import { IWorkflow, IWorkflowAction } from '@one/app/shared/models/workflow/workflow.models';
import { IWorkflowInspection } from '@one/app/shared/models/workflows/workflow.models';
import { isFunction } from 'lodash/index';

/**
 * @param {Readonly<IWorkflowAction>} action The workflow action
 * @param {Readonly<IWorkflowInspection>} inspection The inspection related to this workflow
 * @param workflow The workflow of the action
 * @returns {IspWorkflowStepActionType | null} The workflow action or nothing
 */
export function createWorkflowStepAction(
  action: Readonly<IWorkflowAction>,
  inspection?: Readonly<IWorkflowInspection>,
  workflow?: Readonly<IWorkflow>
): IspWorkflowStepActionType | null {
  if (isFunction(ISP_WORKFLOW_STEP_ACTION_STRATEGY[action.type])) {
    return ISP_WORKFLOW_STEP_ACTION_STRATEGY[action.type](action, inspection, workflow);
  }

  return null;
}
