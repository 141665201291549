export enum EBrdInspectionStep {
  ProductTab = 0,
  SiteTab = 1,
  InspectionTab = 2,
  SampleCollection = 2,
  ReferenceSample = 2,
  InspectorTab = 3,
  WorkflowTab = 4,
  SummaryTab = 5,
}

export enum EBrdInspectionStepTitle {
  PRODUCT_TAB = 'product',
  SITE_TAB = 'site',
  INSPECTION_TAB = 'inspection',
  INSPECTOR_TAB = 'inspector',
  WORKFLOW_TAB = 'workflow',
  SUMMARY_TAB = 'summary',
}

export enum ERecipientType {
  BCC_RECIPIENTS = 'bccRecipients',
  CC_RECIPIENTS = 'ccRecipients',
}
