import { EChecklistRequirementType, IChecklistRequirement } from '@one/app/shared/models/checklist/checklist-requirement.models';

export class ChecklistRequirement implements IChecklistRequirement {
  public static create(requirement: {
    type: EChecklistRequirementType;
    icon: string;
    isRequired: boolean;
    translationKey: string;
    isEnabled: boolean;
  }): IChecklistRequirement {
    return new ChecklistRequirement(
      requirement.type,
      requirement.icon,
      requirement.isRequired,
      requirement.translationKey,
      requirement.isEnabled
    );
  }

  private constructor(
    public type: EChecklistRequirementType,
    public icon: string,
    public isRequired: boolean,
    public translationKey: string,
    public isEnabled: boolean
  ) {}
}
