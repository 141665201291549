import { INotifuseMessageData } from '@one/app/shared/services/third-party/notifuse/notifuse.models';

export enum EQpNotificationId {
  REPORT_DECISION = 'reportdecision',
  REPORT_DECISION_MADE_BY_ENTITY = 'reportdecisionmadebyentity',
  CAP_CREATION = 'capcreation',
  CAP_CONFIRM = 'capconfirm',
  CAP_DECISION = 'capdecision',
  CAP_COMPLETION_DATE = 'capcompletiondate',
  INSPECTION_UPDATE = 'inspectionupdate',
  FACTORY_REQUEST = 'factoryrequest',
}

export interface IReportDecisionMessage extends INotifuseMessageData {
  action: string;
  brandName: string;
  inspectionDate: string;
  inspectionId: number;
}

export interface IFactoryRequestMessage extends INotifuseMessageData {
  entityName: string;
  plannedDate: string;
  inspectionId: number;
  productIdentifiers: string[];
}

export interface ICAPMessage extends INotifuseMessageData {
  capId: number;
  reportId: number;
}

export interface IInspectionUpdateMessage extends INotifuseMessageData {
  inspectionId: number;
}
