import { ChecklistRatingDTO } from '@library/dto/checklist/checklist-rating.dto';
import { RatingTypes } from '@library/dto-enums/rating-types.dto-enum';
import { ETestChecklistStatus } from '@one/app/shared/models/checklist/tests-checklist.models';

export enum EBrdWorkflowChecklistConsultationType {
  TESTS = 'TESTS',
  DEFECTS = 'DEFECTS',
  AUDITS = 'AUDITS',
}

export interface IBrdWorkflowChecklistConsultation {
  readonly id: number;
  readonly name: string;
  readonly publicationStatus: ETestChecklistStatus;
  readonly type: EBrdWorkflowChecklistConsultationType;
  readonly isQima: boolean;
  readonly createdByFirstName: string;
  readonly createdById: number;
  readonly createdByLastName: string;
  readonly createdByLogin: string;
  readonly createdDate: string;
  readonly lastModifiedByFirstName: string;
  readonly lastModifiedById: number;
  readonly lastModifiedByLastName: string;
  readonly lastModifiedByLogin: string;
  readonly lastModifiedDate: string;
  readonly checklistId?: number;
  readonly ratingType: RatingTypes;
  readonly ratings: ChecklistRatingDTO[]; // 💡 can be empty (there is no rating on PASS/FAIL checklist)
}

export interface IBrdSharedWorkflowChecklistConsultation extends IBrdWorkflowChecklistConsultation {
  readonly checklistId: number;
}

export type BrdWorkflowChecklistConsultationType = IBrdWorkflowChecklistConsultation | IBrdSharedWorkflowChecklistConsultation;

export class BrdWorkflowChecklistConsultation implements IBrdWorkflowChecklistConsultation {
  public static of(checklist: {
    id: number;
    name: string;
    publicationStatus: ETestChecklistStatus;
    type: EBrdWorkflowChecklistConsultationType;
    isQima: boolean;
    createdByFirstName: string;
    createdById: number;
    createdByLastName: string;
    createdByLogin: string;
    createdDate: string;
    lastModifiedByFirstName: string;
    lastModifiedById: number;
    lastModifiedByLastName: string;
    lastModifiedByLogin: string;
    lastModifiedDate: string;
    ratingType: RatingTypes;
    ratings: ChecklistRatingDTO[];
  }): IBrdWorkflowChecklistConsultation {
    return new BrdWorkflowChecklistConsultation(
      checklist.id,
      checklist.name,
      checklist.publicationStatus,
      checklist.type,
      checklist.isQima,
      checklist.createdByFirstName,
      checklist.createdById,
      checklist.createdByLastName,
      checklist.createdByLogin,
      checklist.createdDate,
      checklist.lastModifiedByFirstName,
      checklist.lastModifiedById,
      checklist.lastModifiedByLastName,
      checklist.lastModifiedByLogin,
      checklist.lastModifiedDate,
      checklist.ratingType,
      checklist.ratings
    );
  }

  private constructor(
    public id: number,
    public name: string,
    public publicationStatus: ETestChecklistStatus,
    public type: EBrdWorkflowChecklistConsultationType,
    public isQima: boolean,
    public createdByFirstName: string,
    public createdById: number,
    public createdByLastName: string,
    public createdByLogin: string,
    public createdDate: string,
    public lastModifiedByFirstName: string,
    public lastModifiedById: number,
    public lastModifiedByLastName: string,
    public lastModifiedByLogin: string,
    public lastModifiedDate: string,
    public ratingType: RatingTypes,
    public ratings: ChecklistRatingDTO[]
  ) {}
}
