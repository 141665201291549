import { EQpHttpStatusCode } from '@library/models/qp-http.models';
import { IHttpOptions } from '@one/app/shared/models/http/http.models';
import { HttpHeaders } from '@angular/common/http';
import { QimaOptionalType } from '@qima/ngx-qima';
import { isNil } from 'lodash/index';

export const CUSTOM_HTTP_HEADER_IGNORED_INTERCEPTOR_STATUS_CODES = 'IGNORED_INTERCEPTOR_STATUS_CODES';

/**
 * @description
 * Common way to provide some custom headers to the HTTP calls
 * This help for consistency
 * @param {QimaOptionalType<IHttpOptions>} options The HTTP options that will be used to create the headers
 * @param {HttpHeaders} headers The headers class to merge with your custom headers if necessary
 * @returns {HttpHeaders} The headers class updated with new custom headers based on the provided HTTP options
 */
export function getCustomHttpHeaders(options: QimaOptionalType<IHttpOptions>, headers: HttpHeaders = new HttpHeaders()): HttpHeaders {
  if (isNil(options)) {
    return headers;
  }

  if (!isNil(options.ignoredInterceptorStatusCodes)) {
    headers = headers.append(
      CUSTOM_HTTP_HEADER_IGNORED_INTERCEPTOR_STATUS_CODES,
      Array.from(options.ignoredInterceptorStatusCodes.values())
        .map((statusCode: EQpHttpStatusCode): string => {
          return statusCode.toString();
        })
        .join(',')
    );
  }

  return headers;
}
